import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-banned-page',
  templateUrl: './banned-page.component.html',
  styleUrls: ['./banned-page.component.scss']
})
export class BannedPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
