
import { AuthService } from './auth.service'
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor (private authService: AuthService, private router: Router, private snackBar: MatSnackBar) { }
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      return true
    } else {
      this.snackBar.open($localize `Sessione scaduta, esegui nuovamente l\'accesso al sistema`, null, {
        duration: 4000
      }).afterDismissed().subscribe(() => {
        this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
      })
    }
  }
}
