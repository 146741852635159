<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav mode="push">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon color="">menu</mat-icon>
    </button>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-toolbar [mostraFiltro]="false"></app-toolbar>
    <div class="flex-column alternate-theme padding-10 flex-gap-20">
      <div i18n class="titolo-pagina">
        Statistiche Segnalazioni
      </div>
      <div class="flex-row-start flex-gap-10 sezione-periodo" style="width:100%;">
        <div i18n class="sezione-periodo">Sintesi Periodo di Riferimento:</div>
        <span>
          <mat-icon class="testo-accent">insert_invitation</mat-icon>
          <input type="text" ngxDaterangepickerMd matInput id="1234" class="testo-accent pointer no-border"
            startKey="start" endKey="end" [locale]="{applyLabel: 'ok', format: 'DD/MM/YYYY', separator: ' A '}"
            [(ngModel)]="selected" i18n-placeholder placeholder="Seleziona il periodo di interesse"
            (change)="change($event)" [ranges]="ranges" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="true" />
        </span>


        <span></span>
      </div>
      <div class="flex-gap-20 pannello-grafico">
        <mat-card>
          <mat-card-header>
            <mat-card-title>
              <h1 i18n class="titolo-grafico">Provenienza delle Segnalazioni</h1>
            </mat-card-title>
            <mat-card-subtitle class="flex-column flex-gap-20">
              <mat-button-toggle-group name="filtriSorgente" aria-label="Font Style">
                <mat-button-toggle i18n #sorgenteTutte value="TUTTE"
                  (change)="onFiltroSorgenteChange($event.value)">Tutte Le
                  Segnalazioni</mat-button-toggle>
                <mat-button-toggle i18n #sorgenteOdv value="ODV"
                  (change)="onFiltroSorgenteChange($event.value)">Ricevute dal
                  Segnalante o Inoltrate da altri Gestori</mat-button-toggle>
                <mat-button-toggle i18n #sorgenteMie value="USER"
                  (change)="onFiltroSorgenteChange($event.value)">Ricevute
                  dal Segnalante</mat-button-toggle>
              </mat-button-toggle-group>
              <div *ngIf="graficoProvenienzaData.length == 0">
                <h2 i18n class="centra-testo width-full">Nessun Dato disponibile per il periodo selezionato</h2>
              </div>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div style="display: block" [hidden]="graficoProvenienzaData?.length === 0 ">
              <canvas baseChart [data]="graficoProvenienzaData" #chartProvenienza="base-chart"
                [labels]="graficoProvenienzaLabels" [options]="graficoProvenienzaOptions" [colors]="chartColors"
                [chartType]="graficoProvenienzaType"></canvas>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="flex-gap-20 pannello-grafico">
          <mat-card-header>
            <mat-card-title>
              <h1 i18n class="titolo-grafico">Natura delle Segnalazioni</h1>
            </mat-card-title>
            <mat-card-subtitle>
              <mat-button-toggle-group name="filtriTema" aria-label="Font Style">
                <mat-button-toggle i18n #temaTutte value="TUTTE" (change)="onFiltroTemaChange($event.value)">Tutte Le
                  Segnalazioni</mat-button-toggle>
                <mat-button-toggle i18n #temaOdv value="ODV" (change)="onFiltroTemaChange($event.value)">Ricevute dal
                  Segnalante o Inoltrate da altri Gestori</mat-button-toggle>
                <mat-button-toggle i18n #temaMie value="USER" (change)="onFiltroTemaChange($event.value)">Ricevute dal
                  Segnalante</mat-button-toggle>
              </mat-button-toggle-group>
              <div *ngIf="graficoTemaData[0]?.data?.length == 0">
                <h2 i18n class="centra-testo width-full">Nessun Dato disponibile per il periodo selezionato</h2>
              </div>
            </mat-card-subtitle>


          </mat-card-header>

          <mat-card-content>
            <div style="display: block" [hidden]="graficoTemaData[0]?.data?.length === 0 ">
              <canvas baseChart [datasets]="graficoTemaData" #chartTema="base-chart" [labels]="graficoTemaLabels"
                [colors]="chartColors" [legend]="graficoTemaLegend" [chartType]="graficoTemaType"
                [options]="graficoTemaOptions"></canvas>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="flex-gap-20 pannello-grafico">
        <mat-card class="flex-gap-20 pannello-grafico">
          <mat-card-header>
            <mat-card-title>
              <h1 i18n class="titolo-grafico">Ammissibilità delle Segnalazioni</h1>
            </mat-card-title>
            <mat-card-subtitle>
              <mat-button-toggle-group name="filtriAmmissibilita" aria-label="Font Style">
                <mat-button-toggle #ammissibilitaTutte value="TUTTE" i18n
                  (change)="onFiltroAmmissibilitaChange($event.value)">Tutte Le Segnalazioni</mat-button-toggle>
                <mat-button-toggle i18n #ammissibilitaOdv value="ODV"
                  (change)="onFiltroAmmissibilitaChange($event.value)">
                  Ricevute dal Segnalante o Inoltrate da altri Gestori</mat-button-toggle>
                <mat-button-toggle i18n #ammissibilitaMie value="USER"
                  (change)="onFiltroAmmissibilitaChange($event.value)">
                  Ricevute dal Segnalante</mat-button-toggle>
              </mat-button-toggle-group>
              <div [hidden]="graficoAmmissibilitaData.length !== 0">
                <h2 i18n class="centra-testo width-full">Nessun Dato disponibile per il periodo selezionato</h2>
              </div>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div style="display: block" [hidden]="graficoAmmissibilitaData.length <= 0 " class="flex-gap-60">
              <canvas baseChart [data]="graficoAmmissibilitaData" #chartAmmissibilita="base-chart"
                [labels]="graficoAmmissibilitaLabels" [options]="graficoAmmissibilitaOptions" [colors]="chartColors"
                [chartType]="graficoAmmissibilitaType"></canvas>
            </div>
          </mat-card-content>
          <mat-card-footer>
            <div [hidden]="graficoAmmissibilitaData.length === 0">
              <h2 i18n class="titolo-grafico">Motivi Non Ammissibilità</h2>
              <div style="display: block" [hidden]="graficoMotiviInammissibilitaData.length <= 0 ">
                <canvas baseChart [data]="graficoMotiviInammissibilitaData" #chartMotiviInammissibilita="base-chart"
                  [labels]="graficoMotiviInammissibilitaLabels" [options]="graficoMotiviInammissibilitaOptions"
                  [colors]="chartColors" [chartType]="graficoMotiviInammissibilitaType"
                  [legend]="graficoMotiviInammissibilitaLegend"></canvas>
              </div>
            </div>
          </mat-card-footer>
        </mat-card>
        <mat-card class="flex-gap-20">
          <mat-card-header>
            <mat-card-title>
              <h1 i18n class="titolo-grafico">Azioni di Follow-Up</h1>
            </mat-card-title>
            <mat-card-subtitle class="flex-gap-20">
              <mat-button-toggle-group name="filtriAzioni" aria-label="Font Style">
                <mat-button-toggle i18n #azioniTutte value="TUTTE" (change)="onFiltroAzioniChange($event.value)">Tutte
                  Le
                  Segnalazioni</mat-button-toggle>
                <mat-button-toggle i18n #azioniOdv value="ODV" (change)="onFiltroAzioniChange($event.value)">Ricevute
                  dal
                  Segnalante o Inoltrate da altri Gestori</mat-button-toggle>
                <mat-button-toggle i18n #azioniMie value="USER" (change)="onFiltroAzioniChange($event.value)">Ricevute
                  dal
                  Segnalante</mat-button-toggle>
              </mat-button-toggle-group>
              <div [hidden]="graficoAzioniFollowUpData.length != 0">
                <h2 i18n class="centra-testo width-full">Nessun Dato disponibile per il periodo selezionato</h2>
              </div>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div style="display: block" class="flex-gap-20">

              <canvas baseChart [data]="graficoAzioniFollowUpData" #chartAzioniFollowUp="base-chart"
                [labels]="graficoAzioniFollowUpLabels" [options]="graficoAzioniFollowUpOptions" [colors]="chartColors"
                [chartType]="graficoAzioniFollowUpType" [legend]="graficoAzioniFollowUpLegend"></canvas>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
