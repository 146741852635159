import { Component, OnInit, Input, ElementRef } from '@angular/core'

@Component({
  selector: 'app-icona-info',
  templateUrl: './icona-info.component.html',
  styleUrls: ['./icona-info.component.scss']
})
export class IconaInfoComponent implements OnInit {

  @Input() paroleChiave: string[]
  @Input() input: ElementRef

  fissato: boolean
  hover: boolean

  constructor() { }

  ngOnInit() {
  }

  focusInput(input) {
    if (input) {
          setTimeout(() => input.focus())
    }

    // this.scroll(input);
  }

  scroll(el: HTMLElement) {
    if (el) {
      setTimeout(() => el.scrollIntoView())
    }

  }

}
