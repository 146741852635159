<!-- idp.component.html -->
<mat-card style="max-width: 2000px; margin: auto;">
  <mat-card-title i18n>Identity Providers</mat-card-title>
  <mat-card-content>
    <div>
      <mat-form-field>
        <mat-label i18n>Nome IdP</mat-label>
        <input matInput #name="ngModel" [(ngModel)]="idpName">
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Entry Point</mat-label>
        <textarea matInput #entryPoint="ngModel" [(ngModel)]="idpEntryPoint"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Issuer</mat-label>
        <textarea matInput #entryPoint="ngModel" [(ngModel)]="idpIssuer"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Callback URL</mat-label>
        <textarea matInput #callbackUrl="ngModel" [(ngModel)]="idpCallbackUrl"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Certificato IdP</mat-label>
        <textarea matInput #certificate="ngModel" [(ngModel)]="idpCertificate"></textarea>
      </mat-form-field>

      <button mat-raised-button color="primary"
              (click)="add(idpName, idpCertificate)"
              [disabled]="!isValidCertificate(idpCertificate) || !idpName.trim() || !isValidUrl(idpEntryPoint)" i18n>
        Aggiungi IdP
      </button>
    </div>

    <mat-list>
      <mat-list-item *ngFor="let idp of idps">
        <div style="flex: 0.5; min-width: 0;">
          <p style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ idp.name }}</p>
        </div>
        <div style="flex: 1; min-width: 0;">
          <p style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ idp.entryPoint }}</p>
        </div>
        <div>
          <button mat-icon-button (click)="confirmDeletion(idp)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button (click)="editIdp(idp)">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
