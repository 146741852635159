import { SottoTipoContenuto, TipoContenuto } from '../utility/utility'
import { VersioneContenuto } from './versione-contenuto'

export class Contenuto {
  id: number

  tipo: TipoContenuto

  sottotipo: SottoTipoContenuto

  versioni: VersioneContenuto[]
}
