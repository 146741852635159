<div class="padding-10 alternate-theme" style="max-width: 800px; margin:auto;">

  <h2 mat-dialog-title class="titolo-dialog" i18n>Cookie Policy</h2>
  <mat-dialog-content>
    <angular-editor [(ngModel)]="cookiePolicy" [config]="editorConfig">
    </angular-editor>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button i18n mat-button mat-dialog-close class="margin-auto" i18n>Chiudi</button>
  </mat-dialog-actions>

</div>
