import { AuthService } from '../servizi/auth.service'
import { Component, Inject } from '@angular/core'

import { User } from '../modello/user'
import { Odv } from '../modello/odv'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { SessionData } from '../sessione/dati-sessione'
import { ThemeService } from '../servizi/theme.service'
@Component({
  selector: 'app-info-utente-dialog',
  templateUrl: 'info-utente.html',
})
export class InfoUtenteDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any, public dialogRef: MatDialogRef<InfoUtenteDialogComponent>, public authService: AuthService,
    private sessionData: SessionData) {
  }
  get utente(): User {
    const utente = this.authService.getUser()
    return utente
  }

  chiudiDialog() {
    this.dialogRef.close()
  }

  get ruoliGestore() {
    let ruoli = $localize `Nessun ruolo`
    if (this.utente.odvMembership && this.utente.odvMembership.length > 0) {
      ruoli = this.utente.odvMembership?.length > 1 ? $localize `Ricopre i ruoli di ` : $localize `Ricopre il ruolo di `
      ruoli += this.utente.odvMembership[0].nomeOdv
      if (this.utente.odvMembership.length > 1) {
        const tmpList = this.utente.odvMembership.slice(1)
        tmpList.forEach((gruppo: Odv) => {
          ruoli += ', ' + gruppo.nomeOdv
        })
      }
    }

    return ruoli
  }

  get custodia() {
    return this.utente.custode && this.sessionData.configurazione.custodiaIdentita
  }

}
