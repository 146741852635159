import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AuthService } from './auth.service'
import { Esito } from '../utility/esito'
import { ServiziRest } from '../utility/utility'
import { Configurazione, ConfigurazioneBan } from '../modello/configurazione'
import { Observable } from 'rxjs'
import { Traduzioni } from '../modello/traduzioni'
import { SessionData } from '../sessione/dati-sessione'

@Injectable({
  providedIn: 'root',
})
export class ConfigurazioneService {


  deleteTraduzione(traduzione: Traduzioni): Observable<Esito> {
    throw new Error('Method not implemented.')
  }
  addTraduzione(traduzione: Traduzioni): Observable<Esito> {
    return this.http.post<Esito>(ServiziRest.urlAddTraduzione, traduzione)
  }
  updateTraduzione(traduzione: Traduzioni): Observable<Esito> {
    return this.http.post<Esito>(ServiziRest.urlUpdateTraduzione, traduzione)
  }
  constructor(
    private http: HttpClient,
    private authenticationService: AuthService,
    private datiSessione: SessionData
  ) {}

  handleErrorPromise(error: Response | any) {
    console.error(error.message || error)
    return Promise.reject(error.error || error)
  }

  recuperaConfigurazione(): Observable<Esito> {
    return this.http.get<Esito>(ServiziRest.urlGetConfigurazione)
  }

  recuperaTipiNonConformita(): Observable<Esito> {
    return this.http.get<Esito>(ServiziRest.urlLoadTipiNonConformita)
  }

  recuperaTipiNonConformitaLinguaUtente(): Observable<Esito> {
    return this.http.get<Esito>(ServiziRest.urlLoadTipiNonConformitaByHashAndLanguage)
  }

  async salvaConfigurazione(configurazione: Configurazione): Promise<Esito> {
    return this.http
      .post<Esito>(ServiziRest.urlConfigurazione, configurazione)
      .toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  updateTipiNonConformita(traduzioni: Traduzioni[]): Observable<Esito> {
    return this.http
      .post<Esito>(ServiziRest.urlUpdateTipiNonConformita, traduzioni)
  }

  removeTipiNonConformita(traduzione: Traduzioni): Observable<Esito> {
    return this.http
      .delete<Esito>(ServiziRest.urlRemoveTipiNonConformita + '/' + traduzione.hash)
  }

  recuperaConfigurazioneBan(): Observable<Esito> {
    return this.http.get<Esito>(ServiziRest.urlConfigurazioneBan + '/1')
  }

  aggiornaConfigurazioneBan(configurazione: ConfigurazioneBan): Observable<Esito> {
    return this.http
      .put<Esito>(ServiziRest.urlConfigurazioneBan, configurazione)
  }

  loadClientConfigurations(): Observable<Esito> {
    return this.http.get<Esito>(ServiziRest.urlLoadClientConfigurations)
  }

}
