<div class="alternate-theme">
  <h2 i18n mat-dialog-title class="titolo-dialog">{{data.titolo}}</h2>
  <mat-dialog-content>
    <div class="centra-form">
      <div style="margin-top: 20px;">
        <mat-form-field color="accent" style="display: block;width:95%;">
          <mat-select [(ngModel)]="odvSelezionato" [placeholder]="data.etichetta">
            <mat-option *ngFor="
                  let odv of data.odvs" (onSelectionChange)="
                  onGruppoSelezionato(
                    $event,
                    odv
                  )
                " [value]="odv">
              {{ odv.nomeOdv }}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button i18n mat-button mat-dialog-close class="margin-auto" [disabled]="odvSelezionato === undefined" cdkFocusInitial
      (click)="okClick()">Ok</button>
    <button i18n mat-button mat-dialog-close class="margin-auto" (click)="annulla()">Annulla</button>
  </mat-dialog-actions>
</div>
