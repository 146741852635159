import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { BehaviorSubject, forkJoin, Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { ColorCombination } from '../modello/color-combination'
import { Configurazione } from '../modello/configurazione'
import { VersioneContenuto } from '../modello/versione-contenuto'
import { SessionData } from '../sessione/dati-sessione'
import { Esito, ESITO_OK } from '../utility/esito'
import {
  getOptimalTextColor,
  lightenColor,
  SottoTipoContenuto,
  TipoContenuto,
} from '../utility/utility'
import { ColorCombinationService } from './color-combination.service'
import { ConfigurazioneService } from './configurazione.service'
import { ContenutiService } from './contenuti.service'

@Injectable({
  providedIn: 'root',
})
export class SessionDataService {
  constructor(
    private contenutiService: ContenutiService,
    private colorService: ColorCombinationService,
    private configurazioneService: ConfigurazioneService,
    private sessionData: SessionData,
    private snackBar: MatSnackBar
  ) {}

  // dichiara un subject per gli aggiornamenti dei dati di sessione
  public aggiornamentoDatiSessione = new BehaviorSubject<boolean>(null)

  initialize() {
    this.configurazioneService.recuperaConfigurazione()
      .pipe(
        map((esito: any) => {
          if (ESITO_OK === esito.esito) {
            this.sessionData.configurazione = (JSON.parse(esito.payload) as Configurazione[])[0]
            return this.sessionData.configurazione
          } else {
            throw new Error(esito.payload)
          }
        })
      ).subscribe()
    // ricava la lingua dall'url del documento corrente
    const lingua = window.location.href.split('/')[3]

    // Creo un array per contenere le osservabili
    const observables = []

    let logoCliente: VersioneContenuto

    // carica il logo del cliente
    const logoClienteObservable =
    this.contenutiService
      .getVersioneContenuto(
        TipoContenuto.DOCUMENTO,
        SottoTipoContenuto.LOGO_CLIENTE,
        'all'
      )

    let logoSecure: VersioneContenuto

    // carica il logo Secure Blowing
    const logoSecureObservable =
    this.contenutiService
      .getVersioneContenuto(
        TipoContenuto.DOCUMENTO,
        SottoTipoContenuto.LOGO_SECURE,
        'all'
      )

    // carica i colori del sito
    const coloriSitoObservable =
    this.colorService.getDefaultColorCombination()

    observables.push(logoClienteObservable, logoSecureObservable, coloriSitoObservable)

    forkJoin(observables).subscribe(([logoClienteResult, logoSecureResult, coloriSitoResult]: Esito[]) => {
      // Questo blocco verrà eseguito solo quando tutte le chiamate asincrone sono state completate
      // Qui puoi gestire i risultati delle chiamate asincrone. Ad esempio:

      // Elabora logoClienteResult
      if (logoClienteResult.esito === ESITO_OK) {
        const versioneContenuto = JSON.parse(
          logoClienteResult.payload
        ) as VersioneContenuto
        logoCliente = versioneContenuto
        // ora scarica il file
        if (
          versioneContenuto.url !== null &&
          versioneContenuto.url !== '' &&
          versioneContenuto.url !== undefined &&
          versioneContenuto.url !== 'null'
        ) {
          this.contenutiService
            .downloadFile(versioneContenuto.url)
            .subscribe((fileBlob) => {
              // Create a new File instance
              const file = new File([fileBlob], logoCliente['url'], {
                type: fileBlob.type,
              })

              logoCliente['file'] = file

              // valorizza tipo con il tipo del file
              logoCliente.tipo = file.type

              // inserisco this.logoCliente nel SessionData ma come nuovo oggetto
              this.sessionData.logoCliente = logoCliente

              // Converting Blob to Base64
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onloadend = () => {
                const base64data = reader.result
                logoCliente.base64 = base64data as string
              }
            })
        }
      }

      // Elabora logoSecureResult
      if (logoSecureResult.esito === ESITO_OK) {
        const versioneContenuto = JSON.parse(
          logoSecureResult.payload
        ) as VersioneContenuto
        logoSecure = versioneContenuto
        // ora scarica il file
        if (
          versioneContenuto.url !== null &&
          versioneContenuto.url !== '' &&
          versioneContenuto.url !== undefined &&
          versioneContenuto.url !== 'null'
        ) {
          this.contenutiService
            .downloadFile(versioneContenuto.url)
            .subscribe((fileBlob) => {
              // Create a new File instance
              const file = new File([fileBlob], logoSecure['url'], {
                type: fileBlob.type,
              })

              logoSecure['file'] = file

              // valorizza tipo con il tipo del file
              logoSecure.tipo = file.type

              // inserisco this.logoSecure nel SessionData ma come nuovo oggetto
              this.sessionData.logoSecure = logoSecure

              // Converting Blob to Base64
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onloadend = () => {
                const base64data = reader.result
                logoSecure.base64 = base64data as string
              }
            })
        }
      }

      // Elabora coloriSitoResult
      if (coloriSitoResult.esito === ESITO_OK) {
        this.sessionData.colorCombo =
        coloriSitoResult.payload === undefined
            ? new ColorCombination()
            : (JSON.parse(coloriSitoResult.payload) as ColorCombination)
        if (
          this.sessionData.colorCombo.altezzaLogoCliente === undefined ||
          this.sessionData.colorCombo.altezzaLogoCliente === null ||
          this.sessionData.colorCombo.altezzaLogoCliente === 0
        ) {
          this.sessionData.colorCombo.altezzaLogoCliente = 80
        }

        if (
          this.sessionData.colorCombo.altezzaLogoSecure === undefined ||
          this.sessionData.colorCombo.altezzaLogoSecure === null ||
          this.sessionData.colorCombo.altezzaLogoSecure === 0
        ) {
          this.sessionData.colorCombo.altezzaLogoSecure = 80
        }
        if (this.sessionData.colorCombo.id !== undefined) {
          document.documentElement.style.setProperty(
            '--colore-riferimento',
            this.sessionData.colorCombo.coloreRiferimento
          )
          document.documentElement.style.setProperty(
            '--colore-testo',
            this.sessionData.colorCombo.coloreTesto
          )
          document.documentElement.style.setProperty(
            '--colore-testo-chiaro',
            this.sessionData.colorCombo.coloreTestoChiaro
          )
          document.documentElement.style.setProperty(
            '--colore-background-chiaro',
            this.sessionData.colorCombo.coloreBackgroundChiaro
          )
          document.documentElement.style.setProperty(
            '--colore-background-toolbar',
            this.sessionData.colorCombo.coloreBackgroundToolbar
          )
          document.documentElement.style.setProperty(
            '--colore-testo-toolbar',
            this.sessionData.colorCombo.coloreTestoToolbar
          )
          document.documentElement.style.setProperty(
            '--colore-background',
            this.sessionData.colorCombo.coloreBackground
          )
          document.documentElement.style.setProperty(
            '--colore-bottone-disabilitato',
            lightenColor(this.sessionData.colorCombo.coloreBackground)
          )
          document.documentElement.style.setProperty(
            '--colore-background-contrasto',
            getOptimalTextColor(this.sessionData.colorCombo.coloreBackground)
          )
          document.documentElement.style.setProperty(
            '--colore-sfondo-testata-tabelle',
            this.sessionData.colorCombo.coloreSfondoTestataTabelle
          )
          document.documentElement.style.setProperty(
            '--colore-testo-testata-tabella',
            this.sessionData.colorCombo.coloreTestoTestataTabella
          )
          document.documentElement.style.setProperty(
            '--colore-testo-toggle',
            this.sessionData.colorCombo.coloreTestoToggle
          )
          document.documentElement.style.setProperty(
            '--colore-testo-toggle-checked',
            this.sessionData.colorCombo.coloreTestoToggleChecked
          )
          document.documentElement.style.setProperty(
            '--colore-sfondo-nuvoletta-sinistra',
            this.sessionData.colorCombo.coloreSfondoNuvolettaSinistra
          )
          document.documentElement.style.setProperty(
            '--colore-testo-chat-sinistra',
            getOptimalTextColor(this.sessionData.colorCombo.coloreSfondoNuvolettaSinistra)
          )
          document.documentElement.style.setProperty(
            '--colore-sfondo-nuvoletta-destra',
            this.sessionData.colorCombo.coloreSfondoNuvolettaDestra
          )
          document.documentElement.style.setProperty(
            '--colore-testo-chat-destra',
            getOptimalTextColor(this.sessionData.colorCombo.coloreSfondoNuvolettaDestra)
          )
          document.documentElement.style.setProperty(
            '--altezza-logo-secure',
            this.sessionData.colorCombo.altezzaLogoSecure + 'px'
          )
          document.documentElement.style.setProperty(
            '--altezza-logo-cliente',
            this.sessionData.colorCombo.altezzaLogoCliente + 'px'
          )
          document.documentElement.style.setProperty(
            '--colore-testo-bottoni',
            this.sessionData.colorCombo.coloreTestoBottoni
          )
          document.documentElement.style.setProperty(
            '--colore-bordo-selettore-lingua',
            this.sessionData.colorCombo.coloreBordoSelettoreLingua
          )
          // valorizza filtro svg
          // ma prima ricavo l'rgb dalla notazione esadecimale
        }
      } else {
        this.snackBar.open(
          $localize`Errore nel recupero della combinazione di colori di default`,
          $localize`Chiudi`,
          {
            duration: 4000,
          }
        )
      }

      this.aggiornamentoDatiSessione.next(true)
    })
  }
}
