import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../servizi/auth.service';
import { SessionData } from '../../sessione/dati-sessione';
import { ESITO_OK } from '../../utility/esito';

@Component({
  selector: 'app-verifica-email',
  templateUrl: './verifica-email.component.html',
  styleUrls: ['./verifica-email.component.css']
})
export class VerificaEmailComponent implements OnInit, AfterViewInit {
  tokenVerificaEmail: string;
  emailVerificata: boolean = false;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar,
    private sessionData: SessionData,) { }

  ngOnInit(): void {
    this.tokenVerificaEmail = this.route.snapshot.queryParamMap.get('verificaEmail')
    if (this.tokenVerificaEmail !== '' && this.tokenVerificaEmail !== undefined && this.tokenVerificaEmail !== null) {
      this.verificaEmail()
    }

    this.route.data
      .subscribe(
        data => {
          // console.log(data);
          const configurazione = data['configurazione']
          if (configurazione) {
            this.sessionData.configurazione = configurazione
          } else {
            console.error(data)
            this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
          }
          return
        }
      )
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.

  }

  verificaEmail() {
    this.authService.verificaEmail(this.tokenVerificaEmail).
      then(esito => {
        if (ESITO_OK === +esito.esito) {
          this.emailVerificata = true
        } else {
          this.snackBar.open($localize `Errore nella verifica dell\'email. ` + esito['payload'], null, {
            duration: 8000,
          })
        }
      })
      .catch(err => {
        this.snackBar.open($localize `Errore nella verifica dell\'email. ` + err.toString(), null, {
          duration: 8000,
        })
      })
  }

  getLogoCliente() {
    return (this.sessionData.logoCliente !== undefined && this.sessionData.logoCliente.base64 !== undefined ?
      this.sessionData.logoCliente.base64 : this.getLogoSecure())
  }

  getLogoSecure() {
    return (this.sessionData.logoSecure !== undefined && this.sessionData.logoSecure.base64 !== undefined ?
      this.sessionData.logoSecure.base64 : '/assets/img/logo-secure.png')
  }


  backToMainLogin() {
    this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
  }

}
