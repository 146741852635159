import { DirittiSegnalazione } from './diritti-segnalazione'
import { OscuramentoDatiManifestamenteNonNecessari } from './oscuramento-dati-manifestamente-non-necessari'
import { Segnalazione } from './segnalazione'

export class Omissis {
  id: number
  campo: string
  omissis: string
  dirittiSegnalazione: DirittiSegnalazione
  segnalazione: Segnalazione
  idEntita: number
  tutto: boolean
  progressivo: number
  approvato: boolean
  oscuramentoDatiManifestamenteNonNecessari: OscuramentoDatiManifestamenteNonNecessari
  oscuramento: boolean

}
