export const ESITO_OK = 0
export const ESITO_KO = -1
export const ESITO_IN_PROGRESS = -2
export const ESITO_UTENTE_ELIMINATO = 1
export const ESITO_UTENTE_DISABILITATO = 2

export class Esito {
    payload: string
    esito: number
    descrizioneEsito: string
    static ESISTE_GIA_UN_PROCESSO_DI_OSCURAMENTO_IN_CORSO = 1
    static DIRITTO_SEGNALAZIONE_NON_SCELTO_DA_SEGNALANTE = 2
    static DIRITTO_SEGNALAZIONE_CANCELLATO = 3
    static DIRITTO_SEGNALAZIONE_ODV_DESTINATARIO_IN_CONFLITTO_DI_INTERESSI = 4
    static ERRORE_MFA = 5
    static UTENTE_INIBITO = 6
    static NON_AUTORIZZATO = 7
    static TOKEN_NON_VALIDO = 8
    static TOKEN_SCADUTO = 9
}
