<div style="width:100%;">
  <span>
    <mat-button-toggle-group style="z-index:10" multiple name="filtroStati" aria-label="Font Style">
      <mat-button-toggle class="bottone-piccolo" #bozza default value="bozza"
        (click)="aggiornaFiltro('1')" i18n>Bozza</mat-button-toggle>
      <mat-button-toggle class="bottone-piccolo" #nessunaLavorazione default value="nessunaLavorazione"
        (click)="aggiornaFiltro('2')" i18n>Segn. ricevuta</mat-button-toggle>
      <mat-button-toggle class="bottone-piccolo" #lavorazioniInCorso default value="lavorazioniInCorso"
        (click)="aggiornaFiltro('3')" i18n>Lavorazioni in corso</mat-button-toggle>
      <mat-button-toggle class="bottone-piccolo" #alcuneLavorazioniChiuse default value="alcuneLavorazioniChiuse"
        (click)="aggiornaFiltro('4')" i18n>Alcune lavorazioni chiuse</mat-button-toggle>
      <mat-button-toggle class="bottone-piccolo" #lavorazioniChiuse default value="lavorazioniChiuse"
        (click)="aggiornaFiltro('5')" i18n>Tutte le lavorazioni chiuse</mat-button-toggle>
      <mat-button-toggle class="bottone-piccolo" #ritirata default value="ritirata"
        (click)="aggiornaFiltro('6')" i18n>Ritirata</mat-button-toggle>
    </mat-button-toggle-group>
  </span>
</div>
