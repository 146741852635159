import { PitchShifter } from 'soundtouchjs'
import * as lamejs from 'lamejs'

export async function modificaPitchAudio(arrayBuffer, config): Promise<Blob> {
  const sampleRate = config.sampleRate || 44100
  const pitch = config.pitch || 1

  const audioCtx = new AudioContext({ sampleRate })

  // converte il file audio in un array di dati
  const decodedAudio = await audioCtx.decodeAudioData(arrayBuffer)
  const campioniNonSilenzionsiPre = percentageOfNonSilentSamples(decodedAudio)
  console.log('Percentuale di campioni non silenziosi prima della modifica:', campioniNonSilenzionsiPre)

  const durata = decodedAudio.duration
  const maxAttempts = 10 // Numero massimo di tentativi
  let attemptCount = 0 // Contatore dei tentativi
  const processAudio = async (resolve) => {

    attemptCount++

    const offlineCtx = new OfflineAudioContext(
      2,
      sampleRate * durata,
      sampleRate
    )

    const myBuffer = decodedAudio
    const shifter = new PitchShifter(offlineCtx, myBuffer, 1024)

    offlineCtx.startRendering().then(async (renderedBuffer) => {
      console.log('Rendering completed successfully')

      const sampleRate = renderedBuffer.sampleRate
      const numSamples = durata * sampleRate
      const audioBuffer = audioCtx.createBuffer(
        renderedBuffer.numberOfChannels,
        numSamples,
        sampleRate
      )

      for (let i = 0; i < renderedBuffer.numberOfChannels; i++) {
        const channelData = renderedBuffer.getChannelData(i)
        const newChannelData = audioBuffer.getChannelData(i)
        for (let j = 0; j < numSamples; j++) {
          newChannelData[j] = channelData[j]
        }
      }

      applyCompressor(audioBuffer)
      normalizeAudioBuffer(audioBuffer)

      const campioniNonSilenziosiPost = percentageOfNonSilentSamples(audioBuffer)
      console.log('Percentuale di campioni non silenziosi dopo la modifica:', campioniNonSilenziosiPost)

      const difference = Math.abs(campioniNonSilenzionsiPre - campioniNonSilenziosiPost)

      if (difference < 40 || attemptCount > maxAttempts) {
        const blob = audioBufferToWav(audioBuffer)

        resolve(blob)
        audioCtx.destination.disconnect()
        audioCtx.close()
      } else {
        // Ripeti il processo se la differenza non è accettabile
        processAudio(resolve)
      }
    })

    shifter.tempo = 1
    shifter.pitch = pitch

    const play = function () {
      shifter.connect(offlineCtx.destination)
    }

    play()
  }

  return new Promise((resolve) => {
    processAudio(resolve)
  })
}


function percentageOfNonSilentSamples(audioBuffer) {
  const silenceThreshold = 0.001 // Soglia per determinare se un campione è considerato silenzioso

  let nonSilentSamples = 0
  let totalSamples = 0

  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const channelData = audioBuffer.getChannelData(channel)
    totalSamples += channelData.length
    for (let i = 0; i < channelData.length; i++) {
      if (Math.abs(channelData[i]) > silenceThreshold) {
        nonSilentSamples++
      }
    }
  }

  const percentage = (nonSilentSamples / totalSamples) * 100
  return percentage
}

function bufferCopy(srcBuffer) {
  const dstBuffer = new ArrayBuffer(srcBuffer.byteLength)
  const srcUint8Array = new Uint8Array(srcBuffer)
  const dstUint8Array = new Uint8Array(dstBuffer)
  dstUint8Array.set(srcUint8Array)
  return dstBuffer
}

export async function applyCompressor(audioBuffer) {
  const offlineAudioCtx = new OfflineAudioContext(
    audioBuffer.numberOfChannels,
    audioBuffer.length,
    audioBuffer.sampleRate
  )

  const source = offlineAudioCtx.createBufferSource()
  source.buffer = audioBuffer

  const compressor = offlineAudioCtx.createDynamicsCompressor()
  compressor.threshold.value = -50
  compressor.knee.value = 40
  compressor.ratio.value = 12
  compressor.attack.value = 0
  compressor.release.value = 0.5

  source.connect(compressor)
  compressor.connect(offlineAudioCtx.destination)

  source.start(0)
  const processedBuffer = await offlineAudioCtx.startRendering()
  return processedBuffer
}

function normalizeAudioBuffer(audioBuffer) {
  let max = 0
  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const channelData = audioBuffer.getChannelData(channel)
    for (let i = 0; i < channelData.length; i++) {
      max = Math.max(max, Math.abs(channelData[i]))
    }
  }

  const gain = 1 / max
  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const channelData = audioBuffer.getChannelData(channel)
    for (let i = 0; i < channelData.length; i++) {
      channelData[i] *= gain
    }
  }
}

export function audioBufferToWav(aBuffer) {
  let numOfChan = aBuffer.numberOfChannels,
    btwLength = aBuffer.length * numOfChan * 2 + 44,
    btwArrBuff = new ArrayBuffer(btwLength),
    btwView = new DataView(btwArrBuff),
    btwChnls = [],
    btwIndex,
    btwSample,
    btwOffset = 0,
    btwPos = 0
  setUint32(0x46464952) // "RIFF"
  setUint32(btwLength - 8) // file length - 8
  setUint32(0x45564157) // "WAVE"
  setUint32(0x20746d66) // "fmt " chunk
  setUint32(16) // length = 16
  setUint16(1) // PCM (uncompressed)
  setUint16(numOfChan)
  setUint32(aBuffer.sampleRate)
  setUint32(aBuffer.sampleRate * 2 * numOfChan) // avg. bytes/sec
  setUint16(numOfChan * 2) // block-align
  setUint16(16) // 16-bit
  setUint32(0x61746164) // "data" - chunk
  setUint32(btwLength - btwPos - 4) // chunk length

  for (btwIndex = 0; btwIndex < aBuffer.numberOfChannels; btwIndex++) {
btwChnls.push(aBuffer.getChannelData(btwIndex))
}

  while (btwPos < btwLength) {
    for (btwIndex = 0; btwIndex < numOfChan; btwIndex++) {
      // interleave btwChnls
      btwSample = Math.max(-1, Math.min(1, btwChnls[btwIndex][btwOffset])) // clamp
      btwSample =
        (0.5 + btwSample < 0 ? btwSample * 32768 : btwSample * 32767) | 0 // scale to 16-bit signed int
      btwView.setInt16(btwPos, btwSample, true) // write 16-bit sample
      btwPos += 2
    }
    btwOffset++ // next source sample
  }

  const wavHdr = lamejs.WavHeader.readHeader(new DataView(btwArrBuff))

  // Stereo
  const data = new Int16Array(
    btwArrBuff,
    wavHdr.dataOffset,
    wavHdr.dataLen / 2
  )
  const leftData = []
  const rightData = []
  for (let i = 0; i < data.length; i += 2) {
    leftData.push(data[i])
    rightData.push(data[i + 1])
  }
  const left = new Int16Array(leftData)
  const right = new Int16Array(rightData)

  if (true) {
    // STEREO
    if (wavHdr.channels === 2) {
return wavToMp3(wavHdr.channels, wavHdr.sampleRate, left, right)
}
    // MONO
    else if (wavHdr.channels === 1) {
return wavToMp3(wavHdr.channels, wavHdr.sampleRate, data)
}
  } else {
return new Blob([btwArrBuff], { type: 'audio/wav' })
}

  function setUint16(data) {
    btwView.setUint16(btwPos, data, true)
    btwPos += 2
  }

  function setUint32(data) {
    btwView.setUint32(btwPos, data, true)
    btwPos += 4
  }
}

export function wavToMp3(channels, sampleRate, left, right = null) {
  const buffer = []
  const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128)
  let remaining = left.length
  const samplesPerFrame = 1152

  for (let i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
    if (!right) {
      const mono = left.subarray(i, i + samplesPerFrame)
      var mp3buf = mp3enc.encodeBuffer(mono)
    } else {
      const leftChunk = left.subarray(i, i + samplesPerFrame)
      const rightChunk = right.subarray(i, i + samplesPerFrame)
      var mp3buf = mp3enc.encodeBuffer(leftChunk, rightChunk)
    }
    if (mp3buf.length > 0) {
      buffer.push(mp3buf) // new Int8Array(mp3buf));
    }
    remaining -= samplesPerFrame
  }
  const d = mp3enc.flush()
  if (d.length > 0) {
    buffer.push(new Int8Array(d))
  }

  const mp3Blob = new Blob(buffer, { type: 'audio/mp3' })
  // var bUrl = window.URL.createObjectURL(mp3Blob);

  // send the download link to the console
  // console.log('mp3 download:', bUrl);
  return mp3Blob
}

export async function convertBlobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      resolve(fileReader.result as ArrayBuffer)
    }
    fileReader.onerror = reject
    fileReader.readAsArrayBuffer(blob)
  })
}

export async function convertArrayBufferToBlob(
  arrayBuffer: ArrayBuffer
): Promise<Blob> {
  return new Promise((resolve) => {
    const blob = new Blob([arrayBuffer])
    resolve(blob)
  })
}
