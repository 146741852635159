import { Messaggio } from './messaggio'

import { Chat } from './chat'
import { Informativa } from './informativa'

export const TIPO_INFORMATIVA = 'INFORMATIVA'
export const TIPO_ALLEGATO = 'ALLEGATO'

export enum TipoDocumento { TIPO_INFORMATIVA, TIPO_ALLEGATO };
export class Documento {
    constructor(documento: string, nome: string, tipo: string,
      descrizione?: string, hash?: string) {
/*         if (!this.segnalazioni) {
            this.segnalazioni = new Array<Segnalazione>();
        }
        this.segnalazioni.push(segnalazione); */
        this.nome = nome
        this.tipo = tipo
        this.base64 = documento
        this.descrizione = descrizione
        this.hash = hash

    }

    // segnalazioni: Segnalazione[];

    messaggi: Messaggio[]

    informativa: Informativa

    nome: string

    tipo: string

    descrizione: string

    id: string

    base64: string

    hash: string

    allegatoAlleChat: Chat[]

    arrayBuffer: string | ArrayBuffer

}
