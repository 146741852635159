import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Odv } from '../../../modello/odv'
import { DialogComponent } from '../dialog.component'

@Component({
  selector: 'app-dialog-selezione-gruppo',
  templateUrl: './dialog-selezione-gruppo.component.html',
  styleUrls: ['./dialog-selezione-gruppo.component.scss'],
})
export class DialogSelezioneGruppoComponent {
  odvSelezionato: Odv
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { odvs: Odv[]; titolo: string; etichetta: string }
  ) {}

  onGruppoSelezionato(event, odv: Odv) {
    if (event.source.selected) {
      this.odvSelezionato = odv
    }
  }
  annulla(): void {
    this.dialogRef.close(false)
  }

  okClick(): void {
    this.dialogRef.close(this.odvSelezionato)
  }
}
