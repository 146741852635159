import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { DialogComponent } from '../componenti/dialog/dialog.component'


@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  confirm(title: string, question: string): Promise<boolean> {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true
    dialogConfig.data = { title: title, question: question,  }

    const dialogRef = this.dialog.open(DialogComponent, dialogConfig)

    return dialogRef.afterClosed().toPromise()
  }
}
