import { Informativa } from './informativa'
import { Persona } from './persona'
import { Segnalazione } from './segnalazione'
import { OdvAzienda } from './odv-azienda'

export class Azienda {

    id: number

    nomeAzienda: string

    persone?: Persona[]

    odvAzienda: OdvAzienda[]

    informativa?: Informativa

    segnalazioni: Segnalazione[]

    progressivoCorrente: number

    // implementa il toString()
    toString(): string {
        return this.nomeAzienda
    }
}
