<div class="alternate-theme">
  <div i18n mat-dialog-title class="text-center">Consenso per la Registrazione Audio</div>
  <mat-dialog-content class="flex-column flex-gap-20"
  [innerHTML]="messaggio">
  </mat-dialog-content>
  <mat-dialog-actions class="flex-row flex-center-space-around">
    <button i18n mat-button mat-dialog-close>Nego il Consenso</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button i18n mat-button [mat-dialog-close]="true">Presto il consenso</button>
  </mat-dialog-actions>
</div>
