import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogComponent } from '../../componenti/dialog/dialog.component'

import { AuthService } from '../../servizi/auth.service'
import { SessionData } from '../../sessione/dati-sessione'
@Component({
  selector: 'app-chiedi-consenso-audio-dialog',
  templateUrl: 'chiedi-consenso-audio.html',
})
export class ChiediConsensoAudioDialogComponent {
  testoConsenso: any

  get messaggio() {
    return this.testoConsenso
  }

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any, private authService: AuthService, private sessionData: SessionData) {
      this.testoConsenso = data.testoConsenso
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.dialogRef.close('CANCEL')  // Chiude la dialog quando viene premuto il tasto ESC
      }
    })
  }

}
