import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { catchError, map, timeout } from 'rxjs/operators'
import { ServiziRest, _base64ToArrayBuffer } from '../utility/utility'
import { Esito } from '../utility/esito'

@Injectable({
  providedIn: 'root',
})
export class VideoService {

  private readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'video/mp4', 'Accept-Ranges': 'bytes' }),
    reportProgress: true,
  }

  constructor(private readonly http: HttpClient) {}

  public getVideoFile(videoKey: string): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'video/mp4', 'Accept-Ranges': 'bytes' }),
      reportProgress: true,
      responseType: 'text' as 'json',
    }
    const url = `${ServiziRest.urlVideoFile}/${videoKey}`
    return this.http.get(url, httpOptions).pipe(
      map((base64: string) => {
        // elimino il prefisso data:video/mp4;base64,
        let base64Data = base64.replace(/^data:video\/mp4;base64,/, '')
        // rimuovo anche l'eventuale prefisso audio/mp4;base64,
        // che si trova quando il video è stato convertito in mp4
        // da un altro formato
        base64Data = base64Data.replace(/^audio\/mp4;base64,/, '')
        const byteCharacters = atob(base64Data)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const videoBlob = new Blob([byteArray], { type: 'video/mp4' })
        return URL.createObjectURL(videoBlob)
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Error fetching video', error)
        throw error
      })
    )
  }

  landingPageVideoUpload(file: File): Observable<Esito> {
    const formData = new FormData()
    formData.append('file', file)

    return this.http
      .post<Esito>(ServiziRest.urlLandingPageVideoUpload, formData, { headers: new HttpHeaders({ timeout: `${120000}` }) })
      .pipe(timeout(300000)) // 300000 milliseconds = 5 minutes
  }

}
