import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Subscription } from 'rxjs'
import { AudioFile } from '../../modello/audio-file'
import { Segnalazione } from '../../modello/segnalazione'
import { AudioService } from '../../servizi/audio.service'
import { DialogService } from '../../servizi/dialog.service'
import { SegnalazioneService } from '../../servizi/segnalazione.service'
import { SessionData } from '../../sessione/dati-sessione'

import { Esito, ESITO_OK } from '../../utility/esito'

import { MatDialog } from '@angular/material/dialog'
import { ChiediConsensoAudioDialogComponent } from '../../dettaglio-segnalazione/dialogs/chiedi-consenso-audio'
import { openDialogGenerica, _base64ToArrayBuffer } from '../../utility/utility'
import {
  convertBlobToArrayBuffer,
  modificaPitchAudio,
} from '../../utility/audio-helper'
import { AuthService } from '../../servizi/auth.service'
import { ConfigurazioneService } from '../../servizi/configurazione.service'
import { map } from 'rxjs/operators'
import { isMembroDiGruppo } from '../../utility/helper-segnalazioni'

declare global {
  interface Navigator {
    getUserMedia?: (
      constraints: MediaStreamConstraints,
      successCallback: (stream: MediaStream) => void,
      errorCallback: (error: Error) => void
    ) => void;
  }
}

declare let MediaRecorder: any

@Component({
  selector: 'app-pitch-modification',
  templateUrl: './pitch-modification.component.html',
  styleUrls: ['./pitch-modification.component.scss'],
})
export class PitchModificationComponent
  implements OnInit, OnDestroy, OnChanges {
  screenWidth: number
  timeout: any

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth
    // aggiorna la classe dell'elemento HTML in base alla grandezza dello schermo
    if (this.screenWidth < 500) {
      // aggiungi la classe per schermi piccoli
    } else {
      // rimuovi la classe per schermi piccoli
    }
  }

  saveAudio = false

  mediaRecorder: any
  chunks = []
  pitchModdedChunks = [] // aggiunta della variabile per salvare i dati audio modificati
  rec
  audioFiles = []
  audioFormat: string
  durata: number

  sampleRate = 48000

  audioAvviato = false
  registrazioneInCorso: boolean
  audio: AudioFile
  newAudioBuffer: Blob
  sottoscrizioni: Subscription[]
  timer: NodeJS.Timeout
  seconds: any
  minutes: any
  hours: any
  playingAudio: HTMLAudioElement
  currentPosition = 0
  audioDuration = 0
  paused = true
  streamHandler: (stream: any) => void
  errorHandler: (err: any) => void
  originalAudio: Blob
  oldAnonima: boolean

  constructor(
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private audioService: AudioService,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private segnalazioniService: SegnalazioneService,
    private sessionData: SessionData,
    private authService: AuthService,
    private configurazioneService: ConfigurazioneService,
  ) {
    this.sottoscrizioni = []
    this.sottoscrizioni.push(
      segnalazioniService.notifichePerNuovaSegnalazioneSoloLocale.subscribe(
        (segnalazione) => {
          if (this.saveAudio) {
            this.segnalazione = segnalazione
            this.salvaAudio()
            this.saveAudio = false
          }
        }
      )
    )
  }

  // aggiunge un input readOnly
  @Input() readOnly: boolean
  @Input() segnalazione: Segnalazione
  @Input() audioCifrato: boolean
  @Input() audioBase64: string
  @Output() salvaSegnalazione = new EventEmitter<AudioFile>()
  @Output() registrazioneVocaleSalvata = new EventEmitter<AudioFile>()
  @Output() inizioProcessingRegistrazioneVocale = new EventEmitter<AudioFile>()
  @Output() fineProcessingRegistrazioneVocale = new EventEmitter<AudioFile>()
  @Output() registrazioneVocaleEliminata = new EventEmitter<AudioFile>()
  @Input() anonima: boolean
  @Output() registrazioneVocalePresente = new EventEmitter<void>()
  @Output() registrazioneVocaleNonPresente = new EventEmitter<void>()
  @Output() ricaricaSegnalazione = new EventEmitter<void>()

  statoInizialeAnonimato: boolean
  audioIniziale: AudioFile

  loading = false
  ngOnInit() {
    this.screenWidth = window.innerWidth
    this.statoInizialeAnonimato = this.segnalazione.anonima
    // console.log('ngOnInit', this.readOnly)
    // recupera il file audio
    if (this.segnalazione.hashAudio) {
      this.statoInizialeAnonimato = this.segnalazione.anonima
      this.audioService
        .getAudio(this.segnalazione.hashAudio)
        .subscribe(async (audioBlob: Blob) => {
          if (audioBlob) {
            if (!this.audio) {
              this.audio = new AudioFile()
              this.audio.segnalazione = this.segnalazione
            }
            this.audio.content = audioBlob as any
            const audioCtx = new AudioContext({ sampleRate: this.sampleRate })

            const arrayBuffer = await convertBlobToArrayBuffer(
              this.audio.content as any
            )
            this.registrazioneVocalePresente.emit()

            // converte il file audio in un array di dati
            const decodedAudio = await audioCtx.decodeAudioData(arrayBuffer)

            this.durata = decodedAudio.duration
            this.loading = false

            this.audioIniziale = this.audio
            if (this.audioCifrato && !this.segnalazione.anonima && !this.sessionData.configurazione.mascheraTuttiGliAudio) {
              this.aggiornaAnonima(true)
            }
            // this.aggiornaAnonima(this.audioCifrato)
          } else {
            this.audio = undefined
            this.registrazioneVocaleNonPresente.emit()
          }
        })
    }
    // se ho ricevuto un file audio in base64 lo assegno a this.newAudioBuffer
    if (this.audioBase64) {
      this.newAudioBuffer = new Blob([_base64ToArrayBuffer(this.audioBase64)], {
        type: 'audio/mp3',
      })
    }
    this.oldAnonima = this.segnalazione.anonima

    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth
      this.isSmallScreen = window.innerWidth < 500
    })

    // verifica se in localstorage c'è un file audio non salvato
    const audio = localStorage.getItem('audio')
    // se c'è lo assegna a this.audio tenendo presente che audio è in base64
    if (audio) {
      // elimina il file audio dal local storage
      localStorage.removeItem('audio')
      this.audio = new AudioFile()
      this.audio.content = _base64ToArrayBuffer(audio)
    }
    this.sottoscrizioni.push(
      this.segnalazioniService.salvaSegnalazioneInLocalStorage.subscribe(
        () => {
          // raccoglie i dati presenti nel form della segnalazione
          this.salvaAudioInLocalStorage()
        }
      )
    )
  }


  public isSmallScreen = window.innerWidth < 640
  ngOnDestroy() {
    this.sottoscrizioni.forEach((sottoscrizione) => {
      sottoscrizione.unsubscribe()
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.oldAnonima === undefined) {
      this.oldAnonima = this.segnalazione.anonima
      return
    }
    if (changes.anonima) {
      this.saveAudio = true
      const previousValue = this.oldAnonima
      const currentValue = changes.anonima.currentValue

      this.oldAnonima = this.segnalazione.anonima
      if (!this.audioIniziale) {
        this.statoInizialeAnonimato = currentValue
      }
      if (previousValue !== currentValue) {
        this.playingAudio = undefined
        // Esegui il tuo codice qui per gestire il cambiamento dell'input "anonima"
        console.log('Anonima è cambiato da', previousValue, 'a', currentValue)
        if (this.statoInizialeAnonimato === currentValue && this.audioIniziale) {
          this.audio = this.audioIniziale
          this.newAudioBuffer = undefined
        } else {
          this.aggiornaAnonima(currentValue)
        }
      }
    }
  }

  async aggiornaAnonima(nuovoValore: boolean) {
    await this.checkConfigurazione()

    if (this.sessionData.configurazione.mascheraTuttiGliAudio) {
      return
    }

    // Aggiorna il componente in base al nuovo valore di "anonima"
    const config2 = {
      sampleRate: 44100,
      pitch: nuovoValore
        ? this.sessionData.configurazione.pitchShifting
        : 1 / this.sessionData.configurazione.pitchShifting,
    }
    let arrayBuffer: ArrayBuffer
    if (this.audio) {
      arrayBuffer = await convertBlobToArrayBuffer(this.audio.content as any)
    }
    // const arrayBuffer = await file.arrayBuffer();
    if (!this.newAudioBuffer && arrayBuffer) {
      this.newAudioBuffer = await modificaPitchAudio(arrayBuffer, config2)
      this.registrazioneVocalePresente.emit()
    } else if (this.newAudioBuffer) {
      const temp = await convertBlobToArrayBuffer(this.newAudioBuffer as any)
      this.newAudioBuffer = await modificaPitchAudio(temp, config2)
    }

    // sostituisce il file audio con quello modificato
    // this.audio.content = modifiedAudioBlob as any;
  }

  async checkConfigurazione() {
    if (this.sessionData.configurazione === undefined) {
      this.sessionData.configurazione = await this.configurazioneService
        .recuperaConfigurazione()
        .pipe(
          map((esito) => {
            if (esito.esito === ESITO_OK) {
              return JSON.parse(esito.payload)[0]
            } else {
              throw new Error(esito.descrizioneEsito)
            }
          })
        )
        .toPromise()
    }
  }

  richiestaAvvioComponenteAudio() {
    // verifica se l'utente ha già prestato il consenso audio controllando la segnalazione
    if (this.segnalazione && this.segnalazione.consensoAudioPrestato) {
      this.avviaComponenteAudio()
    } else {
      // se non ha ancora prestato il consenso, chiedi il consenso
      this.prestaConsenso()
    }
  }

  prestaConsenso() {
    const dialogRef = this.dialog.open(ChiediConsensoAudioDialogComponent, {
      width: '800px',
      data: {
        testoConsenso: this.sessionData.configurazione.testoRichiestaConsensoAudio,
      },
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.segnalazione.consensoAudioPrestato = true
        this.segnalazione.dataConsensoAudio = new Date()
        this.avviaComponenteAudio()
      } else {
        this.segnalazione.consensoAudioPrestato = false
        this.segnalazione.dataConsensoAudio = undefined
      }
    })
  }

  confermaRitiroConsenso() {
    openDialogGenerica(
      this.dialog,
      $localize`Ritiro consenso`,
      '',
      $localize`Confermi di voler revocare il consenso prestato? Se confermi, la tua registrazione vocale sarà eliminata e non verrà trasmessa al Gestore della Segnalazione.`,
      () => this.ritiraConsensoAudio(),
      () => { }
    )
  }

  confermaDisabilitazioneAudio() {
    openDialogGenerica(
      this.dialog,
      $localize`Inibizione Ascolto`,
      '',
      $localize`Sei sicuro di volere rendere i vocali del Segnalante non più riproducibili?
      Hai attivato la procedura di trascrizione dei vocali già registrati prima di inibire definitivamente il loro ascolto?`,
      () => this.inibisciAscoltoAudio(),
      () => { }
    )
  }

  confermaRiabilitazioneAudio() {
    openDialogGenerica(
      this.dialog,
      $localize`Riabilitazione Ascolto`,
      '',
      $localize`Sei sicuro di volere rendere i vocali del Segnalante riproducibili?`,
      () => this.disinibisciAscoltoAudio(),
      () => { }
    )
  }

  ritiraConsensoAudio() {
    if (this.audio && this.segnalazione.id) {
      this.audioService
        .deleteAudio(this.segnalazione.id)
        .subscribe((esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            this.audio = undefined
            this.audioIniziale = undefined
            this.newAudioBuffer = undefined
            this.audioAvviato = false
            this.segnalazione.hashAudio = undefined
            this.segnalazione.consensoAudioPrestato = false
            this.segnalazione.dataConsensoAudio = undefined
            this.registrazioneVocaleNonPresente.emit()
            this.registrazioneVocaleEliminata.emit(this.audio)
          } else {
            this.snackBar.open(
              $localize`File audio non eliminato, Errore ${JSON.parse(
                esito.payload
              )}`,
              'OK',
              { duration: 2000 }
            )
          }
        })
    } else {
      this.audio = undefined
      this.audioAvviato = false
      this.newAudioBuffer = undefined
      this.audioIniziale = undefined
      this.segnalazione.consensoAudioPrestato = false
      this.segnalazione.dataConsensoAudio = undefined
      this.segnalazione.hashAudio = undefined
      this.registrazioneVocaleNonPresente.emit()
    }
  }

  inibisciAscoltoAudio() {
    if (this.audio && this.segnalazione.id) {
      this.audioService
        .inibisciAscoltoAudio(this.segnalazione.id)
        .subscribe((esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            this.audio = undefined
            this.newAudioBuffer = undefined
            this.segnalazione.consensoAudioPrestato = false
            this.segnalazione.dataConsensoAudio = null
            this.registrazioneVocaleNonPresente.emit()
            this.registrazioneVocaleEliminata.emit(this.audio)
          } else {
            this.snackBar.open(
              $localize`File audio non eliminato, Errore ${JSON.parse(
                esito.payload
              )}`,
              'OK',
              { duration: 2000 }
            )
          }
        })
    } else {
      this.audio = undefined
      this.newAudioBuffer = undefined
      this.segnalazione.consensoAudioPrestato = false
      this.segnalazione.dataConsensoAudio = undefined
      this.registrazioneVocaleNonPresente.emit()
    }
  }

  disinibisciAscoltoAudio() {
    if (this.segnalazione.id) {
      this.audioService
        .disinibisciAscoltoAudio(this.segnalazione.id)
        .subscribe((esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            this.ricaricaSegnalazione.emit()
          } else {
            this.snackBar.open(
              $localize`Non è stato possibile rispristinare il file audio. Errore ${JSON.parse(
                esito.payload
              )}`,
              'OK',
              { duration: 8000 }
            )
          }
        })
    }
  }

  avviaComponenteAudio() {
    this.streamHandler = (stream) => {
      console.log(stream)
      stream.onaddtrack = (e) => {
        console.log(e)
      }
      stream.onremovetrack = (e) => {
        console.log(e)
      }

      this.mediaRecorder = new MediaRecorder(stream)
      this.mediaRecorder.onstop = async () => {
        console.log('data available after MediaRecorder.stop() called.')

        const blob = new Blob(this.chunks, { type: 'audio/mp3' })

        // se la size è minore di 3000 non è valida
        if (blob.size < 3000) {
          this.snackBar.open(
            $localize`Registrazione vocale troppo breve`,
            'OK',
            { duration: 8000 }
          )
          this.fineProcessingRegistrazioneVocale.emit()
          this.playingAudio = undefined
          this.loading = false
          return
        }

        const arrayBuffer = await convertBlobToArrayBuffer(blob)

        // crea una copia dell'arrayBuffer
        const arrayBufferCopy = arrayBuffer.slice(0)

        this.newAudioBuffer = blob

        await this.checkConfigurazione()
        if (
          +this.sessionData.configurazione.pitchShifting !== 1 &&
          (this.segnalazione.anonima ||
            this.sessionData.configurazione.mascheraTuttiGliAudio)
        ) {
          const config = {
            sampleRate: 44100,
            pitch: this.sessionData.configurazione.pitchShifting,
          }
          this.newAudioBuffer = await modificaPitchAudio(arrayBuffer, config)
        }

        // se non esiste un audioIniziale valorizzato lo crea sulla base del nuovo audio
        if (!this.audioIniziale) {
          this.audioIniziale = new AudioFile()
          this.audioIniziale.content = this.newAudioBuffer as any
          this.audioIniziale.segnalazione = this.segnalazione
        }


        // converte il file audio in un array di dati
        const audioCtx = new AudioContext({ sampleRate: this.sampleRate })
        const decodedAudio = await audioCtx.decodeAudioData(arrayBufferCopy)
        this.durata = decodedAudio.duration

        this.registrazioneVocalePresente.emit()
        this.fineProcessingRegistrazioneVocale.emit()
        this.saveAudio = true
        this.playingAudio = undefined
        this.loading = false
      }
      this.mediaRecorder.ondataavailable = (e) => {
        this.chunks.push(e.data)
      }
    }

    this.errorHandler = (err) => {
      this.audioAvviato = false
      console.log(err)
      // mostra un messaggio d'errore spiegando come modificare l'impostazione sull'autorizzazione audio nel browser
      alert(
        $localize`Errore di cattura audio. Potresti aver negato l'accesso al microfono da parte di questo sito. ${err}\n\nPer sbloccare il microfono, devi controllare l'impostazione sull'autorizzazione audio nel browser.`
      )
    }

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // utilizza mediaDevices.getUserMedia()
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(this.streamHandler)
        .catch(this.errorHandler)
      this.audioAvviato = true
    } else if (navigator.getUserMedia) {
      // utilizza getUserMedia()
      navigator.getUserMedia(
        { audio: true },
        this.streamHandler,
        this.errorHandler
      )
      this.audioAvviato = true
    } else {
      // getUserMedia() o mediaDevices.getUserMedia() non disponibile
      console.log(
        'getUserMedia() o mediaDevices.getUserMedia() non disponibile'
      )
    }
  }

  // implementa una funzione che esegue il play dell'audio
  playAudio() {
    if (this.playingAudio && this.oldAnonima === this.segnalazione.anonima) {
      this.playingAudio.play()
      this.paused = false
      return
    }
    if (this.audio || this.newAudioBuffer) {
      this.playingAudio = new Audio()

      this.playingAudio.addEventListener('canplaythrough', () => {
        this.playingAudio.play()
      })
      const src =
        this.newAudioBuffer
          ? this.newAudioBuffer
          : this.audio.content
      this.playingAudio.src = URL.createObjectURL(src as any)

      this.playingAudio
        .play()
        .then(() => {
          // this.durata = this.playingAudio.duration;
        })
        .catch((onrejected) => {
          console.log(onrejected)
        })

      this.playingAudio.onended = () => {
        this.paused = true
      }

      this.playingAudio.addEventListener('timeupdate', () => {
        // forza l'aggiornamento della vista
        this.cd.detectChanges()
      })

      this.paused = false
    }
    this.oldAnonima = this.segnalazione.anonima
  }

  onSliderInput(event) {
    // se il value è infinito, non fare nulla
    if (event.value === Infinity) {
      return
    }
    // imposta il valore corrente dell'audio alla posizione dello slider
    this.playingAudio.currentTime = event.value as any
  }

  minuti(posizioneAudio) {
    return Math.floor(posizioneAudio / 60)
  }

  secondi(posizioneAudio) {
    return Math.floor(posizioneAudio % 60)
  }

  get posizioneAudio() {
    if (this.playingAudio) {
      return this.playingAudio.currentTime
    }
    return 0
  }

  pauseAudio() {
    if (this.playingAudio) {
      this.durata = this.playingAudio.duration
      this.playingAudio.pause()
      this.paused = true
    }
  }

  async scartaAudio() {
    // chiede conferma
    // chiedi se si vuole sovrascrivere il file audio non salvato

    const result = await this.dialogService.confirm(
      $localize`Conferma eliminazione`,
      $localize`Sei sicuro di voler eliminare il file audio?`
    )
    if (result) {
      this.audioIniziale = undefined
      if (this.newAudioBuffer) {
        this.newAudioBuffer = undefined
        if (!this.audio) {
          this.registrazioneVocaleNonPresente.emit()
          this.registrazioneVocaleEliminata.emit()
        }
      } else {
        this.audioService
          .deleteAudio(this.segnalazione.id)
          .subscribe((esito: Esito) => {
            if (esito.esito === ESITO_OK) {
              this.snackBar.open($localize`File audio eliminato dal DB`, 'OK', {
                duration: 2000,
              })
              this.audio = undefined
              this.audioIniziale = undefined
              this.audioAvviato = false
              this.newAudioBuffer = undefined
              this.registrazioneVocaleEliminata.emit()
            } else {
              this.snackBar.open(
                $localize`File audio non eliminato dal DB, Errore ${esito.descrizioneEsito}`,
                'OK',
                { duration: 4000 }
              )
            }
          })
      }
    } else {
      return
    }
  }

  salvaAudio() {
    try {
      if (this.newAudioBuffer || this.audio) {
        if (!this.audio || this.newAudioBuffer) {
          // istanzia un nuovo oggetto audio
          this.audio = new AudioFile()
        }
        this.audio.content = (
          // this.segnalazione.anonima ?
          this.newAudioBuffer
            ? this.newAudioBuffer
            : this.audio.content
          // : this.audio
          // ? this.audio.content
          // : this.newAudioBuffer
        ) as any
        this.audio.segnalazione = this.segnalazione
        // controlla se la segnalazione ha un id
        if (!this.segnalazione.id) {
          // salva la segnalazione
          this.salvaSegnalazione.emit(this.audio)
          return
        }

        // salva il file audio
        this.audioService
          .uploadAudio(this.audio.content as any, this.segnalazione.id)
          .subscribe((esito: Esito) => {
            if (esito.esito === ESITO_OK) {
              this.snackBar.open($localize`File audio salvato`, 'OK', {
                duration: 2000,
              })
              const result = JSON.parse(esito.payload)
              this.newAudioBuffer = undefined
              this.registrazioneVocaleSalvata.emit(result[0])
              this.registrazioneVocalePresente.emit()
            } else {
              this.snackBar.open(
                $localize`File audio non salvato, Errore ${esito.descrizioneEsito}`,
                'OK',
                { duration: 2000 }
              )
            }
            this.registrazioneVocaleSalvata.emit()
          })
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.registrazioneVocaleSalvata.emit()
      this.fineProcessingRegistrazioneVocale.emit()
    }
  }

  salvaAudioInLocalStorage() {
    try {
      if (this.newAudioBuffer || this.audio) {
        if (!this.audio || this.newAudioBuffer) {
          // istanzia un nuovo oggetto audio
          this.audio = new AudioFile()
        }
        this.audio.content = (
          // this.segnalazione.anonima ?
          this.newAudioBuffer
            ? this.newAudioBuffer
            : this.audio.content
          // : this.audio
          // ? this.audio.content
          // : this.newAudioBuffer
        ) as any
        this.audio.segnalazione = this.segnalazione

        // salva il file audio in locale
        if (this.audio.content) {
          // converto in base 64 audio.content
          const reader = new FileReader()
          reader.readAsDataURL(this.audio.content as any)
          reader.onloadend = () => {
            const content = reader.result
            // salva il file audio in locale
            localStorage.setItem('audio', content as any)
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  async startRecording() {
    // azzera i contatori
    this.seconds = 0
    this.minutes = 0
    this.hours = 0
    // inizia a contare il numero di secondi trascorsi
    this.timer = setInterval(() => {
      if (this.seconds === undefined) {
        this.seconds = 0
      }
      if (this.minutes === undefined) {
        this.minutes = 0
      }
      if (this.hours === undefined) {
        this.hours = 0
      }
      this.seconds++
      if (this.seconds === 60) {
        this.seconds = 0
        this.minutes++
      }
      if (this.minutes === 60) {
        this.minutes = 0
        this.hours++
      }
    }, 1000)

    if (this.newAudioBuffer || this.audioIniziale) {
      // chiedi se si vuole sovrascrivere il file audio non salvato
      const result = await this.dialogService.confirm(
        $localize`Conferma eliminazione`,
        $localize`Hai un file audio non salvato. Vuoi sovrascriverlo?`
      )
      if (result) {
        this.newAudioBuffer = undefined
        this.audioIniziale = undefined
        this.audio = undefined
      } else {
        return
      }
    }
    if (!this.audioAvviato) {
      this.avviaComponenteAudio()
      // attendi che il componente audio sia avviato
    }
    this.mediaRecorder.start()
    console.log(this.mediaRecorder.state)
    console.log('recorder started')
    this.registrazioneInCorso = true
    // avvia un timer per fermare la registrazione dopo un tempo di timeout massimo
    this.timeout = setTimeout(() => {
      console.log('timeout per la registrazione vocale raggiunto')
      this.stopRecording()
    }, 180000)
  }

  stopRecording() {
    console.log('stopRecording() called')
    try {
      // se il timer è attivo, lo disattivo
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.inizioProcessingRegistrazioneVocale.emit()
      this.loading = true
      // this.cd.detectChanges();
      this.registrazioneInCorso = false
      // azzera il timer
      clearInterval(this.timer)
      // console.log(this.mediaRecorder.state);
      console.log('recorder stopped')
      // chiude il media recorder
      this.mediaRecorder.stream.getAudioTracks().forEach((track) => {
        track.stop()
      })
      const audioTracks = this.mediaRecorder.stream.getAudioTracks()
      // // rimuovo tutti i tracks audio
      for (let i = 0; i < audioTracks.length; i++) {
        this.mediaRecorder.stream.removeTrack(audioTracks[i])
      }
      // verifico che il media recorder sia attivo

      if (this.mediaRecorder.state !== 'inactive') {
        // se il media recorder è in uno stato non valido, lo riavvio
        this.mediaRecorder.stop()
      }
      this.audioAvviato = false
      this.chunks = []
    } catch (error) {
      console.log(error)
    } finally {
      // this.loading = false;
    }
  }

  get odv() {
    return this.authService.getUser().odv
  }

  get odvDestinatario() {
    // verifica se l'utente corrente ha una membership in uno dei gruppi gestori destinatari della segnalazione
    if (this.segnalazione && this.authService.getUser().odv) {
      return this.segnalazione.dirittiSegnalazioneOdv.some(
        (dirittoSegnalazione) =>
          dirittoSegnalazione.sceltoDaSegnalante &&
          isMembroDiGruppo(
            this.authService.getUser(),
            dirittoSegnalazione.odvDestinatario
          )
      )
    }
  }

  get segnalante() {
    return (
      !this.authService.getUser().odv &&
      !this.authService.getUser().su &&
      !this.authService.getUser().custode
    )
  }
}
