<div class="form-group margin-top-20px theme">
  <mat-expansion-panel [expanded]="true" id="segnalato3" name="Segnalato 3" class="scheda-full-width">
    <mat-expansion-panel-header class="sottotestata-principale-dettagli-segnalazione">
      <mat-panel-title i18n>
        File Allegati
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="desktop-table">

      <mat-table #table [dataSource]="dataSourceDocumenti" style="position: relative; top:20px;" matSort>

        <mat-header-row *matHeaderRowDef="colonneVisualizzate"></mat-header-row>
        <mat-row *matRowDef="let documento; columns: colonneVisualizzate" class="pointer"
          (click)="selected(documento); $event.stopPropagation();"></mat-row>

        <ng-container matColumnDef="id">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="width-150px"> ID </mat-header-cell>
          <mat-cell *matCellDef="let documento" >
            <div *ngIf="!modalitaOmissis" (click)="visualizzaDocumento(documento); $event.stopPropagation();" >{{ documento.id }}</div>
            <div *ngIf="modalitaOmissis">
              <mat-chip-list style="width:100%; margin: auto auto auto 20px">
                <mat-chip [removable]="modalitaOmissis && documento.nome?.toString().trim()!== 'OMISSIS' && documento.nome?.toString().trim()!== '*OMISSIS*'"
                  (click)="documento.nome !== 'OMISSIS' && documento.nome !== '*OMISSIS*' ? toggleOmissis(documento.id) :
                  modalitaOscuramento ? toggleOmissis(documento.id):  nonPuoiGestireOmissis()"
                  [ngClass]="{'disabled-omissis': !isOmissis(documento.id) , 'enabled-omissis': isOmissis(documento.id) }">
                  <div>{{documento.id}} </div>
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nome">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Nome </mat-header-cell>
          <mat-cell *matCellDef="let documento">
            <input matInput *ngIf="!disabilitato " [(ngModel)]="documento.nome"
              (keydown)="salvaSuInvio($event, documento); $event.stopPropagation();" />
            <div *ngIf="disabilitato" style="width: 100%;">
              <div>{{ documento.nome }}</div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="descrizione" *ngIf="!isSmallScreen">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="width-full"> Descrizione </mat-header-cell>
          <mat-cell *matCellDef="let documento">
            <input matInput [(ngModel)]="documento.descrizione" *ngIf="!disabilitato"
              [disabled]="disabilitato" (keydown)="salvaSuInvio($event, documento); $event.stopPropagation();"
              (click)="$event.stopPropagation();" />
            <div *ngIf="disabilitato" style="width: 100%;">
              <div>{{ documento.descrizione }}
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container class="no-print" matColumnDef="azioni">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let documento">
            <span class="width-full"></span>
            <button *ngIf="!disabilitato && segnalazioneHaID()" mat-icon-button class="destra"
              (click)="salvaDocumentoButton(documento); $event.stopPropagation();">
              <mat-icon class="icona-riga-tabella" aria-label="salva documento">save</mat-icon>
            </button>
            <button *ngIf="!disabilitato && documento" mat-icon-button class="destra"
              (click)="openDialogConfermaEliminazioneDocumento(documento); $event.stopPropagation();">
              <mat-icon class="icona-riga-tabella" aria-label="cancella documento">delete</mat-icon>
            </button>
            <button *ngIf="documento && documento.hash" mat-icon-button class="destra"
              (click)="visualizzaDocumento(documento); $event.stopPropagation();">
              <mat-icon class="icona-riga-tabella" aria-label="scarica documento">remove_red_eye</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
      </mat-table>
      <mat-paginator class="no-print" #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
    <div class="mobile-list">
      <mat-list *ngFor="let documento of documenti">
        <mat-list-item>
          <div style="display: flex; flex-direction: column; width: 100%;">
            <div style="flex: 1;">
              <div *ngIf="!modalitaOmissis">
                <h3 i18n>ID: {{ documento.id }}</h3>
              </div>
              <div *ngIf="modalitaOmissis" class="flex-row">
                <h3 i18n>ID:</h3>
              <mat-chip-list style="width:100%; margin: auto auto auto 20px">
                <mat-chip [removable]="modalitaOmissis && documento.nome?.toString().trim()!== 'OMISSIS' && documento.nome !== '*OMISSIS*'"
                  (click)="documento.nome !== 'OMISSIS' && documento.nome !== '*OMISSIS*'? toggleOmissis(documento.id) :
                  modalitaOscuramento ? toggleOmissis(documento.id):  nonPuoiGestireOmissis()"
                  [ngClass]="{'disabled-omissis': !isOmissis(documento.id) , 'enabled-omissis': isOmissis(documento.id) }">
                  <div>{{documento.id}} </div>
                </mat-chip>
              </mat-chip-list>
              </div>

              <div style="display: flex;">
                <h3 style="min-width: 80px; margin:0px" i18n>Nome:</h3>
                <div style="flex: 1;">
                  <input matInput [(ngModel)]="documento.nome" [disabled]="disabilitato"
                    (keydown)="salvaSuInvio($event, documento); $event.stopPropagation();"
                    [hidden]="disabilitato || modalitaOmissis" />
                  <div [hidden]="!disabilitato" style="width: 100%;">
                    <div >{{ documento.nome }}</div>
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-direction: column; width: 100%;">
                <h3 style="min-width: 80px; margin:0px" i18n>Descrizione:</h3>
                <div style="flex: 1;">
                  <textarea maxlength="20000" matInput [(ngModel)]="documento.descrizione" rows="4" [disabled]="disabilitato"
                  placeholder="Inserisci una descrizione" i18n-placeholder
                    (keydown)="salvaSuInvio($event, documento); $event.stopPropagation();"
                    [hidden]="disabilitato || modalitaOmissis"></textarea>
                  <div [hidden]="!disabilitato" style="width: 100%;">
                    <div>{{ documento.descrizione }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: space-around; align-items: center; background-color: #f0f0f0;">
              <button *ngIf="!disabilitato && segnalazioneHaID()" mat-icon-button
                (click)="salvaDocumentoButton(documento); $event.stopPropagation();">
                <mat-icon class="icona-riga-tabella" aria-label="salva documento">save</mat-icon>
              </button>
              <button *ngIf="!disabilitato && documento" mat-icon-button
                (click)="openDialogConfermaEliminazioneDocumento(documento); $event.stopPropagation();">
                <mat-icon class="icona-riga-tabella" aria-label="cancella documento">delete</mat-icon>
              </button>
              <button *ngIf="documento && documento.hash" mat-icon-button
                (click)="visualizzaDocumento(documento); $event.stopPropagation();">
                <mat-icon class="icona-riga-tabella" aria-label="scarica documento">remove_red_eye</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
    </div>



    <div class="no-print" *ngIf="!disabilitato" style="margin-top: 20px">

      <!--form [formGroup]="formDoc" (ngSubmit)="onSubmit()" novalidate>

        <mat-form-field class="width-full"> -->

      <input id="fileupload" type="file" #file style="display: none" (change)="onFilesAdded($event.target.files);"
        multiple />
      <div>
        <mat-icon class="testo-accent">file_upload</mat-icon><label i18n for="fileupload"
          class="pointer testo-accent md-raised testo-label-scalabile">Seleziona File da allegare</label>
      </div>
      <div *ngIf="fileToUpload && fileToUpload.length>0" style="top:20px;height:150px!important; position: relative;"
        i18n>
        Elenco allegati messaggio:
        <table>
          <tr *ngFor="let allegato of fileToUpload; let last = last"> {{last ? scrollToBottom() : ''}}
            <td>
              {{allegato.name}}
            </td>
            <td>
              <button mat-icon-button class="alternate-theme" color="accent"
                (click)="eliminaAllegato(allegato); $event.stopPropagation();">
                <mat-icon aria-label="cancella allegato">delete</mat-icon>
              </button>
            </td>
          </tr>
        </table>
      </div>
      <!--
          <app-input-file #inputfile formControlName="files" i18n-placeholder placeholder="Seleziona file da allegare..."
            multiple (change)="handleFileInput($event.target.files)"></app-input-file>
          <mat-icon matSuffix>folder</mat-icon>
          <mat-error i18n *ngIf="formDoc.get('files').hasError('required')">
            Seleziona almeno un file
          </mat-error>
          <mat-error i18n *ngIf="formDoc.get('files').hasError('maxContentSize')">
            I file non possono superare la dimensione di {{formDoc.get('requiredfile')?.getError('maxContentSize').maxSize | byteFormat}}
            ({{formDoc.get('requiredfile')?.getError('maxContentSize').actualSize | byteFormat}}).
          </mat-error>
        </mat-form-field> -->

      <!-- </form>-->
    </div>
  </mat-expansion-panel>
</div>
