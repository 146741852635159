import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'


import { Base64 } from 'js-base64'
import { InformativaDialogComponent } from '../../gestione-aziende/informativa.dialog.component'
import { Azienda } from '../../modello/azienda'
import { Persona } from '../../modello/persona'
import { PinCode } from '../../modello/pin-code'
import { User } from '../../modello/user'
import { AuthService } from '../../servizi/auth.service'

import { SessionData } from '../../sessione/dati-sessione'
import { ESITO_OK } from '../../utility/esito'
import { getVersione } from '../../utility/utility'
import { CustomValidators } from '../../validators/CustomValidators'

@Component({
  selector: 'app-registrazione',
  templateUrl: './registrazione.component.html',
  styleUrls: ['./registrazione.component.css']
})
export class RegistrazioneComponent implements OnInit {

  pinCode: PinCode
  // tokenRichiestaPin: string;
  utenteConNuovoPin: User
  codiceFiscale: string
  testo: string
  utente: User
  registrationFomr: UntypedFormGroup
  cfForm: UntypedFormGroup
  // pincode: string;
  registrationForm: UntypedFormGroup
  recuperaPINForm: UntypedFormGroup
  primoAccesso: boolean
  codiceFiscaleVerificato: boolean
  informativaAccettata = false

  passwordDimenticata = false
  textOrPassword = 'password'
  azienda: Azienda
  persona: Persona

  registrazioneConEmail = false
  tokenVerificaEmail: string
  emailVerificata: boolean
  tokenRichiestaPin: string

  @ViewChild('inputIdUtente') userId: ElementRef

  constructor(public dialog: MatDialog, public authService: AuthService, private router: Router, private snackBar: MatSnackBar,
    // private recaptcha3: NgRecaptcha3Service,
    private sessionData: SessionData) {
    this.utente = new User()
    this.pinCode = new PinCode()
    this.azienda = new Azienda()

    this.utente.pinCode = this.pinCode

    this.createForm()



  }

  get idUtente() {
    return this.registrationForm.get('userId')
  }

  get configurazione() {
    return this.sessionData.configurazione
  }

  get versione(): string {
    return getVersione()
  }

  get modalitaPinUnico(): boolean {
    return this.sessionData.configurazione.modalitaPinUnicoPerSegnalazione
  }

  static areEqual(c: AbstractControl): ValidationErrors | null {
    const keys: string[] = Object.keys(c.value)
    for (const i in keys) {
      if (i !== '0' && c.value[keys[+i - 1]] !== c.value[keys[i]]) {
        return { 'identico': true }
      }
    }
  }

  ngOnInit() {
    // this.recaptcha3.init(environment.siteKeyCaptcha)
    this.authService.removeStoredCredentials()
    this.testo = ''
    this.emailVerificata = false
  }

  ngOnDestroy(): void {
    // this.recaptcha3.destroy()
  }

  verificaEmail() {

    this.authService.verificaEmail(this.tokenVerificaEmail).
      then(esito => {
        if (ESITO_OK === +esito.esito) {
          this.emailVerificata = true
        } else {
          this.snackBar.open($localize `Errore nella verifica dell\'email. ` + esito['payload'], null, {
            duration: 8000,
          })
        }
      })
      .catch(err => {
        this.snackBar.open($localize `Errore nella verifica dell\'email. ` + err.toString(), null, {
          duration: 8000,
        })
      })
  }

  registrati() {
    if (this.registrationForm.value['email']) {
      this.registrazioneConEmail = true
    }
    // this.recaptcha3.getToken({ action: 'registrazione' }).then(token => {
      this.authService.ottieniPrimoPin(this.registrationForm.value, this.token).then
        (esito => {
          if (+esito.esito === ESITO_OK) {
            const user = JSON.parse(esito.payload) as User
            this.utenteConNuovoPin = user
            this.primoAccesso = false

          } else {
            this.snackBar.open($localize `Errore nel recupero del nuovo PIN. ` + esito['payload'], null, {
              duration: 8000,
            })
          }
        })
        .catch(errore => {
          this.snackBar.open($localize `Errore nel recupero del nuovo PIN. ` + errore.toString(), null, {
            duration: 8000,
          })
        })
    // }).catch((error) => {
    //   console.log(error)
    //   this.snackBar.open($localize `Impossibile eseguire la registrazione. Rivolgersi al supporto. ', null, {
    //     duration: 8000,
    //   })
    // })
  }
  token(value: any, token: any) {
    throw new Error('Method not implemented.')
  }

  copiaPin() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.utenteConNuovoPin.pinCode.pincode))
      e.preventDefault()
      document.removeEventListener('copy', null)
    })
    document.execCommand('copy')

  }

  backLogin() {
    // console.log('Torno alla main login....');
    this.router.navigate(['/login'], {})
  }

  keyDownFunction(event) {
    if (event.keyCode === 13 && !this.registrationForm.invalid) {
      this.registrati()
      // rest of your code
    }
  }

  get dominiPermessi() {
    return this.sessionData.configurazione.dominiAziendali
  }


  openDialogInformativa(): void {
    const azienda = undefined
    const dialogRef = this.dialog.open(InformativaDialogComponent, {
      width: '800px',
      data: { testo: Base64.decode(azienda.informativa.testo), editable: false, showToolbar: false },
      disableClose: true,
      hasBackdrop: true
    })
    dialogRef.afterClosed().subscribe(() => {
      // console.log('returned value is: ' + JSON.stringify(result));
      // this.azienda.informativa.testo=result.data;
    })
  }

  onCaptchaResponse(event) {
    console.log('EVENT:')
    console.log(event)
  }

  private createForm() {
    this.registrationForm = new UntypedFormGroup({
      'userId': new UntypedFormControl('', Validators.required),
      'email': new UntypedFormControl('', this.configurazione.emailSegnalanteObbligatoria ? [CustomValidators.validateEmailPersonale(this.sessionData.configurazione?.dominiAziendali),
      CustomValidators.validaEmail,
      Validators.required] :
        [CustomValidators.validateEmailPersonale(this.sessionData.configurazione?.dominiAziendali),
        CustomValidators.validaEmail]),
      'ripetiEmail': new UntypedFormControl('', [CustomValidators.validateEmailPersonale(this.sessionData.configurazione?.dominiAziendali),
      CustomValidators.validaEmail]),
    }, this.modalitaPinUnico ? undefined : CustomValidators.matchingEmail('email', 'ripetiEmail'))
  }

  get emailIsRequired() {
    return this.sessionData.configurazione.emailSegnalanteObbligatoria
  }

}
