import { BannedPageComponent } from './banned-page/banned-page.component'
import { ConfigurazioneComponent } from './configurazione/configurazione.component'
import { ChatManagerComponent } from './chat-manager/chat-manager.component'
import { StatisticheComponent } from './statistiche/statistiche.component'
import { AuthGuard } from './servizi/auth-guard.service'
import { GestioneAziendeComponent } from './gestione-aziende/gestione-aziende.component'
import { DettaglioSegnalazioneComponent } from './dettaglio-segnalazione/dettaglio-segnalazione.component'
import { GestioneUtentiComponent } from './gestione-utenti/gestione-utenti.component'
import { HomeComponent } from './elenco-segnalazioni/elenco-segnalazioni.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './login/login.component'

import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard'
import { LandingPageComponent } from './landing-page/landing-page.component'

import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component'
import { RichiesteSbloccoComponent } from './richieste-sblocco/richieste-sblocco.component'
import { InformativeComponent } from './informative/informative.component'
import { SegnalanteGuard } from './servizi/segnalante-guard.service'
import { GestioneGruppiComponent } from './gestione-gruppi/gestione-gruppi.component'
import { RegistrazioneComponent } from './login/registrazione/registrazione.component'
import { RecuperoPasswordComponent } from './login/recupero-password/recupero-password.component'
import { segnalazioneResolver } from './dettaglio-segnalazione/segnalazione.resolver'

import { VerificaEmailComponent } from './login/verifica-email/verifica-email.component'

import { ElencoLavorazioniComponent } from './elenco-lavorazioni/elenco-lavorazioni/elenco-lavorazioni.component'
import { DettaglioLavorazioneComponent } from
'./elenco-lavorazioni/dettaglio-lavorazione/dettaglio-lavorazione/dettaglio-lavorazione.component'
import { LogOperativoListComponent } from './log-operativo-list/log-operativo-list.component'
import { CustodeRedirectGuard } from './servizi/custode-redirect.guard'
import { AttivazioneMfaComponent } from './login/attivazione-mfa/attivazione-mfa.component'
import { configurazioneResolver } from './servizi/configurazione.resolver'
import { sessionDataResolver } from './servizi/dati-sessione.resolver'
import { loginResolver } from './login/login-resolver.service'
import { configurazioneBanResolver } from './configurazione/configurazione-ban.resolver'

// import { ChatSegnalazioneComponent } from './chat-manager/chat-segnalazione/chat-segnalazione.component';

const routes: Routes = [
  { path: '', redirectTo: 'landing-page', pathMatch: 'full' },
  { path: 'landing-page', component: LandingPageComponent, resolve: { esito: loginResolver } },
  { path: 'login', component: LoginComponent, resolve: { esito: loginResolver } },
  { path: 'registrazione', component: RegistrazioneComponent },
  { path: 'recupero-password', component: RecuperoPasswordComponent },
  { path: 'verifica-email', component: VerificaEmailComponent, resolve: { configurazione: configurazioneResolver }, },
  { path: 'rigenera-mfa', component: AttivazioneMfaComponent, resolve: { configurazione: configurazioneResolver }, },
  // { path: 'registrazione', component: RegistrazioneComponent },
  { path: 'banned-page', component: BannedPageComponent },
  { path: 'cookies-policy', component: PrivacyPolicyComponent },
  // { path: 'login-anonima', component: LoginAnonimaComponent },
  // { path: 'login-anonima-richiesta-pin', component: LoginAnonimaRichiestaPinComponent },
  // { path: 'reset-pin', component: RecuperaPinComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard, SegnalanteGuard, CustodeRedirectGuard],
  resolve: { configurazione: configurazioneResolver }, },
  {
    path: 'segnalazione', component: DettaglioSegnalazioneComponent,
    resolve: { segnalazione: segnalazioneResolver, datiSessione: sessionDataResolver, configurazione: configurazioneResolver },
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard]
  },
  { path: 'lavorazioni', component: ElencoLavorazioniComponent, canActivate: [AuthGuard, SegnalanteGuard, ],
  resolve: { configurazione: configurazioneResolver }, },
  {
    path: 'lavorazione', component: DettaglioLavorazioneComponent,
    resolve: { datiSessione: sessionDataResolver, configurazione: configurazioneResolver},
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard]
  },
  // { path: 'chat', component: ChatSegnalazioneComponent, canActivate: [AuthGuard]},
  { path: 'utenti', component: GestioneUtentiComponent, canActivate: [AuthGuard, SegnalanteGuard, ],
  resolve: { configurazione: configurazioneResolver } },
  { path: 'gruppi', component: GestioneGruppiComponent, canActivate: [AuthGuard, SegnalanteGuard, ],
  resolve: { configurazione: configurazioneResolver } },
  { path: 'configurazione', component: ConfigurazioneComponent, canDeactivate: [CanDeactivateGuard],
  canActivate: [AuthGuard, SegnalanteGuard], resolve: { configurazione: configurazioneResolver,
    configurazioneBan: configurazioneBanResolver } },
  { path: 'aziende', component: GestioneAziendeComponent, canActivate: [AuthGuard, SegnalanteGuard, ],
  resolve: { configurazione: configurazioneResolver } },
  { path: 'statistiche', component: StatisticheComponent, canActivate: [AuthGuard, SegnalanteGuard],
  resolve: { datiSessione: sessionDataResolver, configurazione: configurazioneResolver }, },
  { path: 'chat-manager', component: ChatManagerComponent, canActivate: [AuthGuard],
  resolve: { datiSessione: sessionDataResolver, segnalazione: segnalazioneResolver, configurazione: configurazioneResolver } },
  { path: 'richieste-sblocco', component: RichiesteSbloccoComponent, canActivate: [AuthGuard, SegnalanteGuard, ],
  resolve: { configurazione: configurazioneResolver } },
  { path: 'informative', component: InformativeComponent, canActivate: [AuthGuard, SegnalanteGuard, ],
  resolve: { configurazione: configurazioneResolver } },
  { path: 'log-operazioni', component: LogOperativoListComponent, canActivate: [AuthGuard, SegnalanteGuard, ],
  resolve: { configurazione: configurazioneResolver } },
]

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload', })]
})
export class AppRoutingModule { }
