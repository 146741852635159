import { Component, ElementRef, Inject, ViewChild } from '@angular/core'

import { Odv } from '../modello/odv'
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms'

import { SessionData } from '../sessione/dati-sessione'
import { Esito, ESITO_OK } from '../utility/esito'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AuthService } from '../servizi/auth.service'
import { User } from '../modello/user'
import { ENTER, COMMA } from '@angular/cdk/keycodes'

import { GestoriSegnalazioniDataService } from '../servizi/gestori-segnalazioni-data.service'
import { Observable, of } from 'rxjs'
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips'
import { MatAutocomplete } from '@angular/material/autocomplete'
import { map } from 'rxjs/operators'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { CustomValidators } from '../validators/CustomValidators'
import { IdentityProvider, IdpService } from '../servizi/idp.service'


@Component({
  selector: 'app-modifica-utente-dialog',
  templateUrl: 'modifica-utente.html'
})
export class ModificaUtenteDialogComponent {
  utente: User

  formUtente: UntypedFormGroup
  odvCtrl = new UntypedFormControl()
  separatorKeysCodes: number[] = [ENTER, COMMA]
  addOnBlur = true
  gruppoSelezionato: Odv
  dirtyGroup: boolean
  filteredOdvGroups: Observable<Odv[]>
  odvGroups: Odv[]
  @ViewChild('odvInput', { static: false }) odvInput: ElementRef<HTMLInputElement>
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete
  errore: any
  idps: IdentityProvider[]

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ModificaUtenteDialogComponent>,
    private odvService: GestoriSegnalazioniDataService,
    private segnalazioniService: SegnalazioneService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private sessionData: SessionData,
    private idpService: IdpService

  ) {
    this.filteredOdvGroups = this.odvCtrl.valueChanges.pipe(
      map((odv: Odv | null) => odv && odv.nomeOdv ? this._filter(odv.nomeOdv.toString()) : this.removeAlreadySelectedGroups()))
    this.utente = data.utente
    this.errore = data.errore
    this.createForm()
    this.caricaGruppi(true)
    this.caricaIdp()
  }

  private _filter(value: string): Odv[] {
    const filterValue = value.toLowerCase()
    return this.odvGroups.filter(odv => odv.nomeOdv.indexOf(filterValue) === 0)
  }

  private createForm() {

    this.formUtente = this.fb.group({
      id: this.fb.control({ value: this.utente.id, disabled: false }),
      nome: this.fb.control({ value: this.utente.nome, disabled: false }, [Validators.required]),
      cognome: this.fb.control({ value: this.utente.cognome, disabled: false }, [Validators.required]),
      userId: this.fb.control({ value: this.utente.userId, disabled: false }, [Validators.required]),
      lingua: this.fb.control({ value: this.utente.lingua, disabled: false }, [Validators.required]),
      email: this.fb.control({ value: this.utente.email, disabled: false }, [CustomValidators.validaEmail, Validators.required]),
      mobile: this.fb.control({ value: this.utente.mobile, disabled: false }, [Validators.max(9999999999), Validators.min(100000000),
      Validators.pattern('[0-9]+')]),
      su: this.fb.control({ value: this.utente.su, disabled: false }),
      suDatiNonSensibili: this.fb.control({ value: this.utente.suDatiNonSensibili, disabled: false }),
      amministratoreGestori: this.fb.control({ value: this.utente.amministratoreGestori, disabled: false }),
      disabilitato: this.fb.control({ value: this.utente.disabilitato, disabled: false }),
      custode: this.fb.control({ value: this.utente.custode, disabled: false }),
      odv: this.fb.control({ value: this.utente.odv, disabled: false }),
      possoUsareEmailPerNotifiche: this.fb.control({ value: this.utente.possoUsareEmailPerNotifiche, disabled: false }),
      idp: this.fb.control({ value: this.utente.idp, disabled: false }),
    })
  }

  chiudiDialog() {
    this.dialogRef.close({ data: this.utente })
  }

  caricaGruppi(mostraEsito: boolean): any {
    this.segnalazioniService.recuperaGruppiGestori()
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.odvGroups = JSON.parse(esito.payload)
          this.odvCtrl.updateValueAndValidity()
          if (mostraEsito) {
            this.snackBar.open($localize`Recupero elenco gruppi Odv. Gruppi recuperati: ` + this.odvGroups.length, null, {
              duration: 2000,
            })
          }
        } else {
          this.snackBar.open($localize`Recupero elenco destinatari Fallito. Errore: ` + esito.descrizioneEsito, null, {
            duration: 4000,
          })
        }
      })
      .catch((error) => {
        this.snackBar.open($localize`Recupero elenco gruppi Odv Fallito. Errore: ` + error, null, {
          duration: 4000,
        })
      })
  }

  caricaIdp() {
    this.idpService.getIdps()
      .subscribe(idps => this.idps = [undefined, ...idps])
  }

  onAddUtente() {
    // console.log('utente:', this.formUtente.value)
    // const chat = new Chat();
    this.utente.userId = this.formUtente.value['userId']
    this.utente.email = this.formUtente.value['email']
    this.utente.mobile = this.formUtente.value['mobile']
    this.utente.nome = this.formUtente.value['nome']
    this.utente.lingua = this.formUtente.value['lingua']
    this.utente.cognome = this.formUtente.value['cognome']
    this.utente.custode = this.formUtente.value['custode']
    this.utente.odv = this.formUtente.value['odv']
    this.utente.su = this.formUtente.value['su']
    this.utente.suDatiNonSensibili = this.formUtente.value['suDatiNonSensibili']
    this.utente.disabilitato = this.formUtente.value['disabilitato']
    this.utente.amministratoreGestori = this.formUtente.value['amministratoreGestori']
    this.utente.possoUsareEmailPerNotifiche = this.formUtente.value['possoUsareEmailPerNotifiche']
    // this.utente.odvMembership = this.formUtente.value['anonimo'];
    // this.utente.odvMembership = this.odvGroups;
    this.chiudiDialog()
  }

  remove(odv: Odv, utente: User): void {
    const index = this.utente.odvMembership.findIndex(tmp => tmp.id === odv.id)
    if (index >= 0) {
      // this.odvGroups.splice(index, 1);
      this.utente.odvMembership.splice(index, 1)
      // this.odvGroups.push(odv);
    }
  }

  add(event: MatChipInputEvent): void {
    const input: MatChipInput = event.chipInput;
    const value = input.inputElement.value
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (this.matAutocomplete !== undefined && !this.matAutocomplete.isOpen) {
      const value = input.inputElement.value
      let selectedOdv = this.odvGroups.find((gruppo) => gruppo.nomeOdv == value)
      // Add our fruit
      if (value) {
        if (!this.utente.odvMembership) {
          this.utente.odvMembership = new Array<Odv>()
        }
        if (!selectedOdv) {
          selectedOdv = new Odv()
          selectedOdv.nomeOdv = value
        }
        this.utente.odvMembership.push(selectedOdv)

      }

      // Reset the input value
      if (input) {
        input.inputElement.value = ''
      }

      this.odvCtrl.setValue(null)
    }
  }

  onGruppoSelected(gruppo: Odv) {
    this.gruppoSelezionato = gruppo
    // console.log('selezionato gruppo ' + gruppo.nomeOdv)
    setTimeout(a => {
      document.getElementById('input-modifica-gruppo-' + gruppo.id).focus()
    }, 500, [])
  }

  onValueChange(event, gruppo: Odv, utente: User) {
    if (this.dirtyGroup) {
      this.dirtyGroup = false
      // console.log(event.target.value)
      // console.log(gruppo.nomeOdv)
      gruppo.nomeOdv = event.target.value
      // console.log(JSON.stringify(utente.odvMembership))
      this.utente = utente
      // this.aggiornaEtichettaGruppoAltriUtenti(gruppo);
    }
    this.gruppoSelezionato = undefined
    //  this.odvInput.nativeElement.blur();
  }

  selezionatoGruppoGestori(event, utente) {
    if (event.key === 'Enter' && event.target.value) {
      // console.log(event);
      let errori = ''
      if (utente.email !== undefined && !CustomValidators.isEmail(utente.email)) {
        errori += 'Email non valida'
      }
      if (utente.mobile && !CustomValidators.isNumeroTelefonico(utente.mobile)) {
        errori += ' Numero Telefonico non valido'
      }
      if (utente.mobile === undefined && utente.email === undefined) {
        errori += 'Fornire almeno uno tra Numero Telefonico ed Email!'
      }

      if (errori.length === 0) {
        // console.log('selezionato gruppo ' + event.target.value);
        //  Verifico se esiste
        this.odvService.recuperaOdvByName(event.target.value)
          .then(esito => {
            if (ESITO_OK === esito.esito) {
              const odv = JSON.parse(esito.payload)
              if (odv) {
                this.utente.odvMembership.push(odv)
              } else {
                const odvTemp = new Odv()
                odvTemp.nomeOdv = event.target.value
                this.utente.odvMembership.push(odvTemp)
                event.target.value = ''
                event.target.blur()
              }
            } else {
              this.snackBar.open($localize`Recupero gruppo Odv Fallito. Errore: ` + esito.payload, null, {
                duration: 4000,
              })
            }
          })


      } else {
        console.error(errori)
      }

    }
  }

  onInputGroupFocus(utente: User) {
    this.utente = utente
    this.filteredOdvGroups = of(this.removeAlreadySelectedGroups())
  }

  private removeAlreadySelectedGroups(): Odv[] {
    let result = []
    for (let i = 0; this.odvGroups !== undefined && i < this.odvGroups.length; i++) {
      const membershipNumber = this.utente && this.utente.odvMembership ? this.utente.odvMembership.length : 0
      if (membershipNumber > 0) {
        let trovato = false
        let j = 0
        while (!trovato && j < membershipNumber) {
          if (this.utente.odvMembership[j] !== undefined && this.utente.odvMembership[j].id === this.odvGroups[i].id) {
            trovato = true
          }
          j++
        }
        if (!trovato) {
          result.push(this.odvGroups[i])
        }
      } else {
        result = this.odvGroups.slice()
      }
    }
    return result
  }

  odvselected(event): void {
    if (!this.utente.odvMembership) {
      this.utente.odvMembership = new Array<Odv>()
    }
    this.utente.odvMembership.push(event.option.value)

    this.odvInput.nativeElement.value = ''
    this.odvCtrl.setValue(null)
    this.caricaGruppi(false)

    const self = this

    //
    //  this.odvInput.nativeElement.blur();
    setTimeout(a => {
      document.getElementById('input-nuovo-gruppo-' + this.utente.id).blur()
      // this.gruppoSelezionato = undefined;
    }, 500, [])
  }

  idpselected(idp): void {
    this.utente.idp = idp === undefined ? null : idp
  }

  custodiaAttiva() {
    return this.sessionData.configurazione?.custodiaIdentita
  }

  sonoSU() {
    return this.authService.getUser()?.su
  }

  get lingue() {
    return this.sessionData.configurazione?.lingue ? this.sessionData.configurazione.lingue : ''
  }

  compareIdp(a1: IdentityProvider, a2: IdentityProvider): boolean {
    return a1 && a2 ? a1.id === a2.id : a1 === a2;
  }

  handleSuChange() {
    if (this.formUtente.value['su']) {
      this.formUtente.patchValue({ suDatiNonSensibili: false });
    }
  }

  handleSuDatiNonSensibiliChange() {
    if (this.formUtente.value['suDatiNonSensibili']) {
      this.formUtente.patchValue({ su: false });
      this.formUtente.patchValue({ amministratoreGestori: false });
    }
  }

  handleAmministratoreGestoriChange() {
    if (this.formUtente.value['amministratoreGestori']) {
      this.formUtente.patchValue({ su: false });
      this.formUtente.patchValue({ suDatiNonSensibili: false });
    }
  }


}


