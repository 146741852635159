<!-- Dropdown per schermi piccoli -->
<div class="dropdown-languages">
  <button mat-button [matMenuTriggerFor]="linguaMenu">{{ linguaSelezionata }}</button>
  <mat-menu #linguaMenu="matMenu">
    <button *ngFor="let lingua of lingue" mat-menu-item (click)="cambiaLingua(lingua)">{{ lingua }}</button>
  </mat-menu>
</div>

<!-- Toggle buttons per schermi grandi -->
<div class="toggle-buttons">
  <button class="bottoni-lingua-editor" *ngFor="let lingua of lingue" mat-button (click)="cambiaLingua(lingua)"
  [ngClass]="lingua === linguaSelezionata ? 'lingua-selezionata' : 'lingua-non-selezionata'" >{{ lingua }}</button>
</div>
