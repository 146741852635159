/* eslint-disable @typescript-eslint/naming-convention */
import { Esito, ESITO_OK } from './../utility/esito'
import { Azienda } from './../modello/azienda'

import { Injectable, EventEmitter, Injector } from '@angular/core'
// import decode from 'jwt-decode';

import { ServiziRest } from '../utility/utility'
import { HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http'
import { SessionData } from '../sessione/dati-sessione'

import { GenericResponse, LoginResponse } from '../modello/http-response-interfaces'

import { Base64 } from 'js-base64'

import * as moment from 'moment'
import { User } from '../modello/user'
import { CustomValidators } from '../validators/CustomValidators'
import { Observable, Subject } from 'rxjs'
import { HeartbeatService } from './hearthbeat.service'
const TOKEN_ATTIVO = false
@Injectable()
export class AuthService {
  public token: string
  cachedRequests: Array<HttpRequest<any>> = []

  sessioneScaduta = new Subject<string>()
  sessioneInScadenza = new Subject()
  timeoutAvvisoScadenzaSessione: NodeJS.Timeout
  timeoutScadenzaSessione: NodeJS.Timeout

  scaduta = false

  public static getUserStatic(): User {
    const tmp: User = JSON.parse(sessionStorage.getItem('currentUser'))
    // console.log ('utente recuperato dal local storage: ' + JSON.stringify(tmp));
    return tmp
  }

  public static getUserPkStatic(): number {
    return AuthService.getUserStatic().id
  }

  public removeStoredCredentials(): any {
    this.token = null
    sessionStorage.removeItem('currentUser')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('sessionId')
    sessionStorage.removeItem('userPk')
    sessionStorage.removeItem('id_token')
    sessionStorage.removeItem('id_user')
    sessionStorage.removeItem('expires_at')
    sessionStorage.removeItem('idSegnalazioneSelezionata')
    if (this.timeoutAvvisoScadenzaSessione) {
      clearTimeout(this.timeoutAvvisoScadenzaSessione)
    }

    if (this.timeoutScadenzaSessione) {
      clearTimeout(this.timeoutScadenzaSessione)
    }
  }

  constructor(private http: HttpClient, private sessionData: SessionData, private injector: Injector) {
    // set token if saved in local storage
    try {
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
      this.token = currentUser && currentUser.token
    } catch (error) {
      this.removeStoredCredentials()
    }
  }

  private _heartbeatService: HeartbeatService | null = null;

  private get heartbeatService(): HeartbeatService {
    if (!this._heartbeatService) {
      this._heartbeatService = this.injector.get(HeartbeatService);
    }
    return this._heartbeatService;
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request)
  }

  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }

  public getToken(): string {
    return sessionStorage.getItem('token')
  }

  public getUser(): User {
    const tmp: User = JSON.parse(sessionStorage.getItem('currentUser'))
    // console.log ('utente recuperato dal local storage: ' + JSON.stringify(tmp));
    return tmp
  }

  public getLinguaUtente(): string {
    let lingua = window.location.href.split('/')[3]
    const lingue = this.sessionData.configurazione?.lingue ? this.sessionData.configurazione.lingue : ''
    // se lingue contiene almeno un valore e lingua non è tra le lingue disponibili,
    // allora prendo la lingua ricavata dal metodo getUser().lingua
    if (lingue && lingue.length > 0 && !lingue.includes(lingua)) {
      lingua = this.getUser()?.lingua
    }
    return lingua
  }

  public setUser(user: User) {
    sessionStorage.setItem('currentUser', JSON.stringify({ user: user }))
    sessionStorage.setItem('id_user', (user && user.id ? user.id.toString() : undefined))
  }

  public getUserPk(): number {
    return this.getUser()?.id
  }

  public getSessionId(): string {
    return sessionStorage.getItem('sessionId')
  }

  public setSessionId(sessionId) {
    sessionStorage.setItem('sessionId', sessionId)
  }

  public setUserPk(userPk) {
    sessionStorage.setItem('userPk', userPk)
  }

  async calcolaHashPassword(password: string): Promise<string> {
    return new Promise<string>((resolve, reject) =>
      resolve('123')
      /*             crypto.pbkdf2(password, SALT, ITERATION_NUMBER, KEY_LENGTH, DIGEST, (err, key) => {

                      if (err)
                          return reject(err)

                      //utente.token = TOKEN;
                      return resolve(key.toString('base64'));
                  }); */
    )
  }

  cancellaUtente(utente: User): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.delete<Esito>(ServiziRest.urlUtenti + '/' + utente.id, options).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  riattivaUtente(utente: User): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<Esito>(ServiziRest.urlUtenti + '/riattiva', JSON.stringify({ id: utente.id }), options).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  sbloccaUtente(utente: User): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<Esito>(ServiziRest.urlUtenti + '/sblocca', JSON.stringify({ id: utente.id }), options).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  disabilitaUtente(utente: User): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<Esito>(ServiziRest.urlUtenti + '/disabilita', JSON.stringify({ id: utente.id }), options).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  creaUtente(utente: User): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post(ServiziRest.urlUtenti, utente, options).toPromise()
      .then((esito) => (esito as Esito))
      .catch(this.handleErrorPromise)
  }

  aggiornaUtente(utente: User): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    utente.pinCode = undefined
    return this.http.patch(ServiziRest.urlUtenti, utente, options).toPromise()
      .then((risposta: Esito) => risposta)
      .catch(this.handleErrorPromise)
  }

  async recuperaUtente(utente: User): Promise<User> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return new Promise<User>((resolve, reject) => {
      const query = CustomValidators.isEmail(utente.email) ? 'email=' + utente.email : 'numeroTelefonico=' + utente.email
      // query += "&hashedPassword=" + encodeURIComponent(utente.hashedPassword);
      this.http.get(ServiziRest.urlUtenti + '?' + query, options)
        .toPromise()
        .then(response => {
          if (TOKEN_ATTIVO) {
            const token = response && response['token']
            if (token) {
              // set token property
              this.token = token

              // store username and jwt token in local storage to keep user logged in between page refreshes
              sessionStorage.setItem('currentUser', JSON.stringify({ user: utente }))
              sessionStorage.setItem('id_user', utente.id.toString())
              sessionStorage.setItem('token', JSON.stringify({ token: token }))
              // return true to indicate successful login
              return resolve(response as User)
            } else {
              // return false to indicate failed login
              return reject(response)
            }
          } else {
            if ((response as Array<object>).length > 0) {
              this.token = response[0].token
              if (this.token === undefined) {
                reject('Token non presente!!!')
              }
              sessionStorage.setItem('currentUser', JSON.stringify({ user: response[0] }))
              sessionStorage.setItem('id_user', response[0].id)
              sessionStorage.setItem('token', JSON.stringify({ token: this.token }))
              return resolve(this.getUser())
            }

            return reject(this.getUser())
          }
        })
        .catch(error => reject(error))
    })
  }

  extractData(res: Response) {
    const body = res.json()
    return body || {}
  }

  handleErrorPromise(error: Response | any) {
    console.error(error.message || error)
    return Promise.reject(error.error || error)
  }

  async recuperaUtenti(segnalanti = true): Promise<Array<User>> {

    return new Promise<Array<User>>((resolve, reject) => {
      this.http.get(ServiziRest.urlUtenti + '/' + segnalanti).toPromise()
        .then(response => {
          SessionData.utenti = response as Array<User>
          return resolve(SessionData.utenti)
        })
        .catch(error => reject(error))
    })
  }

  login(utente: User, token: string, mfaToken: string, mfa: string): Observable<Esito> {
    this.setUser(utente)

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic: ' + Base64.encode(utente.userId + ':' + utente.pinCode.pincode + ':' + mfaToken + ':' + mfa)
    })
    const options = { headers: headers }

    // let options = new RequestOptions({ headers: headers });
    return this.http.post<Esito>(ServiziRest.urlLogin, token, options)
  }

  verifyOtp(utente: User, otp: string, token: string): Promise<Esito> {
    this.setUser(utente)

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic: ' + Base64.encode(utente.userId + ':****:' + otp + ':' + token)
    })
    const options = { headers: headers }
    const body = { otp: otp }
    // let options = new RequestOptions({ headers: headers });
    return this.http.post<Esito>(ServiziRest.urlVerifyOtp, body, options).toPromise()
      .then((esito: Esito) => esito)
      .catch(this.handleErrorPromise)
  }

  loginConPin(utente: User, token: string): Promise<LoginResponse> {
    this.setUser(utente)

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic: ' + Base64.encode(utente.pinCode.pincode)
    })
    const options = { headers: headers }

    // let options = new RequestOptions({ headers: headers });
    return this.http.post<Esito>(ServiziRest.urlLoginConPin, token, options).toPromise()
      .then((response) => {
        const payload: LoginResponse = JSON.parse(response.payload)
        if (payload.idToken != null) {
          //    Nuovo login dobbiamo memorizzare userPk, sessionId e ruolo
          // console.log('Rilevato nuovo login memorizzo sessionId e userPk');
          // console.log('response.idToken:' + payload.idToken);
          // console.log('utente:' + JSON.stringify(payload.user));
          this.setSession(payload)
        } else {
          //  Il backend ha risposto con un codice di errore
          console.error('login fallita, codice errore:' + payload.answerCode)
        }
        return payload
      })
      .catch(this.handleErrorPromise)
  }

  loginNuovaSegnalazione(): Promise<User> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    let payload: any
    // let options = new RequestOptions({ headers: headers });
    return this.http.post<Esito>(ServiziRest.urlLoginNuovaSegnalazione, undefined, options).toPromise()
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          payload = JSON.parse(esito.payload)
          if (payload.idToken != null) {
            //    Nuovo login dobbiamo memorizzare userPk, sessionId e ruolo
            // console.log('Rilevato nuovo login memorizzo sessionId e userPk');
            // console.log('response.idToken:', payload);

            this.setSession(payload)
          } else {
            //  Il backend ha risposto con un codice di errore
            console.error('login fallita, codice errore:' + esito.descrizioneEsito)
          }
        }
        return payload.user
      })
      .catch(this.handleErrorPromise)
  }

  async loginAnonimo(utente: User, token: string): Promise<Esito> {
    this.setUser(utente)

    const creds = utente.pinCode.pincode.split(':')

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic: ' + Base64.encode(utente.pinCode.pincode)
    })
    const options = { headers: headers }

    // let options = new RequestOptions({ headers: headers });
    return this.http.post<Esito>(ServiziRest.urlLogin, token, options).toPromise()
      .then((response) => {
        let payload
        if (response.esito >= 0) {
          payload = JSON.parse(response.payload)
          if (payload.idToken != null) {
            //    Nuovo login dobbiamo memorizzare userPk, sessionId e ruolo
            // console.log('Rilevato nuovo login memorizzo sessionId e userPk');
            // console.log('response.idToken:' + payload.idToken);
            // console.log('utente:' + JSON.stringify(payload.user));
            this.setSession(payload)
          } else {
            //  Il backend ha risposto con un codice di errore
            console.error('login fallita, codice errore:' + payload.answerCode)
          }
        }
        return response
      })
      .catch(this.handleErrorPromise)
  }

  async ottieniPrimoPin(user: User, token: any): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<Esito>(ServiziRest.urlRegister + '/pin',
      JSON.stringify({ utente: user, recaptchav3_token: token }), options).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async inviaEmailVerifica(user: User): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<Esito>(ServiziRest.urlRegister + '/inviaEmailVerifica',
      JSON.stringify({ utente: user }), options).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async rigeneraPin(tokenRichiestaPin: string): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<Esito>(ServiziRest.urlRegister + '/rigenera-pin',
      JSON.stringify({ tokenRichiestaPin: tokenRichiestaPin }), options).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async verificaEmail(tokenVerificaEmail: string): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<Esito>(ServiziRest.urlRegister + '/verificaEmail',
      JSON.stringify({ tokenVerificaEmail: tokenVerificaEmail }), options).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  //  invia una email all'utente con il link per la rigenerazione del PIN (controllo CAPTCHA attivo)
  resetPin(email: string, username: string, token: string): Promise<GenericResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<GenericResponse>(ServiziRest.urlResetPin + '/' + email + '/' + username,
      JSON.stringify({ recaptchav3_token: token }), options).toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  //  invia una email all'utente con il link per la rigenerazione del PIN (controllo CAPTCHA disattivo)
  resetPinNoCaptcha(id: number): Promise<GenericResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<GenericResponse>(ServiziRest.urlResetPinNoCaptcha + '/' + id, options).toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  informativa(nomeazienda: string): Promise<Azienda> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.get<Esito>(ServiziRest.urlGetInformativa + nomeazienda, options).toPromise()
      .then((esito: Esito) => {
        // console.log('returned object is:' + JSON.stringify(azienda));
        if (esito && esito.esito === ESITO_OK) {
          const azienda: Azienda = JSON.parse(esito.payload)
          return azienda
        } else {
          throw new Error(esito.payload)
        }
        return null
      })
  }

  logout(): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })

    const options = { headers: headers }

    return this.http.post<Esito>(ServiziRest.urlLogout, options).toPromise()
      .then(esito => {
        this.removeStoredCredentials()
        this.heartbeatService.cleanup()
        this.sessionData.reset()
        return esito
      })
      .catch(this.handleErrorPromise)
  }

  public setSession(authResult) {
    const expiresAt = moment().add(authResult.expiresIn, 'second')
    sessionStorage.setItem('id_token', authResult.idToken)
    sessionStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()))
    sessionStorage.setItem('currentUser', JSON.stringify(authResult.user))
    sessionStorage.setItem('id_user', authResult.user.id)

  }

  private lastRenewalTime: moment.Moment;


  public isLoggedIn() {
    const user = this.getUser()
    if (!user) {
      this.removeStoredCredentials()
      return false
    }
    // se lastRenewalTime è null o undefined, allora non è mai stato rinnovato il token
    if (!this.lastRenewalTime) {
      this.lastRenewalTime = moment()
    }
    // console.log('Ora: ' + moment());
    console.log('Scadenza: ' + this.getExpiration());
    const expiration = this.getExpiration()
    const expired = moment().isAfter(expiration)
    // console.log('Sessione spirata? ' + expired);
    if ((!expired &&
      this.lastRenewalTime && moment(this.lastRenewalTime).add(20, 'seconds').isBefore(moment()))
      && expiration.isValid()) {
      this.rinnovaToken()
      this.lastRenewalTime = moment(); // aggiorna l'ultimo momento di rinnovo
    }
    return !expired
  }

  rinnovaToken() {
    // console.log('Rinnovo token....');
    // let options = new RequestOptions({ headers: headers });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.get<Esito>(ServiziRest.urlRinnovaToken, options).toPromise()
      .then((response) => {
        const payload: LoginResponse = JSON.parse(response.payload)
        if (payload.idToken != null) {
          //    Nuovo login dobbiamo memorizzare userPk, sessionId e ruolo
          // console.log('Rilevato nuovo login memorizzo sessionId e userPk');
          // console.log('response.idToken:' + payload.idToken);
          // console.log('utente:' + JSON.stringify(payload.user));
          this.setSession(payload)
        } else {
          //  Il backend ha risposto con un codice di errore
          console.error('rinnovo token fallito, codice errore:' + payload.answerCode)
        }
        return payload
      })
      .catch(this.handleErrorPromise)
  }

  isLoggedOut() {
    return !this.isLoggedIn()
  }

  getExpiration() {
    const expiration = sessionStorage.getItem('expires_at')
    const expiresAt = JSON.parse(expiration)
    return moment(expiresAt)
  }

  getMfaQrCode(tokenMfa: string): Observable<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<Esito>(ServiziRest.urlRenewMfa,
      JSON.stringify({ tokenMfa: tokenMfa }), options)
  }

  isMotifUser(): boolean {
    const user = this.getUser()
    const isUserMotif = user?.email?.endsWith('@moti-f.it')
    return isUserMotif
  }

}
