import { AuthService } from '../servizi/auth.service'
import { Component, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-sessione-scaduta-dialog',
  templateUrl: 'sessione-scaduta.html',
})
export class SessioneScadutaDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any, public dialogRef: MatDialogRef<SessioneScadutaDialogComponent>,
    public authService: AuthService, private router: Router) {
  }
  chiudi() {
    this.dialogRef.close()
  }
}
