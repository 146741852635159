<h2 mat-dialog-title class="titolo-dialog">
  Utente
</h2>
<mat-dialog-content class="alternate-theme">
  <div class="centra-form">
    <div class="flex-row">
      <div class="flex-row flex-100">
        <form [formGroup]="formUtente" style="width:100%">
          <div class="flex-column">
            <div class="flex-column">
              <mat-error i18n *ngIf="errore !== undefined">{{errore}}</mat-error>
              <div>
                <mat-form-field color="accent">
                  <input type="text" #userId i18n-placeholder placeholder="Nickname" aria-label="Nickname" matInput
                    formControlName="userId" />
                </mat-form-field>
              </div>
              <div >
                <mat-form-field color="accent">
                  <mat-label>Identity Provider</mat-label>
                  <mat-select formControlName="idp" (valueChange)="idpselected($event)" [compareWith]="compareIdp">
                    <mat-option *ngFor="let idp of idps" [value]="idp">{{ idp?.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div >
                <mat-form-field color="accent">
                  <input type="text" #nome i18n-placeholder placeholder="Nome" aria-label="Nome" matInput formControlName="nome" />
                </mat-form-field>
              </div>
              <div >
                <mat-form-field color="accent">
                  <input type="text" #cognome i18n-placeholder placeholder="Cognome" aria-label="Cognome" matInput
                    formControlName="cognome" />
                </mat-form-field>
              </div>
              <div >
                <mat-form-field color="accent">
                  <mat-label>Lingua</mat-label>
                  <mat-select formControlName="lingua">
                    <mat-option *ngFor="let lang of lingue.split(',')" [value]="lang.trim()">{{ lang.trim() }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div >
                <mat-form-field color="accent">
                  <input type="text" #email i18n-placeholder placeholder="Email" aria-label="Email" matInput formControlName="email" />
                  <mat-error i18n
                    *ngIf="formUtente.controls['email'].errors?.emailNonValida && formUtente.controls['email'].touched">
                    Email non valida</mat-error>
                </mat-form-field>
              </div>
              <div >
                <mat-form-field color="accent">
                  <input type="text" #userId i18n-placeholder placeholder="Numero Telefonico" aria-label="Numero Telefonico" matInput
                    formControlName="mobile" />
                  <mat-error i18n *ngIf="formUtente.controls.mobile.errors?.min || formUtente.controls.mobile.errors?.max">
                    Il numero telefonico introdotto non sembra valido. Sono ammesse solo 9 o 10 cifre numeriche
                  </mat-error>

                  <mat-error i18n *ngIf="formUtente.controls.mobile.errors?.pattern && formUtente.controls.mobile.touched">
                    Sono ammessi solo caratteri numerici</mat-error>
                </mat-form-field>
              </div>


              <div >
                <mat-checkbox matInput type="checkbox" formControlName="odv"></mat-checkbox>
                <mat-label i18n style="padding: 2px;">Gestore</mat-label>
              </div>
              <div *ngIf="formUtente.controls['odv'].value">
                <mat-chip-list #chipList>
                  <div *ngFor="let group of utente.odvMembership; let i=index">
                    <mat-chip [selectable]="true" [removable]="true" (removed)="remove(group, utente)"
                      (click)="onGruppoSelected(group)" *ngIf="gruppoSelezionato!==group">
                      {{ group.nomeOdv}}
                      <mat-icon matChipRemove *ngIf="gruppoSelezionato===group?false:true">cancel</mat-icon>
                    </mat-chip>
                    <input matInput #chipInput *ngIf="gruppoSelezionato!==group?false:true"
                      value="{{ gruppoSelezionato?.nomeOdv }}" [id]="'input-modifica-gruppo-' + group.id"
                      (blur)="onValueChange($event, group, utente)"
                      (keydown.enter)="onValueChange($event, group, utente)">
                  </div>
                  <input i18n-placeholder placeholder="Nuovo Gruppo..." #odvInput [formControl]="odvCtrl" [matAutocomplete]="auto"
                    [id]="'input-nuovo-gruppo-' + utente.id" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" #trigger="matAutocompleteTrigger"
                    (click)="$event.stopPropagation(); trigger.openPanel()"
                    (keydown)="selezionatoGruppoGestori($event,utente)" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)" (focus)="onInputGroupFocus(utente)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="odvselected($event)">
                  <mat-option *ngFor="let group of filteredOdvGroups | async" [value]="group"
                    i18n-matTooltip matTooltip="{{group.nomeOdv}}">
                    {{group.nomeOdv}}
                  </mat-option>
                </mat-autocomplete>
              </div>
              <div style="margin-bottom: 20px;"></div>
            </div>
            <div class="flex-column">
              <div>
                <mat-checkbox matInput type="checkbox" formControlName="amministratoreGestori"
                (change)="handleAmministratoreGestoriChange()"></mat-checkbox>
                <mat-label i18n style="padding: 2px;">Amministra Gestori</mat-label>
              </div>

              <div  *ngIf="custodiaAttiva()">
                <mat-checkbox matInput type="checkbox" formControlName="custode"></mat-checkbox>
                <mat-label i18n style="padding: 2px;">Custode</mat-label>
              </div>
              <div >
                <mat-checkbox matInput type="checkbox" formControlName="possoUsareEmailPerNotifiche"></mat-checkbox>
                <mat-label i18n style="padding: 2px;">Abilita notifiche email</mat-label>
              </div>
              <div >
                <mat-checkbox matInput type="checkbox" formControlName="disabilitato"></mat-checkbox>
                <mat-label i18n style="padding: 2px;">Disabilitato</mat-label>
              </div>

              <div *ngIf="sonoSU()">
                <div  >
                  <mat-checkbox matInput type="checkbox" formControlName="su" [disabled]="!sonoSU()" (change)="handleSuChange()"></mat-checkbox>
                  <mat-label i18n style="padding: 2px;">Super Utente</mat-label>
                </div>
                <mat-hint *ngIf="!sonoSU()">Modifica consentita solo ai Super Utenti</mat-hint>
              </div>
              <div *ngIf="sonoSU()">
                <div >
                  <mat-checkbox matInput type="checkbox" formControlName="suDatiNonSensibili"
                  (change)="handleSuDatiNonSensibiliChange()" [disabled]="!sonoSU()"></mat-checkbox>
                  <mat-label i18n style="padding: 2px;">Super Utente senza accesso ai dati sensibili</mat-label>
                </div>
                <mat-hint *ngIf="!sonoSU()">Modifica consentita solo ai Super Utenti</mat-hint>
              </div>
            </div>
            <div style="margin-bottom: 20px;"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="centered">
    <button i18n mat-button mat-dialog-close>Annulla</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button i18n mat-button (click)="onAddUtente()" [disabled]="formUtente.invalid">Salva</button>
  </div>

</mat-dialog-actions>
