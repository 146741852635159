import { Component, Inject } from '@angular/core'
import { DirittiSegnalazione } from '../modello/diritti-segnalazione'
import { Chat } from '../modello/chat'
import { Odv } from '../modello/odv'
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { ChatService } from '../servizi/chat.service'
import { SessionData } from '../sessione/dati-sessione'
import { ESITO_OK } from '../utility/esito'
import { chatCompareFn, decycle } from '../utility/utility'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MessaggiStore } from '../stores/messaggi.store'
import { Lavorazione } from '../modello/lavorazione'

@Component({
  selector: 'app-nuova-chat-dialog',
  templateUrl: 'nuova-chat.html'
})
export class NuovaChatDialogComponent {
  lavorazioni: Lavorazione[]
  diritti: DirittiSegnalazione[]
  odvPartecipazioneChat: any
  formChats: UntypedFormGroup
  chat: Chat
  lavorazione: any
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NuovaChatDialogComponent>,
    private chatService: ChatService,
    private snackBar: MatSnackBar, private sessionData: SessionData,
    private messaggiStore: MessaggiStore
  ) {
    this.diritti = data.diritti
    const nessunaLavorazione = new Lavorazione()
    this.lavorazioni = [nessunaLavorazione, ...data.lavorazioni]
    this.createForm()
  }

  private createForm() {

    this.formChats = this.fb.group({
      chat: this.fb.control({ value: '', disabled: false }),
      odv: this.fb.control({ value: this.diritti?.[0], disabled: false }),
      lavorazione: this.fb.control({ value: this.lavorazioni?.[0], disabled: false }),
    })
  }

  chiudiDialog() {
    this.dialogRef.close({ data: this.chat })
  }

  doChatCompareFn(v1: Chat, v2: Chat): boolean {
    return chatCompareFn(v1, v2)
  }

  onGruppoSelezionato(event, odv: Odv) {
    if (event.source.selected) {
      this.odvPartecipazioneChat = odv
    }
  }

  onLavorazioneSelezionato(event, lavorazione: Lavorazione) {
    if (event.source.selected) {
      this.lavorazione = lavorazione
    }
  }

  gestoreLavorazione(lavorazione: Lavorazione) {
    return lavorazione.dirittiOdv?.find(dir => dir.gestore)?.odv.nomeOdv
  }

  onAddChat() {
    console.log(
      'Add chat for segnalazione ' + this.sessionData.getSegnalazioneSelezionata().id
    )
    console.log('nome chat:' + this.formChats.controls.chat.value)
    let chat = new Chat()
    chat.nome = this.formChats.controls.chat.value
    if (this.lavorazione && this.lavorazione.id) {
      chat.lavorazione = this.lavorazione
    }

    // se non viene fornita una lavorazione, rifiuta la creazione della chat
    // if (!chat.lavorazione) {
    //   this.snackBar.open(
    //     $localize `Non è stato possibile creare la chat.
    //     Non è stata selezionata una lavorazione, se non lo hai ancora fatto prendi in carico la segnalazione.`,
    //     null,
    //     {
    //       duration: 8000
    //     }
    //   )
    //   return
    // }
    chat.segnalazione = this.sessionData.getSegnalazioneSelezionata()
    // chat.moderatore = gruppoOdvCoinvolto(this.segnalazioneSelezionata, this.authService.getUser());
    chat.moderatore = this.odvPartecipazioneChat
    chat.gruppiPartecipanti = [this.odvPartecipazioneChat]
    this.odvPartecipazioneChat = null
    chat = decycle(chat)
    chat.nome = chat.segnalazione.progressivo + ' - ' + chat.nome.trim()
    this.chatService
      .creaChat(chat)
      .then(esito => {
        if (esito.esito === ESITO_OK) {
          // this.chats.push(JSON.parse(esito.payload));
          // this.chatSelezionata = JSON.parse(esito.payload);
          // this.formChats.controls.chat.setValue(JSON.parse(esito.payload));
          // this.ref.detectChanges();
          // console.log('9>>>>>>>>>>>>>>>>')
          // this.recuperaTuttiIGruppi();
          this.messaggiStore.sottoscriviPerNuoviMessaggiChat(
            +JSON.parse(esito.payload).id
          )
          this.chat = JSON.parse(esito.payload)
          // this.segnalazioneSelezionata.chatAperte.push(this.chatSelezionata);
        } else {
          this.snackBar.open(
            $localize `Non è stato possibile creare la chat.
        ${esito.descrizioneEsito}`,
            null,
            {
              duration: 4000
            }
          )
        }
        this.formChats.controls.chat.setValue(null)
        this.chiudiDialog()
      })
      .catch(error => {
        this.snackBar.open(
          $localize `Non è stato possibile aggiungere la chat alla segnalazione.
      ${error.toString()}`,
          null,
          {
            duration: 4000
          }
        )
      })
  }

  labelLavorazione(lavorazione) {
    if (lavorazione?.id) {
      return $localize `Lavorazione del gruppo ` + this.gestoreLavorazione(lavorazione)
    } else {
      return $localize `Nessuna lavorazione`
    }

  }
}


