
import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http'
import { AuthService } from './auth.service'
import { Observable, EMPTY } from 'rxjs'
import { tap, timeout } from 'rxjs/operators'
import { Router } from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  dialog: any
  constructor (public auth: AuthService, private router: Router, private snackBar: MatSnackBar) { }
  intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // let baseUrl = document.getElementsByTagName('base')[0].href;

    const idToken = sessionStorage.getItem('id_token')
    const idUtente = sessionStorage.getItem('id_user')
    if (idToken) {
      request = request.clone()
      if (!request.url.endsWith('login') && !request.url.endsWith('loginConPin')) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${idToken}`,
            UserId: `${idUtente}`
          }
        })
      }
    }

    request = request.clone({
      setHeaders: {
        'x-api-key': 'HHFY6754GFR43KIO990JHYHG66jjK3344egfmlkUUY88887yhKLlkJHjcrfcdm',
        'lingua': window.location.href.split('/')[3]
      }
    })
    const timeoutValue = request.headers.get('timeout') || 60000
    if (request.method === 'GET') {
      const customRequest = request.clone({
        headers: request.headers.set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
      })

      return next.handle(customRequest).pipe(timeout(+timeoutValue), tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
          // console.log('Risposta ' + event.status);
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || (err.status === 400 && err.error === 'Authentication Error')) {
            console.log('1. Ho intercettato una mancata autorizzazione!!!!!!')
            // this.errorService.addErrors([`Sessione Scaduta`]);
            //  this.auth.sessioneScaduta.emit('Sessione Scaduta');
            this.auth.removeStoredCredentials()
            // redirect to the login route
            // or show a modal
            // se ci troviamo nella login non fare nulla altrimenti vai alla landing page
            if (this.router.url !== '/login') {
              this.snackBar.open($localize `Sessione scaduta, esegui nuovamente l\'accesso al sistema`, null, {
                duration: 4000
              }).afterDismissed().subscribe(() => {
                this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
              })
            }

          } else if (err.status === 404) {
            console.log('2. Ho intercettato un tentativo di accesso non autorizzato (banned?)')
            // this.errorService.addErrors([`Sessione Scaduta`]);
            //  this.auth.sessioneScaduta.emit('Sessione Scaduta');
            this.auth.removeStoredCredentials()
            // redirect to the login route
            // or show a modal
            this.router.navigate(['banned-page'])
            this.snackBar.open($localize `Non hai accesso al sistema`, null, {
              duration: 4000
            }).afterDismissed().subscribe(() => {
              this.router.navigate(['banned-page'])
            })
          }
          return EMPTY
        }
      }))
    }

    return next.handle(request).pipe(timeout(+timeoutValue), tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
        // console.log('Risposta ' + event.status);
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || (err.status === 400 && err.error === 'Authentication Error')) {
          console.log('2. Ho intercettato una mancata autorizzazione!!!!!!')
          // this.errorService.addErrors([`Sessione Scaduta`]);
          //  this.auth.sessioneScaduta.emit('Sessione Scaduta');
          const odv = this.auth.getUser()?.odv
          this.auth.removeStoredCredentials()
          // redirect to the login route
          // or show a modal
          if (this.router.url !== '/login') {
            if (odv) {
              this.router.navigate(['/login'])
            } else {
              this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
            }
          }
        }
      }
    }))
  }
}
