<h2 mat-dialog-title class="titolo-dialog" i18n>Termina Condivisione</h2>
<mat-dialog-content>
  <div i18n *ngIf="!isLavorazione">Confermi di voler terminare la condivisione della segnalazione verso i membri
    del gruppo
    {{data.nomeGruppoOdv}}?</div>
    <div i18n *ngIf="isLavorazione">Confermi di voler terminare la condivisione della lavorazione verso i membri
      del gruppo
      {{data.nomeGruppoOdv}}?</div>
</mat-dialog-content>
<mat-dialog-actions>
  <span class="spacer"></span>
  <button i18n mat-button mat-dialog-close>No</button>
  <span class="spacer"></span>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button i18n mat-button [mat-dialog-close]="true">Sì</button>
  <span class="spacer"></span>
</mat-dialog-actions>
