import { ESITO_OK, Esito } from './../utility/esito'

import { SegnalazioneService } from './../servizi/segnalazione.service'
import { RicercaService } from './../servizi/ricerca.service'
import { AziendeService } from './../servizi/aziende.service'
import { Informativa } from './../modello/informativa'
import { Location } from '@angular/common'
import { Azienda } from './../modello/azienda'
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
  OnDestroy,
} from '@angular/core'

import { AuthService } from '../servizi/auth.service'

import { compare } from '../utility/utility'
import { ConfermaEliminazioneAziendaDialogComponent } from './conferma-eliminazione-azienda.dialog.component'

import { Odv } from '../modello/odv'
import { UntypedFormGroup } from '@angular/forms'
import { MatSort, Sort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips'
import { Subscription } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { SessionData } from '../sessione/dati-sessione'
import { OdvAzienda } from '../modello/odv-azienda'
import { MatOptionSelectionChange } from '@angular/material/core'

@Component({
  selector: 'app-gestione-aziende',
  templateUrl: './gestione-aziende.component.html',
  styleUrls: ['./gestione-aziende.component.scss'],
})
export class GestioneAziendeComponent
  implements OnInit, AfterViewInit, OnDestroy {
  dataSource

  aziende: Azienda[]

  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  colonneVisualizzate = ['id', 'nome', 'odvs', 'azioni']

  odvs: Odv[]
  odvsFiltrati: Odv[]

  sortedData
  azienda: Azienda
  destinatariFiltratiSocieta: { [key: string]: OdvAzienda[] } = {}

  aziendaForm: UntypedFormGroup

  @ViewChild('inputOdvs', { static: true }) inputOdvs: ElementRef

  private subscriptions: Array<Subscription> = []
  destinatarioSelezionato: any
  chipRemoveClicked: boolean
  destinatari: any

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private aziendeService: AziendeService,
    private ricercaService: RicercaService,
    private location: Location,
    private segnalazioneService: SegnalazioneService,
    private route: ActivatedRoute,
    private sessionData: SessionData,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    this.subscriptions?.forEach((sub) => sub.unsubscribe())
  }

  ngOnInit() {
    this.recuperaGruppiGestori()
    if (!this.authService.getUser()?.su) {
      this.location.back()
    } else {
      this.subscriptions.push(
        this.ricercaService.filtraElenco.subscribe((cercami: string) => {
          this.applyFilter(cercami)
        })
      )
    }
    this.route.data.subscribe((data) => {
      // console.log(data);
      const configurazione = data['configurazione']
      if (configurazione) {
        this.sessionData.configurazione = configurazione
      } else {
        console.error(data)
        this.router.navigate(['/landing-page'], {
          queryParams: { initialize: true },
        })
      }
      return
    })
  }

  ngAfterViewInit() {
    this.aziendeService.recuperaAziendeConInformative().then((esito: Esito) => {
      if (esito.esito === ESITO_OK) {
        this.aziende = JSON.parse(esito.payload)
        // ora per ogni azienda recupera i destinatari
        this.aziende.forEach((azienda) => {
          this.caricaDestinatariPerSocieta(azienda)
        })
        this.sortedData = this.aziende.slice()
        this.dataSource = new MatTableDataSource(this.aziende)
        this.dataSource.sortingDataAccessor = (item, property) => {
          const value = item[property]
          return typeof value === 'string' ? value.toLowerCase() : value
        }
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator
      }
    })
    if (this.paginator) {
      const paginatorIntl = this.paginator._intl
      paginatorIntl.nextPageLabel = ''
      paginatorIntl.previousPageLabel = ''
      paginatorIntl.lastPageLabel = ''
      paginatorIntl.firstPageLabel = ''
    }
  }

  sortData(sort: Sort) {
    const data = this.aziende.slice()
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data
      return
    }

    this.dataSource.data = data.sort((a: Azienda, b: Azienda) => {
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {
        case 'id':
          return compare(a.id, b.id, isAsc)
        case 'nome':
          return compare(a.nomeAzienda, b.nomeAzienda, isAsc)
        case 'informativa':
          return compare(a.informativa.testo, b.informativa.testo, isAsc)
        default:
          return 0
      }
    })
  }

  applyFilter(filterValue: string, key?: string) {
    filterValue = filterValue.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue
  }

  selected(azienda) {
    this.azienda = azienda
    // console.log('selected:' + azienda.nomeAzienda);
  }

  back() {
    this.location.back()
  }

  aggiungiAzienda() {
    // console.log('adding azienda...');
    const azienda = new Azienda()
    const informativa = new Informativa()
    azienda.informativa = informativa
    azienda.informativa.testo = 'informativa'
    this.aziende.push(azienda)
    this.refresh()
    this.paginator.lastPage()
  }

  cancella(azienda) {
    this.aziende.splice(this.aziende.indexOf(azienda), 1)
    this.aziendeService
      .cancellaAzienda(azienda)
      .then(() => {
        this.snackBar.open($localize`Azienda ${azienda.id} eliminata`, null, {
          duration: 2000,
        })
      })
      .catch(() => {})
    this.refresh()
  }

  refresh() {
    if (this.aziende) {
      this.dataSource.data = this.aziende
    }
  }

  openDialogConfermaEliminazioneAzienda(azienda): void {
    const dialogRef = this.dialog.open(
      ConfermaEliminazioneAziendaDialogComponent,
      {
        width: '500px',
        data: { id: azienda.id, nome: azienda.nomeAzienda },
        disableClose: true,
        hasBackdrop: true,
      }
    )

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancella(azienda)
      }
    })
  }

  salvaSuInvio(event, azienda) {
    if (event.key === 'Enter') {
      // console.log(event);
      let errori = ''
      if (!azienda.nomeAzienda) {
        errori += 'Nome Azienda non valido'
      }
      if (errori.length === 0) {
        this.salvaAzienda(azienda)
      } else {
        this.snackBar.open(errori.trim(), null, {
          duration: 4000,
        })
      }
    }
  }

  salvaAzienda(azienda?) {
    if (azienda) {
      this.azienda = azienda
    }
    if (this.azienda.id == null) {
      this.aziendeService
        .creaAzienda(this.azienda)
        .then((esito: Esito) => {
          // console.log(azienda);
          if (esito.esito === ESITO_OK) {
            this.azienda = JSON.parse(esito.payload) as Azienda
            const aziendaAggiornataIndex = this.aziende.findIndex(
              (aziendatmp) => aziendatmp.nomeAzienda === azienda.nomeAzienda
            )
            if (aziendaAggiornataIndex !== -1) {
              this.aziende.splice(aziendaAggiornataIndex, 1, this.azienda)
              this.refresh()
            } else {
              console.error(
                'Non sono riuscito a individuare l\'azienda da aggiornare dopo il salvataggio!'
              )
              this.aziende.push(this.azienda)
              this.refresh()
            }
            this.snackBar.open($localize`Azienda creata`, null, {
              duration: 2000,
            })
          } else {
            this.snackBar.open(
              $localize`Creazione azienda fallita` + esito.payload,
              null,
              {
                duration: 4000,
              }
            )
          }
        })
        .catch((errore) => {
          // console.log(errore);
          this.snackBar.open(
            $localize`Creazione azienda fallita` + errore.descrizione,
            null,
            {
              duration: 4000,
            }
          )
        })
    } else {
      this.aziendeService
        .aggiornaAzienda(this.azienda)
        .then((esito: Esito) => {
          // console.log(azienda);
          if (esito.esito === ESITO_OK) {
            this.snackBar.open($localize`Azienda aggiornata`, null, {
              duration: 2000,
            })
          } else {
            this.snackBar.open(
              $localize`Aggiornamento azienda fallito ` +
                esito.payload +
                ' ' +
                esito.descrizioneEsito,
              null,
              {
                duration: 4000,
              }
            )
          }
        })
        .catch((errore) => {
          // console.log(errore);
          this.snackBar.open(
            $localize`Aggiornamento azienda fallito ` + errore,
            null,
            {
              duration: 4000,
            }
          )
        })
    }
  }

  optionSelected() {}

  toggleDestinatarioPreferito(event, destinatario: OdvAzienda) {
    if (destinatario.destinatarioPredefinito === null) {
      destinatario.destinatarioPredefinito = true
    } else if (destinatario.destinatarioPredefinito === false) {
      destinatario.destinatarioPredefinito = null
    } else {
      destinatario.destinatarioPredefinito = false
    }

    this.segnalazioneService
      .salvaDestinatariPerAzienda(
        destinatario.azienda,
        destinatario.odv,
        destinatario.destinatarioPredefinito
      )
      .subscribe((esito: Esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nel salvataggio dei destinatari`,
            null,
            {
              duration: 6000,
            }
          )
          return
        }
        this.caricaDestinatariPerSocieta(this.azienda)
        this.snackBar.open($localize`Destinatario salvato con successo`, null, {
          duration: 6000,
        })
      })
  }

  caricaDestinatariPerSocieta(societa: Azienda) {
    if (!societa) {
      return
    }
    this.azienda = societa

    this.segnalazioneService
      .getDestinatariPerSocieta(societa)
      .subscribe((esito: Esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nel recupero dei destinatari`,
            null,
            {
              duration: 6000,
            }
          )
          return
        }

        // Salva i destinatari nel campo 'destinatariFiltratiSocieta' sotto la chiave corrispondente alla società
        this.destinatariFiltratiSocieta[societa.nomeAzienda] = JSON.parse(esito.payload)
        // this.configurazione.indirizzamentoSegnalazioniGruppiPredefiniti = true;
        this.refresh()
      })
  }

  removeDestinatarioSocieta(event, destinatario, azienda) {
    this.azienda = azienda
    this.destinatarioSelezionato = destinatario.odv

    this.segnalazioneService
      .rimuoviDestinatariPerSocieta(this.destinatarioSelezionato.id, azienda)
      .subscribe((esito: Esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nella rimozione del destinatario`,
            null,
            {
              duration: 6000,
            }
          )
          return
        }
        this.snackBar.open($localize`Destinatario rimosso con successo`, null, {
          duration: 6000,
        })
        this.destinatarioSelezionato = undefined
        this.caricaDestinatariPerSocieta(this.azienda)
      })
    setTimeout(() => {
      this.chipRemoveClicked = false
    }, 50)
  }


  getNonSelectedDestinatariSocieta(): any[] {
    let destinatari = this.destinatari
    if (this.azienda && this.destinatariFiltratiSocieta[this.azienda.nomeAzienda]) {
      const destinatariFiltratiPerSocietaSelezionata = this.destinatariFiltratiSocieta[this.azienda.nomeAzienda]
      destinatari = destinatari.filter(
        (destinatario) =>
          !destinatariFiltratiPerSocietaSelezionata.some(
            (destFiltrato) => destFiltrato.odv.nomeOdv === destinatario.nomeOdv
          )
      )
    }
    return destinatari
  }


  addDestinatarioSocieta(event: MatOptionSelectionChange, destinatario) {
    if (event.isUserInput) {
      // ripulisce il campo di input
      event.source.value = ''
      // Update the selected recipient.
      this.destinatarioSelezionato = destinatario

      this.segnalazioneService
        .salvaDestinatariPerAzienda(this.azienda, destinatario, true)
        .subscribe((esito: Esito) => {
          if (esito.esito !== ESITO_OK) {
            this.snackBar.open(
              $localize`Errore nel salvataggio dei destinatari`,
              null,
              {
                duration: 6000,
              }
            )
            return
          }
          this.caricaDestinatariPerSocieta(this.azienda)
          this.snackBar.open(
            $localize`Destinatario salvato con successo`,
            null,
            {
              duration: 6000,
            }
          )
        })
    }
  }

  private recuperaGruppiGestori() {
    this.segnalazioneService
      .recuperaGruppiGestori()
      .then((esito2: Esito) => {
        if (esito2.esito === ESITO_OK) {
          this.destinatari = JSON.parse(esito2.payload)
          // this.destinatariFiltrati = this.destinatari;
          //  Ora elimina quelle già selezionate
          // this.destinatariFiltrati = this.destinatariFiltrati.filter(destinatario => !destinatario.predefinito);
        } else {
          this.snackBar.open(
            $localize`Recupero elenco destinatari Fallito. Errore: ` +
              esito2.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero elenco destinatari Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

}
