<div class="flex-column">
  <app-toolbar-landing></app-toolbar-landing>
  <mat-toolbar class="centered flex-row toolbar-landing-extra">
    <div></div>
    <div class="testo-x-large centered align-items-center titolo-verifica-email" i18n>Attivazione doppio fattore</div>
    <div></div>
  </mat-toolbar>
  <div class="alternate-theme flex-column">
    <div *ngIf="imageSrc" style="margin:auto">
      <h3 i18n>
        Prendi il tuo telefono e apri un'APP di autenticazione a scelta tra Google Authenticator, Microsoft
        Authenticator e Authy. Queste APP sono liberamente scaricabili dagli store. Una volta avviata scansiona
        il codice QR qui sotto per attivare l'accesso tramite doppio fattore di autenticazione. In caso di dubbi
        contatta il nostro supporto.
      </h3>
    </div>
    <div *ngIf="imageSrc" style="margin:auto">
      <img [src]="imageSrc" alt="QR Code">
    </div>

    <div *ngIf="errore">
      <h3 i18n class="centered">
        {{errore}}
      </h3>
    </div>

    <a mat-button (click)="apriPaginaLogin()" class="centered">
      <i class="material-icons-outlined icona-documentazione-whistleblowing">description</i><span
        class="testo-documentazione-whistleblowing" i18n> Apri la pagina di login
      </span></a>

  </div>
</div>
