<h2 mat-dialog-title class="titolo-dialog">Informativa
</h2>
<mat-dialog-content>
  <div class="centra-form">
    <angular-editor [(ngModel)]="testo" [config]="editorConfig"></angular-editor>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button i18n mat-button class="center" (click)="chiudiDialog()">Chiudi</button>
</mat-dialog-actions>
