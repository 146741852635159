<mat-icon i18n-matTooltip matTooltip="Clicca per bloccare la nota in primo piano" (mouseover)="hover = true; focusInput(input)"
  (click)="fissato = !fissato; focusInput(input)" (mouseleave)="hover = false">
  info
</mat-icon>
<div class="flex-column" [style.visibility]="hover || fissato ? 'visible' : 'hidden'" class="custom-tooltip" >
  <mat-icon (click)="fissato = !fissato" [style.visibility]="fissato ? 'visible' : 'hidden'" style="z-index: 10;
position: absolute; margin-top:-8px; margin-right:64px; background-color: white;">
    close
  </mat-icon>
  <p i18n>Puoi parametrizzare il testo utilizzando le seguenti parole chiave:
    <li class="margine-sinistro" *ngFor="let parolaChiave of paroleChiave">{{parolaChiave}}</li>
  </p>
</div>
