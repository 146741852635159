import { SegnalazioneService } from './../../servizi/segnalazione.service'
import { Component, Inject, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Sort, MatSort } from '@angular/material/sort'
import { Segnalazione } from '../../modello/segnalazione'

import { statoSegnalazioneLeggibile, BOZZA_INDEX, compare, compareDates } from '../../utility/utility'
import { SessionData } from '../../sessione/dati-sessione'
import { Router } from '@angular/router'
import { Overlay } from '@angular/cdk/overlay'

import { ESITO_IN_PROGRESS, ESITO_OK } from '../../utility/esito'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { AuthService } from '../../servizi/auth.service'

@Component({
  selector: 'app-elenco-segnalazioni',
  templateUrl: './elenco-segnalazioni.dialog.component.html',
  styleUrls: ['./elenco-segnalazioni.dialog.component.scss']
})
export class ElencoSegnalazioniDialogComponent {
  @ViewChild(TemplateRef, { static: true }) _dialogTemplate: TemplateRef<any>


  dataSource
  sortedData
  segnalazioni: Segnalazione[]

  colonneVisualizzate = this.mostraTipiNonConformita ? ['id', 'oggetto', 'dataOra', 'descrizione', 'stato'] :
    ['id', 'oggetto', 'dataOra', 'stato']
  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor(private _overlay: Overlay, private _viewContainerRef: ViewContainerRef,
    public dialogRef: MatDialogRef<ElencoSegnalazioniDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Segnalazione[],
    private segnalazioniService: SegnalazioneService,
    private sessionData: SessionData,
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService) {
    this.segnalazioni = data['segnalazioni']
    // console.log('ricevuto: ' + JSON.stringify(this.segnalazioni));
    this.sortedData = this.segnalazioni.slice()
    this.dataSource = new MatTableDataSource(this.sortedData)
    this.dataSource.sort = this.sort
    this.dataSource.sortingDataAccessor = (item, property) => {
      const value = item[property]
      return typeof value === 'string' ? value.toLowerCase() : value
    }
    this.dataSource.paginator = this.paginator
  }

  sortData(sort: Sort) {
    // const data = this.segnalazioni.slice();
    // const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.sortedData = []

      this.segnalazioni.forEach((segnalazione) => {
        if (
          segnalazione.consensi &&
          Array.isArray(segnalazione.consensi) &&
          segnalazione.consensi.length
        ) {
          this.sortedData = [
            ...this.sortedData,
            {
              ...segnalazione,
              matData: new MatTableDataSource(segnalazione.consensi),
            },
          ]
        } else {
          this.sortedData = [...this.sortedData, segnalazione]
        }
      })

      this.dataSource = new MatTableDataSource(this.sortedData)
      this.dataSource.paginator = this.paginator
      // Reimposta l'indice della pagina corrente del paginatore a 0 dopo l'ordinamento
      this.paginator.firstPage()
    }

    this.sortedData = this.sortedData.sort((a, b) => {
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {
        case 'progressivo':
          return a && b ? compare(
            parseInt(a.progressivo?.toString(), 10),
            parseInt(b.progressivo?.toString(), 10),
            isAsc
          ) : 0
        case 'dataOra':
          return compareDates(
            new Date(a.dataInserimento),
            new Date(b.dataInserimento),
            isAsc
          )
        case 'oggetto':
          return compare(a.oggetto, b.oggetto, isAsc)
        case 'descrizione':
          return compare(a.descrizione, b.descrizione, isAsc)
        case 'stato':
          return compare(a.stato, b.stato, isAsc)
        case 'segnalante':
          return compare(a.anonima, b.anonima, isAsc)
        case 'scadenza':
          return compareDates(
            new Date(a.dataInvio),
            new Date(b.dataInvio),
            isAsc
          )
        default:
          return 0
      }
    })

    this.dataSource = new MatTableDataSource(this.sortedData)
  }

  get mostraTipiNonConformita() {
    return this.sessionData.configurazione?.mostraTipiNonConformitaSuDettaglioSegnalazione
  }

  _convertiStatoSegnalazione(indice): string {
    return statoSegnalazioneLeggibile(indice, !this.authService.getUser().odv)
  }

  statoSegnalazione(segnalazione: Segnalazione) {
    return this._convertiStatoSegnalazione(segnalazione !== undefined ? segnalazione.stato : BOZZA_INDEX.toString())
  }


  selected(segnalazione: Segnalazione) {
    // console.log('selected:' + segnalazione.oggetto);
    if (!segnalazione.dataInserimento) {
      this.snackBar.open($localize `Non detieni i diritti per la consultazione della segnalazione selezionata`, null, {
        duration: 6000,
      })
      return
    }
    SessionData.segnalazioneSelezionataElenco = segnalazione
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.segnalazioniService.recuperaSegnalazione(+segnalazione.id)
      .subscribe((esito) => {
        if (esito.esito === ESITO_OK) {
          this.sessionData.nuovaSegnalazioneSelezionata(JSON.parse(esito.payload))
          this.router.navigate(['/segnalazione'])
          this.chiudiDialog()
        } else if (esito.esito === ESITO_IN_PROGRESS) {
          // Gestisci lo stato in progress come preferisci
          // Ad esempio, potresti mostrare un messaggio di attesa all'utente
          console.log(esito.descrizioneEsito);
        } else {
          this.chiudiDialog()
          this.snackBar.open($localize `Non è stato possibile recuperare la segnalazione ` + JSON.stringify(esito.descrizioneEsito), null, {
            duration: 4000,
          })
        }
      },((errore) => {
        this.snackBar.open($localize `Non è stato possibile recuperare la segnalazione ` + segnalazione.id, null, {
          duration: 4000,
        })
      }))

  }

  chiudiDialog() {
    this.dialogRef.close()
  }
}

