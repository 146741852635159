<div mat-dialog-title class="text-center dialog-visualizzatore">{{titolo}}</div>
<mat-dialog-content (contextmenu)="disableRightClick($event)">
  <div class="flex-column">
    <div *ngIf="!audioVideo">
      <ngx-extended-pdf-viewer class="altezza-visualizzatore" *ngIf="documento?.base64" [base64Src]="base64Raw" useBrowserLocale="false"
        [language]="language" [showDownloadButton]="canDownload" [showPrintButton]="canPrint"
        [mobileFriendlyZoom]="true" [showFindButton]="false" [showBorders]="false" [showEditor]="false"
        [showOpenFileButton]="false" [zoom]="'page-width'" [showRotateButton]="rotateButtons" [showSidebarButton]="false"
        [showZoomButtons]="zoomButtons" [scrollMode]="0"
        [showPagingButtons]="true" [showPresentationModeButton]="false" [showPropertiesButton]="false"
        ></ngx-extended-pdf-viewer>
      <angular-editor *ngIf="!documento && testo" #infosegna [(ngModel)]="testo" [config]="editorConfigTb">
      </angular-editor>
    </div>

    <!-- se il documento è un audio o un video -->
    <div *ngIf="audioVideo">
      <app-video-player [videoKey]="videoKey" [tipo]="tipoMedia" [mediaFile]="mediaBlob"></app-video-player>
    </div>

  </div>
</mat-dialog-content>
<mat-dialog-actions class="flex-row flex-center-space-around">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button i18n *ngIf="!restituisceConsenso" mat-button [mat-dialog-close]="true">Chiudi</button>
  <button i18n *ngIf="restituisceConsenso" mat-button [mat-dialog-close]="true">Ho preso visione dell'informativa
    privacy</button>
</mat-dialog-actions>
