/* eslint-disable @typescript-eslint/naming-convention */
import { AuthService } from './../servizi/auth.service'

import { SegnalazioneService } from './../servizi/segnalazione.service'
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core'
import * as moment from 'moment'
import { ElencoSegnalazioniDialogComponent } from './elenco-segnalazioni-dialog/elenco-segnalazioni.dialog.component'
import { SessionData } from '../sessione/dati-sessione'
import { Segnalazione } from '../modello/segnalazione'
import { isMembroDiGruppo } from '../utility/helper-segnalazioni'
import { ThemeService } from '../servizi/theme.service'
import { MatDialog } from '@angular/material/dialog'
import { BaseChartDirective } from 'ng2-charts'

export function drawTextBG(ctx, txt, font, fillStyle, x, y) {
  /// lets save current state as we make a lot of changes
  ctx.save()

  /// set font
  ctx.font = font

  /// draw text from top - makes life easier at the moment
  ctx.textBaseline = 'top'

  /// get width of text
  const width = ctx.measureText(txt).width

  ctx.fillStyle = '#000000'
  /// draw background rect assuming height of font
  ctx.fillRect(x, y, width, 18)

  ctx.fillStyle = fillStyle
  /// draw text on top
  ctx.fillText(txt, x, y)

  /// restore original state
  ctx.restore()
}

export function stampaEtichetteTorta(datasets, chartInstance, ctx) {
  datasets.forEach(function (dataset, i) {
    const meta = chartInstance.controller.getDatasetMeta(i)
    meta.data.forEach(function (bar, index) {
      const data = dataset.data[index]
      const fillStyle = '#ffffff'
      const font = 'bold 18px verdana, sans-serif '
      const etichetta =
        bar._chart.chart.config.data.labels[bar._index] + ':' + data
      drawTextBG(
        ctx,
        bar._chart.chart.config.data.labels[bar._index] + ':' + data,
        font,
        fillStyle,
        bar._model.x -
          (etichetta.length / 2) * 12 +
          (bar._model.innerRadius + bar._model.outerRadius) *
            Math.cos((bar._model.endAngle + bar._model.startAngle) / 2),
        bar._model.y +
          (bar._model.innerRadius + bar._model.outerRadius) *
            Math.sin((bar._model.endAngle + bar._model.startAngle) / 2)
      )
    })
  })
}

@Component({
  selector: 'app-statistiche',
  templateUrl: './statistiche.component.html',
  styleUrls: ['./statistiche.component.scss'],
})
export class StatisticheComponent implements OnInit {
  @ViewChild('chartProvenienza', { static: true })
  chartProvenienza: BaseChartDirective

  @ViewChild('chartTema', { static: true })
  chartTema: BaseChartDirective

  @ViewChild('chartAmmissibilita', { static: true })
  chartAmmissibilita: BaseChartDirective

  @ViewChild('chartMotiviInammissibilita', { static: true })
  chartMotiviInammissibilita: BaseChartDirective

  @ViewChild('chartAzioniFollowUp', { static: true })
  chartAzioniFollowUp: BaseChartDirective

  @ViewChild('temaTutte', { static: true }) temaTutteEl: ElementRef
  @ViewChild('temaOdv', { static: true }) temaOdvEl: ElementRef
  @ViewChild('temaMie', { static: true }) temaMieEl: ElementRef

  @ViewChild('sorgenteTutte', { static: true }) sorgenteTutteEl: ElementRef
  @ViewChild('sorgenteOdv', { static: true }) sorgenteOdvEl: ElementRef
  @ViewChild('sorgenteMie', { static: true }) sorgenteMieEl: ElementRef

  @ViewChild('ammissibilitaTutte', { static: true })
  ammissibilitaTutteEl: ElementRef
  @ViewChild('ammissibilitaOdv', { static: true })
  ammissibilitaOdvEl: ElementRef
  @ViewChild('ammissibilitaMie', { static: true })
  ammissibilitaMieEl: ElementRef

  @ViewChild('azioniTutte', { static: true }) azioniTutteEl: ElementRef
  @ViewChild('azioniOdv', { static: true }) azioniOdvEl: ElementRef
  @ViewChild('azioniMie', { static: true }) azioniMieEl: ElementRef

  public chartColors: any[] = [
    {
      backgroundColor: [
        '#ffb3ba',
        '#ffdfba',
        '#ffffba',
        '#baffc9',
        '#bae1ff',
        '#FF7360',
        '#6FC8CE',
        '#FAFFF2',
        '#FFFCC4',
        '#B9E8E0',
      ],
    },
  ]

  selected: { start: moment.Moment; end: moment.Moment }

  ranges: any = {
    Sempre: [moment().subtract(50, 'year').startOf('year'), moment()],
    Oggi: [moment(), moment()],
    Ieri: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Ultimi 7 Giorni': [moment().subtract(6, 'days'), moment()],
    'Ultimi 30 Giorni': [moment().subtract(29, 'days'), moment()],
    'Il Mese Corrente': [moment().startOf('month'), moment().endOf('month')],
    'Il Mese Scorso': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  }

  // Grafico Provenienza
  public graficoProvenienzaLabels: string[] = []
  public graficoProvenienzaData: number[] = [0]
  public graficoProvenienzaType = 'pie'
  public customPlugin = {
    id: 'customPlugin',
    afterDraw: (chart, args, options) => {
      stampaEtichetteTorta(chart.data.datasets, chart, chart.ctx)
    },
  }

  public graficoProvenienzaOptions = {
    aspectRatio:1,
    canvas: {
      width: 200,
      height: 200
    },
    plugins: [this.customPlugin],
    legend: {
      labels: {
        fontColor: '#04424c',
        fontSize: 16,
      },
    },
    layout: {
      padding: {
        bottom: 15,
      },
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
    },
    responsive: true,
    onClick: (evt, item, chart) => {
      // const provenienza = item[0]['_model'].label;
      const provenienza =
        this.chartProvenienza.chart.config.data.labels[item[0]?._index]
      if (provenienza) {
        this.mostraSegnalazioniPerProvenienza(provenienza === $localize `Sconosciuta`? undefined : provenienza.toString())
      }
    },
  }
  // Grafico Ammissibilita
  public graficoAmmissibilitaLabels: string[] = ['']
  public graficoAmmissibilitaData: number[] = [0]
  public graficoAmmissibilitaType = 'doughnut'
  public graficoAmmissibilitaOptions: any = {
    plugins: [this.customPlugin],
    legend: {
      labels: {
        fontColor: '#04424c',
        fontSize: 16,
      },
    },
    layout: {
      padding: {
        bottom: 15,
      },
    },
    cutoutPercentage: 0,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    onClick: (evt, item, chart) => {
      const ammissibilita =
        this.chartAmmissibilita.chart.config.data.labels[item[0]?._index]
      if (ammissibilita) {
        this.mostraSegnalazioniPerAmmissibilita(
          ammissibilita === $localize `Ammissibili` ? '1' : '0'
        )
      }
    },
    responsive: true,
  }

  // Grafico Motivi Inammissibilita (barchart orizzontale)
  public graficoMotiviInammissibilitaLabels: string[] = ['']
  public graficoMotiviInammissibilitaType = 'bar'
  public graficoMotiviInammissibilitaLegend = false
  public graficoMotiviInammissibilitaData: any[] = [{ data: [5], label: [] }]

  public graficoMotiviInammissibilitaOptions: any = {
    options: {
      indexAxis: 'y',
    },
    plugins: {
      legend: {
        labels: {
          color: '#04424c',
          font: {
            size: 16,
          },
        },
      },
    },
    onClick: (evt, item, chart) => {
      const motivoInammissibilita =
        this.chartMotiviInammissibilita.chart.config.data.labels[item[0]?._index]
      if (motivoInammissibilita) {
        this.mostraSegnalazioniPerMotivoInammissibilita(
          motivoInammissibilita.toString()
        )
      }
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
    },
    scales: {
      x: {
        ticks: {
          color: '#04424c',
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#04424c',
          font: {
            size: 14,
          },
        },
        grid: {
          display: true,
        },
      },
    },
  }


  // Grafico Azioni di Follow Up (barchart orizzontale)
  public graficoAzioniFollowUpLabels: string[] = ['']
  public graficoAzioniFollowUpType = 'bar'
  public graficoAzioniFollowUpLegend = false
  public graficoAzioniFollowUpData: any[] = [{ data: [5], label: [] }]

  public graficoAzioniFollowUpOptions: any = {
    options: {
      indexAxis: 'y',
    },
    plugins: {
      legend: {
        labels: {
          color: '#04424c',
          font: {
            size: 16,
          },
        },
      },
    },
    onClick: (evt, item, chart) => {
      if (item.length > 0) {

        const azione = this.chartAzioniFollowUp.chart.config.data.labels[item[0]?._index]
        if (azione) {
          this.mostraSegnalazioniPerAzione(azione.toString())
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#04424c',
          font: {
            size: 14,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
      y: {
        ticks: {
          color: '#04424c',
          font: {
            size: 14,
          },
          stepSize: 1,
        },
      },
      yAxes: [{
        ticks: {
          beginAtZero: true // Questo fa iniziare l'asse y da zero
        }
      }]
    },
  }


  mostraMotiviInammissibilita = false

  //  Grafico Tema (barchart verticale)
  public graficoTemaLabels: string[] = ['']
  public graficoTemaType = 'bar'
  public graficoTemaLegend = false
  public graficoTemaData: any[] = [{ data: [5], label: [] }]
  public graficoTemaOptions: any = {
    plugins: {
      legend: {
        labels: {
          color: '#04424c',
          font: {
            size: 16,
          },
        },
      },
    },
    onClick: (evt, item, chart) => {
      if (item.length > 0) {
        // const tema = item[0].parsed.x;
        const tema =
        this.chartTema.chart.config.data.labels[item[0]?._index]

        this.mostraSegnalazioniPerTema(tema === $localize `Sconosciuta`? undefined : tema.toString())
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#04424c',
          font: {
            size: 14,
          },
        },
      },
      y: {
        ticks: {
          color: '#04424c',
          font: {
            size: 14,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
  }


  // Radar
  public radarChartLabels: string[] = [
    'Eating',
    'Drinking',
    'Sleeping',
    'Designing',
    'Coding',
    'Cycling',
    'Running',
  ]

  public radarChartData: any = [
    { data: [65, 59, 90, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 96, 27, 100], label: 'Series B' },
  ]
  public radarChartType = 'radar'
  filtroTema: string
  filtroSorgente: string
  filtroAmmissibilita: string
  filtroMotiviInammissibilita: string
  filtroAzioni: string

  lingua = 'it'

  constructor(
    private segnalazioniService: SegnalazioneService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.lingua = window.location.href.split('/')[3]
    if ('en' === this.lingua) {
      this.ranges = {
        Always: [moment().subtract(50, 'year').startOf('year'), moment()],
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Latest 7 days': [moment().subtract(6, 'days'), moment()],
        'Latest 30 days': [moment().subtract(29, 'days'), moment()],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'Previous Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
      }
    } else if ('fr' === this.lingua) {
      this.ranges = {
        Toujours: [moment().subtract(50, 'year').startOf('year'), moment()],
        'Aujourd\'hui': [moment(), moment()],
        Hier: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Derniers 7 jours': [moment().subtract(6, 'days'), moment()],
        'Derniers 30 jours': [moment().subtract(29, 'days'), moment()],
        'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
        'Le mois dernier': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
      }
    } else if ('es' === this.lingua) {
      this.ranges = {
        Siempre: [moment().subtract(50, 'year').startOf('year'), moment()],
        Hoy: [moment(), moment()],
        Ayer: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
        'Este mes': [moment().startOf('month'), moment().endOf('month')],
        'El mes pasado': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
      }
    }
  }

  ngOnInit() {
    this.selected = {
      start: SessionData.dataDa,
      end: SessionData.dataA,
    }
    this.initFilters()
  }

  recuperaDati(): any {
    this.recuperaStatistichePerSorgente()
    this.recuperaStatistichePerTema()
    this.recuperaStatistichePerAmmissibilita()
    this.recuperaStatistichePerMotiviInammissibilita()
    this.recuperaStatistichePerAzioni()
  }

  recuperaStatistichePerMotiviInammissibilita(): any {
    this.segnalazioniService
      .recuperaStatisticheMotiviInammissibilita(
        SessionData.dataDa.format('DDMMYYYY'),
        SessionData.dataA.format('DDMMYYYY'),
        'test',
        this.filtroAmmissibilita
      )
      .then((result) => {
        this.graficoMotiviInammissibilitaData = [{ data: [], label: [] }]
        // this.graficoMotiviInammissibilitaLabels = [];
        console.log(JSON.stringify(result))

        result.forEach((segnalazioniPerMotiviInammissibilita, indice) => {
          const label =
            segnalazioniPerMotiviInammissibilita[
              'lavorazione_motivoNonAmmissibilita'
            ] === null
              ? $localize`Sconosciuta`
              : segnalazioniPerMotiviInammissibilita[
                  'lavorazione_motivoNonAmmissibilita'
                ]
          this.graficoMotiviInammissibilitaLabels[indice] = label
          this.graficoMotiviInammissibilitaData[indice] =
            segnalazioniPerMotiviInammissibilita['count']
        })
        this.reloadChartMotiviInammissibilita()
      })
  }

  recuperaStatistichePerAzioni(): any {
    this.segnalazioniService
      .recuperaStatisticheAzioniFollowUp(
        SessionData.dataDa.format('DDMMYYYY'),
        SessionData.dataA.format('DDMMYYYY'),
        'test',
        this.filtroAzioni
      )
      .then((result) => {
        this.graficoAzioniFollowUpData = [{ data: [], label: [] }]
        // this.graficoAzioniFollowUpLabels.length = 0;
        // console.log(JSON.stringify(result))

        result.forEach((segnalazioniPerAzioniFollowUp, indice) => {
          const label =
            segnalazioniPerAzioniFollowUp['azione_azione'] === null
              ? $localize`Sconosciuta`
              : segnalazioniPerAzioniFollowUp['azione_azione']
          this.graficoAzioniFollowUpLabels[indice] = label
          this.graficoAzioniFollowUpData[indice] =
            segnalazioniPerAzioniFollowUp['count']
        })

        // result.forEach((segnalazioniPerAzioniFollowUp, indice) => {
        //   this.graficoAzioniFollowUpLabels[indice] =
        //     segnalazioniPerAzioniFollowUp['azione_azione'] == null ?
        //       'Sconosciuto' : segnalazioniPerAzioniFollowUp['azione_azione'];
        //       this.graficoAzioniFollowUpLabel.push(label);
        //   this.graficoAzioniFollowUpData[0]['data'].push(+segnalazioniPerAzioniFollowUp['count']);
        // });
        this.reloadChartAzioniFollowUp()
      })
  }

  recuperaStatistichePerSorgente(): any {
    this.segnalazioniService
      .recuperaStatisticheProvenienza(
        SessionData.dataDa.format('DDMMYYYY'),
        SessionData.dataA.format('DDMMYYYY'),
        'test',
        this.filtroSorgente
      )
      .then((result) => {
        this.graficoProvenienzaData.length = 0
        // console.log(JSON.stringify(result))
        this.graficoProvenienzaLabels.length = 0
        result.forEach((segnalazioniPerProvenienza, indice) => {
          const label =
            segnalazioniPerProvenienza['sorgente'] === null
              ? $localize`Sconosciuta`
              : segnalazioniPerProvenienza['sorgente']
          this.graficoProvenienzaLabels.push(label)
          this.graficoProvenienzaData[indice] =
            segnalazioniPerProvenienza['count']
        })
        this.reloadChartProvenienza()
      })
  }

  recuperaStatistichePerTema() {
    this.segnalazioniService
      .recuperaStatisticheTema(
        SessionData.dataDa.format('DDMMYYYY'),
        SessionData.dataA.format('DDMMYYYY'),
        'test',
        this.filtroTema
      )
      .then((result) => {
        this.graficoTemaLabels.length = 0
        this.graficoTemaData = [{ data: [], label: [] }]
        // this.graficoTemaData[]['label'].length = 0;
        // console.log(JSON.stringify(result))
        result.forEach((segnalazioniPerTema, indice) => {
          this.graficoTemaLabels[indice] =
            segnalazioniPerTema['tema'] === null
              ? $localize`Sconosciuta`
              : segnalazioniPerTema['tema']
          this.graficoTemaData[0]['data'].push(+segnalazioniPerTema['count']) //  = {'data': [], 'label': this.graficoTemaLabels[indice]};
          // this.graficoTemaData[0]['label'].push(this.graficoTemaLabels[indice]);
        })
        // console.log(JSON.stringify(this.graficoTemaData))
        this.reloadChartTema()
      })
  }

  recuperaStatistichePerAmmissibilita() {
    this.segnalazioniService
      .recuperaStatisticheAmmissibilita(
        SessionData.dataDa.format('DDMMYYYY'),
        SessionData.dataA.format('DDMMYYYY'),
        'test',
        this.filtroAmmissibilita
      )
      .then((result) => {
        this.graficoAmmissibilitaData.length = 0
        this.graficoAmmissibilitaLabels.length = 0
        // console.log(JSON.stringify(result))
        result.forEach((segnalazioniPerAmmissibilita, indice) => {
          this.graficoAmmissibilitaLabels[indice] =
            segnalazioniPerAmmissibilita['lavorazione_ammissibile'] === 0
              ? $localize`Non Ammissibili`
              : $localize`Ammissibili`
          this.graficoAmmissibilitaData[indice] =
            segnalazioniPerAmmissibilita['count']
        })
        this.reloadChartAmmissibili()
      })
  }

  public change(evento) {
    // console.log(evento)
    this.selected = evento
    SessionData.dataDa =
      this.selected && this.selected.start
        ? this.selected.start
        : moment().subtract(50, 'year').startOf('year')
    SessionData.dataA =
      this.selected && this.selected.end ? this.selected.end : moment()
    this.recuperaDati()
  }

  reloadChartProvenienza() {
    if (this.chartProvenienza !== undefined) {
      // this.chartProvenienza.datasets = this.graficoProvenienzaData;
      // this.chartProvenienza.options = this.graficoProvenienzaOptions;
      this.chartProvenienza.chart.config.data.labels =
        this.graficoProvenienzaLabels
      this.chartProvenienza.chart.update()
    }
  }

  reloadChartTema() {
    if (this.chartTema !== undefined) {
      /*       this.chartTema.ngOnDestroy();
            this.chartTema.labels = this.graficoTemaLabels;
            this.chartTema.datasets = this.graficoTemaData;
            this.chartTema.options = this.graficoTemaOptions;
            this.chartTema.chart = this.chartTema.getChartBuilder(this.chartTema.ctx); */
      this.chartTema.options = this.graficoTemaOptions
      this.chartTema.chart.update()
    }
  }

  reloadChartAmmissibili() {
    if (this.chartAmmissibilita !== undefined) {
      /*       this.chartAmmissibilita.ngOnDestroy();
            this.chartAmmissibilita.labels = this.graficoAmmissibilitaLabels;
            this.chartAmmissibilita.datasets = this.graficoAmmissibilitaData;
            this.chartAmmissibilita.options = this.graficoAmmissibilitaOptions;
            this.chartAmmissibilita.chart = this.chartAmmissibilita.getChartBuilder(this.chartAmmissibilita.ctx); */
      this.chartAmmissibilita.chart.update()
    }
  }

  reloadChartMotiviInammissibilita(): any {
    if (this.chartMotiviInammissibilita !== undefined) {
      /*       this.chartMotiviInammissibilita.ngOnDestroy();
            this.chartMotiviInammissibilita.labels = this.graficoMotiviInammissibilitaLabels;
            this.chartMotiviInammissibilita.datasets = this.graficoMotiviInammissibilitaData;
            this.chartMotiviInammissibilita.options = this.graficoMotiviInammissibilitaOptions;
            this.chartMotiviInammissibilita.chart = this.chartMotiviInammissibilita.getChartBuilder(this.chartMotiviInammissibilita.ctx); */
      this.chartMotiviInammissibilita.chart.update()
    }
  }

  reloadChartAzioniFollowUp(): any {
    if (this.chartAzioniFollowUp !== undefined) {
      /*       this.chartAzioniFollowUp.ngOnDestroy();
            this.chartAzioniFollowUp.labels = this.graficoAzioniFollowUpLabels;
            this.chartAzioniFollowUp.chart = this.chartAzioniFollowUp.getChartBuilder(this.chartAzioniFollowUp.ctx); */
      this.chartAzioniFollowUp.chart.update()
    }
  }

  toggleMotiviInammissibilita() {
    this.mostraMotiviInammissibilita = !this.mostraMotiviInammissibilita
  }

  mostraSegnalazioniPerAzione(azione: string) {
    console.log('mostra segnalazioni per azione: ' + azione)
    this.segnalazioniService
      .recuperaSegnalazioneByAzioneFollowUp(
        SessionData.dataDa.format('DDMMYYYY'),
        SessionData.dataA.format('DDMMYYYY'),
        azione,
        this.filtroAzioni
      )
      .then((esito) => {
        // console.log('esito:' + JSON.stringify(esito))
        this.openDialogElencoSegnalazioni(esito)
      })
      .catch((errore) => {
        console.log('errore:' + JSON.stringify(errore))
      })
  }

  mostraSegnalazioniPerTema(tema: string) {
    // console.log('mostra segnalazioni per tema: ' + this.filtroTema)
    this.segnalazioniService
      .recuperaSegnalazioneByTema(
        SessionData.dataDa.format('DDMMYYYY'),
        SessionData.dataA.format('DDMMYYYY'),
        tema,
        this.filtroTema
      )
      .then((esito) => {
        // console.log('esito:' + JSON.stringify(esito))
        this.openDialogElencoSegnalazioni(esito)
      })
      .catch((errore) => {
        console.log('errore:' + JSON.stringify(errore))
      })
  }

  mostraSegnalazioniPerProvenienza(provenienza: string) {
    // console.log('mostra segnalazioni per provenienza: ' + provenienza)
    this.segnalazioniService
      .recuperaSegnalazioneByProvenienza(
        SessionData.dataDa.format('DDMMYYYY'),
        SessionData.dataA.format('DDMMYYYY'),
        provenienza,
        this.filtroSorgente
      )
      .then((esito) => {
        // console.log('esito:' + JSON.stringify(esito))
        this.openDialogElencoSegnalazioni(esito)
      })
      .catch((errore) => {
        console.log('errore:' + JSON.stringify(errore))
      })
  }

  mostraSegnalazioniPerAmmissibilita(ammissibilita: string) {
    // console.log('mostra segnalazioni per ammissibilita: ' + ammissibilita)
    this.segnalazioniService
      .recuperaSegnalazioneByAmmissibilita(
        SessionData.dataDa.format('DDMMYYYY'),
        SessionData.dataA.format('DDMMYYYY'),
        +ammissibilita,
        this.filtroAmmissibilita
      )
      .then((esito) => {
        // console.log('esito:' + JSON.stringify(esito))
        this.openDialogElencoSegnalazioni(esito)
      })
      .catch((errore) => {
        console.log('errore:' + JSON.stringify(errore))
      })
  }

  mostraSegnalazioniPerMotivoInammissibilita(motivoInammissibilita: string) {
    // console.log('mostra segnalazioni per motivoInammissibilita: ' + motivoInammissibilita)
    this.segnalazioniService
      .recuperaSegnalazioneByMotivoInammissibilita(
        SessionData.dataDa.format('DDMMYYYY'),
        SessionData.dataA.format('DDMMYYYY'),
        motivoInammissibilita,
        this.filtroMotiviInammissibilita
      )
      .then((esito) => {
        // console.log('esito:' + JSON.stringify(esito))
        this.openDialogElencoSegnalazioni(esito)
      })
      .catch((errore) => {
        console.log('errore:' + JSON.stringify(errore))
      })
  }

  openDialogElencoSegnalazioni(segnalazioni: Segnalazione[]): void {
    //  Anonimizza le segnalazioni non in carico
    const anonimizza = segnalazioni.filter(
      (segnalazione) =>
        segnalazione.dirittiSegnalazioneOdv.findIndex((diritto) =>
          isMembroDiGruppo(this.authService.getUser(), diritto.odvDestinatario)
        ) === -1
    )
    anonimizza.forEach((segnalazione) => {
      segnalazione.oggetto = 'Anonimizzata'
      segnalazione.id = 'Anonimizzata'
      segnalazione.dataInserimento = undefined
      segnalazione.descrizione = 'Anonimizzata'
      segnalazione.stato = 'Anonimizzata'
      const indice = segnalazioni.findIndex(
        (tmpsegnalazione) => tmpsegnalazione.id === segnalazione.id
      )
      segnalazioni.splice(indice, 1, segnalazione)
    })
    const dialogRef = this.dialog.open(ElencoSegnalazioniDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      hasBackdrop: true,
      data: { segnalazioni: segnalazioni },
    })

    dialogRef.afterClosed().subscribe((result) => {})
  }

  onFiltroSorgenteChange(valore) {
    // console.log('valore selezionato:' + valore)
    this.filtroSorgente = valore
    SessionData.statisticheSorgenteMie = false
    SessionData.statisticheSorgenteOdv = false
    SessionData.statisticheSorgenteTutte = false
    switch (valore) {
      case 'USER':
        SessionData.statisticheSorgenteMie = true
        break
      case 'ODV':
        SessionData.statisticheSorgenteOdv = true
        break
      case 'TUTTE':
        SessionData.statisticheSorgenteTutte = true
        break
    }
    this.recuperaStatistichePerSorgente()
  }

  onFiltroTemaChange(valore) {
    // console.log('valore selezionato:' + valore)
    this.filtroTema = valore
    SessionData.statisticheTemaMie = false
    SessionData.statisticheTemaOdv = false
    SessionData.statisticheTemaTutte = false
    switch (valore) {
      case 'USER':
        SessionData.statisticheTemaMie = true
        break
      case 'ODV':
        SessionData.statisticheTemaOdv = true
        break
      case 'TUTTE':
        SessionData.statisticheTemaTutte = true
        break
    }
    this.recuperaStatistichePerTema()
  }

  onFiltroAmmissibilitaChange(valore) {
    // console.log('valore selezionato:' + valore)
    this.filtroAmmissibilita = valore
    SessionData.statisticheInammissibilitaMie = false
    SessionData.statisticheInammissibilitaOdv = false
    SessionData.statisticheInammissibilitaTutte = false
    switch (valore) {
      case 'USER':
        SessionData.statisticheInammissibilitaMie = true
        break
      case 'ODV':
        SessionData.statisticheInammissibilitaOdv = true
        break
      case 'TUTTE':
        SessionData.statisticheInammissibilitaTutte = true
        break
    }
    this.recuperaStatistichePerAmmissibilita()
    this.recuperaStatistichePerMotiviInammissibilita()
  }

  onFiltroAzioniChange(valore) {
    // console.log('valore selezionato:' + valore)
    this.filtroAzioni = valore
    SessionData.statisticheAzioniMie = false
    SessionData.statisticheAzioniOdv = false
    SessionData.statisticheAzioniTutte = false
    switch (valore) {
      case 'USER':
        SessionData.statisticheAzioniMie = true
        break
      case 'ODV':
        SessionData.statisticheAzioniOdv = true
        break
      case 'TUTTE':
        SessionData.statisticheAzioniTutte = true
        break
    }
    this.recuperaStatistichePerAzioni()
  }

  initFilters() {
    this.temaTutteEl['checked'] = SessionData.statisticheTemaTutte === true
    this.temaMieEl['checked'] = SessionData.statisticheTemaMie === true
    this.temaOdvEl['checked'] = SessionData.statisticheTemaOdv === true

    this.sorgenteTutteEl['checked'] =
      SessionData.statisticheSorgenteTutte === true
    this.sorgenteMieEl['checked'] = SessionData.statisticheSorgenteMie === true
    this.sorgenteOdvEl['checked'] = SessionData.statisticheSorgenteOdv === true

    this.ammissibilitaTutteEl['checked'] =
      SessionData.statisticheInammissibilitaTutte === true
    this.ammissibilitaMieEl['checked'] =
      SessionData.statisticheInammissibilitaMie === true
    this.ammissibilitaOdvEl['checked'] =
      SessionData.statisticheInammissibilitaOdv === true

    this.azioniTutteEl['checked'] = SessionData.statisticheAzioniTutte === true
    this.azioniMieEl['checked'] = SessionData.statisticheAzioniMie === true
    this.azioniOdvEl['checked'] = SessionData.statisticheAzioniOdv === true

    if (SessionData.statisticheTemaMie) {
      this.filtroTema = 'USER'
    } else if (SessionData.statisticheTemaOdv) {
      this.filtroTema = 'ODV'
    } else {
      this.filtroTema = 'TUTTE'
    }
    this.recuperaStatistichePerTema()

    if (SessionData.statisticheSorgenteMie) {
      this.filtroSorgente = 'USER'
    } else if (SessionData.statisticheSorgenteOdv) {
      this.filtroSorgente = 'ODV'
    } else {
      this.filtroSorgente = 'TUTTE'
    }
    this.recuperaStatistichePerSorgente()

    if (SessionData.statisticheInammissibilitaMie) {
      this.filtroAmmissibilita = 'USER'
    } else if (SessionData.statisticheInammissibilitaOdv) {
      this.filtroAmmissibilita = 'ODV'
    } else {
      this.filtroAmmissibilita = 'TUTTE'
    }
    this.recuperaStatistichePerAmmissibilita()
    this.recuperaStatistichePerMotiviInammissibilita()

    if (SessionData.statisticheAzioniMie) {
      this.filtroAzioni = 'USER'
    } else if (SessionData.statisticheAzioniOdv) {
      this.filtroAzioni = 'ODV'
    } else {
      this.filtroAzioni = 'TUTTE'
    }
    this.recuperaStatistichePerAzioni()
  }
}
