import { InputFileComponent } from './../componenti/input-file/input-file.component'
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  OnChanges,
  ChangeDetectorRef,
  SimpleChange,
} from '@angular/core'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { FileValidators } from '../validators/file-validators'

import { Documento, TipoDocumento } from '../modello/documento'
import { compare, MAX_FILE_SIZE_MB, processFile } from '../utility/utility'
import { ConfermaEliminazioneDocumentoDialogComponent } from './conferma-eliminazione-documento.dialog.component'
import { MatSort, Sort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { Subscription } from 'rxjs'
import { Omissis } from '../modello/omissis'
import { ESITO_OK } from '../utility/esito'
import { DirittiSegnalazione } from '../modello/diritti-segnalazione'
import { DocumentiService } from '../servizi/documenti.service'
import { VisualizzatorePdfDialogComponent } from '../componenti/visualizzatore-pdf/visualizzatore-pdf.component'
import { Segment } from '../dettaglio-segnalazione/dettaglio-segnalazione.component'
import { OscuramentoDatiManifestamenteNonNecessari } from '../modello/oscuramento-dati-manifestamente-non-necessari'
import { OscuramentiService } from '../servizi/oscuramenti.service'

@Component({
  selector: 'app-elenco-allegati',
  templateUrl: './elenco-allegati.component.html',
  styleUrls: ['./elenco-allegati.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElencoAllegatiComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  dataSourceDocumenti: any
  formDoc: UntypedFormGroup
  baseUrl: string
  fileToUpload: Array<File> = null

  documento: Documento

  @Input() disabilitato: boolean
  @Input() dirittiSegnalazione: DirittiSegnalazione
  @Input() omissis: Omissis[]
  @Input() modalitaOmissis: boolean
  @Input() canaleDocAggiunto: string
  @Input() canaleDocAggiornato: string
  @Input() canaleDocEliminato: string
  @Input() tipoEntitaPrincipale: string
  @Input() entitaPrincipale: object
  @Input() processoOscuramento: OscuramentoDatiManifestamenteNonNecessari
  @Input() modalitaOscuramento: boolean

  @Input() documenti: Documento[]

  @Output() eseguiAbilitaCondivisioneDocumento: EventEmitter<Documento> =
    new EventEmitter()
  @Output() eseguiDisabilitaCondivisioneDocumento: EventEmitter<Documento> =
    new EventEmitter()
  @Output() salvaDocumento: EventEmitter<Documento> = new EventEmitter()

  @Output() aggiornaDocumenti: EventEmitter<void> = new EventEmitter()
  @Output() unlinkDocumento: EventEmitter<Documento> = new EventEmitter()

  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild('file', { static: false }) file

  @ViewChild(InputFileComponent, { static: true })
  inputfile: InputFileComponent
  allegatoSegment: Segment[]
  segmentCache: any

  get colonneVisualizzate() {
    return this.isSmallScreen ? ['nome', 'azioni'] : ['id', 'nome', 'descrizione', 'azioni']
  }

  sortedData

  private subscriptions: Array<Subscription> = []

  constructor(
    public dialog: MatDialog,
    private segnalazioniService: SegnalazioneService,
    private _fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private documentiService: DocumentiService,
    private cdr: ChangeDetectorRef,
    private oscuramentiService: OscuramentiService
  ) { }
  ngOnDestroy(): void {
    this.subscriptions?.forEach((sub) => sub.unsubscribe())
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    // create header using child_id
    // console.log(this.documenti)
    this.refresh()
    // verifico se è cambiato modalitaOscuramento
    if (changes.modalitaOscuramento?.currentValue || changes.modalitaOmissis?.currentValue) {
      if (this.modalitaOscuramento || this.modalitaOmissis) {
        this.initSegment()
      }
    }

  }

  ngAfterViewInit() {
    Promise.resolve(null).then(() => this.refresh())
    if (this.paginator) {
      const paginatorIntl = this.paginator._intl
      paginatorIntl.nextPageLabel = ''
      paginatorIntl.previousPageLabel = ''
      paginatorIntl.lastPageLabel = ''
      paginatorIntl.firstPageLabel = ''
    }
  }

  refresh() {
    // elimina i duplicati
    this.documenti = this.documenti?.filter(
      (doc, index, self) =>
        index === self.findIndex((t) => t.id? t.id === doc.id : t.nome === doc.nome)
    )
    if (this.documenti && this.dataSourceDocumenti) {
      this.dataSourceDocumenti.data = [...this.documenti]
    }
    this.cdr.detectChanges()
  }

  ngOnInit() {

    this.subscriptions.push(
      this.segnalazioniService.notificaSalvataggioDocumenti.subscribe(
        (allegati: Documento[]) => {
          this.documenti = allegati
          this.refresh()
        }
      )
    )

    this.subscriptions.push(
      this.segnalazioniService.notificaDocumentoSalvato.subscribe(
        (documento: Documento) => {
          if (!this.documenti) {
            this.documenti = new Array<Documento>()
          }
          const tmpDocumento = this.documenti
            ? this.documenti.find((tmpDoc) => tmpDoc.nome === documento.nome)
            : undefined
          if (tmpDocumento) {
            tmpDocumento.id = documento.id
            tmpDocumento.hash = documento.hash
            tmpDocumento.nome = documento.nome
            tmpDocumento.descrizione = documento.descrizione
          } else {
            this.documenti.push(documento)
          }
          console.log('ds prima di refresh')
          this.refresh()
          console.log('ds dopo refresh')
        }
      )
    )

    /*     this.segnalazioniService.notificaPerElencoDocumentiDaAggiornare.subscribe(({canale, documenti}) => {
          if (this.canale === canale) {
            this.documenti = documenti;
            this.refresh();
          }
        }); */

    this.subscriptions.push(
      this.segnalazioniService.notificaPerDocumentoEliminato.subscribe(
        (pacchetto) => {
          if (this.canaleDocEliminato === pacchetto.canale) {
            const indice = this.documenti
              ? this.documenti.findIndex(
                (tmpDoc) => +tmpDoc.id === +pacchetto.idDocumento
              )
              : -1
            if (indice !== -1) {
              this.documenti.splice(indice, 1)
            }
            console.log('de prima di refresh')
            this.refresh()
            console.log('de dopo refresh')
          }
        }
      )
    )

    this.subscriptions.push(
      this.segnalazioniService.notificaPerDocumentoAggiornato.subscribe(
        (pacchetto) => {
          if (this.canaleDocAggiornato === pacchetto.canale) {
            const indice = this.documenti
              ? this.documenti.findIndex(
                (tmpDoc) => tmpDoc.nome === pacchetto.documento.nome
              )
              : -1
            if (indice !== -1) {
              this.documenti.splice(indice, 1, pacchetto.documento)
            } else {
              this.documenti.unshift(pacchetto.documento)
            }
            console.log('da prima di refresh')
            this.refresh()
            console.log('da dopo refresh')
          }
        }
      )
    )

    this.subscriptions.push(
      this.segnalazioniService.notificaPerDocumentoAggiunto.subscribe(
        (pacchetto) => {
          if (this.canaleDocAggiunto === pacchetto.canale) {
            this.refresh()
            const indice = this.documenti
              ? this.documenti.findIndex(
                (tmpDoc) => tmpDoc.nome === pacchetto.documento.nome
              )
              : -1
            if (indice === -1) {
              this.documenti.unshift(pacchetto.documento)
            }
            console.log('d+ prima di refresh')
            this.refresh()
            console.log('d+ dopo refresh')
          }
        }
      )
    )
    this.formDoc = this._fb.group({
      basicfile: [],
      files: [
        { value: undefined, disabled: false },
        [FileValidators.maxContentSize(104857600)],
      ],
      disabledfile: [{ value: undefined, disabled: true }],
      multiplefile: [{ value: undefined, disabled: false }],
    })

    if (!this.documenti) {
      this.documenti = new Array<Documento>()
    }

    this.dataSourceDocumenti = new MatTableDataSource<Documento>(
      this.documenti
    )
    this.dataSourceDocumenti.sort = this.sort
    this.dataSourceDocumenti.paginator = this.paginator

    window.addEventListener('resize', () => {
      this.isSmallScreen = window.innerWidth < 500
      this.refresh()
    })
    // console.log('Mi occupo del canale allegati ' + this.canale);
  }

  selected(documento) {
    this.documento = documento
    // console.log('selected:' + documento.id);
  }


  visualizzaDocumento(documento) {
    if (documento.nome === 'OMISSIS' || documento.nome === '*OMISSIS*') {
      this.snackBar.open($localize`Non sei abilitato al download del documento!`, null, {
        duration: 6000,
      })
      return
    }

    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: documento,
        testo: undefined, titolo: $localize`Visualizzazione documento`,
        tipoDocumento: TipoDocumento.TIPO_ALLEGATO,
        bypassAuth: false,
        canDownload: false,
        canPrint: false,
      }
    })

  }

  abilitaCondivisioneDocumento(documento) {
    this.eseguiAbilitaCondivisioneDocumento.emit(documento)
  }

  disabilitaCondivisioneDocumento(documento) {
    this.eseguiDisabilitaCondivisioneDocumento.emit(documento)
  }

  sortData(sort: Sort) {
    const data = this.documenti.slice()
    if (!sort.active || sort.direction === '') {
      this.sortedData = data
      return
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {
        case 'id':
          return compare(a.id, b.id, isAsc)
        case 'nome':
          return compare(a.nome, b.nome, isAsc)
        case 'descrizione':
          return compare(a.descrizione, b.descrizione, isAsc)
        default:
          return 0
      }
    })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
    this.dataSourceDocumenti.filter = filterValue
  }

  onSubmit() {
    this.updateElencoDocumenti()
    this.file.nativeElement.value = null
  }

  updateElencoDocumenti() {
    if (this.fileToUpload == null || this.fileToUpload.length === 0) {
      // console.log('No file selected!');
      return
    }
    const promises = this.fileToUpload.map(file => new Promise<void>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = async (e) => {
        // you can perform an action with readed data here
        const base64 = await processFile(file)


        const documento = new Documento(
          reader.result.toString(),
          file.name,
          file.type,
          null
        )
        documento['file'] = file
        if (base64) {
          documento.base64 = base64
        }
        documento.base64 = documento.base64.replace(/^data:audio\/mpeg;base64,/, '')
        documento.base64 = documento.base64.replace(/^video\/mp4;base64,/, '')
        let scartaDuplicato = false
        if (this.documenti) {
          this.documenti.forEach((tmpDoc) => {
            if (tmpDoc.nome === documento.nome) {
              scartaDuplicato = true
            }
          })
        }

        // that.formDoc.controls['files'].setValue(null);

        if (!scartaDuplicato) {
          if (!this.documenti) {
            this.documenti = new Array<Documento>()
          }

          this.documentiService.aggiungiDocumento.next({
            tipoEntitaPrincipale: this.tipoEntitaPrincipale,
            entitaPrincipale: this.entitaPrincipale,
            documento: documento,
          })
        } else {
          this.snackBar.open(
            `Il documento ${documento.nome} è già presente e non verrà riallegato! `,
            null,
            {
              duration: 6000,
            }
          )
          return
        }
      // alla fine del codice
      resolve()
      }

      reader.onerror = (error) => {
        reject(error)
      }

      reader.readAsDataURL(file)
    }
    ))
    Promise.all(promises).then(() => {
      this.fileToUpload = null
    }).catch(error => {
      console.error('Si è verificato un errore durante la lettura dei file:', error)
    })
  }

  aggiungiNuovoDocumento(documento: Documento) {
    // this.documenti.push(documento);
    console.log('prima di refresh')
    this.refresh()
    console.log('dopo refresh')
  }

  salvaSuInvio(event, documento) {
    if (event.key === 'Enter') {
      // console.log(event);
      this.salvaDocumento.emit(documento)
    }
  }

  salvaDocumentoButton(documento) {
    this.salvaDocumento.emit(documento)
  }

  esistonoAllegati(): boolean {
    return this.fileToUpload == null || this.fileToUpload.length === 0
      ? false
      : true
  }

  openDialogConfermaEliminazioneDocumento(documento): void {
    const dialogRef = this.dialog.open(
      ConfermaEliminazioneDocumentoDialogComponent,
      {
        width: '500px',
        data: { id: documento.id, nome: documento.nome },
        disableClose: true,
        hasBackdrop: true,
      }
    )

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.unlinkDocumento.emit(documento)
      }
    })
  }

  async onFilesAdded(files: FileList) {
    this.fileToUpload = Array.from(files)
    const promises = this.fileToUpload.map(async (file) => {
      // verifica che il file sia pdf, jpeg o mp4
      if (
        !file.type.includes('pdf') &&
        !file.type.startsWith('image/') &&
        !file.type.includes('mp4') &&
        !file.type.includes('audio/mp')
      ) {
        this.fileToUpload.splice(this.fileToUpload.indexOf(file))
        this.snackBar.open(
          // eslint-disable-next-line max-len
          $localize`Il formato del file ${file.name} non è consentito e non può essere allegato. I formati permessi sono pdf, jpeg, png, mp3, ogg o mp4`,
          null,
          {
            duration: 12000,
          }
        )
      } else if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        this.fileToUpload.splice(this.fileToUpload.indexOf(file))
        this.snackBar.open(
          $localize`Il file ${file.name} supera il limite dei ${MAX_FILE_SIZE_MB} MB e non può essere allegato`,
          null,
          {
            duration: 12000,
          }
        )
      } else if (file.size === 0) {
        this.fileToUpload.splice(this.fileToUpload.indexOf(file))
        this.snackBar.open(
          $localize`Il file ${file.name} è vuoto e verrà rimosso!!!`,
          null,
          {
            duration: 4000,
          }
        )
      }
    })
    Promise.all(promises).then(() => {
      // Questo codice verrà eseguito solo dopo che tutte le promesse nel tuo array di promesse sono state risolte.
      this.onSubmit()
    })
  }

  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight)
  }

  eliminaAllegato(allegato) {
    this.fileToUpload = this.fileToUpload.filter(
      (file) => file.name !== allegato.name
    )
  }

  nonPuoiGestireOmissis() {
    this.snackBar.open($localize`Si tratta già di un omissis`, null, {
      duration: 4000,
    })
  }

  // toggleOmissis(campo, parola, idEntita, tutto?: boolean) {
  //   // console.log('Omissis del campo ' + campo + ' parola ' + parola)

  //   if (this.omissis === undefined) {
  //     this.omissis = []
  //   }
  //   //  verifico se la parola è già tra gli omissis
  //   const omissisIndex = this.dirittiSegnalazione.omissis.findIndex(
  //     (omi) =>
  //       omi.campo === campo &&
  //       omi.omissis.toString() === parola.toString() &&
  //       omi.idEntita === idEntita
  //   )

  //   if (omissisIndex === -1) {
  //     //  la parola non è tra gli omissis la includo
  //     const omissis = new Omissis()
  //     omissis.campo = campo
  //     omissis.omissis = parola
  //     omissis.dirittiSegnalazione = this.dirittiSegnalazione
  //     omissis.idEntita = idEntita
  //     omissis.tutto = tutto
  //     this.segnalazioniService
  //       .aggiungiOmissisDirittiSegnalazione(omissis)
  //       .subscribe(
  //         (esito) => {
  //           if (esito.esito === ESITO_OK) {
  //             // console.log(esito);

  //             const omi = JSON.parse(esito.payload) as Omissis
  //             omi.dirittiSegnalazione = undefined
  //             this.dirittiSegnalazione.omissis.push(omi)
  //             this.cdr.detectChanges()
  //           } else {
  //             this.snackBar.open($localize `Aggiornamento Omissis Fallito. Errore: ` + esito.payload,
  //               null,
  //               {
  //                 duration: 4000,
  //               }
  //             )
  //           }
  //         },
  //         (err) => {
  //           this.snackBar.open($localize `Aggiornamento Omissis Fallito. Errore: ` + err.toString(),
  //             null,
  //             {
  //               duration: 4000,
  //             }
  //           )
  //         }
  //       )
  //   } else {
  //     //  la parola è tra gli omissis la escludo
  //     this.segnalazioniService
  //       .eliminaOmissisDirittiSegnalazione(
  //         this.dirittiSegnalazione.omissis[omissisIndex]
  //       )
  //       .then((esito) => {
  //         if (esito.esito === ESITO_OK) {
  //           // console.log(esito);

  //           this.dirittiSegnalazione.omissis.splice(omissisIndex, 1)
  //           this.cdr.detectChanges()
  //         } else {
  //           this.snackBar.open($localize `Aggiornamento Omissis Fallito. Errore: ` + esito.payload,
  //             null,
  //             {
  //               duration: 4000,
  //             }
  //           )
  //         }
  //       })
  //       .catch((err) => {
  //         this.snackBar.open($localize `Aggiornamento Omissis Fallito. Errore: ` + err.toString(),
  //           null,
  //           {
  //             duration: 4000,
  //           }
  //         )
  //       })
  //   }
  // }

  isOmissis(idDocumento: any) {
    // trovo il segmento da aggiornare
    const segmento = this.allegatoSegment?.find((seg) => seg[0].idEntita === idDocumento)
    return segmento ? segmento[0].isOmissis : undefined
  }

  toggleOmissis(idDocumento: number) {
    // console.log('Omissis del campo ' + campo + ' parola ' + parola)

    let segmento = this.allegatoSegment.find((seg) => seg[0].idEntita === idDocumento)
    if (!segmento) {
      return
    }
    segmento = segmento[0]
    segmento.isOmissis = !segmento.isOmissis
    // se non è più omissis la elimino
    if (!segmento.isOmissis) {
      // se mi trovo nel processo di oscuramento
      if (this.modalitaOscuramento) {
        const omissisIndex = this.processoOscuramento.omissis.findIndex(
          (omi) =>
            omi.campo === segmento.campo &&
            omi.progressivo === segmento.progressivo &&
            omi.idEntita === segmento.idEntita
        )
        if (omissisIndex === -1) {
          return
        }
        this.oscuramentiService
          .eliminaOmissisOscuramento(
            this.processoOscuramento.omissis[omissisIndex].id
          )
          .subscribe(
            (esito) => {
              if (esito.esito === ESITO_OK) {
                // console.log(esito);
                this.processoOscuramento.omissis.splice(omissisIndex, 1)
                this.aggiornaDocumenti.emit()
              } else {
                this.snackBar.open(
                  $localize`Aggiornamento Omissis Fallito. Errore: ` +
                  esito.payload,
                  null,
                  {
                    duration: 4000,
                  }
                )
              }
            },
            (err) => {
              this.snackBar.open(
                $localize`Aggiornamento Omissis Fallito. Errore: ` +
                err.toString(),
                null,
                { duration: 4000 }
              )
            }
          )
      } else {
        //  la parola è tra gli omissis la escludo
        // trovo l'omissis da eliminare tra gli omissis della segnalazione
        const omissisIndex = this.dirittiSegnalazione.omissis.findIndex(
          (omi) =>
            omi.campo === segmento.campo &&
            omi.progressivo === segmento.progressivo &&
            omi.idEntita === segmento.idEntita
        )
        this.segnalazioniService
          .eliminaOmissisDirittiSegnalazione(
            this.dirittiSegnalazione.omissis[omissisIndex]
          )
          .then((esito) => {
            if (esito.esito === ESITO_OK) {
              // console.log(esito);

              this.dirittiSegnalazione.omissis.splice(omissisIndex, 1)
            } else {
              this.snackBar.open(
                $localize`Aggiornamento Omissis Fallito. Errore: ` +
                esito.payload,
                null,
                {
                  duration: 4000,
                }
              )
            }
          })
          .catch((err) => {
            this.snackBar.open(
              $localize`Aggiornamento Omissis Fallito. Errore: ` +
              err.toString(),
              null,
              {
                duration: 4000,
              }
            )
          })
      }
    } else {
      if (this.modalitaOscuramento) {
        //  la parola non è tra gli omissis la includo

        const omissis = new Omissis()
        omissis.campo = segmento.campo
        omissis.progressivo = segmento.progressivo
        omissis.idEntita = segmento.idEntita
        omissis.tutto = true
        omissis.oscuramentoDatiManifestamenteNonNecessari =
          this.processoOscuramento
        this.oscuramentiService.aggiungiOmissisOscuramento(omissis).subscribe(
          (esito) => {
            if (esito.esito === ESITO_OK) {
              // console.log(esito);

              const omi = JSON.parse(esito.payload) as Omissis
              omi.oscuramentoDatiManifestamenteNonNecessari = undefined
              if (!this.processoOscuramento.omissis) {
                this.processoOscuramento.omissis = []
              }
              this.processoOscuramento.omissis.push(omi)
              this.omissis.push(omi)
            } else {
              this.snackBar.open(
                $localize`Aggiornamento Omissis Fallito. Errore: ` +
                esito.payload,
                null,
                {
                  duration: 4000,
                }
              )
            }
          },
          (err) => {
            this.snackBar.open(
              $localize`Aggiornamento Omissis Fallito. Errore: ` +
              err.toString(),
              null,
              { duration: 4000 }
            )
          }
        )
      } else {
        //  la parola non è tra gli omissis la includo

        const omissis = new Omissis()
        omissis.campo = segmento.campo
        omissis.dirittiSegnalazione = this.dirittiSegnalazione
        omissis.idEntita = segmento.idEntita
        omissis.tutto = true
        omissis.progressivo = segmento.progressivo
        this.segnalazioniService
          .aggiungiOmissisDirittiSegnalazione(omissis)
          .subscribe(
            (esito) => {
              if (esito.esito === ESITO_OK) {
                // console.log(esito);

                const omi = JSON.parse(esito.payload) as Omissis
                omi.dirittiSegnalazione = undefined
                this.dirittiSegnalazione.omissis.push(omi)
              } else {
                this.snackBar.open(
                  $localize`Aggiornamento Omissis Fallito. Errore: ` +
                  esito.payload,
                  null,
                  {
                    duration: 4000,
                  }
                )
              }
            },
            (err) => {
              this.snackBar.open(
                $localize`Aggiornamento Omissis Fallito. Errore: ` +
                err.toString(),
                null,
                {
                  duration: 4000,
                }
              )
            }
          )
      }
    }
  }

  segnalazioneHaID() {
    return this.entitaPrincipale['id'] !== undefined
  }

  public isSmallScreen = window.innerWidth < 640


  initSegment() {
    this.allegatoSegment = []
    this.segmentCache = {}

    this.documenti?.forEach((doc) => {
      this.allegatoSegment.push(this.getSegments(
        'id',
        doc,
        true
      ) as Segment)
    })
  }

  getSegments(
    field: string,
    entita: object,
    tutto?: boolean
  ): Segment[] | Segment {
    const cacheKey = `${field}-${entita['id']}-${tutto}`
    if (!this.segmentCache[cacheKey]) {
      this.segmentCache[cacheKey] = this.segmenti(field, entita, tutto)
    }
    return this.segmentCache[cacheKey]
  }

  segmenti(campo: string, entita: object, tutto?: boolean): Segment[] {

    let attributo = ''

    // Se il campo contiene un punto, accedi all'ultimo campo annidato

    if (campo.includes('.')) {
      const campiAnnidati = campo.split('.')
      // accedi all'ultimo campo annidato
      attributo = entita[campiAnnidati[campiAnnidati.length - 1]]
    } else {
      attributo = entita[campo] || ''
    }

    const parole = tutto ? [attributo] : attributo.split(' ')

    const omissisData =
      this.getOmissisData(campo)

    const segments: Segment[] = parole?.map((parola, index) => {
      const isOmissis =
        omissisData?.find((o) => o.progressivo === index && (o.idEntita === 0 || o.idEntita === entita['id'])) !== undefined
      return {
        campo: campo,
        parola: parola,
        progressivo: index, // usa un progressivo invece di start e end
        isOmissis: isOmissis || entita['nome'] === 'OMISSIS' || entita['nome'] === '*OMISSIS*',
        idEntita:
          entita['id'], // se è la segnalazione non metto l'id entità,
      } as Segment
    })

    return segments
  }

  getOmissisData(campo: string) {
    if (this.modalitaOmissis && !this.modalitaOscuramento) {
      return this.dirittiSegnalazione.omissis !== undefined
        ? this.dirittiSegnalazione.omissis.filter((om) => om.campo === campo)
        : undefined
    } else if (this.modalitaOscuramento) {
      return this.processoOscuramento.omissis?.filter((om) => om.campo === campo)
    }
  }

  get codiceLingua() {
    // ricava il codice della lingua corrente dalla url
    return window.location.href.split('/')[3]
  }

  get isRtl() {
    return ['ar', 'he', 'fa'].includes(this.codiceLingua)
  }

}
