import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core'
import { MatSliderChange } from '@angular/material/slider'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Subscription } from 'rxjs'
import { AudioService } from '../../servizi/audio.service'
import { AuthService } from '../../servizi/auth.service'
import { EVENT_MESSAGGIO_INVIATO } from '../../servizi/event.contants'
import { EventService } from '../../servizi/event.service'
import { SessionData } from '../../sessione/dati-sessione'
import {
  convertBlobToArrayBuffer,
  modificaPitchAudio,
} from '../../utility/audio-helper'

@Component({
  selector: 'app-recorder',
  templateUrl: './recorder.component.html',
  styleUrls: ['./recorder.component.scss'],
})
export class RecorderComponent implements OnDestroy {
  private mediaRecorder: MediaRecorder
  private recordedBlob: Blob[]
  private processedBlob: Blob
  sliderValue = 1
  oldSliderValue = 1
  @Output() processedBlobChanged = new EventEmitter<{blob: Blob; audioCifrato: boolean}>()
  @Input() isDisabled: boolean
  @Input() isAnon: boolean
  @Input() isAudioCifrato: boolean
  @Output() gestisciConsensoAudio = new EventEmitter<boolean>()
  private eventSubscription: Subscription



  constructor(private audioService: AudioService,
    private eventService: EventService,
    private sessionData: SessionData,
    private authService: AuthService,
    private snackBar: MatSnackBar) {}


  ngOnInit(): void {
    if ((this.authService.getUser().odv && !this.isAudioCifrato) ||
    (!this.authService.getUser().odv && !this.isAnon && this.sessionData.configurazione &&
    !this.sessionData.configurazione.mascheraTuttiGliAudio)) {
      this.sliderValue = 1
    } else {
      this.sliderValue = this.sessionData.configurazione? this.sessionData.configurazione.pitchShifting : 0.8
      this.oldSliderValue = this.sliderValue
    }
    this.eventSubscription = this.eventService.eventObservable$.subscribe(
      (event) => {
        switch (event.type) {
          case EVENT_MESSAGGIO_INVIATO:
            this.onMessaggioInviato()
            break
        }
      }
    )
  }

  onMessaggioInviato() {
    this.recordedBlob = []
    this.processedBlob = null
  }

  ngOnDestroy(): void {
    // fermo il media recorder se è in esecuzione
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.stop()
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe()
    }
  }

  async startRecording(event1: Event) {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    this.mediaRecorder = new MediaRecorder(stream)
    this.recordedBlob = []

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        this.recordedBlob.push(event.data)
      }
    }

    this.mediaRecorder.onstop = async () => {
      console.log('MediaRecorder stopped')
      this.processedBlob = new Blob(this.recordedBlob, { type: 'audio/mp3' })

        // se la size è minore di 3000 non è valida
        if (this.processedBlob.size < 4000) {
          this.snackBar.open(
            $localize`Registrazione vocale troppo breve`,
            'OK',
            { duration: 8000 }
          )
          this.processedBlob = null
          this.recordedBlob = undefined
          this.processedBlobChanged.emit({blob: this.processedBlob, audioCifrato: this.sliderValue !== 1})

          return
        }

      // se lo slider è diverso da 1, allora devo modificare il pitch invocando la funzione
      // modificaPitchAudio
      if (this.sliderValue !== 1 && !this.authService.getUser().odv) {
        const arrayBuffer = await convertBlobToArrayBuffer(this.processedBlob)
        this.processedBlob = await modificaPitchAudio(arrayBuffer, {
          pitch: this.sliderValue,
        })
        this.oldSliderValue = this.sliderValue
      }
      this.processedBlobChanged.emit({blob: this.processedBlob, audioCifrato: this.sliderValue !== 1 && !this.authService.getUser().odv})

    }

    this.mediaRecorder.start()
  }

  async stopRecording(event: Event) {
    // chiude il media recorder
    this.mediaRecorder?.stream.getAudioTracks().forEach((track) => {
      track.stop()
    })
    const audioTracks = this.mediaRecorder.stream.getAudioTracks()
    // // rimuovo tutti i tracks audio
    for (let i = 0; i < audioTracks.length; i++) {
      this.mediaRecorder.stream.removeTrack(audioTracks[i])
    }
    // verifico che il media recorder sia attivo

    if (this.mediaRecorder.state !== 'inactive') {
      // se il media recorder è in uno stato non valido, lo riavvio
      this.mediaRecorder.stop()
    }
  }

  async playAudio() {
    if (this.sliderValue !== this.oldSliderValue) {
      this.processedBlob = new Blob(this.recordedBlob, { type: 'audio/mp3' })
      const arrayBuffer = await convertBlobToArrayBuffer(this.processedBlob)
      this.processedBlob = await modificaPitchAudio(arrayBuffer, {
        pitch: this.sliderValue,
      })
      this.processedBlobChanged.emit({blob: this.processedBlob, audioCifrato: this.sliderValue !== 1})
      this.oldSliderValue = this.sliderValue
    }
    const audio = new Audio()
    audio.src = window.URL.createObjectURL(this.processedBlob)
    audio.play()
  }

  openDialog() {
    this.gestisciConsensoAudio.emit(true)
  }

  deleteAudio() {
    this.recordedBlob = undefined
  }

  async uploadAudio() {
    // const audioBlob = new Blob(this.recordedBlob, { type: 'audio/mp3' });
    const audioBlob = this.processedBlob
    this.audioService.uploadAudio(audioBlob).subscribe
    (res => {
      console.log('Ricevuto hash Audio:', res)
    })
  }

  onSliderInput(event: MatSliderChange) {
    this.sliderValue = event.value
  }

  get audioIsPresent(): boolean {
    return this.recordedBlob !== undefined && this.recordedBlob.length > 0
  }

  get odv(): boolean {
    return this.authService.getUser().odv
  }
}
