<h2 i18n mat-dialog-title class="titolo-dialog">Sessione Scaduta</h2>
<mat-dialog-content>
  <div class="centra-form">
    <table style="width:100%">

      <tr>
        <td colspan="12">
          <div i18n  style="width:100%">
            La sessione è scaduta, devi eseguire nuovamente l'accesso per poter continuare
          </div>
        </td>
      </tr>


    </table>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button i18n mat-button class="center" (click)="chiudi()">Chiudi</button>
</mat-dialog-actions>
