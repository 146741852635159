<app-toolbar [mostraFiltro]="true"></app-toolbar>
<div class="theme">
  <mat-sidenav-container autosize>
    <mat-sidenav #sidenav mode="push">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon color="">menu</mat-icon>
      </button>
    </mat-sidenav>

    <mat-sidenav-content>

      <div class="flex-column">
        <div class="titolo-sezione" i18n style="width:100%;">
          Informative
        </div>
        <div class="mat-elevation-z8" id="listaInformative" class="width-full">
          <mat-table #table [dataSource]="dataSource" matSort class="width-full" multiTemplateDataRows>

            <mat-header-row *matHeaderRowDef="colonneVisualizzate"></mat-header-row>
            <mat-row *matRowDef="let informativa; columns: colonneVisualizzate" class="pointer"
              (click)="selected(informativa)">
            </mat-row>

            <ng-container matColumnDef="id">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
              <mat-cell *matCellDef="let informativa"> {{informativa.id}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="nome">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Nome </mat-header-cell>
              <mat-cell *matCellDef="let informativa">
                <div >
                  <div>{{ informativa.nome }}</div>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="azienda">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Azienda </mat-header-cell>
              <mat-cell *matCellDef="let informativa">
                <div >
                  <div>{{ informativa.azienda?.nomeAzienda }}</div>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="azioni">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="width-full">
                <button mat-icon-button class="destra" (click)="aggiungiInformativa(); $event.stopPropagation();" [disabled]="!puoCreareInformative()"
                i18n-matTooltip matTooltip="Crea un nuovo Utente">
                  <mat-icon class="icone-testata-tabelle" aria-label="aggiungi utente">add_circle</mat-icon>
                </button>
              </mat-header-cell>
              <mat-cell *matCellDef="let informativa">
                <button mat-button (click)="editaInformativa(informativa); $event.stopPropagation();">
                  <mat-icon class="icona-riga-tabella" aria-label="autorizza">create</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> todo </mat-header-cell>
              <mat-cell *matCellDef="let utente" style='justify-content: center'>
              </mat-cell>
            </ng-container>
            <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="riga-dettaglio-inner"></mat-row>
          </mat-table>
          <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
