<app-toolbar [mostraFiltro]="true"></app-toolbar>
<div class="theme">
  <mat-sidenav-container autosize>
    <mat-sidenav #sidenav mode="push">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon color="">menu</mat-icon>
      </button>
    </mat-sidenav>

    <mat-sidenav-content>

      <div class="flex-column">
        <div class="titolo-sezione" i18n style="width:100%;">
          Richieste di sblocco
        </div>
        <div class="mat-elevation-z8" id="listaRichieste">
          <mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows>

            <mat-header-row *matHeaderRowDef="colonneVisualizzate"></mat-header-row>
            <mat-row *matRowDef="let richiesta; columns: colonneVisualizzate" class="pointer"
              (click)="openDialogMotivazione(richiesta)">
            </mat-row>

            <ng-container matColumnDef="idsegnalazione">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Progressivo di Sistema</mat-header-cell>
              <mat-cell *matCellDef="let richiesta"> {{richiesta.segnalazione.id}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="idPerSegnalante">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Identificativo Segnalazione</mat-header-cell>
              <mat-cell *matCellDef="let richiesta"> {{richiesta.segnalazione.idPerSegnalante}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="richiedente">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Richiedente </mat-header-cell>
              <mat-cell *matCellDef="let richiesta">
                <div >
                  <div>{{ richiesta.odvDestinatario.nomeOdv }}</div>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="datarichiesta">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Data Richiesta </mat-header-cell>
              <mat-cell *matCellDef="let richiesta">
                {{richiesta.dataRichiestaSbloccoVisibilitaSegnalante | date: 'dd/MM/yyyy'}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="motivorichiesta">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Motivo Richiesta </mat-header-cell>
              <mat-cell *matCellDef="let richiesta">

                <div >
                  <div [innerHTML]="richiesta.motivazioneRichiestaSbloccoVisibilitaSegnalante " style="text-overflow:ellipsis"></div>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="esitorichiesta">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Stato </mat-header-cell>
              <mat-cell *matCellDef="let richiesta">
                <div >
                  <div>{{ esito(richiesta) }}</div>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="descrizioneesito">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Descrizione Esito </mat-header-cell>
              <mat-cell *matCellDef="let richiesta">
                <div >
                  <div [innerHTML]="richiesta.motivazioneRispostaARichiestaSbloccoVisibilitaSegnalante"></div>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="azioni">
              <mat-header-cell i18n *matHeaderCellDef class="width-full">Azioni
              </mat-header-cell>
              <mat-cell *matCellDef="let richiesta">
                <button mat-button
                  *ngIf="richiesta.richiestaSbloccoVisibilitaSegnalanteRespinta ||
                (!richiesta.richiestaSbloccoVisibilitaSegnalanteAccolta && !richiesta.richiestaSbloccoVisibilitaSegnalanteRespinta) ||
                (richiesta.richiestaSbloccoVisibilitaSegnalanteAccolta !== richiesta.richiestaSbloccoVisibilitaSegnalanteRespinta &&
                richiesta.dataRichiestaSbloccoVisibilitaSegnalante > richiesta.dataRispostaARichiestaSbloccoVisibilitaSegnalante)"
                  (click)="openDialogAutorizzazione(richiesta, autorizzaRichiesta, AUTORIZZA); $event.stopPropagation();">
                  <mat-icon class="icona-riga-tabella" aria-label="autorizza">thumb_up</mat-icon>
                </button>
                <button mat-button
                  *ngIf="richiesta.richiestaSbloccoVisibilitaSegnalanteAccolta ||
                (!richiesta.richiestaSbloccoVisibilitaSegnalanteAccolta && !richiesta.richiestaSbloccoVisibilitaSegnalanteRespinta) ||
                (richiesta.richiestaSbloccoVisibilitaSegnalanteAccolta !== richiesta.richiestaSbloccoVisibilitaSegnalanteRespinta &&
                richiesta.dataRichiestaSbloccoVisibilitaSegnalante > richiesta.dataRispostaARichiestaSbloccoVisibilitaSegnalante)"
                  (click)="openDialogAutorizzazione(richiesta, respingiRichiesta, RESPINGI); $event.stopPropagation();">
                  <mat-icon class="icona-riga-tabella" aria-label="respingi">thumb_down</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header> todo </mat-header-cell>
              <mat-cell *matCellDef="let utente" style='justify-content: center'>
              </mat-cell>
            </ng-container>
            <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="riga-dettaglio-inner"></mat-row>
          </mat-table>
          <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
