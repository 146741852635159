import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigurazioneBan } from '../modello/configurazione';
import { ConfigurazioneService } from '../servizi/configurazione.service';
import { Esito, ESITO_OK } from '../utility/esito';
import { inject } from '@angular/core';

export const configurazioneBanResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<ConfigurazioneBan> | Promise<ConfigurazioneBan> | ConfigurazioneBan => {
  const configurazioneService = inject(ConfigurazioneService);

  return configurazioneService.recuperaConfigurazioneBan().pipe(
    map((esito: Esito) => {
      if (esito.esito === ESITO_OK) {
        return JSON.parse(esito.payload);
      } else {
        throw new Error(esito.descrizioneEsito);
      }
    }),
    catchError((error: any) => {
      console.error("An error occurred while retrieving configuration:", error);
      return throwError(error);
    })
  );
};
