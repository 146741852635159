import { Component, OnInit } from '@angular/core'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import { SessionData } from '../../sessione/dati-sessione'
import { ThemeService } from '../../servizi/theme.service'

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  editorConfig: AngularEditorConfig = {
    enableToolbar: false,
    showToolbar: false,
    editable: false,
    spellcheck: false,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Impostare la Policy nella sezione di configurazione',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  }
  faq: string = this.sessionData.configurazione.faq

  constructor(private sessionData: SessionData) { }

  ngOnInit(): void {
  }

}
