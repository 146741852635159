<span class="bottone-flottante-alto-sinistra no-print">
  <button mat-mini-fab (click)='backToLogin();$event.stopPropagation();' i18n-matTooltip matTooltip="Torna Indietro">
    <mat-icon class="icona-fab" aria-label="Torna indietro">keyboard_arrow_left</mat-icon>
  </button>
</span>
<div class="flex-column">
<mat-toolbar>
  <div>
    <img src="{{getLogoCliente()}}" class="logo-cliente">
  </div>
  <span class="testo-x-large" style="text-align: center;width: 100%;" i18n>Recupero Password</span>
</mat-toolbar>
<div class="alternate-theme">
  <form [formGroup]="recuperoPasswordForm" style="height:100%"
  autocomplete="off"
    (keydown)="keyDownFunction($event); $event.stopPropagation();">
    <mat-card style="height: calc(100vh - 100px);">

      <mat-card-title>

      </mat-card-title>
      <mat-card-content>
        <div class="flex-column">
          <!-- Campo username -->
          <div class="flex-row" >
            <div style="flex: 0 0 25%"></div>
            <div class="flex-column" >
              <mat-form-field color="accent">
                <input type="text" matInput class="form-control" formControlName="username"
                  i18n-placeholder placeholder="Nickname">
              </mat-form-field>
              <div
                *ngIf="recuperoPasswordForm.controls.username.invalid && (recuperoPasswordForm.controls.username.dirty || recuperoPasswordForm.controls.username.touched)"
                class="alert alert-danger posizione-errori-utente errori-form">

                <mat-error *ngIf="recuperoPasswordForm.controls.username.errors.required" i18n>
                  Questo dato è obbligatorio.
                </mat-error>
              </div>
            </div>
            <div style="flex: 0 0 25%"></div>
          </div>

          <!-- Campo email -->
          <div class="flex-row" >
            <div style="flex: 0 0 25%"></div>
            <div class="flex-column" >
              <mat-form-field color="accent">
                <input type="text" autocomplete="new-password" matInput class="form-control" formControlName="email"
                  i18n-placeholder placeholder="Email">
              </mat-form-field>
              <div
                *ngIf="recuperoPasswordForm.controls.email.invalid && (recuperoPasswordForm.controls.email.dirty || recuperoPasswordForm.controls.email.touched)"
                class="alert alert-danger posizione-errori-utente errori-form">

                <mat-error *ngIf="recuperoPasswordForm.controls.email.errors.required" i18n>
                  Questo dato è obbligatorio.
                </mat-error>

                <mat-error *ngIf="recuperoPasswordForm.controls.email.errors.emailNonValida" i18n>
                  L'email digitata non è valida.
                </mat-error>
                <mat-error *ngIf="recuperoPasswordForm.controls.email.errors.dominioNonPermesso" i18n>
                  Non è consentito l'utilizzo di email appartenenti ai seguenti domini: {{dominiPermessi}}
                </mat-error>
                <mat-error *ngIf="recuperoPasswordForm.controls.email.errors.mismatchedEmail" i18n>
                  Gli indirizzi email inseriti non coincidono.
                </mat-error>
              </div>
            </div>
            <div style="flex: 0 0 25%"></div>
          </div>

          <!-- Campo ripeti email -->
          <div class="flex-row" >
            <div style="flex: 0 0 25%"></div>
            <div class="flex-column" >
              <mat-form-field color="accent">
                <input type="text" autocomplete="new-password" matInput class="form-control"
                  formControlName="ripetiEmail" i18n-placeholder placeholder="Digita nuovamente l'indirizzo Email" >
              </mat-form-field>
              <div *ngIf="recuperoPasswordForm.hasError('mismatchedEmail')"
                class="alert alert-danger posizione-errori-utente errori-form">
                <mat-error i18n>
                  Gli indirizzi email inseriti non coincidono.
                </mat-error>
              </div>
            </div>
            <div style="flex: 0 0 25%"></div>
          </div>

          <!--  FOOTER DEL MODULO -->
          <div class="flex-row">
            <div style="flex: 0 0 25%"></div>
            <div style="flex: 0 0 50%">
              <a (click)="backToLogin()" class="pointer" i18n>Torna alla Login</a>
            </div>
            <div style="flex: 0 0 25%"></div>
          </div>

          <div class="flex-column">
            <div class="flex-row" >
              <div style="flex: 0 0 25%"></div>
              <button i18n class="bottone-bordato bottone-login"
                [disabled]="recuperoPasswordForm.hasError('mismatchedEmail') || !recuperoPasswordForm.controls.email.value  || recuperoPasswordForm.controls.email.errors?.emailNonValida"
                mat-button (click)="onClickRecupera($event)"
                 i18n>Recupera
              </button>
              <div style="flex: 0 0 25%"></div>
            </div>
            <div class="flex-row" >
              <div style="flex: 0 0 25%"></div>
              <div class="flex-column">
                <div class="centered" style="margin-top: 30px;">
                  <H3 i18n>
                    Il recupero è possibile solo se l'indirizzo email digitato è quello fornito in fase di
                    registrazione.<br><br>
                    Se non hai fornito nessun indirizzo email purtroppo non c'è modo di recuperare le tue vecchie
                    credenziali. In quest'ultimo caso puoi comunque fare una nuova registrazione.
                  </H3>
                </div>
              </div>
              <div style="flex: 0 0 25%"></div>
            </div>
          </div>

        </div>

      </mat-card-content>
      <mat-card-footer>


      </mat-card-footer>
    </mat-card>
  </form>
</div>
<div class="footer centro"> {{versione}} </div>
</div>
