import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Segnalazione } from '../modello/segnalazione';
import { SegnalazioneService } from '../servizi/segnalazione.service';
import { SessionData } from '../sessione/dati-sessione';
import { ESITO_IN_PROGRESS, ESITO_OK } from '../utility/esito';
import { inject } from '@angular/core';
import { PincodeService } from '../servizi/pincode.service';
import { AuthService } from '../servizi/auth.service';
import { User } from '../modello/user';
import { Segnalante } from '../modello/segnalante';
import { Documento } from '../modello/documento';

export const segnalazioneResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Segnalazione> | Promise<Segnalazione> | Segnalazione => {
  const sessionData = inject(SessionData);
  const segnalazioneService = inject(SegnalazioneService);
  const pincodeService = inject(PincodeService);

  if (sessionData.getSegnalazioneSelezionata()) {
    return sessionData.getSegnalazioneSelezionata();
  }

  const idSegnalazioneSelezionata = sessionStorage.getItem('idSegnalazioneSelezionata');
  console.log('resolver segnalazione', idSegnalazioneSelezionata);

  if (!idSegnalazioneSelezionata) {
    const segnalazioneBase64 = localStorage.getItem('segnalazione');
    // procedi solo se segnalazioneBase64 è valorizzato
    if (!segnalazioneBase64) {
      console.log("Nessuna segnalazione selezionata...");
      apriSegnalazione();
      return;
    }
    const segnalazioneString = atob(segnalazioneBase64);
    // console.log('segnalazioneString', segnalazioneString);

    if (segnalazioneString) {
      // svuota il local storage
      localStorage.removeItem('segnalazione');
      const segnalazione = JSON.parse(segnalazioneString);
      // ripristino i segnalati
      const segnalatiBase64 = localStorage.getItem('segnalati');
      if (segnalatiBase64) {
        const segnalatiString = atob(segnalatiBase64);
        if (segnalatiString) {
          const segnalati = JSON.parse(segnalatiString);
          segnalazione.segnalati = segnalati;
        }
      }



      // ora ripristino anche i dati di sessione
      const newSessionDataBase64 = localStorage.getItem('sessionData');
      // console.log('newSessionDataBase64', newSessionDataBase64);
      if (newSessionDataBase64) {
        const newSessionData = atob(newSessionDataBase64);
        // console.log('newSessionData', newSessionData);
        if (newSessionData) {
          const sessionDataJson = JSON.parse(newSessionData);
          SessionData.initialize(sessionDataJson);
        }
      }

      // ripristina il pincode
      const pinCodeBase64 = localStorage.getItem('pincode');
      const pinCode = pinCodeBase64 ? atob(pinCodeBase64) : null;
      if (pinCode) {
        // elimina il pincode dal local storage
        localStorage.removeItem('pincode');
        pincodeService.pinCode = pinCode;
      }

      // se non ho un pinCode e la segnalazione non ha id allora ne debbo aprire una
      if (!pinCode && !segnalazione.id) {
        apriSegnalazione();
        return
      }

      sessionData.nuovaSegnalazioneSelezionata(segnalazione);

      console.log("Segnalazione selezionata recuperata dalla sessione locale: ", segnalazione);
      return segnalazione;
    } else {
      console.log("Inner - Nessuna segnalazione selezionata...");
      return;
    }
  }

  return segnalazioneService.recuperaSegnalazione(+idSegnalazioneSelezionata).pipe(
    map((esito) => {
      if (ESITO_OK === esito.esito) {
        if (esito.payload) {
          sessionData.nuovaSegnalazioneSelezionata(JSON.parse(esito.payload) as Segnalazione);
        }

        return sessionData.getSegnalazioneSelezionata();
      } else if (esito.esito === ESITO_IN_PROGRESS) {
        console.log(esito.descrizioneEsito);
      } else {
        throw new Error(esito.payload);
      }
    })
  );
};

export const apriSegnalazione = async () => {
  const sessionData = inject(SessionData);
  const authService = inject(AuthService);
  const segnalazioneService = inject(SegnalazioneService);
  const pincodeService = inject(PincodeService);
  authService
  // eslint-disable-next-line @typescript-eslint/naming-convention
  .loginNuovaSegnalazione()
  .then((data: User) => {
    if (authService.isLoggedIn()) {
      // SessionData.utente = data;
      // console.log('Navigo nella home.....');
      const segnalazione = new Segnalazione()
      const segnalante: Segnalante = new Segnalante()

      segnalazione.segnalante = segnalante
      segnalazione.segnalante.utente = authService.getUser()
      segnalazione.segnalante.utente.lingua = window.location.href.split('/')[3]
      segnalazione.anonima = false
      segnalazione.stato = '0'

      segnalazione.presaInCarico = false
      segnalazione.documenti = new Array<Documento>()

      sessionData.nuovaSegnalazioneSelezionata(segnalazione)
      sessionData.pinCode = data?.pinCode?.pincode
      // console.log('avvio creazione nuova segnalazione:');
      // naviga verso segnalazione e passa il pinCode come parametro
      pincodeService.pinCode = data?.pinCode?.pincode;
      return segnalazione
    }
  })
  .catch((err) => {
    console.log('errore login:' + err)
  })
}
