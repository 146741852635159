import {Segnalazione} from './segnalazione'
import {User} from './user'

export class Segnalante {

  id: string

  nome: string

  cognome: string

  codiceFiscale: string

  genere: string

  dataDiNascita: Date

  luogoDiNascita: string

  indirizzoDiResidenza: string

  cap: string

  citta: string

  regione: string

  stato: string

  telefono: string

  email: string

  emailVerificata: boolean

  emailVerificaInviata: boolean

  possoUsareEmailPerNotifiche: boolean

  incarico: string

  orgUnitAndSite: string

  qualifica: string

  incaricoT0: string

  orgUnitAndSiteT0: string

  qualificaT0: string

  rapportoConLaSocieta: string

  segnalazioneFatta: Segnalazione

  utente: User

  sso: boolean
}
