import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PincodeService {
  private _pinCode: string;

  set pinCode(value: string) {
    this._pinCode = value;
  }

  get pinCode(): string {
    return this._pinCode;
  }
}
