import { Documento } from './documento'

import { Segnalazione } from './segnalazione'

import { User } from './user'

import { SorgenteSegnalazione } from './sorgente-segnalazione'

import { Tema } from './tema'

import { AzioniFollowUp } from './azioni-follow-up'

import { Odv } from './odv'
import { DirittiLavorazione } from './diritti-lavorazione'
import { Chat } from './chat'
import { LavorazioneMaster } from './lavorazione-master'

export class Lavorazione {

  id: number

  documenti: Documento[]

  ammissibile: boolean

  motivoNonAmmissibilita: string

  condivisa: boolean

  visibileAlSegnalante: boolean

  segnalazione: Segnalazione

  // sorgente: SorgenteSegnalazione;

  // tema: Tema;

  azioniFollowUp: AzioniFollowUp[]

  noteFollowUp: string

  stato: string

  dirittiOdv: DirittiLavorazione[]

  chats: Chat[]

  lavorazioneMaster: LavorazioneMaster

  chatConSegnalante: Chat

}
