import { ViewChild } from '@angular/core'
import { ElementRef } from '@angular/core'
import { Component, Input, OnInit } from '@angular/core'
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Azienda } from '../../modello/azienda'
import { Consenso } from '../../modello/consenso'
import { DirittiSegnalazione } from '../../modello/diritti-segnalazione'
import { Odv } from '../../modello/odv'
import { OdvAzienda } from '../../modello/odv-azienda'
import { Segnalazione } from '../../modello/segnalazione'
import { User } from '../../modello/user'
import { ConfermaModificaVisibilitaSegnalazioneInoltrataDialogComponent } from '../../pannello-lavorazioni/pannello-lavorazione-segnalazione/conferma-modifica-visibilita-segnalazione-inoltrata.dialog.component'
import { ConfermaScondivisioneSegnalazioneDialogComponent } from '../../pannello-lavorazioni/pannello-lavorazione-segnalazione/conferma-scondivisione-segnalazione.dialog.component'
import { ConfermaVisibilitaIdentitaSegnalanteDialogComponent } from '../../pannello-lavorazioni/pannello-lavorazione-segnalazione/conferma-visibilita-identita-segnalante.dialog.component'
import { DirittiInoltroDialogComponent } from '../../pannello-lavorazioni/pannello-lavorazione-segnalazione/diritti-inoltro.dialog.component'
import { AuthService } from '../../servizi/auth.service'
import { DialogService } from '../../servizi/dialog.service'
import { OmissisService } from '../../servizi/omissis.service'
import { SegnalazioneService } from '../../servizi/segnalazione.service'
import { SessionData } from '../../sessione/dati-sessione'
import { Esito, ESITO_OK } from '../../utility/esito'
import {
  isMembroDiGruppo,
  possoGestireAutorizzazioneVisibilitaSegnalazione,
  possoGestireAutorizzazioneComunicazioneConSegnalante,
  hoInoltratoSegnalazione,
  sonoGestoreSceltoDaSegnalante,
  puoAprireLavorazioni,
  isNecessarioConsensoSegnalante,
  puoCondividereNomeSegnalante,
  puoConsultareNomeSegnalati,
  possoEditareOmissis,
  destinatariVisibili,
  readOnlyDestinatari,
  puoChattareConSegnalante,
  dirittiSegnalazioneUtenteRicevutiDaSegnalante,
  puoConsultareNomeSegnalante,
  gruppiOdvUtilizzabiliPerPresaInCarico,
  recuperaDirittiSegnalazione,
  gruppiPartecipatiConLavorazioniAperte,
  gruppiAiQualiPossoInoltrare,
  possoInoltrareSegnalazione,
} from '../../utility/helper-segnalazioni'
import { BOZZA_INDEX } from '../../utility/utility'

@Component({
  selector: 'app-condivisione-segnalazione',
  templateUrl: './condivisione-segnalazione.component.html',
  styleUrls: ['./condivisione-segnalazione.component.scss'],
})
export class CondivisioneSegnalazioneComponent implements OnInit {
  @Input()
  segnalazione: Segnalazione
  @Input()
  elencoAziende: Azienda[]
  @ViewChild('inputDestinatari', { static: false })
  inputDestinatari: ElementRef
  dialogRefConfermaScondivisioneSegnalazione: any

  dialogRefConfermaCambioVisibilitaSegnalazione: any
  dialogRefConfermaVisibilita: any
  nomeOdvOmissisInModifica: string
  destinatariSegnalazione: any[]
  destinatariSegnalazioneFiltrati: any
  loading: boolean
  dialogRefDirittiInoltro: any

  constructor(
    private authService: AuthService,
    private segnalazioniService: SegnalazioneService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private omissisService: OmissisService,
    private sessionData: SessionData,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  get utente(): User {
    return this.authService.getUser()
  }

  possoInoltrareSegnalazione() {
    return possoInoltrareSegnalazione(this.segnalazione, this.authService.getUser(), this.elencoAziende)
  }

  possoGestireAutorizzazioneVisibilitaSegnalazione(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    return possoGestireAutorizzazioneVisibilitaSegnalazione(
      this.segnalazione,
      dirittiSegnalazione,
      this.authService.getUser()
    )
  }

  possoGestireAutorizzazioneComunicazioneConSegnalante(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    return possoGestireAutorizzazioneComunicazioneConSegnalante(
      this.segnalazione,
      dirittiSegnalazione,
      this.authService.getUser()
    )
  }

  possoGestireAutorizzazioneCreazioneLavorazioni(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    const result =
      (hoInoltratoSegnalazione(
        dirittiSegnalazione,
        this.authService.getUser()
      ) ||
        sonoGestoreSceltoDaSegnalante(
          this.segnalazione,
          this.authService.getUser()
        )) &&
      !dirittiSegnalazione.sceltoDaSegnalante &&
      puoAprireLavorazioni(this.segnalazione, this.authService.getUser())
    return result
  }

  possoRimuovereCondivisioneSegnalazione(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    const result =
      (hoInoltratoSegnalazione(
        dirittiSegnalazione,
        this.authService.getUser()
      ) ||
        sonoGestoreSceltoDaSegnalante(
          this.segnalazione,
          this.authService.getUser()
        )) &&
      !dirittiSegnalazione.sceltoDaSegnalante
    return result
  }

  possoGestireRipristinoCondivisione(
    dirittiSegnalazione: DirittiSegnalazione
  ){
    // se sono membro del gruppo che ha inoltrato la segnalazione
    const result =
      hoInoltratoSegnalazione(dirittiSegnalazione, this.authService.getUser())
    return result
  }

  possoGestireAutorizzazioneGestioneIdentitaSegnalante(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    //  il diritto segnalazione ricevuto è riferito al destinatario, debbo stabilire se (1) posso gestirlo essendo
    //  io colui il quale lo ha inoltrato (2) posso assegnare l'autorizzazione alla visibilità dell'identità del
    //  segnalante perchè sono autorizzato
    //  In definitiva sono autorizzato se:
    //  1. la segnalazione non è anonima
    //  2. AND Il diritto segnalazione da autorizzare è stato inoltrato da un gruppo a cui appartengo
    //  3. AND se è attivo il modulo consensi debbo averne ricevuto uno dal segnalante
    //  4. AND se attivo il modulo custode debbo aver ricevuto il suo ok
    if (this.segnalazione.anonima) {
      return false
    }
    const dirittoSegnalazioneInoltratoDaUnGruppoDiCuiSonoMembro =
      isMembroDiGruppo(
        this.authService.getUser(),
        dirittiSegnalazione.odvCheHaInoltrato
      )
    if (!dirittoSegnalazioneInoltratoDaUnGruppoDiCuiSonoMembro) {
      return false
    }

    if (this.sessionData.configurazione.consensiSegnalante) {
      const consenso = this.segnalazione.consensi?.find(
        (conse) => conse.attivo && conse.stato === Consenso.STATI.CONCESSO
      )
      if (!consenso) {
        return false
      }
    }

    if (this.sessionData.configurazione.custodiaIdentita) {
      const odvCheDeveEssereAbilitato = dirittiSegnalazione.odvCheHaInoltrato
      //  ricava il diritto che da accesso ai dati del segnalante
      const diritto = this.segnalazione.dirittiSegnalazioneOdv.find(
        (dir) => +dir.odvDestinatario.id === +odvCheDeveEssereAbilitato.id
      )
      if (
        !diritto ||
        !puoConsultareNomeSegnalante(
          diritto,
          this.segnalazione,
          this.authService.getUser()
        )
      ) {
        return false
      }
    }
    return true
  }

  possoGestireAutorizzazioneGestioneIdentitaSegnalati(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    const result =
      (hoInoltratoSegnalazione(
        dirittiSegnalazione,
        this.authService.getUser()
      ) ||
        sonoGestoreSceltoDaSegnalante(
          this.segnalazione,
          this.authService.getUser()
        )) &&
      puoConsultareNomeSegnalati(
        this.segnalazione,
        this.authService.getUser()
      ) &&
      !dirittiSegnalazione.sceltoDaSegnalante
    return result
  }

  possoGestireAutorizzazioniGestioneOmissis(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    const result =
      (possoEditareOmissis(dirittiSegnalazione, this.authService.getUser()) ||
        sonoGestoreSceltoDaSegnalante(
          this.segnalazione,
          this.authService.getUser()
        )) &&
      !dirittiSegnalazione.sceltoDaSegnalante
    return result
  }

  possoGestireAutorizzazioneGestioneAudio(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    const result =
      (possoEditareOmissis(dirittiSegnalazione, this.authService.getUser()) ||
        sonoGestoreSceltoDaSegnalante(
          this.segnalazione,
          this.authService.getUser()
        )) &&
      !dirittiSegnalazione.sceltoDaSegnalante
    return result
  }

  possoGestireAutorizzazioneCondivisione(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    const hoInoltrato = hoInoltratoSegnalazione(
      dirittiSegnalazione,
      this.authService.getUser()
    )
    const hoDirittiSegnalazioneDirettiDalSegnalante =
      sonoGestoreSceltoDaSegnalante(
        this.segnalazione,
        this.authService.getUser()
      )
    const gestoreSceltoDaSegnalante = sonoGestoreSceltoDaSegnalante(
      this.segnalazione,
      this.authService.getUser()
    )
    const result =
      ((hoInoltrato && hoDirittiSegnalazioneDirettiDalSegnalante) || gestoreSceltoDaSegnalante) &&
      !dirittiSegnalazione.sceltoDaSegnalante
    return result
  }
  statoCondivisioneSospesa(destinatario: DirittiSegnalazione) {
    const stato = destinatario.consultazioneSegnalazioneSospesa
    return stato
  }

  statoVisibilitaSegnalante(destinatario: DirittiSegnalazione) {
    // let dirittoSegnalazione = this.segnalazione.dirittiSegnalazioneOdv && this.segnalazione.dirittiSegnalazioneOdv.
    let dirittoSegnalazione = this.segnalazione.dirittiSegnalazioneOdv?.find(
      (diritto) =>
        diritto.odvDestinatario &&
        diritto.odvDestinatario.id === destinatario.odvDestinatario.id
    )
    if (dirittoSegnalazione === undefined) {
      dirittoSegnalazione =
        this.segnalazione.dirittiSegnalazioneOdv &&
        this.segnalazione.dirittiSegnalazioneOdv.find(
          (diritto) =>
            diritto.odvDestinatario &&
            diritto.odvDestinatario.id === destinatario.odvDestinatario.id
        )
    }
    return dirittoSegnalazione.puoVedereSegnalante
  }

  statoVisibilitaSegnalati(destinatario: DirittiSegnalazione) {
    // let dirittoSegnalazione = this.segnalazione.dirittiSegnalazioneOdv && this.segnalazione.dirittiSegnalazioneOdv.
    let dirittoSegnalazione = this.segnalazione.dirittiSegnalazioneOdv?.find(
      (diritto) =>
        diritto.odvDestinatario &&
        diritto.odvDestinatario.id === destinatario.odvDestinatario.id
    )
    if (dirittoSegnalazione === undefined) {
      dirittoSegnalazione =
        this.segnalazione.dirittiSegnalazioneOdv &&
        this.segnalazione.dirittiSegnalazioneOdv.find(
          (diritto) =>
            diritto.odvDestinatario &&
            diritto.odvDestinatario.id === destinatario.odvDestinatario.id
        )
    }
    return dirittoSegnalazione.puoVedereSegnalati
  }

  statoAscoltoAudio(destinatario: DirittiSegnalazione) {
    // let dirittoSegnalazione = this.segnalazione.dirittiSegnalazioneOdv && this.segnalazione.dirittiSegnalazioneOdv.
    let dirittoSegnalazione = this.segnalazione.dirittiSegnalazioneOdv?.find(
      (diritto) =>
        diritto.odvDestinatario &&
        diritto.odvDestinatario.id === destinatario.odvDestinatario.id
    )
    if (dirittoSegnalazione === undefined) {
      dirittoSegnalazione =
        this.segnalazione.dirittiSegnalazioneOdv &&
        this.segnalazione.dirittiSegnalazioneOdv.find(
          (diritto) =>
            diritto.odvDestinatario &&
            diritto.odvDestinatario.id === destinatario.odvDestinatario.id
        )
    }
    return dirittoSegnalazione.audioCifrato
  }

  statoComunicazioneSegnalante(destinatario: DirittiSegnalazione) {
    if (!destinatario.odvDestinatario) {
      return false
    }

    // let dirittoSegnalazione = this.segnalazione.dirittiSegnalazioneOdv && this.segnalazione.dirittiSegnalazioneOdv.
    let dirittoSegnalazione = this.segnalazione.dirittiSegnalazioneOdv?.find(
      (diritto) =>
        diritto.odvDestinatario &&
        diritto.odvDestinatario.id === destinatario.odvDestinatario.id
    )
    if (dirittoSegnalazione === undefined) {
      dirittoSegnalazione =
        this.segnalazione.dirittiSegnalazioneOdv &&
        this.segnalazione.dirittiSegnalazioneOdv.find(
          (diritto) =>
            diritto.odvDestinatario &&
            diritto.odvDestinatario.id === destinatario.odvDestinatario.id
        )
    }
    return (
      dirittoSegnalazione && dirittoSegnalazione.comunicazioneConSegnalante
    )
  }

  statoAperturaLavorazioni(destinatario: DirittiSegnalazione) {
    // let dirittoSegnalazione = this.segnalazione.dirittiSegnalazioneOdv && this.segnalazione.dirittiSegnalazioneOdv.
    let dirittoSegnalazione = this.segnalazione.dirittiSegnalazioneOdv?.find(
      (diritto) =>
        diritto.odvDestinatario &&
        diritto.odvDestinatario?.id === destinatario.odvDestinatario?.id
    )
    if (dirittoSegnalazione === undefined) {
      dirittoSegnalazione =
        this.segnalazione.dirittiSegnalazioneOdv &&
        this.segnalazione.dirittiSegnalazioneOdv.find(
          (diritto) =>
            diritto.odvDestinatario &&
            diritto.odvDestinatario?.id === destinatario.odvDestinatario?.id
        )
    }
    return dirittoSegnalazione?.aperturaLavorazioni
  }

  gruppiAiQualiPossoInoltrare(azienda: Azienda) {
    return gruppiAiQualiPossoInoltrare(this.authService.getUser(), this.segnalazione, this.elencoAziende, azienda)
  }

  get inoltroLibero() {
    return !this.sessionData.configurazione.consentiInoltroSegnalazioniLibero
  }

  toggleVisibilitaSegnalazione(dirittiSegnalazione: DirittiSegnalazione) {
    const nuovoValoreVisibilita =
      !this.statoCondivisioneSospesa(dirittiSegnalazione)
    this.segnalazioniService
      .aggiornaVisibilitaSegnalazione(
        dirittiSegnalazione.id,
        nuovoValoreVisibilita ? 1 : 0,
        +dirittiSegnalazione.odvDestinatario.id,
        +this.segnalazione.id
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).consultazioneSegnalazioneSospesa = nuovoValoreVisibilita
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).consultazioneSegnalazioneSospesa = nuovoValoreVisibilita
          dirittiSegnalazione.consultazioneSegnalazioneSospesa =
            nuovoValoreVisibilita
        } else {
          this.snackBar.open(
            $localize`Aggiornamento autorizzazione fallito. ` +
              JSON.stringify(esito.descrizioneEsito),
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((err) => {
        this.snackBar.open(
          $localize`Aggiornamento autorizzazione fallito. ` +
            JSON.stringify(err),
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  toggleComunicazioneConSegnalante(
    evento,
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    const nuovoValoreVisibilita =
      !this.statoComunicazioneSegnalante(dirittiSegnalazione)
    this.segnalazioniService
      .aggiornaComunicazioneConSegnalante(
        dirittiSegnalazione.id,
        nuovoValoreVisibilita ? 1 : 0
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).comunicazioneConSegnalante = nuovoValoreVisibilita
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).comunicazioneConSegnalante = nuovoValoreVisibilita
          dirittiSegnalazione.comunicazioneConSegnalante =
            nuovoValoreVisibilita
        } else {
          this.snackBar.open(
            $localize`Aggiornamento autorizzazione fallito. ` +
              JSON.stringify(esito.descrizioneEsito),
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((err) => {
        this.snackBar.open(
          $localize`Aggiornamento autorizzazione fallito. ` +
            JSON.stringify(err),
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  toggleAperturaLavorazioni(evento, dirittiSegnalazione: DirittiSegnalazione) {
    const nuovoValoreVisibilita =
      !this.statoAperturaLavorazioni(dirittiSegnalazione)
    this.segnalazioniService
      .aggiornaAperturaLavorazioni(
        dirittiSegnalazione.id,
        nuovoValoreVisibilita ? 1 : 0
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).aperturaLavorazioni = nuovoValoreVisibilita
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).aperturaLavorazioni = nuovoValoreVisibilita
          dirittiSegnalazione.aperturaLavorazioni = nuovoValoreVisibilita
        } else {
          this.snackBar.open(
            $localize`Aggiornamento autorizzazione fallito. ` +
              JSON.stringify(esito.descrizioneEsito),
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((err) => {
        this.snackBar.open(
          $localize`Aggiornamento autorizzazione fallito. ` +
            JSON.stringify(err),
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  toggleVisibilitaSegnalante(dirittiSegnalazione: DirittiSegnalazione) {
    const nuovoValoreVisibilita =
      !this.statoVisibilitaSegnalante(dirittiSegnalazione)
    this.segnalazioniService
      .aggiornaVisibilitaSegnalante(
        dirittiSegnalazione.id,
        nuovoValoreVisibilita ? 1 : 0
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).puoVedereSegnalante = nuovoValoreVisibilita
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).puoVedereSegnalante = nuovoValoreVisibilita
          dirittiSegnalazione.puoVedereSegnalante = nuovoValoreVisibilita
        } else {
          this.snackBar.open(
            $localize`Aggiornamento autorizzazione fallito. ` +
              JSON.stringify(esito.descrizioneEsito),
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((err) => {
        this.snackBar.open(
          $localize`Aggiornamento autorizzazione fallito. ` +
            JSON.stringify(err),
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  toggleVisibilitaSegnalati(evento, dirittiSegnalazione: DirittiSegnalazione) {
    const nuovoValoreVisibilita =
      !this.statoVisibilitaSegnalati(dirittiSegnalazione)
    this.segnalazioniService
      .aggiornaVisibilitaSegnalati(
        dirittiSegnalazione.id,
        nuovoValoreVisibilita ? 1 : 0
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).puoVedereSegnalati = nuovoValoreVisibilita
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).puoVedereSegnalati = nuovoValoreVisibilita
          dirittiSegnalazione.puoVedereSegnalati = nuovoValoreVisibilita
        } else {
          this.snackBar.open(
            $localize`Aggiornamento autorizzazione fallito. ` +
              JSON.stringify(esito.descrizioneEsito),
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((err) => {
        this.snackBar.open(
          $localize`Aggiornamento autorizzazione fallito. ` +
            JSON.stringify(err),
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  toggleAscoltoAudio(evento, dirittiSegnalazione: DirittiSegnalazione) {
    const nuovoValoreVisibilita = !this.statoAscoltoAudio(dirittiSegnalazione)
    this.segnalazioniService
      .aggiornaAscoltoAudio(
        dirittiSegnalazione.id,
        nuovoValoreVisibilita ? 1 : 0
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).audioCifrato = nuovoValoreVisibilita
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).audioCifrato = nuovoValoreVisibilita
          dirittiSegnalazione.audioCifrato = nuovoValoreVisibilita
        } else {
          this.snackBar.open(
            $localize`Aggiornamento autorizzazione fallito. ` +
              JSON.stringify(esito.descrizioneEsito),
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((err) => {
        this.snackBar.open(
          $localize`Aggiornamento autorizzazione fallito. ` +
            JSON.stringify(err),
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  toggleCondivisione(evento, dirittiSegnalazione: DirittiSegnalazione) {
    const nuovoValoreCondivisione =
      !this.statoCondivisione(dirittiSegnalazione)
    this.segnalazioniService
      .aggiornaStatoCondivisione(
        dirittiSegnalazione.id,
        nuovoValoreCondivisione ? 1 : 0
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).segnalazioneCondivisibile = nuovoValoreCondivisione
          this.segnalazione.dirittiSegnalazioneOdv.find(
            (dirse) => dirse.id === dirittiSegnalazione.id
          ).segnalazioneCondivisibile = nuovoValoreCondivisione
          dirittiSegnalazione.segnalazioneCondivisibile =
            nuovoValoreCondivisione
        } else {
          this.snackBar.open(
            $localize`Aggiornamento autorizzazione fallito. ` +
              JSON.stringify(esito.descrizioneEsito),
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((err) => {
        this.snackBar.open(
          $localize`Aggiornamento autorizzazione fallito. ` +
            JSON.stringify(err),
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  openDialogConfermaScondivisioneSegnalazione(
    dirittiSegnalazione: DirittiSegnalazione
  ): void {
    if (this.dialogRefConfermaScondivisioneSegnalazione === undefined) {
      this.dialogRefConfermaScondivisioneSegnalazione = this.dialog.open(
        ConfermaScondivisioneSegnalazioneDialogComponent,
        {
          width: '800px',
          data: {
            nomeGruppoOdv: dirittiSegnalazione.odvDestinatario.nomeOdv,
            isLavorazione: false,
          },
          disableClose: true,
          hasBackdrop: true,
        }
      )
      this.dialogRefConfermaScondivisioneSegnalazione
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.nomeOdvOmissisInModifica = undefined
            this.omissisService.disattivaOmissisSeAttivi.next()
            this.disinoltraSegnalazione(dirittiSegnalazione)
          }

          this.dialogRefConfermaScondivisioneSegnalazione = undefined
        })
    }
  }

  async openDialogConfermaRiabilitazioneCondivisione(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    const result = await this.dialogService.confirm(
      $localize`Conferma ripristino condivisione`,
      $localize`Sei sicuro di voler ripristinare la condivisione della segnalazione con ${dirittiSegnalazione.odvDestinatario.nomeOdv}?`
    )
    if (result) {
      this.segnalazioniService.ripristinaInoltro(
        dirittiSegnalazione.id
      ).then((res) => {
        if (res.esito === ESITO_OK) {

          dirittiSegnalazione.cancellato = false
          const dirittoOdvDaModificare: DirittiSegnalazione = this.sessionData
            .getSegnalazioneSelezionata()
            .dirittiSegnalazioneOdv.find(
              (diritto) =>
                diritto.odvDestinatario.id === dirittiSegnalazione.odvDestinatario.id
            )

          if (dirittoOdvDaModificare) {
            dirittoOdvDaModificare.cancellato = false
          }
          this.snackBar.open(
            $localize`Condivisione Segnalazione Aggiornata`,
            null,
            {
              duration: 2000,
            }
          )
        } else {
          this.snackBar.open(
            $localize`La modifica della condivisione segnalazione è fallita. Errore: ` +
              JSON.stringify(res.payload),
            null,
            {
              duration: 4000,
            }
          )
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.snackBar.open(
          $localize`La modifica della condivisione segnalazione è fallita. Codice Errore: ` +
            error,
          null,
          {
            duration: 4000,
          }
        )
        console.error(
          'La modifica della condivisione segnalazione è fallita. id segnalazione: ' +
            this.segnalazione.id
        )
      })
    }
  }

  openDialogConfermaCambioVisibilitaSegnalazione(
    dirittiSegnalazione: DirittiSegnalazione
  ): void {
    if (this.dialogRefConfermaCambioVisibilitaSegnalazione === undefined) {
      this.dialogRefConfermaCambioVisibilitaSegnalazione = this.dialog.open(
        ConfermaModificaVisibilitaSegnalazioneInoltrataDialogComponent,
        {
          width: '800px',
          data: {
            nomeGruppoOdv: dirittiSegnalazione.odvDestinatario.nomeOdv,
            statoVibilitaSegnalazione:
              this.statoVisibilitaSegnalante(dirittiSegnalazione),
          },
          disableClose: true,
          hasBackdrop: true,
        }
      )
      this.dialogRefConfermaCambioVisibilitaSegnalazione
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.toggleVisibilitaSegnalazione(dirittiSegnalazione)
          }

          this.dialogRefConfermaCambioVisibilitaSegnalazione = undefined
        })
    }
  }

  openDialogConfermaVisibilitaIdentitaSegnalante(
    dirittiSegnalazione: DirittiSegnalazione
  ): void {
    if (this.dialogRefConfermaVisibilita === undefined) {
      this.dialogRefConfermaVisibilita = this.dialog.open(
        ConfermaVisibilitaIdentitaSegnalanteDialogComponent,
        {
          width: '800px',
          data: {
            nomeGruppoOdv: dirittiSegnalazione.odvDestinatario.nomeOdv,
            mancaConsenso: isNecessarioConsensoSegnalante(
              this.segnalazione,
              this.sessionData.configurazione.consensiSegnalante
            ),
            statoVibilitaSegnalante:
              this.statoVisibilitaSegnalante(dirittiSegnalazione),
          },
          disableClose: true,
          hasBackdrop: true,
        }
      )
      this.dialogRefConfermaVisibilita.afterClosed().subscribe((result) => {
        if (result) {
          this.toggleVisibilitaSegnalante(dirittiSegnalazione)
        }

        this.dialogRefConfermaVisibilita = undefined
      })
    }
  }

  openDialogDirittiInoltro(destinatario: Odv): void {
    if (this.dialogRefDirittiInoltro === undefined) {
      this.dialogRefDirittiInoltro = this.dialog.open(
        DirittiInoltroDialogComponent,
        {
          width: '800px',
          data: {
            isLavorazione: false,
            destinatario: destinatario,
            segnalazioneAnonima: this.segnalazione.anonima,
            mancaConsenso: isNecessarioConsensoSegnalante(
              this.segnalazione,
              this.sessionData.configurazione.consensiSegnalante
            ),
            puoChattareConSegnalante: puoChattareConSegnalante(
              this.authService.getUser(),
              this.segnalazione
            ),
            puoConsultareLavorazione: undefined,
            puoModificareLavorazione: undefined,
            puoCreareLavorazioni: puoAprireLavorazioni(
              this.segnalazione,
              this.utente
            ),
            puoVedereSegnalante: puoCondividereNomeSegnalante(
              this.segnalazione,
              this.utente
            ),
            puoVedereSegnalati: puoConsultareNomeSegnalati(
              this.segnalazione,
              this.utente
            ),
            odvs: gruppiPartecipatiConLavorazioniAperte(
              this.segnalazione,
              this.authService.getUser()
            ),
            segnalazione: this.segnalazione,
          },
          disableClose: true,
          hasBackdrop: true,
        }
      )
      this.dialogRefDirittiInoltro.afterClosed().subscribe((result) => {
        // console.log('Inoltro al destinatario con result:' + JSON.stringify(result));
        if (result) {
          this.loading = true
          this.inoltraSegnalazione(
            result.odvCheInoltra,
            destinatario,
            result.consultazioneLavorazione,
            result.modificaLavorazione,
            result.aperturaLavorazioni,
            result.chat,
            result.nomeSegnalante,
            result.nomiSegnalati,
            true
          )
        }

        this.dialogRefDirittiInoltro = undefined
      })
    }
  }

  inoltraSegnalazione(
    origine: Odv,
    destinatario: Odv,
    consultazioneLavorazione: boolean,
    modificaLavorazione: boolean,
    aperturaLavorazioni: boolean,
    chat: boolean,
    visibilitaNomeSegnalante: boolean,
    visibilitaNomiSegnalati: boolean,
    consultazioneSegnalazioneSospesa: boolean
  ) {
    this.segnalazioniService
      .inoltraSegnalazioneOppureAggiornaInoltro(
        undefined,
        +this.segnalazione.id,
        origine.id,
        destinatario.id,
        1,
        consultazioneSegnalazioneSospesa ? 1 : 0,
        consultazioneLavorazione ? 1 : 0,
        modificaLavorazione ? 1 : 0,
        aperturaLavorazioni ? 1 : 0,
        chat ? 1 : 0,
        visibilitaNomeSegnalante ? 1 : 0,
        visibilitaNomiSegnalati ? 1 : 0
      )
      .then((res) => {
        if (res.esito === ESITO_OK) {
          const nuovoDirittoOdv = JSON.parse(
            res.payload
          ) as DirittiSegnalazione

          if (nuovoDirittoOdv) {
            this.sessionData.getSegnalazioneSelezionata().dirittiSegnalazioneOdv.push(
              nuovoDirittoOdv
            )
          }

          this.snackBar.open($localize`Segnalazione Inoltrata`, null, {
            duration: 2000,
          })
        } else {
          this.snackBar.open(
            $localize`Inoltro della segnalazione fallito. Errore: ` +
              JSON.stringify(res.payload),
            null,
            {
              duration: 4000,
            }
          )
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.snackBar.open(
          $localize`Inoltro della segnalazione fallito. Codice Errore: ` +
            error,
          null,
          {
            duration: 4000,
          }
        )
        console.error(
          'Non è stato possibile inoltrare la segnalazione ' +
            this.segnalazione.id
        )
      })
    // }
  }

  disinoltraSegnalazione(destinatario: DirittiSegnalazione) {
    // console.log('all\'inizio del disinoltro il numero di lavorazioni nell\'array è', this.lavorazioni?.length);

    let indice = this.segnalazione.dirittiSegnalazioneOdv.findIndex(
      (diritto) => diritto.id === destinatario.id
    )
    if (indice === -1) {
      indice = this.segnalazione.dirittiSegnalazioneOdv.findIndex(
        (diritto) => diritto.id === destinatario.id
      )
    }
    this.loading = true

    this.segnalazioniService
      .disinoltraSegnalazione(
        undefined,
        +this.segnalazione.id,
        destinatario.odvDestinatario.id,
        destinatario.odvCheHaInoltrato.id
      )
      .then((res) => {
        if (res.esito === ESITO_OK) {

          const dirittoOdvDaModificare: DirittiSegnalazione = this.sessionData
            .getSegnalazioneSelezionata()
            .dirittiSegnalazioneOdv.find(
              (diritto) =>
                diritto.odvDestinatario.id === destinatario.odvDestinatario.id
            )

          if (dirittoOdvDaModificare) {
            dirittoOdvDaModificare.cancellato = true
          }

          this.snackBar.open(
            $localize`Condivisione Segnalazione Aggiornata`,
            null,
            {
              duration: 2000,
            }
          )
        } else {
          this.snackBar.open(
            $localize`La modifica della condivisione segnalazione è fallita. Errore: ` +
              JSON.stringify(res.payload),
            null,
            {
              duration: 4000,
            }
          )
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.snackBar.open(
          $localize`La modifica della condivisione segnalazione è fallita. Codice Errore: ` +
            error,
          null,
          {
            duration: 4000,
          }
        )
        console.error(
          'La modifica della condivisione segnalazione è fallita. id segnalazione: ' +
            this.segnalazione.id
        )
      })
  }

  readOnly() {
    const ronly =
      this.segnalazione?.id !== undefined &&
      this.segnalazione.stato !== BOZZA_INDEX.toString()
    return ronly
  }

  destinatariVisibili(filtro?: number) {
    return destinatariVisibili(
      this.segnalazione,
      this.authService.getUser(),
      filtro
    )
  }

  readOnlyDestinatari() {
    return readOnlyDestinatari(
      this.segnalazione,
      this.sessionData.configurazione.segnalantePuoScegliereOdv
    )
  }

  omissisInModifica(dirittiSegnalazione: DirittiSegnalazione) {
    return (
      dirittiSegnalazione.odvDestinatario.nomeOdv ===
      this.nomeOdvOmissisInModifica
    )
  }

  statoCondivisione(dirittiSegnalazione: DirittiSegnalazione) {
    return dirittiSegnalazione.segnalazioneCondivisibile
  }

  condivisioneSelezionata(diritti: DirittiSegnalazione) {
    this.omissisService.attivaDisattivaOmissis.next(diritti)
    if (this.nomeOdvOmissisInModifica === diritti.odvDestinatario.nomeOdv) {
      this.nomeOdvOmissisInModifica = undefined
    } else {
      this.nomeOdvOmissisInModifica = diritti.odvDestinatario.nomeOdv
    }
  }

  elencoDestinatariClicked(event) {
    if (!this.readOnly()) {
      this.recuperaTuttiIDestinatari()
    } else {
      console.warn('modifica negata!!!')
    }
  }

  recuperaTuttiIDestinatari(): any {
    this.destinatariSegnalazione = []
    this.segnalazioniService
      .recuperaGruppiGestoriPerAperturaSegnalazione()
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          const gruppi = JSON.parse(esito.payload)
          this.destinatariSegnalazione = gruppi
          this.destinatariSegnalazioneFiltrati = gruppi
          //  Quindi elimina i gruppi già presenti
          this.destinatariSegnalazioneFiltrati =
            this.destinatariSegnalazioneFiltrati.filter((destinatario) => {
              let res = true
              if (
                this.segnalazione &&
                this.segnalazione.dirittiSegnalazioneOdv
              ) {
                res =
                  this.segnalazione.dirittiSegnalazioneOdv.find(
                    (odvtmp) =>
                      odvtmp.odvDestinatario.nomeOdv === destinatario.nomeOdv
                  ) === undefined
              }
              return res
            })
        } else {
          this.snackBar.open(
            $localize`Recupero elenco destinatari Fallito. Errore: ` +
              esito.payload,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero elenco destinatari Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
      .finally(() => {
        this.loading = false
      })
  }

  addDestinatario(event: MatChipInputEvent): void {
    const input: MatChipInput = event.chipInput
    const value = input.inputElement.value
    this.inputDestinatari.nativeElement.value = ''
    if (this.destinatariSegnalazioneFiltrati.length === 1) {
      this.selectedDestinatario(this.destinatariSegnalazioneFiltrati.pop())
    }

    // Reset the input value
    if (input) {
      input.inputElement.value = ''
      this.recuperaTuttiIDestinatari()
    }
  }

  selectedDestinatario(destinatario: Odv) {
    // console.log('destinatario selezionato ' + destinatario.nomeOdv);
    if (
      this.segnalazione.dirittiSegnalazioneOdv &&
      this.segnalazione.dirittiSegnalazioneOdv.find(
        (tmpdestinatario) =>
          tmpdestinatario.odvDestinatario.nomeOdv === destinatario.nomeOdv
      )
    ) {
      // console.log('destinatario già incluso');
    } else {
      if (!this.segnalazione.dirittiSegnalazioneOdv) {
        this.segnalazione.dirittiSegnalazioneOdv =
          new Array<DirittiSegnalazione>()
      }
      const dirittiSegnalazione = new DirittiSegnalazione()
      dirittiSegnalazione.aperturaLavorazioni = true
      dirittiSegnalazione.comunicazioneConSegnalante = true
      dirittiSegnalazione.consultazioneSegnalazione = true
      dirittiSegnalazione.odvCheHaInoltrato = undefined
      dirittiSegnalazione.odvDestinatario = destinatario
      dirittiSegnalazione.sceltoDaSegnalante = true
      dirittiSegnalazione.puoVedereSegnalante = true
      dirittiSegnalazione.puoVedereSegnalati = true
      dirittiSegnalazione.consultazioneSegnalazioneSospesa = true
      this.segnalazione.dirittiSegnalazioneOdv.push(dirittiSegnalazione)
      // this.segnalazioneForm.get('destinatari').setValue(this.segnalazione.dirittiSegnalazioneOdv);
      // @ToDo aggiorna form chiamante
    }
    this.recuperaTuttiIDestinatari()
    this.inputDestinatari.nativeElement.blur()
  }

  tooltipDestinatari(destinatario: DirittiSegnalazione) {
    const result = destinatario.odvCheHaInoltrato
      ? destinatario.cancellato
      ? $localize`Condivisione cancellata da ` + destinatario.odvCheHaInoltrato?.nomeOdv
      : $localize`Coinvolto da ` + destinatario.odvCheHaInoltrato?.nomeOdv
      : $localize`Impostato dal sistema`
    return result
  }

  getTooltipText(key: string): string {
    const translations = {
      visibilityEnabled: $localize`Visibilità della Segnalazione Abilitata`,
      visibilityDisabled: $localize`Visibilità della Segnalazione Disabilitata`,
      communicationDisabled: $localize`Inibisci la comunicazione verso il segnalante`,
      communicationEnabled: $localize`Consenti la comunicazione verso il segnalante`,
      buildDisabled: $localize`Inibisci la creazione di Lavorazioni`,
      buildEnabled: $localize`Consenti la creazione di Lavorazioni`,
      identityEnabled: $localize`Nascondi l'identità del segnalante`,
      identityDisabled: $localize`Mostra l'identità del segnalante`,
      supervisedUserEnabled: $localize`Nascondi l'identità dei segnalati`,
      supervisedUserDisabled: $localize`Mostra l'identità del segnalati`,
      audioInChiaro: $localize`Consenti l'ascolto dell'audio del segnalante in chiaro`,
      audioNonInChiaro: $localize`Non consentire l'ascolto dell'audio del segnalante in chiaro`,
      nonCondivisibile: $localize`Il gestore non potrà condividere la segnalazione con altri gruppi`,
      condivisibile: $localize`Il gestore potrà condividere la segnalazione con altri gruppi`,
    }
    return translations[key]
  }

  get mostraIconaGestioneAudioCifrato() {
    return !(
      this.sessionData.configurazione.mascheraTuttiGliAudio ||
      this.segnalazione.anonima
    )
  }
}
