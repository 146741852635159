import { User } from './user'

export class PinCode {

    id: number

    pincode: string

    utente: User

    dataCreazione: Date

    scaduto: boolean

    dataInvioLinkRecupero: Date
}
