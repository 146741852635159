import { DirittiSegnalazione } from './diritti-segnalazione'
import { Informativa } from './informativa'

import { Segnalazione } from './segnalazione'
import { User } from './user'

export class Consenso {

  static STATI = {
    ATTESA_INOLTRO: 0,
    RICHIESTA_INOLTRATA: 1,
    CONCESSO: 2,
    NEGATO: 3,
    RICHIESTA_ANNULLATA: 4
}

  id: number

  stato: number

  tipoConsenso: string

  nome: string

  cognome: string

  dataRichiesta: Date

  attivo: boolean

  visto: boolean

  dataRisposta: Date

  ipRisposta: string

  segnalazione: Segnalazione

  informativa: Informativa

  richiedente: User
  odvRichiedente: DirittiSegnalazione
}
