<mat-toolbar class="altezza-toolbar-landing">
  <div style="height: 100%;" class="flex-row" style="align-items: center;">
    <div class="div-flex-element"></div>
    <div class="flex-item column-row-switch flex-gap-20">
      <div class="flex-row margin-auto" >
        <img *ngIf="timeoutCaricamento" src="{{getLogoCliente()}}" class="logo-cliente">
      </div>
      <div class="flex-row flex-lang-selector-pos" style="align-self: start;">
        <app-language-selector></app-language-selector>
      </div>
    </div>
    <div class="div-flex-element"></div>
  </div>
</mat-toolbar>

