<span class="bottone-flottante-alto-sinistra no-print">
    <button mat-mini-fab (click)='backToMainLogin();$event.stopPropagation();' i18n-matTooltip matTooltip="Torna Indietro">
        <mat-icon class="icona-fab" aria-label="Torna indietro">keyboard_arrow_left</mat-icon>
    </button>
</span>
<div class="flex-column">
    <div style="margin-left: 44px;">
        <img src="{{getLogoCliente()}}" class="logo-cliente">
    </div>
    <mat-toolbar class="centered flex-row toolbar-landing-extra" >
      <div></div>
      <div class="testo-x-large centered align-items-center titolo-verifica-email"
             i18n>Verifica Email</div>
             <div></div>
    </mat-toolbar>
    <div class="alternate-theme flex-column" >
        <mat-card class="no-border flex-column flex-gap-30">
            <div class="flex-column centered align-items-center" style="height:20%" *ngIf="!emailVerificata && tokenVerificaEmail">
                <div class="flex-100" center i18n>Verifica in corso, attendere...</div>
                <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
            </div>

            <div class="flex-column" style="height:20%" *ngIf="!tokenVerificaEmail">
                <div class="flex-100 centered align-items-center" center i18n>Nessun token di verifica ricevuto</div>
            </div>


            <div *ngIf="emailVerificata" class="flex-row">

                <H2 class="centered align-items-center text-center" i18n>Il tuo indirizzo email è adesso abilitato alla ricezione delle notifiche.</H2>

            </div>
        </mat-card>

    </div>
</div>
