import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Esito } from '../utility/esito'
import { ServiziRest } from '../utility/utility'

@Injectable({
  providedIn: 'root'
})
export class ClientiService {

  constructor(
    private http: HttpClient,
  ) { }

  getClients(): Observable<Esito> {
    return this.http.get<Esito>(`${ServiziRest.urlClienti}`)
  }

  createNewClient(newClientName: string, existingClientName: string, ipaddress: string): Observable<Esito> {
    const payload = { clientName: newClientName, sourceClientName: existingClientName, ipaddress: ipaddress }
    return this.http.post<Esito>(`${ServiziRest.urlClienti}`, payload)
  }
}
