<div class="overlay flex-row" *ngIf="savingOrLoading">
  <mat-spinner diameter="50" strokeWidth="5" style="margin: auto;"></mat-spinner>
</div>
<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav mode="push">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon color="">menu</mat-icon>
    </button>￼
  </mat-sidenav>

  <mat-sidenav-content>
    <app-toolbar></app-toolbar>
    <div style="position:relative; display:flex; flex-direction: column">
      <div class="flex-row">
      <div i18n class="titolo-pagina">
        Gestione Utenti
      </div>
      <div>
        <!-- aggiunge una checkbox material per abiltare o meno il recupero degli utenti segnalanti -->
        <mat-checkbox style='padding:5px;' matInput type="checkbox" #ancheSegnalanti (change)='recuperaUtenti(ancheSegnalanti.checked)' i18n>
          Recupera anche gli utenti segnalanti
        </mat-checkbox>
      </div>
    </div>
      <div class="mat-elevation-z8" id="listaUtenti">
        <mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows (matSortChange)="sortData($event)">

          <mat-header-row *matHeaderRowDef="colonneVisualizzate" ></mat-header-row>
          <mat-row *matRowDef="let utente; columns: colonneVisualizzate" class="pointer" (click)="selected(utente)"></mat-row>

          <ng-container matColumnDef="id">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.id}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="nome">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Nome </mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.nome | maskEncrypted}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="cognome">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Cognome </mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.cognome | maskEncrypted}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="userId">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Nickname </mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.userId}} </mat-cell>
          </ng-container>

<!--           <ng-container matColumnDef="lingua">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Lingua</mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.lingua | maskEncrypted}} </mat-cell>
          </ng-container> -->

          <ng-container matColumnDef="email">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Email</mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.email | maskEncrypted}} </mat-cell>
          </ng-container>

<!--           <ng-container matColumnDef="mobile">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Numero Telefonico </mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.mobile | maskEncrypted}} </mat-cell>
          </ng-container> -->
          <ng-container matColumnDef="odv">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Gestore </mat-header-cell>
            <mat-cell *matCellDef="let utente">
              <mat-checkbox style='padding:5px;' matInput type="checkbox" (change)='salvaUtente()' [(ngModel)]="utente.odv"
                (keydown)="salvaSuInvio($event,utente)" [disabled]="true">
              </mat-checkbox>
              <div *ngIf="utente.odv">
                <mat-chip-list #chipList>
                  <div *ngFor="let group of utente.odvMembership; let i=index">
                    <mat-chip>
                      {{ group.nomeOdv}}
                    </mat-chip>
                  </div>
                </mat-chip-list>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="su">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header i18n-matTooltip matTooltip="Super Utente"> SU </mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
              <mat-checkbox [disabled]="true" matInput type="checkbox" [(ngModel)]="utente.su" (change)='handleSuChange(utente)'>
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="suDatiNonSensibili">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header i18n-matTooltip matTooltip="Super Utente senza accesso ai dati sensibili"> SU limitato</mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
              <mat-checkbox [disabled]="true" matInput type="checkbox" [(ngModel)]="utente.suDatiNonSensibili" (change)='handleSuDatiNonSensibiliChange(utente)'>
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="amministratoreGestori">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header i18n-matTooltip matTooltip="Amministratore Gestori"> AG </mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
              <mat-checkbox matInput type="checkbox" [(ngModel)]="utente.amministratoreGestori" (change)='salvaUtente()' (keydown)="salvaSuInvio($event,utente)"
              [disabled]="true">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="custode" *ngIf="custodiaAttiva()">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Custode </mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
              <mat-checkbox matInput type="checkbox" [(ngModel)]="utente.custode" (change)='salvaUtente()' (keydown)="salvaSuInvio($event,utente)"
              [disabled]="true">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="disabilitato">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Disab. </mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
              <mat-checkbox matInput type="checkbox" [(ngModel)]="utente.disabilitato" (change)='salvaUtente()'
                (keydown)="salvaSuInvio($event,utente)" [disabled]="true">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="possoUsareEmailPerNotifiche">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Notifiche </mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
              <mat-checkbox matInput type="checkbox" [(ngModel)]="utente.possoUsareEmailPerNotifiche" (change)='salvaUtente()'
                (keydown)="salvaSuInvio($event,utente)" [disabled]="true">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <!-- 'emailVerificata', 'emailVerificaInviata', 'dataInvioLinkVerificaEmail','pinCode.dataInvioLinkRecupero' -->
          <ng-container matColumnDef="emailVerificata">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="centrato">
              Email Verificata
            </mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
              <mat-checkbox matInput type="checkbox" [(ngModel)]="utente.emailVerificata"
              (change)='salvaUtente()' (keydown)="salvaSuInvio($event,utente)" [disabled]="true">
              </mat-checkbox>
            </mat-cell>
        </ng-container>

          <ng-container matColumnDef="emailVerificaInviata">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header style="font-size: smaller!important;"> Email Verifica Inviata </mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
              <mat-checkbox matInput type="checkbox" [(ngModel)]="utente.emailVerificaInviata" (change)='salvaUtente()'
                (keydown)="salvaSuInvio($event,utente)" [disabled]="true">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="dataInvioLinkVerificaEmail">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header style="font-size: smaller!important;"> Data Invio Verifica Email </mat-header-cell>
            <mat-cell *matCellDef="let utente" style="font-size: smaller!important;"> {{utente.dataInvioLinkVerificaEmail | date:'short'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="dataInvioLinkRecupero">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header style="font-size: smaller!important;"> Data Invio Recupero Pin</mat-header-cell>
            <mat-cell *matCellDef="let utente" style="font-size: smaller!important;"> {{utente.pinCode?.dataInvioLinkRecupero | date:'short'}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="azioni">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="width-full">
              <button mat-icon-button class="destra" (click)="aggiungiUtente(); $event.stopPropagation();" *ngIf="puoCreareUtenti()"
              i18n-matTooltip matTooltip="Crea un nuovo Utente">
                <mat-icon class="icone-testata-tabelle" aria-label="aggiungi utente">add_circle</mat-icon>
              </button>
            </mat-header-cell>
            <mat-cell *matCellDef="let utente">
              <button *ngIf="superUtente()" mat-icon-button class="destra" (click)="openDialogConfermaEliminazioneUtente(utente); $event.stopPropagation();"
              [disabled]="!modificabile(utente)" i18n-matTooltip matTooltip="Elimina Utente">
                <mat-icon class="icona-riga-tabella" aria-label="cancella utente">delete</mat-icon>
              </button>

              <button *ngIf="!utente.disabilitato" mat-icon-button class="destra" (click)="openDialogModificaUtente(utente); $event.stopPropagation();"
              [disabled]="!modificabile(utente)">
                <mat-icon class="icona-riga-tabella" aria-label="modifica utente">create</mat-icon>
              </button>

              <button *ngIf="!utente.disabilitato" mat-icon-button class="destra" (click)="openDialogConfermaDisabilitazioneUtente(utente); $event.stopPropagation();"
              [disabled]="!modificabile(utente)" i18n-matTooltip matTooltip="Disabilita Utente">
                <mat-icon class="icona-riga-tabella" aria-label="riattiva utente" >arrow_circle_down</mat-icon>
              </button>

              <button *ngIf="utente.disabilitato" mat-icon-button class="destra" (click)="openDialogConfermaRiattivazioneUtente(utente); $event.stopPropagation();"
              [disabled]="!modificabile(utente)" i18n-matTooltip matTooltip="Riabilita Utente">
                <mat-icon class="icona-riga-tabella" aria-label="riattiva utente" >arrow_circle_up</mat-icon>
              </button>

              <button *ngIf="utente.bloccato" mat-icon-button class="destra" (click)="openDialogConfermaSbloccoUtente(utente); $event.stopPropagation();"
              [disabled]="!modificabile(utente)" i18n-matTooltip matTooltip="Sblocca Utente">
                <mat-icon class="icona-riga-tabella" aria-label="sblocca utente" >lock_open</mat-icon>
              </button>

              <button *ngIf="!utente.disabilitato && utente.email && (!utente.sso || !utente.emailVerificata)" mat-icon-button class="destra" (click)="inviaEmail(utente); $event.stopPropagation();"
              [disabled]="!modificabile(utente)" [matTooltip]="calcolaTooltipIconaEmail(utente)">
                <mat-icon class="icona-riga-tabella" aria-label="invia email per recupero PIN" >email</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> todo </mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
            </mat-cell>
          </ng-container>
          <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="riga-dettaglio-inner"></mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
        </mat-paginator>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
