<!-- edit-idp-dialog.component.html -->
<h1 mat-dialog-title i18n>Modifica IdP</h1>
<div mat-dialog-content>
  <!-- Campi di input per nome e certificato -->
  <mat-form-field>
    <mat-label i18n>Nome IdP</mat-label>
    <input matInput [(ngModel)]="idpName" required>
  </mat-form-field>

  <mat-form-field>
    <mat-label i18n>Entry Point</mat-label>
    <textarea matInput [(ngModel)]="idpEntryPoint" required></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label i18n>Issuer</mat-label>
    <textarea matInput [(ngModel)]="idpIssuer" required></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label i18n>Callback URL</mat-label>
    <textarea matInput [(ngModel)]="idpCallbackUrl" required></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label i18n>Certificato IdP</mat-label>
    <textarea matInput [(ngModel)]="idpCertificate" required></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()" i18n>Annulla</button>
  <button mat-button
          [disabled]="!isValidCertificate(idpCertificate) || !idpName.trim() || !isValidUrl(idpEntryPoint)"
          (click)="onSave()" i18n>Salva</button>
</div>
