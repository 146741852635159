/* eslint-disable @typescript-eslint/no-use-before-define */
import { DirittiSegnalazione } from './../modello/diritti-segnalazione'
import { LavorazioneService } from '../servizi/lavorazione.service'
import { User } from '../modello/user'
import {
  INAMMISSIBILE_INDEX,
  CHIUSA_INDEX,
  IN_LAVORAZIONE_INDEX,
  ISTRUTTTORIA_APERTA_INDEX,
  NUOVA_INDEX,
  NON_COMPETENTE_INDEX,
  BOZZA_INDEX,
  SEGNALAZIONE_RICEVUTA_INDEX,
  ISTRUTTORIA_IN_CORSO_INDEX,
} from './utility'
import { Segnalazione } from '../modello/segnalazione'

import { Lavorazione } from '../modello/lavorazione'
import { Odv } from '../modello/odv'
import { DirittiLavorazione } from '../modello/diritti-lavorazione'
import { Consenso } from '../modello/consenso'
import { Segnalante } from '../modello/segnalante'
import { Documento } from '../modello/documento'
import { Configurazione } from '../modello/configurazione'

// eslint-disable-next-line @typescript-eslint/naming-convention
import { PDFDocument } from 'pdf-lib'
import { Azienda } from '../modello/azienda'
import { OdvAzienda } from '../modello/odv-azienda'


export function isOdv(user: User): boolean {
  return user?.odv
}

// tslint:disable-next-line:no-shadowed-variable
export function editabile(segnalazione: Segnalazione): boolean {
  return (
    +segnalazione.stato !== INAMMISSIBILE_INDEX &&
    +segnalazione.stato !== CHIUSA_INDEX
  )
}

// tslint:disable-next-line:no-shadowed-variable
export function destinatarioEditabile(
  segnalazione: Segnalazione,
  user: User
): boolean {
  if (
    (user?.odv || segnalante(segnalazione, user)) &&
    segnalazione.stato !== CHIUSA_INDEX.toString()
  ) {
    return true
  } else {
    return false
  }
}

// tslint:disable-next-line:no-shadowed-variable
export function corpoSegnalazioneEditabile(
  segnalazione: Segnalazione
): boolean {
  return !segnalazione.stato
}

// tslint:disable-next-line:no-shadowed-variable
export function mittenteSegnalazione(
  segnalazione: Segnalazione,
  user: User
): boolean {
  return (
    segnalazione.segnalante && segnalazione.segnalante.utente.id === user.id
  )
}

// tslint:disable-next-line:no-shadowed-variable
export function segnalante(segnalazione: Segnalazione, user: User): boolean {
  // @ToDo da rivedere in fase di test
  return user.id === +segnalazione.segnalante?.id
}

// tslint:disable-next-line:no-shadowed-variable
export async function promuoviStatoLavorazione(
  lavorazione: Lavorazione,
  segnalazione: Segnalazione,
  lavorazioneService: LavorazioneService
): Promise<string> {
  let nuovoStato: number

  switch (+lavorazione.stato) {
    case 1:
      nuovoStato = IN_LAVORAZIONE_INDEX
      break
    case 2:
      nuovoStato = ISTRUTTTORIA_APERTA_INDEX
      break
    case 3:
      nuovoStato = CHIUSA_INDEX
      break
    case 4:
      nuovoStato = IN_LAVORAZIONE_INDEX
      break
    case 5:
      nuovoStato = IN_LAVORAZIONE_INDEX
      break
    case 6:
      nuovoStato = IN_LAVORAZIONE_INDEX
      break
  }

  lavorazione.stato = nuovoStato.toString()

  lavorazioneService
    .setStatoLavorazione(lavorazione, segnalazione)
    .then(() => nuovoStato)
    .catch((error) => {
      throw error
    })
  return nuovoStato.toString()
}

export function getStringaStato(stato: string): string {
  switch (+stato) {
    case NUOVA_INDEX:
      return $localize`Nuova`

    case IN_LAVORAZIONE_INDEX:
      return $localize`In Lavorazione`

    case ISTRUTTTORIA_APERTA_INDEX:
      return $localize`Istruttoria Aperta`

    case CHIUSA_INDEX:
      return $localize`Chiusa`

    case INAMMISSIBILE_INDEX:
      return $localize`Inammissibile`

    case NON_COMPETENTE_INDEX:
      return $localize`Non Competente`

    case BOZZA_INDEX:
      return $localize`Bozza`

    case SEGNALAZIONE_RICEVUTA_INDEX:
      return $localize`Segnalazione Ricevuta`

    case ISTRUTTORIA_IN_CORSO_INDEX:
      return $localize`Istruttoria in Corso`
  }
}

// tslint:disable-next-line:no-shadowed-variable
export function prossimoStatoLavorazione(lavorazione: Lavorazione): string {
  switch (+lavorazione.stato) {
    case NUOVA_INDEX:
      return $localize`Avvia Lavorazione`

    case IN_LAVORAZIONE_INDEX:
      return $localize`Apri Istruttoria`

    case ISTRUTTTORIA_APERTA_INDEX:
      return $localize`Chiudi Lavorazione`

    case CHIUSA_INDEX:
      return $localize`Riapri in Lavorazione`

    case INAMMISSIBILE_INDEX:
      return $localize`Riapri in Lavorazione`

    case NON_COMPETENTE_INDEX:
      return $localize`Riapri in Lavorazione`
  }
}

// tslint:disable-next-line:no-shadowed-variable
export function iconaTransizione(lavorazione: Lavorazione): string {
  switch (+lavorazione.stato) {
    case NUOVA_INDEX:
      return 'build'

    case IN_LAVORAZIONE_INDEX:
      return 'question_answer'

    case ISTRUTTTORIA_APERTA_INDEX:
      return 'done'
    case CHIUSA_INDEX:
      return 'undo'
    case INAMMISSIBILE_INDEX:
      return 'undo'
    case NON_COMPETENTE_INDEX:
      return 'undo'
  }
}

// tslint:disable-next-line:no-shadowed-variable
/* export function iconaTransizioneNota(nota: NoteGestore) {
  switch (+nota.stato) {
    case NUOVA_INDEX:
      return 'build';

    case IN_LAVORAZIONE_INDEX:
      return 'question_answer';

    case ISTRUTTTORIA_APERTA_INDEX:
      return 'done';
    case CHIUSA_INDEX:
      return 'undo';
    case INAMMISSIBILE_INDEX:
      return 'undo';
    case NON_COMPETENTE_INDEX:
      return 'undo';
  }
} */

/* export function puoGestireSegnalazione(partecipante: User, lavorazione?: Lavorazione, miasegnalazione?: Segnalazione): boolean {
  let result = partecipante.odvMembership.find(membro => {
    return miasegnalazione.dirittiSegnalazioneOdv.find(diritto => +diritto.odvDestinatario.id === +membro.id) !== undefined;
  });

  if (!result && lavorazione) {
    result = partecipante.odvMembership.find(membro => {
      return lavorazione.dirittiOdv && lavorazione.dirittiOdv.find(diritto => +diritto.odv.id === +membro.id) !== undefined;
    });
  }
  return result !== undefined;
} */

export function isSegnalante(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  return miasegnalazione.segnalante &&
    miasegnalazione.segnalante.utente &&
    miasegnalazione.segnalante.utente.id &&
    mioutente
    ? miasegnalazione.segnalante.utente.id === mioutente.id
    : false
}

export function isPresaInCarico(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  return miasegnalazione.segnalante &&
    miasegnalazione.segnalante.utente &&
    miasegnalazione.segnalante.utente.id
    ? miasegnalazione.segnalante.utente.id === mioutente.id
    : false
}

export function isGestoreLavorazioniSegnalazione(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  let result = miasegnalazione !== null && miasegnalazione !== undefined
  result =
    result &&
    miasegnalazione.lavorazioni &&
    miasegnalazione.lavorazioni.some(
      (lavorazione) =>
        lavorazione.dirittiOdv &&
        lavorazione.dirittiOdv.some(
          (diritto) =>
            isMembroDiGruppo(mioutente, diritto.odv) &&
            (diritto.gestore || diritto.coGestore)
        )
    )
  return result
}

export function isGestoreSegnalazione(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  let result = miasegnalazione !== null && miasegnalazione !== undefined
  result =
    result &&
    miasegnalazione.lavorazioni &&
    miasegnalazione.lavorazioni.some(
      (lavorazione) =>
        lavorazione.dirittiOdv &&
        lavorazione.dirittiOdv.some(
          (diritto) =>
            isMembroDiGruppo(mioutente, diritto.odv) &&
            (diritto.gestore || diritto.coGestore)
        )
    )
  return result
}

export function isOdvGestoreOCogestoreSegnalazione(
  miasegnalazione: Segnalazione,
  odv: Odv
): boolean {
  let result = miasegnalazione !== null && miasegnalazione !== undefined
  result =
    result &&
    miasegnalazione.lavorazioni &&
    miasegnalazione.lavorazioni.some(
      (lavorazione) =>
        lavorazione.dirittiOdv &&
        lavorazione.dirittiOdv.some(
          (diritto) =>
            +diritto.odv.id === +odv.id &&
            (diritto.gestore || diritto.coGestore)
        )
    )
  return result
}

export function isOdvSceltoDaSegnalanteByOdvId(
  miasegnalazione: Segnalazione,
  idOdv: number
): boolean {
  let result = miasegnalazione !== null && miasegnalazione !== undefined
  result =
    result &&
    miasegnalazione.dirittiSegnalazioneOdv &&
    miasegnalazione.dirittiSegnalazioneOdv.find(
      (diritto) =>
        diritto.odvDestinatario.id === idOdv && diritto.sceltoDaSegnalante
    ) !== undefined
  return result
}

export function isOdvSceltoDaSegnalanteByOdvName(
  miasegnalazione: Segnalazione,
  odvName: string
): boolean {
  let result = miasegnalazione !== null && miasegnalazione !== undefined
  result =
    result &&
    miasegnalazione.dirittiSegnalazioneOdv &&
    miasegnalazione.dirittiSegnalazioneOdv.find(
      (diritto) =>
        diritto.odvDestinatario.nomeOdv === odvName &&
        diritto.sceltoDaSegnalante
    ) !== undefined
  return result
}

export function isUtenteMembrodiOdvSceltoDaSegnalante(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  let result = miasegnalazione !== null && miasegnalazione !== undefined
  result =
    result &&
    miasegnalazione.dirittiSegnalazioneOdv &&
    miasegnalazione.dirittiSegnalazioneOdv.find(
      (diritto) =>
        isMembroDiGruppo(mioutente, diritto.odvDestinatario) &&
        diritto.sceltoDaSegnalante
    ) !== undefined
  return result
}

export function odvUtenteChePossonoFareRichiestaDiConsensoAlSegnalante(
  user: User,
  segnalazione: Segnalazione
): DirittiSegnalazione[] {
  //  ricava i gruppi odv di cui l'utente è membro e che hanno ricevuto la segnalazione dal segnalante
  const odvCoinvolti: DirittiSegnalazione[] =
    segnalazione.dirittiSegnalazioneOdv.filter(
      (diritto) =>
        isMembroDiGruppo(user, diritto.odvDestinatario) &&
        diritto.sceltoDaSegnalante
    )

  const consensiInApprovazioneOApprovati = segnalazione.consensi?.filter(
    (consenso) =>
      consenso.stato === Consenso.STATI.ATTESA_INOLTRO ||
      consenso.stato === Consenso.STATI.RICHIESTA_INOLTRATA ||
      (consenso.stato === Consenso.STATI.CONCESSO && consenso.attivo)
  )
  const odvConAlmenoUnConsensoInAttesaOApprovato =
    consensiInApprovazioneOApprovati.map(
      (consenso) => consenso.odvRichiedente?.odvDestinatario
    )
  const odvChePossonoFareRichiesta = odvCoinvolti.filter((odv) =>
    odvConAlmenoUnConsensoInAttesaOApprovato.every(
      (odv2) => odv2?.id !== odv.odvDestinatario.id
    )
  )
  return odvChePossonoFareRichiesta
}

export function odvHaRicevutoConsensoDaSegnalante(
  odv: Odv,
  segnalazione: Segnalazione
): boolean {
  if (odv === null || odv === undefined) {
    return false
  }
  //  ricava i gruppi odv di cui l'utente è membro e che hanno ricevuto la segnalazione dal segnalante

  //  ricava i dirittiOdv
  const dirittoOdv: DirittiSegnalazione =
    segnalazione.dirittiSegnalazioneOdv.find(
      (diritti) => +diritti.odvDestinatario?.id === +odv.id
    )
  const consensiApprovati = segnalazione.consensi?.filter(
    (consenso) =>
      consenso.stato === Consenso.STATI.CONCESSO &&
      consenso.attivo &&
      +consenso.odvRichiedente?.id === +dirittoOdv.id
  )
  return consensiApprovati?.length > 0
}

export function dirittiSegnalazioneUtenteRicevutiDaSegnalante(
  segnalazione: Segnalazione,
  user: User
): DirittiSegnalazione[] {
  const result = segnalazione.dirittiSegnalazioneOdv.filter(
    (dodv) =>
      user.odvMembership.find(
        (odv) => +odv.id === +dodv.odvDestinatario?.id
      ) !== undefined
  )
  return result
}

export function isOdvGestoreSegnalazione(
  miasegnalazione: Segnalazione,
  odv: Odv
): boolean {
  let result = miasegnalazione !== null && miasegnalazione !== undefined
  result =
    result &&
    miasegnalazione.lavorazioni &&
    miasegnalazione.lavorazioni.some(
      (lavorazione) =>
        lavorazione.dirittiOdv &&
        lavorazione.dirittiOdv.some(
          (diritto) => +diritto.odv.id === +odv.id && diritto.gestore
        )
    )
  return result
}

export function isGestoreLavorazione(
  mialavorazione: Lavorazione,
  mioutente: User
): boolean {
  const gestore = gestoreLavorazione(mialavorazione)
  if (gestore && isMembroDiGruppo(mioutente, gestore)) {
    return true
  }
  return false

  // const hoDirittiInoltrati = mialavorazione.dirittiOdv.some(diritto => {
  //   const cogestore = diritto.coGestore;
  //   const membroDiGruppo = isMembroDiGruppo(mioutente, diritto.odv);
  //   const segnalazioneNonNull = diritto.segnalazione !== null;
  //   return cogestore && membroDiGruppo && segnalazioneNonNull;
  // });
  // return hoDirittiInoltrati;
}

export function isGestoreOCogestoreLavorazione(
  mialavorazione: Lavorazione,
  mioutente: User
): boolean {
  const gestore = gestoreLavorazione(mialavorazione)
  if (gestore && isMembroDiGruppo(mioutente, gestore)) {
    return true
  }

  const hoDirittiInoltrati = mialavorazione.dirittiOdv.some((diritto) => {
    const cogestore = diritto.coGestore
    const membroDiGruppo = isMembroDiGruppo(mioutente, diritto.odv)
    const segnalazioneNonNull = diritto.segnalazione !== null
    return cogestore && membroDiGruppo && segnalazioneNonNull
  })
  return hoDirittiInoltrati
}

/*   const result = mialavorazione && mialavorazione.dirittiOdv && mialavorazione.dirittiOdv.
    some(diritto => isMembroDiGruppo(mioutente, diritto.odv) && (diritto.gestore || diritto.coGestore));
  return result; */

export function isOdvGestoreLavorazione(
  mialavorazione: Lavorazione,
  odv: Odv
): boolean {
  const result =
    mialavorazione &&
    mialavorazione.dirittiOdv &&
    mialavorazione.dirittiOdv.some(
      (diritto) =>
        +diritto.odv.id === +odv.id && (diritto.gestore || diritto.coGestore)
    )
  return result
}

export function isUtenteGestoreDiAlmenoUnaLavorazione(
  segnalazione: Segnalazione,
  dirittoSegnalazione: DirittiSegnalazione
): boolean {
  const lavorazioni = segnalazione.lavorazioni
  const result =
    lavorazioni?.find(
      (lavorazione) =>
        lavorazione.dirittiOdv &&
        lavorazione.dirittiOdv.some(
          (diritto) =>
            (diritto.gestore || diritto.coGestore) &&
            +diritto.odv.id === +dirittoSegnalazione.odvDestinatario.id
        )
    ) !== undefined

  return result
}

export function gruppiPartecipatiConLavorazioniAperte(
  segnalazione: Segnalazione,
  utente: User
): Odv[] {
  const lavorazioni = segnalazione.lavorazioni
  // restituisce i dirittiOdv delle lavorazioni aperte che hanno come gruppo destinatario uno dei gruppi di cui l'utente è membro
  const result =
    lavorazioni?.filter(
      (lavorazione) =>
        lavorazione.dirittiOdv &&
        lavorazione.dirittiOdv.some(
          (diritto) =>
            (diritto.gestore || diritto.coGestore) &&
            isMembroDiGruppo(utente, diritto.odv)
        )
    )?.map((lavorazione) => gestoreLavorazione(lavorazione))

  return result
}

export function isSoloIoGestore(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  return (
    isGestoreSegnalazione(miasegnalazione, mioutente) &&
    !isAltroGestore(miasegnalazione, mioutente)
  )
}

export function isIndirizzataAMioGruppo(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  if (miasegnalazione && miasegnalazione.dirittiSegnalazioneOdv) {
    miasegnalazione.dirittiSegnalazioneOdv.forEach((diritto) => {
      const indice = mioutente.odvMembership.findIndex(
        (odv1) => odv1.id === diritto.odvDestinatario.id
      )
      if (indice !== -1) {
        return true
      }
    })
  } else {
    return false
  }
}

export function isAltroGestore(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  let result = miasegnalazione !== null && miasegnalazione !== undefined
  result =
    result &&
    miasegnalazione.lavorazioni &&
    miasegnalazione.lavorazioni.some(
      (lavorazione) =>
        lavorazione.dirittiOdv &&
        lavorazione.dirittiOdv.some(
          (diritto) =>
            !isMembroDiGruppo(mioutente, diritto.odv) &&
            (diritto.gestore || diritto.coGestore)
        )
    )
  return result
}

export function isSoloAltroGestore(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  return (
    isAltroGestore(miasegnalazione, mioutente) &&
    !isGestoreSegnalazione(miasegnalazione, mioutente)
  )
}

export function isCoGestita(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  let result = miasegnalazione !== null && miasegnalazione !== undefined
  result =
    result &&
    miasegnalazione &&
    miasegnalazione.lavorazioni &&
    miasegnalazione.lavorazioni.some(
      (lavorazione) =>
        lavorazione.dirittiOdv &&
        lavorazione.dirittiOdv.some(
          (diritto) =>
            !isMembroDiGruppo(mioutente, diritto.odv) &&
            (diritto.gestore || diritto.coGestore)
        )
    )
  return result && isGestoreSegnalazione(miasegnalazione, mioutente)
}

export function isSegnalazioneNonGestita(
  miasegnalazione: Segnalazione,
  mioutente: User
): boolean {
  return (
    !isAltroGestore(miasegnalazione, mioutente) &&
    !isGestoreSegnalazione(miasegnalazione, mioutente)
  )
}

export function isLavorazioneGestita(
  lavorazione: Lavorazione,
  segnalazione: Segnalazione
): boolean {
  const dirittoGestore = lavorazione.dirittiOdv.some(
    (dirittoLavorazione) =>
      segnalazione.dirittiSegnalazioneOdv.some(
        (dirittoSegnalazione) =>
          +dirittoSegnalazione.odvDestinatario.id === +dirittoLavorazione.odv.id
      ) && dirittoLavorazione.gestore
  )
  if (dirittoGestore) {
    return true
  } else {
    return false
  }
}

/**
 * Ritorna il primo gruppo che detiene diritti di gestore sulla lavorazione.
 * @param lavorazione
 */
export function gestoreLavorazione(lavorazione: Lavorazione): Odv {
  return lavorazione.dirittiOdv.find((diritti) => diritti.gestore).odv
}

export function coGestoreLavorazione(
  lavorazione: Lavorazione
): DirittiLavorazione[] {
  return lavorazione.dirittiOdv.filter((diritti) => diritti.coGestore)
}

export function gruppoGestoreLavorazioneUtente(
  lavorazione: Lavorazione,
  utente: User
): Odv {
  if (!lavorazione) {
    return null
  }
  const dirittoGestore = lavorazione.dirittiOdv.find(
    (dirittoLavorazione) => dirittoLavorazione.gestore
  )
  const dirittoCoGestore = lavorazione.dirittiOdv.find(
    (dirittoLavorazione) => dirittoLavorazione.coGestore
  )
  if (
    dirittoGestore &&
    utente?.odvMembership.find((odv) => odv.id === dirittoGestore.odv.id)
  ) {
    return dirittoGestore.odv
  }
  if (
    dirittoCoGestore &&
    utente?.odvMembership.find((odv) => odv.id === dirittoCoGestore.odv.id)
  ) {
    return dirittoCoGestore.odv
  }
  const dirittoConsultazione = lavorazione.dirittiOdv.find(
    (dirittoLavorazione) =>
      utente?.odvMembership.find((odv) => odv.id === dirittoLavorazione.odv.id)
  )
  return dirittoConsultazione?.odv
}

/**
 * Al momento potrebbero esserci anche lavorazioni per le quali non si detiene più il diritto sulla
 * segnalazione collegata. Da decidere se vanno eliminate. QUesto metodo non le considera e ricerca
 * solo tra i gruppi Odv per i quali esiste un dirittosegnalazione valido
 * @param lavorazione
 *
 */
export function gestoreLavorazioneAbilitato(lavorazione: Lavorazione): Odv {
  if (!lavorazione) {
    return null
  }
  const dirittoGestore = lavorazione.dirittiOdv.find(
    (dirittoLavorazione) => dirittoLavorazione.gestore
  )
  const dirittoCoGestore = lavorazione.dirittiOdv.find(
    (dirittoLavorazione) => dirittoLavorazione.coGestore
  )
  if (dirittoGestore) {
    return dirittoGestore.odv
  } else if (dirittoCoGestore) {
    return dirittoCoGestore.odvCheHaCondiviso
  }
  return null
}

export function gruppoOdvCoinvolto(
  miasegnalazione: Segnalazione,
  mioutente: User,
  lavorazione?: Lavorazione
): Odv {
  if (lavorazione) {
    const diritto = lavorazione.dirittiOdv.find((dirittoTmp) =>
      mioutente.odvMembership.some((odv) => +odv.id === +dirittoTmp.odv.id)
    )
    if (diritto) {
      return diritto.odv
    } else {
      return undefined
    }
  } else {
    if (miasegnalazione && miasegnalazione.dirittiSegnalazioneOdv) {
      for (let i = 0; i < miasegnalazione.dirittiSegnalazioneOdv.length; i++) {
        const diritto = miasegnalazione.dirittiSegnalazioneOdv[i]
        const indice = mioutente.odvMembership.findIndex(
          (odv1) => odv1.id === diritto.odvDestinatario.id
        )
        if (indice !== -1) {
          return mioutente.odvMembership[indice]
        }
      }
    } else {
      return undefined
    }
  }
}

/**
 * Ritorna tutti i gruppi Odv che mioutente può utilizzare per prendere in carico miasegnalazione
 * @param miasegnalazione
 * @param mioutente
 */
export function gruppiOdvUtilizzabiliPerPresaInCarico(
  miasegnalazione: Segnalazione,
  mioutente: User
): Odv[] {
  let result: Odv[] = []
  if (miasegnalazione && miasegnalazione.dirittiSegnalazioneOdv) {
    for (let i = 0; i < miasegnalazione.dirittiSegnalazioneOdv.length; i++) {
      const diritto = miasegnalazione.dirittiSegnalazioneOdv[i]
      result = result.concat(
        mioutente.odvMembership.filter(
          (odv1) => odv1.id === diritto.odvDestinatario.id
        )
      )
    }
  }
  return result
}

export function isMembroDiGruppo(mioutente: User, odv: Odv): boolean {
  if (!odv) {
    return false
  }
  if (!mioutente || !mioutente.odvMembership) {
    return false
  } else {
    return (
      mioutente.odvMembership.find((gruppo) => +gruppo.id === +odv.id) !==
      undefined
    )
  }
}

export function isMembroDiGruppi(mioutente: User, odvs: Odv[]): boolean {
  if (!mioutente || !mioutente.odvMembership) {
    return false
  } else {
    return odvs.every(
      (odv) =>
        mioutente.odvMembership.find((gruppo) => +gruppo.id === +odv.id) !==
        undefined
    )
  }
}

export function isMembroDiAlmenoUnGruppo(
  mioutente: User,
  odvs: Odv[]
): boolean {
  if (!mioutente || !mioutente.odvMembership) {
    return false
  } else {
    return odvs?.some(
      (odv) =>
        mioutente.odvMembership.find((gruppo) => +gruppo.id === +odv.id) !==
        undefined
    )
  }
}

export function listaOdvDestinatari(diritti: DirittiSegnalazione[]): Odv[] {
  const listaOdv = []
  diritti.forEach((diritto) => {
    listaOdv.push(diritto.odvDestinatario)
  })
  return listaOdv
}

export function recuperaDirittiSegnalazione(
  segnalazione: Segnalazione,
  utente: User
): DirittiSegnalazione {
  const dirittiSegnalazione = segnalazione.dirittiSegnalazioneOdv
  const dirittoTrovato = dirittiSegnalazione.find((diritto) =>
    isMembroDiGruppo(utente, diritto.odvDestinatario)
  )
  return dirittoTrovato
}

export function puoAprireLavorazioni(
  segnalazione: Segnalazione,
  utente: User
): boolean {
  const dirittiSegnalazione = segnalazione.dirittiSegnalazioneOdv
  const dirittoTrovato =
    dirittiSegnalazione &&
    dirittiSegnalazione.find((diritto) =>
      isMembroDiGruppo(utente, diritto.odvDestinatario) && diritto.aperturaLavorazioni
    )
  return dirittoTrovato && dirittoTrovato.aperturaLavorazioni
}

export function puoEssereAbilitatoAllaConsultazioneDelSegnalante(
  segnalazione: Segnalazione,
  utente: User
): boolean {
  const dirittiSegnalazione = segnalazione.dirittiSegnalazioneOdv
  const dirittoTrovato =
    dirittiSegnalazione &&
    dirittiSegnalazione.find((diritto) =>
      isMembroDiGruppo(utente, diritto.odvDestinatario)
    )
  return (
    (dirittoTrovato && dirittoTrovato.puoVedereSegnalante) ||
    segnalazione.segnalante?.utente.id === utente.id
  )
}

export function puoConsultareNomeSegnalante(
  diritto: DirittiSegnalazione,
  segnalazione: Segnalazione,
  utente: User
): boolean {
  // const dirittiSegnalazione = segnalazione.dirittiSegnalazioneOdv;
  // const dirittoTrovato = dirittiSegnalazione && dirittiSegnalazione.find(diritto => isMembroDiGruppo(utente, diritto.odvDestinatario));
  return (
    (diritto &&
      diritto.puoVedereSegnalante &&
      diritto.richiestaSbloccoVisibilitaSegnalanteAccolta) ||
    segnalazione.segnalante?.utente.id === utente.id
  )
}

export function nonPotrebbeConsultareNomeSegnalante(
  segnalazione: Segnalazione,
  utente: User
): boolean {
  const dirittiSegnalazione = segnalazione.dirittiSegnalazioneOdv
  const dirittoTrovato =
    dirittiSegnalazione &&
    dirittiSegnalazione.find((diritto) =>
      isMembroDiGruppo(utente, diritto.odvDestinatario)
    )
  return (
    (dirittoTrovato &&
      dirittoTrovato.puoVedereSegnalante &&
      !dirittoTrovato.richiestaSbloccoVisibilitaSegnalanteAccolta) ||
    segnalazione.segnalante?.utente.id === utente.id
  )
}

export function puoCondividereNomeSegnalante(
  segnalazione: Segnalazione,
  utente: User,
  lavorazione?: Lavorazione
): boolean {
  let consensiDiOdv = []
  if (lavorazione !== undefined) {
    //  Debbo verificare se l'utente passato è gestore o cogestore della lavorazione ed utilizzare il gruppo odv per
    //  determinare se abbia un consenso autorizzato da parte del segnalante
    const gruppiGestoriLavorazione = lavorazione.dirittiOdv
      .filter((dlav) => dlav.gestore || dlav.coGestore)
      .map((dlav) => dlav.odv)
    //  ora verifico se l'utente passato è membro di uno di tali gruppi
    const membershipUtente = gruppiGestoriLavorazione.filter((gruppo) =>
      isMembroDiGruppo(utente, gruppo)
    )
    if (membershipUtente === undefined || membershipUtente.length === 0) {
      return false
    }

    const consensiSegnalazione = segnalazione.consensi
    consensiDiOdv = consensiSegnalazione?.filter(
      (consenso) =>
        consenso.attivo &&
        consenso.stato === Consenso.STATI.CONCESSO &&
        membershipUtente.find(
          (membership) =>
            consenso.odvRichiedente?.odvDestinatario.id === membership.id
        ) !== undefined
    )
  } else {
    //  debbo verificare se l'utente passato appartiene ad almeno un gruppo gestore della segnalazione che abbia
    //  ricevuto il consenso da parte del segnalante
    const consensiSegnalazione = segnalazione.consensi
    consensiDiOdv = consensiSegnalazione?.filter(
      (consenso) =>
        consenso.attivo &&
        consenso.stato === Consenso.STATI.CONCESSO &&
        utente.odvMembership.find(
          (membership) =>
            consenso.odvRichiedente?.odvDestinatario.id === membership.id
        ) !== undefined
    )
  }
  return consensiDiOdv?.length > 0
}

export function puoConsultareNomeSegnalati(
  segnalazione: Segnalazione,
  utente: User
): boolean {
  const dirittiSegnalazione = segnalazione.dirittiSegnalazioneOdv
  const dirittoTrovato =
    dirittiSegnalazione &&
    dirittiSegnalazione.find(
      (diritto) =>
        isMembroDiGruppo(utente, diritto.odvDestinatario)
    )
  return (
    (dirittoTrovato && dirittoTrovato.puoVedereSegnalati) ||
    segnalazione.segnalante?.utente?.id === utente.id
  )
}

export function puoConsultareNomeSegnalatiAlNettoLavorazione(
  segnalazione: Segnalazione,
  utente: User
): boolean {
  const dirittiSegnalazione = segnalazione.dirittiSegnalazioneOdv
  const dirittoTrovato =
    dirittiSegnalazione &&
    dirittiSegnalazione.find((diritto) =>
      isMembroDiGruppo(utente, diritto.odvDestinatario)
    )
  return (
    (dirittoTrovato && dirittoTrovato.puoVedereSegnalati) ||
    segnalazione.segnalante?.utente?.id === utente.id
  )
}

export function deveAprireLavorazionePerVedereSegnalati(
  segnalazione: Segnalazione,
  utente: User
): boolean {
  return (
    !puoConsultareNomeSegnalati(segnalazione, utente) &&
    puoConsultareNomeSegnalatiAlNettoLavorazione(segnalazione, utente)
  )
}

export function puoChattareConSegnalante(
  gestore: User,
  miasegnalazione: Segnalazione
): boolean {
  const result = gestore.odvMembership.find(
    (membro) =>
      miasegnalazione.dirittiSegnalazioneOdv.find(
        (diritto) =>
          diritto.odvDestinatario.id === membro.id &&
          diritto.comunicazioneConSegnalante
      ) !== undefined
  )
  return result !== undefined
}

export function recuperaMieiDirittiSegnalazione(
  segnalazione: Segnalazione,
  utente: User
): DirittiSegnalazione {
  const dirittiSegnalazione = segnalazione.dirittiSegnalazioneOdv
  const dirittoTrovato = dirittiSegnalazione.find((diritto) =>
    isMembroDiGruppo(utente, diritto.odvDestinatario)
  )
  return dirittoTrovato
}

export function recuperaMieiDirittiLavorazione(
  lavorazione: Lavorazione,
  utente: User
): DirittiLavorazione {
  const dirittiLavorazione = lavorazione.dirittiOdv
  const dirittoTrovato = dirittiLavorazione.find((diritto) =>
    isMembroDiGruppo(utente, diritto.odv)
  )
  return dirittoTrovato
}

export function recuperaMieiDirittiLavorazioneDaGestoreOCogestore(
  lavorazione: Lavorazione,
  utente: User
): DirittiLavorazione {
  const dirittiLavorazione = lavorazione.dirittiOdv
  const dirittoTrovato = dirittiLavorazione.find(
    (diritto) =>
      isMembroDiGruppo(utente, diritto.odv) &&
      (diritto.gestore || diritto.coGestore)
  )
  return dirittoTrovato
}

export function puoConsultareLavorazione(
  lavorazione: Lavorazione,
  utente: User
): boolean {
  const dirittiLavorazione = lavorazione.dirittiOdv
  const result = dirittiLavorazione.some((diritto) =>
    isMembroDiGruppo(utente, diritto.odv)
  )
  return result
}

export function puoModificareLavorazione(
  lavorazione: Lavorazione,
  utente: User
): boolean {
  const dirittiLavorazione = lavorazione.dirittiOdv
  const result = dirittiLavorazione.some(
    (diritto) =>
      isMembroDiGruppo(utente, diritto.odv) &&
      (diritto.gestore || diritto.coGestore)
  )
  return result
}

export function membershipDaGestorePerSegnalazione(
  segnalazione: Segnalazione,
  utente: User
): string {
  let membership = 'Nessuna'
  const dirittiOk = segnalazione.dirittiSegnalazioneOdv.filter((diritto) =>
    utente.odvMembership.find(
      (member) => +member.id === +diritto.odvDestinatario.id
    )
  )
  if (dirittiOk) {
    membership = ''
    dirittiOk.forEach((diritto) => {
      membership += diritto.odvDestinatario.nomeOdv + ' '
    })
  }
  if (membership.trim() === '') {
    console.log('alllllllttttt')
  }
  if (
    segnalazione.segnalante &&
    +segnalazione.segnalante.utente.id === +utente.id
  ) {
    membership += ' (Segnalante)'
  }
  return membership
}

export function isNecessarioConsensoSegnalante(
  segnalazione: Segnalazione,
  moduloConsensiSegnalanteAbilitato: boolean
): boolean {
  if (!moduloConsensiSegnalanteAbilitato) {
    console.log(
      'Il modulo consensi è disabilitato, non è necessario gestire i consensi sull\'identità del segnalante'
    )
    return false
  }
  if (segnalazione.anonima) {
    console.log(
      'La segnalazione è anonima non è necessario gestire i consensi sull\'identità del segnalante'
    )
    return false
  }
  const consenso = segnalazione.consensi?.find(
    (conse) => conse.attivo && conse.stato === Consenso.STATI.CONCESSO
  )

  return consenso === undefined
}

export function possoGestireAutorizzazioneVisibilitaSegnalazione(
  segnalazione: Segnalazione,
  dirittiSegnalazione: DirittiSegnalazione,
  user: User
): boolean {
  const result =
    (hoInoltratoSegnalazione(dirittiSegnalazione, user) ||
      sonoGestoreSceltoDaSegnalante(segnalazione, user)) &&
    !dirittiSegnalazione.sceltoDaSegnalante &&
    !gestoreDestinatarioSegnalante(segnalazione, dirittiSegnalazione)
  return result
}

export function possoRimuovereCondivisioneSegnalazione(
  segnalazione: Segnalazione,
  dirittiSegnalazione: DirittiSegnalazione,
  user: User
): boolean {
  const result =
    (hoInoltratoSegnalazione(dirittiSegnalazione, user) ||
      sonoGestoreSceltoDaSegnalante(segnalazione, user)) &&
    !dirittiSegnalazione.sceltoDaSegnalante
  return result
}

export function possoGestireAutorizzazioneModificaLavorazione(
  segnalazione: Segnalazione,
  dirittiSegnalazione: DirittiSegnalazione,
  lavorazione: Lavorazione,
  user: User,
  modificabilitaLavorazioniDaParteDiTerzi: boolean
): boolean {
  const result =
    (hoInoltratoSegnalazione(dirittiSegnalazione, user) ||
      sonoGestoreSceltoDaSegnalante(segnalazione, user)) &&
    !miaLavorazione(
      lavorazione,
      user
    ) &&
    statoCondivisione(dirittiSegnalazione, lavorazione) &&
    modificabilitaLavorazioniDaParteDiTerzi
  return result
}

export function possoGestireCondivisioneLavorazione(
  dirittiSegnalazione: DirittiSegnalazione,
  lavorazione: Lavorazione,
  user: User
): boolean {
  const result =
    miaLavorazione(lavorazione, user) && !sonoIo(user, dirittiSegnalazione)
  return result
}

export function possoGestireAutorizzazioneComunicazioneConSegnalante(
  segnalazione: Segnalazione,
  dirittiSegnalazione: DirittiSegnalazione,
  user: User
): boolean {
  const result =
    (hoInoltratoSegnalazione(dirittiSegnalazione, user) ||
      sonoGestoreSceltoDaSegnalante(segnalazione, user)) &&
    !dirittiSegnalazione.sceltoDaSegnalante &&
    puoChattareConSegnalante(user, segnalazione)
  return result
}

export function possoGestireAutorizzazioneCreazioneLavorazioni(
  segnalazione: Segnalazione,
  dirittiSegnalazione: DirittiSegnalazione,
  user: User
): boolean {
  const result =
    (hoInoltratoSegnalazione(dirittiSegnalazione, user) ||
      sonoGestoreSceltoDaSegnalante(segnalazione, user)) &&
    !dirittiSegnalazione.sceltoDaSegnalante &&
    puoAprireLavorazioni(segnalazione, user)
  return result
}

export function possoEditareOmissis(
  dirittiSegnalazione: DirittiSegnalazione,
  user: User
): boolean {
  return hoInoltratoSegnalazione(dirittiSegnalazione, user)
}

export function hoInoltratoSegnalazione(
  dirittiSegnalazione: DirittiSegnalazione,
  user: User
): boolean {
  if (
    dirittiSegnalazione?.odvCheHaInoltrato &&
    isMembroDiGruppo(user, dirittiSegnalazione.odvCheHaInoltrato)
  ) {
    return true
  }
  return false
}

export function sonoAbilitatoAllaGestioneIdentitaSegnalante(
  user: User,
  segnalazione: Segnalazione
): boolean {
  return (
    segnalazione.dirittiSegnalazioneOdv?.find(
      (dir) =>
        isMembroDiGruppo(user, dir.odvDestinatario) && dir.puoVedereSegnalante
    ) !== undefined
  )
}

export function sonoGestoreSceltoDaSegnalante(
  segnalazione: Segnalazione,
  user: User
): boolean {
  return (
    segnalazione?.dirittiSegnalazioneOdv?.find(
      (diritti) =>
        diritti.sceltoDaSegnalante &&
        isMembroDiGruppo(user, diritti.odvDestinatario)
    ) !== undefined
  )
}

export function gestoreDestinatarioSegnalante(
  segnalazione: Segnalazione,
  dirittiSegnalazione: DirittiSegnalazione
): boolean {
  return (
    dirittiSegnalazione.odvDestinatario.id ===
    segnalazione.segnalante?.utente.id
  )
}

export function miaLavorazione(
  lavorazione: Lavorazione,
  user: User
): boolean {
  const gestore = gestoreLavorazione(lavorazione)
  if (!gestore) {
    return false
  }
  return isMembroDiGruppo(user, gestore)
}

export function sonoIo(user: User, destinatario: DirittiSegnalazione): boolean {
  if (
    destinatario.odvDestinatario &&
    isMembroDiGruppo(user, destinatario.odvDestinatario)
  ) {
    return true
  }
  return false
}

export function statoCondivisione(
  destinatario: DirittiSegnalazione,
  lavorazione: Lavorazione
): boolean {
  const stato =
    lavorazione &&
    lavorazione.dirittiOdv &&
    lavorazione.dirittiOdv.find(
      (tmpOdv) => +tmpOdv.odv.id === +destinatario.odvDestinatario.id
    ) !== undefined
  return stato
}

export function nomeSegnalante(
  consenso: Consenso,
  custodiaIdentita: boolean
): string {
  //  verifica che l'utente abbia ricevuto come membro del gruppo che ha ricevuto la segnalazione
  //  l'autorizzazione a visualizzare il dato identificativo del segnalante
  const dirittiSegnalazioneOdvRichiedente = consenso.odvRichiedente

  //  se è abilitato il custode deve aver ricevuto la sua autorizzazione
  const verificaCustodia =
    !custodiaIdentita ||
    dirittiSegnalazioneOdvRichiedente?.richiestaSbloccoVisibilitaSegnalanteAccolta

  //  deve inoltra aver aperto una lavorazione

  return verificaCustodia ? consenso.nome?.toString() : '***'
}

export function cognomeSegnalante(
  consenso: Consenso,
  custodiaIdentita: boolean
): string {
  //  verifica che l'utente abbia ricevuto come membro del gruppo che ha ricevuto la segnalazione
  //  l'autorizzazione a visualizzare il dato identificativo del segnalante
  const dirittiSegnalazioneOdvRichiedente = consenso.odvRichiedente

  //  se è abilitato il custode deve aver ricevuto la sua autorizzazione
  const verificaCustodia =
    !custodiaIdentita ||
    dirittiSegnalazioneOdvRichiedente?.richiestaSbloccoVisibilitaSegnalanteAccolta

  //  deve inoltra aver aperto una lavorazione

  return verificaCustodia ? consenso.cognome?.toString() : '***'
}

export function dirittiUtenteSullaSegnalazione(
  utente: User,
  segnalazione: Segnalazione
): DirittiSegnalazione[] {
  return segnalazione.dirittiSegnalazioneOdv?.filter((diritto) =>
    isMembroDiGruppo(utente, diritto.odvDestinatario)
  )
}

export function destinatariVisibili(
  segnalazione: Segnalazione,
  user: User,
  filtro?: number
): Odv[] | DirittiSegnalazione[] {
  if (filtro === 1) {
    if (
      isUtenteMembrodiOdvSceltoDaSegnalante(segnalazione, user)
    ) {
      return segnalazione.dirittiSegnalazioneOdv.filter(
        (dsegna) =>
        !dsegna.sceltoDaSegnalante
      )
    } else {
    //  solo le condivisioni
    return segnalazione.dirittiSegnalazioneOdv?.filter(
      (dsegna) =>
        (isMembroDiGruppo(user, dsegna.odvDestinatario) ||
          isMembroDiGruppo(user, dsegna.odvCheHaInoltrato)) &&
        !dsegna.sceltoDaSegnalante
    )
    }
  } else if (filtro === 2) {
    //  solo i gruppi scelti dal segnalante
    if (
      isUtenteMembrodiOdvSceltoDaSegnalante(segnalazione, user) ||
      isSegnalante(segnalazione, user) ||
      (segnalazione.anonima && +segnalazione.stato === BOZZA_INDEX) ||
      user.custode
    ) {
      return segnalazione.dirittiSegnalazioneOdv?.filter(
        (dsegna) => dsegna.sceltoDaSegnalante
      )
    } else {
      //  restituisci solo i/il gruppo che mi ha condiviso la segnalazione
      const odvCheMiHannoCondivisoLaSegnalazione: Odv[] =
        segnalazione.dirittiSegnalazioneOdv
          ?.filter((dsegna) => isMembroDiGruppo(user, dsegna.odvDestinatario))
          .map((ds) => ds.odvCheHaInoltrato)

      return odvCheMiHannoCondivisoLaSegnalazione
        ? segnalazione.dirittiSegnalazioneOdv?.filter((dsegna) =>
            odvCheMiHannoCondivisoLaSegnalazione.find(
              (odv) => odv && +odv.id === +dsegna.odvDestinatario.id
            )
          )
        : []
    }
  } else {
    //  tutti
    return segnalazione.dirittiSegnalazioneOdv?.filter(
      (dsegna) =>
        isMembroDiGruppo(user, dsegna.odvDestinatario) ||
        isMembroDiGruppo(user, dsegna.odvCheHaInoltrato)
    )
  }
}

export function readOnlyDestinatari(
  segnalazione: Segnalazione,
  segnalantePuoScegliereOdv: boolean
): boolean {
  return (
    (segnalazione?.id !== undefined &&
      segnalazione.stato !== BOZZA_INDEX.toString()) ||
    !segnalantePuoScegliereOdv
  )
}

export function getIdSegnalazione(
  segnalazione: Segnalazione,
  utente: User
): string {
  if (!segnalazione) {
    return undefined
  }
  if (utente?.odv) {
    if (!segnalazione.progressivo) {
      return undefined
    }
    return (
      segnalazione.progressivo +
      ' (...' +
      segnalazione.idPerSegnalante?.substring(
        segnalazione.idPerSegnalante?.length - 4
      ) +
      ')'
    )
  } else {
    return segnalazione.idPerSegnalante
  }
}

export function nuovaSegnalazione(user: User): Segnalazione {
  const segnalazione = new Segnalazione()
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const segnalante = new Segnalante()

  segnalazione.segnalante = segnalante
  segnalazione.segnalante.utente = user
  segnalazione.anonima = false
  segnalazione.stato = '0'

  segnalazione.presaInCarico = false
  segnalazione.documenti = new Array<Documento>()

  return segnalazione
}

export function scadenza(
  segnalazione: Segnalazione,
  configurazione: Configurazione
): Date {
  // calcola la scadenza sommando alla data di invio della segnalazione in numero di giorni pari a quello indicato nella configurazione
  // nel campo limiteGiorniPresaInCaricoSegnalazione
  // se data invio non è valorizzata restituisce undefined
  if (!segnalazione.dataInvio) {
    return undefined
  }
  const dataScadenza = new Date(segnalazione.dataInvio)
  dataScadenza.setDate(
    dataScadenza.getDate() +
      configurazione.limiteGiorniChiusuraIndagineSegnalazione
  )
  return dataScadenza
}

export async function convertiInPdf(
  imageBase64: string,
  tipo: string
): Promise<string> {
  try {
    // Rimuovi il prefisso "data:image/xxx;base64," dall'immagine base64
    const base64Data = imageBase64.replace(/^data:image\/\w+;base64,/, '')

    // Converte la stringa base64 in un ArrayBuffer
    const binaryString = atob(base64Data)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    // Crea un nuovo documento PDF
    const pdfDoc = await PDFDocument.create()

    // Incorpora l'immagine nel documento PDF
    let image
    if (tipo.startsWith('image/png')) {
      image = await pdfDoc.embedPng(bytes)
    } else if (tipo.startsWith('image/jpeg')) {
      image = await pdfDoc.embedJpg(bytes)
    } else {
      throw new Error('Tipo di immagine non supportato')
    }

    // Aggiungi una pagina e posiziona l'immagine
    const page = pdfDoc.addPage()
    const { width, height } = image.scale(1)

    // Ridimensiona la pagina in base alle dimensioni dell'immagine
    page.setSize(width, height)

    // Posiziona l'immagine nella pagina
    page.drawImage(image, {
      x: 0,
      y: 0,
      width: width,
      height: height,
    })

    // Serializza il PDF in un ArrayBuffer
    const pdfBytes = await pdfDoc.save()

    // Converte l'ArrayBuffer in una stringa base64
    // const base64Pdf = btoa(String.fromCharCode(...new Uint8Array(pdfBytes)));
    const base64Pdf = btoa(
      new Uint8Array(pdfBytes).reduce(function (data, byte) {
        return data + String.fromCharCode(byte)
      }, '')
    )

    return base64Pdf
  } catch (error) {
    console.log(error)
  }
}

export function possoInoltrareSegnalazione(segnalazione: Segnalazione, user: User, elencoAziende: Azienda[]) {
  const dirittolavorazione = segnalazione.lavorazioni?.find(
    (lavorazione) =>
      lavorazione.dirittiOdv?.find((diritto) =>
      diritto.odv &&
      isMembroDiGruppo(user, diritto.odv)
      ) !== undefined
  )
  if (!dirittolavorazione) {
    return false
  }
  const result =
    segnalazione.dirittiSegnalazioneOdv?.find(
      (diritto) =>
        diritto.aperturaLavorazioni &&
        (isMembroDiGruppo(
          user,
          diritto.odvDestinatario
        ) ||
          diritto.segnalazioneCondivisibile)
    ) &&
    user.odv &&
    elencoAziende.find(
      (azienda) =>
        gruppiAiQualiPossoInoltrare(user, segnalazione, elencoAziende, azienda).length > 0 &&
        azienda.id === segnalazione.aziendaInteressata?.id
    ) !== undefined
  return result
}

export function gruppiAiQualiPossoInoltrare(user: User, segnalazione: Segnalazione, elencoAziende: Azienda[], azienda: Azienda) {
  if (!azienda) {
    return []
  }
  const dirittoSegnalazione = recuperaDirittiSegnalazione(segnalazione, user)
  // se il diritto segnalazione non è scelto da segnalante non posso inoltrare
  if (!dirittoSegnalazione.segnalazioneCondivisibile && !dirittoSegnalazione.sceltoDaSegnalante) {
    return []
  }
  // ricavo l'entità ricevuta dall'elenco aziende in quanto più completa
  const aziendaCompleta = elencoAziende.find(
    (aziendaElenco) => aziendaElenco.id === azienda.id
  )
  // verifico se la segnalazione è destinata ad aziende specifiche
  const aziendeDestinatarie = [aziendaCompleta]
  // l'azienda passata non è tra quelle destinarie della segnalazione torno un array vuoto
  if (
    aziendeDestinatarie &&
    aziendeDestinatarie.length > 0 &&
    aziendeDestinatarie.find(
      (aziendaDestinataria) =>
        aziendaDestinataria && aziendaDestinataria.id === aziendaCompleta.id
    ) === undefined
  ) {
    return []
  }

  // escludi quelli che hanno già ricevuto la segnalazione
  const result = aziendaCompleta.odvAzienda.filter(
    (odvAzienda: OdvAzienda) =>
      segnalazione.dirittiSegnalazioneOdv?.find(
        (diritto) => +diritto.odvDestinatario.id === +odvAzienda.odv.id
      ) === undefined
  )
  return result
}

export function hoPresoInCaricoSegnalazione(segnalazione: Segnalazione, user: User) {
  const dirittolavorazione = segnalazione?.lavorazioni?.find(
    (lavorazione) =>
      lavorazione.dirittiOdv?.find((diritto) =>
      diritto.odv &&
      isMembroDiGruppo(user, diritto.odv)
      ) !== undefined
  )
  if (!dirittolavorazione) {
    return false
  }
  return true
}
