<div class="desktop-table">
  <mat-table #innerTables mat-table #matSort="matSort" [dataSource]="getDataSource(segnalazione)" matSort
    matSortActive="id" matSortDirection="desc">
    <ng-container matColumnDef="{{innerColumns[i]}}"
      *ngFor="let innerDisplayedColumn of innerDisplayedColumns; let i=index">
      <div *ngIf=" innerDisplayedColumn === 'Azioni'">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <button *ngIf="puoAggiungereRichiesta(segnalazione)" i18n-matTooltip matTooltip="Nuova Richiesta di consenso"
            mat-icon-button class="destra"
            (click)="creaModificaRichiestaConsenso(segnalazione); $event.stopPropagation();">
            <mat-icon class="icone-testata-tabelle" aria-label="nuova richiesta di consenso">add_circle
            </mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let consenso">
          <button *ngIf="puoAnnullareRichiesta(consenso, segnalazione)" i18n-matTooltip matTooltip="Annulla Richiesta"
            mat-icon-button class="destra"
            (click)="annullaRichiestaConsenso(consenso, segnalazione); $event.stopPropagation();">
            <mat-icon class="icone-testata-tabelle" aria-label="invia richiesta di consenso">undo
            </mat-icon>
          </button>
          <button *ngIf="puoEditareRichiesta(consenso, segnalazione)" i18n-matTooltip matTooltip="Modifica Richiesta"
            mat-icon-button class="destra"
            (click)="modificaRichiestaConsenso(consenso, segnalazione); $event.stopPropagation();">
            <mat-icon class="icone-testata-tabelle" aria-label="modifica richiesta di consenso">create
            </mat-icon>
          </button>
          <button *ngIf="puoInviareRichiesta(consenso, segnalazione)" i18n-matTooltip matTooltip="Invia Richiesta"
            mat-icon-button class="destra"
            (click)="openDialogConfermaInvioRichiestaConsenso(consenso, segnalazione); $event.stopPropagation();">
            <mat-icon class="icone-testata-tabelle" aria-label="invia richiesta di consenso">send
            </mat-icon>
          </button>

          <button *ngIf="puoAutorizzareRichiesta(consenso, segnalazione)" i18n-matTooltip
            matTooltip="Autorizza Richiesta" mat-icon-button class="destra"
            (click)="openDialogConfermaAzioneConsenso(true, consenso, segnalazione); $event.stopPropagation();">
            <mat-icon class="icone-testata-tabelle" aria-label="invia richiesta di consenso">thumb_up
            </mat-icon>
          </button>
          <button *ngIf="puoRifiutareRichiesta(consenso, segnalazione)" i18n-matTooltip matTooltip="Rifiuta Richiesta"
            mat-icon-button class="destra"
            (click)="openDialogConfermaAzioneConsenso(false, consenso, segnalazione); $event.stopPropagation();">
            <mat-icon class="icone-testata-tabelle" aria-label="invia richiesta di consenso">thumb_down
            </mat-icon>
          </button>
          <!-- aggiunge un bottone per la consultazione dell'informativa su una dialog -->
          <button *ngIf="puoConsultareInformativa()" i18n-matTooltip matTooltip="Consultazione Informativa"
            mat-icon-button class="destra"
            (click)="openDialogConsultazioneInformativa(consenso); $event.stopPropagation();">
            <mat-icon class="colore-background" aria-label="consultazione informativa">info
            </mat-icon>
          </button>
        </mat-cell>
      </div>

      <div *ngIf="innerDisplayedColumn == 'Richiedente'">
        <mat-cell *matCellDef="let consenso">
          {{consenso.odvRichiedente?.odvDestinatario?.nomeOdv}}
        </mat-cell>
      </div>

      <div *ngIf=" innerDisplayedColumn !== 'azioni' && innerDisplayedColumn !== 'odvRichiedente'">
        <mat-header-cell i18n *matHeaderCellDef mat-sort-header> {{localized(innerDisplayedColumn)}} </mat-header-cell>
      </div>
      <div>
        <mat-cell *matCellDef="let consenso"> {{ getDatoInnerTable(consenso, innerColumns[i]) }} </mat-cell>
      </div>
      <div *ngIf="innerDisplayedColumn != 'Richiedente'">
        <mat-cell *matCellDef="let consenso"> {{ getDatoInnerTable(consenso, innerColumns[i]) }} </mat-cell>
      </div>
    </ng-container>
    <mat-header-row *matHeaderRowDef="innerColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: innerColumns;"></mat-row>
  </mat-table>
</div>

<div class="mobile-list">
  <mat-list *ngFor="let consenso of consensi(segnalazione)">
    <mat-list-item>
      <h3 matLine i18n> Stato: {{ getDatoInnerTable(consenso, 'stato') }} </h3>
      <p matLine i18n> Data Richiesta: {{ getDatoInnerTable(consenso, 'dataRichiesta') }} </p>
      <p matLine i18n> Data Risposta: {{ getDatoInnerTable(consenso, 'dataRisposta') }} </p>
      <p matLine i18n> Richiedente: {{ getDatoInnerTable(consenso, 'richiedente') }} </p>
      <button *ngIf="puoAutorizzareRichiesta(consenso, segnalazione)" i18n-matTooltip matTooltip="Autorizza Richiesta"
        mat-icon-button class="destra"
        (click)="openDialogConfermaAzioneConsenso(true, consenso, segnalazione); $event.stopPropagation();">
        <mat-icon class="icone-testata-tabelle" aria-label="invia richiesta di consenso">thumb_up
        </mat-icon>
      </button>
      <button *ngIf="puoRifiutareRichiesta(consenso, segnalazione)" i18n-matTooltip matTooltip="Rifiuta Richiesta"
        mat-icon-button class="destra"
        (click)="openDialogConfermaAzioneConsenso(false, consenso, segnalazione); $event.stopPropagation();">
        <mat-icon class="icone-testata-tabelle" aria-label="invia richiesta di consenso">thumb_down
        </mat-icon>
      </button>

      <!-- aggiunge un bottone per la consultazione dell'informativa su una dialog -->
      <button *ngIf="puoConsultareInformativa()" i18n-matTooltip matTooltip="Consultazione Informativa" mat-icon-button
        class="destra" (click)="openDialogConsultazioneInformativa(consenso); $event.stopPropagation();">
        <mat-icon class="colore-background" aria-label="consultazione informativa">info
        </mat-icon>
      </button>
      <!-- Metti qui altre informazioni pertinenti -->

      <button *ngIf="puoAggiungereRichiesta(getDataSource(segnalazione))" mat-icon-button
        (click)="creaModificaRichiestaConsenso(getDataSource(segnalazione));">
        <mat-icon aria-label="nuova richiesta di consenso">add_circle</mat-icon>
      </button>
      <!-- Altri bottoni per le azioni come nel tuo layout della tabella -->
    </mat-list-item>
    <mat-divider></mat-divider>
  </mat-list>
</div>