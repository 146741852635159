import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor() { }

  private eventSubject = new Subject<{ type: string; data: any }>();

  eventObservable$ = this.eventSubject.asObservable();

  emitEvent(type: string, data: any) {
    this.eventSubject.next({ type, data });
  }
}
