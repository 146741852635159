import { Component, Inject } from '@angular/core'

import { AuthService } from '../../servizi/auth.service'
import { User } from '../../modello/user'
import { Odv } from '../../modello/odv'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { odvHaRicevutoConsensoDaSegnalante } from '../../utility/helper-segnalazioni'
import { Segnalazione } from '../../modello/segnalazione'
import { ThemeService } from '../../servizi/theme.service'


@Component({
  selector: 'app-diritti-inoltro-dialog',
  templateUrl: 'diritti-inoltro.html',
})
export class DirittiInoltroDialogComponent {
  isLavorazione: boolean
  destinatario: Odv
  segnalazione: Segnalazione
  consultazione = false
  modificaLavorazione = false
  aperturaLavorazioni = false
  chat = false
  nomeSegnalante = false
  nomiSegnalati = false
  segnalazioneAnonima = true
  mancaConsenso = true
  puoChattareConSegnalante = false
  puoConsultareLavorazione = false
  puoModificareLavorazione = false
  puoCreareLavorazioni = false
  puoVedereSegnalante = false
  puoVedereSegnalati = false
  odvCheInoltra: Odv
  // diritti: DirittiSegnalazione[];
  odvs: Odv[]

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any, public dialogRef: MatDialogRef<DirittiInoltroDialogComponent>, public authService: AuthService) {
    this.isLavorazione = data.isLavorazione
    this.destinatario = data.destinatario
    this.segnalazioneAnonima = data.segnalazioneAnonima
    this.mancaConsenso = data.mancaConsenso
    this.puoChattareConSegnalante = data.puoChattareConSegnalante
    this.puoConsultareLavorazione = data.puoConsultareLavorazione
    this.puoCreareLavorazioni = data.puoCreareLavorazioni
    this.puoModificareLavorazione = data.puoModificareLavorazione
    this.puoVedereSegnalante = false
    this.puoVedereSegnalati = data.puoVedereSegnalati
    this.odvs = data.odvs
    this.segnalazione = data.segnalazione
    if (this.odvs.length == 1) {
      this.odvCheInoltra = this.odvs[0]
      this.puoVedereSegnalante = odvHaRicevutoConsensoDaSegnalante(this.odvs[0], this.segnalazione)
    }
  }

  get utente(): User {
    const utente = this.authService.getUser()
    return utente
  }

  chiudiDialog() {
    this.dialogRef.close({
      consultazioneLavorazione: this.consultazione,
      modificaLavorazione: this.modificaLavorazione,
      aperturaLavorazioni: this.aperturaLavorazioni,
      chat: this.chat,
      nomeSegnalante: this.nomeSegnalante,
      nomiSegnalati: this.nomiSegnalati,
      odvCheInoltra: this.odvCheInoltra
    })
  }

  onGruppoSelezionato(event, odv: Odv) {
    this.odvCheInoltra = odv
    if (event.source.selected) {
      this.puoVedereSegnalante = odvHaRicevutoConsensoDaSegnalante(odv, this.segnalazione)
    }
  }

  annulla() {
    this.dialogRef.close(false)
  }

  toggleModificalavorazione(event) {
    if (this.modificaLavorazione) {
      this.consultazione = true
    }
  }

  nomeEntita() {
    return this.isLavorazione ? $localize `Lavorazione` : $localize `Segnalazione`
  }

}
