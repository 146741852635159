import { Component, Inject, OnInit } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Traduzioni } from '../../modello/traduzioni'

@Component({
  selector: 'app-translation-dialog',
  templateUrl: './translation-dialog.component.html',
  styleUrls: ['./translation-dialog.component.scss'],
})
export class TranslationDialogComponent implements OnInit {
  formTranslations: UntypedFormGroup
  languages: string[]
  indiceTraduzione: number

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TranslationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      traduzioni: Traduzioni[];
      filtro: string;
      commaSeparated: boolean;
    },
  ) {
    this.formTranslations = this.formBuilder.group({})
    // ricavo l'hash della traduzione corrispondente al filtro

    if (data.commaSeparated) {
      // trasforma ogni traduzione in un array di oggetti {lingua: 'it', testo: 'testo'} considerando il ritorno a capo come separatore
      let translations = []
      for (const traduzione of this.data.traduzioni) {
        if (traduzione.testo !== null) {
          translations = traduzione.testo?.split('\n')
        }

        // trova l'indice della traduzione corrispondente al filtro
        const index = translations.findIndex((t) =>
          t.startsWith(this.data.filtro),
        )
        // se l'indice è valido, valorizzo una variabile con il suo valore ed esco dal ciclo
        if (index > -1) {
          this.indiceTraduzione = index
        }
      }
      for (const traduzione of this.data.traduzioni) {
        let tempVal = ''
        if (traduzione.testo !== null) {
          tempVal = traduzione.testo.trim().split('\n')[this.indiceTraduzione]
        }

        this.formTranslations.addControl(
          traduzione.lingua.trim(),
          this.formBuilder.control(tempVal),
        )
      }
    } else {
      const filtered = this.data.traduzioni.filter(
        (t) => t.testo === this.data.filtro,
      )
      const hash = filtered ? filtered[0].hash : ''
      if (hash === '') {
        // stampa un messaggio d'errore e chiudi la dialog
        this.dialogRef.close()
      }
      // modifico data.traduzioni in modo che contenga solo le traduzioni che hanno lo stesso hash
      this.data.traduzioni = this.data.traduzioni.filter((t) => t.hash === hash)
      for (const traduzione of this.data.traduzioni) {
        if (
          traduzione.lingua !== '' &&
          traduzione.testo !== '' &&
          traduzione.hash === hash
        ) {
          this.formTranslations.addControl(
            traduzione.lingua.trim(),
            this.formBuilder.control(traduzione.testo),
          )
        }
      }
    }
  }
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {

  }

  onCancelClick(): void {
    this.dialogRef.close()
  }

  onSaveClick(): void {
    if (this.data.commaSeparated) {
      this.saveCommaSeparated()
    } else {
      this.saveNonCommaSeparated()
    }
  }

  saveNonCommaSeparated(): void {
    // recupera i dati dal form
    const translations = this.formTranslations.value
    // recupera le traduzioni ricevute e le modifica sulla base di quelle presenti nel form con match stessa lingua
    const returnObject = this.data.traduzioni.map((t) => {
      t.testo = translations[t.lingua]
      return t
    })
    this.dialogRef.close(returnObject)
  }

  saveCommaSeparated(): void {
    if (this.formTranslations.valid) {
      // recupero l'array di oggetti Traduzioni dal form
      const translations = this.formTranslations.value
      let returnObject = []
      let index = 0
      // ciclo su tutte le traduzioni presenti nel form e costruisco un array di oggetti Traduzioni
      for (const key in translations) {
        if (Object.prototype.hasOwnProperty.call(translations, key)) {
          // considero this.data.traduzioni e sostituisco il testo alla posizione this.indiceTraduzione con quello preso dal form
          // se indiceTraduzione è maggiore della lunghezza dell'array, aggiungo il testo alla fine
          if (
            this.indiceTraduzione >
            this.data.traduzioni[index].testo?.split('\n').length - 1
          ) {
            translations[key] = this.data.traduzioni.map((t) =>
              t.lingua === key ? t.testo + '\n' + translations[key] : t.testo,
            )[index]
          } else {
            translations[key] = this.data.traduzioni.map((t) =>
              t.lingua === key && t.testo !== null
                ? t.testo
                    .split('\n')
                    .map((testo, i) =>
                      i === this.indiceTraduzione ? translations[key] : testo,
                    )
                    .join('\n')
                : t.testo,
            )[index]
          }
          index++
          const translation: Traduzioni = new Traduzioni()
          translation.lingua = key
          translation.testo = translations[key]
          returnObject.push(translation)
        }
      }
      // mi assicuro che tutti i testi delle traduzioni contengano lo stesso numero di ritorni a capo
      const max = Math.max(
        ...returnObject.map((t) => (t.testo ? t.testo.split('\n').length : 0)),
      )
      returnObject = returnObject.map((t) => {
        const temp = t.testo?.split('\n')
        while (temp && temp.length < max) {
          temp.push('')
        }
        t.testo = temp.join('\n')
        return t
      })

      this.dialogRef.close(returnObject)
    }
  }
}
