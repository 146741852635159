<div class="flex-row" *ngIf="segnalazione">
  <mat-form-field color="accent" >
    <input matInput class="pointer" type="text">
    <mat-checkbox #checkPianificazioneEliminazione
    [ngModel]="segnalazione.eliminareSegnalazione"
    i18n (change)="checkChanged($event)"
    class="label-segnalazione testo-label-scalabile">
      Pianifica Eliminazione della segnalazione e
      di tutte le informazioni correlate
    </mat-checkbox>
  </mat-form-field>
  <div style="display:block;">
  <mat-form-field style="top:19px"  color="accent" *ngIf="checkPianificazioneEliminazione.checked">
    <mat-label class="label-segnalazione testo-label-scalabile" i18n>Data decisione eliminazione</mat-label>
    <input matInput disabled class="contenuto-segnalazione" [ngModel]="segnalazione.dataDecisioneEliminazione | data">
  </mat-form-field>
  </div>

</div>
