<div *ngIf="mediaUrl && isVideo" class="flex-column">

    <video id="myVideo" width="100%" controls controlsList="nodownload" #videoplayer>
      <source [src]="mediaUrl" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>

  <div *ngIf="mediaUrl && isAudio" class="flex-column">

    <audio id="myAudio" width="100%" controls controlsList="nodownload" style="margin:auto" #audioplayer>
      <source [src]="mediaUrl" type="audio/mpeg">
      Your browser does not support the audio tag.
    </audio>
  </div>

  <div *ngIf="loadError" class="error-message" style="width: 640px; align-self: center;" i18n>Contenuto non disponibile.</div>

  <script>
    document.getElementById('myVideo').addEventListener('contextmenu', (e) => {
        e.preventDefault();
    });
    document.getElementById('myAudio').addEventListener('contextmenu', (e) => {
        e.preventDefault();
    });
</script>
