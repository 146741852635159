import { OverlayContainer } from '@angular/cdk/overlay'
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatNativeDateModule, MatOptionModule, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatBadgeModule } from '@angular/material/badge'
import { MatCardModule } from '@angular/material/card'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatRadioModule } from '@angular/material/radio'
import {MatSliderModule} from '@angular/material/slider'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatDividerModule } from '@angular/material/divider'
import { MatListModule } from '@angular/material/list'
import { BrowserModule } from '@angular/platform-browser'
import { AngularEditorModule } from '@kolkov/angular-editor'
import { ChartsModule } from 'ng2-charts'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BannedPageComponent } from './banned-page/banned-page.component'
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard'
import { ChatManagerComponent } from './chat-manager/chat-manager.component'
import { ChatComponent } from './chat-manager/chat/chat.component'
import { NuovaChatDialogComponent } from './chat-manager/nuova-chat.dialog.component'
import { SelezionaGruppoOdvDialogComponent } from './chat-manager/seleziona-gruppo-odv.dialog.component'
import { FiltroGestoreComponent } from './componenti/filtro-gestore/filtro-gestore.component'
import { FiltroStatiComponent } from './componenti/filtro-stati/filtro-stati.component'
import { IconaInfoComponent } from './componenti/icona-info/icona-info.component'
import { InputFileComponent } from './componenti/input-file/input-file.component'
import { ScrollTracker } from './componenti/scroll-tracker/scroll-tracker-directive'
import { ToolbarLandingComponent } from './componenti/toolbar-landing/toolbar-landing.component'
import { ConfigurazioneComponent } from './configurazione/configurazione.component'
import { DettaglioSegnalazioneComponent } from './dettaglio-segnalazione/dettaglio-segnalazione.component'
import { ConfermaInvioSegnalazioneDialogComponent } from './dettaglio-segnalazione/dialogs/conferma-invio-segnalazione'
import { ConfermaSbloccoDatiDialogComponent } from './dettaglio-segnalazione/dialogs/conferma-sblocco-dati'
import { RichiestaSbloccoSegnalanteDialogComponent } from './dettaglio-segnalazione/dialogs/richiesta-sblocco-segnalante.dialog.component'
import { InformativaComponent } from './dettaglio-segnalazione/informativa/informativa.component'
import { RispostaCustodeDialogComponent } from './dettaglio-segnalazione/risposta-custode/risposta-custode.dialog.component'
import { AutocompleteOffDirective } from './direttive/autocomplete-off.directive'
import { ConfermaEliminazioneDocumentoDialogComponent } from './elenco-allegati/conferma-eliminazione-documento.dialog.component'
import { ElencoAllegatiComponent } from './elenco-allegati/elenco-allegati.component'
import { ConfermaEliminazioneSegnalazioneDialogComponent } from
'./elenco-segnalazioni/dialogs/conferma-eliminazione-segnalazione.dialog.component'
import { ConfermaDecisioneDialogComponent } from './elenco-segnalazioni/consensi-segnalante/conferma-decisione.dialog.component'
import { RichiestaConsensoSegnalanteDialogComponent } from
'./elenco-segnalazioni/consensi-segnalante/dialog-richiesta-consenso-segnalante-component'
import { HomeComponent } from './elenco-segnalazioni/elenco-segnalazioni.component'
import { InfoUtenteDialogComponent } from './info-utente/info-utente.dialog.component'
import { SessioneScadutaDialogComponent } from './elenco-segnalazioni/sessione-scaduta.dialog.component'
import { ConfermaEliminazioneAziendaDialogComponent } from './gestione-aziende/conferma-eliminazione-azienda.dialog.component'
import { GestioneAziendeComponent } from './gestione-aziende/gestione-aziende.component'
import { InformativaDialogComponent } from './gestione-aziende/informativa.dialog.component'

import { ConfermaEliminazioneUtenteDialogComponent } from './gestione-utenti/conferma-eliminazione-utente.dialog.component'
import { ConfermaRiattivazioneUtenteDialogComponent } from './gestione-utenti/conferma-riattivazione-utente.dialog.component'
import { GestioneUtentiComponent } from './gestione-utenti/gestione-utenti.component'
import { ModificaUtenteDialogComponent } from './gestione-utenti/modifica-utente.dialog.component'
import { CreaModificaInformativaDialogComponent } from './informative/dialog-crea-modifica-informativa-component'
import { InformativeComponent } from './informative/informative.component'
import { LandingPageComponent } from './landing-page/landing-page.component'
import { CookiesPolicyComponent } from './login/cookies-policy/cookies-policy.component'
import { FaqComponent } from './login/faq/faq.component'

import { LoginComponent, LoginFallitaDialogComponent } from './login/login.component'
import { NormativaComponent } from './login/normativa/normativa.component'
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component'

import { TerminiServizioComponent } from './login/termini-servizio/termini-servizio.component'
import { ConfermaCreazioneAzioneFollowUpDialogComponent } from
'./pannello-lavorazioni/dialogs/conferma-creazione-azione-follow-up.dialog.component'
import { ConfermaCreazioneSorgenteDialogComponent } from './pannello-lavorazioni/dialogs/conferma-creazione-sorgente.dialog.component'
import { ConfermaCreazioneTemaDialogComponent } from './pannello-lavorazioni/dialogs/conferma-creazione-tema.dialog.component'
import { NoteNonAmmissibilitaObbligatorieDialogComponent } from
'./pannello-lavorazioni/dialogs/note-non-ammissibilita-obbligatorie.dialog.component.1'
import { ConfermaModificaVisibilitaSegnalazioneInoltrataDialogComponent } from
'./pannello-lavorazioni/pannello-lavorazione-segnalazione/conferma-modifica-visibilita-segnalazione-inoltrata.dialog.component'
import { ConfermaScondivisioneSegnalazioneDialogComponent } from
'./pannello-lavorazioni/pannello-lavorazione-segnalazione/conferma-scondivisione-segnalazione.dialog.component'
import { ConfermaVisibilitaIdentitaSegnalanteDialogComponent } from
'./pannello-lavorazioni/pannello-lavorazione-segnalazione/conferma-visibilita-identita-segnalante.dialog.component'
import { DirittiInoltroDialogComponent } from './pannello-lavorazioni/pannello-lavorazione-segnalazione/diritti-inoltro.dialog.component'
import { Base64DecodePipe } from './pipe/base64-decode.pipe'
import { ByteFormatPipe } from './pipe/byte-format.pipe'
import { DataOraPipe } from './pipe/data-ora.pipe'
import { DataSourcePipe } from './pipe/data-source.pipe'
import { DataPipe } from './pipe/data.pipe'
import { DummyPipe } from './pipe/dummy'
import { GenerePipe } from './pipe/genere.pipe'
import { OraPipe } from './pipe/ora.pipe'
import { ArraySortPipe } from './pipe/order-by.pipe'
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe'
import { TestoInizialePipe } from './pipe/testo-iniziale.pipe'
import { AutorizzazioneSbloccoDialogComponent } from './richieste-sblocco/dialog-autorizzazione-component'
import { MotivazioneDialogComponent } from './richieste-sblocco/motivazione.dialog.component'
import { RichiesteSbloccoComponent } from './richieste-sblocco/richieste-sblocco.component'
import { AuthService } from './servizi/auth.service'
import { SegnalazioneService } from './servizi/segnalazione.service'
import { TokenInterceptor } from './servizi/token-interceptor'
import { WINDOW_PROVIDERS } from './servizi/window-service'
import { SessionData } from './sessione/dati-sessione'
import { ElencoSegnalazioniDialogComponent } from './statistiche/elenco-segnalazioni-dialog/elenco-segnalazioni.dialog.component'
import { StatisticheComponent } from './statistiche/statistiche.component'
import { ToolbarComponent } from './toolbar/toolbar.component'
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { PannelloLavorazioneSegnalazioneComponent } from './pannello-lavorazioni/pannello-lavorazione-segnalazione.component'
import { CondivisioneSegnalazioneComponent } from './componenti/condivisione-segnalazione/condivisione-segnalazione.component'
import { ElencoConsensiSegnalanteComponent } from './componenti/elenco-consensi-segnalante/elenco-consensi-segnalante.component'

import { TestoFinalePipe } from './pipe/testo-finale.pipe'
import { VisualizzatorePdfDialogComponent } from './componenti/visualizzatore-pdf/visualizzatore-pdf.component'
import { FileUploaderComponent } from './componenti/file-uploader/file-uploader.component'
import { XspEditorComponent } from './componenti/xsp-editor/xsp-editor.component'
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'
import { GestioneGruppiComponent } from './gestione-gruppi/gestione-gruppi.component'
import { ModificaGruppoDialogComponent } from './gestione-gruppi/modifica-gruppo.dialog.component'
import { RegistrazioneComponent } from './login/registrazione/registrazione.component'
import { RecuperoPasswordComponent } from './login/recupero-password/recupero-password.component'
import { getItalianPaginatorIntl } from './it-paginator-intl'
import { AngularSplitModule } from 'angular-split'
import { VerificaEmailComponent } from './login/verifica-email/verifica-email.component'
import { DettaglioLavorazioneComponent } from
'./elenco-lavorazioni/dettaglio-lavorazione/dettaglio-lavorazione/dettaglio-lavorazione.component'
import { ElencoLavorazioniComponent } from './elenco-lavorazioni/elenco-lavorazioni/elenco-lavorazioni.component'
import { DialogComponent } from './componenti/dialog/dialog.component'
import { TranslationDialogComponent } from './componenti/translation-dialog/translation-dialog.component'
import { TabellaTraduzioniComponent } from './componenti/tabella-traduzioni/tabella-traduzioni.component'
import { PitchModificationComponent } from './componenti/pitch-modification/pitch-modification.component'
import { LanguageSelectorComponent } from './componenti/language-selector/language-selector.component'
import { LogOperativoListComponent } from './log-operativo-list/log-operativo-list.component'
import { ChiediConsensoAudioDialogComponent } from './dettaglio-segnalazione/dialogs/chiedi-consenso-audio'
import { VideoPlayerComponent } from './componenti/video-player/video-player.component'
import { RecorderComponent } from './componenti/recorder/recorder.component'
import { AllegatiDialogComponent } from './componenti/allegati-dialog/allegati-dialog.component'
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer'
import { EmailComponent } from './componenti/email/email.component'
import { ColorPickerComponent } from './componenti/color-picker/color-picker.component'
import { MaskEncryptedPipe } from './pipe/mask-encrypted.pipe'
import { HeartbeatService } from './servizi/hearthbeat.service'
import { DialogSelezioneGruppoComponent } from './componenti/dialog/dialog-selezione-gruppo/dialog-selezione-gruppo.component'
import { EditorLinguaSelectorComponent } from './componenti/editor-lingua-selector/editor-lingua-selector.component'
import { HighlightPipe } from './pipe/highlight.pipe'
import { PianificazioneEliminazioneSegnalazioneComponent } from './componenti/pianificazione-eliminazione-segnalazione/pianificazione-eliminazione-segnalazione.component'
import { AttivazioneMfaComponent } from './login/attivazione-mfa/attivazione-mfa.component'
import { IdpComponent } from './componenti/idp/idp.component'
import { IdpEditDialogComponent } from './componenti/idp/idp-edit-dialog/idp-edit-dialog.component'
import { NewClientDialogComponent } from './componenti/new-client-dialog/new-client-dialog.component'

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DettaglioSegnalazioneComponent,
    LoginFallitaDialogComponent,
    SessioneScadutaDialogComponent,
    ElencoAllegatiComponent,
    InputFileComponent,
    ByteFormatPipe,
    ConfermaEliminazioneSegnalazioneDialogComponent,
    ConfermaEliminazioneDocumentoDialogComponent,
    ConfermaEliminazioneUtenteDialogComponent,
    ConfermaEliminazioneDocumentoDialogComponent,
    ConfermaEliminazioneAziendaDialogComponent,
    ConfermaCreazioneSorgenteDialogComponent,
    NoteNonAmmissibilitaObbligatorieDialogComponent,
    GestioneUtentiComponent,
    InfoUtenteDialogComponent,
    InformativaDialogComponent,
    ConfermaCreazioneTemaDialogComponent,
    ConfermaCreazioneAzioneFollowUpDialogComponent,
    ConfermaSbloccoDatiDialogComponent,
    // ChatSegnalazioneComponent,
    ScrollTracker,
    OraPipe,
    ArraySortPipe,
    DummyPipe,
    FiltroStatiComponent,
    ToolbarComponent,
    GestioneAziendeComponent,
    StatisticheComponent,
    ElencoSegnalazioniDialogComponent,
    ChatManagerComponent,
    ChatComponent,
    FiltroGestoreComponent,
    PannelloLavorazioneSegnalazioneComponent,
    DirittiInoltroDialogComponent,
    ConfigurazioneComponent,
    IconaInfoComponent, RichiestaConsensoSegnalanteDialogComponent,
    BannedPageComponent,
    NuovaChatDialogComponent, SelezionaGruppoOdvDialogComponent, LandingPageComponent,
    ToolbarLandingComponent, PrivacyPolicyComponent, TerminiServizioComponent,
    CookiesPolicyComponent, NormativaComponent, FaqComponent, InformativaComponent,
    ConfermaRiattivazioneUtenteDialogComponent, ConfermaInvioSegnalazioneDialogComponent, DataPipe,
    RichiestaSbloccoSegnalanteDialogComponent, CreaModificaInformativaDialogComponent,
    RichiesteSbloccoComponent, AutorizzazioneSbloccoDialogComponent, InformativeComponent, Base64DecodePipe,
    SanitizeHtmlPipe, DataSourcePipe, DataOraPipe, TestoInizialePipe,
    MotivazioneDialogComponent, ConfermaDecisioneDialogComponent, GenerePipe, AutocompleteOffDirective, ModificaUtenteDialogComponent,
    ConfermaVisibilitaIdentitaSegnalanteDialogComponent, ConfermaModificaVisibilitaSegnalazioneInoltrataDialogComponent,
    ConfermaScondivisioneSegnalazioneDialogComponent, RispostaCustodeDialogComponent,
    CondivisioneSegnalazioneComponent, ElencoConsensiSegnalanteComponent,
    TestoFinalePipe, VisualizzatorePdfDialogComponent, FileUploaderComponent, XspEditorComponent,
    GestioneGruppiComponent, ModificaGruppoDialogComponent,
    RegistrazioneComponent, RecuperoPasswordComponent, VerificaEmailComponent, DettaglioLavorazioneComponent,
    ElencoLavorazioniComponent, DialogComponent, TranslationDialogComponent, TabellaTraduzioniComponent,
    PitchModificationComponent, LanguageSelectorComponent, LogOperativoListComponent, ChiediConsensoAudioDialogComponent,
    VideoPlayerComponent, RecorderComponent, AllegatiDialogComponent, EmailComponent, ColorPickerComponent,
    MaskEncryptedPipe, DialogSelezioneGruppoComponent, EditorLinguaSelectorComponent, HighlightPipe,
    PianificazioneEliminazioneSegnalazioneComponent, AttivazioneMfaComponent, IdpComponent, IdpEditDialogComponent, NewClientDialogComponent
  ],
  imports: [
    CommonModule, NgxDaterangepickerMd.forRoot(),
    BrowserModule, ChartsModule,
    AppRoutingModule,
    BrowserAnimationsModule, MatBadgeModule,
    MatCheckboxModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatTableModule,
    MatCardModule, ReactiveFormsModule, MatIconModule, MatGridListModule, MatDialogModule, MatToolbarModule,
    MatSortModule, MatPaginatorModule, MatExpansionModule, MatFormFieldModule, FormsModule, MatNativeDateModule,
    HttpClientModule, MatSnackBarModule, MatSidenavModule, MatTooltipModule, MatProgressSpinnerModule,
    MatChipsModule, MatMenuModule, MatOptionModule, MatSelectModule, MatButtonToggleModule,
    MatRadioModule, MatAutocompleteModule, AngularEditorModule, DragDropModule,
    MatTabsModule, MatDividerModule, MatListModule,
    NgxExtendedPdfViewerModule, AngularSplitModule, MatSliderModule
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
  { provide: MatPaginatorIntl, useValue: getItalianPaginatorIntl() },
  { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
  { provide: LocationStrategy, useClass: HashLocationStrategy },
    HttpClientModule, AuthService, WINDOW_PROVIDERS, CanDeactivateGuard,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }, SessionData, SegnalazioneService
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
  constructor(overlayContainer: OverlayContainer, private heartbeatService: HeartbeatService) {
    const theme = 'theme'
    overlayContainer.getContainerElement().classList.add(theme)
    pdfDefaultOptions.assetsFolder = '../assets'
  }
}
