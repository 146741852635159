<span class="bottone-flottante-alto-sinistra no-print">
  <button mat-mini-fab (click)='backToMainLogin();$event.stopPropagation();' i18n-matTooltip
    matTooltip="Torna Indietro">
    <mat-icon class="icona-fab" aria-label="Torna indietro">keyboard_arrow_left</mat-icon>
  </button>
</span>
<div class="flex-column">
  <mat-toolbar class="login-toolbar">
    <mat-toolbar-row *ngIf="singleRow" class="height-94">
      <div style="margin-left: 44px;">
        <img src="{{getLogoCliente()}}" class="logo-cliente">
      </div>
      <div class="testo-x-large" style="text-align: center;width: 65%;" i18n>Accesso Gestore</div>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="!singleRow" class="height-94">
      <div style="text-align: center;width: 100%;">
        <img src="{{getLogoCliente()}}" class="logo-cliente">
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="!singleRow">
      <div class="testo-x-large" *ngIf="!singleRow" style="text-align: center;width: 100%;" i18n>Accesso Gestore</div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="alternate-theme">
    <form [formGroup]="loginForm" *ngIf="loginForm" style="height:100%" (keydown)="keyDownFunction($event); $event.stopPropagation();"
      >
      <mat-card style="height: calc(100vh - 100px);">

        <mat-card-title>

        </mat-card-title>
        <mat-card-content>
          <div class="flex-column">

            <!-- Campo Nickname -->
            <div *ngIf="!necessarioMFA" class="flex">
              <div style="flex: 0 0 25%"></div>
              <div class="flex-column">
                <mat-form-field color="accent">
                  <input type="text" autocomplete="username" #inputIdUtente matInput class="form-control"
                    formControlName="userId" i18n-placeholder placeholder="Nickname" required>
                </mat-form-field>
                <div
                  *ngIf="loginForm.controls.userId?.invalid && (loginForm.controls.userId?.dirty || loginForm.controls.userId?.touched)"
                  class="alert alert-danger posizione-errori-utente errori-form">

                  <mat-error *ngIf="loginForm.controls.userId?.errors.required" i18n>
                    Il nickname è obbligatorio.
                  </mat-error>
                </div>
              </div>
              <div style="flex: 0 0 25%"></div>
            </div>

            <!-- Campo password -->
            <div class="flex" *ngIf="!necessarioMFA && autenticazioneLocale">
              <div style="flex: 0 0 25%"></div>
              <div class="flex-column">

                <mat-form-field color="accent">
                  <input type="{{textOrPassword}}" matInput class="form-control" formControlName="password"
                    autocomplete="new-password" [placeholder]="'Password'" required>
                </mat-form-field>
                <div *ngIf="password.invalid && (password.dirty || password.touched)"
                  class="alert alert-danger posizione-errori-password errori-form">

                  <mat-error *ngIf="password.errors.required" i18n>
                    La Password è obbligatoria
                  </mat-error>
                  <mat-error *ngIf="password.errors && !password.errors.required"
                    i18n>
                    Password non corretta
                  </mat-error>
                </div>
              </div>
              <div style="flex: 0 0 25%"></div>
            </div>

            <!-- Campo MFA -->
            <div class="flex" *ngIf="necessarioMFA">
              <div style="flex: 0 0 25%"></div>
              <div class="flex-column">

                <mat-form-field color="accent">
                  <input type="text" #otp matInput class="form-control" formControlName="token"
                    autocomplete="new-password" i18n-placeholder placeholder="Codice di Autenticazione a Doppio Fattore"
                    pattern="[0-9]*" inputmode="numeric">
                </mat-form-field>

              </div>
              <div style="flex: 0 0 25%"></div>
            </div>

            <!--  FOOTER DEL MODULO -->
            <div class="flex-row centered">
              <div style="flex: 0 0 25%"></div>
              <div class="flex-start-13" *ngIf="autenticazioneLocale">
                <a (click)="apriRecuperoPassword()" class="pointer" i18n >Recupera Password</a>
              </div>
              <div class="flex-start-13" *ngIf="!autenticazioneLocale && autenticazioneSecureAbilitata">
                <a (click)="doForzaAutenticazioneLocale()" class="pointer" i18n >Accedi con le credenziali Secure Blowing</a>
              </div>
              <div class="flex-start-13" *ngIf="autenticazioneLocale && ssoAbilitata">
                <a (click)="doForzaAutenticazioneSSO()" class="pointer" i18n >Accedi tramite Single Sign On</a>
              </div>
              <div class="flex-start-13" *ngIf="mfaAttivo && loginForm.valid">
                <button mat-button (click)="attivaMFA()" class="pointer" i18n [disabled]="!loginForm.valid">Reimposta
                  l'Autenticazione a Doppio Fattore</button>
              </div>
              <div style="flex: 1 1 auto"></div>
              <div [style]="modalitaPinUnico ? 'flex: 1 1 12%':'flex: 1 1 37%'">
              </div>
              <div style="flex: 0 0 25%"></div>
            </div>

            <div class="flex-column flex-gap-30">
              <div class="flex">
                <div style="flex: 0 0 25%"></div>
                <div class="flex-fill centered">
                  <button i18n class="bottone-bordato bottone-login width-20" mat-button
                    [disabled]="!loginForm.valid" *ngIf="!tokenSessione && captcha"  #loginButton
                    (click)="onClickLogin($event)"
                    i18n type="submit">Login
                  </button>
                  <button i18n class="bottone-bordato bottone-login width-20" mat-button
                  [disabled]="!loginForm.valid" *ngIf="!tokenSessione && !captcha" #loginButton
                  (click)="onClickLogin($event)"
                  i18n type="submit">Login
                </button>
                  <button i18n class="bottone-bordato bottone-login width-20" mat-button [disabled]="!loginForm.valid"
                    *ngIf="tokenSessione" #verifyOtpButton (click)="verifyOtp()"  i18n
                    type="submit">Verifica OTP
                  </button>
                </div>

                <div style="flex: 0 0 25%"></div>
              </div>
            </div>
            <div *ngIf="imageSrc" style="margin:auto; font-weight: bolder;">
              <h2 i18n>
                A breve riceverai un messaggio di posta elettronica contenente il link per l'abilitazione del doppio fattore di autenticazione
              </h2>
            </div>
            <div *ngIf="utenteConNuovoPin" class="flex-row centered">
              <div style="flex: 0 0 25%"></div>
              <div class="flex-column">
                <H2 *ngIf="modalitaPinUnico && !utenteConNuovoPin.odv" i18n>Ecco il tuo nuovo PIN per l'accesso al
                  sistema di
                  segnalazione</H2>
                <H2 *ngIf="utenteConNuovoPin.odv || !modalitaPinUnico" i18n>Ecco la tua nuova Password per l'accesso al
                  sistema
                  di segnalazione</H2>
                <BR>
                <div class="flex-row centered">
                  <H1>{{utenteConNuovoPin.pinCode.pincode}}
                    <button mat-icon-button (click)="copiaPin()" i18n-matTooltip matTooltip="Copia negli appunti">
                      <mat-icon class="mat-18" color="accent">file_copy</mat-icon>
                    </button>
                  </H1>
                </div>

                <BR>
                <H2 i18n>Conservalo con cura e non rivelarlo a nessuno, ti verrà richiesto ad ogni accesso</H2>
                <H2 i18n>Qualora lo dimenticassi potrai richiederne uno nuovo utilizzando la funzionalità di recupero
                </H2>
                <div *ngIf="registrazioneConEmail">
                  <H2 i18n>Abbiamo inviato un'email all'indirizzo di posta che ci hai fornito, al suo
                    interno troverai
                    un link che ti permetterà l'attivazione dei servizi di notifica e recupero {{ modalitaPinUnico &&
                    !utenteConNuovoPin?.odv ? 'PIN' : 'Password'}}</H2>
                </div>
              </div>
              <div style="flex: 0 0 25%"></div>
            </div>
          </div>

        </mat-card-content>
        <mat-card-footer>


        </mat-card-footer>
      </mat-card>
    </form>
  </div>
  <div class="footer centro"> {{versione}} </div>
</div>
