

import { UntypedFormControl, AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'

import { isValidEmailAziendale } from '../utility/utility'

export class CustomValidators {


  static validateIdUtente(control: UntypedFormControl) {

    // console.log('valida Id Utente');
    let errori: { [key: string]: any }

    // tslint:disable-next-line:max-line-length
    const regexpEmail = new RegExp('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$')
    const regexpNumeroTelefonico = new RegExp('^[0-9]*$')

    if (!regexpEmail.test(control.value) && !regexpNumeroTelefonico.test(control.value) ||
      (regexpNumeroTelefonico.test(control.value) && (control.value.toString().length < 9 || control.value.toString().length > 10))) {
      errori = ({ 'neEmailNeTelefono': { value: control.value } })
    }
    if (!regexpEmail.test(control.value)) {
      errori = ({ 'emailNonValida': { value: control.value } })
    }
    if (control.value == null || control.value.toString().length === 0) {
      errori = ({ 'lunghezzaMinima': { value: control.value } })
    }
    return errori
  }

  static validaEmail(control: UntypedFormControl) {

    // console.log('valida Id Utente');
    let errori: { [key: string]: any }

    // tslint:disable-next-line:max-line-length
    const regexpEmail = new RegExp('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')

    if (control.value && !regexpEmail.test(control.value)) {
      errori = ({ 'emailNonValida': { value: control.value } })
    }
    return errori
  }

  static validaCitta(control: UntypedFormControl) {

    // console.log('valida Id Utente');
    let errori: { [key: string]: any }

    // tslint:disable-next-line:max-line-length


    if (control.value && control.value.nome === '') {
      errori = ({ 'cittaNonValida': { value: control.value } })
    }
    return errori
  }

  static validaCodiceFiscale(control: UntypedFormControl) {
    let validi, i, s, set1, set2, setpari, setdisp
    let errori: { [key: string]: any }
    let cf = control.value
    if (cf === '' || cf === null || cf === undefined) {
 return null
}
    cf = cf.toUpperCase()
    if (cf.length !== 16) {
      return { 'codiceFisccaleErrato': { value: control.value } }
    }
    validi = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    for (i = 0; i < 16; i++) {
      if (validi.indexOf(cf.charAt(i)) === -1) {
        return { 'codiceFisccaleErrato': { value: control.value } }
      }
    }
    set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ'
    setpari = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    setdisp = 'BAKPLCQDREVOSFTGUHMINJWZYX'
    s = 0
    for (i = 1; i <= 13; i += 2) {
      s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))))
    }
    for (i = 0; i <= 14; i += 2) {
      s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))))
    }
    if (s % 26 !== cf.charCodeAt(15) - 'A'.charCodeAt(0)) {
      return { 'codiceFisccaleErrato': { value: control.value } }
    }
    return errori
  }

  static validateEmailAziendale(control: UntypedFormControl) {

    // console.log('valida Id Utente');
    let errori: { [key: string]: any }
    return isValidEmailAziendale(control.value) ? { 'dominioAziendaleErrato': { value: control.value } } : errori
  }


  static validateEmailPersonale(dominiAziendali: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let errori: { [key: string]: any }

      const dominio = control.value ? control.value.split('@')[1] : ''
      if (dominiAziendali && dominiAziendali.split(',').filter(ema => ema === dominio).length > 0) {
        errori = ({ 'dominioNonPermesso': { value: control.value } })
      }

      return errori
    }
  }

  static matchingText(textKey: string, confirmTextKey: string, outcomeRefKey: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const text = group.controls[textKey].value
      const confirmText = group.controls[confirmTextKey].value

      if ((text !== confirmText) && (text != null || confirmText != null)
        && (text !== undefined && confirmText !== undefined) &&
        (text.length > 0 || confirmText.value.length > 0)) {
        return { [outcomeRefKey]: true }
      }
    }
  }

  // SINGLE FIELD VALIDATORS
  static emailValidator(control: UntypedFormControl): { [key: string]: any } {
    const emailRegexp = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i
    if (control.value && !emailRegexp.test(control.value)) {
      return { invalidEmail: true }
    }
  }

  static checkIsValidDomain(domain) {
    const re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/)
    return domain.match(re)
  }

  // FORM GROUP VALIDATORS
  static matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const password = group.controls[passwordKey]
      const confirmPassword = group.controls[confirmPasswordKey]

      if ((password.value !== confirmPassword.value) && (password.value != null || confirmPassword.value != null)
        && (password.value !== undefined && confirmPassword.value !== undefined) &&
        (password.value.length > 0 || confirmPassword.value.length > 0)) {
        return {
          mismatchedPasswords: true
        }
      }
    }
  }

  static matchingEmail(emailKey: string, confirmEmailKey: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const email = group.controls[emailKey]
      const confirmEmail = group.controls[confirmEmailKey]

      if ((email.value !== confirmEmail.value) && (email.value != null || confirmEmail.value != null)
        && (email.value?.length > 0 || confirmEmail.value?.length > 0)) {
        return {
          mismatchedEmail: true
        }
      }
    }
  }

  static isEmail(stringa): boolean {
    const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegexp.test(stringa)
  }



  static maiuscolePassword(maiuscolePassword: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (maiuscolePassword) {

        const regMaiuscola = new RegExp('(?=(?:.*[A-Z]){' + maiuscolePassword + ',})')
        if (!regMaiuscola.test(control.value)) {
          return ({ 'maiuscole': true })
        }
      } else {
        return ({ 'maiuscole': true })
      }
    }
  }

  static maiuscolePin(maiuscolePin: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (maiuscolePin) {

        const regMaiuscola = new RegExp('(?=(?:.*[A-Z]){' + maiuscolePin + ',})')
        if (!regMaiuscola.test(control.value)) {
          return ({ 'maiuscole': true })
        }
      } else {
        return ({ 'maiuscole': true })
      }

    }
  }

  static lunghezzaMinimaPassword(lunghezzaMinimaPassword: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (lunghezzaMinimaPassword) {
        if (control.value && control.value.length < lunghezzaMinimaPassword) {
          return ({ 'lunghezzaMinima': true })
        }
      } else {
        return ({ 'lunghezzaMinima': true })
      }
    }
  }

  static lunghezzaMinimaPin(lunghezzaMinimaPin: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // prendo la stringa successiva ai due punti
      const valore = control.value ? control.value.split(':')[1] : ''
      if (lunghezzaMinimaPin) {
        if (valore && valore.length < lunghezzaMinimaPin) {
          return ({ 'lunghezzaMinima': true })
        }
      } else {
        return ({ 'lunghezzaMinima': true })
      }
    }

  }

  static minuscolePassword(minuscolePassword: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (minuscolePassword) {
        const regMinuscola = new RegExp('(?=(?:.*[a-z]){' + minuscolePassword + ',})')
        if (!regMinuscola.test(control.value)) {
          return ({ 'minuscole': true })
        }
      } else {
        return ({ 'minuscole': true })
      }
    }
  }

  static minuscolePin(minuscolePin: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valore = control.value ? control.value.split(':')[1] : ''
      if (minuscolePin) {
        const regMinuscola = new RegExp('(?=(?:.*[a-z]){' + minuscolePin + ',})')
        if (!regMinuscola.test(valore)) {
          return ({ 'minuscole': true })
        }
      } else {
        return ({ 'minuscole': true })
      }
    }
  }

  static alfabetici(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const regMaiuscola = new RegExp('(?=.*[a-zA-Z].*[a-zA-Z])')
      if (!regMaiuscola.test(control.value)) {
        return ({ 'alfabetici': true })
      }
    }
  }

  static numericiPassword(numeriPassword: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (numeriPassword) {
        const regNumerici = new RegExp('(?=(?:.*[0-9]){' + numeriPassword + ',})')
        if (!regNumerici.test(control.value)) {
          return ({ 'numerici': true })
        }
      } else {
        return ({ 'numerici': true })
      }
    }
  }

  static numericiPin(numeriPin: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valore = control.value ? control.value.split(':')[1] : ''
      if (numeriPin) {
        const regNumerici = new RegExp('(?=(?:.*[0-9]){' + numeriPin + ',})')
        if (!regNumerici.test(valore)) {
          return ({ 'numerici': true })
        }
      } else {
        return ({ 'numerici': true })
      }
    }
  }

  static specialiPassword(caratteriSpecialiPassword: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (caratteriSpecialiPassword) {
        // const regSpeciali = new RegExp('(?=(?:.*[!@#\$%\^&\*\\\\?~_£()-]){' + caratteriSpecialiPassword + ',})')
        const regSpeciali = new RegExp("([!@#$%^*+?_£\\-\\.]){" + caratteriSpecialiPassword + ",}");
        if (!regSpeciali.test(control.value)) {
          return ({ 'speciali': true })
        }
      } else {
        return ({ 'speciali': true })
      }
    }
  }

  static specialiPin(caratteriSpecialiPin: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valore = control.value ? control.value.split(':')[1] : ''
      if (caratteriSpecialiPin) {
        // const regSpeciali = new RegExp('(?=(?:.*[!@#\$%\^&\*\\\\?~_£()-]){' + caratteriSpecialiPin + ',})')
        const regSpeciali = new RegExp(".([!@#$%^*+?_£\\-\\.]).{" + caratteriSpecialiPin + ",}");
        if (!regSpeciali.test(valore)) {
          return ({ 'speciali': true })
        }
      } else {
        return ({ 'speciali': true })
      }
    }
  }


  static isNumeroTelefonico(daControllare: any): boolean {
    const regexpNumeroTelefonico = new RegExp('^[0-9]*$')

    if (!regexpNumeroTelefonico.test(daControllare) ||
      (regexpNumeroTelefonico.test(daControllare) && (daControllare.toString().length < 9 || daControllare.toString().length > 10))) {

      return false
    }
    return true
  }


}
