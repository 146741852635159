import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-conferma-eliminazione-segnalazione-dialog',
  templateUrl: 'conferma-eliminazione-segnalazione.html',
})
export class ConfermaEliminazioneSegnalazioneDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any) { }
}
