export class Traduzioni {

    id: number

    hash: string

    lingua: string

    testo: string

}
