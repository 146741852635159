import { Component, Inject, OnInit } from '@angular/core'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import { SessionData } from '../../sessione/dati-sessione'
import { ThemeService } from '../../servizi/theme.service'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { VisualizzatorePdfDialogComponent } from '../../componenti/visualizzatore-pdf/visualizzatore-pdf.component'
import { Documento, TipoDocumento } from '../../modello/documento'
import { TipoContenuto, SottoTipoContenuto } from '../../utility/utility'

@Component({
  selector: 'app-informativa',
  templateUrl: './informativa.component.html',
  styleUrls: ['./informativa.component.css'],
})
export class InformativaComponent {
  editorConfig: AngularEditorConfig = {
    enableToolbar: false,
    showToolbar: false,
    editable: false,
    spellcheck: false,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Impostare la Policy nella sezione di configurazione',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [
      // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  }
  informativa: string
  informativaDocumento: Documento
  pinCode: string
  informativaAccettata = false
  soloSalvataggio: boolean

  constructor(
    private sessionData: SessionData,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialog: MatDialog
  ) {
    this.pinCode = data['pinCode']
    this.soloSalvataggio = data['soloSalvataggio']
    this.informativa =
      this.sessionData.configurazione.informativaNuovaSegnalazione
    this.informativaDocumento =
      this.sessionData.configurazione.informativaNuovaSegnalazioneDocumento
  }

  copiaPin() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.pinCode)
      e.preventDefault()
      document.removeEventListener('copy', null)
    })
    document.execCommand('copy')
  }

  apriInformativa() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.informativaDocumento,
        testo: this.informativa,
        titolo: $localize`Informativa`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo:
          SottoTipoContenuto.INFORMATIVA_SUL_TRATTAMENTO_DEI_DATI_PERSONALI,
        lingua: this.codiceLingua,
        restituisceConsenso: true,
        canDownload: true,
        canPrint: true,
      },
    })

    dialogRef.afterClosed().subscribe((result) => {
      this.informativaAccettata = result
    })
  }

  get codiceLingua() {
    // ricava il codice della lingua corrente dalla url
    return window.location.href.split('/')[3]
  }

  get operazione() {
    return this.soloSalvataggio ? $localize`SALVARE` : $localize`INVIARE`
  }
}
