<h2 mat-dialog-title>{{titolo}}</h2>

<mat-dialog-content [formGroup]="form">

  <div class="centra-form">
    <angular-editor i18n-placeholder placeholder="Digita la motivazione..." formControlName="motivazione" [config]="editorConfig"></angular-editor>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <div class="flex-row">
    <button i18n mat-button  (click)="close()">Annulla</button>
    <button i18n mat-flat-button color="primary" (click)="eseguiAzione()">{{azione}}</button>
  </div>
</mat-dialog-actions>
