<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav mode="push">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon color="">menu</mat-icon>
    </button>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-toolbar></app-toolbar>
    <div style="display:flex; flex-direction: column">
      <div i18n class="titolo-pagina" >
        Lavorazioni
      </div>
      <div class="mat-elevation-z8" id="listaLavorazioni" *ngIf="lavorazioni && lavorazioni.length>0">
        <mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows (matSortChange)="sortData($event)">

          <mat-header-row *matHeaderRowDef="colonneVisualizzate" ></mat-header-row>
          <mat-row *matRowDef="let lavorazione; columns: colonneVisualizzate" class="pointer" (click)="selected(lavorazione)">
          </mat-row>

          <ng-container matColumnDef="id">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> ID Segnalazione </mat-header-cell>
            <mat-cell *matCellDef="let lavorazione"> {{idSegnalazione(lavorazione)}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="gestore">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Gestore </mat-header-cell>
            <mat-cell *matCellDef="let lavorazione"> {{gestore(lavorazione)}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="stato">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Stato </mat-header-cell>
            <mat-cell *matCellDef="let lavorazione">
              {{convertiStatoLavorazione(lavorazione.stato)}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sorgente">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Sorgente </mat-header-cell>
            <mat-cell *matCellDef="let lavorazione">
              {{lavorazione.lavorazioneMaster.sorgente?.nome}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="tema">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Tema </mat-header-cell>
            <mat-cell *matCellDef="let lavorazione">
              {{lavorazione.lavorazioneMaster.tema?.tema}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ammissibile">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Ammissibile </mat-header-cell>
            <mat-cell *matCellDef="let lavorazione">
              {{lavorazione.lavorazioneMaster.ammissibile ? 'Sì' : 'No'}}
            </mat-cell>
          </ng-container>
        </mat-table>
      </div>
    </div>
    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
    </mat-paginator>
  </mat-sidenav-content>
</mat-sidenav-container>
