import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ServiziRest } from '../utility/utility'
import { Esito } from '../utility/esito'
import { ColorCombination } from '../modello/color-combination'

@Injectable({
  providedIn: 'root',
})
export class ColorCombinationService {

  constructor(private http: HttpClient) {}

  // Creare una nuova combinazione di colori
  createColorCombination(colorCombo: ColorCombination): Observable<Esito> {
    return this.http.post<Esito>(`${ServiziRest.urlColorCombination}`, colorCombo)
  }

  // Aggiornare una combinazione di colori esistente
  updateColorCombination(colorCombo: ColorCombination): Observable<Esito> {
    return this.http.patch<Esito>(`${ServiziRest.urlColorCombination}`, colorCombo)
  }

  // Recuperare la combinazione di colori di default
  getDefaultColorCombination(): Observable<Esito> {
    return this.http.get<Esito>(`${ServiziRest.urlPublicColorCombination}/default`)
  }
}
