import { ESCAPE } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; question: string }) { }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.dialogRef.close();  // Chiude la dialog quando viene premuto il tasto ESC
      }
    });
  }
  onNoClick(): void {
    this.dialogRef.close(false)
  }

  onYesClick(): void {
    this.dialogRef.close(true)
  }
}
