import { Component, OnInit } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import { Input } from '@angular/core'
import { ViewChild } from '@angular/core'
import { ElementRef } from '@angular/core'
import { SessionData } from '../../sessione/dati-sessione'

@Component({
  selector: 'app-filtro-stati',
  templateUrl: './filtro-stati.component.html',
  styleUrls: ['./filtro-stati.component.scss']
})
export class FiltroStatiComponent implements OnInit {

  @Output() filtroSelezionato: EventEmitter<any> = new EventEmitter()

  @ViewChild('bozza', { static: true }) bozzaEl: ElementRef
  @ViewChild('nessunaLavorazione', { static: true }) nessunaLavorazioneEl: ElementRef
  @ViewChild('lavorazioniInCorso', { static: true }) lavorazioniInCorsoEl: ElementRef
  @ViewChild('alcuneLavorazioniChiuse', { static: true }) alcuneLavorazioniChiuseEl: ElementRef
  @ViewChild('lavorazioniChiuse', { static: true }) lavorazioniChiuseEl: ElementRef
  @ViewChild('ritirata', { static: true }) ritirataEl: ElementRef


  @Input()
  set filtroBozza(bozza) {
    SessionData.bozza = bozza
  }

  @Input()
  set filtroLavorazioniInCorso(inLavorazione) {
    SessionData.lavorazioniInCorso = inLavorazione
  }

  @Input()
  set filtroRitirate(ritirata) {
    SessionData.ritirata = ritirata
  }

  @Input()
  set filtroNessunaLavorazione(nessunaLavorazione) {
    SessionData.nessunaLavorazioneInCorso = nessunaLavorazione
  }

  @Input()
  set filtroAlcuneLavorazioniChiuse(chiuse) {
    SessionData.alcuneLavorazioniChiuse = chiuse
    // this.controllaMessaggiNonLetti();
  }

  @Input()
  set filtroLavorazioniChiuse(chiuse) {
    SessionData.tutteLeLavorazioniChiuse = chiuse
    // this.controllaMessaggiNonLetti();
  }


  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.initFilters()
  }, 500)
  }


  initFilters() {
    this.bozzaEl['checked'] = SessionData.bozza
    this.alcuneLavorazioniChiuseEl['checked'] = SessionData.alcuneLavorazioniChiuse
    this.lavorazioniChiuseEl['checked'] = SessionData.tutteLeLavorazioniChiuse
    this.nessunaLavorazioneEl['checked'] = SessionData.nessunaLavorazioneInCorso
    this.lavorazioniInCorsoEl['checked'] = SessionData.lavorazioniInCorso
    this.ritirataEl['checked'] = SessionData.ritirata
  }

  aggiornaFiltro(stato: string) {
    // console.log('filtro:' + stato);
    this.filtroSelezionato.emit({
      stato: stato
    })
  }

}
