import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(value: string, match: string): any {
    if (!match) {
        return value
    }
    // se match è presente in value allora ritorna value tutto in grassetto
    if (value.toLowerCase().indexOf(match.toLowerCase()) !== -1) {
        return `<strong>${value}</strong>`
    }
    return value
    // const regExp = new RegExp(match, 'gi')

    // return value.replace(regExp, (matchedValue: string, index, originalText) => {
    //     let prefix = ''
    //     let suffix = ''

    //     matchedValue = matchedValue.trim()

    //     // Se il match inizia con un carattere di parola e non è l'inizio del testo originale
    //     if (index > 0 && /\w/.test(matchedValue[0]) && !/\w/.test(originalText[index - 1])) {
    //         prefix = '&nbsp;'
    //     }

    //     // Se il match finisce con un carattere di parola e non è alla fine del testo originale
    //     if (index + matchedValue.length < originalText.length && /\w/.test(matchedValue[matchedValue.length - 1]) &&
    //     !/\w/.test(originalText[index + matchedValue.length])) {
    //         suffix = '&nbsp;'
    //     }

    //     return `${prefix}<strong>${matchedValue}</strong>${suffix}`
    // })
}
}
