import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
@Component({
  selector: 'app-conferma-creazione-sorgente-dialog',
  templateUrl: 'conferma-creazione-sorgente.html',
})
export class ConfermaCreazioneSorgenteDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any) { }
}
