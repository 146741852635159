import { Messaggio } from "./messaggio"
import { Odv } from "./odv"
import { User } from "./user"

export class ChatMessageInfo {
  id: number
  delivered: boolean
  read: boolean
  messaggio: Messaggio
  utente: User
  inviataEmailDiNotifica: boolean
  gruppo: Odv

  odvs () {
    if (this.utente.odv)
      return this.utente.odvMembership.map(m => m.nomeOdv)
    else
      return null
  }
}
