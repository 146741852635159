import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-conferma-eliminazione-utente-dialog',
  templateUrl: 'conferma-eliminazione-utente.html',
})
export class ConfermaEliminazioneUtenteDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any) { }
}
