import { Component, Input, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-editor-lingua-selector',
  templateUrl: './editor-lingua-selector.component.html',
  styleUrls: ['./editor-lingua-selector.component.scss']
})
export class EditorLinguaSelectorComponent {
  @Input() lingue: string[] = []
  @Input() linguaSelezionata: string
  @Output() linguaChanged: EventEmitter<string> = new EventEmitter()
  lingua: string

  constructor() {
    // this.linguaSelezionata = this.getDefaultLanguage()
    this.lingua = this.linguaSelezionata
    this.cambiaLingua(this.linguaSelezionata)
  }

  // ricava la lingua di default dalla lingua del browser
  getDefaultLanguage() {
    const linguaBrowser = navigator.language
    const linguaBrowserShort = linguaBrowser.split('-')[0]
    if (this.lingue.includes(linguaBrowserShort)) {
      return linguaBrowserShort
    }
    return 'it'
  }

  cambiaLingua(lingua: string) {
    this.lingua = lingua
    this.linguaChanged.emit(lingua)
  }
}
