import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { SorgenteSegnalazione } from '../modello/sorgente-segnalazione'
import { ServiziRest } from '../utility/utility'
import { Esito } from '../utility/esito'

@Injectable({
  providedIn: 'root'
})
export class SorgentiSegnalazioneService {
  constructor (private http: HttpClient) { }

  handleErrorPromise (error: Response | any) {
    console.error(error.message || error)
    return Promise.reject(error.error || error)
  }

  aggiornaSorgenteSegnalazione (sorgenteSegnalazione: SorgenteSegnalazione): Promise<Esito> {
    return this.http.patch<Esito>(ServiziRest.urlSorgentiSegnalazione, sorgenteSegnalazione).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  aggiornaSorgentiSegnalazione (sorgentiSegnalazione: SorgenteSegnalazione[]): Promise<Esito[]> {
    // richiama aggiornaSorgenteSegnalazione per ogni sorgente e crea una sola promise da ritornare
    return Promise.all(sorgentiSegnalazione.map((sorgente) => this.aggiornaSorgenteSegnalazione(sorgente)))
  }

  creaSorgenteSegnalazione (sorgenteSegnalazione: SorgenteSegnalazione): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlSorgentiSegnalazione, sorgenteSegnalazione).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  cancellaSorgenteSegnalazione (sorgenteSegnalazione: SorgenteSegnalazione): Promise<Esito> {
    return this.http.delete<Esito>(ServiziRest.urlSorgentiSegnalazione + '/' + sorgenteSegnalazione.id).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }


  recuperaSorgenteSegnalazione (sorgenteSegnalazione: SorgenteSegnalazione): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlSorgentiSegnalazione + '/' + sorgenteSegnalazione.id).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaSorgentiSegnalazione (): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlSorgentiSegnalazione).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaSorgenteSegnalazioneByName (sorgenteSegnalazione: SorgenteSegnalazione): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlGetSorgenteByNome + '/' + sorgenteSegnalazione.nome).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  async recuperaSorgentiSegnalazioneByName (testo: string): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlGetSorgentiByNome + '/' + testo).toPromise()
      .then((esito) => (esito as Esito))
      .catch(this.handleErrorPromise)
  }
}
