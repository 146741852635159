import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Tema } from '../modello/tema'
import { ServiziRest } from '../utility/utility'
import { Esito } from '../utility/esito'

@Injectable({
  providedIn: 'root'
})
export class TemiService {
  constructor (private http: HttpClient) { }

  handleErrorPromise (error: Response | any) {
    console.error(error.message || error)
    return Promise.reject(error.error || error)
  }

  aggiornaTema (tema: Tema): Promise<Esito> {
    return this.http.patch<Esito>(ServiziRest.urlTemi, tema).toPromise()
      .then((esito) => (esito))
      .catch(this.handleErrorPromise)
  }

  aggiornaTemi (temi: Tema[]): Promise<Esito[]> {
    return Promise.all(temi.map((tema) => this.aggiornaTema(tema)))
  }

  creaTema (tema: Tema): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlTemi, tema).toPromise()
      .then((esito) => (esito))
      .catch(this.handleErrorPromise)
  }

  cancellaTema (tema: Tema): any {
    return this.http.delete<Esito>(ServiziRest.urlTemi + '/' + tema.id).toPromise()
      .then((esito) => (esito))
      .catch(this.handleErrorPromise)
  }

  recuperaAziende (): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlTemi).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaTema (tema: Tema): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlTemi + '/' + tema.id).toPromise()
      .then((esito) => (esito))
      .catch(this.handleErrorPromise)
  }

  recuperaTemi (): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlTemi).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaTemaByName (tema: Tema): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlGetTemaByNome + '/' + tema.tema).toPromise()
      .then((esito) => (esito))
      .catch(this.handleErrorPromise)
  }

  async recuperaTemiByName (testo: string): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlGetTemiByNome + '/' + testo).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }
}
