import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'maskEncrypted'
})
export class MaskEncryptedPipe implements PipeTransform {

  transform(value: string): string {
    const prefix = 'ENCRYPTED:'
    return value && value.startsWith(prefix) ? '****' : value
  }

}
