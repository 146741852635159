<app-toolbar [mostraFiltro]="false"></app-toolbar>
<div class="alternate-theme">
  <div class="flex-column flex-100 center" class="overlay" *ngIf="saving">
    <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
  </div>
  <mat-sidenav-container autosize>
    <mat-sidenav #sidenav mode="push">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon color="">menu</mat-icon>
      </button>
    </mat-sidenav>

    <mat-sidenav-content *ngIf="visible">
      <div class="titolo-sezione" i18n style="width:100%;">
        Gestione Configurazione
      </div>
      <mat-tab-group (selectedIndexChange)="tabSelezionata($event)">
        <mat-tab i18n-label label="Generale">
          <div *ngIf="tabSelezionato == 0" class="flex-column">
            <mat-card style="width:96%!important">
              <div *ngIf="configurazione && formConfigurazione" class="mat-elevation-z8" id="configurazione"
                class="flex-column flex-gap-30" [formGroup]="formConfigurazione">
                <mat-list class="lista-configurazione flex-gap-30">
                  <mat-list-item>
                    <label i18n class="flex-30"> URL del portale Secure Blowing </label>
                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input style="flex: 0 0 70%;" id="url" matInput formControlName="url" />
                    </mat-form-field>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <label i18n class="flex-30"> Landing Page Video Upload -
                      Il formato deve essere secure-blowing-presentazione-{{'{'}}codice
                      lingua{{'}'}}-{{'{'}}dominio{{'}'}}.mp4 ad esempio secure-blowing-presentazione-en-collaudo.mp4
                    </label>
                    <form [formGroup]="form" (ngSubmit)="submit()"
                      style="display: flex; align-items: center; gap: 1rem;">
                      <input type="file" accept=".mp4" #fileInput style="display: none"
                        (change)="onFileSelect($event)" />
                      <button mat-button type="button" style="margin-top:16px;" (click)="fileInput.click()">Seleziona
                        File</button>
                      <span *ngIf="fileName">{{fileName}}</span>
                      <button *ngIf="fileName" mat-button type="submit">Upload</button>
                    </form>
                  </mat-list-item>

                  <mat-divider style="position: relative;"></mat-divider>

                  <mat-list-item>
                    <h3 class="flex-30" i18n> Lingue disponibili
                    </h3>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30"> Elenco delle Lingue per la presentazione dei
                      contenuti
                      testuali del sito
                    </label>
                    <mat-form-field class="form-group width-full" color="accent">
                      <mat-chip-list #chipListLingue formControlName="lingueForm">

                        <mat-chip *ngFor="let lingua of lingue" [selectable]="true" [removable]="true"
                          (removed)="removeLingua(lingua)">
                          {{lingua}}
                          <mat-icon matChipRemove *ngIf="true">cancel
                          </mat-icon>
                        </mat-chip>

                        <input matInput (matChipInputTokenEnd)="addLingua($event)" [matChipInputFor]="chipListLingue"
                          [readonly]="false">
                      </mat-chip-list>
                    </mat-form-field>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Domini email proibiti
                    </h3>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Elenco dei domini aziendali (In fase dei
                      registrazione, non
                      potranno essere fornite email appartenenti ai domini specificati)
                    </label>
                    <mat-form-field class="form-group width-full" color="accent">
                      <mat-chip-list #chipListdominiAziendali formControlName="dominiAziendali">

                        <mat-chip *ngFor="let email of dominiAziendali" [selectable]="true" [removable]="true"
                          (removed)="removeDomain(email)">
                          {{email}}
                          <mat-icon matChipRemove *ngIf="true">cancel
                          </mat-icon>
                        </mat-chip>

                        <input matInput (matChipInputTokenEnd)="addDomain($event)"
                          [matChipInputFor]="chipListdominiAziendali" [readonly]="false">
                      </mat-chip-list>
                    </mat-form-field>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n i18n> Indirizzamento segnalazioni
                    </h3>
                    <h4 i18n>
                      Il routing automatico avviene secondo il seguente ordine di preferenza:
                      <li>Secondo l'indicazione data dalla Tipologia della Segnalazione </li>
                      <li>Secondo l'indicazione data dall'azienda destinataria della Segnalazione </li>
                      <li>Secondo l'indicazione data dai destinatari predefiniti </li>
                    </h4>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30"> Il Segnalante vede i gestori delle sue segnalazioni
                    </label>
                    <mat-checkbox #vedeOdv matInput type="checkbox" formControlName="segnalanteVedeOdv">
                    </mat-checkbox>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30"> Il Segnalante può selezionare i destinatari delle proprie
                      segnalazioni
                    </label>
                    <mat-checkbox #sceltaOdv matInput type="checkbox" formControlName="segnalantePuoScegliereOdv">
                    </mat-checkbox>
                  </mat-list-item>

                  <mat-list-item *ngIf="sceltaOdv.checked">
                    <label i18n class="flex-30"> Il Segnalante può indirizzare le segnalazioni solo verso
                      i gruppi predefiniti
                    </label>
                    <mat-checkbox matInput #unicoOdv type="checkbox" formControlName="unicoOdvPerSegnalante">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30"> Abilita la gestione del conflitto di interessi
                    </label>
                    <mat-checkbox #abilitaGestioneConflittoInteressi matInput type="checkbox"
                      formControlName="abilitaGestioneConflittoInteressi">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30"> Gruppi destinatari predefiniti (sfondo verde). Tali gruppi
                      riceveranno le segnalazioni delle società prive di indicazioni rispetto ai gruppi predefiniti
                    </label>
                    <mat-form-field class="form-group width-full">
                      <mat-chip-list #chipListDestinatari>
                        <mat-chip *ngFor="let destinatario of destinatari" [selectable]="true" [removable]="true"
                          [class.odv-predefinito]="destinatario.predefinito"
                          [class.odv-alternativo]="destinatario.predefinito === false"
                          (click)="togglePredefinito(destinatario)"
                          [matTooltip]="testoTooltipDestinatario(destinatario)">
                          {{destinatario.nomeOdv}}
                          <mat-icon matChipRemove *ngIf="false">cancel</mat-icon>
                        </mat-chip>
                      </mat-chip-list>
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item *ngIf="true">
                    <label i18n class="flex-30 flex-row"> Elenco Società - Clicca su ognuna per configurare
                      i gruppi destinatari
                    </label>
                    <mat-form-field class="form-group width-full" color="accent">
                      <mat-chip-list #chipListSocieta>

                        <mat-chip *ngFor="let societa of elencoSocieta" [selectable]="true" [removable]="false"
                          (click)="caricaDestinatariPerSocieta(societa)">
                          {{societa.nomeAzienda}}
                          <mat-icon matChipRemove *ngIf="false">cancel</mat-icon>
                          <mat-icon class="pointer" (click)="caricaDestinatariPerSocieta(societa)">people</mat-icon>
                        </mat-chip>

                        <input matInput [matChipInputFor]="chipListSocieta" [readonly]="false">
                      </mat-chip-list>
                    </mat-form-field>
                  </mat-list-item>
                  <mat-list-item *ngIf="societaSelezionata">

                    <label i18n class="flex-30"> Gruppi Destinatari per la società
                      <b>{{societaSelezionata.nomeAzienda}}</b>
                    </label>
                    <mat-form-field class="form-group width-full">
                      <mat-chip-list #chipListDestinatariPerSocieta>
                        <mat-chip *ngFor="let destinatario of destinatariFiltratiSocieta" [selectable]="true"
                          [removable]="true" [matTooltip]="testoTooltipDestinatarioSocieta(destinatario)"
                          (click)="toggleDestinatarioPreferito($event, destinatario)"
                          [class.odv-predefinito]="destinatario.destinatarioPredefinito"
                          [class.odv-alternativo]="destinatario.destinatarioPredefinito === false">
                          {{destinatario.odv.nomeOdv}}
                          <mat-icon matChipRemove *ngIf="true"
                            (mousedown)="removeDestinatarioSocieta($event, destinatario)">cancel</mat-icon>
                        </mat-chip>
                        <div *ngIf="!chipRemoveClicked && !chipClicked">
                          <input #inputDestinatari matInput i18n-placeholder
                            [matChipInputFor]="chipListDestinatariPerSocieta" [matAutocomplete]="auto4"
                            [readonly]="false" autocompleteOff>
                          <mat-autocomplete #auto4="matAutocomplete">
                            <mat-option *ngFor="let destinatario of getNonSelectedDestinatariSocieta()"
                              [value]="destinatario.nomeOdv"
                              (onSelectionChange)="addDestinatarioSocieta($event, destinatario)">
                              {{ destinatario.nomeOdv }}
                            </mat-option>
                          </mat-autocomplete>
                        </div>

                      </mat-chip-list>
                    </mat-form-field>
                  </mat-list-item>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Tipologie Segnalazioni
                    </h3>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Mostra campo per inserimento del tipo non conformita
                    </label>
                    <mat-checkbox #mostraTipiNonConformita matInput type="checkbox"
                      (change)="mostraTipiNonConformitaChange($event)"
                      formControlName="mostraTipiNonConformitaSuDettaglioSegnalazione">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-list-item *ngIf="mostraTipiNonConformita.checked">
                    <label i18n class="flex-30 flex-row"> Elenco delle tipologie di segnalazione
                    </label>
                    <mat-form-field class="form-group width-full" color="accent">
                      <mat-chip-list #chipListTipologieSegnalazione formControlName="tipiNonConformita">

                        <mat-chip *ngFor="let tipoSegnalazione of tipologieSegnalazione" [selectable]="true"
                          [removable]="true" (removed)="removeTipologia(tipoSegnalazione)"
                          (click)="caricaDestinatariPredefiniti(tipoSegnalazione)">
                          {{tipoSegnalazione}}
                          <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                          <mat-icon class="pointer"
                            (click)="openTipiNonConformitaTranslationDialog(tipoSegnalazione)">language</mat-icon>
                          <mat-icon class="pointer"
                            (click)="caricaDestinatariPredefiniti(tipoSegnalazione)">people</mat-icon>
                        </mat-chip>

                        <input matInput (matChipInputTokenEnd)="addTipologia($event)"
                          [matChipInputFor]="chipListTipologieSegnalazione" [readonly]="false">
                      </mat-chip-list>
                    </mat-form-field>
                  </mat-list-item>
                  <mat-list-item *ngIf="tipologiaSelezionata">

                    <label i18n class="flex-30"> Gruppi Destinatari per la tipologia
                      <b>{{tipologiaSelezionata}}</b>
                    </label>
                    <mat-form-field class="form-group width-full">
                      <mat-chip-list #chipListDestinatariPerTipologia>
                        <mat-chip *ngFor="let destinatario of destinatariTipologiaFiltrati" [selectable]="true"
                          [removable]="true" [matTooltip]="testoTooltipDestinatario(destinatario)"
                          (click)="toggleDestinatarioPerTipologiaPreferito($event, destinatario)"
                          [class.odv-predefinito]="destinatario.predefinito"
                          [class.odv-alternativo]="destinatario.predefinito === false">
                          {{destinatario.odv.nomeOdv}}
                          <mat-icon matChipRemove *ngIf="true"
                            (mousedown)="removeDestinatarioTipologia($event, destinatario)">cancel</mat-icon>
                        </mat-chip>
                        <div *ngIf="!chipRemoveClicked">
                          <input #inputDestinatari matInput i18n-placeholder
                            [matChipInputFor]="chipListDestinatariPerTipologia" [matAutocomplete]="auto4"
                            [readonly]="false" autocompleteOff>
                          <mat-autocomplete #auto4="matAutocomplete">
                            <mat-option *ngFor="let destinatario of getNonSelectedDestinatari()"
                              [value]="destinatario.nomeOdv"
                              (onSelectionChange)="addDestinatario($event, destinatario)">
                              {{ destinatario.nomeOdv }}
                            </mat-option>
                          </mat-autocomplete>
                        </div>

                      </mat-chip-list>
                    </mat-form-field>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>

                  <mat-list-item>
                    <h3 class="flex-30" i18n i18n> Inoltro segnalazioni
                    </h3>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30"> Inoltro consentito solo nell'ambito dei gestori della stessa
                      società che riceve la segnalazione
                    </label>
                    <mat-checkbox #consentiInoltroSegnalazioniLibero matInput type="checkbox"
                      formControlName="consentiInoltroSegnalazioniLibero">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Anonimato Segnalazioni
                    </h3>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Consenti segnalazioni anonime
                    </label>
                    <mat-checkbox matInput #consentiSegnalazioniAnonime type="checkbox"
                      formControlName="consentiSegnalazioniAnonime">
                    </mat-checkbox>
                  </mat-list-item>

                  <!--                   <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Indicazione delle società a cui si riferisce il fatto nella scheda
                      Segnalazione
                    </h3>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Mostra campo per inserimento della/e Società
                    </label>
                    <mat-checkbox #societaRiferimentoFatto matInput type="checkbox"
                    (change)="societaRiferimentoFattoChange($event)"
                      formControlName="mostraSocietaACuiSiRiferisceIlFattoSuDettaglioSegnalazione">
                    </mat-checkbox>
                  </mat-list-item> -->

                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Attributi Lavorazione
                    </h3>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Sorgenti
                    </label>
                    <mat-form-field class="form-group width-full" color="accent">
                      <mat-chip-list #chipListSorgenti formControlName="sorgenti">

                        <mat-chip *ngFor="let sorgente of filteredSorgenti()" [selectable]="true" [removable]="true"
                          (removed)="removeSorgente(sorgente)" (click)="openSorgentiTranslationDialog(sorgente)">
                          {{sorgente.nome}}
                          <mat-icon matChipRemove *ngIf="true">cancel
                          </mat-icon>
                        </mat-chip>

                        <input matInput (matChipInputTokenEnd)="addSorgente($event)"
                          [matChipInputFor]="chipListSorgenti" [readonly]="false">
                      </mat-chip-list>
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Temi
                    </label>
                    <mat-form-field class="form-group width-full" color="accent">
                      <mat-chip-list #chipListTemi formControlName="temi">

                        <mat-chip *ngFor="let tema of filteredTemi()" [selectable]="true" [removable]="true"
                          (removed)="removeTema(tema)" (click)="openTemiTranslationDialog(tema)">
                          {{tema.tema}}
                          <mat-icon matChipRemove *ngIf="true">cancel
                          </mat-icon>
                        </mat-chip>

                        <input matInput (matChipInputTokenEnd)="addTema($event)" [matChipInputFor]="chipListTemi"
                          [readonly]="false">
                      </mat-chip-list>
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Azioni di Follow-up
                    </label>
                    <mat-form-field class="form-group width-full" color="accent">
                      <mat-chip-list #chipListAzioniFollowup formControlName="azioni">

                        <mat-chip *ngFor="let azione of filteredAzioni()" [selectable]="true" [removable]="true"
                          (removed)="removeAzione(azione)" (click)="openAzioniTranslationDialog(azione)">
                          {{azione.azione}}
                          <mat-icon matChipRemove *ngIf="true">cancel
                          </mat-icon>
                        </mat-chip>

                        <input matInput (matChipInputTokenEnd)="addAzione($event)"
                          [matChipInputFor]="chipListAzioniFollowup" [readonly]="false">
                      </mat-chip-list>
                    </mat-form-field>
                  </mat-list-item>

                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Mascheramento voce segnalante
                    </h3>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <div class="flex-column flex-30">
                      <label i18n style="flex: 1 1 auto;"> Valore del pitch shifting
                      </label>
                      <p i18n style="flex: 1 1 auto;">Un pitch shifting pari a 1 lascia inalterata la voce, se minore si
                        1 (0.8 per
                        esempio)
                        abbasserà il tono mentre valori superiori a 1 alzano il tono. Valori troppo alti o troppo bassi
                        possono pregiudicare l'intellegibilità del parlato </p>
                    </div>
                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input style="flex: 0 0 70%;" id="pitch" matInput formControlName="pitch" />
                    </mat-form-field>

                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30"> Maschera sempre i vocali del segnalante
                    </label>
                    <mat-checkbox matInput #mascheraTuttiGliAudio type="checkbox"
                      formControlName="mascheraTuttiGliAudio">
                    </mat-checkbox>
                  </mat-list-item>

                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Accesso degli utenti Segnalanti
                    </h3>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <div class="flex-column flex-30">
                      <label i18n style="flex: 1 1 auto;"> Modalità PIN unico per segnalazione
                      </label>
                      <p i18n style="flex: 1 1 auto;">se selezionata agli utenti segnalanti verrà rilasciato un PIN per
                        ogni segnalazione
                        inserita, altrimenti avranno accesso al sistema con nickname e password </p>
                    </div>

                    <mat-checkbox #pinUnico matInput type="checkbox" formControlName="modalitaPinUnicoPerSegnalazione">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;" *ngIf="!pinUnico.checked">
                    <div class="flex-column flex-30">
                      <label i18n style="flex: 1 1 auto;"> Obbligatorietà dell'email da parte del Segnalante
                      </label>
                      <p i18n style="flex: 1 1 auto;">se selezionata in fase di registrazione al segnalante verrà
                        richiesto di inserire
                        obbligatoriamente una casella di posta elettronica </p>
                    </div>

                    <mat-checkbox #emailSegnalante matInput type="checkbox"
                      formControlName="emailSegnalanteObbligatoria">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Custodia delle identità dei segnalanti
                    </h3>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Abilita la figura del custode delle identità dei segnalanti
                    </label>
                    <mat-checkbox #custodiaIdentita matInput type="checkbox" formControlName="custodiaIdentita">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Consensi Segnalante
                    </h3>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30"> Permetti anche segnalazioni esclusivamente vocali
                    </label>
                    <mat-checkbox #segnalazioneVocaleAbilitata matInput type="checkbox"
                      formControlName="segnalazioneVocaleAbilitata">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Testo per la richiesta del consenso al segnalante al trattamento
                      della
                      registrazione audio inclusa nella segnalazione (Segnalazione vocale e scritta)
                    </label>
                    <div color="accent">
                      <div class="flex-column" color="accent">
                        <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                          [sottotipoSelezionato]="sottotipoSelezionato" id="manudoc"
                          [tipoDocumento]="TipoContenuto.DOCUMENTO" class="flex-column"
                          [sottotipoDocumento]="SottotipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_SEGNALAZIONE"
                          [lingua]="linguaSelezionata" [inlineTextOnly]="true"
                          [attivato]="sonoAttivo(SottotipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_SEGNALAZIONE)"
                          (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_SEGNALAZIONE)"
                          (documentoVisibile)="onClickMostraDocumento(NORMATIVA)" [form]="formConfigurazione"
                          [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                          (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)">
                        </app-xsp-editor>
                      </div>
                    </div>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Testo per la richiesta del consenso al segnalante al trattamento
                      della
                      registrazione audio inclusa nella segnalazione (Segnalazione solo vocale)
                    </label>
                    <div color="accent">
                      <div class="flex-column" color="accent">
                        <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                          [sottotipoSelezionato]="sottotipoSelezionato" id="manudoc"
                          [tipoDocumento]="TipoContenuto.DOCUMENTO" class="flex-column"
                          [sottotipoDocumento]="SottotipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_SEGNALAZIONE_VOCALE"
                          [lingua]="linguaSelezionata" [inlineTextOnly]="true"
                          [attivato]="sonoAttivo(SottotipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_SEGNALAZIONE_VOCALE)"
                          (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_SEGNALAZIONE_VOCALE)"
                          (documentoVisibile)="onClickMostraDocumento(NORMATIVA)" [form]="formConfigurazione"
                          [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                          (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)">
                        </app-xsp-editor>
                      </div>
                    </div>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;" class="flex-column list-item-configurazione">
                    <label i18n class="flex-30"> Testo per la richiesta del consenso al segnalante al trattamento
                      delle
                      registrazioni audio incluse nelle chat
                    </label>
                    <div color="accent">
                      <div class="flex-column" color="accent">
                        <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                          [sottotipoSelezionato]="sottotipoSelezionato" id="manudoc"
                          [tipoDocumento]="TipoContenuto.DOCUMENTO" class="flex-column"
                          [sottotipoDocumento]="SottotipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_CHAT"
                          [lingua]="linguaSelezionata" [inlineTextOnly]="true"
                          [attivato]="sonoAttivo(SottotipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_CHAT)"
                          (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_CHAT)"
                          (documentoVisibile)="onClickMostraDocumento(NORMATIVA)" [form]="formConfigurazione"
                          [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                          (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)">
                        </app-xsp-editor>
                      </div>
                    </div>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Abilita il modulo di gestione dei consensi rilasciati dal
                      segnalante
                    </label>
                    <mat-checkbox #consensiSegnalante matInput type="checkbox" formControlName="consensiSegnalante">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;" class="flex-row">
                    <div class="flex-30">
                      <label i18n> Testo per la richiesta del consenso al segnalante
                      </label>
                    </div>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="manudoc"
                        [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.RICHIESTA_CONSENSO_AL_SEGNALANTE"
                        [attivato]="sonoAttivo(SottotipoContenuto.RICHIESTA_CONSENSO_AL_SEGNALANTE)"
                        [lingua]="linguaSelezionata"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.RICHIESTA_CONSENSO_AL_SEGNALANTE)"
                        (documentoVisibile)="onClickMostraDocumento(NORMATIVA)" [form]="formConfigurazione"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        inlineTextOnly="true" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Utilizzo dell'email fornita dal segnalante in calce alla
                      segnalazione
                    </h3>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Richiedi al segnalante se concede l'utilizzo dell'email per
                      ricevere
                      notifiche
                    </label>
                    <mat-checkbox #consensiSegnalante matInput type="checkbox"
                      formControlName="abilitaNotificheVersoEmailInCalceAllaSegnalazione">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Posizione del componente per la gestione della condivisione della
                      Segnalazione
                    </h3>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Posiziona il componente nella scheda Segnalazione (se
                      deselezionato, il
                      componente verrà inserito in testa alla scheda lavorazioni)
                    </label>
                    <mat-checkbox #consensiSegnalante matInput type="checkbox"
                      formControlName="condivisioneSegnalazioneNellaSchedaSegnalazione">
                    </mat-checkbox>
                  </mat-list-item>

                  <mat-divider style="position: relative;"></mat-divider>

                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Diritti di modifica sulle Lavorazioni
                    </h3>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Consenti al titolare della lavorazione di concedere il diritto
                      di modifica
                      della stessa a gestori terzi
                    </label>
                    <mat-checkbox #modificabilitaLavorazioniDaParteDiTerzi matInput type="checkbox"
                      formControlName="modificabilitaLavorazioniDaParteDiTerzi">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Retention delle Segnalazioni e dati collegati
                    </h3>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Periodo di Retention prima della cancellazione
                      delle
                      segnalazioni
                      marcate dai gestori
                    </label>
                    <div class="flex-row flex-fill">
                      <mat-form-field style="flex: 1 1 auto;" color="accent">
                        <input matInput type="number"
                          formControlName="giorniRetentionSegnalazioneMarcataDaCancellareDaGestore" />
                      </mat-form-field>
                      <mat-form-field style="top:-1px">
                        <mat-select [(value)]="selectedUM1">
                          <mat-option #um1 *ngFor="let um of unitaDiMisura" [value]="um">
                            {{um}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Periodo di retention prima della cancellazione di
                      tutte le
                      segnalazioni
                    </label>
                    <div class="flex-row flex-fill">
                      <mat-form-field style="flex: 1 1 auto;" color="accent">
                        <input matInput type="number" formControlName="giorniRetentionSegnalazione" />
                      </mat-form-field>
                      <mat-form-field style="top:-1px">
                        <mat-select [(value)]="selectedUM2">
                          <mat-option #um2 *ngFor="let um of unitaDiMisura" [value]="um">
                            {{um}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </mat-list-item>

                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Eliminazione della Segnalazione per mancata sottomissione </h3>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Giorni entro i quali la segnalazione deve essere
                      inviata.
                      Superata tale scadenza la Segnalazione viene eliminata
                    </label>
                    <div class="flex-row flex-fill">
                      <mat-form-field style="flex: 1 1 auto;" color="accent">
                        <input matInput type="number" formControlName="limiteGiorniSottomissioneSegnalazione" />
                      </mat-form-field>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Chiusura Indagine delle Segnalazioni</h3>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Giorni entro i quali l'indagine deve essere
                      chiusa
                    </label>
                    <div class="flex-row flex-fill">
                      <mat-form-field style="flex: 1 1 auto;" color="accent">
                        <input matInput type="number" formControlName="limiteGiorniChiusuraIndagineSegnalazione" />
                      </mat-form-field>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Soglia di avviso di scadenza del limite
                      di giorni per la chiusura dell'indagine
                    </label>
                    <div class="flex-row flex-fill">
                      <mat-form-field style="flex: 1 1 auto;" color="accent">
                        <input matInput type="number"
                          formControlName="sogliaGiorniPerAlertMancataChiusuraIndagineSegnalazione" />
                      </mat-form-field>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Frequenza di invio email di avviso in giorni dopo
                      il
                      superamento della soglia di avviso
                    </label>
                    <div class="flex-row flex-fill">
                      <mat-form-field style="flex: 1 1 auto;" color="accent">
                        <input matInput type="number"
                          formControlName="frequenzaInvioAlertMancataChiusuraIndagineSegnalazione" />
                      </mat-form-field>
                    </div>
                  </mat-list-item>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="scadenzaChiusuraIndagine" [tipoDocumento]="TipoContenuto.EMAIL"
                        [sottotipoDocumento]="SottotipoContenuto.PROSSIMA_SCADENZA_CHIUSURA_INDAGINE"
                        [attivato]="sonoAttivo(SottotipoContenuto.PROSSIMA_SCADENZA_CHIUSURA_INDAGINE)"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.scadenza}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.scadenza}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>

                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="raggiuntaScadenzaChiusuraIndagine" [tipoDocumento]="TipoContenuto.EMAIL"
                        [sottotipoDocumento]="SottotipoContenuto.RAGGIUNTA_SCADENZA_CHIUSURA_INDAGINE"
                        [attivato]="sonoAttivo(SottotipoContenuto.RAGGIUNTA_SCADENZA_CHIUSURA_INDAGINE)"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.scadenza}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.scadenza}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>

                  <mat-divider style="position: relative;"></mat-divider>
                </mat-list>
              </div>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab i18n-label label="Notifiche Email">
          <div *ngIf="tabSelezionato == 1" class="flex-column">
            <mat-card style="width:96%!important">
              <div *ngIf="configurazione && formConfigurazione" class="mat-elevation-z8" id="configurazione"
                class="flex-column" [formGroup]="formConfigurazione">
                <mat-list>
                  <mat-list-item *ngIf="false" class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column" [titoloSezione]="benvenuto"
                        [tipoDocumento]="TipoContenuto.EMAIL" [sottotipoDocumento]="SottotipoContenuto.BENVENUTO"
                        [attivato]="sonoAttivo(SottotipoContenuto.BENVENUTO)" [lingua]="linguaSelezionata"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}', '{user.username}', '{pin}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}', '{user.username}', '{pin}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="verificaPosta" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.VERIFICA_CASELLA_POSTA)"
                        [sottotipoDocumento]="SottotipoContenuto.VERIFICA_CASELLA_POSTA" [lingua]="linguaSelezionata"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}', '{urlVerifica}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}', '{urlVerifica}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column" [titoloSezione]="verificaPostaSso"
                        [tipoDocumento]="TipoContenuto.EMAIL" [sottotipoDocumento]="SottotipoContenuto.BENVENUTO_SSO"
                        [attivato]="sonoAttivo(SottotipoContenuto.BENVENUTO_SSO)" [lingua]="linguaSelezionata"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}', '{user.username}', '{pin}', '{url}', '{urlVerifica}']"
                        [paroleChiaveTesto]="['{user.nome}', '{user.username}', '{pin}', '{url}', '{urlVerifica}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column" [titoloSezione]="resetPin"
                        [tipoDocumento]="TipoContenuto.EMAIL" [sottotipoDocumento]="SottotipoContenuto.RESET_PIN"
                        [lingua]="linguaSelezionata" [attivato]="sonoAttivo(SottotipoContenuto.RESET_PIN)"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}', '{urlReset}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}', '{urlReset}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="nuovaSegnalazione" [tipoDocumento]="TipoContenuto.EMAIL"
                        [sottotipoDocumento]="SottotipoContenuto.NUOVA_SEGNALAZIONE" [lingua]="linguaSelezionata"
                        [angularEditorEsterno]="false" [attivato]="sonoAttivo(SottotipoContenuto.NUOVA_SEGNALAZIONE)"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerCambioStatoSegnalazione matInput type="checkbox"
                          formControlName="inviaEmailPerCambioStatoSegnalazione">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica i gestori del cambio stato
                          segnalazione
                        </label>
                      </div>
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailSegnalantePerCambioStatoSegnalazione matInput type="checkbox"
                          formControlName="inviaEmailSegnalantePerCambioStatoSegnalazione">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica il segnalante del cambio stato
                          segnalazione
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="statoSegnalazione" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.CAMBIO_STATO_SEGNALAZIONE)"
                        [sottotipoDocumento]="SottotipoContenuto.CAMBIO_STATO_SEGNALAZIONE" [lingua]="linguaSelezionata"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.nuovoStato}', '{segnalazione.vecchioStato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.nuovoStato}', '{segnalazione.vecchioStato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerCambioStatoLavorazione matInput type="checkbox"
                          formControlName="inviaEmailPerCambioStatoLavorazione">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica i gestori del cambio stato della
                          lavorazione
                        </label>
                      </div>
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailSegnalantePerCambioStatoLavorazione matInput type="checkbox"
                          formControlName="inviaEmailSegnalantePerCambioStatoLavorazione">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica il segnalante del cambio stato della
                          lavorazione
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="statoLavorazione" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.CAMBIO_STATO_LAVORAZIONE)"
                        [sottotipoDocumento]="SottotipoContenuto.CAMBIO_STATO_LAVORAZIONE" [lingua]="linguaSelezionata"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{lavorazione.nuovoStato}', '{lavorazione.vecchioStato}', '{odv.nome}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{lavorazione.nuovoStato}', '{lavorazione.vecchioStato}', '{odv.nome}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerNotificaLavorazioneInoltrata matInput type="checkbox"
                          formControlName="inviaEmailPerNotificaLavorazioneInoltrata">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica di inoltro lavorazione
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="inoltroLavorazione" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.INOLTRO_LAVORAZIONE)"
                        [sottotipoDocumento]="SottotipoContenuto.INOLTRO_LAVORAZIONE" [lingua]="linguaSelezionata"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{lavorazione.nuovoStato}', '{lavorazione.vecchioStato}', '{odv.nome}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{lavorazione.nuovoStato}', '{lavorazione.vecchioStato}', '{odv.nome}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerInoltroSegnalazione matInput type="checkbox"
                          formControlName="inviaEmailPerInoltroSegnalazione">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica di inoltro segnalazione
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="inoltroSegnalazione" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.INOLTRO_SEGNALAZIONE)"
                        [sottotipoDocumento]="SottotipoContenuto.INOLTRO_SEGNALAZIONE" [lingua]="linguaSelezionata"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{odv.nome}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{odv.nome}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerDisinoltroSegnalazione matInput type="checkbox"
                          formControlName="inviaEmailPerDisinoltroSegnalazione">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica di annullamento inoltro segnalazione
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="annullamentoInoltroSegnalazione" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.ANNULLAMENTO_INOLTRO_SEGNALAZIONE)"
                        [sottotipoDocumento]="SottotipoContenuto.ANNULLAMENTO_INOLTRO_SEGNALAZIONE"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{odv.nome}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{odv.nome}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>

                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="nuovoMessaggio" [tipoDocumento]="TipoContenuto.EMAIL"
                        [sottotipoDocumento]="SottotipoContenuto.NUOVO_MESSAGGIO"
                        [attivato]="sonoAttivo(SottotipoContenuto.NUOVO_MESSAGGIO)" [lingua]="linguaSelezionata"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{messaggio.testo}', '{messaggio.dataInvio}', '{messaggio.mittente}', '{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{messaggio.testo}', '{messaggio.dataInvio}', '{messaggio.mittente}', '{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="azioniSegnalante" [tipoDocumento]="TipoContenuto.EMAIL"
                        [sottotipoDocumento]="SottotipoContenuto.AZIONI_IN_CARICO_AL_SEGNALANTE"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerNotificaNuovaRichiestaSbloccoIdentitaSegnalante matInput
                          type="checkbox"
                          formControlName="inviaEmailPerNotificaNuovaRichiestaSbloccoIdentitaSegnalante">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica per richiesta sblocco identità
                          segnalante
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="richiestaSbloccoIdentita" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.RICHIESTA_SBLOCCO_IDENTITA_SEGNALANTE)"
                        [sottotipoDocumento]="SottotipoContenuto.RICHIESTA_SBLOCCO_IDENTITA_SEGNALANTE"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>

                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerNotificaNegazioneSbloccoIdentitaSegnalante matInput type="checkbox"
                          formControlName="inviaEmailPerNotificaNegazioneSbloccoIdentitaSegnalante">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica per negazione sblocco identità
                          segnalante
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="negazioneSbloccoIdentita" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.NEGAZIONE_SBLOCCO_IDENTITA_SEGNALANTE)"
                        [sottotipoDocumento]="SottotipoContenuto.NEGAZIONE_SBLOCCO_IDENTITA_SEGNALANTE"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>

                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>

                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerNotificaConcessioneSbloccoIdentitaSegnalante matInput
                          type="checkbox" formControlName="inviaEmailPerNotificaConcessioneSbloccoIdentitaSegnalante">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica per concessione sblocco identità
                          segnalante
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="concessioneSbloccoIdentita" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.CONCESSIONE_SBLOCCO_IDENTITA_SEGNALANTE)"
                        [sottotipoDocumento]="SottotipoContenuto.CONCESSIONE_SBLOCCO_IDENTITA_SEGNALANTE"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerNotificaNuovoConsensoSegnalante matInput type="checkbox"
                          formControlName="inviaEmailPerNotificaNuovoConsensoSegnalante">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica per richiesta consenso al segnalante
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="richiestaConsensoSegnalante" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.RICHIESTA_CONSENSO_AL_SEGNALANTE)"
                        [sottotipoDocumento]="SottotipoContenuto.RICHIESTA_CONSENSO_AL_SEGNALANTE"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="revocaRichiestaConsensoSegnalante" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.REVOCA_RICHIESTA_CONSENSO_AL_SEGNALANTE)"
                        [sottotipoDocumento]="SottotipoContenuto.REVOCA_RICHIESTA_CONSENSO_AL_SEGNALANTE"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerNotificaNegazioneConsensoSegnalante matInput type="checkbox"
                          formControlName="inviaEmailPerNotificaNegazioneConsensoSegnalante">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica per negazione consenso dal
                          segnalante
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="negazioneConsensoSegnalante" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.NEGAZIONE_CONSENSO_DAL_SEGNALANTE)"
                        [sottotipoDocumento]="SottotipoContenuto.NEGAZIONE_CONSENSO_DAL_SEGNALANTE"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <!-- inserisce una checkbox material per abilitare o disabilitare la notifica -->
                      <div class="flex-row-start" color="accent">
                        <div class="flex-30"></div>
                        <mat-checkbox #inviaEmailPerNotificaConcessioneConsensoSegnalante matInput type="checkbox"
                          formControlName="inviaEmailPerNotificaConcessioneConsensoSegnalante">
                        </mat-checkbox>
                        <label i18n class="flex-30 flex-row"> Notifica per accettazione consenso dal
                          segnalante
                        </label>
                      </div>
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="accettazioneConsensoSegnalante" [tipoDocumento]="TipoContenuto.EMAIL"
                        [attivato]="sonoAttivo(SottotipoContenuto.ACCETTAZIONE_CONSENSO_DAL_SEGNALANTE)"
                        [sottotipoDocumento]="SottotipoContenuto.ACCETTAZIONE_CONSENSO_DAL_SEGNALANTE"
                        [lingua]="linguaSelezionata" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>

                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="flex-column list-item-configurazione">
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                        [titoloSezione]="revocaConsensoVocaleChat" [tipoDocumento]="TipoContenuto.EMAIL"
                        [sottotipoDocumento]="SottotipoContenuto.REVOCA_CONSENSO_VOCALE_CHAT"
                        [lingua]="linguaSelezionata"
                        [attivato]="sonoAttivo(SottotipoContenuto.REVOCA_CONSENSO_VOCALE_CHAT)"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                        [paroleChiaveOggetto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']"
                        [paroleChiaveTesto]="['{user.nome}','{user.username}','{segnalazione.id}','{segnalazione.progressivo}', '{segnalazione.azienda}', '{segnalazione.oggetto}', '{segnalazione.descrizione}', '{segnalazione.stato}', '{url}']">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                </mat-list>
              </div>
            </mat-card>
          </div>

        </mat-tab>
        <mat-tab i18n-label label="Landing Page, Informative e Policy">
          <div *ngIf="tabSelezionato == 2" class="flex-column">
            <mat-card style="width:96%!important">
              <div *ngIf="configurazione && formConfigurazione" class="mat-elevation-z8 flex-column flex-gap-30"
                id="configurazione" [formGroup]="formConfigurazione">
                <mat-list>
                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Testo Landing Page
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="manudoc"
                        [titoloSezione]="'Testo Landing Page'" [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.LANDING_PAGE" [lingua]="linguaSelezionata"
                        [attivato]="sonoAttivo(SottotipoContenuto.LANDING_PAGE)"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.LANDING_PAGE)"
                        [inlineTextOnly]="true" (documentoVisibile)="onClickMostraDocumento(LANDING_PAGE)"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>

                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Manuale Gestore
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="manugest" style="flex: 1 1 auto;"
                        [titoloSezione]="'Manuale Gestore'" [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.MANUALE_GESTORE" [lingua]="linguaSelezionata"
                        soloAllegati="true" [attivato]="sonoAttivo(SottotipoContenuto.MANUALE_GESTORE)"
                        [attributo]="'manualeGestoreDocumento'"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.MANUALE_GESTORE)"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>

                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Manuale Utente
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="manudoc" style="flex: 1 1 auto;"
                        [titoloSezione]="'Manuale Utente'" [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.MANUALE_UTENTE" [lingua]="linguaSelezionata"
                        soloAllegati="true" [attivato]="sonoAttivo(SottotipoContenuto.MANUALE_UTENTE)"
                        [attributo]="'manualeUtenteDocumento'"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.MANUALE_UTENTE)"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30"> Mostra il link al manuale utente nella landing page
                    </label>
                    <mat-checkbox matInput #unicoOdv type="checkbox" formControlName="mostraManualeUtenteInLanding">
                    </mat-checkbox>
                  </mat-list-item>

                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Informativa sul Trattamento dei Dati
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="inftrattdoc"
                        [titoloSezione]="'Informativa sul Trattamento dei Dati'"
                        [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.INFORMATIVA_SUL_TRATTAMENTO_DEI_DATI_PERSONALI"
                        [attivato]="sonoAttivo(SottotipoContenuto.INFORMATIVA_SUL_TRATTAMENTO_DEI_DATI_PERSONALI)"
                        soloAllegati="true" [lingua]="linguaSelezionata" [attributo]="'informativaTrattamentoDocumento'"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.INFORMATIVA_SUL_TRATTAMENTO_DEI_DATI_PERSONALI)"
                        (aggiornaTesto)="aggiornaTesto($event)" [angularEditorEsterno]="false"
                        (salvaDocumento)="salvaDocumento($event)" (eliminaDocumento)="eliminaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Procedura WhistleBlowing
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="procwhistle"
                        [titoloSezione]="'Procedura WhistleBlowing'" [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.PROCEDURA_WHISTLEBLOWING" soloAllegati="true"
                        [attivato]="sonoAttivo(SottotipoContenuto.PROCEDURA_WHISTLEBLOWING)"
                        [lingua]="linguaSelezionata" [attributo]="'proceduraWhistleblowingDocumento'"
                        [viewerId]="PROCEDURA_WHISTLEBLOWING"
                        (aggiungiDocumento)="aggiungiDocumento($event, PROCEDURA_WHISTLEBLOWING)"
                        [form]="formConfigurazione" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (salvaDocumento)="salvaDocumento($event)"
                        (aggiornaTesto)="aggiornaTesto($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30"> Mostra il link alle procedure Whistleblowing nella landing page
                    </label>
                    <mat-checkbox matInput #unicoOdv type="checkbox"
                      formControlName="mostraProcedureWhistleblowingInLanding">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Informativa per la Registrazione Utente
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="ru" [titoloSezione]="'Informativa'"
                        [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.INFORMATIVA_REGISTRAZIONE_UTENTE"
                        [attivato]="sonoAttivo(SottotipoContenuto.INFORMATIVA_REGISTRAZIONE_UTENTE)" soloAllegati="true"
                        [lingua]="linguaSelezionata" [attributo]="'informativaRegistrazioneUtenteDocumento'"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.INFORMATIVA_REGISTRAZIONE_UTENTE)"
                        (aggiornaTesto)="aggiornaTesto($event)" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Informativa per creazione nuova segnalazione
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="ns"
                        [testo]="configurazione.informativaNuovaSegnalazione" [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [titoloSezione]="'Informativa'"
                        [sottotipoDocumento]="SottotipoContenuto.INFORMATIVA_PER_CREAZIONE_NUOVA_SEGNALAZIONE"
                        [lingua]="linguaSelezionata"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.INFORMATIVA_PER_CREAZIONE_NUOVA_SEGNALAZIONE)"
                        [attivato]="sonoAttivo(SottotipoContenuto.INFORMATIVA_PER_CREAZIONE_NUOVA_SEGNALAZIONE)"
                        [angularEditorEsterno]="false" (aggiornaTesto)="aggiornaTesto($event)"
                        (eliminaDocumento)="eliminaDocumento($event)"
                        (salvaDocumento)="salvaDocumento($event)"></app-xsp-editor>
                    </div>
                  </mat-list-item>

                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>

                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Termini Di Servizio
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="ts" [titoloSezione]="'Termini di Servizio'"
                        [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.TERMINI_SERVIZIO"
                        [attivato]="sonoAttivo(SottotipoContenuto.TERMINI_SERVIZIO)" soloAllegati="true"
                        [lingua]="linguaSelezionata" (aggiornaTesto)="aggiornaTesto($event)"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.TERMINI_SERVIZIO)"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Cookie Policy
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="cpol" [titoloSezione]="'Cookie Policy'"
                        [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.COOKIE_POLICY"
                        [attivato]="sonoAttivo(SottotipoContenuto.COOKIE_POLICY)" soloAllegati="true"
                        [lingua]="linguaSelezionata" (aggiornaTesto)="aggiornaTesto($event)"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.COOKIE_POLICY)"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Misure di Sicurezza
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="msic" [titoloSezione]="'Misure di Sicurezza'"
                        [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.MISURE_DI_SICUREZZA"
                        [attivato]="sonoAttivo(SottotipoContenuto.MISURE_DI_SICUREZZA)" soloAllegati="true"
                        [lingua]="linguaSelezionata" (aggiornaTesto)="aggiornaTesto($event)"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.MISURE_DI_SICUREZZA)"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Privacy
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="ppol" [titoloSezione]="'Privacy Policy'"
                        [tipoDocumento]="TipoContenuto.DOCUMENTO" [sottotipoDocumento]="SottotipoContenuto.PRIVACY"
                        [attivato]="sonoAttivo(SottotipoContenuto.PRIVACY)" [attributo]="'privacyPolicyDocumento'"
                        soloAllegati="true" [lingua]="linguaSelezionata" (aggiornaTesto)="aggiornaTesto($event)"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.PRIVACY)"
                        [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                        (eliminaDocumento)="eliminaDocumento($event)" (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30"> Mostra il link all'informativa privacy nella landing page
                    </label>
                    <mat-checkbox matInput #unicoOdv type="checkbox" formControlName="mostraPrivacyPolicyInLanding">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Normativa
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="NORMATIVA"
                        [tipoDocumento]="TipoContenuto.DOCUMENTO" [sottotipoDocumento]="SottotipoContenuto.NORMATIVA"
                        [lingua]="linguaSelezionata"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.NORMATIVA)"
                        [attivato]="sonoAttivo(SottotipoContenuto.NORMATIVA)" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item class="list-item-configurazione">
                    <label i18n class="flex-30"> Domande Frequenti
                    </label>
                    <div class="flex-column" color="accent">
                      <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                        [sottotipoSelezionato]="sottotipoSelezionato" id="FAQ" [tipoDocumento]="TipoContenuto.DOCUMENTO"
                        [sottotipoDocumento]="SottotipoContenuto.FAQ" [lingua]="linguaSelezionata"
                        (aggiungiDocumento)="aggiungiDocumento($event, SottotipoContenuto.FAQ)"
                        [attivato]="sonoAttivo(SottotipoContenuto.FAQ)" [angularEditorEsterno]="false"
                        (eliminaDocumento)="eliminaDocumento($event)" (aggiornaTesto)="aggiornaTesto($event)"
                        (salvaDocumento)="salvaDocumento($event)">
                      </app-xsp-editor>
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab i18n-label label="Sicurezza">
          <div *ngIf="tabSelezionato == 3" class="flex-column">
            <mat-card style="width:96%!important">
              <div *ngIf="configurazione && formConfigurazione" class="mat-elevation-z8" id="configurazione"
                class="flex-column flex-gap-30" [formGroup]="formConfigurazione">
                <mat-list>
                  <mat-list-item>
                    <h3 i18n> Identity provider per SSO
                    </h3>
                  </mat-list-item>
                  <mat-list-item style="height:auto">
                    <app-idp class="width-full bottom-30px"></app-idp>
                  </mat-list-item>

                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Autenticazione predefinita tramite Identity Provider Esterno
                    </label>
                    <mat-checkbox #autenticazionePredefinitaSSO matInput type="checkbox"
                      formControlName="autenticazionePredefinitaSSO">
                    </mat-checkbox>
                  </mat-list-item>

                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Autenticazione Secure Blowing abilitata
                    </label>
                    <mat-checkbox #autenticazioneSecureAbilitata matInput type="checkbox"
                      formControlName="autenticazioneSecureAbilitata">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Filtri anti-spam e Password Policy
                    </h3>
                  </mat-list-item>

                  <mat-list-item style="height:auto">
                    <h4 i18n class="flex-30 flex-row"> Filtri Anti-Spam
                    </h4>
                  </mat-list-item>
                  <mat-list-item style="height:auto" *ngIf="motif">
                    <div class="flex-column">
                      <!-- finestraTemporaleInSecondiPerCheckBan -->
                      <mat-list-item>
                        <label i18n class="flex-30 flex-row"> Finestra temporale in secondi per check ban
                        </label>
                        <mat-form-field style="flex: 1 1 auto;" color="accent">
                          <input matInput type="number" formControlName="finestraTemporaleInSecondiPerCheckBan" />
                        </mat-form-field>
                      </mat-list-item>
                      <!-- sogliaBanPerTassoRichiesteElevato -->
                      <mat-list-item>
                        <label i18n class="flex-30 flex-row"> Soglia ban per tasso richieste elevato
                        </label>
                        <mat-form-field style="flex: 1 1 auto;" color="accent">
                          <input matInput type="number" formControlName="sogliaBanPerTassoRichiesteElevato" />
                        </mat-form-field>
                      </mat-list-item>
                      <mat-divider style="position: relative;"></mat-divider>
                      <!-- sogliaBanPerLoginFallite -->
                      <mat-list-item>
                        <label i18n class="flex-30 flex-row"> Soglia ban per login fallite
                        </label>
                        <mat-form-field style="flex: 1 1 auto;" color="accent">
                          <input matInput type="number" formControlName="sogliaBanPerLoginFallite" />
                        </mat-form-field>
                      </mat-list-item>
                      <mat-divider style="position: relative;"></mat-divider>
                      <!-- sogliaNumeroBanTemporaneiPrimaDiDefinitivo -->
                      <mat-list-item>
                        <label i18n class="flex-30 flex-row"> Soglia numero ban temporanei prima di applicare un ban permanente
                        </label>
                        <mat-form-field style="flex: 1 1 auto;" color="accent">
                          <input matInput type="number" formControlName="sogliaNumeroBanTemporaneiPrimaDiDefinitivo" />
                        </mat-form-field>
                      </mat-list-item>
                      <!-- durataBanTemporaneiInMinuti -->
                      <mat-list-item>
                        <label i18n class="flex-30 flex-row"> Durata ban temporanei in minuti
                        </label>
                        <mat-form-field style="flex: 1 1 auto;" color="accent">
                          <input matInput type="number" formControlName="durataBanTemporaneiInMinuti" />
                        </mat-form-field>
                      </mat-list-item>
                      <mat-divider style="position: relative;"></mat-divider>
                      <!-- inserimento sogliaMovimentiMouse -->
                      <mat-list-item>
                        <label i18n class="flex-30 flex-row"> Soglia Movimenti Mouse
                        </label>
                        <mat-form-field style="flex: 1 1 auto;" color="accent">
                          <input matInput type="number" formControlName="sogliaMovimentiMouse" />
                        </mat-form-field>
                      </mat-list-item>
                      <!-- sogliaBattitureTastiera -->
                      <mat-list-item>
                        <label i18n class="flex-30 flex-row"> Soglia Battiture Tastiera
                        </label>
                        <mat-form-field style="flex: 1 1 auto;" color="accent">
                          <input matInput type="number" formControlName="sogliaBattitureTastiera" />
                        </mat-form-field>
                      </mat-list-item>
                      <!-- sogliaClick -->
                      <mat-list-item>
                        <label i18n class="flex-30 flex-row"> Soglia Click
                        </label>
                        <mat-form-field style="flex: 1 1 auto;" color="accent">
                          <input matInput type="number" formControlName="sogliaClick" />
                        </mat-form-field>
                      </mat-list-item>
                    </div>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item style="height:auto">
                    <h4 i18n class="flex-30 flex-row"> Doppio Fattore di autenticazione
                    </h4>
                    <mat-checkbox #mfa matInput type="checkbox" formControlName="abilitaMFA">
                    </mat-checkbox>
                  </mat-list-item>
                  <!-- Mostra un messaggio di errore se nessuna checkbox è selezionata -->
                  <mat-error *ngIf="formConfigurazione.hasError('noCheckboxSelected')" i18n>
                    Almeno una misura di sicurezza tra Captcha e doppio fattore di autenticazione deve essere attiva.
                  </mat-error>
                  <mat-list-item style="height:auto">
                    <mat-list-item class="flex-column list-item-configurazione" *ngIf="mfa.checked">
                      <div class="flex-column" color="accent">
                        <app-xsp-editor (sonoAttivo)="impostaSottotipoAttivo($event)"
                          [sottotipoSelezionato]="sottotipoSelezionato" class="flex-column"
                          [titoloSezione]="notificaResetMFA" [tipoDocumento]="TipoContenuto.EMAIL"
                          [sottotipoDocumento]="SottotipoContenuto.ATTIVAZIONE_MFA"
                          [attivato]="sonoAttivo(SottotipoContenuto.ATTIVAZIONE_MFA)" [lingua]="linguaSelezionata"
                          [angularEditorEsterno]="false" (eliminaDocumento)="eliminaDocumento($event)"
                          (aggiornaTesto)="aggiornaTesto($event)" (salvaDocumento)="salvaDocumento($event)"
                          [paroleChiaveOggetto]="['{user.nome}','{user.username}', '{urlMfa}']"
                          [paroleChiaveTesto]="['{user.nome}','{user.username}', '{urlMfa}']"> </app-xsp-editor>
                      </div>
                    </mat-list-item>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item style="height:auto">
                    <h4 class="flex-30" i18n> Blocco della Password
                    </h4>
                  </mat-list-item>
                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Numero tentativi prima del blocco
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="tentativiLoginFallitiPrimaDelBlocco" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Blocco permanente
                    </label>

                    <mat-checkbox #bloccoPermanente matInput type="checkbox" formControlName="bloccoPermanente">
                    </mat-checkbox>
                  </mat-list-item>

                  <mat-list-item *ngIf="!bloccoPermanente.checked">
                    <label i18n class="flex-30 flex-row"> Durata del blocco in minuti
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="minutiBlocco" />
                    </mat-form-field>
                  </mat-list-item>


                  <mat-list-item>
                    <h4 class="flex-30" i18n> Robustezza Password
                    </h4>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Lunghezza minima password
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="lunghezzaMinimaPassword" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Numero minimo di caratteri in maiuscolo
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="maiuscolePassword" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Numero minimo di caratteri in minuscolo
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="minuscolePassword" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Numero minimo di caratteri numerici
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="numeriPassword" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Numero minimo di caratteri speciali
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="caratteriSpecialiPassword" />
                    </mat-form-field>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Robustezza PIN
                    </h3>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Scadenza in giorni
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="scadenzaPin" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Lunghezza minima password
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="lunghezzaMinimaPIN" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Numero minimo di caratteri in maiuscolo
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="maiuscolePIN" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Numero minimo di caratteri in minuscolo
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="minuscolePIN" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Numero minimo di caratteri numerici
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="numeriPIN" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Numero minimo di caratteri speciali
                    </label>

                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="caratteriSpecialiPIN" />
                    </mat-form-field>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Tracciamento IP Utenti
                    </h3>
                  </mat-list-item>
                  <mat-list-item style="margin-bottom:30px;">
                    <label i18n class="flex-30"> Traccia l'IP degli utenti segnalanti
                    </label>
                    <mat-checkbox #ipUtenti matInput type="checkbox" formControlName="ipTracing">
                    </mat-checkbox>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                  <mat-list-item>
                    <h3 class="flex-30" i18n> Sessione Utente
                    </h3>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Durata sessione in secondi
                    </label>
                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="durataSessione" />
                    </mat-form-field>
                  </mat-list-item>

                  <mat-list-item>
                    <label i18n class="flex-30 flex-row"> Avvisa della sessione in scadenza con un anticipo
                      di
                      secondi
                    </label>
                    <mat-form-field style="flex: 1 1 auto;" color="accent">
                      <input matInput type="number" formControlName="sogliaAvvisoSecondiScadenzaSessione" />
                    </mat-form-field>
                  </mat-list-item>
                  <mat-divider style="position: relative;"></mat-divider>
                </mat-list>
              </div>
            </mat-card>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div class="bottone-flottante-basso-destra bottoni-flottanti ">
        <button mat-fab (click)="aggiornaConfigurazione($event); $event.stopPropagation();" i18n-matTooltip
          matTooltip="Salva" class="no-print" matTooltipPosition="left" [disabled]="formConfigurazione.invalid">
          <mat-icon aria-label="salva configurazione" class="icona-fab no-print">save</mat-icon>
        </button>
      </div>
      <div *ngIf="motif" class="bottone-flottante-basso-destra-su bottoni-flottanti ">
        <button mat-fab (click)="loadClientConfigurations(); $event.stopPropagation();" i18n-matTooltip
          matTooltip="Ricarica elenco clienti" class="no-print" matTooltipPosition="left">
          <mat-icon aria-label="ricarica configurazioni clienti" class="icona-fab no-print">refresh</mat-icon>
        </button>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
