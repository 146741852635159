<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav mode="push">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon color="">menu</mat-icon>
    </button>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-toolbar></app-toolbar>
    <div style="position:relative; display:flex; flex-direction: column" style="width: 100%;">
      <div i18n class="titolo-pagina">
        Log Operazioni
      </div>
      <div style="position:relative; display:flex; flex-direction: column" style="width: 100%;">

        <mat-table [dataSource]="dataSource" multiTemplateDataRows matSort style="width: 100%;" (matSortChange)="sortData($event)">

          <ng-container matColumnDef="operazione">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Operazione</mat-header-cell>
            <mat-cell *matCellDef="let element">{{getOperazioneLabel(element.operazione)}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="dataOperazione">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Data Operazione</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.dataOperazione | date:'dd/MM/yyyy HH:mm'}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="segnalazione">
            <mat-header-cell i18m *matHeaderCellDef>Segnalazione</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a (click)="apriSegnalazioneSelezionata(element.segnalazione)" class="pointer width-full">
                Segnalazione {{element.segnalazione?.progressivo}} - {{element.segnalazione?.descrizione }}
                <br>
                <p>
                {{ element.segnalazione?.oggetto | slice:0:80}}
                {{ element.segnalazione?.oggetto?.length > 80 ? '...': ''}}
                </p>
                <p>
                  {{ element.segnalazione?.descrizioneFatto | slice:0:256}}
                  {{ element.segnalazione?.descrizioneFatto?.length > 256 ? '...': ''}}
                  </p>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="lavorazione">
            <mat-header-cell i18n *matHeaderCellDef>Lavorazione</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ testoCellaLavorazione(element.lavorazione) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="utente">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Utente</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ nomeUtente(element)}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="utenteBeneficiario">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Utente beneficiario</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ nomeUtenteBeneficiario(element)}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="odv">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Gruppo gestore</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.odv?.nomeOdv}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="odvDestinatario">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Gruppo gestore destinatario</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.odvDestinatario?.nomeOdv}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="note">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Note</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.note}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row class="pointer" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator #paginator
               [length]="totalCount"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               (page)="getPaginatorData($event)">
        </mat-paginator>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
