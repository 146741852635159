<input [id]="fileUploadId" type="file" #file style="display: none" (change)="onFilesAdded($event.target.files);"
  [multiple]="multi" [accept]="accept"/>
  <span class="pointer grassetto" (click)="file.click()">{{label}}</span>
<button i18n class="no-print" color="accent" *ngIf="multi && fileToUpload?.length>0" style="margin-left:20px;"
  (click)="onSubmit()" mat-raised-button>Carica</button>
<div *ngIf="multi && fileToUpload && fileToUpload.length>0"
  style="top:20px;height:150px!important; position: relative;" i18n>
  Elenco allegati messaggio:
  <table>
    <tr *ngFor="let allegato of fileToUpload; let last = last"> {{last ? scrollToBottom() : ''}}
      <td>
        {{allegato.name}}
      </td>
      <td>
        <button mat-icon-button class="alternate-theme" color="accent"
          (click)="eliminaAllegato(allegato); $event.stopPropagation();">
          <mat-icon aria-label="cancella allegato">delete</mat-icon>
        </button>
      </td>
    </tr>
  </table>
</div>
