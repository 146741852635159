import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';

import { Esito } from '../utility/esito';
import { ConfigurazioneService } from '../servizi/configurazione.service';

export const loginResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Esito> | Promise<Esito> | Esito => {
  const configurazioneService = inject(ConfigurazioneService);
  return configurazioneService.recuperaConfigurazione();
};
