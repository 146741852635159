import { ESITO_KO, ESITO_OK, ESITO_UTENTE_DISABILITATO, ESITO_UTENTE_ELIMINATO } from './../utility/esito'
import { GestoriSegnalazioniDataService } from '../servizi/gestori-segnalazioni-data.service'
import { SegnalazioneService } from './../servizi/segnalazione.service'
import { AuthService } from './../servizi/auth.service'
import { Component, OnInit, ViewChild, Inject, HostListener, OnDestroy, AfterViewInit, ElementRef, ViewChildren, QueryList } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { COMMA, ENTER } from '@angular/cdk/keycodes'

import { compare, isValidEmailPersonale, openDialogGenerica } from '../utility/utility'


import { WINDOW } from '../servizi/window-service'

import { User } from '../modello/user'
import { ConfermaEliminazioneUtenteDialogComponent } from './conferma-eliminazione-utente.dialog.component'

import { UntypedFormControl } from '@angular/forms'
import { Observable, of, Subscription } from 'rxjs'
import { Odv } from '../modello/odv'

import { RicercaService } from '../servizi/ricerca.service'
import { Location, DOCUMENT } from '@angular/common'
import { Esito } from '../utility/esito'
import { MatAutocomplete } from '@angular/material/autocomplete'
import { MatSort, Sort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips'
import { ConfermaRiattivazioneUtenteDialogComponent } from './conferma-riattivazione-utente.dialog.component'
import { SessionData } from '../sessione/dati-sessione'

import { ModificaUtenteDialogComponent } from './modifica-utente.dialog.component'
import { map } from 'rxjs/operators'
import { CustomValidators } from '../validators/CustomValidators'
import { ConfigurazioneService } from '../servizi/configurazione.service'
import { orderBy } from 'lodash'


@Component({
  selector: 'app-gestione-utenti',
  templateUrl: './gestione-utenti.component.html',
  styleUrls: ['./gestione-utenti.component.scss']
})
export class GestioneUtentiComponent implements OnInit, AfterViewInit, OnDestroy {

  navIsFixed: boolean
  utenti: User[]
  dataSource
  utente: User

  selectable = true
  removable = true
  addOnBlur = true
  separatorKeysCodes: number[] = [ENTER, COMMA]
  odvCtrl = new UntypedFormControl()
  filteredOdvGroups: Observable<Odv[]>
  odvGroups: Odv[]
  gruppoSelezionato: Odv

  private subscriptions: Array<Subscription> = []

  @ViewChildren('focusMe') focusMe: QueryList<HTMLInputElement>
  @ViewChild('odvInput', { static: false }) odvInput: ElementRef<HTMLInputElement>
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete

  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator

  colonneVisualizzate = ['id', 'nome', 'cognome', 'userId', 'email',  'odv', 'su', 'amministratoreGestori', 'custode', 'disabilitato', 'possoUsareEmailPerNotifiche', 'emailVerificata', 'emailVerificaInviata', 'dataInvioLinkVerificaEmail','pinCode.dataInvioLinkRecupero', 'azioni']

  sortedData
  dirtyGroup: boolean
  idModificabile: any
  dirty: boolean
  savingOrLoading: boolean

  constructor(public dialog: MatDialog, public authService: AuthService, private router: Router,
    private route: ActivatedRoute, public snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document, @Inject(WINDOW) private window: Window,
    private segnalazioniService: SegnalazioneService, private odvService: GestoriSegnalazioniDataService,
    private ricercaService: RicercaService, private location: Location,
    private sessionData: SessionData,
    private configurazioneService: ConfigurazioneService) {
    // disabilitaBack();
    this.filteredOdvGroups = this.odvCtrl.valueChanges.pipe(
      map((odv: Odv | null) => odv && odv.nomeOdv ? this._filter(odv.nomeOdv.toString()) : this.removeAlreadySelectedGroups()))
  }
  ngOnDestroy(): void {
    this.subscriptions?.forEach(sub => sub.unsubscribe())
  }

  private _filter(value: string): Odv[] {
    const filterValue = value.toLowerCase()
    return this.odvGroups.filter(odv => odv.nomeOdv.indexOf(filterValue) === 0)
  }

  private removeAlreadySelectedGroups(): Odv[] {
  let result = []
  for (let i = 0; this.odvGroups !== undefined && i < this.odvGroups.length; i++) {
    const membershipNumber = this.utente && this.utente.odvMembership ? this.utente.odvMembership.length : 0
    if (membershipNumber > 0) {
      let trovato = false
      let j = 0
      while (!trovato && j < membershipNumber) {
        if (this.utente.odvMembership[j] !== undefined && this.utente.odvMembership[j].id === this.odvGroups[i].id) {
          trovato = true
        }
        j++
      }
      if (!trovato) {
        result.push(this.odvGroups[i])
      }
    } else {
      result = this.odvGroups.slice()
    }
  }
  return result
}

  ngOnInit() {
    this.savingOrLoading = true
    if (this.authService.getUser() && !this.authService.getUser()?.su && !this.authService.getUser()?.amministratoreGestori) {
      this.location.back()
    } else {
      this.subscriptions.push(this.ricercaService.filtraElenco.subscribe((cercami: string) => {
        this.applyFilter(cercami)
      }))
      this.caricaGruppi(false)
    }

    this.route.data.subscribe((data) => {
      // console.log(data);
      const configurazione = data['configurazione']
      if (configurazione) {
        this.sessionData.configurazione = configurazione
      } else {
        console.error(data)
        this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
      }
      return
    })

    this.configurazioneService.recuperaConfigurazione().
    subscribe(esito => {
      if (esito.esito === ESITO_OK) {
        this.sessionData.configurazione = JSON.parse(esito.payload)[0]
        // this.aggiornaFormatiBinariDocumenti(this.sessionData.configurazione);

        this.segnalazioniService.recuperaGruppiGestori()
          .then((esito2: Esito) => {
            if (esito2.esito === ESITO_OK) {
              // nulla da fare
            } else {
              this.snackBar.open($localize `Recupero elenco destinatari Fallito. Errore: ` + esito.descrizioneEsito, null, {
                duration: 4000,
              })
            }
          })
          .catch((error) => {
            this.snackBar.open($localize `Recupero elenco destinatari Fallito. Errore: ` + error, null, {
              duration: 4000,
            })
          })
      } else {
        this.snackBar.open($localize `Errore nel recupero della configurazione. ` + esito.descrizioneEsito, null, {
          duration: 4000,
        })
      }
    }, (errore => {
      this.snackBar.open($localize `Errore nel recupero della configurazione. ` + errore.toString(), null, {
        duration: 4000,
      })
    }))

    if (this.sessionData.configurazione?.custodiaIdentita) {
      this.colonneVisualizzate = ['id', 'nome', 'cognome', 'userId', 'email',  'odv', 'su', 'suDatiNonSensibili', 'amministratoreGestori',
      'custode', 'disabilitato', 'possoUsareEmailPerNotifiche', 'emailVerificata', 'emailVerificaInviata', 'dataInvioLinkVerificaEmail','dataInvioLinkRecupero', 'azioni']
    } else {
      this.colonneVisualizzate = ['id', 'nome', 'cognome', 'userId', 'email', 'odv', 'su', 'suDatiNonSensibili', 'amministratoreGestori',
      'disabilitato', 'possoUsareEmailPerNotifiche', 'emailVerificata', 'emailVerificaInviata', 'dataInvioLinkVerificaEmail','dataInvioLinkRecupero', 'azioni']
    }
  }

  gruppiOdv(utente: User): Odv[] {
    return []
  }

  caricaGruppi(mostraEsito: boolean): any {
    this.segnalazioniService.recuperaGruppiGestori()
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.odvGroups = JSON.parse(esito.payload)
          this.odvCtrl.updateValueAndValidity()
          if (mostraEsito) {
            this.snackBar.open($localize `Recupero elenco gruppi Odv. Gruppi recuperati: ` + this.odvGroups.length, null, {
              duration: 2000,
            })
          }
        } else {
          this.snackBar.open($localize `Recupero elenco destinatari Fallito. Errore: ` + esito.descrizioneEsito, null, {
            duration: 4000,
          })
        }
      })
      .catch((error) => {
        this.snackBar.open($localize `Recupero elenco gruppi Odv Fallito. Errore: ` + error, null, {
          duration: 4000,
        })
      })
  }



  ngAfterViewInit() {
    this.recuperaUtenti()
  }

  recuperaUtenti(ancheSegnalante=false) {
    this.authService.recuperaUtenti(ancheSegnalante).then((utenti: User[]) => {
      this.savingOrLoading = false
      this.utenti = utenti
      this.sortedData = this.utenti.slice()
      this.dataSource = new MatTableDataSource(this.utenti)
      this.dataSource.sortingDataAccessor = (item, property) => {
        const value = item[property]
        return typeof value === 'string' ? value.toLowerCase() : value
      }
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
    }, (errore) => {
      this.savingOrLoading = false
      this.snackBar.open($localize `Errore nel recupero degli utenti. ` + errore.toString(), null, {
        duration: 4000,
      })
    })
    if (this.paginator) {
      const paginatorIntl = this.paginator._intl
      paginatorIntl.nextPageLabel = ''
      paginatorIntl.previousPageLabel = ''
      paginatorIntl.lastPageLabel = ''
      paginatorIntl.firstPageLabel = ''
    }
  }


  sortData(sort: Sort) {
    const data = this.utenti.slice()
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data
      return
    }

    this.dataSource.data = data.sort((a: User, b: User) => {
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {
        case 'id': return compare(a.id, b.id, isAsc)
        case 'nome': return compare(a.nome, b.nome, isAsc)
        case 'cognome': return compare(a.cognome, b.cognome, isAsc)
        case 'userId': return compare(a.userId, b.userId, isAsc)
        case 'lingua': return compare(a.lingua, b.lingua, isAsc)
        case 'codiceFiscale': return compare(a.codiceFiscale, b.codiceFiscale, isAsc)
        case 'email': return compare(a.email, b.email, isAsc)
        case 'custode': return compare(a.custode, b.custode, isAsc)
        case 'mobile': return compare(a.mobile, b.mobile, isAsc)
        case 'odv': return compare(a.odv, b.odv, isAsc)
        case 'su': return compare(a.su, b.su, isAsc)
        case 'amministratoreGestori': return compare(a.amministratoreGestori, b.amministratoreGestori, isAsc)
        case 'disabilitato': return compare(a.disabilitato, b.disabilitato, isAsc)
        case 'possoUsareEmailPerNotifiche': return compare(a.possoUsareEmailPerNotifiche, b.possoUsareEmailPerNotifiche, isAsc)
        case 'dataInvioLinkVerificaEmail': return compare(a.dataInvioLinkVerificaEmail, b.dataInvioLinkVerificaEmail, isAsc)
        case 'dataInvioLinkRecupero': return compare(a.pinCode.dataInvioLinkRecupero, b.pinCode.dataInvioLinkRecupero, isAsc)
        default: return 0
      }
    })
  }

  applyFilter(filterValue: string) {

    if (this.dataSource) {
      this.dataSource.filterPredicate =
        (data: User, filter: string) => data['id'].toString().indexOf(filter) !== -1
          || (data['nome'] && data['nome'].toLowerCase().indexOf(filter) !== -1)
          || (data['cognome'] && data['cognome'].toLowerCase().indexOf(filter) !== -1)
          || (data['userId'] && data['userId'].toLowerCase().indexOf(filter) !== -1)
          || (data['lingua'] && data['lingua'].toLowerCase().indexOf(filter) !== -1)
          || (data['codiceFiscale'] && data['codiceFiscale'].toLowerCase().indexOf(filter) !== -1)
          || (data['email'] && data['email'].toString().toLowerCase().indexOf(filter) !== -1)
          || (data['mobile'] && data['mobile'].toString().toLowerCase().indexOf(filter) !== -1)
          || (data['dataInvioLinkVerificaEmail'] && data['dataInvioLinkVerificaEmail'].toString().toLowerCase().indexOf(filter) !== -1)
          || (data['pinCode'] && data['pinCode']['dataInvioLinkRecupero'] &&
          data['pinCode']['dataInvioLinkRecupero'].toString().toLowerCase().indexOf(filter) !== -1)

      filterValue = filterValue.trim() // Remove whitespace
      filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue
    }

  }

  selected(utente) {
    this.utente = utente
    if (utente.id !== this.idModificabile) {
      this.idModificabile = undefined
    }
    // console.log('selected:' + utente.persona.nome);
  }

  isSelectable(utente) {
    return !this.dirty
  }

  superUtente() {
    return this.authService.getUser()?.su
  }

  back() {
    this.router.navigate(['/home'], { queryParams: { page: 1 } })
  }

  aggiungiUtente() {
    // console.log('adding user...');
    const user = new User()
    this.openDialogModificaUtente(user)
  }

  cancella(utente: User) {
    this.savingOrLoading = true
    this.authService.cancellaUtente(utente)
      .then((esito: Esito) => {
        // console.log(utente);
        if (esito.esito !== ESITO_KO) {
          this.caricaGruppi(false)
          const messaggio = esito.esito === ESITO_OK ? 'aggiornato' :
            esito.esito === ESITO_UTENTE_DISABILITATO ? 'disabilitato' :
              esito.esito === ESITO_UTENTE_ELIMINATO ? 'eliminato' :
                'aggiornato!'
          if (esito.esito === ESITO_UTENTE_DISABILITATO) {
            const utenteAggiornato = this.utenti.find((user) => user.id === utente.id)
            utenteAggiornato.disabilitato = true
          } else if (esito.esito === ESITO_UTENTE_ELIMINATO) {
            this.utenti = this.utenti.filter(user => user.id !== utente.id)
            this.refresh()
          }
          this.snackBar.open($localize `Utente ${messaggio}`, null, {
            duration: 2000,
          })
          this.savingOrLoading = false
        } else {
          this.snackBar.open($localize `Eliminazione o Disabilitazione utente fallita` + esito.payload, null, {
            duration: 2000,
          })
          this.savingOrLoading = false
        }

      })
      .catch((errore) => {
        // console.log(errore);
        this.snackBar.open($localize `Eliminazione o Disabilitazione utente fallita` + errore, null, {
          duration: 2000,
        })
        this.savingOrLoading = false
      })
    this.refresh()
  }

  riattiva(utente) {
    this.savingOrLoading = true
    this.authService.riattivaUtente(utente)
      .then((esito: Esito) => {
        this.savingOrLoading = false
        // console.log(utente);
        if (esito.esito !== ESITO_KO) {
          this.caricaGruppi(false)
          if (esito.esito === ESITO_OK) {
            const utenteAggiornato = this.utenti.find((user) => user.id === utente.id)
            utenteAggiornato.disabilitato = false
          }
          this.snackBar.open($localize `Utente riattivato`, null, {
            duration: 2000,
          })
        } else {
          this.snackBar.open($localize `Eliminazione o Disabilitazione utente fallita` + esito.payload, null, {
            duration: 2000,
          })
        }

      })
      .catch((errore) => {
        this.savingOrLoading = false
        // console.log(errore);
        this.snackBar.open($localize `Eliminazione o Disabilitazione utente fallita` + errore, null, {
          duration: 2000,
        })
      })
    this.refresh()
  }

  sblocca(utente) {
    this.savingOrLoading = true
    this.authService.sbloccaUtente(utente)
      .then((esito: Esito) => {
        this.savingOrLoading = false
        // console.log(utente);
        if (esito.esito !== ESITO_KO) {
          this.caricaGruppi(false)
          if (esito.esito === ESITO_OK) {
            const utenteAggiornato = this.utenti.find((user) => user.id === utente.id)
            utenteAggiornato.bloccato = false
          }
          this.snackBar.open($localize `Utente sbloccato`, null, {
            duration: 2000,
          })
        } else {
          this.snackBar.open($localize `Sblocco utente fallita` + esito.payload, null, {
            duration: 2000,
          })
        }

      })
      .catch((errore) => {
        this.savingOrLoading = false
        // console.log(errore);
        this.snackBar.open($localize `Sblocco utente fallita` + errore, null, {
          duration: 2000,
        })
      })
    this.refresh()
  }

  disabilita(utente) {
    this.savingOrLoading = true
    this.authService.disabilitaUtente(utente)
      .then((esito: Esito) => {
        this.savingOrLoading = false
        // console.log(utente);
        if (esito.esito !== ESITO_KO) {
          this.caricaGruppi(false)
          if (esito.esito === ESITO_OK) {
            const utenteAggiornato = this.utenti.find((user) => user.id === utente.id)
            utenteAggiornato.disabilitato = true
          }
          this.snackBar.open($localize `Utente disabilitato`, null, {
            duration: 2000,
          })
        } else {
          this.snackBar.open($localize `Disabilitazione utente fallita` + esito.payload, null, {
            duration: 2000,
          })
        }

      })
      .catch((errore) => {
        this.savingOrLoading = false
        // console.log(errore);
        this.snackBar.open($localize `Disabilitazione utente fallita` + errore, null, {
          duration: 2000,
        })
      })
    this.refresh()
  }

  refresh() {
    if (this.utenti !== undefined) {
      this.dataSource.data = this.utenti
    }
  }

  openDialogConfermaEliminazioneUtente(utente): void {
    const dialogRef = this.dialog.open(ConfermaEliminazioneUtenteDialogComponent, {
      width: '500px',
      data: { 'id': utente.id, 'userId': utente.userId, },
      disableClose: true,
      hasBackdrop: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancella(utente)
      }
    })
  }

  modifica(utente) {
    this.idModificabile = utente.id
  }

  openDialogConfermaRiattivazioneUtente(utente): void {
    const dialogRef = this.dialog.open(ConfermaRiattivazioneUtenteDialogComponent, {
      width: '500px',
      data: { 'id': utente.id, 'userId': utente.userId },
      disableClose: true,
      hasBackdrop: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.riattiva(utente)
      }
    })
  }

  openDialogConfermaDisabilitazioneUtente(utente): void {
    openDialogGenerica(this.dialog, $localize`Disabilitazione Utente ` + utente.userId, '',
    $localize`Confermi di voler disabilitare l'utenza?`,
      () => this.disabilita(utente),
      () => { })
  }

  openDialogConfermaSbloccoUtente(utente: User) {

    openDialogGenerica(this.dialog, $localize`Sblocco Utente ` + utente.userId, '',
    $localize`Confermi di voler sblocccare l'utenza?`,
      () => this.sblocca(utente),
      () => { })
  }

  inviaEmailPerRecuperoPIN(utente: User) {

    this.authService.resetPinNoCaptcha(utente.id)
      .then(response => {
        if (+response.esito === ESITO_OK) {
          this.snackBar.open($localize `E\' stata inviata un\'email con le istruzioni per generare il PIN all\'indirizzo dell\'utente. `,
          null, {
            duration: 8000,
          })
        } else {
          this.snackBar.open(response['payload'], null, {
            duration: 8000,
          })
        }
      })
      .catch(error => {
        this.snackBar.open($localize `Invio email di generazione del PIN fallito ` + error, null, {
          duration: 8000,
        })
      })

  }

  inviaEmail(utente: User) {
    return utente.emailVerificata ? this.inviaEmailPerRecuperoPIN(utente) : this.inviaEmailVerifica(utente)
  }

  calcolaTooltipIconaEmail(utente: User): string {
    return utente.emailVerificata ? 'Invia Email per Recupero PIN' : utente.emailVerificaInviata ? 'Invia nuovamente un\'email di verifica '
    : 'Invia Email di verifica dell\'indirizzo di posta elettronica'
  }

  openDialogModificaUtente(utente, errore?): void {
    this.utente = utente
    const dialogRef = this.dialog.open(ModificaUtenteDialogComponent, {
      width: '800px',
      height: 'auto',
      data: { 'utente': utente, 'errore': errore },
      disableClose: true,
      hasBackdrop: true
    })
    dialogRef.afterClosed().subscribe(result => {
      console.log('result:', result)
      if (result) {
        //  SessionData.password = result;
        this.savingOrLoading = true
        this.utente = result.data
        if (this.utente.id) {
          this.authService.aggiornaUtente(this.utente)
            .then((esito) => {
              this.savingOrLoading = false
              if (ESITO_OK === esito.esito) {
                const ute = JSON.parse(esito.payload) as User
                const index = this.utenti.findIndex(ut => ut.id === +ute.id)
                if (index !== -1) {
                  this.utenti.splice(index, 1, ute)
                  this.refresh()
                } else {
                  this.utenti.push(ute)
                  this.refresh()
                }
                this.snackBar.open($localize `Utente aggiornato`, null, {
                  duration: 2000,
                })
              } else {
                this.snackBar.open($localize `Aggiornamento utente fallito ` + JSON.parse(esito.payload), null, {
                  duration: 2000,
                })
                this.openDialogModificaUtente(this.utente, JSON.parse(esito.payload))
              }
              // console.log(utente);

            })
            .catch((err) => {
              this.savingOrLoading = false
              // console.log(errore);
              this.snackBar.open($localize `Aggiornamento utente fallito ` + err, null, {
                duration: 2000,
              })
            })
        } else {
          this.authService.creaUtente(this.utente)
            .then((esito) => {
              this.savingOrLoading = false
              if (ESITO_OK === esito.esito) {
                const ute = JSON.parse(esito.payload) as User
                const index = this.utenti.findIndex(ut => ut.id === +ute.id)
                if (index !== -1) {
                  this.utenti.splice(index, 1, ute)
                  this.refresh()
                } else {
                  this.utenti.push(ute)
                  this.refresh()
                }
                this.snackBar.open($localize `Utente aggiornato`, null, {
                  duration: 2000,
                })
              } else {
                this.snackBar.open($localize `Aggiornamento utente fallito ` + JSON.parse(esito.payload), null, {
                  duration: 2000,
                })
                this.openDialogModificaUtente(this.utente, JSON.parse(esito.payload))
              }
              // console.log(utente);

            })
            .catch((err) => {
              this.savingOrLoading = false
              // console.log(errore);
              this.snackBar.open($localize `Aggiornamento utente fallito ` + err, null, {
                duration: 2000,
              })
            })
        }
      }
    })
  }


  selezionatoGruppoGestori(event, utente) {
    if (event.key === 'Enter' && event.target.value) {
      // console.log(event);
      let errori = ''
      if (utente.email !== undefined && !CustomValidators.isEmail(utente.email)) {
        errori += 'Email non valida'
      }
      if (utente.mobile && !CustomValidators.isNumeroTelefonico(utente.mobile)) {
        errori += ' Numero Telefonico non valido'
      }
      if (utente.mobile === undefined && utente.email === undefined) {
        errori += 'Fornire almeno uno tra Numero Telefonico ed Email!'
      }

      if (errori.length === 0) {
        // console.log('selezionato gruppo ' + event.target.value);
        //  Verifico se esiste
        this.odvService.recuperaOdvByName(event.target.value)
          .then(esito => {
            if (ESITO_OK === esito.esito) {
              const odv = JSON.parse(esito.payload)
              if (odv) {
                this.utente.odvMembership.push(odv)
              } else {
                const odvTemp = new Odv()
                odvTemp.nomeOdv = event.target.value
                this.utente.odvMembership.push(odvTemp)
                event.target.value = ''
                event.target.blur()
              }
              this.salvaUtente()
            } else {
              this.snackBar.open($localize `Recupero gruppo Odv Fallito. Errore: ` + esito.payload, null, {
                duration: 4000,
              })
            }
          })
      } else {
        this.snackBar.open(errori.trim(), null, {
          duration: 4000,
        })
      }

    }
  }

  salvaSuInvio(event, utente) {
    if (event.key === 'Enter') {
      this.salva(utente)
    } else {
      this.dirty = true
    }
  }

  salva(utente) {
    // console.log(event);
    let errori = ''

    if (!utente.odv && utente.email !== undefined && (!CustomValidators.isEmail(utente.email) || !isValidEmailPersonale(utente.email))) {
      errori += 'Email non valida. Verifica il formato e che non appartenga ai domini aziendali'
    }
    if (utente.mobile && !CustomValidators.isNumeroTelefonico(utente.mobile)) {
      errori += ' Numero Telefonico non valido'
    }
    if (utente.mobile === undefined && utente.email === undefined) {
      errori += 'Fornire almeno uno tra Numero Telefonico ed Email!'
    }
    if (errori.length === 0) {
      this.salvaUtente()
      this.dirty = false
    } else {
      this.snackBar.open(errori.trim(), null, {
        duration: 4000,
      })
    }
  }

  // modificabile(utente: User) {
  //  return (this.authService.getUser()?.su ||
  //    this.authService.getUser()?.amministratoreGestori && isMembroDiGruppi(this.authService.getUser(), utente.odvMembership));
  // }

  modificabile(utente: User) {
    return (this.authService.getUser()?.su || this.authService.getUser()?.amministratoreGestori)
  }

  editabile(utente: User) {
    return utente.id === this.idModificabile
  }

  puoCreareUtenti() {
    return this.authService.getUser()?.su || this.authService.getUser()?.amministratoreGestori
  }

  logout() {
    this.authService.logout()
    this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
  }

  custodiaAttiva() {
    return this.sessionData.configurazione?.custodiaIdentita
  }

  salvaUtente() {
    if (!this.utente.id) {
      // this.utente.userId = this.utente.email;
      // sterilizzo il pincode
      this.savingOrLoading = true
      this.utente.pinCode = undefined
      this.authService.creaUtente(this.utente)
        .then((esito: Esito) => {
          this.savingOrLoading = false
          if (ESITO_OK === esito.esito) {
            const utente = JSON.parse(esito.payload) as User
            const index = this.utenti.findIndex(ut => ut.id === +utente.id)
            if (index !== -1) {
              this.utenti.splice(index, 1, utente)
              this.refresh()
            } else {
              this.utenti.push(utente)
              this.refresh()
            }
            this.snackBar.open($localize `Utente creato`, null, {
              duration: 2000,
            })
          } else {
            this.snackBar.open($localize `Creazione utente fallita ` + JSON.parse(esito.payload), null, {
              duration: 2000,
            })
            this.openDialogModificaUtente(this.utente, JSON.parse(esito.payload))
          }
        }, (errore) => {
          this.savingOrLoading = false
          // console.log(errore);
        })
    } else {
      this.utente.pinCode = undefined
      this.savingOrLoading = true
      // this.utente.userId = this.utente.email;
      this.authService.aggiornaUtente(this.utente)
        .then((esito: Esito) => {
          this.savingOrLoading = false
          // console.log(utente);
          if (esito.esito !== ESITO_KO) {
            this.caricaGruppi(false)
            const messaggio = esito.esito === ESITO_OK ? 'aggiornato' :
              esito.esito === ESITO_UTENTE_DISABILITATO ? 'disabilitato' :
                esito.esito === ESITO_UTENTE_ELIMINATO ? 'eliminato' :
                  'aggiornato!'
            this.snackBar.open($localize `Utente ${messaggio}`, null, {
              duration: 2000,
            })
          } else {
            this.snackBar.open($localize `Aggiornamento utente fallito ` + esito.payload, null, {
              duration: 2000,
            })
          }

        })
        .catch((errore) => {
          this.savingOrLoading = false
          // console.log(errore);
          this.snackBar.open($localize `Aggiornamento utente fallito ` + errore, null, {
            duration: 2000,
          })
        })
    }
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
    if (number > 50) {
      this.navIsFixed = true
    } else if (this.navIsFixed && number < 10) {
      this.navIsFixed = false
    }
    // console.log("scroll:" + number);
  }

  add(event: MatChipInputEvent): void {
    const input: MatChipInput = event.chipInput;
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (this.matAutocomplete !== undefined && !this.matAutocomplete.isOpen) {
      const value = input.inputElement.value
      let selectedOdv = this.odvGroups.find((gruppo) => gruppo.nomeOdv == value)
      // Add our fruit
      if (value) {
        if (!this.utente.odvMembership) {
          this.utente.odvMembership = new Array<Odv>()
        }
        if (!selectedOdv) {
          selectedOdv = new Odv()
          selectedOdv.nomeOdv = value
        }
        this.utente.odvMembership.push(selectedOdv)
        this.salvaUtente()
      }

      // Reset the input value
      if (input) {
        input.inputElement.value = ''
      }

      this.odvCtrl.setValue(null)
    }
  }

  remove(odv: Odv, utente: User): void {
    const index = utente.odvMembership.indexOf(odv)
    if (index >= 0) {
      utente.odvMembership.splice(index, 1)
      this.utente = utente
      this.salvaUtente()
      // this.odvGroups.push(odv);
    }
  }

  odvselected(event): void {
    if (!this.utente.odvMembership) {
      this.utente.odvMembership = new Array<Odv>()
    }
    this.utente.odvMembership.push(event.option.value)
    this.salvaUtente()
    this.odvInput.nativeElement.value = ''
    this.odvCtrl.setValue(null)
    this.caricaGruppi(false)

    const self = this

    //
    //  this.odvInput.nativeElement.blur();
    setTimeout(a => {
      document.getElementById('input-nuovo-gruppo-' + this.utente.id).blur()
      // this.gruppoSelezionato = undefined;
    }, 500, [])
  }


  onGruppoSelected(gruppo: Odv) {
    this.gruppoSelezionato = gruppo
    console.log('selezionato gruppo ' + gruppo.nomeOdv)
    setTimeout(a => {
      document.getElementById('input-modifica-gruppo-' + gruppo.id).focus()
    }, 500, [])
  }

  onKeyDown(event) {
    if (event.key === 'Enter') {
      this.dirtyGroup = false
    } else {
      this.dirtyGroup = true
    }
  }

  onInputGroupFocus(utente: User) {
    this.utente = utente
    this.filteredOdvGroups = of(this.removeAlreadySelectedGroups())
  }

  onValueChange(event, gruppo: Odv, utente: User) {
    if (this.dirtyGroup) {
      this.dirtyGroup = false
      console.log(event.target.value)
      console.log(gruppo.nomeOdv)
      gruppo.nomeOdv = event.target.value
      console.log(JSON.stringify(utente.odvMembership))
      this.utente = utente
      this.salvaUtente()
      this.aggiornaEtichettaGruppoAltriUtenti(gruppo)
    }
    this.gruppoSelezionato = undefined
    //  this.odvInput.nativeElement.blur();
  }

  aggiornaEtichettaGruppoAltriUtenti(gruppo: Odv) {
    this.utenti.forEach(utente => {
      const tmpGroup = utente.odvMembership.find(group => group.id === gruppo.id)
      if (tmpGroup) {
        tmpGroup.nomeOdv = gruppo.nomeOdv
      }
    })
  }

  inviaEmailVerifica(utente: User) {
    this.authService.inviaEmailVerifica(utente).then
      (esito => {
        if (+esito.esito === ESITO_OK) {
          utente.emailVerificaInviata = true

        } else {
          this.snackBar.open($localize `Errore di invio dell\'email di verifica. ` + esito['payload'], null, {
            duration: 8000,
          })
        }
      })
      .catch(errore => {
        this.snackBar.open($localize `Errore di invio dell\'email di verifica ` + errore.toString(), null, {
          duration: 8000,
        })
      })
  }

  handleSuChange(user) {
    // se user.su è true allora debbo assicurarmi che user.suDatiNonSensibili sia false
    if (user.su) {
      user.suDatiNonSensibili = false
    }
  }

  handleSuDatiNonSensibiliChange(user) {
    // se user.suDatiNonSensibili è true allora debbo assicurarmi che user.su sia false
    if (user.suDatiNonSensibili) {
      user.su = false
    }
  }

}

