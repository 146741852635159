/* eslint-disable no-undef */
import { PinCode } from './../modello/pin-code'
import { LoginResponse } from './../modello/http-response-interfaces'
import { AuthService } from './../servizi/auth.service'
import { SessionData } from './../sessione/dati-sessione'
import { User } from './../modello/user'
import { Component, ElementRef, Inject, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core'
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'

import { ActivatedRoute, Data, Router } from '@angular/router'

import { Azienda } from '../modello/azienda'
import { Persona } from '../modello/persona'

import { Esito, ESITO_OK } from '../utility/esito'
import { getVersione, ServiziRest } from '../utility/utility'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

import { SegnalazioneService } from '../servizi/segnalazione.service'
import { CustomValidators } from '../validators/CustomValidators'
import { MatButton } from '@angular/material/button'
import { MatInput } from '@angular/material/input'
import { UserBehaviorTrackingService } from '../servizi/user-behavior-tracking.service'

@Component({
  selector: 'app-login-fallita-dialog',
  templateUrl: 'login-fallita-dialog.html',
})
export class LoginFallitaDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  pinCode: PinCode
  // tokenRichiestaPin: string;
  utenteConNuovoPin: User
  codiceFiscale: string
  testo: string
  utente: User
  loginForm: UntypedFormGroup
  cfForm: UntypedFormGroup
  // pincode: string;
  recuperaPINForm: UntypedFormGroup
  primoAccesso: boolean

  informativaAccettata = false

  passwordDimenticata = false
  textOrPassword = 'password'
  azienda: Azienda
  persona: Persona

  isRecuperoPin: boolean
  registrazioneConEmail = false

  tokenRichiestaPin: string

  @ViewChild('inputIdUtente') userId: ElementRef
  @ViewChild('loginButton', { static: false }) loginButton: MatButton
  @ViewChild('verifyOtpButton', { static: false }) verifyOtpButton: MatButton
  @ViewChild(MatInput) otpInput: MatInput

  isRegistrazione: boolean
  token: any
  mfa: string
  mfaToken: string
  imageSrc: any
  necessarioMFA: boolean
  tokenSessione: any
  forzaAutenticazioneLocale: boolean

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private segnalazioneService: SegnalazioneService,
    private sessionData: SessionData,
    private userBehaviorTracking: UserBehaviorTrackingService
  ) {
    this.utente = new User()
    this.pinCode = new PinCode()
    this.azienda = new Azienda()

    this.utente.pinCode = this.pinCode
    this.necessarioMFA = false
  }

  get idUtente() {
    return this.loginForm.get('userId')
  }

  get password() {
    return this.loginForm.get('password')
  }

  get configurazione() {
    return this.sessionData.configurazione
  }

  get versione(): string {
    return getVersione()
  }

  get modalitaPinUnico(): boolean {
    return this.sessionData.configurazione?.modalitaPinUnicoPerSegnalazione
  }

  get dominiPermessi() {
    return this.sessionData.configurazione.dominiAziendali
  }

  get captcha() {
    return this.sessionData.configurazione?.abilitaCaptcha
  }

  ngOnDestroy() {
    console.log('On Destroy Login page...')
  }



  ngOnInit() {
    this.userBehaviorTracking.startSession()
    this.authService.removeStoredCredentials()
    // estrae il parametro dalla url chiamato autologin
    // se presente effettua la login automatica
    const autologin = this.route.snapshot.queryParamMap.get('autologin')
    const loginfailed = this.route.snapshot.queryParamMap.get('loginfailed')
    if (autologin !== '' && autologin !== undefined && autologin !== null) {
      this.authService.setSession(JSON.parse(autologin))
      this.router.navigate(['/home'])
      return
    }
    if (loginfailed !== '' && loginfailed !== undefined && loginfailed !== null) {
      this.snackBar.open($localize`Accesso fallito`, null, {
        duration: 8000,
      })
    }
    this.route.data.subscribe((data: Data) => {
      // console.log(data)
      const esito = data['esito']
      if (esito.esito === ESITO_OK) {
        this.sessionData.configurazione = JSON.parse(esito.payload)[0]
        this.createForm()
        return this.sessionData.configurazione
      } else {
        this.snackBar.open(
          $localize`Errore nel recupero della configurazione. ` +
          esito['payload'],
          null,
          {
            duration: 8000,
          }
        )
      }
    })
    this.authService.removeStoredCredentials()
    this.testo = ''
    this.isRecuperoPin = false
  }

  ngAfterViewInit(): void {
    this.tokenRichiestaPin = this.route.snapshot.queryParamMap.get('ricpin')
    if (
      this.tokenRichiestaPin !== '' &&
      this.tokenRichiestaPin !== undefined &&
      this.tokenRichiestaPin !== null
    ) {
      this.generaNuovoPin()
    }
  }

  generaNuovoPin() {
    this.authService
      .rigeneraPin(this.tokenRichiestaPin)
      .then((esito) => {
        if (+esito.esito === ESITO_OK) {
          const user = JSON.parse(esito.payload) as User
          this.utenteConNuovoPin = user
          this.forzaAutenticazioneLocale = true
          this.primoAccesso = false
          this.toggleLoginGestore()
        } else {
          this.snackBar.open(
            $localize`Errore nel recupero del nuovo PIN. ` + esito['payload'],
            null,
            {
              duration: 10000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Errore nel recupero del nuovo PIN. ` + errore.toString(),
          null,
          {
            duration: 8000,
          }
        )
      })
  }

  copiaPin() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData(
        'text/plain',
        this.utenteConNuovoPin.pinCode.pincode
      )
      e.preventDefault()
      document.removeEventListener('copy', null)
    })
    document.execCommand('copy')
    this.loginForm.controls.password.setValue(null)
    if (
      this.loginForm.controls.userId.value !== this.utenteConNuovoPin?.userId
    ) {
      this.loginForm.controls.userId.setValue(null)
    }
  }

  backToMainLogin() {
    // console.log('Torno alla main login....');
    this.router.navigate(['/landing-page'], {
      queryParams: { initialize: true },
    })
  }

  toggleTextOrPassword() {
    this.textOrPassword =
      this.textOrPassword === 'password' ? 'text' : 'password'
    // console.log(this.textOrPassword);
  }

  keyDownFunction(event) {
    if (event.key === 'Enter' && this.loginForm.valid) {
      event.preventDefault()
      if (this.tokenSessione) {
        this.verifyOtpButton._elementRef.nativeElement.click()
      } else {
        this.loginButton._elementRef.nativeElement.click()
      }
    }
  }

  async login() {
    this.utente.userId = this.loginForm.controls.userId.value
    // console.log('utente: ' + this.utente);
    if (!this.utente.pinCode) {
      this.utente.pinCode = new PinCode()
    }
    this.utente.pinCode.pincode = this.loginForm.controls.password.value
    this.pinCode = this.loginForm.controls.password.value
    this.mfaToken = this.loginForm.controls.token.value

    const isBehaviorNormal = await this.userBehaviorTracking.sendBehaviorData()

    if (!isBehaviorNormal) {
      console.warn('Login non eseguito a causa di comportamento sospetto')
      return
    }

    // this.utente.email = this.loginForm.controls.idUtente.value;
    // this.utente.pinCode.pincode = this.loginForm.controls.password;

    this.authService
      .login(
        this.utente,
        JSON.stringify({}),
        this.mfaToken,
        this.mfa
      )
      .subscribe((esito: Esito) => {
        if (ESITO_OK === esito.esito) {
          const payload: LoginResponse = JSON.parse(esito.payload)
          if (payload.sso) {
            // Reindirizza l'intera pagina al tuo endpoint SSO
            const apiKey = 'HHFY6754GFR43KIO990JHYHG66jjK3344egfmlkUUY88887yhKLlkJHjcrfcdm'
            const userId = this.utente.userId

            window.location.href =
              `${ServiziRest.urlInitiateSSO}?x-api-key=${encodeURIComponent(apiKey)}&userid=${encodeURIComponent(userId)}`
            return
          }
          if (payload['qrCodeImage']) {
            console.log('Attivato MFA')
            // ho ricevuto l'immagine di un qr code in base64 la visualizzo nella pagina
            this.imageSrc = payload['qrCodeImage']
            this.mfa = undefined
          } else if (payload['mfa'] === 'MFA') {
            console.log('MFA attivo viene richiesto l\'invio del token')
            this.necessarioMFA = true
            this.tokenSessione = payload['token']
            setTimeout(() => {
              this.otpInput.focus()
            })
          } else if (payload.idToken != null) {
            //    Nuovo login dobbiamo memorizzare userPk, sessionId e ruolo
            // console.log('Rilevato nuovo login memorizzo sessionId e userPk');
            // console.log('response.idToken:' + payload.idToken);
            // console.log('utente:' + JSON.stringify(payload.user));
            this.authService.setSession(payload)
            this.router.navigate(['/home'])
            return
          } else {
            //  Il backend ha risposto con un codice di errore
            this.snackBar.open(
              $localize`Impossibile eseguire la login. Rivolgersi al supporto. ` +
              payload.answerCode,
              null,
              {
                duration: 8000,
              }
            )
          }
        } else {
          this.snackBar.open(esito.payload, null, {
            duration: 8000,
          })
        }
      }, ((err) => {
        // console.log('errore login:' + err);
        this.openDialogLoginFallita(err)
      }))
  }

  verifyOtp() {
    this.utente.userId = this.loginForm.controls.userId.value
    // console.log('utente: ' + this.utente);
    if (!this.utente.pinCode) {
      this.utente.pinCode = new PinCode()
    }
    this.utente.pinCode.pincode = this.loginForm.controls.password.value
    this.pinCode = this.loginForm.controls.password.value
    this.mfaToken = this.loginForm.controls.token.value
    if (this.mfa) {
      this.authService
        .login(
          this.utente,
          JSON.stringify({ }),
          this.mfaToken,
          this.mfa
        )
        .subscribe((esito: Esito) => {
          if (ESITO_OK === esito.esito) {
            const payload: LoginResponse = JSON.parse(esito.payload)
            if (payload.sso) {
              // Reindirizza l'intera pagina al tuo endpoint SSO
              const apiKey = 'HHFY6754GFR43KIO990JHYHG66jjK3344egfmlkUUY88887yhKLlkJHjcrfcdm'
              const userId = this.utente.userId

              window.location.href =
                `${ServiziRest.urlInitiateSSO}?x-api-key=${encodeURIComponent(apiKey)}&userid=${encodeURIComponent(userId)}`
              return
            }
            if (payload['qrCodeImage']) {
              console.log('Attivato MFA')
              // ho ricevuto l'immagine di un qr code in base64 la visualizzo nella pagina
              this.imageSrc = payload['qrCodeImage']
              this.mfa = undefined
            } else if (payload['mfa'] === 'MFA') {
              console.log('MFA attivo viene richiesto l\'invio del token')
              this.necessarioMFA = true
              this.tokenSessione = payload['token']
              setTimeout(() => {
                this.otpInput.focus()
              })
            } else if (payload.idToken != null) {
              //    Nuovo login dobbiamo memorizzare userPk, sessionId e ruolo
              // console.log('Rilevato nuovo login memorizzo sessionId e userPk');
              // console.log('response.idToken:' + payload.idToken);
              // console.log('utente:' + JSON.stringify(payload.user));
              this.authService.setSession(payload)
              this.router.navigate(['/home'])
              return
            } else {
              //  Il backend ha risposto con un codice di errore
              this.snackBar.open(
                $localize`Impossibile eseguire la login. Rivolgersi al supporto. ` +
                payload.answerCode,
                null,
                {
                  duration: 8000,
                }
              )
            }
          } else {
            this.snackBar.open(esito.payload, null, {
              duration: 8000,
            })
          }
        }, ((err) => {
          // console.log('errore login:' + err);
          this.openDialogLoginFallita(err)
        }))
    } else {
      this.authService
        .verifyOtp(this.utente, this.mfaToken, this.tokenSessione)
        .then((esito: Esito) => {
          if (ESITO_OK === esito.esito) {
            const payload: LoginResponse = JSON.parse(esito.payload)
            if (payload.sso) {
              // Reindirizza l'intera pagina al tuo endpoint SSO
              const apiKey = 'HHFY6754GFR43KIO990JHYHG66jjK3344egfmlkUUY88887yhKLlkJHjcrfcdm'
              const userId = this.utente.userId

              window.location.href =
                `${ServiziRest.urlInitiateSSO}?x-api-key=${encodeURIComponent(apiKey)}&userid=${encodeURIComponent(userId)}`
              return
            }
            if (payload.idToken != null) {
              this.authService.setSession(payload)
              this.router.navigate(['/home'])
            } else {
              //  Il backend ha risposto con un codice di errore
              this.snackBar.open(
                $localize`Impossibile eseguire la login. Rivolgersi al supporto. ` +
                payload.answerCode,
                null,
                {
                  duration: 8000,
                }
              )
            }
          } else {
            const codiceErrore = esito.esito
            if (codiceErrore === Esito.ERRORE_MFA) {
              this.snackBar.open(
                // eslint-disable-next-line max-len
                $localize`La verifica a doppio fattore è fallita, assicurati di digitare correttamente il numero presente sull'APP. Se il problema persiste contatta il supporto.`,
                null,
                {
                  duration: 8000,
                }
              )
            } else {
              this.snackBar.open(esito.payload, null, {
                duration: 8000,
              })
            }
          }
        })
        .catch((err) => {
          // console.log('errore login:' + err);
          this.openDialogLoginFallita(err)
        })
    }
  }

  openDialogLoginFallita(err?: string): void {
    const dialogRef = this.dialog.open(LoginFallitaDialogComponent, {
      width: '500px',
      data: { err },
      disableClose: true,
      hasBackdrop: true,
    })

    dialogRef.afterClosed().subscribe(() => {
      // console.log('The dialog was closed');
    })
  }

  toggleLogin() {
    this.utente = new User()
    this.pinCode = new PinCode()
    this.azienda = new Azienda()

    this.utente.pinCode = this.pinCode

    this.informativaAccettata = false
    this.testo = ''
    this.createForm()
  }

  onCaptchaResponse(event) {
    console.log('EVENT:')
    console.log(event)
  }

  private createForm() {
    this.loginForm = new UntypedFormGroup({
      userId: new UntypedFormControl(
        '',
        this.modalitaPinUnico ? [] : Validators.required
      ),
      password: new UntypedFormControl(
        '',
        Validators.compose([
          this.autenticazioneLocale ? Validators.required : null,
          this.autenticazioneLocale ? CustomValidators.lunghezzaMinimaPassword(
            this.sessionData.configurazione?.lunghezzaMinimaPassword
          ) : null,
          this.autenticazioneLocale ? CustomValidators.maiuscolePassword(
            this.sessionData.configurazione?.maiuscolePassword
          ) : null,
          this.autenticazioneLocale ? CustomValidators.minuscolePassword(
            this.sessionData.configurazione?.minuscolePassword
          ) : null,
          this.autenticazioneLocale ? CustomValidators.numericiPassword(
            this.sessionData.configurazione?.numeriPassword
          ) : null,
          this.autenticazioneLocale ? CustomValidators.specialiPassword(
            this.sessionData.configurazione?.caratteriSpecialiPassword
          ) : null,
        ])
      ),
      token: new UntypedFormControl('', []),
    })
  }

  toggleLoginGestore() {
    this.loginForm.controls['userId'].setValidators([Validators.required])
    if (this.autenticazioneLocale) {
      this.loginForm.controls['password'].setValidators([
        this.autenticazioneLocale ? Validators.required : undefined,
        this.autenticazioneLocale ? CustomValidators.lunghezzaMinimaPassword(
          this.sessionData.configurazione.lunghezzaMinimaPassword
        ) : undefined,
        this.autenticazioneLocale ? CustomValidators.maiuscolePassword(
          this.sessionData.configurazione?.maiuscolePassword
        ) : undefined,
        this.autenticazioneLocale ? CustomValidators.minuscolePassword(
          this.sessionData.configurazione?.minuscolePassword
        ) : undefined,
        this.autenticazioneLocale ? CustomValidators.numericiPassword(
          this.sessionData.configurazione?.numeriPassword
        ) : undefined,
        this.autenticazioneLocale ? CustomValidators.specialiPassword(
          this.sessionData.configurazione?.caratteriSpecialiPassword
        ) : undefined,
      ])
    } else {
      // escludi il controllo della password
      this.loginForm.controls['password'].setValidators([])
    }

    this.loginForm.reset()
  }

  apriRegistrazione() {
    this.router.navigate(['registrazione'])
  }

  apriRecuperoPassword() {
    this.router.navigate(['recupero-password'])
  }

  get emailIsRequired() {
    return (
      this.isRecuperoPin ||
      (this.isRegistrazione &&
        this.sessionData.configurazione.emailSegnalanteObbligatoria)
    )
  }
  getLogoCliente() {
    return this.sessionData.logoCliente !== undefined &&
      this.sessionData.logoCliente.base64 !== undefined
      ? this.sessionData.logoCliente.base64
      : this.getLogoSecure()
  }

  getLogoSecure() {
    return this.sessionData.logoSecure !== undefined &&
      this.sessionData.logoSecure.base64 !== undefined
      ? this.sessionData.logoSecure.base64
      : '/assets/img/logo-secure.png'
  }

  onClickLogin(event) {
    this.login()
  }

  get mfaAttivo() {
    return this.sessionData.configurazione?.abilitaMFA
  }

  attivaMFA() {
    const event = new MouseEvent('click', { bubbles: true })
    this.mfa = '1'
    if (this.tokenSessione) {
      this.verifyOtpButton._elementRef.nativeElement.dispatchEvent(event)
    } else {
      this.loginButton._elementRef.nativeElement.dispatchEvent(event)
    }
  }

  get singleRow() {
    return window.innerWidth > 600
  }
  get autenticazioneLocale() {
    return !this.configurazione.autenticazionePredefinitaSSO || this.forzaAutenticazioneLocale
  }

  get autenticazioneSecureAbilitata() {
    return this.configurazione.autenticazioneSecureAbilitata
  }

  get ssoAbilitata() {
    return this.configurazione.autenticazionePredefinitaSSO
  }

  doForzaAutenticazioneLocale() {
    this.forzaAutenticazioneLocale = true
    // aggiorna obbligatorietà campi del form
    this.toggleLoginGestore()
  }

  doForzaAutenticazioneSSO() {
    this.forzaAutenticazioneLocale = false
    // aggiorna obbligatorietà campi del form
    this.toggleLoginGestore()
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    this.userBehaviorTracking.trackMouseMove(e)
  }

  @HostListener('document:keypress', ['$event'])
  onKeyPress(e: KeyboardEvent) {
    this.userBehaviorTracking.trackKeyStroke(e)
  }

  @HostListener('document:click', ['$event'])
  onClick(e: MouseEvent) {
    this.userBehaviorTracking.trackClick(e)
  }

  @HostListener('document:touchend', ['$event'])
  onTouch(e: TouchEvent) {
    this.userBehaviorTracking.trackClick(e)
  }
}


