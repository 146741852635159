import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Segnalazione } from '../../modello/segnalazione';
import { SegnalazioneService } from '../../servizi/segnalazione.service';
import { ESITO_OK } from '../../utility/esito';

@Component({
  selector: 'app-pianificazione-eliminazione-segnalazione',
  templateUrl: './pianificazione-eliminazione-segnalazione.component.html',
  styleUrls: ['./pianificazione-eliminazione-segnalazione.component.scss']
})
export class PianificazioneEliminazioneSegnalazioneComponent {

  // input di una segnalazione
  @Input() segnalazione: Segnalazione
  @Output() recuperaDatiArchiviazione = new EventEmitter<number>()
  cancella: boolean = false
  dataCancellazione: Date = null

  constructor(private segnalazioneService: SegnalazioneService, private snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.cancella = this.segnalazione?.eliminareSegnalazione
    this.dataCancellazione = this.segnalazione?.dataDecisioneEliminazione
  }

  checkChanged(event) {
    this.cancella = event.checked
    if (this.cancella) {
      this.dataCancellazione = new Date()
    } else {
      this.dataCancellazione = null
    }
    this.save()
  }
  save() {
    this.segnalazioneService.aggiornaPianificazioneCancellazione(
      +this.segnalazione.id,
      this.cancella
    ).subscribe(
      esito => {
        if (esito && ESITO_OK == esito.esito) {
          this.segnalazione.eliminareSegnalazione = this.cancella
          this.segnalazione.dataDecisioneEliminazione = this.dataCancellazione
          this.recuperaDatiArchiviazione.emit(+this.segnalazione.id)
        } else {
          console.error(esito.payload)
          this.snackBar.open(
            $localize`Aggiornamento pianificazione cancellazione fallito.`,
            null,
            {
              duration: 8000,
            }
          )
        }
      }

      , error => {
        console.error(error)
        this.snackBar.open(
          $localize`Aggiornamento pianificazione cancellazione fallito.`,
          null,
          {
            duration: 8000,
          }
        )
      })
  }
}
