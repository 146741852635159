import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { VersioneContenuto } from '../modello/versione-contenuto'
import { Esito } from '../utility/esito'
import { ServiziRest } from '../utility/utility'

@Injectable({
  providedIn: 'root'
})
export class ContenutiService {
  // crea subject per notificare la disponibilità di una entità VersioneContenuto aggiornata
  public versioneContenutoSubject = new Subject<VersioneContenuto>()

  constructor(private http: HttpClient) { }

  getVersioneContenuto(tipo: string, sottotipo: string, lingua: string): Observable<Esito> {
    return this.http.get<Esito>(`${ServiziRest.urlContenutoGet}/${tipo?.trim()}/${sottotipo?.trim()}/${lingua?.trim()}`)
  }

  downloadFile(hash: string): Observable<Blob> {
    if (!hash || hash === '' || hash === 'null') {
      return null
    }
    const url = `${ServiziRest.urlFileDownload}/${hash}`
    return this.http.get(url, { responseType: 'blob' })
  }

  deleteVersioneContenuto(id: number): Observable<Esito> {
    return this.http.delete<Esito>(`${ServiziRest.urlContenutoProtected}/versione-contenuto/${id}`)
  }

  deleteFileVersioneContenuto(id: number): Observable<Esito> {
    return this.http.delete<Esito>(`${ServiziRest.urlContenutoProtected}/file/${id}`)
  }

  updateVersioneContenuto( versioneContenuto: VersioneContenuto, file?: File): Observable<Esito> {
    const formData = new FormData()

    // Aggiunge i campi dell'oggetto versioneContenuto alla richiesta
    Object.keys(versioneContenuto).forEach(key => {
      formData.append(key, versioneContenuto[key])
    })

    // Aggiunge il file alla richiesta, se fornito
    if (file) {
      formData.append('file', file, file.name)
    }

    // eslint-disable-next-line max-len
    return this.http.put<Esito>(`${ServiziRest.urlContenutoProtected}/${versioneContenuto.contenuto.tipo}/${versioneContenuto.contenuto.sottotipo}/${versioneContenuto.lingua}`, formData)
  }
}
