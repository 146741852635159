<h1 mat-dialog-title>Nuovo Cliente</h1>
<div mat-dialog-content>
  <form [formGroup]="form" novalidate>
    <mat-form-field>
      <mat-label i18n>Nome Nuovo Cliente</mat-label>
      <input matInput formControlName="newClientName">
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n>Cliente Esistente (Opzionale)</mat-label>
      <mat-select formControlName="existingClientName">
        <mat-option *ngFor="let client of existingClients" [value]="client">
          {{ client }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n>IP Frontend</mat-label>
      <input matInput formControlName="ipaddress">
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="flex-row flex-100">
  <button mat-button i18n (click)="close()">Annulla</button>
  <!-- bottone abilitato solo se ipaddress e newClientName sono valorizzati -->
  <button mat-button i18n [mat-dialog-close]="form.value"
  [disabled]="!form.value.ipaddress || !form.value.newClientName"
  cdkFocusInitial>Crea ambiente per nuovo cliente</button>
</div>
