import * as moment from 'moment'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'ora',
  pure: false
})
export class OraPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value != null) {
      value = moment(value).format('HH:mm')
    }
    return value
  }

}
