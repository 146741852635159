import { User } from './user'
import { Segnalazione } from './segnalazione'
import { Chat } from './chat'
import { DestinatariPerTipologia } from './destinatari-per-tipologia'
import { AutorizzazioneOscuramento } from './autorizzazione-oscuramento'
import { OscuramentoDatiManifestamenteNonNecessari } from './oscuramento-dati-manifestamente-non-necessari'

    export class Odv {

        id: number

        nomeOdv: string

        utenti?: User[]

        segnalazioni?: Segnalazione[]

        partecipaAlleChat: Chat[]

        chatModerate: Chat[]

        predefinito: boolean

        destinatariPerTipologia: DestinatariPerTipologia[]

        oscuramentiDatiManifestamenteNonNecessari: OscuramentoDatiManifestamenteNonNecessari[]

        autorizzazioniOscuramento: AutorizzazioneOscuramento[]

        public toString() {
            return this.nomeOdv
        }

    }
