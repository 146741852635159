import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-conferma-scondivisione-segnalazione-dialog',
  templateUrl: 'conferma-scondivisione-segnalazione.html',
})
export class ConfermaScondivisioneSegnalazioneDialogComponent {
  isLavorazione = false
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any) {
      this.isLavorazione = data.isLavorazione
    }
}
