import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: "root",
})
export class CustodeRedirectGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Controlla il profilo dell'utente qui
    const isCustode = this.checkCustodeProfile()

    if (isCustode && !this.checkOdvProfile()) {
      // Redirige l'utente al percorso 'richieste-sblocco'
      this.router.navigate(['/richieste-sblocco'])
      return false
    }

    return true
  }
  // Implementa la logica per verificare se l'utente ha il profilo "custode"
  private checkCustodeProfile(): boolean {
    if (this.authService.getUser()?.custode) {
      return true
    }
    return false
  }

  private checkOdvProfile(): boolean {
    if (this.authService.getUser()?.odv) {
      return true
    }
    return false
  }
}
