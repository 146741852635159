import {HostListener} from '@angular/core'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
@Injectable()
export abstract class ComponentCanDeactivate {

  abstract  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true
        }
    }
}
