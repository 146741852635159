import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { Consenso } from '../../modello/consenso'
import { Segnalazione } from '../../modello/segnalazione'
import { SegnalazioneService } from '../../servizi/segnalazione.service'
import { ESITO_OK } from '../../utility/esito'
import { DataOraPipe } from '../../pipe/data-ora.pipe'
import { decodeHtml, decodificaStatoRichiestaConsenso, openDialogGenerica, SottoTipoContenuto, TipoContenuto } from '../../utility/utility'
import { cognomeSegnalante, isMembroDiGruppo, isSegnalante, isUtenteMembrodiOdvSceltoDaSegnalante,
  nomeSegnalante, odvUtenteChePossonoFareRichiestaDiConsensoAlSegnalante } from '../../utility/helper-segnalazioni'
import { AuthService } from '../../servizi/auth.service'
import { RichiestaConsensoSegnalanteDialogComponent }
from '../../elenco-segnalazioni/consensi-segnalante/dialog-richiesta-consenso-segnalante-component'
import { MatSort, Sort } from '@angular/material/sort'
import { SessionData } from '../../sessione/dati-sessione'
import { VisualizzatorePdfDialogComponent } from '../visualizzatore-pdf/visualizzatore-pdf.component'
import { TipoDocumento } from '../../modello/documento'
import { Traduzioni } from '../../modello/traduzioni'
import { ContenutiService } from '../../servizi/contenuti.service'

@Component({
  selector: 'app-elenco-consensi-segnalante',
  templateUrl: './elenco-consensi-segnalante.component.html',
  styleUrls: ['./elenco-consensi-segnalante.component.scss']
})
export class ElencoConsensiSegnalanteComponent implements AfterViewInit {
  @Input()
  segnalazione: Segnalazione

  @ViewChild(MatSort) sort: MatSort

  innerColumns = ['id', 'stato', 'dataRichiesta', 'dataRisposta', 'odvRichiedente', 'azioni']
  innerDisplayedColumns = ['ID', 'Stato Consenso', 'Data Richiesta',
    'Data Risposta', 'Richiedente', 'Azioni']
  constructor(private dialog: MatDialog, private dialog2: MatDialog, private segnalazioniService: SegnalazioneService,
    private snackBar: MatSnackBar, private authService: AuthService, private sessionData: SessionData,
    private contenutiService: ContenutiService) { }

  ngAfterViewInit(): void {
    this.segnalazione['matData'].sort = this.sort
  }

  getDataSource(segnalazione: Segnalazione) {
    if (segnalazione['matData'] === undefined) {
      segnalazione['matData'] = new MatTableDataSource(segnalazione.consensi?.filter(consenso => consenso.stato === Consenso.STATI.CONCESSO
        || consenso.stato === Consenso.STATI.NEGATO || consenso.stato === Consenso.STATI.RICHIESTA_INOLTRATA))
    }

    return segnalazione['matData']
  }

  consensi(segnalazione) {
    const consensi = this.getDataSource(segnalazione).data
    // ordina per data risposta decrescente
    return consensi.sort((a, b) => {
      if (a.dataRisposta > b.dataRisposta) {
        return -1
      }
      if (a.dataRisposta < b.dataRisposta) {
        return 1
      }
      return 0
    })
  }


  getDatoInnerTable(consenso: Consenso, colonnaConsenso) {
    const dataOraPipe = new DataOraPipe()
    switch (colonnaConsenso) {
      case 'dataRichiesta':
        return dataOraPipe.transform(consenso[colonnaConsenso])
        break
      case 'dataRisposta':
        return dataOraPipe.transform(consenso[colonnaConsenso])
        break
      case 'stato':
        return decodificaStatoRichiestaConsenso(+consenso.stato)
        break
      case 'richiedente':
        return consenso.odvRichiedente?.odvDestinatario ? consenso.odvRichiedente.odvDestinatario.nomeOdv : consenso.richiedente.email
        break
      default:
        return consenso[colonnaConsenso]
    }
    // innerColumn === 'data' ? (element[innerColumn] | date: 'dd/MM/yyyy') : element[innerColumn]
  }

  nomeSegnalante(consenso: Consenso) {
    if (isSegnalante(this.segnalazione, this.authService.getUser())) {
      return this.segnalazione.segnalante.nome
    }
    return nomeSegnalante(consenso, this.sessionData.configurazione.custodiaIdentita)
  }

  cognomeSegnalante(consenso: Consenso) {
    if (isSegnalante(this.segnalazione, this.authService.getUser())) {
      return this.segnalazione.segnalante.cognome
    }
    return cognomeSegnalante(consenso, this.sessionData.configurazione.custodiaIdentita)
  }

  puoAggiungereRichiesta(segnalazione: Segnalazione) {
    if (segnalazione.consensi &&
      isUtenteMembrodiOdvSceltoDaSegnalante(segnalazione, this.authService.getUser())) {
      const odvChePossonoFareRichiesta = odvUtenteChePossonoFareRichiestaDiConsensoAlSegnalante(this.authService.getUser(), segnalazione)
      return odvChePossonoFareRichiesta !== undefined && odvChePossonoFareRichiesta.length > 0
    }
    return false
  }

  puoEditareRichiesta(consenso: Consenso, segnalazione: Segnalazione) {
    return consenso.stato === Consenso.STATI.ATTESA_INOLTRO &&
      isUtenteMembrodiOdvSceltoDaSegnalante(segnalazione, this.authService.getUser())
  }

  puoInviareRichiesta(consenso: Consenso, segnalazione: Segnalazione) {
    return consenso.stato === Consenso.STATI.ATTESA_INOLTRO &&
      isUtenteMembrodiOdvSceltoDaSegnalante(segnalazione, this.authService.getUser())
  }

  puoAnnullareRichiesta(consenso: Consenso, segnalazione: Segnalazione) {
    return (consenso.stato === Consenso.STATI.ATTESA_INOLTRO || consenso.stato === Consenso.STATI.RICHIESTA_INOLTRATA)
      && isUtenteMembrodiOdvSceltoDaSegnalante(segnalazione, this.authService.getUser())
  }

  puoAutorizzareRichiesta(consenso: Consenso, segnalazione: Segnalazione) {
    return isSegnalante(segnalazione, this.authService.getUser()) && consenso.stato !== Consenso.STATI.CONCESSO && consenso.attivo
  }

  puoRifiutareRichiesta(consenso: Consenso, segnalazione: Segnalazione) {
    return isSegnalante(segnalazione, this.authService.getUser()) && consenso.stato !== Consenso.STATI.NEGATO && consenso.attivo
  }

  puoConsultareInformativa() {
    const segnalante = isSegnalante(this.segnalazione, this.authService.getUser())
    return segnalante
  }

  openDialogConsultazioneInformativa(consenso: Consenso) {
      this.contenutiService
      .getVersioneContenuto(TipoContenuto.DOCUMENTO,
        SottoTipoContenuto.RICHIESTA_CONSENSO_AL_SEGNALANTE,
        this.codiceLingua)
      .subscribe(esito => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Traduzioni
          this.sessionData.configurazione.testoRichiestaConsensoSegnalante = decodeHtml(temp.testo)

          const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
            width: '100vw',
            maxWidth: '1024px',
            data: {
              src: null,
              testo: this.sessionData.configurazione.testoRichiestaConsensoSegnalante,
              titolo: $localize`Richiesta consenso`,
              tipoDocumento: null,
              tipo: null,
              sottotipo: null,
              lingua: this.codiceLingua,
              bypassAuth: true,
              canDownload: true,
              canPrint: true,
            },
          })
        } else {
          this.snackBar.open($localize `Recupero Testo Richiesta Consenso Fallito. Errore: ` + esito.descrizioneEsito, null, {
            duration: 4000,
          })
        }
      })
    }



  creaModificaRichiestaConsenso(segnalazione: Segnalazione, consenso?: Consenso) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)

    const dialogRef = this.dialog.open(RichiestaConsensoSegnalanteDialogComponent, {
      width: '600px',
      data: {
        segnalante: segnalazione.segnalante,
        consenso: consenso,
        diritti: segnalazione.dirittiSegnalazioneOdv.filter
          (diritto => isMembroDiGruppo(this.authService.getUser(), diritto.odvDestinatario))
      },
      disableClose: true,
      hasBackdrop: true
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result !== undefined) {
        const cons = result.data as Consenso
        cons.segnalazione = new Segnalazione()
        cons.segnalazione.id = segnalazione.id
        this.salvaRichiestaConsenso(result.data, segnalazione)
      }
    })
  }

  salvaRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.segnalazioniService.salvaConsenso(consenso)
      .then(esito => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Consenso
          const segna = this.sessionData.segnalazioni.find(s => +s.id === +segnalazione.id)
          const consensi = segna.consensi
          const indice = consensi.findIndex(c => c.id === temp.id)
          if (indice > -1) {
            consensi.splice(indice, 1, temp)
          } else {
            consensi.push(temp)
          }
          this.refresh()
          // document.getElementById('button-' + segnalazione.id).click();
          this.snackBar.open($localize `Salvataggio Consenso Eseguito`, null, {
            duration: 4000,
          })
        } else {
          this.snackBar.open($localize `Salvataggio Consenso Fallito. Errore: ` + esito.descrizioneEsito, null, {
            duration: 4000,
          })
        }

      })
      .catch(errore => {
        this.snackBar.open($localize `Salvataggio Consenso Fallito. Errore: ` + errore, null, {
          duration: 4000,
        })
      })
  }

  annullaRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.segnalazioniService.annullaRichiestaConsenso(consenso.id)
      .then(esito => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Consenso
          const consensi = this.sessionData.getSegnalazioneSelezionata().consensi
          consensi.splice(consensi.findIndex(cons => +cons.id === +temp.id), 1, temp)
          this.refresh()
          // document.getElementById('button-' + segnalazione.id).click();
          this.snackBar.open($localize `Annullamento Richiesta di Consenso Eseguito`, null, {
            duration: 4000,
          })
        } else {
          this.snackBar.open($localize `Annullamento Richiesta di Consenso Fallito. Errore: ` + esito.descrizioneEsito, null, {
            duration: 4000,
          })
        }

      })
      .catch(errore => {
        this.snackBar.open($localize `Annullamento Richiesta di Consenso Fallito. Errore: ` + errore, null, {
          duration: 4000,
        })
      })
  }

  autorizzaRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.segnalazioniService.autorizzaConsenso(consenso.id)
      .then(esito => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Consenso
          const consensi = this.sessionData.getSegnalazioneSelezionata().consensi
          if (consenso.stato === Consenso.STATI.RICHIESTA_INOLTRATA) {
            consensi.splice(consensi.findIndex(cons => +cons.id === +temp.id), 1, temp)
          } else {
            consensi.find(con => con.id === consenso.id).attivo = false
            this.sessionData.getSegnalazioneSelezionata().consensi = [temp, ...consensi]
          }
          this.refresh()
          // document.getElementById('button-' + segnalazione.id).click();
          this.snackBar.open($localize `Autorizzazione Richiesta di Consenso Eseguito`, null, {
            duration: 4000,
          })
          this.segnalazioniService.notificaConsensoAggiornato.next(consenso)
        } else {
          this.snackBar.open($localize `Autorizzazione Richiesta di Consenso Fallito. Errore: ` + esito.descrizioneEsito, null, {
            duration: 4000,
          })
        }

      })
      .catch(errore => {
        this.snackBar.open($localize `Annullamento Richiesta di Consenso Fallito. Errore: ` + errore, null, {
          duration: 4000,
        })
      })
  }


  respingiRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.segnalazioniService.negaConsenso(consenso.id)
      .then(esito => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Consenso
          const consensi = this.sessionData.getSegnalazioneSelezionata().consensi
          if (consenso.stato === Consenso.STATI.RICHIESTA_INOLTRATA) {
            consensi.splice(consensi.findIndex(cons => +cons.id === +temp.id), 1, temp)
          } else {
            consensi.find(con => con.id === consenso.id).attivo = false
            this.sessionData.getSegnalazioneSelezionata().consensi = [temp, ...consensi]
          }
          this.refresh()
          // document.getElementById('button-' + segnalazione.id).click();
          this.snackBar.open($localize `Rifiuto Richiesta di Consenso Eseguito`, null, {
            duration: 4000,
          })
          this.segnalazioniService.notificaConsensoAggiornato.next(consenso)
        } else {
          this.snackBar.open($localize `Rifiuto Richiesta di Consenso Fallito. Errore: ` + esito.descrizioneEsito, null, {
            duration: 4000,
          })
        }

      })
      .catch(errore => {
        this.snackBar.open($localize `Annullamento Richiesta di Consenso Fallito. Errore: ` + errore, null, {
          duration: 4000,
        })
      })
  }

  modificaRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.creaModificaRichiestaConsenso(segnalazione, consenso)
  }

  openDialogConfermaInvioRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione): void {
    openDialogGenerica(this.dialog, $localize `Invio Richiesta di Consenso`,
      '', $localize `Confermi di voler inviare la richiesta al segnalante?`,
      () => this.inviaRichiestaConsenso(consenso, segnalazione),
      () => { })
  }

  inviaRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.segnalazioniService.inoltraConsenso(consenso.id)
      .then(esito => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Consenso
          const consensi = this.sessionData.getSegnalazioneSelezionata().consensi
          consensi.splice(consensi.findIndex(cons => +cons.id === +temp.id), 1, temp)
          this.refresh()
          // document.getElementById('button-' + segnalazione.id).click();
          this.snackBar.open($localize `Inoltro Consenso Eseguito`, null, {
            duration: 4000,
          })
        } else {
          this.snackBar.open($localize `Inoltro Consenso Fallito. Errore: ` + esito.descrizioneEsito, null, {
            duration: 4000,
          })
        }

      })
      .catch(errore => {
        this.snackBar.open($localize `Inoltro Consenso Fallito. Errore: ` + errore, null, {
          duration: 4000,
        })
      })
  }

  mostraInformativa() {
    const dialogRef = this.dialog2.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.informativaTrattamentoDocumento,
        testo: this.sessionData.configurazione.informativaTrattamento, titolo: $localize `Informativa Privacy`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.INFORMATIVA_SUL_TRATTAMENTO_DEI_DATI_PERSONALI,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: true,
        canPrint: true,
      }
    })
  }

  get codiceLingua() {
    // ricava il codice della lingua corrente dalla url
    return window.location.href.split('/')[3]
  }

  openDialogConfermaAzioneConsenso(autorizza: boolean, consenso: Consenso, segnalazione: Segnalazione): void {
    this.contenutiService
    .getVersioneContenuto(TipoContenuto.DOCUMENTO,
      SottoTipoContenuto.RICHIESTA_CONSENSO_AL_SEGNALANTE,
      this.codiceLingua)
    .subscribe(esito => {
      if (ESITO_OK === esito.esito) {
        const temp = JSON.parse(esito.payload) as Traduzioni
        this.sessionData.configurazione.testoRichiestaConsensoSegnalante = temp.testo

        openDialogGenerica(this.dialog,
          autorizza ? $localize `Autorizza Consenso` : $localize `Nega Consenso`,
          decodeHtml(this.sessionData.configurazione.testoRichiestaConsensoSegnalante),
          autorizza ? $localize `Confermi di voler autorizzare il consenso richiesto?` :
          $localize `Confermi di voler negare il consenso richiesto?`,
          autorizza ? () => this.autorizzaRichiestaConsenso(consenso, segnalazione) :
          () => this.respingiRichiestaConsenso(consenso, segnalazione),
          () => { },
          autorizza ? $localize `Visualizza Informativa` : '',
          autorizza ? () => this.mostraInformativa() : () => { },
           window.innerWidth < 600 ? '100%' : '50%')
      } else {
        this.snackBar.open($localize `Recupero Testo Richiesta Consenso Fallito. Errore: ` + esito.descrizioneEsito, null, {
          duration: 4000,
        })
      }
    })
  }

  refresh() {
    this.segnalazione['matData'] = new MatTableDataSource(this.segnalazione.consensi)
    // this.sort.sort(({ id: 'id', start: 'desc' }) as MatSortable);
    this.segnalazione['matData'].sort = this.sort
    const sortState: Sort = { active: 'id', direction: 'desc' }
    this.sort.active = sortState.active
    this.sort.direction = sortState.direction
    this.sort.sortChange.emit(sortState)
  }

  localized(innerDisplayedColumn) {
    // ['ID', 'Stato Consenso', 'Data Richiesta', 'Data Risposta', 'Nome', 'Cognome', 'Richiedente', 'Azioni']
    switch (innerDisplayedColumn) {
      case 'ID':
        return $localize `ID`
        break
      case 'Stato Consenso':
        return $localize `Stato Consenso`
        break
      case 'Data Richiesta':
        return $localize `Data Richiesta`
        break
      case 'Data Risposta':
        return $localize `Data Risposta`
        break
      case 'Nome':
        return $localize `Nome`
        break
      case 'Cognome':
        return $localize `Cognome`
        break
      case 'Richiedente':
        return $localize `Richiedente`
        break
      case 'Azioni':
        return $localize `Azioni`
        break

      default:
        return innerDisplayedColumn
        break

    }

  }

}
