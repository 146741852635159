// idp.component.ts
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IdentityProvider, IdpService } from '../../servizi/idp.service';
import { ESITO_OK } from '../../utility/esito';
import { openDialogGenerica } from '../../utility/utility';
import { IdpEditDialogComponent } from './idp-edit-dialog/idp-edit-dialog.component';


@Component({
  selector: 'app-idp',
  templateUrl: './idp.component.html',
  styleUrls: ['./idp.component.scss']
})
export class IdpComponent implements OnInit {
  idps: IdentityProvider[] = [];
  idpName: string = '';
  idpCertificate: string = '';
  idpEntryPoint: string = '';
  idpIssuer: string = '';
  idpCallbackUrl: string = '/public/api/v1/sso/acs';
  constructor(private idpService: IdpService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,) { }

  ngOnInit() {
    this.getIdps();
  }

  getIdps(): void {
    this.idpService.getIdps()
      .subscribe(idps => this.idps = idps);
  }

  add(name: string, certificate: string): void {
    this.idpService.addIdp({ name, certificate } as IdentityProvider)
      .subscribe(idp => this.idps.push(idp));
  }

  delete(idp: IdentityProvider): void {
    this.idps = this.idps.filter(h => h !== idp);
    this.idpService.deleteIdp(idp.id).subscribe();
  }

  // Aggiungi i metodi per l'update e altre operazioni CRUD qui...
  editIdp(idp: IdentityProvider): void {
    const dialogRef = this.dialog.open(IdpEditDialogComponent, {
      width: '50vw',
      data: { ...idp } // pass a copy of idp object
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.idpService.updateIdp(result).subscribe((esito) => {
          if (esito.esito === ESITO_OK) {
            const idp = JSON.parse(esito.payload) as IdentityProvider;
            // Replace the old IdP data with the updated one in the local array
            const index = this.idps.findIndex(i => i.id === idp.id);
            if (index > -1) {
              this.idps[index] = idp;
            }
          } else {
            this.snackBar.open($localize`Aggiornamento fallito!`, $localize`Chiudi`, { duration: 8000 });
          }
        });
      }
    });
  }

  isValidCertificate(certificate: string): boolean {
    const trimmedCert = certificate.trim();
    return trimmedCert.startsWith("-----BEGIN CERTIFICATE-----") && trimmedCert.endsWith("-----END CERTIFICATE-----");
  }

  isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }

  confirmDeletion(idp: any): void {
    openDialogGenerica(
      this.dialog,
      $localize`Conferma cancellazione`,
      $localize`Stai per rimuovere l'Identity Provider: ${idp.name}`,
      $localize`Vuoi procedere con la cancellazione?`,
      () => this.delete(idp), // onOk
      () => { },              // onKo
      undefined,              // otherActionLabel
      undefined,              // onOtherAction
      '500px',                // width
      0,                      // closeAfterSeconds
      '',                     // etichettaSelezione
      []                      // elencoOggettiSelezione
    );
  }
}
