<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav mode="push">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon color="">menu</mat-icon>
    </button>￼
  </mat-sidenav>

  <mat-sidenav-content>
    <app-toolbar></app-toolbar>
    <div style="display:flex; flex-direction: column">
      <div i18n class="titolo-pagina" >
        Gestione Aziende
      </div>
      <div class="mat-elevation-z8" id="listaAziende">
        <mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows  (matSortChange)="sortData($event)">

          <mat-header-row *matHeaderRowDef="colonneVisualizzate" ></mat-header-row>
          <mat-row *matRowDef="let azienda; columns: colonneVisualizzate" class="pointer" (click)="selected(azienda)">
          </mat-row>

          <ng-container matColumnDef="id">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
            <mat-cell *matCellDef="let azienda"> {{azienda.id}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="nome">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Nome </mat-header-cell>
            <mat-cell *matCellDef="let azienda">
              <input matInput [(ngModel)]="azienda.nomeAzienda" (keydown)="salvaSuInvio($event,azienda)" (blur)="salvaAzienda(azienda)" />
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="odvs">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header style="justify-content: center"> Gruppi Gestori
            </mat-header-cell>

              <mat-cell *matCellDef="let azienda">


                <mat-chip-list #chipListDestinatariPerSocieta>
                  <mat-chip *ngFor="let destinatario of destinatariFiltratiSocieta[azienda.nomeAzienda]" [selectable]="true"
                    [removable]="true"
                    (click)="toggleDestinatarioPreferito($event, destinatario)"
                    [class.odv-predefinito]="destinatario.destinatarioPredefinito"
                    [class.odv-alternativo]="destinatario.destinatarioPredefinito === false"
                    >
                    {{destinatario.odv.nomeOdv}}
                    <mat-icon matChipRemove *ngIf="true"
                      (mousedown)="removeDestinatarioSocieta($event, destinatario, azienda)">cancel</mat-icon>
                  </mat-chip>
                  <div *ngIf="true">
                    <input #inputDestinatari matInput i18n-placeholder placeholder="Seleziona Gruppo..."
                      [matChipInputFor]="chipListDestinatariPerSocieta" [matAutocomplete]="auto4"
                      [readonly]="false" autocompleteOff>
                    <mat-autocomplete #auto4="matAutocomplete">
                      <mat-option *ngFor="let destinatario of getNonSelectedDestinatariSocieta()"
                        [value]="destinatario.nomeOdv"
                        (onSelectionChange)="addDestinatarioSocieta($event, destinatario)">
                        {{ destinatario.nomeOdv }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </mat-chip-list>
              </mat-cell>
          </ng-container>
          <ng-container matColumnDef="azioni">
            <mat-header-cell *matHeaderCellDef class="width-full">
              <button mat-icon-button class="destra" (click)="aggiungiAzienda(); $event.stopPropagation();">
                <mat-icon class="icone-testata-tabelle" aria-label="aggiungi azienda">add_circle</mat-icon>
              </button>
            </mat-header-cell>
            <mat-cell *matCellDef="let azienda">
              <button *ngIf="azienda && azienda.nomeAzienda" mat-icon-button class="azioni destra"
                (click)="salvaAzienda(azienda); $event.stopPropagation();">
                <mat-icon class="icona-riga-tabella" aria-label="salva azienda">save</mat-icon>
              </button>
              <button mat-icon-button class="azioni destra"
                (click)="openDialogConfermaEliminazioneAzienda(azienda); $event.stopPropagation();">
                <mat-icon class="icona-riga-tabella" aria-label="cancella azienda">delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> todo </mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
            </mat-cell>
          </ng-container>
          <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="riga-dettaglio-inner"></mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
        </mat-paginator>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
