<h2 mat-dialog-title i18n>Modifica traduzioni</h2>
<div mat-dialog-content >
  <form [formGroup]="formTranslations">
    <mat-form-field *ngFor="let traduzione of data.traduzioni" class="width-full">
      <mat-label>{{ traduzione.lingua }}</mat-label>
      <textarea maxlength="20000" rows="10" matInput  [formControlName]="traduzione.lingua"></textarea>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()" i18n>Annulla</button>
  <button mat-button color="primary" (click)="onSaveClick()" i18n>Salva</button>
</div>
