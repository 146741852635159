<div class="padding-10 alternate-theme" style="max-width: 800px; margin:auto;">
  <h2 class="titolo-dialog" i18n>Informativa nuova segnalazione</h2>
  <div class="flex-column">
    <div class="flex-column flex-center-center" style="text-align: center;">
      <h4 i18n>PRIMA DI {{operazione}} LA SEGNALAZIONE TI RICORDIAMO DI VERIFICARE CHE NON SIANO
        PRESENTI RIFERIMENTI ALLA TUA IDENTITA' NEI CAMPI DESCRITTIVI O IN QUALCHE ALLEGATO</h4>
      <h4 i18n>TI INVITIAMO AD UTILIZZARE SEMPRE IL PRESENTE CANALE INFORMATICO PER EVENTUALI SUCCESSIVE COMUNICAZIONI
        INERENTI QUESTA SEGNALAZIONE</h4>
      <div *ngIf="pinCode" class="flex-column flex-center-center" style="text-align: center;">
        <h4 i18n> Per accedere alla segnalazione in futuro utilizza il seguente PIN </h4>
        <div class="flex-row">
          <h2> {{ pinCode }}</h2>
          <button mat-icon-button (click)="copiaPin()" style="margin:1.5em" i18n-matTooltip
                  matTooltip="Copia negli appunti" matTooltipPosition="right">
            <mat-icon class="mat-18" color="accent">file_copy</mat-icon>
          </button>
        </div>
        <h4 i18n> Memorizzalo e conservalo in un luogo sicuro, poichè in caso di smarrimento non potrà essere rigenerato o
          recuperato. Ti invitiamo ad accedere frequentemente al Sistema per verificare la presenza di eventuali
          richieste di informazioni da parte dei Gestori</h4>
      </div>
      <div>
        <h4 i18n *ngIf="soloSalvataggio">
          LA SEGNALAZIONE SARA' SALVATA SUL SISTEMA MA NON VERRA' INVIATA A ORGANISMI INTERNI PER LA SUA VALUTAZIONE
          FINO A QUANDO NON DECIDERAI DI FARLO UTILIZZANDO IL PULSANTE DI INVIO
        </h4>
      </div>
    </div>
    <button mat-button (click)="apriInformativa()" i18n class="flex-fill"> Clicca qui per consultare l’informativa privacy </button>
  </div>
  <div class="flex-row" style="margin-top: 16px;">
    <button mat-button [mat-dialog-close]="true" *ngIf="informativaAccettata || soloSalvataggio"
            i18n class="margin-auto" cdkFocusInitial>Procedi</button>
  </div>
</div>
