import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servizi/auth.service';
import { SessionData } from '../../sessione/dati-sessione';
import { Esito, ESITO_OK } from '../../utility/esito';

@Component({
  selector: 'app-attivazione-mfa',
  templateUrl: './attivazione-mfa.component.html',
  styleUrls: ['./attivazione-mfa.component.scss']
})
export class AttivazioneMfaComponent {
  tokenRigeneraMfa: string;
  emailVerificata: boolean = false;
  imageSrc: any;
  errore: string;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar,
    private sessionData: SessionData,) { }

  ngOnInit(): void {
    this.tokenRigeneraMfa = this.route.snapshot.queryParamMap.get('rigeneraMfa')
    if (this.tokenRigeneraMfa !== '' && this.tokenRigeneraMfa !== undefined && this.tokenRigeneraMfa !== null) {
      this.getMfaQrCode()
    }

    this.route.data
      .subscribe(
        data => {
          // console.log(data);
          const configurazione = data['configurazione']
          if (configurazione) {
            this.sessionData.configurazione = configurazione
          } else {
            console.error(data)
            this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
          }
          return
        }
      )
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.

  }

  getMfaQrCode() {
    this.authService.getMfaQrCode(this.tokenRigeneraMfa).
      subscribe(esito => {
        const codice = esito.esito
        switch (codice) {
          case ESITO_OK:
            const payload = JSON.parse(esito.payload)
            if (payload['qrCodeImage']) {
              console.log('Attivato MFA')
              // ho ricevuto l'immagine di un qr code in base64 la visualizzo nella pagina
              this.imageSrc = payload['qrCodeImage']
            }
            break
          case Esito.TOKEN_NON_VALIDO:
            this.errore = $localize `Il token non è valido.`
            break
          case Esito.TOKEN_SCADUTO:
            this.errore = $localize `Il token è scaduto.`
            break
          default:
            this.errore = $localize `Errore nella rigenerazione del doppio fattore. `
            break
        }

      },
        err => {
          this.errore=$localize `Errore nella rigenerazione del doppio fattore. ` + err.toString()
      })
  }

  getLogoCliente() {
    return (this.sessionData.logoCliente !== undefined && this.sessionData.logoCliente.base64 !== undefined ?
      this.sessionData.logoCliente.base64 : this.getLogoSecure())
  }

  getLogoSecure() {
    return (this.sessionData.logoSecure !== undefined && this.sessionData.logoSecure.base64 !== undefined ?
      this.sessionData.logoSecure.base64 : '/assets/img/logo-secure.png')
  }


  backToMainLogin() {
    this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
  }
  apriPaginaLogin() {
    this.router.navigate(['/login'])
  }
}
