import { Segnalazione } from './segnalazione'

export class AudioFile {

  id: number

  name: string

  mimeType: string

  content: ArrayBuffer

  segnalazione: Segnalazione
}
