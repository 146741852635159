<div mat-dialog class="padding-10" style="max-width: 800px; margin:auto; background-color: white;">

  <h2 mat-dialog-title class="titolo-dialog " i18n>Privacy</h2>
  <mat-dialog-content >
    <angular-editor [(ngModel)]="privacyPolicy" [config]="editorConfig">
    </angular-editor>
  </mat-dialog-content>
  <mat-dialog-actions >
    <button i18n mat-button mat-dialog-close class="margin-auto" i18n>Chiudi</button>
  </mat-dialog-actions>

</div>
