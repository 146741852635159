// idp-edit-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdentityProvider } from '../../../servizi/idp.service';


@Component({
  selector: 'app-idp-edit-dialog',
  templateUrl: 'idp-edit-dialog.component.html',
})
export class IdpEditDialogComponent {
  id = 0;
  idpName: string;
  idpCertificate: string;
  idpEntryPoint: string;
  idpCallbackUrl: string;
  idpIssuer: string;

  constructor(
    public dialogRef: MatDialogRef<IdpEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.id = data.id;
      this.idpName = data.name;
      this.idpCertificate = data.certificate;
      this.idpEntryPoint = data.entryPoint;
      this.idpCallbackUrl = data.callbackUrl;
      this.idpIssuer = data.issuer;
  }

  isValidCertificate(certificate: string): boolean {
    const trimmedCert = certificate.trim();
    return trimmedCert.startsWith("-----BEGIN CERTIFICATE-----") && trimmedCert.endsWith("-----END CERTIFICATE-----");
  }

  isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }

  onSave() {
    // Logica di salvataggio
    if (this.isValidCertificate(this.idpCertificate) && this.idpName.trim()) {
      // Chiama il servizio di salvataggio
      this.dialogRef.close({id: this.id,
        name: this.idpName,
        certificate: this.idpCertificate,
        entryPoint: this.idpEntryPoint,
        callbackUrl: this.idpCallbackUrl,
        issuer: this.idpIssuer });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
