<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" style="position: relative;">

  <h2 mat-dialog-title class="dialog-title">Editor stile sito</h2>
  <div mat-dialog-content class="theme">
    <table class="color-picker-table">

      <tr>
        <td>
          <input #secureLogoInput type="file" accept="image/*" (change)="changeSecureLogo($event)"
            style="display: none;">
          <button mat-button color="accent" (click)="secureLogoInput.click()">Seleziona il logo Secure Blowing</button>
        </td>
      </tr>
      <tr *ngIf="colorCombo">
        <td>
          <mat-form-field appearance="fill">
            <mat-label>Altezza del logo (px)</mat-label>
            <input matInput id="logo-secure-height" type="number" (input)="colorCombo.altezzaLogoSecure = $event.target.value"
            [(ngModel)] = "colorCombo.altezzaLogoSecure">
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="colorCombo">
        <td colspan="2">
          <!-- Elemento img per l'anteprima, lega la proprietà src a logoPreviewUrl e style.height a colorCombo.altezzaLogo -->
          <div class="image-preview" [class.hidden]="!secureLogoPreviewUrl">
            <img [src]="secureLogoPreviewUrl" [style.height.px]="colorCombo.altezzaLogoSecure" class="centered-image">
          </div>
        </td>
      </tr>



      <tr>
        <td>
          <input #customerLogoInput type="file" accept="image/*" (change)="changeCustomerLogo($event)"
            style="display: none;">
          <button mat-button color="accent" (click)="customerLogoInput.click()">Seleziona il logo del
            cliente</button>
        </td>
      </tr>
      <tr *ngIf="colorCombo">
        <td>
          <mat-form-field appearance="fill">
            <mat-label>Altezza del logo (px)</mat-label>
            <input matInput id="logo-height" type="number" (input)="colorCombo.altezzaLogoCliente = $event.target.value"
            [(ngModel)] = "colorCombo.altezzaLogoCliente">
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="colorCombo">
        <td colspan="2">
          <!-- Elemento img per l'anteprima, lega la proprietà src a logoPreviewUrl e style.height a colorCombo.altezzaLogo -->
          <div class="image-preview" [class.hidden]="!clienteLogoPreviewUrl">
            <img [src]="clienteLogoPreviewUrl" [style.height.px]="colorCombo.altezzaLogoCliente" class="centered-image">
          </div>
        </td>
      </tr>


      <tr>
        <td><label for="text-color">Seleziona colore testo:</label></td>
        <td><input #textColorInput id="text-color" type="color" (change)="changeTextColor($event)"></td>
      </tr>
      <tr>
        <td><label for="bg-toolbar-color">Seleziona Colore Sfondo Toolbar:</label></td>
        <td><input #backgroundToolbarColorInput id="bg-toolbar-color" type="color"
            (change)="changeBackgroundToolbarColor($event)"></td>
      </tr>
      <tr>
        <td><label for="bg-chiaro-color">Seleziona colore sfondo chiaro (sfondo in secondo piano e sottopancia
            chat):</label></td>
        <td><input #backgroundChiaroColorInput id="bg-chiaro-color" type="color"
            (change)="changeBackgroundChiaroColor($event)"></td>
      </tr>
      <tr>
        <td><label for="text-toolbar-color">Seleziona Colore del testo e delle icone sulla Toolbar:</label></td>
        <td><input #textToolbarColorInput id="text-toolbar-color" type="color"
            (change)="changeTextToolbarColor($event)">
        </td>
      </tr>
      <tr>
        <td><label for="text-toolbar-color">Seleziona Colore dell'icona per la selezione della lingua:</label></td>
        <td><input #selettoreLinguaColorInput id="lingua-sel-color" type="color"
            (change)="changeSelettoreLinguaColor($event)">
        </td>
      </tr>
      <tr>
        <td><label for="primary-color">Seleziona il Colore delle icone nelle righe delle tabelle e lo sfondo dei
            chip:</label></td>
        <td><input #primaryColorInput id="primary-color" type="color" (change)="changePrimaryColor($event)"></td>
      </tr>
      <tr>
        <td><label for="bg-color">Seleziona il colore sfondo della testata delle tabelle:</label></td>
        <td><input #backgroundTableHeader id="bg-color" type="color"
            (change)="changeBackgroundTableHeaderColor($event)">
        </td>
      </tr>
      <tr>
        <td><label for="bg-color">Seleziona colore bottoni:</label></td>
        <td><input #backgroundColorInput type="color" (change)="changeTextButtonColor($event)"></td>
      </tr>
      <tr>
        <td><label for="text-chiaro-color">Seleziona il colore del testo nei bottoni:</label></td>
        <td><input #textButtonColorInput id="text-chiaro-color" type="color" (change)="changeTextButtonColor($event)">
        </td>
      </tr>
      <tr>
        <td><label for="text-chiaro-color">Seleziona il colore del testo chiaro e dello sfondo delle righe delle
            tabelle:</label></td>
        <td><input #textChiaroColorInput id="text-chiaro-color" type="color" (change)="changeTextChiaroColor($event)">
        </td>
      </tr>
      <tr>
        <td><label for="text-chiaro-color">Seleziona il colore di sfondo della nuvoletta Chat di destra:</label></td>
        <td><input #sfondoNuvolettaDestra  type="color" (change)="changeSfondoNuvolettaDestra($event)">
        </td>
      </tr>
      <tr>
        <td><label for="text-chiaro-color">Seleziona il colore di sfondo della nuvoletta Chat di sinistra:</label></td>
        <td><input #sfondoNuvolettaSinistra type="color" (change)="changeSfondoNuvolettaSinistra($event)">
        </td>
      </tr>
    </table>
  </div>
  <!-- riga flex con i due bottoni separati -->
  <div mat-dialog-actions style="display: flex; justify-content: space-around;">
    <!-- bottone per il salvataggio della combinazione -->
    <div mat-dialog-actions style="display: flex; justify-content: center;">
      <button mat-button (click)="saveCombination()">Salva combinazione</button>
    </div>
    <div mat-dialog-actions style="display: flex; justify-content: center;">
      <button mat-button (click)="closeDialog()">Chiudi</button>
    </div>

  </div>

  <div class="resize-handle" (mousedown)="onResizeMouseDown($event)"></div>
</div>
