
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ServiziRest, GlobalVars } from '../utility/utility'
import { Esito } from '../utility/esito'
import { AuthService } from './auth.service'
import { Messaggio } from '../modello/messaggio'
import { Chat } from '../modello/chat'
import { Observable, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  notificheNuovoMessaggioPerSegnalazioneSelezionata = new Subject<Messaggio>()
  notifichePerMessaggioNonLetto = new Subject<Messaggio>()
  notifichePerMessaggioLetto = new Subject<Object>()
  notificaPerAggiornamentoChat = new Subject()
  notificaPerChatSelezionata = new Subject<Chat>()

  notificaPannelloManagerApertoChiuso = new Subject<boolean>()


  sottoscrizioniNotifica: string[] = new Array<string>()
  sottoscrizioniAggiornamentiChat: string[] = new Array<string>()

  sottoscrizioneOnRead: any

  constructor(private http: HttpClient, private authenticationService: AuthService) { }

  handleErrorPromise(error: Response | any) {
    console.error(error.message || error)
    return Promise.reject(error.error || error)
  }

  async aggiungiUtentePartecipanteChat(idChat: number, idUtente: number, idSegnalazione: number, idGruppoOdv: number): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlChatAddUtentePartecipante + '/' + idChat + '/' + idUtente +
      '/' + idSegnalazione + '/' + idGruppoOdv, null).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  async aggiungiGruppoPartecipanteChat(idChat: number, idSegnalazione: number, idGruppoOdv: number): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlChatAddGruppoPartecipante + '/' + idChat +
      '/' + idSegnalazione + '/' + idGruppoOdv, null).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  async aggiungiSegnalanteAChat(idChat: number, idSegnalazione: number): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<Esito>(ServiziRest.urlChatAddSegnalante + '/chat/' +
      idChat + '/segnalazione/' +
      idSegnalazione, options).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  async eliminaUtentePartecipanteChat(idChat: number, idUtente: number, idSegnalazione: number, idGruppoOdv: number): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlChatRemoveUtentePartecipante + '/' + idChat + '/' + idUtente +
      '/' + idSegnalazione + '/' + idGruppoOdv, null).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  async eliminaGruppoPartecipanteChat(idChat: number, idSegnalazione: number, idGruppoOdv: number): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlChatRemoveGruppoPartecipante + '/' + idChat +
      '/' + idSegnalazione + '/' + idGruppoOdv, null).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  async eliminaSegnalanteDaChat(idChat: number, idSegnalazione: number): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlChatRemoveSegnalante + '/' + idChat +
      '/' + idSegnalazione, null).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  async impostaModeratore(idChat: number, idGruppo: number): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlChatImpostaModeratore + '/' + idChat + '/' + idGruppo, null).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  async recuperaChatById(idChat: string): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlChat + '/' + idChat).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  async recuperaChatBySegnalazione(idSegnalazione: number): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlCrossOrigin +
      ServiziRest.urlChat + '/segnalazione/' + idSegnalazione).toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  async recuperaChatByLavorazione(idLavorazione: number): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlCrossOrigin +
      ServiziRest.urlChat + '/lavorazione/' + idLavorazione).toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  creaChat(chat: Chat): Promise<Esito> {
    chat.partecipanti = []
    chat.partecipanti.push(this.authenticationService.getUser())

    return this.http.post<Esito>(ServiziRest.urlChat, chat).toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaMessaggiByChat(idChat: number, messageToBeRetrieved?: number, messageToBeSkipped?: number): Promise<Esito> {
    return this.http.get(ServiziRest.urlGetMessaggiByChat +
      '/' + idChat +
      '/' + messageToBeRetrieved +
      '/' + messageToBeSkipped
    ).toPromise()
      .then(response => response as Esito)
      .catch(this.handleErrorPromise)
  }

  recuperaPartecipantiByChat(idChat: number, idSegnalazione: number): Promise<Esito> {
    return this.http.get(ServiziRest.urlGetPartecipantiByChat +
      '/' + idChat +
      '/' + idSegnalazione
    ).toPromise()
      .then(response => response as Esito)
      .catch(this.handleErrorPromise)
  }

  recuperaMessaggiNonLetti(): Promise<Esito> {
    /*     if (!this.authenticationService.isLoggedIn()) {
          return new Promise(null);
        } */

    return this.http.get(ServiziRest.urlGetMessaggiNonLetti
    ).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  inviaMessaggio(formData: FormData): Promise<Esito> {
    return this.http.post(ServiziRest.urlMessaggi, formData
    ).toPromise()
      .then((esito: Esito) => esito)
      .catch(this.handleErrorPromise)
  }

  deleteMessaggio(idMessaggio: number): Observable<Esito> {
    return this.http.delete<Esito>(ServiziRest.urlMessaggi + '/' + idMessaggio)
  }


  async recuperaUtentiPerChat(idSegnalazione: number, idChat: number): Promise<Esito> {
    return this.http.get(ServiziRest.urlGetPossibiliUtentiPartecipantiByChat +
      '/' + idSegnalazione +
      '/' + idChat
    ).toPromise()
      .then(response => response as Esito)
      .catch(this.handleErrorPromise)
  }

  async recuperaGruppiPerChat(idSegnalazione: number, idChat: number): Promise<Esito> {
    return this.http.get(ServiziRest.urlGetPossibiliGruppiPartecipantiByChat +
      '/' + idSegnalazione +
      '/' + idChat
    ).toPromise()
      .then(response => response as Esito)
      .catch(this.handleErrorPromise)
  }

  eliminaSottoscrizioneNotifica(idChat: number) {
    this.sottoscrizioniNotifica = this.sottoscrizioniNotifica.filter(idChatTmp => +idChatTmp !== +idChat)
  }

  sottoscriviPerModificheChat(idChat) {

    if (!this.sottoscrizioniAggiornamentiChat.includes(idChat)) {
      GlobalVars.socket.on('chat-aggiornata-' + idChat, (idChat1: any) => {
        this.notificaPerAggiornamentoChat.next(idChat1)
      })
      this.sottoscrizioniAggiornamentiChat.push(idChat)
    }
  }

  async annullaSottoscrizionePerModificheChat(idChat) {
    GlobalVars.socket.removeListener('chat-aggiornata-' + idChat)
    const index = this.sottoscrizioniAggiornamentiChat.findIndex(chat => chat === idChat)
    if (index !== -1) {
      this.sottoscrizioniAggiornamentiChat.splice(index, 1)
    }
  }

  annullaSottoscrizioniPerModificheChat() {
    this.sottoscrizioniAggiornamentiChat.forEach(idChat => {
      GlobalVars.socket.removeListener('chat-aggiornata-' + idChat)
    })
    this.sottoscrizioniAggiornamentiChat = []
  }

  messaggioRicevuto(messaggio: Messaggio): Observable<Esito> {
    return this.http.patch<Esito>(ServiziRest.urlMessaggi + '/ricevuto/' + messaggio.id, null)
  }

  messaggioLetto(messaggio: Messaggio): Observable<Esito> {
    return this.http.patch<Esito>(ServiziRest.urlMessaggi + '/letto/' + messaggio.id, null)
  }
}
