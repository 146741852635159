<h2 i18n mat-dialog-title class="titolo-dialog">Identità del Segnalante</h2>
<mat-dialog-content>
  <div i18n *ngIf="data.mancaConsenso && !data.statoVibilitaSegnalante" class="colore-testo-avviso">Attenzione, non hai ancora il consenso del
    segnalante, valuta attentamente se è opportuno svelarne l'identità a terzi!</div>
  <div i18n *ngIf="!data.statoVibilitaSegnalante">Confermi di voler svelare l'identità del segnalante ai membri del gruppo
    {{data.nomeGruppoOdv}}?</div>
  <div i18n *ngIf="data.statoVibilitaSegnalante">Confermi di voler inibire la visibilità dell'identità del segnalante ai
    membri del gruppo {{data.nomeGruppoOdv}}?</div>
</mat-dialog-content>
<mat-dialog-actions>
  <span class="spacer"></span>
    <button i18n mat-button mat-dialog-close>No</button>
    <span class="spacer"></span>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button i18n mat-button [mat-dialog-close]="true">Sì</button>
    <span class="spacer"></span>
</mat-dialog-actions>
