import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatTabChangeEvent } from '@angular/material/tabs'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import { VersioneContenuto } from '../../modello/versione-contenuto'
import { SessionData } from '../../sessione/dati-sessione'
import { TipoContenuto } from '../../utility/utility'

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent {

  tipo = TipoContenuto.EMAIL  // 1
  @Input() email: VersioneContenuto
  @Input() id: string
  @Output() emailChange = new EventEmitter<VersioneContenuto>()
  @Input() paroleChiaveOggetto: []
  @Input() lingua: string
  @Input() paroleChiaveTesto: []
  @Input() titolo: string

  editorConfigTb: AngularEditorConfig = {
    enableToolbar: true,
    showToolbar: true,
    toolbarHiddenButtons: [[], ['insertImage', 'insertVideo']],
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: $localize`Inserisci testo qui...`,
    translate: 'no',
    uploadUrl: 'https://devel.secure-blowing.com:3000/api/v1/images', // if needed
    customClasses: [
      // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  }

  constructor() { }

  onAggiornaTesto() {
    this.emailChange.emit(this.email)
  }

}
