/* eslint-disable @typescript-eslint/naming-convention */
import { SegnalazioneService } from './../servizi/segnalazione.service'
import { SessionData } from './../sessione/dati-sessione'
import { AuthService } from './../servizi/auth.service'
import { ConfigurazioneService } from './../servizi/configurazione.service'
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ESITO_OK, Esito } from '../utility/esito'
import { Configurazione, ConfigurazioneBan } from '../modello/configurazione'
import { Odv } from '../modello/odv'
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormGroup,
} from '@angular/forms'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import { ComponentCanDeactivate } from '../can-deactivate/can-deactivate.component'
import { Observable, of, Subject, Subscription } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatChipInputEvent } from '@angular/material/chips'

import { MatCheckbox } from '@angular/material/checkbox'
import { TipoDocumento } from '../modello/documento'
import {
  mapNumberToSottoTipoContenuto,
  SottoTipoContenuto,
  TipoContenuto,
  traduzioniAdapter,
  validateLandingVideoFileName,
  _base64ToArrayBuffer,
} from '../utility/utility'
import { CustomValidators } from '../validators/CustomValidators'
import { DialogService } from '../servizi/dialog.service'
import { TranslationDialogComponent } from '../componenti/translation-dialog/translation-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators'
import { Traduzioni } from '../modello/traduzioni'
import { SorgentiSegnalazioneService } from '../servizi/sorgenti-segnalazione.service'
import { SorgenteSegnalazione } from '../modello/sorgente-segnalazione'
import { TemiService } from '../servizi/temi.service'
import { Tema } from '../modello/tema'
import { AzioniFollowUpService } from '../servizi/azioni-follow-up.service'
import { AzioniFollowUp } from '../modello/azioni-follow-up'
import { DestinatariPerTipologia } from '../modello/destinatari-per-tipologia'
import { MatOptionSelectionChange } from '@angular/material/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ContenutiService } from '../servizi/contenuti.service'
import { VersioneContenuto } from '../modello/versione-contenuto'
import { Contenuto } from '../modello/contenuto'
import { VideoService } from '../servizi/video.service'
import { Azienda } from '../modello/azienda'
import { AziendeService } from '../servizi/aziende.service'
import { OdvAzienda } from '../modello/odv-azienda'

@Component({
  selector: 'app-configurazione',
  templateUrl: './configurazione.component.html',
  styleUrls: ['./configurazione.component.scss'],
})
export class ConfigurazioneComponent
  extends ComponentCanDeactivate
  implements OnInit, OnDestroy {
  // Creare un subject che emette i valori che vuoi salvare
  private updateSubject = new Subject<VersioneContenuto>()
  temi: any

  saving = false

  azioni: any

  _linguaSelezionata: string

  TERMINI_SERVIZIO = 0
  INFORMATIVA_NUOVA_SEGNALAZIONE = 1
  NORMATIVA = 2
  COOKIE_POLICY = 3
  PRIVACY_POLICY = 4
  INFORMATIVA_REGISTRAZIONE_UTENTE = 5
  INFORMATIVA_TRATTAMENTO_DATI = 6
  MANUALE_UTENTE = 7
  PROCEDURA_WHISTLEBLOWING = 8
  MISURE_DI_SICUREZZA = 9
  LANDING_PAGE = 10
  MANUALE_GESTORE = 11

  unitaDiMisura = [$localize`giorni`, $localize`mesi`, $localize`anni`]
  selectedUM1 = $localize`giorni`
  selectedUM2 = $localize`giorni`
  form: FormGroup = new FormGroup({})
  fileToUpload: File = null
  lingua

  configurazioneBan: ConfigurazioneBan

  @ViewChild('inputDestinatari', { static: false })
  inputDestinatari: ElementRef
  @ViewChild('sceltaOdv', { read: ElementRef })
  sceltaOdv: ElementRef<MatCheckbox>

  editorConfigTb: AngularEditorConfig = {
    enableToolbar: true,
    showToolbar: false,
    toolbarHiddenButtons: [[], ['insertImage', 'insertVideo']],
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: $localize`Inserisci testo qui...`,
    translate: 'no',
    uploadUrl: 'https://devel.secure-blowing.com:3000/api/v1/images', // if needed
    customClasses: [
      // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  }

  editorConfigs = [
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
    { ...this.editorConfigTb },
  ]

  formConfigurazione: UntypedFormGroup

  destinatari: Array<Odv>
  destinatariFiltrati: Array<Odv>
  tabSelezionato: number
  documentoAttivo: number
  visible = false
  selectedTranslation: string
  tipiNonConformita: any
  sorgenti: any
  tipologiaSelezionata: any
  destinatarioSelezionato: any
  chipRemoveClicked: boolean
  chipClicked: boolean
  subscriptions: Array<Subscription>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TipoContenuto = TipoContenuto
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SottotipoContenuto = SottoTipoContenuto
  sottotipoSelezionato: SottoTipoContenuto

  elencoSocieta: Array<Azienda>
  societaSelezionata: Azienda
  destinatariFiltratiSocieta: OdvAzienda[]
  destinatariTipologiaFiltrati: Array<DestinatariPerTipologia>
  fileName: any
  formConfigurazioneBan: UntypedFormGroup

  constructor(
    private configurazioneService: ConfigurazioneService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private segnalazioniService: SegnalazioneService,
    private sessionData: SessionData,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private sorgenteSegnalazioneService: SorgentiSegnalazioneService,
    private temiService: TemiService,
    private azioniService: AzioniFollowUpService,
    private route: ActivatedRoute,
    private router: Router,
    private contenutiService: ContenutiService,
    private videoService: VideoService,
    private aziendaService: AziendeService
  ) {
    super()
  }

  ngOnInit() {
    this.tabSelezionato = 0
    this.chipRemoveClicked = false
    this.subscriptions = []
    this.chipClicked = false

    this.aziendaService.recuperaAziende().then((esito) => {
      if (esito.esito === ESITO_OK) {
        this.elencoSocieta = JSON.parse(esito.payload) as Azienda[]
      }
    })

    this.route.data.subscribe((data) => {
      console.log(data)
      const configurazione = data['configurazione']
      this.configurazioneBan = data['configurazioneBan']
      if (configurazione) {
        console.log('configurazione: ', configurazione)
        this.sessionData.configurazione = configurazione
        this._linguaSelezionata = this.lingue ? this.lingue[0] : 'it'
      } else {
        console.error(data)
        this.router.navigate(['/landing-page'], {
          queryParams: { initialize: true },
        })
      }
      this.recuperaTuttoIlResto()
      return
    })
    // A ogni modifica del valore emesso dal subject, aspetta 500 millisecondi prima di invocare la chiamata al servizio
    this.subscriptions.push(
      this.updateSubject
        .pipe(
          debounceTime(500), // Aspetta 500ms prima di invocare il servizio, questo eviterà molte chiamate non necessarie
          // eslint-disable-next-line max-len
          switchMap((versioneContenuto) =>
            this.contenutiService.updateVersioneContenuto(versioneContenuto)
          ) // Chiama il servizio di aggiornamento con l'ultimo valore
        )
        .subscribe(
          (esito) => {
            if (ESITO_OK === esito.esito) {
              console.log(
                'Aggiornamento versione contenuto eseguito con successo'
              )
            } else {
              this.snackBar.open(
                $localize`L'aggiunta del documento è fallita` + esito.payload,
                null,
                {
                  duration: 6000,
                }
              )
            }
          },
          (error) => {
            this.snackBar.open(
              $localize`L'aggiunta del documento è fallita` + error,
              null,
              {
                duration: 6000,
              }
            )
          }
        )
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  recuperaTuttoIlResto() {
    this.lingua = window.location.href.split('/')[3]
    this.recuperaTutteLeSorgentiSegnalazione()
    this.recuperaTuttiITemi()
    this.recuperaTutteLeAzioni()
    this.recuperaTipiNonConformita()
    this.recuperaGruppiGestori()
  }

  private recuperaGruppiGestori() {
    this.segnalazioniService
      .recuperaGruppiGestori()
      .then((esito2: Esito) => {
        if (esito2.esito === ESITO_OK) {
          this.destinatari = JSON.parse(esito2.payload)
          // this.destinatariFiltrati = this.destinatari;
          //  Ora elimina quelle già selezionate
          // this.destinatariFiltrati = this.destinatariFiltrati.filter(destinatario => !destinatario.predefinito);
          this.createForm()
          this.visible = true
        } else {
          this.snackBar.open(
            $localize`Recupero elenco destinatari Fallito. Errore: ` +
            esito2.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero elenco destinatari Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  private recuperaTipiNonConformita() {
    this.configurazioneService
      .recuperaTipiNonConformita()
      .pipe(
        filter((esito1: Esito) => esito1.esito === ESITO_OK),
        map((esito2: Esito) => esito2.payload),
        catchError((error) => {
          console.error('Errore nella richiesta del valore aggiuntivo', error)
          return of(null)
        })
      )
      .subscribe((payload) => {
        this.tipiNonConformita = JSON.parse(payload) as Traduzioni[]
        this.sessionData.configurazione.tipiNonConformita =
          this.tipiNonConformita
        // ciclo su tutte le traduzioni ricevute ed aggiungo in this.sessionData.configurazione delle proprietà
        this.tipiNonConformita.forEach((traduzione) => {
          this.sessionData.configurazione[
            'tipiNonConformita' + traduzione.lingua
          ] = traduzione.testo
        })
        this.refreshForm()
      })
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.formConfigurazione && this.formConfigurazione.dirty) {
      return confirm(
        $localize`Hai delle modifiche non salvate. Sei sicuro di voler uscire?`
      )
    }
    return true
  }

  private createForm() {
    this.formConfigurazione = this.fb.group({
      id: this.fb.control(this.configurazione.id),

      unicoOdvPerSegnalante: this.fb.control(
        this.configurazione.unicoOdvPerSegnalante
      ),

      abilitaGestioneConflittoInteressi: this.fb.control(
        this.configurazione.abilitaGestioneConflittoInteressi
      ),

      consentiSegnalazioniAnonime: this.fb.control(
        this.configurazione.consentiSegnalazioniAnonime
      ),

      consentiInoltroSegnalazioniLibero: this.fb.control(
        this.configurazione.consentiInoltroSegnalazioniLibero
      ),
      segnalazioneVocaleAbilitata: this.fb.control(
        this.configurazione.segnalazioneVocaleAbilitata
      ),

      segnalanteVedeOdv: this.fb.control(this.configurazione.segnalanteVedeOdv),

      segnalantePuoScegliereOdv: this.fb.control(
        this.configurazione.segnalantePuoScegliereOdv
      ),

      destinatari: this.fb.control(this.destinatari),

      url: this.fb.control(this.configurazione.url),

      maiuscolePassword: this.fb.control(this.configurazione.maiuscolePassword),

      minuscolePassword: this.fb.control(this.configurazione.minuscolePassword),

      numeriPassword: this.fb.control(this.configurazione.numeriPassword),

      caratteriSpecialiPassword: this.fb.control(
        this.configurazione.caratteriSpecialiPassword
      ),

      lunghezzaMinimaPassword: this.fb.control(
        this.configurazione.lunghezzaMinimaPassword
      ),

      maiuscolePIN: this.fb.control(this.configurazione.maiuscolePIN),

      minuscolePIN: this.fb.control(this.configurazione.minuscolePIN),

      numeriPIN: this.fb.control(this.configurazione.numeriPIN),

      caratteriSpecialiPIN: this.fb.control(
        this.configurazione.caratteriSpecialiPIN
      ),

      lunghezzaMinimaPIN: this.fb.control(
        this.configurazione.lunghezzaMinimaPIN
      ),

      scadenzaPin: this.fb.control(this.configurazione.scadenzaPin),
      inviaEmailPerCambioStatoSegnalazione: this.fb.control(
        this.configurazione.inviaEmailPerCambioStatoSegnalazione
      ),
      inviaEmailSegnalantePerCambioStatoSegnalazione: this.fb.control(
        this.configurazione.inviaEmailSegnalantePerCambioStatoSegnalazione
      ),
      // aggiungi le altre proprietà per i campi presenti in questo update:
      // UPDATE `configurazione` SET `inviaEmailPerCambioStatoSegnalazione` = '1', `inviaEmailPerCambioStatoLavorazione` = '1',
      // `inviaEmailPerNotificaNuovaRichiestaSbloccoIdentitaSegnalante` = '1', `inviaEmailPerNotificaNegazioneSbloccoIdentitaSegnalante` = '1',
      // `inviaEmailPerNotificaConcessioneSbloccoIdentitaSegnalante` = '1', `inviaEmailPerNotificaNuovoConsensoSegnalante` = '1',
      // `inviaEmailPerNotificaNegazioneConsensoSegnalante` = '1', `inviaEmailPerNotificaConcessioneConsensoSegnalante` = '1',
      // `inviaEmailPerNotificaLavorazioneInoltrata` = '1' WHERE `configurazione`.`id` = 1;
      inviaEmailPerCambioStatoLavorazione: this.fb.control(
        this.configurazione.inviaEmailPerCambioStatoLavorazione
      ),
      inviaEmailSegnalantePerCambioStatoLavorazione: this.fb.control(
        this.configurazione.inviaEmailSegnalantePerCambioStatoLavorazione
      ),
      inviaEmailPerNotificaNuovaRichiestaSbloccoIdentitaSegnalante:
        this.fb.control(
          this.configurazione
            .inviaEmailPerNotificaNuovaRichiestaSbloccoIdentitaSegnalante
        ),
      inviaEmailPerNotificaNegazioneSbloccoIdentitaSegnalante: this.fb.control(
        this.configurazione
          .inviaEmailPerNotificaNegazioneSbloccoIdentitaSegnalante
      ),
      inviaEmailPerNotificaConcessioneSbloccoIdentitaSegnalante:
        this.fb.control(
          this.configurazione
            .inviaEmailPerNotificaConcessioneSbloccoIdentitaSegnalante
        ),
      inviaEmailPerNotificaNuovoConsensoSegnalante: this.fb.control(
        this.configurazione.inviaEmailPerNotificaNuovoConsensoSegnalante
      ),
      inviaEmailPerNotificaNegazioneConsensoSegnalante: this.fb.control(
        this.configurazione.inviaEmailPerNotificaNegazioneConsensoSegnalante
      ),
      inviaEmailPerNotificaConcessioneConsensoSegnalante: this.fb.control(
        this.configurazione.inviaEmailPerNotificaConcessioneConsensoSegnalante
      ),
      inviaEmailPerNotificaLavorazioneInoltrata: this.fb.control(
        this.configurazione.inviaEmailPerNotificaLavorazioneInoltrata
      ),

      inviaEmailPerInoltroSegnalazione: this.fb.control(
        this.configurazione.inviaEmailPerInoltroSegnalazione
      ),

      inviaEmailPerDisinoltroSegnalazione: this.fb.control(
        this.configurazione.inviaEmailPerDisinoltroSegnalazione
      ),

      privacyPolicy: this.fb.control(this.configurazione.privacyPolicy),

      cookiePolicy: this.fb.control(this.configurazione.cookiePolicy),

      terminiServizio: this.fb.control(this.configurazione.terminiServizio),

      normativa: this.fb.control(this.sessionData.normativa),

      dominiAziendali: this.fb.control(this.configurazione.dominiAziendali),
      lingueForm: this.fb.control(this.configurazione.lingue),

      custodiaIdentita: this.fb.control(this.configurazione.custodiaIdentita),

      consensiSegnalante: this.fb.control(
        this.configurazione.consensiSegnalante
      ),

      durataSessione: this.fb.control(this.configurazione.durataSessione),
      sogliaAvvisoSecondiScadenzaSessione: this.fb.control(
        this.configurazione.sogliaAvvisoSecondiScadenzaSessione
      ),
      modificabilitaLavorazioniDaParteDiTerzi: this.fb.control(
        this.configurazione.modificabilitaLavorazioniDaParteDiTerzi
      ),
      autenticazionePredefinitaSSO: this.fb.control(
        this.configurazione.autenticazionePredefinitaSSO
      ),
      autenticazioneSecureAbilitata: this.fb.control(
        this.configurazione.autenticazioneSecureAbilitata
      ),
      ipTracing: this.fb.control(this.configurazione.ipTracing),
      condivisioneSegnalazioneNellaSchedaSegnalazione: this.fb.control(
        this.configurazione.condivisioneSegnalazioneNellaSchedaSegnalazione
      ),
      modalitaPinUnicoPerSegnalazione: this.fb.control(
        this.configurazione.modalitaPinUnicoPerSegnalazione
      ),
      abilitaNotificheVersoEmailInCalceAllaSegnalazione: this.fb.control(
        this.configurazione.abilitaNotificheVersoEmailInCalceAllaSegnalazione
      ),
      minutiBlocco: this.fb.control(this.configurazione.minutiBlocco),
      tentativiLoginFallitiPrimaDelBlocco: this.fb.control(
        this.configurazione.tentativiLoginFallitiPrimaDelBlocco
      ),
      bloccoPermanente: this.fb.control(this.configurazione.bloccoPermanente),
      emailSegnalanteObbligatoria: this.fb.control(
        this.configurazione.emailSegnalanteObbligatoria
      ),
      abilitaMFA: this.fb.control(this.configurazione.abilitaMFA),
      mostraSocietaACuiSiRiferisceIlFattoSuDettaglioSegnalazione:
        this.fb.control(
          this.configurazione
            .mostraSocietaACuiSiRiferisceIlFattoSuDettaglioSegnalazione
        ),
      abilitaCaptcha: this.fb.control(this.configurazione.abilitaCaptcha),
      mostraTipiNonConformitaSuDettaglioSegnalazione: this.fb.control(
        this.configurazione.mostraTipiNonConformitaSuDettaglioSegnalazione
      ),
      giorniRetentionSegnalazioneMarcataDaCancellareDaGestore: this.fb.control(
        this.configurazione
          .giorniRetentionSegnalazioneMarcataDaCancellareDaGestore
      ),
      giorniRetentionSegnalazione: this.fb.control(
        this.configurazione.giorniRetentionSegnalazione
      ),
      mostraPrivacyPolicyInLanding: this.fb.control(
        this.configurazione.mostraPrivacyPolicyInLanding
      ),
      mostraManualeUtenteInLanding: this.fb.control(
        this.configurazione.mostraManualeUtenteInLanding
      ),
      mostraProcedureWhistleblowingInLanding: this.fb.control(
        this.configurazione.mostraProcedureWhistleblowingInLanding
      ),
      tipiNonConformita: this.fb.control(this.tipiNonConformita),
      pitch: this.fb.control(this.configurazione.pitchShifting),
      mascheraTuttiGliAudio: this.fb.control(
        this.configurazione.mascheraTuttiGliAudio
      ),

      lingua: this.fb.control(this.lingua),
      // aggiungo le sorgenti e mi assicuro che i
      sorgenti: this.fb.control(this.sorgenti),
      temi: this.fb.control(this.temi),
      azioni: this.fb.control(this.azioni),
      misureDiSicurezza: this.fb.control(this.configurazione.misureDiSicurezza),
      limiteGiorniPresaInCaricoSegnalazione: this.fb.control(
        this.configurazione.limiteGiorniPresaInCaricoSegnalazione
      ),
      sogliaGiorniPerAlertMancataPresaInCaricoSegnalazione: this.fb.control(
        this.configurazione.sogliaGiorniPerAlertMancataPresaInCaricoSegnalazione
      ),
      frequenzaInvioAlertMancataPresaInCaricoSegnalazione: this.fb.control(
        this.configurazione.frequenzaInvioAlertMancataPresaInCaricoSegnalazione
      ),
      limiteGiorniChiusuraIndagineSegnalazione: this.fb.control(
        this.configurazione.limiteGiorniChiusuraIndagineSegnalazione
      ),
      sogliaGiorniPerAlertMancataChiusuraIndagineSegnalazione: this.fb.control(
        this.configurazione
          .sogliaGiorniPerAlertMancataChiusuraIndagineSegnalazione
      ),
      frequenzaInvioAlertMancataChiusuraIndagineSegnalazione: this.fb.control(
        this.configurazione
          .frequenzaInvioAlertMancataChiusuraIndagineSegnalazione
      ),
      limiteGiorniSottomissioneSegnalazione: this.fb.control(
        this.configurazione.limiteGiorniSottomissioneSegnalazione
      ),
      indirizzamentoSegnalazioniGruppiPredefiniti: this.fb.control(
        this.configurazione.indirizzamentoSegnalazioniGruppiPredefiniti
      ),
      sogliaMovimentiMouse: this.fb.control(
        this.configurazioneBan.sogliaMovimentiMouse
      ),
      sogliaBattitureTastiera: this.fb.control(
        this.configurazioneBan.sogliaBattitureTastiera
      ),
      sogliaClick: this.fb.control(this.configurazioneBan.sogliaClick),
      finestraTemporaleInSecondiPerCheckBan: this.fb.control(
        this.configurazioneBan.finestraTemporaleInSecondiPerCheckBan
      ),
      sogliaBanPerTassoRichiesteElevato: this.fb.control(
        this.configurazioneBan.sogliaBanPerTassoRichiesteElevato
      ),
      sogliaBanPerLoginFallite: this.fb.control(
        this.configurazioneBan.sogliaBanPerLoginFallite
      ),
      durataBanTemporaneiInMinuti: this.fb.control(
        this.configurazioneBan.durataBanTemporaneiInMinuti
      ),
      sogliaNumeroBanTemporaneiPrimaDiDefinitivo: this.fb.control(
        this.configurazioneBan.sogliaNumeroBanTemporaneiPrimaDiDefinitivo
      ),
    }, { validator: this.atLeastOneCheckboxRequired })
    this.lingue.forEach((lingua) => {
      this.formConfigurazione.addControl(
        'tipoNonConformita' + lingua,
        this.fb.control(this.configurazione['tipoNonConformita' + lingua])
      )
    })
  }

  atLeastOneCheckboxRequired(group: FormGroup): { [s: string]: boolean } {
    if (group.get('abilitaCaptcha').value || group.get('abilitaMFA').value) {
      return null;
    }
    return { noCheckboxSelected: true };
  }

  refreshForm() {
    this.createForm()
  }

  get dominiAziendali() {
    return this.configurazione.dominiAziendali === undefined ||
      this.configurazione.dominiAziendali === null ||
      this.configurazione.dominiAziendali?.trim() === ''
      ? []
      : this.configurazione.dominiAziendali.split(',')
  }

  get lingue() {
    if (!this.configurazione) {
      return []
    }
    return this.configurazione.lingue === undefined ||
      this.configurazione.lingue === null ||
      this.configurazione.lingue?.trim() === ''
      ? []
      : this.configurazione.lingue.split(',')
  }

  addDomain(event: MatChipInputEvent) {
    const value = event.value
    // Add our new email
    if ((value || '').trim()) {
      //  Verifica se è un dominio valido
      if (!CustomValidators.checkIsValidDomain(value)) {
        this.snackBar.open(
          $localize`Il dominio email digitato non è valido. Il formato deve essere <NOME DOMINIO>.<SUFFISSO> ad esempio test.com`,
          null,
          {
            duration: 8000,
          }
        )
      } else if (
        this.configurazione.dominiAziendali
          ?.split(',')
          .filter((ema) => ema === value).length > 0
      ) {
        this.snackBar.open($localize`Dominio già presente.`, null, {
          duration: 8000,
        })
      } else {
        if (this.configurazione.dominiAziendali === null) {
          this.configurazione.dominiAziendali = ''
        }
        this.configurazione.dominiAziendali +=
          (this.configurazione.dominiAziendali !== undefined &&
            this.configurazione.dominiAziendali.trim().length > 0
            ? ','
            : '') + value.trim()
        this.formConfigurazione.controls['dominiAziendali'].setValue(
          this.configurazione.dominiAziendali
        )
        this.formConfigurazione.controls['dominiAziendali'].markAsDirty()
      }
    }

    // Reset the input value
    event.chipInput?.clear()
  }

  removeDomain(domain) {
    this.configurazione.dominiAziendali = this.configurazione.dominiAziendali
      .split(',')
      .filter((ema) => ema !== domain)
      .join(',')
    this.formConfigurazione.controls['dominiAziendali'].setValue(
      this.configurazione.dominiAziendali
    )
    this.formConfigurazione.controls['dominiAziendali'].markAsDirty()
  }

  addLingua(event: MatChipInputEvent) {
    const value = event.value
    // Add our new language
    if ((value || '').trim()) {
      if (
        this.configurazione.lingue?.split(',').filter((ema) => ema === value)
          .length > 0
      ) {
        this.snackBar.open($localize`Lingua già presente.`, null, {
          duration: 8000,
        })
      } else {
        if (this.configurazione.lingue === null) {
          this.configurazione.lingue = ''
        }
        this.configurazione.lingue +=
          (this.configurazione.lingue !== undefined &&
            this.configurazione.lingue.trim().length > 0
            ? ','
            : '') + value.trim()
        this.formConfigurazione.controls['lingueForm'].setValue(
          this.configurazione.lingue
        )
        this.formConfigurazione.controls['lingueForm'].markAsDirty()
      }
    }

    // Reset the input value
    event.chipInput?.clear()
  }

  async removeLingua(lingua) {
    // Richiede all'utente di confermare l'eliminazione
    const result = await this.dialogService.confirm(
      $localize`Conferma eliminazione`,
      $localize`Sei sicuro di voler eliminare la lingua ${lingua}? Se confermi anche tutte le traduzioni verranno eliminate.`
    )
    if (result) {
      this.configurazione.lingue = this.configurazione.lingue
        .split(',')
        .filter((ema) => ema !== lingua)
        .join(',')
      this.formConfigurazione.controls['lingueForm'].setValue(
        this.configurazione.lingue
      )
      this.formConfigurazione.controls['lingueForm'].markAsDirty()
    }
  }

  get tipologieSegnalazione() {
    // individua il testo della lingua corrente
    const linguaUtente = this.authService.getLinguaUtente()
    // trovo l'indice per il tipoNonConformita nella lingua corrente
    let index = this.tipiNonConformita?.findIndex(
      (tnc) => tnc.lingua === linguaUtente
    )
    if (index === -1) {
      index = 0
    }
    return this.tipiNonConformita === undefined ||
      this.tipiNonConformita === null ||
      this.tipiNonConformita[index]['testo'] === undefined ||
      this.tipiNonConformita[index]['testo']?.trim() === ''
      ? []
      : this.tipiNonConformita[index]['testo']?.split('\n')
  }

  addTipologia(event: MatChipInputEvent) {
    const value = event.value

    // individua il testo della lingua corrente
    const linguaUtente = this.authService.getLinguaUtente()
    // trovo l'indice per il tipoNonConformita nella lingua corrente
    let index = this.tipiNonConformita?.findIndex(
      (tnc) => tnc.lingua === linguaUtente
    )
    if (index === -1) {
      index = 0
    }

    if ((value || '').trim()) {
      //  Verifica se è un dominio valido

      // verifica che il testo di ciascun tipo non sia nullo se lo è lo inizializza a stringa vuota
      this.tipiNonConformita.forEach((tnc) => {
        if (tnc.testo === undefined || tnc.testo === null) {
          tnc.testo = ''
        }
      })

      this.tipiNonConformita[index].testo +=
        (this.tipiNonConformita !== undefined &&
          this.tipiNonConformita[index].testo !== undefined &&
          this.tipiNonConformita[index].testo !== null &&
          this.tipiNonConformita[index].testo.trim().length > 0
          ? '\n'
          : '') + value.trim()
      // aggiorno le traduzioni sul form
      let oldTesto = ''
      this.lingue.forEach((lingua) => {
        //  ricavo da testiTipiNonConformita il testo corrispondente alla lingua
        let testo = this.tipiNonConformita.filter(
          (tnc) => tnc.lingua === lingua
        )[0].testo
        if (testo === '' || testo == null) {
          testo = oldTesto
        }
        this.formConfigurazione.controls[
          'tipoNonConformita' + lingua
        ].patchValue(testo)
        oldTesto = testo
      })
      // marco il form come modificato
      // this.formConfigurazione.markAsDirty();
      this.salvaTipiNonConformita()
    }

    // Reset the input value
    event.chipInput?.clear()
  }

  salvaTipiNonConformita() {
    // usa un observer per salvare l'aggiornamento del tipo conformita
    this.configurazioneService
      .updateTipiNonConformita(this.tipiNonConformita)
      .subscribe(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            const hashTipiNonConformita = JSON.parse(esito.payload)['hash']
            if (hashTipiNonConformita) {
              this.sessionData.configurazione.hashTipiNonConformita =
                hashTipiNonConformita
            }
            this.snackBar.open(
              $localize`Elenco tipologie non conformità aggiornato con successo`,
              null,
              {
                duration: 8000,
              }
            )
          } else {
            this.snackBar.open(
              $localize`Errore durante l'aggiornamento delle tipologie non conformità`,
              null,
              {
                duration: 8000,
              }
            )
          }
        },
        (error) => {
          this.snackBar.open(
            $localize`Errore durante l'aggiornamento delle tipologie non conformità`,
            null,
            {
              duration: 8000,
            }
          )
        }
      )
  }

  removeTipologia(tipologia) {
    // chiedi conferma della cancellazione
    this.dialogService
      .confirm(
        $localize`Conferma eliminazione`,
        $localize`Sei sicuro di voler eliminare la tipologia ${tipologia}? Se confermi anche tutte le traduzioni verranno eliminate.`
      )
      .then((result) => {
        if (result) {
          // ricava dall'array tipiNonConformita quello con nome tipologia uguale a tipologia
          const tipologiaDaCancellare = this.tipiNonConformita.filter(
            (tnc) => tnc.testo === tipologia
          )[0]
          // rimuovi la tipologia dall'array tipiNonConformita

          // individua il testo della lingua corrente
          const linguaUtente = this.authService.getLinguaUtente()
          // trovo l'indice per il tipoNonConformita nella lingua corrente
          let index = this.tipiNonConformita?.findIndex(
            (tnc) => tnc.lingua === linguaUtente
          )
          if (index === -1) {
            index = 0
          }
          // trovo l'indice tipologia all'interno del testo
          const indiceTipologia = this.tipiNonConformita[index].testo
            .split('\n')
            .findIndex((tipo) => tipo === tipologia)

          // rimuove tutti i testi di tutte le lingue a partire dall'indice trovato
          this.tipiNonConformita.forEach((tnc) => {
            tnc.testo = tnc.testo
              .split('\n')
              .filter((tipo, index1) => index1 !== indiceTipologia)
              .join('\n')
          })

          // aggiorno le traduzioni sul form
          let oldTesto = ''
          this.lingue.forEach((lingua) => {
            //  ricavo da testiTipiNonConformita il testo corrispondente alla lingua
            let testo = this.tipiNonConformita.filter(
              (tnc) => tnc.lingua === lingua
            )[0].testo
            if (testo === '' || testo == null) {
              testo = oldTesto
            }
            this.formConfigurazione.controls[
              'tipoNonConformita' + lingua
            ].patchValue(testo)
            oldTesto = testo
          })
          this.salvaTipiNonConformita()
        }
      })
  }

  get configurazione(): Configurazione {
    return this.sessionData.configurazione
  }

  get tipoInformativa(): number {
    return TipoDocumento.TIPO_INFORMATIVA
  }

  get tipoAllegato(): number {
    return TipoDocumento.TIPO_ALLEGATO
  }

  aggiornaConfigurazione(event) {
    this.saving = true
    if (this.formConfigurazione.dirty) {
      if (
        this.formConfigurazione.controls['segnalanteVedeOdv'].dirty &&
        !this.formConfigurazione.controls['segnalanteVedeOdv'].value
      ) {
        this.formConfigurazione.controls['segnalantePuoScegliereOdv'].setValue(
          false
        )
      } else if (
        this.formConfigurazione.controls['segnalantePuoScegliereOdv'].dirty &&
        this.formConfigurazione.controls['segnalantePuoScegliereOdv'].value
      ) {
        this.formConfigurazione.controls['segnalanteVedeOdv'].setValue(true)
      }
      if (this.sceltaOdv) {
        this.sceltaOdv.nativeElement.disabled =
          !this.formConfigurazione.controls['segnalantePuoScegliereOdv'].value
      }

      this.sessionData.configurazione = {
        ...this.sessionData.configurazione,
        ...this.formConfigurazione.value,
      }
      this.configurazioneBan = {
        ...this.configurazioneBan,
        ...this.formConfigurazione.value,
      }
      const lunghezzaMinimaPinCalcolata =
        this.sessionData.configurazione.minuscolePIN +
        this.sessionData.configurazione.maiuscolePIN +
        this.sessionData.configurazione.caratteriSpecialiPIN +
        this.sessionData.configurazione.numeriPIN
      if (
        this.sessionData.configurazione.lunghezzaMinimaPIN <
        lunghezzaMinimaPinCalcolata
      ) {
        this.sessionData.configurazione.lunghezzaMinimaPIN =
          lunghezzaMinimaPinCalcolata
      }

      const lunghezzaMinimaPasswordCalcolata =
        this.sessionData.configurazione.minuscolePassword +
        this.sessionData.configurazione.maiuscolePassword +
        this.sessionData.configurazione.caratteriSpecialiPassword +
        this.sessionData.configurazione.numeriPassword
      if (
        this.sessionData.configurazione.lunghezzaMinimaPassword <
        lunghezzaMinimaPasswordCalcolata
      ) {
        this.sessionData.configurazione.lunghezzaMinimaPassword =
          lunghezzaMinimaPasswordCalcolata
      }

      this.sessionData.configurazione.pitchShifting =
        this.formConfigurazione.controls['pitch'].value

      this.sessionData.configurazione.mascheraTuttiGliAudio =
        this.formConfigurazione.controls['mascheraTuttiGliAudio'].value

      if (this.selectedUM1 !== $localize`giorni`) {
        if (this.selectedUM1 === $localize`mesi`) {
          this.sessionData.configurazione.giorniRetentionSegnalazioneMarcataDaCancellareDaGestore =
            this.sessionData.configurazione
              .giorniRetentionSegnalazioneMarcataDaCancellareDaGestore * 30
        } else {
          this.sessionData.configurazione.giorniRetentionSegnalazioneMarcataDaCancellareDaGestore =
            this.sessionData.configurazione
              .giorniRetentionSegnalazioneMarcataDaCancellareDaGestore * 365
        }
      }

      if (this.selectedUM2 !== $localize`giorni`) {
        if (this.selectedUM2 === $localize`mesi`) {
          this.sessionData.configurazione.giorniRetentionSegnalazione =
            this.sessionData.configurazione.giorniRetentionSegnalazione * 30
        } else {
          this.sessionData.configurazione.giorniRetentionSegnalazione =
            this.sessionData.configurazione.giorniRetentionSegnalazione * 365
        }
      }

      this.selectedUM1 = $localize`giorni`
      this.selectedUM2 = $localize`giorni`

      // forkJoin([this.getUpdateTestiTipiNonConformita()]).subscribe(
      //   (esiti: Esito[]) => {
      //     const tuttiGliEsitiOK = esiti.every(
      //       (esito) => esito.esito === ESITO_OK
      //     )
      //     if (!tuttiGliEsitiOK) {
      //       this.saving = false
      //       this.snackBar.open(
      //         $localize`Errore durante l'aggiornamento dei testi home`,
      //         $localize`Chiudi`,
      //         {
      //           duration: 5000,
      //         }
      //       )
      //       return
      //     }
      this.configurazioneService.aggiornaConfigurazioneBan
        (this.configurazioneBan).subscribe(
          (esito) => {
            if (esito.esito === ESITO_OK) {
              this.configurazioneBan = JSON.parse(esito.payload)
            } else {
              this.snackBar.open(
                $localize`Errore durante l'aggiornamento della configurazione Ban`,
                null,
                {
                  duration: 8000,
                }
              )
            }
          },
          (error) => {
            this.snackBar.open(
              $localize`Errore durante l'aggiornamento della configurazione Ban`,
              null,
              {
                duration: 8000,
              }
            )
          }
        )
      this.configurazioneService
        .salvaConfigurazione(this.sessionData.configurazione)
        .then((esito) => {
          this.saving = false
          if (esito.esito === ESITO_OK) {
            this.snackBar.open(
              $localize`Configurazione Aggiornata. `,
              $localize`Chiudi`,
              {
                duration: 5000,
              }
            )
            this.sessionData.configurazione = JSON.parse(esito.payload)
            this.formConfigurazione.reset()
            this.createForm()
          } else {
            this.snackBar.open(
              $localize`Errore nel salvataggio della configurazione. ` +
              esito.descrizioneEsito,
              null,
              {
                duration: 4000,
              }
            )
          }
        })
      this.saving = false
      //    }
      //  )
    } else {
      this.saving = false
      this.snackBar.open($localize`Nessuna dato modificato`, null, {
        duration: 4000,
      })
    }
  }

  private getUpdateTestiTipiNonConformita() {
    const testiTipiNonConformita = [] as Traduzioni[]
    this.lingue.forEach((lingua) => {
      const traduzione = new Traduzioni()
      traduzione.lingua = lingua
      traduzione.testo =
        this.formConfigurazione.controls['tipoNonConformita' + lingua]?.value
      testiTipiNonConformita.push(traduzione)
    })
    const updateTestiTipiNonConformita = this.configurazioneService
      .updateTipiNonConformita(testiTipiNonConformita)
      .pipe(
        tap((esito) => {
          if (esito.esito === ESITO_OK) {
            const hashTipiNonConformita = JSON.parse(esito.payload)['hash']
            if (hashTipiNonConformita) {
              this.sessionData.configurazione.hashTipiNonConformita =
                hashTipiNonConformita
            }
          }
        })
      )
    return updateTestiTipiNonConformita
  }

  aggiornaFormatiBinariDocumenti(configurazione: Configurazione) {
    if (configurazione.informativaNuovaSegnalazioneDocumento) {
      configurazione.informativaNuovaSegnalazioneDocumento.arrayBuffer =
        _base64ToArrayBuffer(
          configurazione.informativaNuovaSegnalazioneDocumento.base64
        )
    }
  }

  tabSelezionata(event) {
    // console.log(event)
    this._linguaSelezionata = this.lingue[0]

    this.tabSelezionato = event
  }

  focusInput(input) {
    setTimeout(() => input.focus())
  }

  togglePredefinito(destinatario: Odv) {
    if (
      this.destinatari.filter((odv) => odv.predefinito).length === 1 &&
      destinatario.predefinito
    ) {
      this.snackBar.open(
        $localize`E' obbligatorio almeno un gruppo predefinito`,
        null,
        {
          duration: 4000,
        }
      )
      return
    }
    if (destinatario.predefinito === null) {
      destinatario.predefinito = true
    } else if (destinatario.predefinito === false) {
      destinatario.predefinito = null
    } else {
      destinatario.predefinito = false
    }
    this.segnalazioniService
      .salvaGruppoOdv(destinatario)
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.snackBar.open($localize`Dati Destinatario Aggiornati. `, null, {
            duration: 4000,
          })
        } else {
          this.snackBar.open(
            $localize`Errore nel salvataggio del destinatario. ` +
            esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Errore nel salvataggio della configurazione. ` +
          errore.toString(),
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  aggiungiDocumento(
    contenitore: {
      attributo: string;
      pdfViewer: number;
      documento: VersioneContenuto;
    },
    sottotipoNumerico: number | string
  ) {
    this.configurazione[contenitore.attributo] = contenitore.documento

    // this.aggiornaConfigurazione(undefined)
    this.doAggiornaDocumento(contenitore.documento, sottotipoNumerico)
    this.onClickMostraDocumento(contenitore.pdfViewer)
  }

  aggiornaTesto(versioneContenuto: VersioneContenuto) {
    this.updateSubject.next(versioneContenuto) // Emetti il nuovo valore
  }

  eliminaDocumento(documento: VersioneContenuto) {
    this.contenutiService.deleteFileVersioneContenuto(+documento.id).subscribe(
      (esito) => {
        if (ESITO_OK === esito.esito) {
          const documentoTemp = JSON.parse(esito.payload)
          // usa il subjct versioneContenutoSubject di contenuti.service per aggiornare la lista dei documenti
          this.contenutiService.versioneContenutoSubject.next(documentoTemp)
          this.snackBar.open(
            $localize`La cancellazione del file è stata eseguita. `,
            null,
            {
              duration: 6000,
            }
          )
        } else {
          this.snackBar.open(
            $localize`La cancellazione del file è fallita` + esito.payload,
            null,
            {
              duration: 6000,
            }
          )
        }
      },
      (error) => {
        this.snackBar.open(
          $localize`La cancellazione del documento è fallita` + error,
          null,
          {
            duration: 6000,
          }
        )
      }
    )
  }

  salvaDocumento(documento: VersioneContenuto) {
    this.doAggiornaDocumento(documento)
  }

  doAggiornaDocumento(
    documento: VersioneContenuto,
    sottotipo?: number | string
  ) {
    // documento.lingua = this.linguaSelezionata
    if (documento.lingua === undefined) {
      documento.lingua = this.linguaSelezionata
    }
    // si accerta che ci sia una entità contenuto associiata al documento
    if (!documento.contenuto) {
      documento.contenuto = new Contenuto()
    }
    if (!documento.contenuto.tipo) {
      documento.contenuto.tipo = TipoContenuto.DOCUMENTO
    }
    // s sottotipo è un numro lo converte in stringa
    if (typeof sottotipo === 'number') {
      documento.contenuto.sottotipo = mapNumberToSottoTipoContenuto(sottotipo)
    }

    this.contenutiService
      .updateVersioneContenuto(documento, documento['file'])
      .subscribe(
        (esito) => {
          if (ESITO_OK === esito.esito) {
            // notifica il cambiamento del documento
            this.contenutiService.versioneContenutoSubject.next(
              JSON.parse(esito.payload)
            )
            this.snackBar.open(
              $localize`Modifica eseguita con successo. `,
              null,
              {
                duration: 6000,
              }
            )
          } else {
            this.snackBar.open(
              $localize`L'aggiunta del documento è fallita` + esito.payload,
              null,
              {
                duration: 6000,
              }
            )
          }
        },
        (error) => {
          this.snackBar.open(
            $localize`L'aggiunta del documento è fallita` + error,
            null,
            {
              duration: 6000,
            }
          )
        }
      )
  }

  sonoAttivo(sottotipo: SottoTipoContenuto) {
    // return true
    return this.sottotipoSelezionato === sottotipo // && this.tabPolicySelezionato
  }

  impostaSottotipoAttivo(sottotipo: SottoTipoContenuto) {
    // return true
    this.sottotipoSelezionato = sottotipo
  }

  onClickMostraDocumento(pdfViewer: number) {
    this.documentoAttivo = -1
    setTimeout(() => {
      this.documentoAttivo = pdfViewer
    }, 100)
  }

  toggleToolbar(editor) {
    console.log('editor:', editor)
    // console.log('toolbar:', toolbar)
    this.editorConfigs[editor].showToolbar =
      !this.editorConfigs[editor].showToolbar
  }

  openSorgentiTranslationDialog(sorgente: SorgenteSegnalazione): void {
    const dialogRef = this.dialog.open(TranslationDialogComponent, {
      width: '600px',
      data: {
        traduzioni: traduzioniAdapter(this.sorgenti, this.lingue),
        filtro: sorgente.nome,
        commaSeparated: false,
      },
    })

    dialogRef.afterClosed().subscribe((sorgenti) => {
      if (sorgenti) {
        // aggiorno le sorgenti nell'array sostituendo quelle modificate
        sorgenti.forEach((sorgente1) => {
          // se sorgente1 ha id nullo lo aggiungo a this.sorgenti
          if (!sorgente1.id) {
            sorgente1.nome = sorgente1.testo
            this.sorgenti.push(sorgente1)
          } else {
            const index = this.sorgenti.findIndex((s) => s.id === sorgente1.id)
            // se non trovo l'elemento, lo aggiungo a this.sorgenti
            if (index === -1) {
              sorgente1.nome = sorgente1.testo
              this.sorgenti.push(sorgente1)
            } else {
              sorgente1.nome = sorgente1.testo
              this.sorgenti[index] = sorgente1
            }
          }
        })
        // salva le sorgenti
        this.sorgenteSegnalazioneService
          .aggiornaSorgentiSegnalazione(this.sorgenti)
          .then((esito: Esito[]) => {
            if (esito[0].esito === ESITO_OK) {
              this.snackBar.open($localize`Dati Sorgenti Aggiornati. `, null, {
                duration: 4000,
              })
            } else {
              this.snackBar.open(
                $localize`Errore nel salvataggio delle sorgenti. ` +
                esito[0].descrizioneEsito,
                null,
                {
                  duration: 4000,
                }
              )
            }
          })
      }
    })
  }

  openTemiTranslationDialog(tema: Tema): void {
    const dialogRef = this.dialog.open(TranslationDialogComponent, {
      width: '600px',
      data: {
        traduzioni: traduzioniAdapter(this.temi, this.lingue),
        filtro: tema.tema,
        commaSeparated: false,
      },
    })

    dialogRef.afterClosed().subscribe((temi) => {
      if (temi) {
        // aggiorno i temi nell'array sostituendo quelle modificate
        temi.forEach((tema1) => {
          // se sorgente1 ha id nullo lo aggiungo a this.sorgenti
          tema1.tema = tema1.testo
          if (!tema1.id) {
            this.temi.push(tema1)
          } else {
            const index = this.temi.findIndex((s) => s.id === tema1.id)
            // se non trovo l'elemento, lo aggiungo a this.sorgenti
            if (index === -1) {
              this.temi.push(tema1)
            } else {
              this.temi[index] = tema1
            }
          }
        })
        // salva i temi
        this.temiService.aggiornaTemi(this.temi).then((esito: Esito[]) => {
          if (esito[0].esito === ESITO_OK) {
            this.snackBar.open($localize`Temi Aggiornati. `, null, {
              duration: 4000,
            })
          } else {
            this.snackBar.open(
              $localize`Errore nel salvataggio dei Temi. ` +
              esito[0].descrizioneEsito,
              null,
              {
                duration: 4000,
              }
            )
          }
        })
      }
    })
  }

  openAzioniTranslationDialog(azione: AzioniFollowUp): void {
    const dialogRef = this.dialog.open(TranslationDialogComponent, {
      width: '600px',
      data: {
        traduzioni: traduzioniAdapter(this.azioni, this.lingue),
        filtro: azione.azione,
        commaSeparated: false,
      },
    })

    dialogRef.afterClosed().subscribe((azioni) => {
      if (azioni) {
        // aggiorno l'array sostituendo quelle modificate
        azioni.forEach((azione1) => {
          // se sorgente1 ha id nullo lo aggiungo a this.sorgenti
          azione1.azione = azione1.testo
          if (!azione1.id) {
            this.azioni.push(azione1)
          } else {
            const index = this.azioni.findIndex((s) => s.id === azione1.id)
            // se non trovo l'elemento, lo aggiungo a this.sorgenti
            if (index === -1) {
              this.azioni.push(azione1)
            } else {
              this.azioni[index] = azione1
            }
          }
        })
        // salva le azioni
        this.azioniService
          .aggiornaAzioniFollowUp(this.azioni)
          .then((esito: Esito[]) => {
            if (esito[0].esito === ESITO_OK) {
              this.snackBar.open(
                $localize`Azioni di Folloup Aggiornate. `,
                null,
                {
                  duration: 4000,
                }
              )
            } else {
              this.snackBar.open(
                $localize`Errore nel salvataggio delle Azioni di Followup. ` +
                esito[0].descrizioneEsito,
                null,
                {
                  duration: 4000,
                }
              )
            }
          })
      }
    })
  }
  openTipiNonConformitaTranslationDialog(text): void {
    // Prima carica tutte le traduzioni disponibili
    this.configurazioneService
      .recuperaTipiNonConformita()
      .pipe(
        filter((esito: Esito) => esito.esito === ESITO_OK),
        map((esito: Esito) => esito.payload),
        catchError((error) => {
          console.error(
            'Errore nella richiesta recuperaTipiNonConformita',
            error
          )
          return of(null)
        })
      )
      .subscribe((payload) => {
        const tipiNonConformita = JSON.parse(payload) as Traduzioni[]
        this.sessionData.configurazione.tipiNonConformita =
          this.tipiNonConformita
        const dialogRef = this.dialog.open(TranslationDialogComponent, {
          width: '600px',
          data: {
            traduzioni: tipiNonConformita,
            filtro: text,
            commaSeparated: true,
          },
        })

        dialogRef.afterClosed().subscribe((testiTipiNonConformita) => {
          if (testiTipiNonConformita) {
            // ricavo la lingua dell'utente
            const linguaUtente = this.authService.getLinguaUtente()
            // aggiorno le traduzioni sul form
            this.lingue.forEach((lingua) => {
              //  ricavo da testiTipiNonConformita il testo corrispondente alla lingua
              const testo = testiTipiNonConformita.find(
                (traduzione) => traduzione.lingua === lingua
              ).testo
              this.formConfigurazione.controls[
                'tipoNonConformita' + lingua
              ].patchValue(testo)

              // aggiorno tipiNonConformita
              this.tipiNonConformita.forEach((tipoNonConformita) => {
                if (tipoNonConformita.lingua === lingua) {
                  tipoNonConformita.testo = testo
                }
              })
            })

            const updateTestiTipiNonConformita = this.configurazioneService
              .updateTipiNonConformita(testiTipiNonConformita)
              .pipe(
                tap((esito) => {
                  if (esito.esito === ESITO_OK) {
                    const hashTipiNonConformita = JSON.parse(esito.payload)[
                      'hash'
                    ]
                    if (hashTipiNonConformita) {
                      this.sessionData.configurazione.hashTipiNonConformita =
                        hashTipiNonConformita
                    }
                  }
                })
              )
            updateTestiTipiNonConformita.subscribe(
              (esito) => {
                if (esito.esito === ESITO_OK) {
                  this.snackBar.open(
                    $localize`Le traduzioni sono state aggiornate`,
                    null,
                    {
                      duration: 6000,
                    }
                  )
                } else {
                  this.snackBar.open(
                    $localize`Le traduzioni non sono state aggiornate`,
                    null,
                    {
                      duration: 6000,
                    }
                  )
                }
              },
              (error) => {
                this.snackBar.open(
                  $localize`Le traduzioni non sono state aggiornate`,
                  null,
                  {
                    duration: 6000,
                  }
                )
              }
            )
          }
        })
      })
  }

  cambiaLinguaFaq(lingua) {
    console.log(lingua.value)
  }
  testoTooltipDestinatario(destinatario): any {
    return destinatario.predefinito
      ? $localize`Destinatario predefinito`
      : destinatario.predefinito === null
        ? ''
        : $localize`Destinatario alternativo nel caso di conflitto di interessi in capo al predefinito`
  }

  testoTooltipDestinatarioSocieta(destinatario): any {
    return destinatario.destinatarioPredefinito
      ? $localize`Destinatario predefinito`
      : destinatario.destinatarioPredefinito === null
        ? ''
        : $localize`Destinatario alternativo nel caso di conflitto di interessi in capo al predefinito`
  }

  recuperaTutteLeSorgentiSegnalazione(): any {
    this.sorgenteSegnalazioneService
      .recuperaSorgentiSegnalazione()
      .then((esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nel recupero delle sorgenti segnalazione`,
            null,
            {
              duration: 6000,
            }
          )

          return
        }
        const data = JSON.parse(esito.payload)
        this.sorgenti = data
        this.refreshForm()
      })
  }

  filteredSorgenti(): SorgenteSegnalazione[] {
    const linguaUtente = this.authService.getLinguaUtente()
    const result = this.sorgenti?.filter(
      (sorgente) => sorgente.lingua === linguaUtente
    )
    return result
  }

  addSorgente(event: MatChipInputEvent) {
    // prendo l'input MatChipInputEvent#chipInput.inputElement
    const nuovaSorgente = event.value
    if (nuovaSorgente) {
      // verifico che non sia già presente
      const sorgentePresente = this.sorgenti?.find(
        (sorgente) =>
          sorgente.lingua === this.authService.getLinguaUtente() &&
          sorgente.descrizione === nuovaSorgente
      )
      if (sorgentePresente) {
        this.snackBar.open(
          $localize`La sorgente segnalazione è già presente`,
          null,
          {
            duration: 6000,
          }
        )
        return
      }
      const nuovaSorgenteSegnalazione = new SorgenteSegnalazione()
      nuovaSorgenteSegnalazione.nome = nuovaSorgente
      nuovaSorgenteSegnalazione.lingua = this.authService.getLinguaUtente()
      this.sorgenteSegnalazioneService
        .creaSorgenteSegnalazione(nuovaSorgenteSegnalazione)
        .then((esito) => {
          if (esito.esito !== ESITO_OK) {
            this.snackBar.open(
              $localize`Errore nell'aggiunta della sorgente segnalazione`,
              null,
              {
                duration: 6000,
              }
            )

            return
          }
          // pulisco il campo
          event.chipInput?.clear()
          this.recuperaTutteLeSorgentiSegnalazione()
          this.snackBar.open(
            $localize`Sorgente segnalazione aggiunta con successo`,
            null,
            {
              duration: 6000,
            }
          )
        })
        .catch(() => {
          this.snackBar.open(
            $localize`Errore nell'aggiunta della sorgente segnalazione`,
            null,
            {
              duration: 6000,
            }
          )
        })
    }
  }

  removeSorgente(sorgenteDaRimuovere: SorgenteSegnalazione) {
    // verifico che sorgente sia presente
    const sorgentePresente = this.sorgenti?.find(
      (sorgente: SorgenteSegnalazione) =>
        sorgente.lingua === this.authService.getLinguaUtente() &&
        sorgente.nome === sorgenteDaRimuovere.nome
    )
    if (!sorgentePresente) {
      this.snackBar.open(
        $localize`La sorgente segnalazione non è presente`,
        null,
        {
          duration: 6000,
        }
      )
      return
    } else {
      this.sorgenteSegnalazioneService
        .cancellaSorgenteSegnalazione(sorgentePresente)
        .then((esito) => {
          this.handleCancellaSorgente(esito)
        })
    }
  }

  handleCancellaSorgente(esito) {
    if (esito.esito !== ESITO_OK) {
      if (esito.esito === 1) {
        this.snackBar.open(
          $localize`La sorgente segnalazione è utilizzata e non è possibile eliminarla`,
          null,
          {
            duration: 6000,
          }
        )
      } else {
        this.snackBar.open(
          $localize`Errore nella rimozione della sorgente segnalazione`,
          null,
          {
            duration: 6000,
          }
        )
      }

      return
    }
    this.recuperaTutteLeSorgentiSegnalazione()
    this.snackBar.open(
      $localize`Sorgente segnalazione rimossa con successo`,
      null,
      {
        duration: 6000,
      }
    )
  }

  recuperaTuttiITemi(): any {
    this.temiService.recuperaTemi().then((esito) => {
      if (esito.esito !== ESITO_OK) {
        this.snackBar.open($localize`Errore nel recupero dei Temi`, null, {
          duration: 6000,
        })

        return
      }
      const data = JSON.parse(esito.payload)
      this.temi = data
      this.refreshForm()
    })
  }

  recuperaTutteLeAzioni(): any {
    this.azioniService.recuperaAzioniFollowUp().then((esito) => {
      if (esito.esito !== ESITO_OK) {
        this.snackBar.open(
          $localize`Errore nel recupero delle Azioni Follow Up`,
          null,
          {
            duration: 6000,
          }
        )

        return
      }
      const data = JSON.parse(esito.payload)
      this.azioni = data
      this.refreshForm()
    })
  }

  filteredTemi(): Tema[] {
    const linguaUtente = this.authService.getLinguaUtente()
    const result = this.temi?.filter((tema) => tema.lingua === linguaUtente)
    return result
  }

  filteredAzioni(): AzioniFollowUp[] {
    const linguaUtente = this.authService.getLinguaUtente()
    const result = this.azioni?.filter(
      (azione) => azione.lingua === linguaUtente
    )
    return result
  }

  addTema(event: MatChipInputEvent) {
    // prendo l'input MatChipInputEvent#chipInput.inputElement
    const nuovo = event.value
    if (nuovo) {
      // verifico che non sia già presente
      const temaPresente = this.temi?.find(
        (tema) =>
          tema.lingua === this.authService.getLinguaUtente() &&
          tema.tema === nuovo
      )
      if (temaPresente) {
        this.snackBar.open($localize`Il Tema è già presente`, null, {
          duration: 6000,
        })
        return
      }
      const nuovoTema = new Tema()
      nuovoTema.tema = nuovo
      nuovoTema.lingua = this.authService.getLinguaUtente()
      this.temiService.creaTema(nuovoTema).then((esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open($localize`Errore nell'aggiunta del Tema`, null, {
            duration: 6000,
          })

          return
        }
        // pulisco il campo
        event.chipInput?.clear()
        this.recuperaTuttiITemi()
        this.snackBar.open($localize`Tema aggiunta con successo`, null, {
          duration: 6000,
        })
      })
    }
  }

  addAzione(event: MatChipInputEvent) {
    // prendo l'input MatChipInputEvent#chipInput.inputElement
    const nuovo = event.value
    if (nuovo) {
      // verifico che non sia già presente
      const azionePresente = this.azioni?.find(
        (azione) =>
          azione.lingua === this.authService.getLinguaUtente() &&
          azione.azione === nuovo
      )
      if (azionePresente) {
        this.snackBar.open($localize`L'azione è già presente`, null, {
          duration: 6000,
        })
        return
      }
      const nuovaAzione = new AzioniFollowUp()
      nuovaAzione.azione = nuovo
      nuovaAzione.lingua = this.authService.getLinguaUtente()
      this.azioniService.creaAzioniFollowUp(nuovaAzione).then((esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nell'aggiunta dell'azione`,
            null,
            {
              duration: 6000,
            }
          )

          return
        }
        // pulisco il campo
        event.chipInput?.clear()
        this.recuperaTutteLeAzioni()
        this.snackBar.open($localize`Azione aggiunta con successo`, null, {
          duration: 6000,
        })
      })
    }
  }

  removeTema(temaDaRimuovere: Tema) {
    // verifico che sia presente
    const temaPresente = this.temi?.find(
      (tema: Tema) =>
        tema.lingua === this.authService.getLinguaUtente() &&
        tema.tema === temaDaRimuovere.tema
    )
    if (!temaPresente) {
      this.snackBar.open($localize`Tema non trovato`, null, {
        duration: 6000,
      })
      return
    } else {
      this.temiService.cancellaTema(temaPresente).then((esito) => {
        if (esito.esito !== ESITO_OK) {
          if (esito.esito === 1) {
            this.snackBar.open(
              $localize`Il Tema è utilizzato e non è possibile eliminarlo`,
              null,
              {
                duration: 6000,
              }
            )
          } else {
            this.snackBar.open(
              $localize`Errore nella rimozione del Tema`,
              null,
              {
                duration: 6000,
              }
            )
          }

          return
        }
        this.recuperaTuttiITemi()
        this.snackBar.open($localize`Tema rimosso con successo`, null, {
          duration: 6000,
        })
      })
    }
  }

  removeAzione(azioneDaRimuovere: AzioniFollowUp) {
    // verifico che sia presente
    const azionePresente = this.azioni?.find(
      (azione: AzioniFollowUp) =>
        azione.lingua === this.authService.getLinguaUtente() &&
        azione.azione === azioneDaRimuovere.azione
    )
    if (!azionePresente) {
      this.snackBar.open($localize`Azione non trovata`, null, {
        duration: 6000,
      })
      return
    } else {
      this.azioniService
        .cancellaAzioniFollowUp(azionePresente)
        .then((esito) => {
          if (esito.esito !== ESITO_OK) {
            if (esito.esito === 1) {
              this.snackBar.open(
                $localize`L'azione è utilizzata e non è possibile eliminarla`,
                null,
                {
                  duration: 6000,
                }
              )
            } else {
              this.snackBar.open(
                $localize`Errore nella rimozione dell'azione`,
                null,
                {
                  duration: 6000,
                }
              )
            }

            return
          }
          this.recuperaTutteLeAzioni()
          this.snackBar.open($localize`Azione rimossa con successo`, null, {
            duration: 6000,
          })
        })
    }
  }

  caricaDestinatariPredefiniti(tipoSegnalazione) {
    this.tipologiaSelezionata = tipoSegnalazione
    this.destinatariFiltrati = []

    const hashTipoSegnalazione = this.getHashTipoSegnalazione(tipoSegnalazione)

    this.segnalazioniService
      .getDestinatariPredefinitiPerTipologiaSegnalazione(tipoSegnalazione)
      .subscribe((esito: Esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nel recupero dei destinatari`,
            null,
            {
              duration: 6000,
            }
          )
          return
        }
        this.destinatariTipologiaFiltrati = JSON.parse(esito.payload)
        // this.configurazione.indirizzamentoSegnalazioniGruppiPredefiniti = true
        this.refreshForm()
      })
  }

  caricaDestinatariPerSocieta(societa) {
    if (!societa) {
      return
    }
    this.societaSelezionata = societa
    this.destinatariFiltratiSocieta = []

    this.segnalazioniService
      .getDestinatariPerSocieta(societa)
      .subscribe((esito: Esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nel recupero dei destinatari`,
            null,
            {
              duration: 6000,
            }
          )
          return
        }
        this.destinatariFiltratiSocieta = JSON.parse(esito.payload)
        // this.configurazione.indirizzamentoSegnalazioniGruppiPredefiniti = true
        this.refreshForm()
      })
  }

  getHashTipoSegnalazione(tipoSegnalazione) {
    const tipoNonConformita = this.tipiNonConformita.find((tipo) => {
      const tipi1 = tipo.testo?.split('\n')
      // cerco se tra i tipi è presente il tipo selezionato
      return tipi1.includes(tipoSegnalazione)
    })
    if (!tipoNonConformita) {
      return
    }

    const tipi = tipoNonConformita.testo?.split('\n')
    // ricavo l'indice del tipo selezionato
    const index = tipi.indexOf(tipoSegnalazione)
    const hash = tipoNonConformita.hash

    const hashTipo = hash + '_' + index
    return hashTipo
  }

  addDestinatario(event: MatOptionSelectionChange, destinatario) {
    if (event.isUserInput) {
      // Update the selected recipient.
      this.destinatarioSelezionato = destinatario

      // Update the list of recipients for the selected type of report.
      const destinatarioPerTipologia = new DestinatariPerTipologia()
      destinatarioPerTipologia.hash = this.getHashTipoSegnalazione(
        this.tipologiaSelezionata
      )
      destinatarioPerTipologia.odv = destinatario
      this.segnalazioniService
        .salvaDestinatariPerTipologiaSegnalazione(destinatarioPerTipologia)
        .subscribe((esito: Esito) => {
          if (esito.esito !== ESITO_OK) {
            this.snackBar.open(
              $localize`Errore nel salvataggio dei destinatari`,
              null,
              {
                duration: 6000,
              }
            )
            return
          }
          this.caricaDestinatariPredefiniti(this.tipologiaSelezionata)
          this.snackBar.open(
            $localize`Destinatario salvato con successo`,
            null,
            {
              duration: 6000,
            }
          )
        })
    }
  }

  toggleDestinatarioPreferito(event, destinatario: OdvAzienda) {
    this.chipClicked = true
    if (destinatario.destinatarioPredefinito === null) {
      destinatario.destinatarioPredefinito = true
    } else if (destinatario.destinatarioPredefinito === false) {
      destinatario.destinatarioPredefinito = null
    } else {
      destinatario.destinatarioPredefinito = false
    }
    this.segnalazioniService
      .salvaDestinatariPerAzienda(
        destinatario.azienda,
        destinatario.odv,
        destinatario.destinatarioPredefinito
      )
      .subscribe((esito: Esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nel salvataggio dei destinatari`,
            null,
            {
              duration: 6000,
            }
          )
          return
        }
        this.caricaDestinatariPerSocieta(this.societaSelezionata)
        this.snackBar.open($localize`Destinatario salvato con successo`, null, {
          duration: 6000,
        })
      })
    setTimeout(() => {
      this.chipClicked = false
    }, 50)
  }

  toggleDestinatarioPerTipologiaPreferito(
    event,
    destinatario: DestinatariPerTipologia
  ) {
    this.chipClicked = true
    if (destinatario.predefinito === null) {
      destinatario.predefinito = true
    } else if (destinatario.predefinito === false) {
      destinatario.predefinito = null
    } else {
      destinatario.predefinito = false
    }
    this.segnalazioniService
      .salvaDestinatariPerTipologiaSegnalazione(destinatario)
      .subscribe((esito: Esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nel salvataggio dei destinatari`,
            null,
            {
              duration: 6000,
            }
          )
          return
        }
        this.snackBar.open($localize`Destinatario salvato con successo`, null, {
          duration: 6000,
        })
      })
    setTimeout(() => {
      this.chipClicked = false
    }, 50)
  }

  addDestinatarioSocieta(event: MatOptionSelectionChange, destinatario) {
    if (event.isUserInput) {
      // Update the selected recipient.
      this.destinatarioSelezionato = destinatario

      this.segnalazioniService
        .salvaDestinatariPerAzienda(this.societaSelezionata, destinatario, true)
        .subscribe((esito: Esito) => {
          if (esito.esito !== ESITO_OK) {
            this.snackBar.open(
              $localize`Errore nel salvataggio dei destinatari`,
              null,
              {
                duration: 6000,
              }
            )
            return
          }
          this.caricaDestinatariPerSocieta(this.societaSelezionata)
          this.snackBar.open(
            $localize`Destinatario salvato con successo`,
            null,
            {
              duration: 6000,
            }
          )
        })
    }
  }

  removeDestinatarioTipologia(event, destinatario: DestinatariPerTipologia) {
    this.chipRemoveClicked = true
    this.destinatarioSelezionato = destinatario

    // chiedi conferma della cancellazione
    this.dialogService
      .confirm(
        $localize`Conferma eliminazione`,
        $localize`Sei sicuro di voler eliminare il destinatario?`
      )
      .then((result) => {
        if (result) {
          this.segnalazioniService
            .rimuoviDestinatariPerTipologiaSegnalazione(destinatario)
            .subscribe((esito: Esito) => {
              if (esito.esito !== ESITO_OK) {
                this.snackBar.open(
                  $localize`Errore nella rimozione del destinatario`,
                  null,
                  {
                    duration: 6000,
                  }
                )
                return
              }
              this.snackBar.open(
                $localize`Destinatario rimosso con successo`,
                null,
                {
                  duration: 6000,
                }
              )
              this.destinatarioSelezionato = undefined
              this.caricaDestinatariPredefiniti(this.tipologiaSelezionata)
            })
          setTimeout(() => {
            this.chipRemoveClicked = false
          }, 50)
        }
      })
  }

  removeDestinatarioSocieta(event, destinatario) {
    event.preventDefault()
    this.chipRemoveClicked = true
    this.destinatarioSelezionato = destinatario.odv

    this.segnalazioniService
      .rimuoviDestinatariPerSocieta(
        this.destinatarioSelezionato.id,
        this.societaSelezionata
      )
      .subscribe((esito: Esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore nella rimozione del destinatario`,
            null,
            {
              duration: 6000,
            }
          )
          return
        }
        this.snackBar.open($localize`Destinatario rimosso con successo`, null, {
          duration: 6000,
        })
        this.destinatarioSelezionato = undefined
        this.caricaDestinatariPerSocieta(this.societaSelezionata)
      })
    setTimeout(() => {
      this.chipRemoveClicked = false
    }, 50)
  }

  getNonSelectedDestinatari(): any[] {
    let destinatari = this.destinatari
    if (this.destinatariFiltrati.length > 0) {
      destinatari = destinatari.filter(
        (destinatario) =>
          !this.destinatariFiltrati.some(
            (destFiltrato) => destFiltrato.nomeOdv === destinatario.nomeOdv
          )
      )
    }
    return destinatari
  }

  getNonSelectedDestinatariSocieta(): any[] {
    let destinatari = this.destinatari
    if (this.destinatariFiltratiSocieta.length > 0) {
      destinatari = destinatari.filter(
        (destinatario) =>
          !this.destinatariFiltratiSocieta.some(
            (destFiltrato) => destFiltrato.odv.nomeOdv === destinatario.nomeOdv
          )
      )
    }
    return destinatari
  }

  indirizzamentoSegnalazioniGruppiPredefinitiChange(event) {
    const indirizzamentoSegnalazioniGruppiPredefiniti =
      event && event.checked ? event.checked : false
    this.configurazione.indirizzamentoSegnalazioniGruppiPredefiniti =
      indirizzamentoSegnalazioniGruppiPredefiniti
    // marca il form dirty
    this.formConfigurazione.markAsDirty()
    // this.refreshForm()
  }

  societaRiferimentoFattoChange(event) {
    const valore = event && event.checked ? event.checked : false
    this.configurazione.mostraSocietaACuiSiRiferisceIlFattoSuDettaglioSegnalazione =
      valore
    // marca il form dirty
    this.formConfigurazione.markAsDirty()
    // this.refreshForm()
  }

  mostraTipiNonConformitaChange(event) {
    const valore = event && event.checked ? event.checked : false
    this.configurazione.mostraTipiNonConformitaSuDettaglioSegnalazione = valore
    // marca il form dirty
    this.formConfigurazione.markAsDirty()
    // this.refreshForm()
  }

  changeLanguage(
    lingua: string,
    sottotipoDocumento: SottoTipoContenuto
  ) {
    // Aggiorna il contenuto in base alla lingua selezionata
    // Esempio:
    this.sottotipoSelezionato = sottotipoDocumento
    this._linguaSelezionata = lingua
  }

  get linguaSelezionata(): string {
    return this._linguaSelezionata
  }

  onContentChange(event, tipoDocumento) {
    console.log('onContentChange', event, tipoDocumento)
  }

  checkChanges(event, tipoDocumento) {
    console.log('checkChanges', event.target.innerHTML, tipoDocumento)
  }

  get cambioPassword() {
    return $localize` Cambio Password `
  }

  get benvenuto() {
    return $localize` Messaggio di benvenuto `
  }

  get benvenutoSso() {
    return $localize` Messaggio di benvenuto per utente Single Sign On `
  }

  get verificaPosta() {
    return $localize` Notifica per Verifica Casella di Posta `
  }

  get verificaPostaSso() {
    return $localize` Notifica per Verifica Casella di Posta degli utenti Single Sign On `
  }

  get resetPin() {
    return $localize` Notifica per Reset PIN `
  }

  get nuovaSegnalazione() {
    return $localize` Notifica la creazione di una nuova Segnalazione `
  }

  get statoSegnalazione() {
    return $localize` Notifica il cambio di stato della Segnalazione `
  }

  get statoLavorazione() {
    return $localize` Notifica il cambio di stato della Lavorazione `
  }

  get inoltroLavorazione() {
    return $localize` Notifica Inoltro Lavorazione `
  }

  get inoltroSegnalazione() {
    return $localize` Notifica l'inoltro della Segnalazione `
  }

  get annullamentoInoltroSegnalazione() {
    return $localize` Notifica annullamento inoltro della Segnalazione `
  }

  get nuovoMessaggio() {
    return $localize` Notifica l'arrivo di un nuovo Messaggio `
  }

  get azioniSegnalante() {
    return $localize` Notifica la presenza di azioni in carico al Segnalante `
  }

  get richiestaSbloccoIdentita() {
    return $localize` Notifica la presenza di nuove richieste di sblocco identità segnalante verso il custode `
  }

  get negazioneSbloccoIdentita() {
    return $localize` Notifica negazione alla consultazione dell'identità segnalante `
  }

  get concessioneSbloccoIdentita() {
    return $localize` Notifica la concessione alla consultazione dell'identità segnalante `
  }

  get richiestaConsensoSegnalante() {
    return $localize` Notifica la richiesta di un nuovo consenso al segnalante `
  }

  get revocaRichiestaConsensoSegnalante() {
    return $localize` Notifica la revoca della richiesta di un nuovo consenso al segnalante `
  }

  get negazioneConsensoSegnalante() {
    return $localize` Notifica la negazione di un consenso da parte del segnalante `
  }

  get accettazioneConsensoSegnalante() {
    return $localize` Notifica l'accettazione di un consenso da parte del segnalante `
  }

  get revocaConsensoVocaleChat() {
    return $localize` Notifica la revoca del consenso vocale chat `
  }

  get scadenzaChiusuraIndagine() {
    return $localize` Avviso di prossima scadenza per la chiusura dell'indagine `
  }

  get notificaResetMFA() {
    return $localize` Email per l'attivazione del doppio fattore di autenticazione `
  }

  get raggiuntaScadenzaChiusuraIndagine() {
    return $localize` Avviso di sopravvenuta scadenza per la chiusura dell'indagine `
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.fileToUpload = event.target.files[0]
      this.fileName = this.fileToUpload.name
      if (!validateLandingVideoFileName(this.fileName)) {
        this.snackBar.open($localize`Il nome del file non è valido`, null, {
          duration: 6000,
        })
        // resetto il file
        this.fileToUpload = undefined
        this.fileName = undefined
      }
    }
  }

  submit() {
    if (validateLandingVideoFileName(this.fileName)) {
      this.saving = true
      this.videoService.landingPageVideoUpload(this.fileToUpload).subscribe(
        (esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            this.snackBar.open($localize`Video caricato con successo`, null, {
              duration: 6000,
            })
          }
          this.saving = false
        },
        (error) => {
          this.snackBar.open(
            $localize`Errore nel caricamento del video`,
            null,
            {
              duration: 6000,
            }
          )
          this.saving = false
        }
      )
    } else {
      this.snackBar.open($localize`Il nome del file non è valido`, null, {
        duration: 6000,
      })
    }
  }

  get motif() {
    // verifica se il dominio dell'email dell'utente è moti-f.it
    return this.authService.isMotifUser()
  }

  loadClientConfigurations() {
    this.configurazioneService
      .loadClientConfigurations()
      .subscribe((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.snackBar.open(
            $localize`Configurazioni caricate con successo`,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
  }
}
