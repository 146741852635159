<div class="theme">
  <h2 mat-dialog-title class="titolo-dialog">
    Nuova chat
  </h2>
  <mat-dialog-content>
    <div class="centra-form">
      <div class="flex-row flex-100">
        <div class="flex-row flex-fill">
          <form [formGroup]="formChats" style="width:100%">
            <div class="flex-column flex-space-around">
              <div class="flex-row">
                <mat-form-field class="flex-fill" color="accent">
                  <input type="text" #nome i18n-placeholder placeholder="Nome nuova Chat" aria-label="Number" matInput
                    formControlName="chat" />
                </mat-form-field>
              </div>
              <div class="flex-row">
                <mat-form-field *ngIf="nome.value != ''" color="accent" style="display: block;width:95%;">
                  <mat-select formControlName="odv" i18n-placeholder placeholder="Partecipa o Modera come..."
                    [compareWith]="doChatCompareFn">
                    <mat-option *ngFor="
                            let dirittoSegnalazione of diritti" (onSelectionChange)="
                            onGruppoSelezionato(
                              $event,
                              dirittoSegnalazione.odvDestinatario
                            )
                          " [value]="dirittoSegnalazione">
                      {{ dirittoSegnalazione.odvDestinatario.nomeOdv }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex-row">
                <mat-form-field *ngIf="nome.value != ''" color="accent" style="display: block;width:95%;">
                  <mat-select formControlName="lavorazione" i18n-placeholder placeholder="Lavorazione associata..."
                    [compareWith]="doChatCompareFn">
                    <mat-option *ngFor="
                            let lavorazione of lavorazioni" (onSelectionChange)="
                            onLavorazioneSelezionato(
                              $event,
                              lavorazione
                            )
                          " [value]="lavorazione">
                      {{labelLavorazione(lavorazione)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div style="margin-bottom: 20px;"></div>
          </form>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex-row flex-space-around flex-fill">
      <button i18n class="flex-fill" mat-button mat-dialog-close>Annulla</button>
      <button i18n class="flex-fill" [disabled]="!nome.value" mat-button (click)="onAddChat()">Crea</button>
    </div>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  </mat-dialog-actions>
</div>
