import { Injectable } from '@angular/core'

import { Router, NavigationEnd } from '@angular/router'

import { filter } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
  })
export class RoutingState {
  private history = []

  constructor(
    private router: Router
  ) {}

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects]
      })
  }

  public getHistory(): string[] {
    return this.history
  }

  public getPreviousUrl(): string {
    const previousUrl = this.history[this.history.length - 2] as string
    if (previousUrl !== undefined && previousUrl.startsWith('/home')) {
      return '/home'
    } else {
      return this.history[this.history.length - 2] || '/index'
    }

  }

}
