import { User } from './user'
import { Segnalazione } from './segnalazione'
import { Documento } from './documento'
import { Chat } from './chat'
import { ChatMessageInfo } from './chat-message-info'


export class Messaggio {

    id: string

    mittente: User

    segnalazione: Segnalazione

    testo: string

    dataInvio: Date

    allegati: Documento[]

    consegnato: boolean

    letto: boolean

    chat: Chat

    audioHash: string
    audioCifrato: boolean
    statoInvii: ChatMessageInfo[]
    statiComeSegnalante: ChatMessageInfo[]
    static scs?(messaggio): ChatMessageInfo[] {
      if (false && messaggio.statiComeSegnalante) {
        return messaggio.statiComeSegnalante
      } else {
        // recupera tutti i gruppi partecipanti della chat
        const gruppi = messaggio.chat.gruppiPartecipanti ? messaggio.chat.gruppiPartecipanti : []
        const stati = []
        // ciclo su tutti i gruppi partecipanti
        for (const gruppo of gruppi) {
          // creo uno stato per ogni gruppo
          const cmi = new ChatMessageInfo()
          stati.push(cmi)
          cmi.gruppo = gruppo
          // Verifico se esiste almeno un utente del gruppo che ha letto il messaggio
          // messaggio.statoInvii[].utente.odvMembership[]
          // il gruppo è un odv ed è legato a più utenti
          // attraverso la relazione utenti
          // ciclo su tutti gli utenti del gruppo
          const gestori = []
          for (const utente of gruppo.utenti) {
            // ciclo su tutti gli stati di invio del messaggio
            for (const statoInvio of messaggio.statoInvii) {
              // se lo stato di invio è relativo all'utente
              if (statoInvio.utente.id === utente.id && statoInvio.delivered) {
                // aggiungo l'utente alla lista dei gestori
                gestori.push(gruppo)
                break
              }
            }
          }
          cmi.delivered = true
          // se c'è almeno un gestore che ha letto il messaggio
          if (gestori.length > 0) {
            cmi.read = true
          } else {
            cmi.read = false
          }

        }
        messaggio.statiComeSegnalante = stati
        return stati
      }
    }

}
