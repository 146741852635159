<div class="alternate-theme">
  <h1 class="text-center" i18n>Invio Segnalazione</h1>
  <div class="flex-column flex-center-center gap-20">
    <mat-checkbox class="flex-fill" i18n matInput *ngIf="chiediConsenso" [(ngModel)]="segnalante.possoUsareEmailPerNotifiche">Voglio ricevere notifiche sulla segnalazione all'indirizzo email fornito</mat-checkbox>
    <mat-form-field class="width-full flex-fill" *ngIf="possoUsareEmailPerNotifiche">
      <mat-label i18n>Indirizzo email fornito nel modulo di segnalazione</mat-label>
      <input matInput [(ngModel)]="segnalante.email" disabled>
    </mat-form-field>
    <mat-form-field class="width-full flex-fill" *ngIf="possoUsareEmailPerNotifiche">
      <mat-label i18n>Conferma l'indirizzo email</mat-label>
      <input matInput i18n-placeholder placeholder="Conferma l'indirizzo email" *ngIf="possoUsareEmailPerNotifiche"
             [(ngModel)]="confermaEmail">
    </mat-form-field>
    <h3 class="flex-fill top-20" i18n>
      Confermi di voler inviare la segnalazione ?
    </h3>
  </div>
  <div class="flex-row flex-center-space-around">
    <button mat-button [mat-dialog-close]="false" i18n>No</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button [mat-dialog-close]="true" [disabled]="!possoProcedere" i18n>Sì</button>
  </div>
</div>
