<div class="flex-row">
  <div class="overlay flex-row flex-100 center" *ngIf="loading">
    <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
  </div>

  <mat-form-field class="form-group flex-row flex-fill">
    <div class="flex-fill">
      <mat-chip-list #chipListDestinatari>
        <mat-chip *ngFor="let destinatario of destinatariVisibili(1)"
          [removable]="(!readOnlyDestinatari() || possoRimuovereCondivisioneSegnalazione(destinatario)) && !destinatario.cancellato "
          (removed)="openDialogConfermaScondivisioneSegnalazione(destinatario)"
          [class.odv-scelto-da-segnalante]=" destinatario.sceltoDaSegnalante"
          [class.odv-inoltrato]="!destinatario.sceltoDaSegnalante"
          [ngClass]="{
            'condivisione-sospesa': destinatario.cancellato
          }"
          [matTooltip]="tooltipDestinatari(destinatario)">
          {{ destinatario.odvDestinatario.nomeOdv }}
          <mat-icon matChipRemove
            *ngIf="(!readOnlyDestinatari() || possoRimuovereCondivisioneSegnalazione(destinatario)) && !destinatario.cancellato">cancel
          </mat-icon>
          <mat-icon class="pointer" *ngIf="destinatario.cancellato && possoGestireRipristinoCondivisione(destinatario)" [ngClass]="{
            'condivisione-sospesa': destinatario.cancellato
          }" matTooltip="Riabilita condivisione" i18n-matTooltip
              (click)="openDialogConfermaRiabilitazioneCondivisione(destinatario); $event.stopPropagation();">
              flash_on
            </mat-icon>

          <mat-icon class="pointer" *ngIf="!destinatario.cancellato && possoGestireAutorizzazioneVisibilitaSegnalazione(destinatario)" [ngClass]="{
          'condivisione-attiva': !statoCondivisioneSospesa(destinatario),
          'condivisione-inattiva': statoCondivisioneSospesa(destinatario)
        }" matTooltip="{{ getTooltipText(statoCondivisioneSospesa(destinatario) ? 'visibilityDisabled' : 'visibilityEnabled') }}"
            (click)="openDialogConfermaCambioVisibilitaSegnalazione(destinatario); $event.stopPropagation();">
            {{ statoCondivisioneSospesa(destinatario) ? 'visibility_off' : 'visibility' }}
          </mat-icon>

          <mat-icon class="pointer" *ngIf="!destinatario.cancellato && possoGestireAutorizzazioneComunicazioneConSegnalante(destinatario)" [ngClass]="{
          'comunicazione-segnalante-attiva': statoComunicazioneSegnalante(destinatario),
          'comunicazione-segnalante-inattiva': !statoComunicazioneSegnalante(destinatario)
        }" matTooltip="{{ getTooltipText(statoComunicazioneSegnalante(destinatario) ? 'communicationDisabled' : 'communicationEnabled') }}"
            (click)="toggleComunicazioneConSegnalante($event, destinatario)">
            message
          </mat-icon>

          <mat-icon class="pointer" *ngIf="!destinatario.cancellato && possoGestireAutorizzazioneCreazioneLavorazioni(destinatario)" [ngClass]="{
          'lavorazione-attiva': statoAperturaLavorazioni(destinatario),
          'lavorazione-inattiva': !statoAperturaLavorazioni(destinatario)
        }" matTooltip="{{ getTooltipText(statoAperturaLavorazioni(destinatario) ? 'buildDisabled' : 'buildEnabled') }}"
            (click)="toggleAperturaLavorazioni($event, destinatario)">
            build
          </mat-icon>

          <mat-icon class="pointer" *ngIf="!destinatario.cancellato && possoGestireAutorizzazioneGestioneIdentitaSegnalante(destinatario)" [ngClass]="{
        'visibilita-segnalante-abilitata': statoVisibilitaSegnalante(destinatario),
        'visibilita-segnalante-disabilitata': !statoVisibilitaSegnalante(destinatario)
        }" matTooltip="{{ getTooltipText(statoVisibilitaSegnalante(destinatario) ? 'identityEnabled' : 'identityDisabled') }}"
            (click)="openDialogConfermaVisibilitaIdentitaSegnalante(destinatario)">
            perm_identity
          </mat-icon>

          <mat-icon class="pointer" *ngIf="!destinatario.cancellato && possoGestireAutorizzazioneGestioneIdentitaSegnalati(destinatario)" [ngClass]="{
        'visibilita-segnalati-abilitata': statoVisibilitaSegnalati(destinatario),
        'visibilita-segnalati-disabilitata': !statoVisibilitaSegnalati(destinatario)
      }" matTooltip="{{ getTooltipText(statoVisibilitaSegnalati(destinatario) ? 'supervisedUserEnabled' : 'supervisedUserDisabled') }}"
            (click)="toggleVisibilitaSegnalati($event, destinatario)">
            supervised_user_circle
          </mat-icon>

          <mat-icon class="pointer" *ngIf="!destinatario.cancellato && possoGestireAutorizzazioniGestioneOmissis(destinatario)"
          [ngClass]="{'servizio-non-attivo': !omissisInModifica(destinatario),
      'servizio-attivo': omissisInModifica(destinatario)}"
            (click)="condivisioneSelezionata(destinatario); $event.stopPropagation();" i18n-matTooltip
            matTooltip="Gestisci Omissis">
            strikethrough_s</mat-icon>

          <mat-icon class="pointer" *ngIf="!destinatario.cancellato && possoGestireAutorizzazioneGestioneAudio(destinatario) && mostraIconaGestioneAudioCifrato"
            [ngClass]="{
              'ascolto-audio-non-in-chiaro': statoAscoltoAudio(destinatario),
              'ascolto-audio-in-chiaro': !statoAscoltoAudio(destinatario)
            }"
            matTooltip="{{ getTooltipText(statoAscoltoAudio(destinatario) ? 'audioInChiaro' : 'audioNonInChiaro') }}"
            (click)="toggleAscoltoAudio($event, destinatario)">
            hearing
          </mat-icon>

          <mat-icon class="pointer"
          [ngClass]="{
            'servizio-attivo': statoCondivisione(destinatario),
            'servizio-non-attivo': !statoCondivisione(destinatario)
          }"
            *ngIf="possoGestireAutorizzazioneCondivisione(destinatario) && !destinatario.cancellato"
            matTooltip="{{ getTooltipText(statoCondivisione(destinatario) ? 'condivisibile' : 'nonCondivisibile') }}"
            (click)="toggleCondivisione($event, destinatario)">
            share
          </mat-icon>

        </mat-chip>
        <input #inputDestinatari matInput i18n-placeholder placeholder="Condivisione Segnalazione"
        class="label-segnalazione testo-label-scalabile"
          [matChipInputFor]="chipListDestinatari" [matAutocomplete]="auto4" [readonly]="readOnlyDestinatari()"
          (click)="elencoDestinatariClicked($event)" autocompleteOff (matChipInputTokenEnd)="addDestinatario($event)">
        <mat-autocomplete #auto4="matAutocomplete">
          <mat-option *ngFor="let destinatario of destinatariSegnalazioneFiltrati" [value]="destinatario.nomeOdv"
            (onSelectionChange)="selectedDestinatario(destinatario)">
            {{ destinatario.nomeOdv }}
          </mat-option>
        </mat-autocomplete>
      </mat-chip-list>
    </div>

    <mat-menu #menu="matMenu">
      <ng-container *ngIf="!inoltroLibero">
        <button *ngFor="let gruppo of gruppiAiQualiPossoInoltrare(segnalazione.aziendaInteressata)" mat-menu-item
          (click)="openDialogDirittiInoltro(gruppo.odv)">
          {{ gruppo.odv.nomeOdv }}</button>
      </ng-container>

      <ng-container *ngIf="inoltroLibero">
        <div *ngFor="let azienda of elencoAziende">
          <button [matMenuTriggerFor]="sub_menu" mat-menu-item>
            {{ azienda.nomeAzienda }}</button>
          <mat-menu #sub_menu>
            <button *ngFor="let gruppo of gruppiAiQualiPossoInoltrare(azienda)" mat-menu-item
              (click)="openDialogDirittiInoltro(gruppo.odv)">
              {{ gruppo.odv.nomeOdv }} </button>
          </mat-menu>
        </div>

      </ng-container>
    </mat-menu>
    <mat-label i18n class="label-segnalazione testo-label-scalabile">Condivisione Segnalazione</mat-label>
  </mat-form-field>
  <div>
    <button *ngIf="possoInoltrareSegnalazione()" mat-fab [matMenuTriggerFor]="menu" i18n-matTooltip
      matTooltip="Inoltra Segnalazione">
      <mat-icon aria-label="inoltra segnalazione" class="icona-fab">forward</mat-icon>
    </button>
  </div>
</div>
