import { User } from './user'
import { Segnalazione } from './segnalazione'
import { Documento } from './documento'
import { Messaggio } from './messaggio'
import { Odv } from './odv'
import { Lavorazione } from './lavorazione'


export class Chat {

    id: string

    moderatore: Odv

    partecipanti: User[]

    gruppiPartecipanti: Odv[]

    segnalazione: Segnalazione

    nome: string

    dataCreazione: Date

    allegati: Documento[]

    messaggi: Messaggio[]

    chatConSegnalante: boolean

    lavorazione: Lavorazione

}
