import { Component, ElementRef, Inject, ViewChild } from '@angular/core'

import { Odv } from '../modello/odv'
import { UntypedFormGroup, UntypedFormBuilder, } from '@angular/forms'

import { SessionData } from '../sessione/dati-sessione'


import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { AuthService } from '../servizi/auth.service'

import { ThemeService } from '../servizi/theme.service'
import { ENTER, COMMA } from '@angular/cdk/keycodes'


@Component({
  selector: 'app-modifica-gruppo-dialog',
  templateUrl: 'modifica-gruppo.html'
})
export class ModificaGruppoDialogComponent {
  gruppo: Odv

  formGruppo: UntypedFormGroup

  separatorKeysCodes: number[] = [ENTER, COMMA]
  addOnBlur = true
  gruppoSelezionato: Odv

  errore: any

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ModificaGruppoDialogComponent>,
    private authService: AuthService,
    private sessionData: SessionData
  ) {

    this.gruppo = data.gruppo
    this.errore = data.errore
    this.createForm()

  }


  private createForm() {
    this.formGruppo = this.fb.group({
      id: this.fb.control({ value: this.gruppo.id, disabled: false }),
      nomeOdv: this.fb.control({ value: this.gruppo.nomeOdv, disabled: false }),
      predefinito: this.fb.control({ value: this.gruppo.predefinito, disabled: false }),
    })
  }

  chiudiDialog() {
    this.dialogRef.close({ data: this.gruppo })
  }


  onAddGruppo() {
    console.log('gruppo:', this.formGruppo.value)

    this.gruppo.nomeOdv = this.formGruppo.value['nomeOdv']
    this.gruppo.predefinito = this.formGruppo.value['predefinito']

    this.chiudiDialog()
  }


  custodiaAttiva() {
    return this.sessionData.configurazione?.custodiaIdentita
  }

  sonoSU() {
    return this.authService.getUser()?.su
  }

}


