import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import * as moment from 'moment'
import { Subscription } from 'rxjs'
import { AuthService } from '../servizi/auth.service'
import { RicercaService } from '../servizi/ricerca.service'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { ThemeService } from '../servizi/theme.service'
import { Esito, ESITO_OK } from '../utility/esito'
import { Location } from '@angular/common'
import { Informativa } from '../modello/informativa'
import { CreaModificaInformativaDialogComponent } from './dialog-crea-modifica-informativa-component'
import { _base64ToArrayBuffer } from '../utility/utility'

@Component({
  selector: 'app-informative',
  templateUrl: './informative.component.html',
  styleUrls: ['./informative.component.scss']
})
export class InformativeComponent implements OnInit, AfterViewInit, OnDestroy {

  dataSource
  sortedData
  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  colonneVisualizzate = ['id', 'nome',
    'azienda', 'azioni']

  informative: Informativa[]
  informativa: Informativa

  private subscriptions: Array<Subscription> = []

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private ricercaService: RicercaService,
    private location: Location,
    private segnalazioneService: SegnalazioneService
  ) { }


  ngOnDestroy(): void {
    this.subscriptions?.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    if (!this.authService.getUser()?.su) {
      this.location.back()
    } else {
      this.subscriptions.push(this.ricercaService.filtraElenco.subscribe((cercami: string) => {
        this.applyFilter(cercami)
      }))
    }
  }

  ngAfterViewInit() {
    this.refreshData()
  }


  reloadTable() {
    // this.sortedData = this.informative.slice();
    this.dataSource = new MatTableDataSource(this.informative)
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
  }

  refreshData() {
    this.segnalazioneService.getInformative().then((esito: Esito) => {
      if (esito.esito === ESITO_OK) {
        this.informative = JSON.parse(esito.payload)
        this.reloadTable()
      }
    })
  }

  capplyFilter(filterValue: string, key?: string) {
    filterValue = filterValue.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue
  }

  applyFilter(filterValue: string, key?: string) {
    if (this.dataSource) {

      this.dataSource.filterPredicate =
        (data: Informativa, filter: string) => {
          console.log(data)
          const miadata = moment(new Date(data['dataRichiestaSbloccoVisibilitaSegnalante']), 'dd/MM/yyyy')
          return data['segnalazione']['id'].toString().indexOf(filter) !== -1
            || (data['odvDestinatario']['nomeOdv'] && data['odvDestinatario']['nomeOdv'].toLowerCase().indexOf(filter) !== -1)
            || (data['motivazioneRichiestaSbloccoVisibilitaSegnalante'] &&
              data['motivazioneRichiestaSbloccoVisibilitaSegnalante'].toLowerCase().indexOf(filter) !== -1)
            || (data['motivazioneRispostaARichiestaSbloccoVisibilitaSegnalante'] &&
              data['motivazioneRispostaARichiestaSbloccoVisibilitaSegnalante'].toLowerCase().indexOf(filter) !== -1)
        }
      filterValue = filterValue.trim() // Remove whitespace
      filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue
    }
  }

  selected(informativa) {
    this.informativa = informativa
    // console.log('selected:' + azienda.nomeAzienda);
  }

  salvaInformativa(informativa) {
    if (informativa) {
      this.informativa = informativa
    }
    this.segnalazioneService
      .salvaInformativa(this.informativa)
      .then((esito: Esito) => {

        if (esito.esito === ESITO_OK) {
          const tmp = JSON.parse(esito.payload) as Informativa

          const infoIndex = this.informative.findIndex(info => +info.id === +tmp.id)
          if (infoIndex !== -1) {
            this.informative.splice(infoIndex, 1, tmp)
          } else {
            this.informative.push(tmp)
          }
          this.reloadTable()
          this.snackBar.open($localize `Informativa aggiornata`, null, {
            duration: 2000
          })
          //  verifica se c'è un'altra informativa legata alla stessa azienda, se è così la aggiorna
          const infor = this.informative.find(informa =>
            +informa.azienda?.id === +this.informativa.azienda?.id && +informa.id !== +this.informativa.id)
          if (infor) {
            infor.azienda = undefined
          }
        } else {
          this.segnalazioneService.getInformativaById(informativa.id)
            .then((esito2: Esito) => {
              if (esito2.esito === ESITO_OK) {
                this.informativa = JSON.parse(esito2.payload)
                const indice = this.informative.findIndex(ric => +ric.id === +this.informativa.id)
                this.informative.splice(indice, 1, this.informativa)
                this.reloadTable()
              }
            })
          this.snackBar.open($localize `Aggiornamento Informativa fallito ` +
            esito.payload +
            ' ' +
            esito.descrizioneEsito,
            null,
            {
              duration: 4000
            }
          )
        }
      })
      .catch(errore => {
        // console.log(errore);
        this.snackBar.open($localize `Aggiornamento Informativa fallito ` + errore, null, {
          duration: 4000
        })
      })
  }


  editaInformativa(informativa: Informativa) {
    const dialogRef = this.dialog.open(CreaModificaInformativaDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: { informativa },
      disableClose: true,
      hasBackdrop: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.salvaInformativa(result.data)
      }
    })
  }

  toHTML(input): any {
    return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent
  }

  aggiungiInformativa() {
    this.editaInformativa(new Informativa())
  }

  puoCreareInformative() {
    return true
  }

}
