<div class="flex-column centered" class="alternate-theme">

  <h2 mat-dialog-title i18n style="text-align:center">Richiesta di Consenso al Segnalante</h2>

  <mat-dialog-content [formGroup]="form">
    <div class="flex-row centered" *ngIf="consenso.id">
      <mat-form-field class="flex-20">
        <div class="width-full">
          <input matInput i18n-placeholder placeholder="ID" formControlName="id">
        </div>
      </mat-form-field>

      <mat-form-field class="flex-80">
        <div class="width-full">
          <input matInput i18n-placeholder placeholder="Stato" formControlName="stato">
        </div>
      </mat-form-field>
    </div>
    <div class="flex-row centered" *ngIf="consenso.id">
      <mat-form-field class="flex-100">
        <div class="width-full">
          <input matInput i18n-placeholder placeholder="Data" formControlName="dataRichiesta" datetime="dd/MM/yyyy">
        </div>
      </mat-form-field>
    </div>
    <div class="flex-row centered">
      <div>
        <button mat-icon-button (click)="toggleLock()">
          <mat-icon>{{sbloccato ? 'lock_open' : 'lock'}}</mat-icon>
        </button>
      </div>
      <mat-form-field class="flex-45">
        <div class="width-full">
          <input matInput i18n-placeholder placeholder="Nome" formControlName="nome">
        </div>
      </mat-form-field>
      <mat-form-field class="flex-45">
        <div class="width-full">
          <input matInput i18n-placeholder placeholder="Cognome" formControlName="cognome">
        </div>
      </mat-form-field>
    </div>
    <div style="margin-top: 20px;">
      <mat-form-field color="accent" style="display: block;width:95%;">
        <mat-select formControlName="odvRichiedente" i18n-placeholder placeholder="Seleziona il gruppo che richiede il consenso">
          <mat-option *ngFor="
                  let dirittoSegnalazione of odvSelezionabili" (onSelectionChange)="
                  onGruppoSelezionato(
                    $event,
                    dirittoSegnalazione.odvDestinatario
                  )
                " [value]="dirittoSegnalazione">
            {{ dirittoSegnalazione.odvDestinatario.nomeOdv }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex-row centered">
      <button i18n mat-button (click)="close()">Annulla</button>
      <button i18n mat-button color="primary" (click)="salva()">Salva</button>
    </div>
  </mat-dialog-actions>
</div>
