<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title class="titolo-dialog" i18n>Elenco Segnalazioni</h2>
  <mat-dialog-content>

    <div class="mat-elevation-z8 top-60px" id="listaSegnalazioni">
      <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

        <mat-header-row *matHeaderRowDef="colonneVisualizzate" ></mat-header-row>
        <mat-row *matRowDef="let segnalazione; columns: colonneVisualizzate" class="pointer" (click)="selected(segnalazione)"></mat-row>

        <ng-container matColumnDef="id">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione"> {{segnalazione.id}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="oggetto">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Oggetto </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione" class="grassetto"> {{segnalazione.oggetto}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dataOra">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Data e Ora </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione"> {{segnalazione.dataInserimento | date: 'dd/MM/yyyy H:mm'}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="descrizione" *ngIf="mostraTipiNonConformita">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Tipo non conformità / Violazione </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione"> {{segnalazione.descrizione?
            segnalazione.descrizione.length > 100 ? segnalazione.descrizione.substr(0,100) +
            "...":segnalazione.descrizione:""}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="stato">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Stato </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione" class="grassetto">
            {{ statoSegnalazione(segnalazione)}} </mat-cell>
        </ng-container>
      </mat-table>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button i18n mat-button [mat-dialog-close]="true">Chiudi</button>
  </mat-dialog-actions>
</div>
