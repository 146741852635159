<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav mode="push">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon color="">menu</mat-icon>
    </button>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-toolbar></app-toolbar>
    <div style="display:flex; flex-direction: column" class="alternate-theme">
      <div i18n class="titolo-pagina">
        Lavorazione della segnalazione con ID {{ idSegnalazione }}
      </div>
      <mat-expansion-panel [disabled]="true" *ngIf="odv && lavorazione!==undefined"
        [expanded]="true" id="lavorazione-segnalazione1" name="LavorazioneSegnalazione1">
        <mat-expansion-panel-header class="sottotestata-principale-dettagli-segnalazione">
          <mat-panel-title i18n>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="margin-top-20px">
          <form #fgestore="ngForm" [formGroup]="formLavorazione">
            <!--div [formGroup]="formLavorazione" class="position-fixed">-->

            <div class="flex-row flex-gap-10">
              <mat-form-field class="full top-10px">
                <input matInput i18n-placeholder placeholder="Stato" disabled id="stato-lavorazione" class="disabled-light"
                  value="{{convertiStatoLavorazione(lavorazione.stato)}}">
                <mat-label i18n class="label-segnalazione">Stato</mat-label>
              </mat-form-field>
              <mat-form-field class="width-full top-13px" color="accent">
                <mat-select i18n-placeholder placeholder="Stato visibile al segnalante?" formControlName="visibileAlSegnalante">
                  <mat-option default value="true">Sì</mat-option>
                  <mat-option value="false">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="margin-top-20px">
              <mat-form-field class="width-full" color="accent">
                <div>
                  <mat-chip-list #chipListDestinatariInoltro>
                    <mat-chip *ngFor="let dirittiLavorazione of lavorazione.dirittiOdv" [removable]="true"
                      (removed)="openDialogConfermaScondivisioneLavorazione($event, dirittiLavorazione, lavorazione)">
                      {{dirittiLavorazione.odv?.nomeOdv}}
                      <mat-icon matChipRemove *ngIf="puoRimuovereCondivisione(dirittiLavorazione)">
                        cancel</mat-icon>

                      <mat-icon *ngIf="possoGestireAutorizzazioneModificaLavorazione(dirittiLavorazione)"
                        [class.lavorazione-modificabile]="statoLavorazioneModificabile(dirittiLavorazione, lavorazione)"
                        [class.lavorazione-non-modificabile]="!statoLavorazioneModificabile(dirittiLavorazione, lavorazione)"
                        i18n-matTooltip matTooltip=" {{ statoLavorazioneModificabile(dirittiLavorazione, lavorazione) ? 'Disabilita la Condivisione in modifica della Lavorazione' : 'Abilita la Condivisione in modifica della Lavorazione' }}"
                        (click)="toggleLavorazioneModificabile($event, dirittiLavorazione, lavorazione)">edit</mat-icon>
                    </mat-chip>
                    <mat-menu #menuShare="matMenu">
                      <mat-checkbox style="padding-left: 10px;padding-right: 10px;">Condivisione</mat-checkbox>
                    </mat-menu>
                    <input disabled i18n-placeholder placeholder="Condivisione lavorazione"
                      [matChipInputFor]="chipListDestinatariInoltro" matInput>
                  </mat-chip-list>
                </div>
              </mat-form-field>
            </div>

            <mat-form-field class="width-full" color="accent">
              <input type="text" i18n-placeholder placeholder="Sorgente Segnalazione..." matInput formControlName="sorgente"
                [matAutocomplete]="autosorgente" #triggerSorgente="matAutocompleteTrigger"
                (click)="$event.stopPropagation(); openPanel(triggerSorgente)">
              <mat-autocomplete #autosorgente="matAutocomplete">
                <mat-option *ngFor="let item of sorgentiSegnalazione" [value]="item.nome">
                  {{item.nome}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="width-full" color="accent">
              <mat-select #select i18n-placeholder placeholder="Ammissibile?" formControlName="ammissibile">
                <mat-option default value="true">Sì</mat-option>
                <mat-option value="false">No</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="width-full" color="accent"
              *ngIf=" formLavorazione && formLavorazione.controls && formLavorazione.controls.ammissibile.value=='false' ">
              <textarea maxlength="20000" matInput formControlName="motivoNonAmmissibilita"
                i18n-placeholder placeholder="Motivi non ammissibilità"></textarea>
            </mat-form-field>

            <div>
              <mat-form-field color="accent">
                <input matInput class="pointer" type="text">
                <mat-checkbox #checkPianificazioneEliminazione
                  [checked]="formLavorazione.controls.eliminareSegnalazione.value === 'true'"
                  (change)="pianificaEliminazioneChanged($event)" [disabled]="formLavorazione.disabled">
                  Pianifica Eliminazione della segnalazione e
                  di tutte le informazioni correlate
                </mat-checkbox>
              </mat-form-field>
              <mat-form-field color="accent" *ngIf="checkPianificazioneEliminazione.checked"
                style="top:24">
                <mat-label i18n>Data decisione eliminazione</mat-label>
                <input matInput disabled value=" {{ formLavorazione.controls.dataDecisioneEliminazione | data }}">
              </mat-form-field>
            </div>


            <mat-form-field class="width-full" color="accent">
              <input type="text" i18n-placeholder placeholder="Tema Segnalazione" matInput formControlName="tema" #tema
                [matAutocomplete]="auto2" #triggerTema="matAutocompleteTrigger"
                (click)="$event.stopPropagation(); openPanel(triggerTema)">
            </mat-form-field>
            <mat-autocomplete #auto2="matAutocomplete">
              <mat-option *ngFor="let item of temiSegnalazione" [value]="item.tema">
                {{item.tema}}
              </mat-option>
            </mat-autocomplete>
            <mat-form-field class="form-group width-full" color="accent">
              <mat-chip-list #chipListAzioni>

                <mat-chip *ngFor="let azione of lavorazione.azioniFollowUp" [selectable]="true"
                  [removable]="gestibile(lavorazione)" (removed)="remove(azione, lavorazione)">
                  {{azione.azione}}
                  <mat-icon matChipRemove
                    *ngIf="gestibile(lavorazione) && isUtenteCorrenteGestoreOCogestoreLavorazione(lavorazione)">cancel
                  </mat-icon>
                </mat-chip>

                <input matInput #inputFollowUp i18n-placeholder placeholder="Azioni di follow-up..." formControlName="azioniFollowUpForm"
                  (matChipInputTokenEnd)="add($event, lavorazione)" [matChipInputFor]="chipListAzioni"
                  #trigger="matAutocompleteTrigger" [matAutocomplete]="auto3" (click)="trigger.openPanel()"
                  [readonly]="!gestore() || (formLavorazione.disabled && formLavorazione) || readOnly">
                <mat-autocomplete #auto3="matAutocomplete">
                  <div *ngIf="gestore() && formLavorazione && formLavorazione.enabled">
                    <mat-option *ngFor="let item of azioniFollowUpFiltrate" [value]="item.azione"
                      (onSelectionChange)="selected(item, lavorazione)">
                      {{item.azione}}
                    </mat-option>
                  </div>
                </mat-autocomplete>
              </mat-chip-list>
            </mat-form-field>

            <div class="width-full">
              <angular-editor *ngIf="gestore() && formLavorazione && formLavorazione.enabled"
                formControlName="noteFollowUp" [config]="editorConfigTb"></angular-editor>
              <angular-editor *ngIf="!gestore() || (formLavorazione && formLavorazione.disabled)"
                formControlName="noteFollowUp" [config]="editorConfigNoTb">
              </angular-editor>
              <!--textarea matInput formControlName="noteFollowUp" rows="5" i18n-placeholder placeholder="Note follow up"></textarea>-->
            </div>
            <app-elenco-allegati #allegatiGestore *ngIf="true"
              [disabilitato]="!gestibile(lavorazione) || !lavorazione.id" [documenti]="lavorazione.documenti"
              [entitaPrincipale]="lavorazione" [tipoEntitaPrincipale]="'lavorazione'"
              [canaleDocAggiunto]="'documento-aggiunto-lavorazione-' + lavorazione.id"
              [canaleDocAggiornato]="'documento-aggiornato-lavorazione-' + lavorazione.id"
              [canaleDocEliminato]="'documento-eliminato-lavorazione-' + lavorazione.id" [disabilitato]="true"
              (salvaDocumento)="undefined" (aggiornaDocumento)="undefined" (aggiungiDocumento)="undefined"
              (unlinkDocumento)="undefined"></app-elenco-allegati>
          </form>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
