<mat-list-item>
  <h3 class="flex-30" i18n> {{ titolo }}
  </h3>
</mat-list-item>
<div *ngIf="email">
  <mat-list-item class="list-item-configurazione">
    <div class="flex-row flex-30">
      <label i18n> Oggetto
      </label>
      <app-icona-info class="flex-fill margine-sinistro" [input]="oeb" [paroleChiave]="paroleChiaveOggetto">
      </app-icona-info>
    </div>


    <mat-form-field class="flex-fill" color="accent">
      <input #oeb matInput type="text" [(ngModel)]="email.oggetto" (ngModelChange)="onAggiornaTesto()" />
    </mat-form-field>
  </mat-list-item>

  <mat-list-item class="list-item-configurazione">

    <div class="flex-row flex-30">
      <label i18n>Testo</label>
      <app-icona-info class="flex-fill margine-sinistro" [input]="teb" [paroleChiave]="paroleChiaveTesto">
      </app-icona-info>
    </div>

    <div color="primary">
      <angular-editor [id]="id" #teb [(ngModel)]="email.testo" [config]="editorConfigTb" (ngModelChange)="onAggiornaTesto()">
      </angular-editor>
    </div>
  </mat-list-item>
</div>
