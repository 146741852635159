<div #languageSelector class="language-selector" *ngIf="sonoPronto" [style.transform]="'translateX(-' + shiftAmount + 'px)'">
  <div class="selected-language">
    <div style="margin-left: 12px;">
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="55" viewBox="0 0 200 110" style="margin-left:12px">
        <!-- Percorso principale con bordo -->
        <path stroke-width="7" [attr.stroke]="coloreTesto" fill="none"
        d="M10 4
             L 100 4
             Q 110 4, 110 10
             L 110 70
             Q 110 80, 100 80
             L 40 80 L 25 90 L 25 80
             L 20 80
             Q 4 80, 4 70
             L 4 16
             Q 4 4, 10 4" />
        <!-- Sezione bianca con bordo -->
        <path [attr.fill]="coloreSfondo" [attr.stroke]="coloreTesto" stroke-width="7"
        d="M 70 16
             L 160 16
             Q 170 16, 170 22
             L 170 82
             Q 170 92, 160 92
             L 150 92 L 150 102 L 135 92
             L 80 92
             Q 64 92, 64 82
             L 64 28
             Q 64 16, 70 16" />
      </svg>
    </div>

    <span class="selected-text-language">
      {{selectedLanguage | uppercase}}
    </span>
  </div>
  <div class="expander" (click)="toggleLanguages()">
    >
  </div>
  <div class="language-list flex-row no-scroll">
    <ng-container *ngFor="let language of languages; let i = index">
      <div class="text-language" style="cursor: pointer; display: inline-block; width: 32px; opacity: 0;"
        [ngClass]="{'fadeIn': showLanguages, 'fadeOut': !showLanguages}"
        [style.animationDelay]="showLanguages ? (i * 0.1) + 's' : ((languages.length - i - 1) * 0.1) + 's'"
        (click)="languageSelected(language)" *ngIf="language.code !== selectedLanguage">
        {{language.code | uppercase}}
      </div>
    </ng-container>
  </div>


</div>
