import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import { Azienda } from '../modello/azienda'
import { Informativa } from '../modello/informativa'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { ThemeService } from '../servizi/theme.service'
import { AziendeService } from '../servizi/aziende.service'
import { ESITO_OK } from '../utility/esito'
import { Base64 } from 'js-base64'
import { Documento, TipoDocumento } from '../modello/documento'
import { _arrayBufferToBase64, _base64ToArrayBuffer } from '../utility/utility'
import { DocumentiService } from '../servizi/documenti.service'

@Component({
  selector: 'app-crea-modifica-informativa-dialog',
  templateUrl: 'dialog-crea-modifica-informativa-component.html',
})
export class CreaModificaInformativaDialogComponent implements OnInit {


  editorConfigTb: AngularEditorConfig = {
    enableToolbar: true,
    showToolbar: true,
    toolbarHiddenButtons: [[],['insertImage', 'insertVideo',]],
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Inserisci testo qui...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  }
  informativa: Informativa
  azienda: Azienda
  aziende: Azienda[]

  formInformativa: UntypedFormGroup
  src: any
  currentPage = 1

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreaModificaInformativaDialogComponent>, private snackBar: MatSnackBar,
    private dialog: MatDialog, private segnalazioniService: SegnalazioneService, private aziendeService: AziendeService,
    private documentiService: DocumentiService) {
    this.informativa = data['informativa']
      console.log('informativa:', this.informativa.nome)
  }
  ngOnInit(): void {
    this.createForm()
    this.caricaDati()
  }
  caricaDati() {
    this.aziendeService.recuperaAziende()
      .then(esito => {
        if (ESITO_OK === esito.esito) {
          this.aziende = JSON.parse(esito.payload)
        } else {
          this.snackBar.open($localize `Recupero aziende fallito!` + esito.descrizioneEsito, null, {
            duration: 6000,
          })
        }
      })
      .catch(error => {
        this.snackBar.open($localize `Recupero aziende fallito!` + error.toString(), null, {
          duration: 6000,
        })
      })
  }

  private createForm() {
    console.log(this.informativa?.nome)
    this.formInformativa = this.fb.group({
      id: this.fb.control({ value: this.informativa.id, disabled: true }),
      nome: this.fb.control( this.informativa?.nome,),
      testo: this.fb.control({ value: this.informativa.testo ? Base64.decode(this.informativa.testo) : '', disabled: false }),
      // nomeFile: this.fb.control({ value: this.informativa.nomeFile, disabled: false }),
      azienda: this.fb.control({ value: this.informativa.azienda?.nomeAzienda, disabled: false }),
    })
  }

  aggiornaAzienda(azienda) {
    this.informativa.azienda = azienda
  }

  get testoInformativa() {
    return this.informativa.testo? Base64.decode(this.informativa.testo): ''
  }

  aggiungiDocumento(pacchetto: {attributo: string; pdfViewer: number; documento: Documento}) {
    this.informativa.documento = pacchetto.documento
  }

  close() {
    this.dialogRef.close()
  }

  salva() {
    this.informativa.nome = this.formInformativa.value.nome
    if (this.azienda) {
      this.informativa.azienda = this.azienda
    }
    this.dialogRef.close({ data: this.informativa })
  }

  get tipoInformativa(): number {
    return TipoDocumento.TIPO_INFORMATIVA
  }

  isDocumentoPdf() {
    return this.informativa.documento?.tipo === 'application/pdf'
  }

  paginaAvanti() {
    this.currentPage++
  }

  paginaIndietro() {
    this.currentPage--
  }

  eliminaDocumento() {
    this.documentiService.eliminaDocumento(this.informativa.documento).then(
      esito => {
        if (ESITO_OK === esito.esito) {
          this.snackBar.open($localize `La cancellazione del documento è stata eseguita. ` + esito.payload, null, {
            duration: 6000,
          })
          this.informativa.documento = undefined
        } else {
          this.snackBar.open($localize `La cancellazione del documento è fallita` + esito.payload, null, {
            duration: 6000,
          })
        }
      }
    ).catch(error => {
      this.snackBar.open($localize `La cancellazione del documento è fallita` + error, null, {
        duration: 6000,
      })
    })
  }

  aggiornaTesto(testo: string) {
    this.informativa.testo = Base64.encode(testo)
  }

}
