<!-- inserisci una checkbox per decidere se allegare un file o usare l'editor -->
<div *ngIf="isDocumento" class="flex-column" style="margin-top:16px;">
  <app-editor-lingua-selector [linguaSelezionata]="lingua" [lingue]="lingue" (linguaChanged)="changeLanguage($event)">
  </app-editor-lingua-selector>
  <mat-checkbox [ngModel]="documento.usaFile" (ngModelChange)="onUsaFileChange($event)"
    *ngIf="!inlineTextOnly && !isEmail && !soloAllegati">
    <span i18n>Usa File</span>
  </mat-checkbox>
  <div class="flex-column" *ngIf="!inlineTextOnly && (documento.usaFile || soloAllegati)">
    <p i18n *ngIf="documento && documento.url && documento.url!=='null'"> Il File Allegato è
    <h3>{{documento?.nome}}</h3>
    <p i18n *ngIf="!documento || !documento.url || documento.url==='null'">Nessun Documento PDF collegato</p>
    <p i18n>
      <app-file-uploader [id]="fileUploaderId" [multi]="false" [accept]="'application/pdf'"
        [label]="computeLabel(documento)" (onDocumentAdded)="onAggiungiDocumento($event)"></app-file-uploader>
    <p i18n *ngIf=" documento && documento.url && (!base64Raw || !attivato) ">
      Se vuoi consultarlo <span class="pointer grassetto" (click)="attivaVisualizzatore()"> clicca qui</span>
    </p>
    <p i18n *ngIf="documento && documento.url">
      Se vuoi eliminarlo <span class="pointer grassetto" (click)="onEliminaFile()"> clicca qui</span>
    </p>
    <p i18n>Sono ammessi solo file in formato PDF.</p>
  </div>
  <div *ngIf="attivato && mostraVisualizzatorePDF" style="margin-top:16px;">
    <ngx-extended-pdf-viewer #pdfViewer [id]="pdfViewerId" [base64Src]="base64Raw" useBrowserLocale="true" height="60vh"
      [language]="language" [pageViewMode]="'single'" delayFirstView="1000"></ngx-extended-pdf-viewer>
  </div>
  <div *ngIf="!documento.usaFile && !soloAllegati">
    <angular-editor [id]="sottotipoDocumento" #tss [(ngModel)]="documento.testo" (ngModelChange)="onAggiornaTesto()"
      [config]="editorConfigTb">
    </angular-editor>
  </div>
</div>
<div *ngIf="isEmail" class="flex-column" style="margin-top:16px;">
  <div class="flex-row">
    <div style="flex: 1;"></div>
    <div style="flex: 2.35;"> <app-editor-lingua-selector [linguaSelezionata]="lingua" [lingue]="lingue"
        (linguaChanged)="changeLanguage($event)">
      </app-editor-lingua-selector></div>

  </div>

  <app-email class="flex-column" [email]="documento" [id]="sottotipoDocumento" (emailChange)="aggiornaEmail($event)"
    [lingua]="lingua" [paroleChiaveOggetto]="paroleChiaveOggetto" [paroleChiaveTesto]="paroleChiaveTesto"
    [titolo]="titoloSezione">
  </app-email>
</div>
