import { Segnalazione } from './segnalazione'
import { LavorazioneMaster } from './lavorazione-master'

export class SorgenteSegnalazione {
    id: number
    nome: string
    lingua: string
    segnalazioni: Segnalazione[]
    lavorazioniMaster: LavorazioneMaster[]
    predefinita: boolean

    public toString() {
        return this.nome
    }
}
