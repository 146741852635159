import { Segnalazione } from './segnalazione'

import { SorgenteSegnalazione } from './sorgente-segnalazione'

import { Tema } from './tema'
import { Lavorazione } from './lavorazione'

export class LavorazioneMaster {

  id: number

  ammissibile: boolean

  visibileAlSegnalante: boolean

  motivoNonAmmissibilita: string

  segnalazione: Segnalazione

  sorgente: SorgenteSegnalazione

  tema: Tema

  stato: string

  lavorazioni: Lavorazione[]

}
