import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'

@Pipe({
  name: 'dataOra'
})
export class DataOraPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value != null) {
      value = moment(value).format('DD/MM/YYYY HH:mm')
    }
    return value
  }

}
