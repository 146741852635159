import { Component, Output, EventEmitter, Inject, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TipoDocumento } from '../../modello/documento'
import { FileInput } from '../input-file/file-input.model'
import { InputFileComponent } from '../input-file/input-file.component'
import { VisualizzatorePdfDialogComponent } from '../visualizzatore-pdf/visualizzatore-pdf.component'

@Component({
  selector: 'app-allegati-dialog',
  templateUrl: './allegati-dialog.component.html',
  styleUrls: ['./allegati-dialog.component.scss']
})
export class AllegatiDialogComponent {
  allegati: any[]
  @Output() onEliminaAllegato = new EventEmitter<any>()
  @Output() onAggiungiAllegati = new EventEmitter<any>()
  @ViewChild('newattachedfile') newAttachedFile: InputFileComponent
  @Output() fileSelected = new EventEmitter<File[]>()
  formAllegati: FormGroup
  constructor(
    public dialogRef: MatDialogRef<AllegatiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { allegati: any[] },
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.allegati = data.allegati
  }

  ngOnInit() {
    this.formAllegati = new FormGroup({
      files: new FormControl(null)
    })
  }

  eliminaAllegato(allegato: any) {
    this.onEliminaAllegato.emit(allegato)
  }

  closeDialog() {
    this.dialogRef.close()
  }

  visualizza(documento) {
    if (documento.id === 'OMISSIS') {
      this.snackBar.open($localize `Non sei abilitato al download del documento!`, null, {
        duration: 6000,
      })
      return
    }

    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: documento,
        testo: undefined, titolo: $localize `Visualizzazione documento`,
        tipoDocumento: TipoDocumento.TIPO_ALLEGATO,
        bypassAuth: false,
        canDownload: false,
        canPrint: false,
      }
    })

  }

  handleFileInput(target: any): void {

    const files = target['files']
    // this.fileToUpload = Array.from(files);
    const fileArray = (
      this.formAllegati.controls['files'].value as FileInput
    ).files
    // Gestisci l'input del file qui (ad esempio, aggiungi il file all'elenco degli allegati)
    this.fileSelected.emit(fileArray)
    // Dopo aver gestito l'input del file, apri la finestra di dialogo del file
    // this.newAttachedFile.open();
  }

}
