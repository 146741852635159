<h2 mat-dialog-title class="titolo-dialog">
  Gruppo
</h2>
<mat-dialog-content class="alternate-theme" style="overflow: hidden;">
  <div class="centra-form">
    <div class="flex-row flex-100">
      <div class="flex-row flex-100">
        <form [formGroup]="formGruppo" style="width:100%">
          <div class="flex-column" >
            <div class="flex-column" >
              <mat-error i18n *ngIf="errore !== undefined">{{errore}}</mat-error>
              <div class="flex-row">
                <mat-form-field class="flex-85" color="accent">
                  <input type="text" #userId i18n-placeholder placeholder="Nome Gruppo" aria-label="Nome gruppo" matInput
                    formControlName="nomeOdv" />
                </mat-form-field>
              </div>

              <div class="flex-row">
                <mat-checkbox matInput type="checkbox" style="margin:auto" formControlName="predefinito"></mat-checkbox>
                <mat-label class="flex-100" i18n style="padding: 2px;">Predefinito</mat-label>
              </div>

            </div>
            <div style="margin-bottom: 20px;"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-row">
    <button i18n mat-button mat-dialog-close>Annulla</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button i18n mat-button (click)="onAddGruppo()" [disabled]="formGruppo.invalid">Salva</button>
  </div>

</mat-dialog-actions>
