<div class="alternate-theme">
  <form [formGroup]="formInformativa">
    <h2 mat-dialog-title>Informativa</h2>

    <mat-dialog-content>
      <div class="flex-column centered">
        <div class="flex-row centered">
          <mat-form-field class="flex-20">
            <div class="width-full">
              <input matInput i18n-placeholder placeholder="ID" formControlName="id">
            </div>
          </mat-form-field>

          <mat-form-field class="flex-80">
            <div class="width-full">
              <input matInput i18n-placeholder placeholder="Nome" formControlName="nome" required>
            </div>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="flex-100">
            <div class="width-full">
              <input matInput i18n-placeholder placeholder="Azienda" formControlName="azienda" [matAutocomplete]="auto">
            </div>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let azienda of aziende" [value]="azienda.nomeAzienda"
                (onSelectionChange)="aggiornaAzienda(azienda)">
                {{azienda.nomeAzienda}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <app-xsp-editor [documento]="informativa.documento" [testo]="testoInformativa"
          [tipoDocumento]="tipoInformativa" (aggiungiDocumento)="aggiungiDocumento($event)" [attivato]="true"
          (eliminaDocumento)="eliminaDocumento()" (aggiornaTesto)="aggiornaTesto($event)"></app-xsp-editor>

      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <div class="flex-row flex-100 centered">
        <button i18n mat-button (click)="close()">Annulla</button>
        <button i18n mat-flat-button color="primary" [disabled]="!formInformativa.valid" (click)="salva()">Salva</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
