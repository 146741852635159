<mat-sidenav-container class="sidenav-container" style="width:100%">
  <mat-sidenav #sidenav mode="push">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon color="">menu</mat-icon>
    </button>
    <mat-toolbar> </mat-toolbar>
  </mat-sidenav>

  <mat-sidenav-content style="overflow-y: hidden;" class="flex-fill">
    <div class="flex-column" class="flex-column flex-100 flex-gap-20">
      <div>
        <app-toolbar [mostraFiltro]="false"></app-toolbar>
      </div>
      <div>
      <mat-expansion-panel class="flex-column alternate-theme " [expanded]="true"
      id="testatchat" name="test"
      (opened)="pannelloApertoChiuso(true)"
      (closed)="pannelloApertoChiuso(false)">
        <mat-expansion-panel-header class="sottotestata-principale-dettagli-segnalazione">
          <mat-panel-title i18n>
            Chat
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-chat-manager">
          <div class="chat-manager-item-1">
            <div class="flex-row flex-space-around-center">
              <div class="flex-row">
                <mat-form-field *ngIf="segnalazione" color="accent" class="width-full padding-lr">
                  <mat-select i18n-placeholder placeholder="Segnalazione Selezionata"
                    hint="Seleziona una segnalazione..." [(ngModel)]="segnalazione"
                    [compareWith]="doSegnalazioneCompareFn">
                    <mat-option *ngFor="let segnalazione of segnalazioni"
                      (onSelectionChange)="onSegnalazioneSelezionata($event)" [value]="segnalazione">
                      {{ segnalazione.progressivo + ' - ' + segnalazione.oggetto}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-icon-button *ngIf="segnalazione" (click)="apriSegnalazioneSelezionata()" i18n-matTooltip
                  matTooltip="Apri Segnalazione">
                  <mat-icon class="icone-in-mezzo-al-testo">play_arrow</mat-icon>
                </button>
              </div>
            </div>
            <div class="flex-row flex-100 flex-space-around-center" *ngIf="segnalazione">
              <mat-form-field color="accent" class="width-full padding-lr">
                <mat-select i18n-placeholder placeholder="Chat Selezionata" [(ngModel)]="chatSelezionata"
                  [compareWith]="doChatCompareFn">
                  <mat-option *ngFor="let chat of chats" (onSelectionChange)="onChatSelezionata($event, chat)"
                    [value]="chat">
                    {{ chat.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="chat-manager-item-2">
            <div class="flex-row" *ngIf="segnalazione">
              <form [formGroup]="formPartecipanti" style="width:100%">
                <mat-form-field class="form-group width-full padding-lr" color="accent"
                  *ngIf="chatSelezionata !== null && chatSelezionata != undefined">
                  <mat-chip-list #chipListPartecipanti>
                    <mat-chip *ngIf="chatSelezionata.moderatore && !isGruppoEscluso(chatSelezionata.moderatore)" [selectable]="true" [removable]="false"
                      class="moderatore" [style.font-weight]="
                      sonoMembroDiGruppo(chatSelezionata.moderatore)
                        ? 'bold'
                        : 'normal'
                    " [style.font-size]="
                      sonoMembroDiGruppo(chatSelezionata.moderatore)
                        ? 'larger'
                        : 'normal'
                    "
                    [style.background-color]=" isGruppoEscluso(chatSelezionata.moderatore) ? '#111111!important' : 'white'"
                    [matTooltip]="tooltipModeratore(chatSelezionata)"
                      [class.testo-rosso]="gestore(chatSelezionata.moderatore)">
                      <!--{{chatSelezionata.moderatore.persona.nome + ' ' + chatSelezionata.moderatore.persona.cognome}}-->
                      {{ chatSelezionata.moderatore.nomeOdv }}
                      <mat-icon>shield</mat-icon>
                    </mat-chip>
                    <mat-chip *ngIf="chatSelezionata.chatConSegnalante" [selectable]="true" [removable]="gestibile()"
                      [style.font-weight]="'normal'" [style.font-size]="'normal'" i18n-matTooltip
                      matTooltip="Segnalante" (removed)="removeSegnalante()" class="partecipante" i18n>
                      Segnalante
                    </mat-chip>
                    <div *ngFor="let gruppo of chatSelezionata.gruppiPartecipanti">
                      <mat-chip *ngIf="
                        gruppo &&
                        (!chatSelezionata.moderatore ||
                          gruppo.id !== chatSelezionata.moderatore.id) && !isGruppoEscluso(gruppo)
                      " [selectable]="true" [removable]="gestibile()" [style.font-weight]="
                        sonoMembroDiGruppo(gruppo) ? 'bold' : 'normal'
                      " [style.font-size]="
                        sonoMembroDiGruppo(gruppo) ? 'larger' : 'normal'
                      " [style.color]=" isGruppoEscluso(gruppo) ? '#ff0000' : 'white'"
                      [class.testo-rosso]="gestore(gruppo)" [matTooltip]="
                        gestore(gruppo)
                          ? 'Gestore Segnalazione'
                          : gruppo.nomeOdv
                      " (removed)="remove(gruppo)" class="partecipante">

                        <!--{{utente.persona.nome + ' ' + utente.persona.cognome}} -->
                        {{ gruppo.nomeOdv }}
                        <mat-icon matChipRemove *ngIf="gestibile()">cancel</mat-icon>
                      </mat-chip>
                    </div>

                    <input *ngIf="!gestibile()" matInput i18n-placeholder placeholder="Partecipanti..."
                      [matChipInputFor]="chipListPartecipanti" disabled />
                    <input matInput #inputPartecipanti i18n-placeholder placeholder="Partecipanti..."
                      hint="Aggiungi partecipante..." formControlName="partecipanti" *ngIf="gestibile()"
                      (matChipInputTokenEnd)="addPartecipante($event)" [matChipInputFor]="chipListPartecipanti"
                      [matAutocomplete]="auto3" #triggerPartecipanti="matAutocompleteTrigger"
                      (click)="$event.stopPropagation(); triggerPartecipanti.openPanel()" />
                    <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="openPanel()">
                      <mat-option *ngFor="let item of gruppiFiltrati" [value]="item"
                        (onSelectionChange)="selected(item)" [class.testo-rosso]="gestore(item)">
                        {{ item ? item.nomeOdv : "Segnalante" }}
                        <!-- {{item.persona.nome + ' ' + item.persona.cognome}} -->
                      </mat-option>
                    </mat-autocomplete>
                  </mat-chip-list>
                </mat-form-field>
              </form>
            </div>

            <div class="flex-row flex-space-around-center">
              <div class="flex-column flex-10 flex-space-around-center" *ngIf="
                chatSelezionata &&
                !chatSelezionata.chatConSegnalante &&
                moderatore() &&
                puoChattareConSegnalante() &&
                tuttiPartecipantiAbilitati()
              " class="bottone-modera-chat">
                <button i18n mat-button color="primary" (click)="onAggiungiSegnalante()">
                  Aggiungi Segnalante
                </button>
              </div>

              <div class="flex-10 flex-column flex-space-around-center" *ngIf="
                chatSelezionata &&
                chatSelezionata.chatConSegnalante &&
                moderatore() &&
                puoChattareConSegnalante()
              " class="bottone-modera-chat">
                <button i18n mat-button color="primary" (click)="onEliminaSegnalante()">
                  Elimina Segnalante
                </button>
              </div>

              <div class="flex-10 flex-column flex-space-around-center" *ngIf="
                chatSelezionata && !chatSelezionata.moderatore && membroOdv()
              ">
                <button i18n mat-button color="primary" (click)="onModeraChatClick()">
                  Modera Chat
                </button>
              </div>

              <div class="flex-10 flex-column flex-space-around-center" *ngIf="chatSelezionata && moderatore()"
                class="bottone-modera-chat">
                <button i18n mat-button color="primary" (click)="onRilasciaModeratoreChatClick()">
                  Rilascia il ruolo di Moderatore
                </button>
              </div>
              <div class="flex-20 flex-column" *ngIf="segnalazione && odv">
                <button i18n mat-button color="primary" [disabled]="!segnalazione"
                  (click)="openDialogCreazioneChat(); $event.stopPropagation()" i18n-matTooltip
                  matTooltip="Aggiungi Chat" class="bottone-modera-chat">
                  Crea nuova Chat
                </button>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="trascrizioneInCorso && odvSceltoDaSegnalante" class="flex-row" style="text-align: center;">
          <h3 style="width:100%" i18n>Procedura per inibire l'ascolto dei messaggi audio del segnalante in corso</h3>
          <button i18n mat-button color="primary" [disabled]="!segnalazione" *ngIf="odvSceltoDaSegnalante"
            (click)="onInibisciAscolto(); $event.stopPropagation()" i18n-matTooltip matTooltip="Inibisci Ascolto">
            Inibisci Ascolto
          </button>
        </div>
        <div *ngIf="vocaliInibiti && !trascrizioneInCorso" class="flex-row" style="text-align: center;">
          <h3 style="width:100%" i18n>Il segnalante ha richiesto ed ottenuto l'inibizione all'ascolto dei propri
            messaggi vocali</h3>
        </div>
      </mat-expansion-panel>
      </div>
      <div style="margin-top:18px">
        <app-chat [(segnalazione)]="segnalazione" [chat]="chatSelezionata"></app-chat>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
