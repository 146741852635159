import { Component, Inject } from '@angular/core'
import { DirittiSegnalazione } from '../modello/diritti-segnalazione'

import { Odv } from '../modello/odv'

import { ChatService } from '../servizi/chat.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'


@Component({
  selector: 'seleziona-gruppo-odv-dialog',
  templateUrl: 'seleziona-gruppo-odv.html'
})
export class SelezionaGruppoOdvDialogComponent {
  diritti: DirittiSegnalazione[]
  odvPartecipazioneChat: any
  dirittoSelezionato: DirittiSegnalazione
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<SelezionaGruppoOdvDialogComponent>,
  ) {
    this.diritti = data.diritti
    if (this.odvPartecipazioneChat === undefined) {
      this.odvPartecipazioneChat = this.diritti[0].odvDestinatario
      this.dirittoSelezionato = this.diritti[0]
    } else {
      this.dirittoSelezionato = this.diritti.find(diritto => diritto.odvDestinatario.id === this.odvPartecipazioneChat.id)
    }
  }

  chiudiDialog() {
    this.dialogRef.close({ data: this.odvPartecipazioneChat })
  }

  onGruppoSelezionato(event, odv: Odv) {
    if (event.source.selected) {
      this.odvPartecipazioneChat = odv
    }
  }

}


