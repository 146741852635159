import { OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core'
import { ElementRef } from '@angular/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import { Configurazione } from '../../modello/configurazione'
import { VersioneContenuto } from '../../modello/versione-contenuto'
import { ContenutiService } from '../../servizi/contenuti.service'
import { SessionData } from '../../sessione/dati-sessione'
import { ESITO_OK } from '../../utility/esito'
import {
  mapNumberToSottoTipoContenuto,
  openDialogGenerica,
  randomString,
  SottoTipoContenuto,
  TipoContenuto,
  _arrayBufferToBase64,
  _base64ToArrayBuffer,
} from '../../utility/utility'

@Component({
  selector: 'app-xsp-editor',
  templateUrl: './xsp-editor.component.html',
  styleUrls: ['./xsp-editor.component.scss'],
})
export class XspEditorComponent implements OnInit, OnChanges, OnDestroy {
  editorConfigTb: AngularEditorConfig = {
    enableToolbar: true,
    showToolbar: true,
    toolbarHiddenButtons: [[], ['insertImage', 'insertVideo']],
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: $localize`Inserisci testo qui...`,
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [
      // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  }

  @ViewChild('pdfViewer', { static: false }) visualizzatorePdf: ElementRef
  @Input() testo: string
  @Input() titoloSezione: string
  @Input() attributo: string
  @Input() viewerId: number
  @Input() tipoDocumento: TipoContenuto
  @Input() sottotipoDocumento: SottoTipoContenuto
  @Input() sottotipoSelezionato: SottoTipoContenuto
  @Input() documento: VersioneContenuto
  @Input() lingua: string
  @Input() attivato: boolean
  @Input() inlineTextOnly: boolean
  @Input() soloAllegati: boolean
  @Input() angularEditorEsterno: boolean
  @Input() paroleChiaveOggetto: []
  @Input() paroleChiaveTesto: []
  @Output() aggiungiDocumento = new EventEmitter<{
    attributo: string;
    pdfViewer: number;
    documento: VersioneContenuto;
  }>()
  @Output() salvaDocumento = new EventEmitter<VersioneContenuto>()
  @Output() eliminaDocumento = new EventEmitter<VersioneContenuto>()
  @Output() aggiornaTesto = new EventEmitter<VersioneContenuto>()
  @Output() documentoVisibile = new EventEmitter<void>()
  @Input() form: UntypedFormGroup
  @Output() sonoAttivo = new EventEmitter<SottoTipoContenuto>()

  private _language = 'it-IT'
  currentPage = 1
  pdfViewerId: string
  fileUploaderId: string
  subscriptions = []
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SottoTipoContenuto = SottoTipoContenuto

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private contenutiService: ContenutiService,
    private sessionData: SessionData,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.pdfViewerId = randomString(8, 0, 0, 0)
    this.fileUploaderId = randomString(8, 0, 0, 0)
    // console.log(this);
    // console.log(this.lingua, this.tipoDocumento)
    if (this.lingua && this.tipoDocumento !== null) {
      this.caricaDocumento(
        this.tipoDocumento,
        this.sottotipoDocumento,
        this.lingua
      )
    }
    this.subscriptions.push(
      this.contenutiService.versioneContenutoSubject.subscribe(
        (versioneContenuto) => {
          // se la versione contenuto ricevuta è quella che sto visualizzando, aggiorna il testo
          if (
            versioneContenuto &&
            ((versioneContenuto.nome === this.documento?.nome &&
              versioneContenuto.nome !== '' &&
              versioneContenuto.nome !== null &&
              versioneContenuto.nome !== undefined) ||
              (versioneContenuto.id === this.documento?.id &&
                versioneContenuto.id !== '' &&
                versioneContenuto.id !== null &&
                versioneContenuto.id !== undefined))
          ) {
            // copia versioneContenuto in documento ma nn sovrascrivere il campo base64
            this.documento = {
              ...this.documento,
              ...versioneContenuto,
              base64: this.documento.base64,
            }
          }
        }
      )
    )
  }

  onAggiungiDocumento(documento: VersioneContenuto) {
    this.sonoAttivo.emit(this.documento?.contenuto?.sottotipo)
    this.documento =
    { ...this.documento,
      ...documento,
    }
    this.documento.usaFile = true
    this.aggiungiDocumento.emit({
      attributo: this.attributo,
      pdfViewer: this.viewerId,
      documento: this.documento,
    })
  }

  aggiornaEmail(email: VersioneContenuto) {
    this.aggiornaTesto.emit(email)
  }

  languageChanged(language: string) {
    this.lingua = language
    this.caricaDocumento(
      this.tipoDocumento,
      this.sottotipoDocumento,
      this.lingua
    )
  }

  onAggiornaTesto() {
    this.sonoAttivo.emit(this.sottotipoSelezionato)
    if (this.form) {
      this.form.markAsDirty()
    }
    this.aggiornaTesto.emit(this.documento)
  }

  onUsaFileChange(usaFile) {
    this.documento.usaFile = usaFile
    this.salvaDocumento.emit(this.documento)
  }

  onEliminaFile() {
    this.sonoAttivo.emit(this.documento?.contenuto?.sottotipo)
    if (this.documento.usaFile) {
      openDialogGenerica(
        this.dialog,
        $localize`Eliminazione file`,
        '',
        $localize`Confermi di voler eliminare il file?`,
        () => this.doEliminaFile(),
        () => {
          console.log('switch annullato')
        },
        undefined,
        undefined,
        '50vw'
      )
    }
  }

  doEliminaFile() {
    this.eliminaDocumento.emit(this.documento)
  }

  attivaVisualizzatore() {
    this.sonoAttivo.emit(this.documento?.contenuto?.sottotipo)
    this.getDocumento(this.tipoDocumento, this.sottotipoDocumento, this.lingua)
  }

  isDocumentoPdf() {
    return this.documento?.tipo === 'application/pdf'
  }

  paginaAvanti() {
    this.currentPage++
  }

  paginaIndietro() {
    this.currentPage--
  }

  caricaDocumento(
    tipoDocumento: string,
    sottotipoDocumento: number | string,
    lingua: string
  ) {
    this.documentoVisibile.emit()
    if (this.documento?.id === 'OMISSIS') {
      this.snackBar.open(
        $localize`Non sei abilitato al download del documento!`,
        null,
        {
          duration: 6000,
        }
      )
      return
    }
    // if (!this.documento?.url) {
    //   this.snackBar.open(
    //     $localize`Il documento non ha un indirizzo valido!`,
    //     null,
    //     {
    //       duration: 6000,
    //     }
    //   )
    //   return
    // }

    // se sottoTipoDocumento è un numero, lo converto in stringa
    if (typeof sottotipoDocumento === 'number') {
      sottotipoDocumento = mapNumberToSottoTipoContenuto(sottotipoDocumento)
    }
    this.contenutiService
      .getVersioneContenuto(tipoDocumento, sottotipoDocumento, lingua)
      .subscribe(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            const contenuto = JSON.parse(esito.payload)
            this.documento = contenuto
            // this.documento.base64 = base64
            /*         if (base64) {
                  this.documento.arrayBuffer = _base64ToArrayBuffer(base64?.indexOf(',') !== -1 ? base64?.split(',')[1] : base64);
                }
                if (this.documento.arrayBuffer && !this.documento.base64) {
                  this.documento.base64 = _arrayBufferToBase64(this.documento.arrayBuffer);
                } */
          }
        },
        (errore) => {
          console.log(errore)
          this.snackBar.open(
            $localize`Il recupero del file è fallito!` + errore,
            null,
            {
              duration: 6000,
            }
          )
        }
      )
  }

  async getDocumento(
    tipoDocumento: string,
    sottotipoDocumnto: number | string,
    lingua: string
  ): Promise<void> {
    // se sottotipoDocumnto è un numero, lo converto in stringa
    if (typeof sottotipoDocumnto === 'number') {
      sottotipoDocumnto = mapNumberToSottoTipoContenuto(sottotipoDocumnto)
    }
    const esito = await this.contenutiService
      .getVersioneContenuto(tipoDocumento, sottotipoDocumnto, lingua)
      .toPromise()

    // verifica se l'esito è ok, ricava la versione contenuto e se ha un hash lo usa per recuperare il file
    if (esito.esito === ESITO_OK) {
      this.documento = JSON.parse(esito.payload)
      if (this.documento && this.documento.url && this.documento.url !== 'null') {
        const fileBlob: Blob = await this.contenutiService
          .downloadFile(this.documento.url)
          ?.toPromise()

        if (fileBlob && fileBlob?.size > 0) {
          // Create a new File instance
          const file = new File([fileBlob], this.documento.url, {
            type: fileBlob?.type,
          })

          // valorizza tipo con il tipo del file
          this.documento.tipo = file?.type

          // Converting Blob to Base64
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onloadend = () => {
            const base64data = reader.result
            this.documento.base64 = base64data
          }
        }
      }
    }
  }

  get base64Raw() {
    const contenuto = this.documento?.base64 as string
    // se c'è la virgola vuol dire che è un base64 con header e lo debbo rimuovere
    if (contenuto?.indexOf(',') !== -1) {
      return contenuto?.split(',')[1]
    }
    return contenuto
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['lingua'] || changes['sottotipoSelezionato']) {
      // Qui puoi mettere la logica che vuoi eseguire quando lingua cambia
      if (
        this.lingua &&
        this.tipoDocumento !== null &&
        this.sottotipoDocumento === this.sottotipoSelezionato
      ) {
        console.log(
          'Recupero il documento',
          this.tipoDocumento,
          this.sottotipoDocumento,
          this.lingua
        )
        this.getDocumento(
          this.tipoDocumento,
          this.sottotipoDocumento,
          this.lingua
        )
      }
    }
  }

  public get language(): string {
    return this._language
  }

  public computeLabel(documento) {
    return documento && documento.url && documento.url !== 'null'
      ? $localize` clicca qui per sostituirlo.`
      : $localize` clicca qui per selezionarne uno.`
  }

  get isDocumento() {
    return this.documento?.contenuto.tipo === TipoContenuto.DOCUMENTO
  }

  get isEmail() {
    return this.documento?.contenuto.tipo === TipoContenuto.EMAIL
  }

  get mostraVisualizzatorePDF() {
    console.log('mostraVisualizzatorePDF')
    const result = this.isDocumentoPdf() && this.base64Raw && this.documento.usaFile
    if (this.documento.contenuto.sottotipo === SottoTipoContenuto.INFORMATIVA_REGISTRAZIONE_UTENTE) {
      console.log('mostraVisualizzatorePDF', result)
    }
    return result
  }

  get lingue() {
    if (!this.configurazione) {
      return []
    }
    return this.configurazione.lingue === undefined ||
      this.configurazione.lingue === null ||
      this.configurazione.lingue?.trim() === ''
      ? []
      : this.configurazione.lingue.split(',')
  }

  get configurazione(): Configurazione {
    return this.sessionData.configurazione
  }

  changeLanguage(
    lingua: string,
  ) {
    // Aggiorna il contenuto in base alla lingua selezionata
    this.lingua = lingua
    this.getDocumento(
      this.tipoDocumento,
      this.sottotipoDocumento,
      this.lingua
    )
  }

}
