import { Operazioni } from '../utility/utility'
import { DirittiSegnalazione } from './diritti-segnalazione'
import { Lavorazione } from './lavorazione'
import { Odv } from './odv'
import { Segnalazione } from './segnalazione'
import { User } from './user'

export class LogOperativo {
  id: number
  operazione: Operazioni
  dataOperazione: Date
  segnalazione: Segnalazione
  dirittiSegnalazione: DirittiSegnalazione
  odvDestinatario: Odv
  odv: Odv
  lavorazione: Lavorazione
  utente: User
  utenteBeneficiario: User
  note: string
}
