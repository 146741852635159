// idp.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiziRest } from '../utility/utility';
import { map } from 'rxjs/operators';
import { Esito } from '../utility/esito';

export interface IdentityProvider {
  id: string;
  name: string;
  certificate: string;
  issuer: string;
  entryPoint: string;
  callbackUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class IdpService {


  constructor(private http: HttpClient) {}

  getIdps(): Observable<IdentityProvider[]> {
    // mappa l'oggetto JSON ricevuto dal server che è un Esito, ricavando il payload ed in quest'ultimo l'array di IdentityProvider
    return this.http.get<Esito>(`${ServiziRest.urlIdp}`).
    pipe(map((esito: Esito) => JSON.parse(esito.payload) as IdentityProvider[]));

  }

  getIdp(id: string): Observable<IdentityProvider> {
    return this.http.get<Esito>(`${ServiziRest.urlIdp}/${id}`).
    pipe(map((esito: Esito) => JSON.parse(esito.payload) as IdentityProvider));
  }

  addIdp(idp: IdentityProvider): Observable<IdentityProvider> {
    return this.http.post<Esito>(ServiziRest.urlIdp, idp).
    pipe(map((esito: Esito) => JSON.parse(esito.payload) as IdentityProvider));
  }

  updateIdp(idp: IdentityProvider): Observable<any> {
    return this.http.put(`${ServiziRest.urlIdp}/${idp.id}`, idp);
  }

  deleteIdp(id: string): Observable<any> {
    return this.http.delete(`${ServiziRest.urlIdp}/${id}`);
  }
}
