import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { Odv } from '../modello/odv'

@Injectable({
  providedIn: 'root'
})
export class GestoriSegnalazioniService {
  gruppiGestoriChanged = new Subject<Odv[]>()
  // gruppoGestoreAdded = new Subject<Odv>();
  // gruppoGestoreModified = new Subject<Odv>();
  // gruppoGestoreDeleted = new Subject<Odv>();

  constructor () { }

  private gruppiGestori: Odv[] = []

  setGruppi (gruppi: Odv[]) {
    this.gruppiGestori = gruppi
    this.gruppiGestoriChanged.next(this.gruppiGestori.slice())
  }

  getGruppiGestori () {
    return this.gruppiGestori.slice()
  }

  getOdv (index: number) {
    return this.gruppiGestori[index]
  }

  addOdv (odv: Odv) {
    this.gruppiGestori.push(odv)
    this.gruppiGestoriChanged.next(this.gruppiGestori.slice())
  }

  updateOdv (newOdv: Odv) {
    const indice = this.gruppiGestori.findIndex(gruppo => gruppo.id === newOdv.id)
    this.gruppiGestori[indice] = newOdv
    this.gruppiGestoriChanged.next(this.gruppiGestori.slice())
  }

  deleteOdv (odv: Odv) {
    const indice = this.gruppiGestori.findIndex(gruppo => gruppo.id === odv.id)
    this.gruppiGestori.splice(indice, 1)
    this.gruppiGestoriChanged.next(this.gruppiGestori.slice())
  }
}
