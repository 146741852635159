<div class="alternate-theme">
  <h2 i18n mat-dialog-title class="titolo-dialog">Condivisione {{nomeEntita()}} con il
    gruppo {{
    destinatario.nomeOdv }}</h2>
  <mat-dialog-content>
    <div class="centra-form">
      <div style="margin-top: 20px;">
        <mat-form-field color="accent" style="display: block;width:95%;">
          <mat-select [(ngModel)]="odvCheInoltra" i18n-placeholder placeholder="Seleziona il gruppo che inoltra la segnalazione">
            <mat-option *ngFor="
                  let odv of odvs" (onSelectionChange)="
                  onGruppoSelezionato(
                    $event,
                    odv
                  )
                " [value]="odv">
              {{ odv.nomeOdv }}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>
      <table style="width:100%; overflow: hidden">

        <tr *ngIf="puoModificareLavorazione">
          <td colspan="12">
            <mat-checkbox i18n [(ngModel)]="modificaLavorazione" (change)="toggleModificalavorazione($event)">Modifica
              Lavorazione</mat-checkbox>
          </td>
        </tr>
        <tr *ngIf="puoCreareLavorazioni && !isLavorazione">
          <td colspan="12">
            <mat-checkbox i18n [(ngModel)]="aperturaLavorazioni">Apertura Nuove Lavorazioni</mat-checkbox>
          </td>
        </tr>
        <tr *ngIf="puoChattareConSegnalante===true && !isLavorazione">
          <td colspan="12">
            <mat-checkbox i18n [(ngModel)]="chat">Chat con Segnalante</mat-checkbox>
          </td>
        </tr>
        <tr *ngIf="!segnalazioneAnonima && puoVedereSegnalante && !mancaConsenso && !isLavorazione">
          <td colspan="8">
            <mat-checkbox i18n [(ngModel)]="nomeSegnalante">Vede nome Segnalante</mat-checkbox>
          </td>
          <td colspan="4">
            <div i18n *ngIf="(mancaConsenso || mancaConsenso === undefined) && nomeSegnalante" class="colore-testo-avviso">
              Attenzione, non hai ancora il consenso del segnalante, valuta attentamente se è opportuno svelarne
              l'identità a terzi!
            </div>
          </td>
        </tr>
        <tr *ngIf="puoVedereSegnalati && !isLavorazione">
          <td colspan="12">
            <mat-checkbox i18n [(ngModel)]="nomiSegnalati">Vede nomi Segnalati</mat-checkbox>
          </td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button i18n mat-button mat-dialog-close class="margin-auto" [disabled]="odvCheInoltra === undefined" cdkFocusInitial
      (click)="chiudiDialog()">Condividi</button>
    <button i18n mat-button mat-dialog-close class="margin-auto" (click)="annulla()">Annulla</button>
  </mat-dialog-actions>
</div>
