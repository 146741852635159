<div class="overlay flex centered" *ngIf="loading">
  <mat-spinner diameter="50" strokeWidth="5" style="margin:auto"></mat-spinner>
</div>
<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav mode="push">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon class="white-icon">menu</mat-icon>
    </button>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-toolbar></app-toolbar>
    <div class="flex-row">


      <div class="titolo-pagina" i18n>
        Segnalazioni
      </div>

      <div class="padding-10" style="display:flex;"
      *ngIf="mostraSoloTerminiModificati !== null && mostraSoloTerminiModificati !== undefined">
          <!-- mostra un toggle button -->
          <mat-button-toggle class="bottone-piccolo" style="margin:auto; font-size: smaller;" #toggleTermineModifica
            [matTooltip]="getTooltipToggleTermineCancellazione()" (change)="toggleTerminiCancellazione()">
            <mat-icon>lock_clock</mat-icon>
          </mat-button-toggle>
      </div>

      <app-filtro-stati (filtroSelezionato)="aggiornaFiltro($event)" class="padding-10 font-smaller margin-auto"
        [filtroBozza]="bozza()" [filtroAlcuneLavorazioniChiuse]="alcuneLavorazioniChiuse()"
        [filtroLavorazioniChiuse]="chiuse()" [filtroLavorazioniInCorso]="lavorazioniInCorso()"
        [filtroRitirate]="ritirate()" [filtroNessunaLavorazione]="nessunaLavorazioneInCorso()">
      </app-filtro-stati>
    </div>

    <div class="mat-elevation-z8" id="listaSegnalazioni" *ngIf="odv && elencoAziende?.length > 0">
      <mat-table #table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)">

        <mat-header-row *matHeaderRowDef="colonneVisualizzate"></mat-header-row>
        <mat-row *matRowDef="let segnalazione; columns: colonneVisualizzate;" class="pointer"
          (click)="selected(segnalazione)"></mat-row>

        <ng-container matColumnDef="progressivo" *ngIf="odv || custode">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header [class.focused]="isInputFocused"> Progr
          </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione" [innerHTML]="segnalazione.progressivo | highlight:stringaRicercata">
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="idPerSegnalante">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header [class.focused]="isInputFocused"> Identificativo
            Segnalazione </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione"
            [innerHTML]="segnalazione.idPerSegnalante | highlight:stringaRicercata">
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="aziendaInteressata" *ngIf="multiAzienda">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Azienda </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione"> {{segnalazione.aziendaInteressata?.nomeAzienda}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="oggetto">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header [class.focused]="isInputFocused">
            Oggetto </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione" [innerHTML]="segnalazione.oggetto | highlight:stringaRicercata">
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dataInserimento">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header [class.focused]="isInputFocused"> Data e Ora
          </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione"
            [innerHTML]="segnalazione.dataInserimento | date: 'short' | highlight:stringaRicercata">
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="descrizione" *ngIf="mostraTipiNonConformita">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header [class.focused]="isInputFocused">
            Tipo non conformità / Violazione </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione" [innerHTML]='(segnalazione.descrizione?
            segnalazione.descrizione.length > 100 ? segnalazione.descrizione.substr(0,100) +
            "...":segnalazione.descrizione:"") | highlight: stringaRicercata'></mat-cell>
        </ng-container>
        <ng-container matColumnDef="segnalante">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Segnalante </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione">
            {{ segnalante(segnalazione)}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="stato">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Stato </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione">
            {{ statoSegnalazione(segnalazione)}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="scadenza">
          <mat-header-cell i18n *matHeaderCellDef mat-sort-header [class.focused]="isInputFocused"> Scadenza
          </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione"
            [innerHTML]="scadenza(segnalazione) | date: 'shortDate' | highlight:stringaRicercata">
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="azioni">
          <mat-header-cell *matHeaderCellDef class="width-full">
            <button i18n-matTooltip matTooltip="Nuova Segnalazione" mat-icon-button class="destra"
              (click)="aggiungiSegnalazione(); $event.stopPropagation();">
              <mat-icon class="icone-testata-tabelle" aria-label="aggiungi segnalazione">add_circle</mat-icon>
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let segnalazione" class="height-120px">
            <button i18n-matTooltip matTooltip="Approfondimenti" mat-icon-button *ngIf="possoChattare(segnalazione)"
              (click)="openChatSegnalazione(segnalazione); $event.stopPropagation();">
              <mat-icon class="icona-riga-tabella" aria-label="chat segnalazione">chat</mat-icon>
            </button>
            <button *ngIf="false" mat-icon-button
              (click)="openDialogConfermaEliminazioneSegnalazione(segnalazione); $event.stopPropagation();">
              <mat-icon class="icona-riga-tabella" aria-label="cancella segnalazione">delete</mat-icon>
            </button>
            <button *ngIf="(isGestore) && consensiAbilitati && !segnalazione.anonima" mat-icon-button
              (click)="toggleRow($event, segnalazione); $event.stopPropagation();"
              [matBadge]="numeroConsensi(segnalazione)" matBadgeColor="accent" matBadgeSize="medium"
              id="button-{{segnalazione.id}}" #badge [matTooltip]="calcolaTooltipBottoneConsensi(segnalazione)"
              [matBadgeHidden]="!mostrareBadge(badge, segnalazione)">
              <mat-icon class="icona-riga-tabella" aria-label="visualizza consensi">more_vert</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let segnalazione" [attr.colspan]="innerDisplayedColumns.length">
            <div class="example-element-detail" *ngIf="segnalazione.consensi?.length > 0"
              [@detailExpand]="segnalazione == expandedElement ? 'expanded' : 'collapsed'">
              <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                <div class="width-full text-center">
                  <h3 i18n>Consensi Segnalazione</h3>
                </div>
                <mat-table #innerTables mat-table #innerSort="matSort" [dataSource]="getDataSource(segnalazione)"
                  class="inner-table" matSort matSortActive="id" matSortDirection="desc">
                  <ng-container matColumnDef="{{innerColumns[i]}}"
                    *ngFor="let innerDisplayedColumn of innerDisplayedColumns; let i=index">

                    <div *ngIf="checkIfAzioniIsVisible(innerDisplayedColumn)">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <button *ngIf="puoAggiungereRichiesta(segnalazione)" i18n-matTooltip
                          matTooltip="Nuova Richiesta di consenso" mat-icon-button class="destra"
                          (click)="creaModificaRichiestaConsenso(segnalazione); $event.stopPropagation();">
                          <mat-icon class="icone-testata-tabelle" aria-label="nuova richiesta di consenso">add_circle
                          </mat-icon>
                        </button>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let consenso">
                        <button *ngIf="puoEditareRichiesta(consenso, segnalazione)" i18n-matTooltip
                          matTooltip="Modifica Richiesta" mat-icon-button class="destra"
                          (click)="modificaRichiestaConsenso(consenso, segnalazione); $event.stopPropagation();">
                          <mat-icon class="icone-testata-tabelle" aria-label="modifica richiesta di consenso">create
                          </mat-icon>
                        </button>
                        <button *ngIf="puoInviareRichiesta(consenso, segnalazione)" i18n-matTooltip
                          matTooltip="Invia Richiesta" mat-icon-button class="destra"
                          (click)="openDialogConfermaInvioRichiestaConsenso(consenso, segnalazione); $event.stopPropagation();">
                          <mat-icon class="icone-testata-tabelle" aria-label="invia richiesta di consenso">send
                          </mat-icon>
                        </button>
                        <button *ngIf="puoAnnullareRichiesta(consenso, segnalazione)" i18n-matTooltip
                          matTooltip="Annulla Richiesta" mat-icon-button class="destra"
                          (click)="annullaRichiestaConsenso(consenso, segnalazione); $event.stopPropagation();">
                          <mat-icon class="icone-testata-tabelle" aria-label="invia richiesta di consenso">undo
                          </mat-icon>
                        </button>
                        <button *ngIf="puoAutorizzareRichiesta(consenso, segnalazione)" i18n-matTooltip
                          matTooltip="Autorizza Richiesta" mat-icon-button class="destra"
                          (click)="openDialogConfermaAzioneConsenso(true, consenso, segnalazione); $event.stopPropagation();">
                          <mat-icon class="icone-testata-tabelle" aria-label="invia richiesta di consenso">thumb_up
                          </mat-icon>
                        </button>
                        <button *ngIf="puoRifiutareRichiesta(consenso, segnalazione)" i18n-matTooltip
                          matTooltip="Rifiuta Richiesta" mat-icon-button class="destra"
                          (click)="openDialogConfermaAzioneConsenso(false, consenso, segnalazione); $event.stopPropagation();">
                          <mat-icon class="icone-testata-tabelle" aria-label="invia richiesta di consenso">thumb_down
                          </mat-icon>
                        </button>
                      </mat-cell>
                    </div>
                    <!-- Nome con lucchetto -->
                    <div *ngIf="checkIfNomeIsVisible(innerDisplayedColumn)">
                      <mat-cell *matCellDef="let consenso; let rowIndex = index">
                        <div class="flex-0-row">
                          <div>
                            <button mat-icon-button
                              (click)="unlockedRows[segnalazione.progressivo] = !unlockedRows[segnalazione.progressivo]">
                              <mat-icon>{{unlockedRows[segnalazione.progressivo] ? 'lock_open' : 'lock'}}</mat-icon>
                            </button>
                          </div>
                          <div class="flex-fill" style="margin: auto;">
                            {{unlockedRows[segnalazione.progressivo] ? nomeSegnalante(consenso) : '********'}}
                          </div>
                        </div>

                      </mat-cell>
                    </div>

                    <!-- Cognome con lucchetto -->
                    <div *ngIf="checkIfCognomeIsVisible(innerDisplayedColumn)">
                      <mat-cell *matCellDef="let consenso; let rowIndex = index">
                        <span>
                          {{unlockedRows[segnalazione.progressivo] ? cognomeSegnalante(consenso) : '********'}}
                        </span>
                      </mat-cell>
                    </div>

                    <div *ngIf="checkIfRichiedenteIsVisible(innerDisplayedColumn)">
                      <mat-cell *matCellDef="let consenso">
                        {{nomeOdvRichiedente(consenso)}}
                      </mat-cell>
                    </div>

                    <div *ngIf=" innerDisplayedColumn !== 'azioni' && innerDisplayedColumn !== 'informativa'
                    && innerDisplayedColumn !== 'odvRichiedente'">
                      <mat-header-cell i18n *matHeaderCellDef mat-sort-header> {{innerDisplayedColumn}}
                      </mat-header-cell>
                    </div>
                    <div *ngIf="innerDisplayedColumn != 'Informativa'">
                      <mat-cell *matCellDef="let consenso"> {{ getDatoInnerTable(consenso, innerColumns[i]) }}
                      </mat-cell>
                    </div>
                    <div *ngIf="innerDisplayedColumn != 'Richiedente'">
                      <mat-cell *matCellDef="let consenso"> {{ getDatoInnerTable(consenso, innerColumns[i]) }}
                      </mat-cell>
                    </div>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="innerColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: innerColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
            <div class="example-element-detail" *ngIf="!segnalazione.consensi?.length"
              [@detailExpand]="segnalazione == expandedElement ? 'expanded' : 'collapsed'">
              <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                <div class="width-full text-center">
                  <h3 i18n>Nessuna richiesta di consenso presente</h3>
                  <div *ngIf="!puoAggiungereRichiesta(segnalazione)">
                    <h4 i18n>Il segnalante non ha indirizzato la segnalazione ad uno dei gruppi di cui sei membro,
                      quindi non
                      sei abilitato all'inserimento di richieste di consenso verso di lui</h4>
                  </div>
                  <button i18n-matTooltip matTooltip="Crea una nuova richiesta di consenso" mat-icon-button
                    *ngIf="puoAggiungereRichiesta(segnalazione)"
                    (click)="creaModificaRichiestaConsenso(segnalazione); $event.stopPropagation();">
                    <mat-icon aria-label="nuova richiesta di consenso">add_circle</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let segnalazione; columns: ['expandedDetail']" class="riga-dettaglio-inner"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]"
        [showFirstLastButtons]="true"></mat-paginator>
    </div>
    <div *ngIf="!odv" class="flex-column">
      <h4 style="align-self: center;">
        Accesso non consentito
      </h4>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>

<div class="footer centro"> {{versione}} </div>
