<div style="display:contents">
  <div *ngIf="!isDisabled || odv" style="display:contents" class="flex-row">
    <button class="flex" mat-icon-button (mousedown)="startRecording($event)" (touchstart)="startRecording($event)"
    (mouseup)="stopRecording($event)" (touchend)="stopRecording($event)"
      aria-label="Start recording" class="record-button" >
      <mat-icon class="icone-testata-tabelle" i18n-matTooltip
      matTooltip="Cliccare sul microfono e tenere premuto per registrare un messaggio vocale" matTooltipPosition="right" color="accent">mic</mat-icon>
    </button>
    <button class="flex" *ngIf="audioIsPresent" mat-icon-button (click)="playAudio()" aria-label="Play audio"
      >
      <mat-icon class="icone-testata-tabelle" color="accent">play_arrow</mat-icon>
    </button>
    <button class="flex" *ngIf="audioIsPresent" mat-icon-button (click)="deleteAudio()" aria-label="Delete audio"
      >
      <mat-icon class="icone-testata-tabelle" color="accent">delete</mat-icon>
    </button>
    <div style="display:contents" *ngIf="false">
      <mat-slider class="flex" min="0.6" max="1.8" step="0.1" [(ngModel)]="sliderValue" (input)="onSliderInput($event)"
        aria-label="Slider"  [matTooltip]="sliderValue"></mat-slider>
    </div>

  </div>

  <div style="display:contents" *ngIf="!odv">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon *ngIf="!isDisabled " class="icone-testata-tabelle" style="min-width: 0px!important;" color="accent">more_vert</mat-icon>
      <mat-icon *ngIf="isDisabled" class="icone-testata-tabelle" style="min-width: 0px!important;" color="accent">mic</mat-icon>
    </button>
    <mat-menu #menu="matMenu" yPosition="above">
      <button mat-menu-item (click)="openDialog()">
        <mat-icon>mic</mat-icon>
        <span i18n>Gestisci consenso audio</span>
      </button>
    </mat-menu>
  </div>

</div>
