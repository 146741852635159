import { Component, OnInit } from '@angular/core'
import { ConfigurazioneService } from '../../servizi/configurazione.service'
import { SessionData } from '../../sessione/dati-sessione'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { FaqComponent } from '../../login/faq/faq.component'
import { VisualizzatorePdfDialogComponent } from '../visualizzatore-pdf/visualizzatore-pdf.component'
import { TipoDocumento } from '../../modello/documento'
import { TipoContenuto, SottoTipoContenuto } from '../../utility/utility'
import { SessionDataService } from '../../servizi/session-data.service'

@Component({
  selector: 'app-toolbar-landing',
  templateUrl: './toolbar-landing.component.html',
  styleUrls: ['./toolbar-landing.component.scss'],
})
export class ToolbarLandingComponent implements OnInit {
  timeoutCaricamento: boolean

  constructor(
    private configurazione: ConfigurazioneService,
    private dialog: MatDialog,
    private router: Router,
    private sessionData: SessionData,
    private sessionDataService: SessionDataService
  ) {}

  ngOnInit() {
    // console.log('organizzo le FAQ in una struttura dati idonea...')
    const temp = this.sessionData.configurazione.faq
    this.sessionDataService.aggiornamentoDatiSessione.subscribe(() => {
      this.timeoutCaricamento = true
    })
  }

  getLogoCliente() {
    return this.sessionData.logoCliente !== undefined &&
      this.sessionData.logoCliente.base64 !== undefined
      ? this.sessionData.logoCliente.base64
      : this.getLogoSecure()
  }

  getLogoSecure() {
    return this.sessionData.logoSecure !== undefined &&
      this.sessionData.logoSecure.base64 !== undefined
      ? this.sessionData.logoSecure.base64
      : '/assets/img/logo-secure.png'
  }

  apriDialogCookiesPolicy() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.cookiePolicyDocumento,
        testo: this.sessionData.configurazione.cookiePolicy,
        titolo: 'Cookie Policy',
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.COOKIE_POLICY,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: true,
        canPrint: true,
      },
    })
  }

  get codiceLingua() {
    // ricava il codice della lingua corrente dalla url
    return window.location.href.split('/')[3]
  }

  apriDialogTerminiServizio() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.terminiServizioDocumento,
        testo: this.sessionData.configurazione.terminiServizio,
        titolo: $localize`Termini di Servizio`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.TERMINI_SERVIZIO,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: true,
        canPrint: true,
      },
    })
  }

  apriFAQ() {
    const dialogRef = this.dialog.open(FaqComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {},
    })
  }

  apriDialogNormativa() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.normativaDocumento,
        testo: this.sessionData.configurazione.normativa,
        titolo: $localize`Normativa`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.NORMATIVA,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: true,
        canPrint: true,
      },
    })
  }

  apriDialogPrivacyPolicy() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.privacyPolicyDocumento,
        testo: this.sessionData.configurazione.privacyPolicy,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        titolo: 'Cookies & Privacy Policy',
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.PRIVACY,
        lingua: this.codiceLingua,
        bypassAuth: true,
      },
    })
  }
}
