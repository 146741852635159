
import { AuthService } from './auth.service'
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
@Injectable({
  providedIn: 'root'
})
export class SegnalanteGuard implements CanActivate {
  constructor (private authService: AuthService, private router: Router, private snackBar: MatSnackBar) {

  }

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.getUser() && !this.authService.getUser().automatico) {
      return true
    } else {
      this.router.navigate(['segnalazione'])
      this.snackBar.open($localize `Pagina non disponibile`, null, {
        duration: 4000
      }).afterDismissed().subscribe(() => {

      })
    }
  }
}
