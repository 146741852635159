<h2 mat-dialog-title class="titolo-dialog" i18n>
  Richiesta di sblocco dati Segnalante
</h2>
<mat-dialog-content>
  <div class="centra-form">
    <div >
      <div >
        <form [formGroup]="formRichiesta" style="width:100%">
          <div >
            <div >
              <div color="accent">
                <angular-editor #motivazione formControlName="motivazioneRichiesta" [config]="editorConfig">
                </angular-editor>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <mat-form-field color="accent" style="display: block;width:95%;">
                <mat-select formControlName="odv" i18n-placeholder placeholder="Seleziona il gruppo che richiede lo sblocco">
                  <mat-option *ngFor="
                          let dirittoSegnalazione of diritti" (onSelectionChange)="
                          onGruppoSelezionato(
                            $event,
                            dirittoSegnalazione.odvDestinatario
                          )
                        " [value]="dirittoSegnalazione">
                    {{ dirittoSegnalazione.odvDestinatario.nomeOdv }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div style="margin-bottom: 20px;"></div>
        </form>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <div class="flex-row flex-center-space-around">
    <button i18n mat-button mat-dialog-close>Annulla</button>
    <button i18n mat-button (click)="onInoltraRichiesta()">Inoltra Richiesta di sblocco</button>
  </div>

</mat-dialog-actions>
