import { Injectable } from '@angular/core'
import { Azienda } from '../modello/azienda'
import { ServiziRest } from '../utility/utility'
import { HttpClient } from '@angular/common/http'
import { Esito } from '../utility/esito'
import { Informativa } from '../modello/informativa'

@Injectable({
  providedIn: 'root'
})
export class AziendeService {
  constructor (private http: HttpClient) { }

  handleErrorPromise (error: Response | any) {
    console.error(error.message || error)
    return Promise.reject(error.error || error)
  }

  aggiornaAzienda (azienda: Azienda): Promise<Esito> {
    return this.http.patch(ServiziRest.urlAziende, azienda).toPromise()
      .then((esito: Esito) => esito)
      .catch(this.handleErrorPromise)
  }

  aggiornaInformativa (informativa: Informativa): Promise<Esito> {
    return this.http.patch(ServiziRest.urlAziende + '/informativa', informativa).toPromise()
      .then((esito: Esito) => esito)
      .catch(this.handleErrorPromise)
  }

  creaAzienda (azienda: Azienda): Promise<Esito> {
    return this.http.post(ServiziRest.urlAziende, azienda).toPromise()
      .then((esito: Esito) => esito)
      .catch(this.handleErrorPromise)
  }

  cancellaAzienda (azienda: Azienda): Promise<Esito> {
    return this.http.delete(ServiziRest.urlAziende + '/' + azienda.id).toPromise()
      .then((esito: Esito) => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaAziende (): Promise<Esito> {
    return this.http.get(ServiziRest.urlAziende).toPromise()
      .then((esito: Esito) => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaAziendeConInformative (): Promise<Esito> {
    return this.http.get(ServiziRest.urlAziende + '/con/informative').toPromise()
      .then((esito: Esito) => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaAziendeConOdv (): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlAziende + '/odv/odv').toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaAzienda (azienda: Azienda): Promise<Esito> {
    return this.http.get(ServiziRest.urlAziende + '/' + azienda.id).toPromise()
      .then((esito: Esito) => esito)
      .catch(this.handleErrorPromise)
  }
}
