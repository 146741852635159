import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'

import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Subscription } from 'rxjs'

import { AuthService } from '../../servizi/auth.service'

import { RicercaService } from '../../servizi/ricerca.service'

import { Esito, ESITO_OK } from '../../utility/esito'
import { BOZZA_INDEX, CHIUSA_INDEX, compare, compareNumbers, INAMMISSIBILE_INDEX, IN_LAVORAZIONE_INDEX, ISTRUTTTORIA_APERTA_INDEX, NESSUNA_LAVORAZIONE_IN_CORSO_INDEX, NON_COMPETENTE_INDEX, statoLavorazioneLeggibile } from '../../utility/utility'
import { Location } from '@angular/common'
import { Lavorazione } from '../../modello/lavorazione'
import { LavorazioneService } from '../../servizi/lavorazione.service'
import { SessionData } from '../../sessione/dati-sessione'
import { Router } from '@angular/router'
import * as moment from 'moment'

@Component({
  selector: 'app-elenco-lavorazioni',
  templateUrl: './elenco-lavorazioni.component.html',
  styleUrls: ['./elenco-lavorazioni.component.scss']
})
export class ElencoLavorazioniComponent implements OnInit, AfterViewInit, OnDestroy {
  dataSource

  lavorazioni: Lavorazione[]

  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  colonneVisualizzate = ['id', 'gestore', 'stato', 'sorgente', 'tema', 'ammissibile']

  sortedData
  lavorazione: Lavorazione

  lavorazioneForm: UntypedFormGroup

  @ViewChild('inputOdvs', { static: true }) inputOdvs: ElementRef

  private subscriptions: Array<Subscription> = []

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private lavorazioneService: LavorazioneService,
    private ricercaService: RicercaService,
    private location: Location,
    private router: Router,
  ) { }
  ngOnDestroy(): void {
    this.subscriptions?.forEach(sub => sub.unsubscribe())
  }

  ngOnInit() {
    if (!this.authService.getUser()?.odv) {
      this.location.back()
    } else {
      this.subscriptions.push(this.ricercaService.filtraElenco.subscribe((cercami: string) => {
        this.applyFilter(cercami)
      }))
    }
  }

  ngAfterViewInit() {
    if (this.paginator) {
      const paginatorIntl = this.paginator._intl
      paginatorIntl.nextPageLabel = ''
      paginatorIntl.previousPageLabel = ''
      paginatorIntl.lastPageLabel = ''
      paginatorIntl.firstPageLabel = ''
    }
    this.lavorazioneService.recuperaLavorazioniByUtente().then((esito: Esito) => {
      if (esito.esito === ESITO_OK) {
        this.lavorazioni = JSON.parse(esito.payload)
        this.sortedData = this.lavorazioni.slice()
        this.dataSource = new MatTableDataSource(this.sortedData)
        this.dataSource.sort = this.sort
        this.dataSource.sortingDataAccessor = (item, property) => {
          const value = item[property]
          return typeof value === 'string' ? value.toLowerCase() : value
        }
        this.dataSource.paginator = this.paginator
      }
    })
  }

  sortData(sort: Sort) {
    const data = this.lavorazioni.slice()
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data
      return
    }

    this.dataSource.data = data.sort((a: Lavorazione, b: Lavorazione) => {
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {
        case 'id':
          return compareNumbers(a.id, b.id, isAsc)
        case 'stato':
          return compare(a.stato, b.stato, isAsc)
        case 'sorgente':
          return compare(a.lavorazioneMaster.sorgente, b.lavorazioneMaster.sorgente, isAsc)
        case 'tema':
          return compare(a.lavorazioneMaster.tema, b.lavorazioneMaster.tema, isAsc)
        case 'ammissibile':
          return compare(a.lavorazioneMaster.ammissibile, b.lavorazioneMaster.ammissibile, isAsc)
        default:
          return 0
      }
    })
  }

  applyFilter(filterValue: string, key?: string) {
    if (this.dataSource) {
      if (key != null) {

        this.dataSource.filterPredicate =
          (data: Lavorazione) => {
            let result = false
            if (SessionData.bozza === true) {
              result = result || ((data['stato'] + '').indexOf('1') !== -1)
            }
            if (SessionData.nessunaLavorazioneInCorso === true) {
              result = result || ((data['stato'] + '').indexOf('2') !== -1)
            }
            if (SessionData.lavorazioniInCorso === true) {
              result = result || ((data['stato'] + '').indexOf('3') !== -1)
            }
            if (SessionData.alcuneLavorazioniChiuse === true) {
              result = result || ((data['stato'] + '').indexOf('4') !== -1)
            }
            if (SessionData.tutteLeLavorazioniChiuse === true) {
              result = result || ((data['stato'] + '').indexOf('5') !== -1)
            }
          }

      } else {
        this.dataSource.filterPredicate =
          (data: Lavorazione, filter: string) => {
            const miadata = moment(new Date(data['dataInserimento']), 'dd/MM/yyyy HH:mm')
            return this.idSegnalazione(data).toString().indexOf(filter) !== -1
              || (data['stato'] === NESSUNA_LAVORAZIONE_IN_CORSO_INDEX.toString() && filter.toLowerCase() === 'nessuna lavorazione in corso')
              || (data['stato'] === BOZZA_INDEX.toString() && filter.toLowerCase() === 'bozza')
              || (data['stato'] === IN_LAVORAZIONE_INDEX.toString() && filter.toLowerCase() === 'in lavorazione')
              || (data['stato'] === CHIUSA_INDEX.toString() && filter.toLowerCase() === 'chiusa')
              || (data['stato'] === INAMMISSIBILE_INDEX.toString() && filter.toLowerCase() === 'non ammissibile')
              || (data['stato'] === NON_COMPETENTE_INDEX.toString() && filter.toLowerCase() === 'non di competenza')
              || (data['stato'] === ISTRUTTTORIA_APERTA_INDEX.toString() && filter.toLowerCase() === 'istruttoria aperta')
              || (data.lavorazioneMaster.tema && data.lavorazioneMaster.tema?.tema.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
              || (data.lavorazioneMaster.sorgente && data.lavorazioneMaster.sorgente?.nome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
          }
      }
      filterValue = filterValue.trim() // Remove whitespace
      filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue
    }
  }

  selected(lavorazione) {
    this.lavorazione = lavorazione
    console.log('selected:', lavorazione)
    SessionData.lavorazioneSelezionata = lavorazione
    // this.segnalazioniService.recuperaSegnalazione(segnalazione.id)
    //  .subscribe((esito) => {
    //    if (esito.esito === ESITO_OK) {
    //      this.sessionData.nuovaSegnalazioneSelezionata(JSON.parse(esito.payload))
          this.router.navigate(['/lavorazione'], {state: {readOnly: true, idSegnalazione: this.lavorazione.dirittiOdv[0].segnalazione.id}})
    //    } else {
    //      this.snackBar.open($localize `Non è stato possibile recuperare la segnalazione ' + JSON.stringify(esito.descrizioneEsito), null, {
    //        duration: 4000,
    //      })
    //    }
    //  }), (() => {
    //    this.snackBar.open($localize `Non è stato possibile recuperare la segnalazione ' + segnalazione.idPerSegnalante, null, {
    //      duration: 4000,
    //    })
    //  })

  }

  back() {
    this.location.back()
  }

  gestore(lavorazione: Lavorazione) {
    const diritti = lavorazione.dirittiOdv.find(dodv => dodv.gestore)
    return diritti ? diritti.odv.nomeOdv : 'Nessun gestore'
  }

  idSegnalazione(lavorazione: Lavorazione) {
    // const diritti = lavorazione.dirittiOdv.find(dodv => dodv.gestore)

    return lavorazione.dirittiOdv[0]?.segnalazione?.id
  }
  convertiStatoLavorazione(indice): string {
    return indice ? statoLavorazioneLeggibile(indice) : 'Indefinito'
  }

  refresh() {
    if (this.lavorazioni) {
      this.dataSource.data = this.lavorazioni
    }
  }

  optionSelected() { }
}
