import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Configurazione } from '../modello/configurazione';
import { ConfigurazioneService } from '../servizi/configurazione.service';
import { SessionData } from '../sessione/dati-sessione';
import { ESITO_OK } from '../utility/esito';
import { inject } from '@angular/core';

export const configurazioneResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Configurazione> | Promise<Configurazione> | Configurazione => {
  const sessionData = inject(SessionData);
  const configurazioneService = inject(ConfigurazioneService);

  if (sessionData.configurazione) {
    return sessionData.configurazione;
  }

  return configurazioneService.recuperaConfigurazione().pipe(
    map((esito) => {
      if (ESITO_OK === esito.esito) {
        sessionData.configurazione = (JSON.parse(esito.payload) as Configurazione[])[0];
        return sessionData.configurazione;
      } else {
        throw new Error(esito.payload);
      }
    })
  );
};
