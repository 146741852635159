import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { Omissis } from '../modello/omissis'
import { Esito } from '../utility/esito'
import { ServiziRest } from '../utility/utility'

@Injectable({
  providedIn: 'root'
})
export class OscuramentiService {

  // subject per eliminare una autorizzazione pendente
  public eliminaAutorizzazionePendente = new Subject<number>()

  constructor(private http: HttpClient,) { }

  avviaProcessoOscuramentoDatiManifestamenteNonNecessari(idDirittoSegnalazione: number): Observable<Esito> {
    return this.http.post<Esito>(ServiziRest.urlOscuramenti +
      '/avviaProcessoOscuramentoDatiManifestamenteNonNecessari/' + idDirittoSegnalazione, JSON.stringify(idDirittoSegnalazione))
  }

  recuperaProcessoOscuramentoInCorso(idSegnalazione: number): Observable<Esito> {
    return this.http.get<Esito>(ServiziRest.urlOscuramenti + '/recuperaProcessoOscuramentoInCorso/' + idSegnalazione)
  }

  aggiungiOmissisOscuramento(omissis: Omissis): Observable<Esito> {
    return this.http.post<Esito>(ServiziRest.urlOscuramenti + '/aggiungiOmissisOscuramento', omissis)
  }

  eliminaOmissisOscuramento(idOmissis: number): Observable<Esito> {
    return this.http.delete<Esito>(ServiziRest.urlOscuramenti + '/eliminaOmissisOscuramento/' + idOmissis)
  }

  autorizzaOscuramento(idAutorizzazioneOscuramento: number): Observable<Esito> {
    return this.http.patch<Esito>(ServiziRest.urlOscuramenti + '/autorizzaOscuramento/' +
    idAutorizzazioneOscuramento, JSON.stringify(idAutorizzazioneOscuramento))
  }

  rifiutaOscuramento(idAutorizzazioneOscuramento: number): Observable<Esito> {
    return this.http.patch<Esito>(ServiziRest.urlOscuramenti + '/rifiutaOscuramento/' +
    idAutorizzazioneOscuramento, JSON.stringify(idAutorizzazioneOscuramento))
  }

  annullaProcessoOscuramento(idOscuramento: number): Observable<Esito> {
    return this.http.patch<Esito>(ServiziRest.urlOscuramenti + '/annullaProcessoOscuramento/' +
    idOscuramento, JSON.stringify(idOscuramento))
  }

  recuperaAutorizzazioniPendenti(): Observable<Esito> {
    return this.http
      .get<Esito>(ServiziRest.urlOscuramenti + '/recuperaAutorizzazioniPendenti')
  }

  condividiProcessoOscuramento(idOscuramento: number): Observable<Esito> {
    return this.http.patch<Esito>(ServiziRest.urlOscuramenti + '/condividiProcessoOscuramento/' +
    idOscuramento, JSON.stringify(idOscuramento))
  }

  recuperaUltimoOscuramentoChiusoPerGestoreUtenteCorrente(idSegnalazione: number): Observable<Esito> {
    return this.http.get<Esito>(ServiziRest.urlOscuramenti +
      '/recuperaUltimoOscuramentoChiusoPerGestoreUtenteCorrente/' + idSegnalazione)
  }

  recuperaUltimoOscuramentoChiuso(idSegnalazione: number): Observable<Esito> {
    return this.http.get<Esito>(ServiziRest.urlOscuramenti +
      '/recuperaUltimoOscuramentoChiuso/' + idSegnalazione)
  }
}
