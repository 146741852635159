import { Azienda } from './azienda'
import { Consenso } from './consenso'
import { Documento } from './documento'

export class Informativa {

    id: number
    nome: string

    testo: string

    azienda?: Azienda
    documento?: Documento
    consensi: Consenso[]

    // base64: string;
}
