import { Component, Inject } from '@angular/core'

import { AngularEditorConfig } from '@kolkov/angular-editor'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-motivazione-dialog',
  templateUrl: 'motivazione.html',
})
export class MotivazioneDialogComponent {

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  }
  testo: string
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any, public dialogRef: MatDialogRef<MotivazioneDialogComponent>) {
    this.testo = data !== undefined && data.testo !== undefined ? data.testo : ''
    this.editorConfig.showToolbar = data.showToolbar
    this.editorConfig.editable = data.editable
  }
  chiudiDialog() {
    this.dialogRef.close({ data: this.testo })
  }
}
