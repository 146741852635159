<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav mode="push">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon color="">menu</mat-icon>
    </button>￼
  </mat-sidenav>

  <mat-sidenav-content>
    <app-toolbar></app-toolbar>
    <div style="position:relative; display:flex; flex-direction: column">
      <div i18n class="titolo-pagina">
        Gestione Gruppi
      </div>

      <div class="mat-elevation-z8" id="listaUtenti">
        <mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows>

          <mat-header-row *matHeaderRowDef="colonneVisualizzate"></mat-header-row>
          <mat-row *matRowDef="let gruppo; columns: colonneVisualizzate" class="pointer" (click)="selected(gruppo)">
          </mat-row>

          <ng-container matColumnDef="id">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
            <mat-cell *matCellDef="let gruppo"> {{gruppo.id}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="nomeOdv">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Nome </mat-header-cell>
            <mat-cell *matCellDef="let gruppo">
              {{ gruppo.nomeOdv }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="predefinito">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> Predefinito </mat-header-cell>
            <mat-cell *matCellDef="let gruppo">
              <mat-checkbox style='padding:5px;' matInput type="checkbox" [(ngModel)]="gruppo.predefinito"
                [disabled]="true">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="azioni">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="width-full">
              <button mat-icon-button class="destra" (click)="aggiungiGruppo(); $event.stopPropagation();"
                *ngIf="puoCreareGruppi" i18n-matTooltip matTooltip="Crea un nuovo Gruppo">
                <mat-icon class="icone-testata-tabelle" aria-label="aggiungi gruppo">add_circle</mat-icon>
              </button>
            </mat-header-cell>
            <mat-cell *matCellDef="let gruppo">
              <button *ngIf="superUtente" mat-icon-button class="destra azioni"
                (click)="openDialogConfermaEliminazioneGruppo(gruppo); $event.stopPropagation();"
                i18n-matTooltip matTooltip="Elimina Gruppo">
                <mat-icon class="icona-riga-tabella" aria-label="cancella gruppo">delete</mat-icon>
              </button>

              <button *ngIf="superUtente || gestore" mat-icon-button class="destra azioni"
                (click)="openDialogModificaGruppo(gruppo); $event.stopPropagation();">
                <mat-icon class="icona-riga-tabella" aria-label="modifica gruppo">create</mat-icon>
              </button>

            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <mat-header-cell i18n *matHeaderCellDef mat-sort-header> todo </mat-header-cell>
            <mat-cell *matCellDef="let utente" style='justify-content: center'>
            </mat-cell>
          </ng-container>
          <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="riga-dettaglio-inner"></mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
        </mat-paginator>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
