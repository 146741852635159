import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { DirittiSegnalazione } from '../modello/diritti-segnalazione'
import { AuthService } from '../servizi/auth.service'
import { RicercaService } from '../servizi/ricerca.service'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { Location } from '@angular/common'

import { ThemeService } from '../servizi/theme.service'
import { Subscription } from 'rxjs/internal/Subscription'
import { Esito, ESITO_OK } from '../utility/esito'
import { MatTableDataSource } from '@angular/material/table'
import { calcolaStatoRichiestaSbloccoSegnalante } from '../utility/utility'
import * as moment from 'moment'
import { AutorizzazioneSbloccoDialogComponent } from './dialog-autorizzazione-component'
import { MotivazioneDialogComponent } from './motivazione.dialog.component'
import { ActivatedRoute, Router } from '@angular/router'
import { SessionData } from '../sessione/dati-sessione'

@Component({
  selector: 'app-richieste-sblocco',
  templateUrl: './richieste-sblocco.component.html',
  styleUrls: ['./richieste-sblocco.component.scss']
})
export class RichiesteSbloccoComponent implements OnInit, AfterViewInit, OnDestroy {

  AUTORIZZA = $localize `Autorizza`
  RESPINGI = $localize `Respingi`
  dataSource
  sortedData
  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  colonneVisualizzate = ['idsegnalazione','idPerSegnalante', 'richiedente', 'datarichiesta',
    'motivorichiesta', 'esitorichiesta', 'descrizioneesito', 'azioni']

  richiesteSblocco: DirittiSegnalazione[]
  richiestaSblocco: DirittiSegnalazione

  private subscriptions: Array<Subscription> = []

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private ricercaService: RicercaService,
    private location: Location,
    private segnalazioneService: SegnalazioneService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionData: SessionData,
  ) { }


  ngOnDestroy(): void {
    this.subscriptions?.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      // console.log(data);
      const configurazione = data['configurazione']
      if (configurazione) {
        this.sessionData.configurazione = configurazione
      } else {
        console.error(data)
        this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
      }
      return
    })
    const isCustode = this.authService.getUser()?.custode
    if (!this.authService.getUser()?.su && !isCustode) {
      this.location.back()
    } else {
      this.subscriptions.push(this.ricercaService.filtraElenco.subscribe((cercami: string) => {
        this.applyFilter(cercami)
      }))
    }
  }

  ngAfterViewInit() {
    this.refreshData()
  }


  reloadTable() {
    // this.sortedData = this.richiesteSblocco.slice();
    this.dataSource = new MatTableDataSource(this.richiesteSblocco)
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
  }

  refreshData() {
    this.segnalazioneService.recuperaRichiesteSblocco().then((esito: Esito) => {
      if (esito.esito === ESITO_OK) {
        this.richiesteSblocco = JSON.parse(esito.payload)
        this.sortedData = this.richiesteSblocco.slice()
        this.dataSource = new MatTableDataSource(this.richiesteSblocco)
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator
      }
    })
  }

  capplyFilter(filterValue: string, key?: string) {
    filterValue = filterValue.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue
  }

  applyFilter(filterValue: string, key?: string) {
    if (this.dataSource) {

      this.dataSource.filterPredicate =
        (data: DirittiSegnalazione, filter: string) => {
          console.log(data)
          const miadata = moment(new Date(data['dataRichiestaSbloccoVisibilitaSegnalante']), 'dd/MM/yyyy')
          return data['segnalazione']['id'].toString().indexOf(filter) !== -1
            || (data['odvDestinatario']['nomeOdv'] && data['odvDestinatario']['nomeOdv'].toLowerCase().indexOf(filter) !== -1)
            || (data['motivazioneRichiestaSbloccoVisibilitaSegnalante'] &&
              data['motivazioneRichiestaSbloccoVisibilitaSegnalante'].toLowerCase().indexOf(filter) !== -1)
            || (data['dataRichiestaSbloccoVisibilitaSegnalante'] && miadata.format('DD/MM/YYYY').indexOf(filter) !== -1)
            || (calcolaStatoRichiestaSbloccoSegnalante(data).toLowerCase().indexOf(filter) !== -1)
            || (data['motivazioneRispostaARichiestaSbloccoVisibilitaSegnalante'] &&
              data['motivazioneRispostaARichiestaSbloccoVisibilitaSegnalante'].toLowerCase().indexOf(filter) !== -1)
        }
      filterValue = filterValue.trim() // Remove whitespace
      filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue
    }
  }

  selected(richiesta) {
    this.richiestaSblocco = richiesta
    // console.log('selected:' + azienda.nomeAzienda);
  }

  salvaRichiesta(richiesta) {
    if (richiesta) {
      this.richiestaSblocco = richiesta
    }
    if (this.richiestaSblocco.id == null) {
      this.snackBar.open($localize `Impossibile continuare, la richiesta è priva di identificativo`, null, {
        duration: 2000
      })
    } else {
      this.segnalazioneService
        .aggiornaRichiestaSbloccoSegnalante(this.richiestaSblocco)
        .then((esito: Esito) => {
          // console.log(azienda);
          if (esito.esito === ESITO_OK) {
            this.snackBar.open($localize `Richiesta di sblocco aggiornata`, null, {
              duration: 2000
            })
          } else {
            this.segnalazioneService.getDirittiSegnalazioneById(richiesta.id)
              .then((esito2: Esito) => {
                if (esito2.esito === ESITO_OK) {
                  const tmpSegna = this.richiestaSblocco.segnalazione
                  this.richiestaSblocco = JSON.parse(esito2.payload)
                  this.richiestaSblocco.segnalazione = tmpSegna
                  const indice = this.richiesteSblocco.findIndex(ric => ric.id === this.richiestaSblocco.id)
                  this.richiesteSblocco.splice(indice, 1, this.richiestaSblocco)
                  this.reloadTable()
                }
              })
            this.snackBar.open($localize `Aggiornamento Richiesta di sblocco fallito ` +
              esito.payload +
              ' ' +
              esito.descrizioneEsito,
              null,
              {
                duration: 4000
              }
            )
          }
        })
        .catch(errore => {
          // console.log(errore);
          this.snackBar.open($localize `Aggiornamento Richiesta di sblocco fallito ` + errore, null, {
            duration: 4000
          })
        })
    }
  }


  esito(richiesta: DirittiSegnalazione) {
    return calcolaStatoRichiestaSbloccoSegnalante(richiesta)
  }


  openDialogAutorizzazione(richiesta: DirittiSegnalazione, titolo: string, azione: string): void {
    const motivazione = richiesta.motivazioneRispostaARichiestaSbloccoVisibilitaSegnalante
    const dialogRef = this.dialog.open(AutorizzazioneSbloccoDialogComponent, {
      width: '800px',
      height: '700px',
      data: { titolo, azione, motivazione },
      disableClose: true,
      hasBackdrop: true
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(titolo, richiesta.id, result)
      if (result !== undefined) {
        richiesta.motivazioneRispostaARichiestaSbloccoVisibilitaSegnalante = result.data.value
        if (this.AUTORIZZA === azione) {
          richiesta.richiestaSbloccoVisibilitaSegnalanteAccolta = true
          richiesta.richiestaSbloccoVisibilitaSegnalanteRespinta = false
        } else {
          richiesta.richiestaSbloccoVisibilitaSegnalanteAccolta = false
          richiesta.richiestaSbloccoVisibilitaSegnalanteRespinta = true
        }
        this.salvaRichiesta(richiesta)
      }

    })
  }

  openDialogMotivazione(richiesta: DirittiSegnalazione): void {
    const dialogRef = this.dialog.open(MotivazioneDialogComponent, {
      width: '800px',
      height: '600px',
      data: {
        testo: richiesta.motivazioneRichiestaSbloccoVisibilitaSegnalante,
        editable: false,
        showToolbar: false
      },
      disableClose: false,
      hasBackdrop: true
    })
    dialogRef.afterClosed().subscribe(result => {
    })
  }

  get autorizzaRichiesta() {
    return $localize `Autorizza richiesta`
  }

  get respingiRichiesta() {
    return $localize `Respingi richiesta`
  }

}


