import { ElementRef } from '@angular/core'
import { Component, Input, ViewChild } from '@angular/core'
import { VideoService } from '../../servizi/video.service'

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css'],
  providers: [VideoService],
})
export class VideoPlayerComponent {

  @Input() public videoKey!: string
  @Input() public tipo!: string
  @Input() public mediaFile: Blob

  mediaUrl: string
  mediaBase64: string

  options = {
    autoplay: true,
    controls: [
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'fullscreen',
    ],
    keyboard: {
      global: true,
    },
    tooltips: {
      controls: true,
    },
  }

  constructor(private readonly videoService: VideoService) {}

  @ViewChild('videoPlayer') videoElement: ElementRef
  @ViewChild('audioPlayer') audioElement: ElementRef

  public ngAfterViewInit(): void {
    if (this.videoKey) {
      this.videoService.getVideoFile(this.videoKey).subscribe(
        (mediaUrl: string) => {
          this.mediaUrl = mediaUrl
          if (this.isVideo && this.videoElement) {
            this.videoElement.nativeElement.src = this.mediaUrl
          } else if(this.audioElement) {
            this.audioElement.nativeElement.src = this.mediaUrl
          }
        },
        (error) => {
          this.handleError(error)
          console.error('Error fetching media')
        }
      )
    } else if (this.mediaFile) {
      this.mediaUrl = URL.createObjectURL(this.mediaFile)

      if (this.isVideo && this.videoElement) {
        this.videoElement.nativeElement.src = this.mediaUrl
      } else if (this.audioElement) {
        this.audioElement.nativeElement.src = this.mediaUrl
      }
    }
  }

  played(event: any) {
    console.log('played', event)
  }

  get isVideo(): boolean {
    return this.tipo.startsWith('video')
  }

  get isAudio(): boolean {
    return this.tipo.startsWith('audio')
  }


  loadError = false

  handleError(event: any) {
    this.loadError = true
  }
}
