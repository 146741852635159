import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AudioFile } from '../modello/audio-file'
import { ServiziRest } from '../utility/utility'
import { Esito, ESITO_OK } from '../utility/esito'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  constructor(private http: HttpClient) { }

  saveAudioFile2(audio: AudioFile, segnalazioneId: string): Observable<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    const url = `${ServiziRest.urlAudio}/${segnalazioneId}/create`
    audio.content = new Uint8Array(audio.content)
    return this.http.post<Esito>(url, audio, options)
  }

  getAudioById(idAudio: string): Observable<Esito> {
    return this.http.get<Esito>(ServiziRest.urlAudio + '/' + idAudio).pipe(
      map((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          // trasformo il contenuto del file audio in un array di byte
          esito.payload['content'] = new Uint8Array(esito.payload['content'])
        }
        return esito
      })
    )
  }

  deleteAudio(idSegnalazione: string): Observable<Esito> {
    return this.http.delete<Esito>(ServiziRest.urlAudio + '/' + idSegnalazione)
  }

  inibisciAscoltoAudio(idSegnalazione: string): Observable<Esito> {
    return this.http.post<Esito>(
      ServiziRest.urlInibisciAudio,
      JSON.stringify(idSegnalazione)
    )
  }

  disinibisciAscoltoAudio(idSegnalazione: string): Observable<Esito> {
    return this.http.post<Esito>(
      ServiziRest.urlDisinibisciAudio,
      JSON.stringify(idSegnalazione)
    )
  }

  getAudioBySegnalazione(idSegnalazione: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      Accept: 'audio/mpeg',
      'Response-Type': 'blob' as const,
    })
    const options = { headers: headers }
    // mi assicuro che il contenuto venga inviato come octet-stream

    return this.http
      .get(ServiziRest.urlAudioBySegnalazione + '/' + idSegnalazione, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(map((blob: any) => blob))
  }

  uploadAudio(audioBlob: Blob, segnalazione?: any): Observable<Esito> {
    const formData = new FormData()
    formData.append('audio', audioBlob, 'audio.mp3')
    if (segnalazione) {
      formData.append('segnalazione', JSON.stringify(segnalazione))
    }

    return this.http.post<Esito>(ServiziRest.urlAudioUpload, formData)
  }

  getAudio(hashAudio: string): Observable<Blob> {
    const url = `${ServiziRest.urlAudioDownload}/${hashAudio}`
    return this.http.get(url, { responseType: 'blob' })
  }
}
