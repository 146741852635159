<div id="contenitore" class="alternate-theme">
  <mat-tab-group #tabs style="margin-top:12px;z-index: 100;" (selectedTabChange)="onTabSelected($event)">

    <mat-tab class="label-tab" *ngFor="let lavorazione of lavorazioni; let i = index"
      label="{{ calcolaNomeGestore(lavorazione) }}" style="overflow-y:hidden; overflow-x:hidden;">

      <button *ngIf="lavorazione && lavorazione.id && isUtenteCorrenteGestoreOCogestoreLavorazione(lavorazione) && odv"
        mat-fab (click)="promuoviStatoDialog(lavorazione); $event.stopPropagation();" i18n-matTooltip
        matTooltip="{{prossimoStato(lavorazione)}}" class="position-fixed colonna-4">
        <mat-icon aria-label="cambia stato" class="icona-fab">{{iconaTransizione(lavorazione)}}</mat-icon>
      </button>

      <button
        *ngIf="lavorazione && lavorazione.id && odv && possoPrendereInCaricoLavorazione(lavorazione) &&
        !lavorazioneChiusa(lavorazione) && !isUtenteCorrenteGestoreOCogestoreLavorazione(lavorazione)"
        class="position-fixed colonna-5" mat-fab (click)="prendiInCaricoDialog(); $event.stopPropagation();"
        i18n-matTooltip matTooltip="Prendi in carico">
        <mat-icon aria-label="prendi in carico" class="icona-fab">flash_on</mat-icon>
      </button>



      <button
        *ngIf="false && lavorazione && lavorazione.id && odv && (competenza(lavorazione) && !segnalazione.presaInCarico && isUtenteCorrenteGestoreOCogestoreLavorazione(lavorazione)) && !lavorazioneChiusa(lavorazione)"
        class="position-fixed colonna-5" mat-fab (click)="nonDiCompetenzaDialog(lavorazione); $event.stopPropagation();"
        i18n-matTooltip matTooltip="Non di competenza del gruppo">
        <mat-icon aria-label="non competente" class="icona-fab">work_off</mat-icon>
      </button>

      <button style="z-index: 100;"
        *ngIf="lavorazione && (odv && isUtenteCorrenteGestoreOCogestoreLavorazione(lavorazione)) && !lavorazioneChiusa(lavorazione)"
        mat-fab class="position-fixed colonna-2"
        (click)="salvaLavorazioneSegnalazione(lavorazione); $event.stopPropagation();" i18n-matTooltip
        matTooltip="Salva">
        <mat-icon aria-label="salva segnalazione" class="icona-fab">save</mat-icon>
      </button>

      <button mat-fab class="position-fixed colonna-3" (click)="openChat(lavorazione); $event.stopPropagation();"
        i18n-matTooltip matTooltip="Approfondimenti">
        <mat-icon aria-label="chat segnalazione" class="icona-fab">chat</mat-icon>
      </button>


      <mat-menu #menu="matMenu">
        <div *ngIf="ciSonoPossibiliDestinatari(lavorazione)">
          <ng-container *ngFor="let azienda of destinatariSegnalazioneAziende">

            <button i18n *ngFor="let gruppo of gruppiAiQualiPossoInoltrare(azienda, lavorazione)"
              (click)="openDialogCondividiLavorazione($event, gruppo, lavorazione)" mat-menu-item>
              {{ gruppo.odv.nomeOdv }}</button>

          </ng-container>
        </div>
        <div *ngIf="!ciSonoPossibiliDestinatari(lavorazione)" i18n>
          Nessun gruppo selezionabile, condividi prima la segnalazione.
        </div>

      </mat-menu>
      <form #fgestore="ngForm" [formGroup]="formNoteSegnalazione">
        <div *ngIf="!puoAprireLavorazioni() && lavorazione === undefined" class="testo-grande top-50 flex-column"  style="color:black; width:100%">
          <div i18n>
            Non sei autorizzato all'apertura di una Lavorazione
          </div>

        </div>

        <mat-expansion-panel [style.margin-top]="'64px'" [disabled]="true" *ngIf="odv && lavorazione!==undefined"
          [expanded]="true" id="lavorazione-segnalazione1" name="LavorazioneSegnalazione1">
          <mat-expansion-panel-header class="sottotestata-principale-dettagli-segnalazione">
            <mat-panel-title i18n>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="margin-top-20px">
            <div class="flex-row flex-gap-20">
              <mat-form-field class="full top-10px">
                <input matInput i18n-placeholder placeholder="Stato" disabled id="stato-lavorazione"
                  class="disabled-light" value="{{_convertiStatoLavorazione(lavorazione.stato)}}">
                <mat-label i18n class="label-segnalazione testo-label-scalabile">Stato</mat-label>
              </mat-form-field>
              <mat-form-field class="top-13px" color="accent">
                <mat-select i18n-placeholder placeholder="Stato visibile al segnalante?"
                  formControlName="visibileAlSegnalante">
                  <mat-option default value="true" i18n>Sì</mat-option>
                  <mat-option value="false" i18n>No</mat-option>
                </mat-select>
                <mat-label i18n class="label-segnalazione testo-label-scalabile">Stato visibile al segnalante?</mat-label>
              </mat-form-field>
            </div>
            <div class="margin-top-20px" class="flex-row">
              <mat-form-field class="width-full flex-row" color="accent">
                <div class="flex-row">
                  <mat-chip-list #chipListDestinatariInoltro>
                    <mat-chip *ngFor="let dirittiLavorazione of lavorazione.dirittiOdv" [removable]="true"
                      (removed)="openDialogConfermaScondivisioneLavorazione($event, dirittiLavorazione, lavorazione)">
                      {{dirittiLavorazione.odv?.nomeOdv}}
                      <mat-icon matChipRemove *ngIf="puoRimuovereCondivisione(dirittiLavorazione)">
                        cancel</mat-icon>

                      <mat-icon *ngIf="possoGestireAutorizzazioneModificaLavorazione(dirittiLavorazione)"
                        [class.lavorazione-modificabile]="statoLavorazioneModificabile(dirittiLavorazione, lavorazione)"
                        [class.lavorazione-non-modificabile]="!statoLavorazioneModificabile(dirittiLavorazione, lavorazione)"
                        i18n-matTooltip
                        matTooltip=" {{ statoLavorazioneModificabile(dirittiLavorazione, lavorazione) ? 'Disabilita la Condivisione in modifica della Lavorazione' : 'Abilita la Condivisione in modifica della Lavorazione' }}"
                        (click)="toggleLavorazioneModificabile($event, dirittiLavorazione, lavorazione)">edit</mat-icon>
                    </mat-chip>
                    <mat-menu #menuShare="matMenu">
                      <mat-checkbox style="padding-left: 10px;padding-right: 10px;">Condivisione</mat-checkbox>
                    </mat-menu>
                    <input disabled i18n-placeholder
                      [matChipInputFor]="chipListDestinatariInoltro" matInput>
                  </mat-chip-list>

                </div>
                <mat-label for="" i18n class="label-segnalazione testo-label-scalabile">Condivisione lavorazione</mat-label>
              </mat-form-field>
              <div>
                <button
                  *ngIf="lavorazione && lavorazione.id && odv && possoInoltrareLavorazione(lavorazione) && ciSonoPossibiliDestinatari(lavorazione)"
                  mat-fab [matMenuTriggerFor]="menu" i18n-matTooltip matTooltip="Inoltra">
                  <mat-icon aria-label="inoltra segnalazione" class="icona-fab">forward</mat-icon>
                </button>
              </div>
            </div>

            <mat-form-field class="width-full" color="accent">
              <mat-label class="label-segnalazione testo-label-scalabile" i18n>Sorgente Segnalazione</mat-label>
              <mat-select formControlName="sorgente" (selectionChange)="selectedSorgente($event.value, lavorazione)"
                [compareWith]="compareSorgente">
                <mat-option *ngFor="let item of sorgentiSegnalazione" [value]="item">
                  {{item.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="width-full" color="accent">
              <mat-select #select i18n-placeholder placeholder="Ammissibile?" formControlName="ammissibile">
                <mat-option default value="true">Sì</mat-option>
                <mat-option value="false">No</mat-option>
              </mat-select>
              <mat-label for="" i18n class="label-segnalazione testo-label-scalabile">Ammissibile?</mat-label>
            </mat-form-field>
            <mat-form-field class="width-full" color="accent"
              *ngIf=" formNoteSegnalazione && formNoteSegnalazione.controls && formNoteSegnalazione.controls.ammissibile.value=='false' ">
              <textarea maxlength="20000" matInput formControlName="motivoNonAmmissibilita" i18n-placeholder
                placeholder="Motivi non ammissibilità"></textarea>
            </mat-form-field>


            <mat-form-field class="width-full" color="accent">
              <mat-label class="label-segnalazione testo-label-scalabile" i18n>Tema Segnalazione</mat-label>
              <mat-select formControlName="tema" (selectionChange)="selectedTema($event.value, lavorazione)"
                [compareWith]="compareTema">
                <mat-option *ngFor="let item of temiSegnalazione" [value]="item">
                  {{item.tema}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="form-group width-full" color="accent">
              <mat-label class="label-segnalazione testo-label-scalabile" i18n>Azioni di follow-up</mat-label>
              <mat-select class="select-follow-up" formControlName="azioniFollowUpForm" multiple
                (selectionChange)="selected($event.value, lavorazione)" [compareWith]="compareAzione">
                <mat-option *ngFor="let item of azioniFollowUpFiltrate" [value]="item">
                  {{item.azione}}
                </mat-option>
              </mat-select>

              <mat-chip-list aria-label="Azioni di follow-up selezionate">
                <mat-chip *ngFor="let azione of lavorazione.azioniFollowUp" [selectable]="true"
                  [removable]="gestibile(lavorazione)" (removed)="remove(azione, lavorazione)">
                  {{azione.azione}}
                  <mat-icon matChipRemove
                    *ngIf="gestibile(lavorazione) && isUtenteCorrenteGestoreOCogestoreLavorazione(lavorazione)">cancel
                  </mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-form-field>



            <div class="width-full">
              <angular-editor *ngIf="gestore() && formNoteSegnalazione && formNoteSegnalazione.enabled"
                formControlName="noteFollowUp" [config]="editorConfigTb"></angular-editor>
              <angular-editor *ngIf="!gestore() || (formNoteSegnalazione && formNoteSegnalazione.disabled)"
                formControlName="noteFollowUp" [config]="editorConfigNoTb">
              </angular-editor>
            </div>
            <app-elenco-allegati #allegatiGestore *ngIf="true"
              [disabilitato]="!gestibile(lavorazione) || !lavorazione.id" [documenti]="lavorazione.documenti"
              [entitaPrincipale]="lavorazione" [tipoEntitaPrincipale]="'lavorazione'"
              [canaleDocAggiunto]="'documento-aggiunto-lavorazione-' + lavorazione.id"
              [canaleDocAggiornato]="'documento-aggiornato-lavorazione-' + lavorazione.id"
              [canaleDocEliminato]="'documento-eliminato-lavorazione-' + lavorazione.id"
              (salvaDocumento)="salvaDocumentoLavorazione($event, lavorazione)"
              (aggiornaDocumento)="aggiornaDocumentoLavorazione(lavorazione)"
              (aggiungiDocumento)="aggiungiDocumentoLavorazione($event, lavorazione)"
              (unlinkDocumento)="scollegaDocumentoLavorazione($event, lavorazione)"></app-elenco-allegati>
          </div>
        </mat-expansion-panel>
      </form>
    </mat-tab>

    <mat-tab *ngIf="dirittiSegnalazioniGestibili() && dirittiSegnalazioniGestibili().length>0 && puoAprireLavorazioni()"
      i18n-label label="Avvio Lavorazione">
      <div class="testo-grande top-50">
        <div style="margin: 40px;" i18n>
          Devi prendere in carico la segnalazione per aprire una Lavorazione
        </div>

        <div *ngFor="let dirittoSegnalazione of dirittiSegnalazioniGestibili()"
          style="margin-left:60px; margin-bottom: 20px;">
          <button mat-fab (click)="prendiInCaricoDialog(dirittoSegnalazione); $event.stopPropagation();" i18n-matTooltip
            matTooltip="Prendi in carico la Lavorazione come {{dirittoSegnalazione.odvDestinatario.nomeOdv}}">
            <mat-icon aria-label="prendi in carico" class="icona-fab">flash_on</mat-icon>
          </button>
          <p i18n> Prendi in carico come membro del gruppo {{dirittoSegnalazione.odvDestinatario.nomeOdv}}
          </p>
        </div>
      </div>
    </mat-tab>

    <mat-tab *ngIf="lavorazioni === undefined || lavorazioni.length === 0 " i18n-label label="Attenzione">
      <div class="testo-grande top-50">
        <div style="margin: 40px;" i18n>
          Non hai lavorazioni in gestione o condivise da parte di altri gruppi
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>
