<h2 i18n mat-dialog-title class="titolo-dialog">Visibilità Segnalazione Inoltrata</h2>
<mat-dialog-content>
  <div i18n *ngIf="!data.statoVibilitaSegnalazione">Confermi di voler modificare la visibilità della segnalazione ai membri
    del gruppo
    {{data.nomeGruppoOdv}}?</div>
  <div i18n *ngIf="data.statoVibilitaSegnalazione">Confermi di voler modificare la visibilità della segnalazione ai membri del
    gruppo {{data.nomeGruppoOdv}}?</div>
</mat-dialog-content>
<mat-dialog-actions>
  <span class="spacer"></span>
  <button i18n mat-button mat-dialog-close>No</button>
  <span class="spacer"></span>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button i18n mat-button [mat-dialog-close]="true">Sì</button>
  <span class="spacer"></span>
</mat-dialog-actions>
