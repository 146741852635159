import { Component, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-new-client-dialog',
  templateUrl: './new-client-dialog.component.html',
  styleUrls: ['./new-client-dialog.component.scss']
})
export class NewClientDialogComponent {

  form: FormGroup
  existingClients: string[] = [] // Popola questa lista con i nomi dei clienti esistenti

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NewClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.form = this.fb.group({
      newClientName: [data.newClientName ? data.newClientName : '', Validators.required],
      existingClientName: data.existingClientName ? [data.existingClientName, Validators.required] : [''],
      ipaddress: [data.ipaddress ? data.ipaddress : '52.31.137.73', Validators.required]
    })

    // Supponendo che 'data.existingClients' sia un array di nomi di clienti esistenti
    this.existingClients = data.existingClients
  }

  save() {
    this.dialogRef.close(this.form.value)
  }

  close() {
    this.dialogRef.close()
  }
}
