import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'

import { Persona } from '../../modello/persona'
import { PinCode } from '../../modello/pin-code'
import { User } from '../../modello/user'
import { AuthService } from '../../servizi/auth.service'
import { UserBehaviorTrackingService } from '../../servizi/user-behavior-tracking.service'
import { SessionData } from '../../sessione/dati-sessione'
import { ESITO_OK } from '../../utility/esito'
import { getVersione } from '../../utility/utility'
import { CustomValidators } from '../../validators/CustomValidators'



@Component({
  selector: 'app-recupero-password',
  templateUrl: './recupero-password.component.html',
  styleUrls: ['./recupero-password.component.css']
})
export class RecuperoPasswordComponent implements OnInit {
  pinCode: PinCode
  // tokenRichiestaPin: string;
  utenteConNuovoPin: User

  utente: User
  recuperoPasswordForm: UntypedFormGroup

  // pincode: string;
  primoAccesso: boolean

  passwordDimenticata = false
  textOrPassword = 'password'

  persona: Persona

  registrazioneConEmail = false
  tokenVerificaEmail: string

  tokenRichiestaPin: string

  @ViewChild('inputIdUtente') userId: ElementRef

  constructor(public dialog: MatDialog, public authService: AuthService, private router: Router,
    private snackBar: MatSnackBar,
    private userBehaviorTracking: UserBehaviorTrackingService,
    // private recaptcha3: NgRecaptcha3Service,
    private sessionData: SessionData,
  ) {
    this.utente = new User()
    this.pinCode = new PinCode()

    this.utente.pinCode = this.pinCode

    this.createForm()

  }

  getLogoCliente() {
    return (this.sessionData.logoCliente !== undefined && this.sessionData.logoCliente.base64 !== undefined ?
      this.sessionData.logoCliente.base64 : this.getLogoSecure())
  }

  getLogoSecure() {
    return (this.sessionData.logoSecure !== undefined && this.sessionData.logoSecure.base64 !== undefined ?
      this.sessionData.logoSecure.base64 : '/assets/img/logo-secure.png')
  }

  get idUtente() {
    return this.recuperoPasswordForm.get('userId')
  }

  get password() {
    return this.recuperoPasswordForm.get('password')
  }

  get configurazione() {
    return this.sessionData.configurazione
  }

  get versione(): string {
    return getVersione()
  }

  get modalitaPinUnico(): boolean {
    return this.sessionData.configurazione.modalitaPinUnicoPerSegnalazione
  }

  get dominiPermessi() {
    return this.sessionData.configurazione.dominiAziendali
  }

  ngOnInit() {
    this.userBehaviorTracking.startSession()
    this.authService.removeStoredCredentials()
  }


  ngOnDestroy(): void {
    // this.recaptcha3.destroy()
  }

  recuperaPin() {
    console.log('recupera pin button clicked!', this.recuperoPasswordForm.controls.email.value)
    //  richiedo l'invio del link per il recupero pin al mio indirizzo di posta
    // this.recaptcha3.getToken({ action: 'recupero_pin' }).then(token => {
    this.userBehaviorTracking.sendBehaviorData();
    this.authService.resetPin(this.recuperoPasswordForm.controls.email.value, this.recuperoPasswordForm.controls.username.value, this.token)
      .then(response => {
        if (+response.esito === ESITO_OK) {
          this.snackBar.open($localize `Richiesta di recupero accolta. Controlla la tua posta e segui le istruzioni. `, null, {
            duration: 8000,
          })
        } else {
          this.snackBar.open(response['payload'], null, {
            duration: 8000,
          })
        }
      })
      .catch(error => {
        this.snackBar.open($localize `Reset PIN fallito ` + error, null, {
          duration: 8000,
        })
      })
    // }).catch((error) => {
    //   console.log(error)
    //   this.snackBar.open($localize `Impossibile eseguire la login. Rivolgersi al supporto. ', null, {
    //     duration: 8000,
    //   })
    // })
  }
  token: string;

  copiaPin() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.utenteConNuovoPin.pinCode.pincode))
      e.preventDefault()
      document.removeEventListener('copy', null)
    })
    document.execCommand('copy')
    this.recuperoPasswordForm.controls.password.setValue(null)
    if (this.recuperoPasswordForm.controls.userId.value !== this.utenteConNuovoPin?.userId) {
      this.recuperoPasswordForm.controls.userId.setValue(null)
    }
  }

  backToLogin() {
    // console.log('Torno alla main login....');
    this.router.navigate(['/login'], {})
  }

  toggleTextOrPassword() {
    this.textOrPassword === 'password' ? this.textOrPassword = 'text' : this.textOrPassword = 'password'
    // console.log(this.textOrPassword);
  }

  keyDownFunction(event) {
    if (event.keyCode === 13 && !this.recuperoPasswordForm.invalid) {
      this.recuperaPin()
      // rest of your code
    }
  }


  private createForm() {
    this.recuperoPasswordForm = new UntypedFormGroup({
      'username': new UntypedFormControl('', this.modalitaPinUnico ? [] : [Validators.required]),
      'email': new UntypedFormControl('', this.modalitaPinUnico ? [] : [Validators.required,
      CustomValidators.validateEmailPersonale(this.sessionData.configurazione?.dominiAziendali),
      CustomValidators.validaEmail]),
      'ripetiEmail': new UntypedFormControl('', this.modalitaPinUnico ? [] : [CustomValidators.validateEmailPersonale(this.sessionData.configurazione?.dominiAziendali)]),

    }, CustomValidators.matchingEmail('email', 'ripetiEmail'))

  }

  onClickRecupera(event) {
      this.recuperaPin()
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    this.userBehaviorTracking.trackMouseMove(e);
  }

  @HostListener('document:keypress', ['$event'])
  onKeyPress(e: KeyboardEvent) {
    this.userBehaviorTracking.trackKeyStroke(e);
  }

  @HostListener('document:click', ['$event'])
  onClick(e: MouseEvent) {
    this.userBehaviorTracking.trackClick(e);
  }

  @HostListener('document:touchend', ['$event'])
  onTouch(e: TouchEvent) {
    this.userBehaviorTracking.trackClick(e);
  }

}
