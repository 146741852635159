import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ColorCombination } from '../../modello/color-combination'
import { VersioneContenuto } from '../../modello/versione-contenuto'
import { ColorCombinationService } from '../../servizi/color-combination.service'
import { ContenutiService } from '../../servizi/contenuti.service'
import { SessionData } from '../../sessione/dati-sessione'
import { ESITO_OK } from '../../utility/esito'
import {
  getOptimalTextColor,
  hexToFilterColor,
  increaseContrastToThreshold,
  SottoTipoContenuto,
  TipoContenuto,
} from '../../utility/utility'

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit, AfterViewInit {
  logoCliente: any
  logoSecure: any
  // Proprietà per memorizzare l'URL dell'anteprima dell'immagine
  clienteLogoPreviewUrl: string
  secureLogoPreviewUrl: string
  constructor(
    private dialogRef: MatDialogRef<ColorPickerComponent>,
    private colorService: ColorCombinationService,
    private snackBar: MatSnackBar,
    private contenutiService: ContenutiService,
    private sessionData: SessionData
  ) {}
  ngOnInit(): void {
    this.colorService.getDefaultColorCombination().subscribe((esito) => {
      if (esito.esito === ESITO_OK) {
        this.colorCombo =
          esito.payload === undefined
            ? new ColorCombination()
            : (JSON.parse(esito.payload) as ColorCombination)
        if (
          this.colorCombo.altezzaLogoCliente === undefined ||
          this.colorCombo.altezzaLogoCliente === null ||
          this.colorCombo.altezzaLogoCliente === 0
        ) {
          this.colorCombo.altezzaLogoCliente = 80
        }

        if (
          this.colorCombo.altezzaLogoSecure === undefined ||
          this.colorCombo.altezzaLogoSecure === null ||
          this.colorCombo.altezzaLogoSecure === 0
        ) {
          this.colorCombo.altezzaLogoSecure = 80
        }
      } else {
        this.snackBar.open(
          $localize`Errore nel recupero della combinazione di colori di default`,
          $localize`Chiudi`,
          {
            duration: 4000,
          }
        )
      }
    })
    // recupera il logo come versionecontenuto
    this.logoCliente = this.sessionData.logoCliente
    if (!this.logoCliente) {
      this.contenutiService
        .getVersioneContenuto(
          TipoContenuto.DOCUMENTO,
          SottoTipoContenuto.LOGO_CLIENTE,
          'all'
        )
        .subscribe((esito) => {
          // verifica se l'esito è ok, ricava la versione contenuto e se ha un hash lo usa per recuperare il file
          if (esito.esito === ESITO_OK) {
            this.logoCliente = JSON.parse(esito.payload)
            this.caricaLogo('cliente')
          }
        })
    } else {
      this.caricaLogo('cliente')
    }

    this.logoSecure = this.sessionData.logoSecure
    if (!this.logoSecure) {
      this.contenutiService
        .getVersioneContenuto(
          TipoContenuto.DOCUMENTO,
          SottoTipoContenuto.LOGO_SECURE,
          'all'
        )
        .subscribe((esito) => {
          // verifica se l'esito è ok, ricava la versione contenuto e se ha un hash lo usa per recuperare il file
          if (esito.esito === ESITO_OK) {
            this.logoSecure = JSON.parse(esito.payload)
            this.caricaLogo('secure')
          }
        })
    } else {
      this.caricaLogo('secure')
    }
  }

  get lingua() {
    // ricava il codice della lingua corrente dalla url
    return window.location.href.split('/')[3]
  }

  public colorCombo: ColorCombination

  private initialWidth: number
  private initialHeight: number
  private initialX: number
  private initialY: number
  private resizing = false
  @ViewChild('textColorInput') textColorInput: ElementRef
  @ViewChild('textChiaroColorInput') textChiaroColorInput: ElementRef
  @ViewChild('backgroundChiaroColorInput')
  backgroundChiaroColorInput: ElementRef
  @ViewChild('backgroundToolbarColorInput')
  backgroundToolbarColorInput: ElementRef
  @ViewChild('textToolbarColorInput') textToolbarColorInput: ElementRef
  @ViewChild('backgroundColorInput') backgroundColorInput: ElementRef
  @ViewChild('primaryColorInput') primaryColorInput: ElementRef
  @ViewChild('backgroundTableHeader') backgroundTableHeader: ElementRef
  @ViewChild('sfondoNuvolettaDestra') sfondoNuvolettaDestra: ElementRef
  @ViewChild('sfondoNuvolettaSinistra') sfondoNuvolettaSinistra: ElementRef
  @ViewChild('textButtonColorInput') textButtonColorInput: ElementRef
  @ViewChild('selettoreLinguaColorInput') selettoreLinguaColorInput: ElementRef

  private caricaLogo(logoType: 'cliente' | 'secure') {
    let logoData

    if (logoType === 'cliente') {
      logoData = this.logoCliente
    } else if (logoType === 'secure') {
      logoData = this.logoSecure // Assumo che logoSecure sia simile a logoCliente
    }

    if (
      logoData &&
      logoData['url'] &&
      logoData['url'] !== 'null' &&
      !(logoData['file'] instanceof File)
    ) {
      this.contenutiService
        .downloadFile(logoData['url'])
        .subscribe((fileBlob) => {
          // Create a new File instance
          const file = new File([fileBlob], logoData['url'], {
            type: fileBlob.type,
          })

          logoData['file'] = file
          logoData.tipo = file.type

          // Assumendo che tu abbia una proprietà simile a SessionData.logoSecure
          if (logoType === 'cliente') {
            this.sessionData.logoCliente = Object.assign(
              new VersioneContenuto(
                logoData.documento,
                logoData.nome,
                logoData.tipo
              ),
              logoData
            )
          } else if (logoType === 'secure') {
            this.sessionData.logoSecure = Object.assign(
              new VersioneContenuto(
                logoData.documento,
                logoData.nome,
                logoData.tipo
              ),
              logoData
            )
          }

          // Converting Blob to Base64
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onloadend = () => {
            const base64data = reader.result
            if (logoType === 'cliente') {
              this.clienteLogoPreviewUrl = base64data as string
            } else if (logoType === 'secure') {
              this.secureLogoPreviewUrl = base64data as string // Assumo che tu abbia una variabile simile per il logo sicuro
            }
          }
        })
    } else if (logoData && logoData['file'] instanceof File) {
      // Converting Blob to Base64
      const reader = new FileReader()
      reader.readAsDataURL(logoData['file'])
      reader.onloadend = () => {
        const base64data = reader.result
        if (logoType === 'cliente') {
          this.clienteLogoPreviewUrl = base64data as string
        } else if (logoType === 'secure') {
          this.secureLogoPreviewUrl = base64data as string
        }
      }
    } else {
      if (logoType === 'cliente') {
        this.clienteLogoPreviewUrl = ''
      } else if (logoType === 'secure') {
        this.secureLogoPreviewUrl = ''
      }
    }
  }

  ngAfterViewInit() {
    const styles = getComputedStyle(document.documentElement)

    this.textColorInput.nativeElement.value = styles
      .getPropertyValue('--colore-testo')
      .trim()
    this.textChiaroColorInput.nativeElement.value = styles
      .getPropertyValue('--colore-testo-chiaro')
      .trim()
    this.backgroundChiaroColorInput.nativeElement.value = styles
      .getPropertyValue('--colore-background-chiaro')
      .trim()
    this.backgroundToolbarColorInput.nativeElement.value = styles
      .getPropertyValue('--colore-background-toolbar')
      .trim()
    this.textToolbarColorInput.nativeElement.value = styles
      .getPropertyValue('--colore-testo-toolbar')
      .trim()
    this.backgroundColorInput.nativeElement.value = styles
      .getPropertyValue('--colore-background')
      .trim()
    this.primaryColorInput.nativeElement.value = styles
      .getPropertyValue('--colore-riferimento')
      .trim()
    this.backgroundTableHeader.nativeElement.value = styles
      .getPropertyValue('--colore-sfondo-testata-tabelle')
      .trim()
    this.sfondoNuvolettaDestra.nativeElement.value = styles
      .getPropertyValue('--colore-sfondo-nuvoletta-destra')
      .trim()
    this.sfondoNuvolettaSinistra.nativeElement.value = styles
      .getPropertyValue('--colore-sfondo-nuvoletta-sinistra')
      .trim()
    this.textButtonColorInput.nativeElement.value = styles
      .getPropertyValue('--colore-testo-bottoni')
      .trim()
      this.selettoreLinguaColorInput.nativeElement.value = styles
      .getPropertyValue('--colore-bordo-selettore-lingua')
      .trim()
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.resizing) {
      const deltaX = event.clientX - this.initialX
      const deltaY = event.clientY - this.initialY
      const newWidth = this.initialWidth + deltaX
      const newHeight = this.initialHeight + deltaY
      const dialog = document.querySelector('.cdk-overlay-pane') as HTMLElement
      dialog.style.width = `${newWidth}px`
      dialog.style.height = `${newHeight}px`
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.resizing = false
  }

  onResizeMouseDown(event: MouseEvent) {
    this.resizing = true
    this.initialX = event.clientX
    this.initialY = event.clientY
    const dialog = document.querySelector('.cdk-overlay-pane')
    this.initialWidth = dialog.clientWidth
    this.initialHeight = dialog.clientHeight
    event.preventDefault()
  }

  changePrimaryColor(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty('--colore-riferimento', color)
  }

  changeBackgroundToolbarColor(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty(
      '--colore-background-toolbar',
      color
    )
  }

  changeBackgroundTableHeaderColor(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty(
      '--colore-sfondo-testata-tabelle',
      color
    )

    const coloreTesto = increaseContrastToThreshold(color, 3)
    document.documentElement.style.setProperty(
      '--colore-testo-testata-tabella',
      coloreTesto
    )
  }

  changeTextToolbarColor(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty('--colore-testo-toolbar', color)
  }

  changeSelettoreLinguaColor(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty('--colore-bordo-selettore-lingua', color)
  }

  changeBackgroundColor(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty('--colore-background', color)
    // calcola il colore del testo in base al colore di sfondo
    const coloreTesto = getOptimalTextColor(color)
    document.documentElement.style.setProperty(
      '--colore-testo-toggle-checked',
      coloreTesto
    )
  }

  changeBackgroundChiaroColor(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty(
      '--colore-background-chiaro',
      color
    )
    // calcola il colore del testo in base al colore di sfondo
    const coloreTesto = getOptimalTextColor(color)
    document.documentElement.style.setProperty(
      '--colore-testo-toggle',
      coloreTesto
    )
  }

  changeSfondoNuvolettaDestra(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty(
      '--colore-sfondo-nuvoletta-destra',
      color
    )
    // calcola il colore del testo in base al colore di sfondo
    const coloreTesto = getOptimalTextColor(color)
    document.documentElement.style.setProperty(
      '--colore-testo-chat-destra',
      coloreTesto
    )
  }

  changeSfondoNuvolettaSinistra(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty(
      '--colore-sfondo-nuvoletta-sinistra',
      color
    )
    // calcola il colore del testo in base al colore di sfondo
    const coloreTesto = getOptimalTextColor(color)
    document.documentElement.style.setProperty(
      '--colore-testo-chat-sinistra',
      coloreTesto
    )
  }

  changeTextColor(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty('--colore-testo', color)
  }

  changeTextChiaroColor(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty('--colore-testo-chiaro', color)
  }
  changeTextButtonColor(event: Event) {
    const color = (event.target as HTMLInputElement).value
    document.documentElement.style.setProperty('--colore-testo-bottoni', color)
  }
  closeDialog(): void {
    this.dialogRef.close()
  }

  saveCombination(): void {
    this.colorCombo.coloreRiferimento =
      this.primaryColorInput.nativeElement.value
    this.colorCombo.coloreTesto = this.textColorInput.nativeElement.value
    this.colorCombo.coloreTestoChiaro =
      this.textChiaroColorInput.nativeElement.value
    this.colorCombo.coloreBackgroundChiaro =
      this.backgroundChiaroColorInput.nativeElement.value
    this.colorCombo.coloreBackgroundToolbar =
      this.backgroundToolbarColorInput.nativeElement.value
    this.colorCombo.coloreTestoToolbar =
      this.textToolbarColorInput.nativeElement.value
    this.colorCombo.coloreBackground =
      this.backgroundColorInput.nativeElement.value
    this.colorCombo.coloreSfondoTestataTabelle =
      this.backgroundTableHeader.nativeElement.value
    this.colorCombo.coloreSfondoNuvolettaDestra =
      this.sfondoNuvolettaDestra.nativeElement.value
    this.colorCombo.coloreSfondoNuvolettaSinistra =
      this.sfondoNuvolettaSinistra.nativeElement.value
    this.colorCombo.coloreTestoBottoni =
      this.textButtonColorInput.nativeElement.value
    this.colorCombo.coloreBordoSelettoreLingua =
      this.selettoreLinguaColorInput.nativeElement.value

    if (this.colorCombo.id === 0 || this.colorCombo.id === undefined) {
      this.colorCombo.default = true
      this.colorService
        .createColorCombination(this.colorCombo)
        .subscribe((esito) => {
          if (esito.esito === ESITO_OK) {
            this.snackBar.open(
              $localize`Combinazione di colori salvata`,
              $localize`Chiudi`,
              {
                duration: 4000,
              }
            )
            this.sessionData.colorCombo = this.colorCombo
          } else {
            this.snackBar.open(
              $localize`Errore nel salvataggio della combinazione di colori`,
              $localize`Chiudi`,
              {
                duration: 4000,
              }
            )
          }
        })
    } else {
      this.colorService
        .updateColorCombination(this.colorCombo)
        .subscribe((esito) => {
          if (esito.esito === ESITO_OK) {
            this.snackBar.open(
              $localize`Combinazione di colori salvata`,
              $localize`Chiudi`,
              {
                duration: 4000,
              }
            )
            this.sessionData.colorCombo = this.colorCombo
          } else {
            this.snackBar.open(
              $localize`Errore nel salvataggio della combinazione di colori`,
              $localize`Chiudi`,
              {
                duration: 4000,
              }
            )
          }
        })
    }

    // salva il logo come versionecontenuto
    if (this.logoCliente) {
      this.logoCliente.contenuto = {
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.LOGO_CLIENTE,
      }
      this.logoCliente.lingua = 'all'
      this.contenutiService
        .updateVersioneContenuto(this.logoCliente, this.logoCliente['file'])
        .subscribe((esito) => {
          if (esito.esito === ESITO_OK) {
            this.snackBar.open($localize`Logo salvato`, $localize`Chiudi`, {
              duration: 4000,
            })
            this.sessionData.logoCliente = this.logoCliente
          } else {
            this.snackBar.open(
              $localize`Errore nel salvataggio del logo`,
              $localize`Chiudi`,
              {
                duration: 4000,
              }
            )
          }
        })
    }

    // salva il logo secure come versionecontenuto
    if (this.logoSecure) {
      this.logoSecure.contenuto = {
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.LOGO_SECURE,
      }
      this.logoSecure.lingua = 'all'
      this.contenutiService
        .updateVersioneContenuto(this.logoSecure, this.logoSecure['file'])
        .subscribe((esito) => {
          if (esito.esito === ESITO_OK) {
            this.snackBar.open($localize`Logo salvato`, $localize`Chiudi`, {
              duration: 4000,
            })
            this.sessionData.logoSecure = this.logoSecure
          } else {
            this.snackBar.open(
              $localize`Errore nel salvataggio del logo`,
              $localize`Chiudi`,
              {
                duration: 4000,
              }
            )
          }
        })
    }
  }

  // ... altre proprietà e metodi ...

  // Metodo chiamato quando l'utente seleziona un file
  changeCustomerLogo(event: Event) {
    const input = event.target as HTMLInputElement

    // Verifica se un file è stato selezionato
    if (input.files && input.files[0]) {
      const file = input.files[0]
      const reader = new FileReader()
      // Evento onload viene chiamato quando il file è stato letto
      reader.onload = (e: ProgressEvent<FileReader>) => {
        // Imposta la proprietà logoPreviewUrl con l'URL dell'immagine
        this.clienteLogoPreviewUrl = e.target.result as string
      }

      reader.onloadend = () => {
        const base64data = reader.result
        this.logoCliente['base64'] = base64data as string
      }
      this.logoCliente['file'] = file
      // valorizza il tipo con il tipo del file
      this.logoCliente.tipo = file.type

      // Legge il contenuto del file come Data URL
      reader.readAsDataURL(file)
    }
  }

  changeSecureLogo(event: Event) {
    const input = event.target as HTMLInputElement

    // Verifica se un file è stato selezionato
    if (input.files && input.files[0]) {
      const file = input.files[0]
      const reader = new FileReader()
      // Evento onload viene chiamato quando il file è stato letto
      reader.onload = (e: ProgressEvent<FileReader>) => {
        // Imposta la proprietà logoPreviewUrl con l'URL dell'immagine
        this.secureLogoPreviewUrl = e.target.result as string
      }

      reader.onloadend = () => {
        const base64data = reader.result
        this.logoSecure['base64'] = base64data as string
      }
      this.logoSecure['file'] = file
      this.logoCliente.tipo = file.type

      // Legge il contenuto del file come Data URL
      reader.readAsDataURL(file)
    }
  }
}
