import { User } from './user'
import { Segnalato } from './segnalato'
import { Segnalante } from './segnalante'

import { Documento } from './documento'
import { Messaggio } from './messaggio'
import { Chat } from './chat'
import { Lavorazione } from './lavorazione'
import { DirittiLavorazione } from './diritti-lavorazione'
import { DirittiSegnalazione } from './diritti-segnalazione'
import { LavorazioneMaster } from './lavorazione-master'
import { Azienda } from './azienda'
import { Omissis } from './omissis'
import { Consenso } from './consenso'
import { AudioFile } from './audio-file'
import { OscuramentoDatiManifestamenteNonNecessari } from './oscuramento-dati-manifestamente-non-necessari'

export class Segnalazione {

  id: string
  idPerSegnalante: string
  progressivo: string
  documenti: Documento[]
  oggetto: string
  descrizione?: string
  ente: string
  periodo: string
  data: Date
  dataChiusura: Date
  luogo: string
  stato: string
  statoPerSegnalante: string
  segnalatoSbloccato: boolean
  segnalanteSbloccato: boolean
  segnalante: Segnalante
  segnalati: Segnalato[]
  gestore: User
  altriSoggetti: string
  altreImprese: string
  motiviCondottaIllecita: string
  altroCondottaIllecita: string
  mod: string
  others: string
  area: string
  altro: string
  settore: string
  altroSettore: string
  contieneAllegato: boolean
  anonima: boolean
  dataInserimento: Date
  visionata: boolean
  userId: string
  messaggi: Messaggio[]
  presaInCarico: boolean
  autoreUltimoAggiornamento: User
  chatAperte: Chat[]
  lavorazioni: Lavorazione[]
  dirittiLavorazione: DirittiLavorazione[]
  dirittiSegnalazioneOdv: DirittiSegnalazione[]
  lavorazioneMaster: LavorazioneMaster
  aziendaInteressata: Azienda
  descrizioneFatto: string
  copieCondivise: Segnalazione[]
  omissis: Omissis[]
  consensi: Consenso[]
  audio: AudioFile
	consensoAudioPrestato: boolean
	dataConsensoAudio: Date
  dataInvio: Date
	consensoAudioChatPrestato: boolean
	dataConsensoAudioChat: Date
  hashAudio: string
  trascrizioneVocaliChat: boolean
  vocaliChatInibiti: boolean
  segnalazioneRiferibileAlGestorePredefinito: boolean
  oscuramentiDatiManifestamenteNonNecessari: OscuramentoDatiManifestamenteNonNecessari[]
  sospendiCancellazione: boolean
  eliminareSegnalazione: boolean
	dataDecisioneEliminazione: Date
  segnalazioneVocale: boolean
}
