import { AzioniFollowUp } from './../modello/azioni-follow-up'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServiziRest } from '../utility/utility'
import { Esito, ESITO_IN_PROGRESS } from '../utility/esito'

@Injectable({
  providedIn: 'root'
})
export class AzioniFollowUpService {
  isFetching: any
  constructor (private http: HttpClient) { }

  handleErrorPromise (error: Response | any) {
    this.isFetching = false
    console.error(error.message || error)
    return Promise.reject(error.error || error)
  }

  aggiornaAzioneFollowUp (azioneFollowUp: AzioniFollowUp): Promise<Esito> {
    return this.http.patch<Esito>(ServiziRest.urlAzioniFollowUp, azioneFollowUp).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  aggiornaAzioniFollowUp (azioni: AzioniFollowUp[]): Promise<Esito[]> {
    return Promise.all(azioni.map((azione) => this.aggiornaAzioneFollowUp(azione)))
  }

  creaAzioniFollowUp (azioneFollowUp: AzioniFollowUp): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlAzioniFollowUp, azioneFollowUp).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  cancellaAzioniFollowUp (azioneFollowUp: AzioniFollowUp): any {
    return this.http.delete<Esito>(ServiziRest.urlAzioniFollowUp + '/' + azioneFollowUp.id).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaAzioneFollowUp (azioneFollowUp: AzioniFollowUp): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlAzioniFollowUp + '/' + azioneFollowUp.id).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaAzioniFollowUp (): Promise<Esito> {
    if (this.isFetching) {
      // Crea un oggetto Esito che rappresenti lo stato "in attesa"
      const esitoInProgress: Esito = {
        esito: ESITO_IN_PROGRESS,
        descrizioneEsito: 'La richiesta è in corso di elaborazione.',
        payload: null,
      };
      console.log('recuperaAzioniFollowUp: richiesta in corso');
      return Promise.resolve(esitoInProgress);
    }

    this.isFetching = true; // Imposta lo stato a true indicando che la richiesta è in corso
    return this.http.get<Esito>(ServiziRest.urlAzioniFollowUp).toPromise()
      .then((esito) => {
        this.isFetching = false
        return esito
      })
      .catch(this.handleErrorPromise)
  }

  recuperaAzioneFollowUpByName (azioneFollowUp: AzioniFollowUp): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlGetAzioniFollowUpByNome + '/' + azioneFollowUp.azione).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }

  async recuperaAzioniFollowUpByName (testo: string): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlGetAzioniFollowUpByNome + '/' + testo).toPromise()
      .then((esito) => esito)
      .catch(this.handleErrorPromise)
  }
}
