import { Esito, ESITO_OK } from './../../utility/esito'

import { of, Subscription } from 'rxjs'
import { SessionData } from './../../sessione/dati-sessione'
import { AuthService } from './../../servizi/auth.service'
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core'
import { Messaggio } from '../../modello/messaggio'
import { SegnalazioneService } from '../../servizi/segnalazione.service'

import { GlobalVars, MAX_FILE_SIZE_MB, processFile, decycle, TipoContenuto, SottoTipoContenuto } from '../../utility/utility'
import { Segnalazione } from '../../modello/segnalazione'
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { Documento, TipoDocumento } from '../../modello/documento'
import { FileInput } from '../../componenti/input-file/file-input.model'
import { FileValidators } from '../../validators/file-validators'
import { Chat } from '../../modello/chat'

import { ChatService } from '../../servizi/chat.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DocumentiService } from '../../servizi/documenti.service'
import { MessaggiStore } from '../../stores/messaggi.store'
import {
  isMembroDiGruppo,
} from '../../utility/helper-segnalazioni'
import { AudioService } from '../../servizi/audio.service'
import { DialogService } from '../../servizi/dialog.service'
import { MatDialog } from '@angular/material/dialog'
import { AllegatiDialogComponent } from '../../componenti/allegati-dialog/allegati-dialog.component'
import { InputFileComponent } from '../../componenti/input-file/input-file.component'
import { ChiediConsensoAudioDialogComponent } from '../../dettaglio-segnalazione/dialogs/chiedi-consenso-audio'
import { filter, map, catchError } from 'rxjs/operators'
import { EventService } from '../../servizi/event.service'
import { EVENT_MESSAGGIO_INVIATO } from '../../servizi/event.contants'
import { VisualizzatorePdfDialogComponent } from '../../componenti/visualizzatore-pdf/visualizzatore-pdf.component'
import { ActivatedRoute, Router } from '@angular/router'
import { ContenutiService } from '../../servizi/contenuti.service'
import { VersioneContenuto } from '../../modello/versione-contenuto'
import { convertBlobToArrayBuffer, modificaPitchAudio } from '../../utility/audio-helper'
import { ChatMessageInfo } from '../../modello/chat-message-info'
import { Odv } from '../../modello/odv'


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent
  implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  curScrollPos = 0
  endReached = false
  messaggi: Messaggio[]
  nuoviMessaggi = false
  formAllegati: UntypedFormGroup
  fileToUpload: Array<File> = null
  allegati: Documento[]
  testoMessaggio: string
  messaggio: Messaggio
  nuovoMessaggioInviato = false
  fileCount = 0

  private subscriptions: Array<Subscription>
  // sottoscrizioneNotifichePerMessaggioNonLettoSelezionato;
  audioBlob: Blob

  @ViewChild('scrollMe', { static: false })
  private myScrollContainer: ElementRef
  sottoscrizioneNotifichePerMessaggioNonLettoSelezionato: any
  testoRichiestaConsensoAudioChat: string
  audioCifrato: boolean

  @Input()
  set aggiornaLetti(messaggio) {
    this.scrollToBottom()
    // this.controllaMessaggiNonLetti();
  }

  @Input() segnalazione: Segnalazione
  @Input() chat: Chat

  @Output() attachmentRemoved = new EventEmitter<number>()
  @ViewChild('attachedfiles') attachedfiles: InputFileComponent

  pannelloManagerAperto: boolean;

  constructor(
    private _fb: UntypedFormBuilder,
    private chatService: ChatService,
    private segnalazioneService: SegnalazioneService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private documentiService: DocumentiService,
    private sessionData: SessionData,
    private messaggiStore: MessaggiStore,
    private audioService: AudioService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private contenutiService: ContenutiService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.pannelloManagerAperto = true
  }

  ngOnInit() {

    this.messaggi = []
    // this.socket = new io.connect(ServiziRest.baseUrlProd, { 'forceNew': true });
    // this.inizializzaChat();
    this.subscriptions = []
    this.sottoscriviPerNotifiche()
    this.contenutiService
      .getVersioneContenuto(TipoContenuto.DOCUMENTO,
        SottoTipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_CHAT,
        this.lingua)
      .pipe(
        filter((esito: Esito) => esito.esito === ESITO_OK),
        map((esito: Esito) => esito.payload),
        catchError((error) => {
          console.error('Errore nella richiesta del valore aggiuntivo', error)
          return of(null)
        })
      )
      .subscribe((payload) => {
        this.testoRichiestaConsensoAudioChat = (
          JSON.parse(payload) as VersioneContenuto
        )?.testo
        this.sessionData.configurazione.testoRichiestaConsensoAudioChat =
          this.testoRichiestaConsensoAudioChat
      })

    this.chatService.notificaPannelloManagerApertoChiuso.subscribe
      ((aperto) => {
        this.pannelloManagerAperto = aperto
      })
  }

  ngAfterViewChecked(): void {
    if (this.nuoviMessaggi || this.nuovoMessaggioInviato) {
      this.scrollToBottom()
      this.nuoviMessaggi = false
      this.nuovoMessaggioInviato = false
    }
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    // console.log(JSON.stringify(changes));
    if (!this.segnalazione) {
      // torna alla lista delle segnalazioni
      this.router.navigate(['/home'])
    }
    if (changes.segnalazione && this.segnalazione) {
      if (this.segnalazione.id !== changes.segnalazione.currentValue.id) {
        this.resetChat()
      }
      this.segnalazione = changes.segnalazione.currentValue
    }
    if (
      changes.chat &&
      changes.chat.currentValue !== changes.chat.previousValue
    ) {
      this.chat = changes.chat.currentValue
      this.messaggiStore.chatSelezionata = this.chat
      // console.log('inizializzo chat...')
      this.inizializzaChat()
    }
    // check the object "changes" for new data
  }
  resetChat(): any {
    this.chat = undefined
    this.messaggi = []
    this.fileCount = 0
  }

  ngOnDestroy(): void {
    console.log(
      '*+*+*+*+*+*+ ELIMINO Sottoscrizioni per ricevere notifiche read *+*+*+*+*+*'
    )
    this.subscriptions?.forEach((sub: Subscription) => sub.unsubscribe())
  }

  sottoscriviPerNotifiche() {
    this.subscriptions.push(
      this.messaggiStore.messaggiNonLettiAggiornati.subscribe((messaggi) => {
        if (this.chat) {
          messaggi.forEach((messaggio) => {
            if (
              +messaggio.mittente.id !== +this.authService.getUser().id &&
              this.chat &&
              +this.chat.id === +messaggio.chat.id
            ) {
              this.aggiungiUltimiMessaggi([messaggio])
              this.nuoviMessaggi = true
              this.scrollToBottom()
              this.messaggiStore.aggiornaStatoMessaggioLetto(messaggio)
            }
          })
        }
      })
    )

    this.subscriptions.push(
      this.messaggiStore.messaggiChatSelezionataAggiornati.subscribe(
        (messaggi) => {
          if (this.chat) {
            this.messaggi = messaggi
            messaggi.forEach((messaggio) => {
              if (
                +messaggio.mittente.id !== +this.authService.getUser().id &&
                this.chat &&
                +this.chat.id === +messaggio.chat.id
              ) {
                if (!messaggio.letto) {
                  this.messaggiStore.aggiornaStatoMessaggioLetto(messaggio)
                }
              }
            })
            if (this.curScrollPos !== 0) {
              this.scrollToBottom()
            }
          }
        }
      )
    )
  }

  inizializzaChat() {
    this.messaggi = []
    this.messaggiStore.messaggiChatSelezionata = []
    this.fileCount = 0
    this.allegati = new Array<Documento>()
    // console.log('CM-2')
    this.caricaMessaggiByChat()

    this.nuovoMessaggio()
    this.formAllegati = this._fb.group({
      basicfile: [],
      files: [
        { value: undefined, disabled: false },
        [FileValidators.maxContentSize(MAX_FILE_SIZE_MB)],
      ],
      disabledfile: [{ value: undefined, disabled: true }],
      multiplefile: [{ value: undefined, disabled: false }],
    })
  }

  nuovoMessaggio() {
    this.messaggio = new Messaggio()
    this.messaggio.testo = this.testoMessaggio
    this.messaggio.mittente = this.authService.getUser()
    this.messaggio.chat = this.chat
  }

  updateScrollPos(e) {
    // console.log(e);
    this.curScrollPos = e.pos
    this.endReached = e.endReached
    if (this.curScrollPos === 0) {
      // console.log('CM-1')
      this.caricaMessaggiByChat(true)
    }
  }

  scrollToBottom(): void {
    // console.log('scroll to bottom...')
    setTimeout(() => {
      try {
        if (this.myScrollContainer) {
          this.myScrollContainer.nativeElement.scrollTop =
            this.myScrollContainer.nativeElement.scrollHeight
        }
      } catch (err) {
        console.log('scrollToBottom-err:' + err)
      }
    }, 100)
  }

  scrollToTop(): void {
    try {
      if (this.myScrollContainer) {
        this.myScrollContainer.nativeElement.scrollTop = 0
      }
    } catch (err) {
      // console.log('scrollToBottom-err:' + err);
    }
  }

  caricaMessaggiByChat(nonScrollare?: boolean) {
    if (this.chat?.id) {
      // console.log('carico messaggi per la chat', this.chat.id)
      this.messaggiStore
        .caricaAltriMessaggi(+this.chat.id)
        .then(() => {
          if (!nonScrollare) {
            this.scrollToBottom()
          } else {
            this.myScrollContainer.nativeElement.scrollTop = 10
          }
        })
        .catch((error) => {
          this.snackBar.open(
            $localize`Non è stato possibile recuperare le chat collegate alla segnalazione.
        ${error.toString()}`,
            null,
            {
              duration: 4000,
            }
          )
        })
    }
  }

  aggiungiMessaggi(tmpMessaggi: Messaggio[]): any {
    tmpMessaggi.forEach((tmpmessaggio) => {
      const tmp = this.messaggi.find(
        (messaggio) =>
          messaggio.testo === tmpmessaggio.testo &&
          messaggio.dataInvio === tmpmessaggio.dataInvio
      )
      if (!tmp) {
        this.messaggi.unshift(tmpmessaggio)
      } else {
        // console.log('messaggio già inserito skip!!!');
      }
    })
  }

  mio(messaggio: Messaggio) {
    if ((+messaggio.mittente.odv && this.authService.getUser().odv) || (!this.authService.getUser().odv && !messaggio.mittente.odv)) {
      return true
    }
    return false
  }


  private calcolaGruppoMittente(messaggio: Messaggio): string {
    const gruppiSegnalazione = this.segnalazione.dirittiSegnalazioneOdv
    //  @ToDo mettere a posto l'elecazione dei gruppi
    //  gruppiSegnalazione = gruppiSegnalazione.concat(this.segnalazione.odvDestinatariInoltro);
    const gruppoMittente = gruppiSegnalazione
      ? gruppiSegnalazione.find((gruppo) =>
        messaggio.mittente.odvMembership
          ? messaggio.mittente.odvMembership.find(
            (tempGruppoMittente) =>
              tempGruppoMittente.id === gruppo.odvDestinatario.id
          ) !== undefined
          : undefined
      )
      : undefined

    return gruppoMittente
      ? gruppoMittente.odvDestinatario?.nomeOdv.toString()
      : messaggio.mittente.odvMembership[0]?.nomeOdv.toString()
  }

  calcolaMittente(messaggio: Messaggio) {
    return this.usernameMittente(messaggio) + (this.gruppoMittente(messaggio) !== '' ?
      '(' + this.gruppoMittente(messaggio) + ')' : '')
  }

  gruppoMittente(messaggio: Messaggio): string {
    // return messaggio.mittente.persona ? messaggio.mittente.persona.nome + ' ' + messaggio.mittente.persona.cognome : 'non definito';
    const mittente = messaggio.mittente
    if (+this.segnalazione?.segnalante?.utente.id === +mittente.id) {
      return ''
    }
    let risultato = ''
    if (mittente.odv) {
      // se l'utente corrente non è un odv allora restituisco la stringa vuota
      if (!this.authService.getUser().odv) {
        risultato = ''
      } else {
        risultato = isMembroDiGruppo(mittente, this.chat.moderatore)
          ? this.chat.moderatore.nomeOdv.toString()
          : this.chat.gruppiPartecipanti
            .find((gruppo) => isMembroDiGruppo(mittente, gruppo))
            ?.nomeOdv.toString()
        // risultato = odvMembershipToString(mittente)
      }
    } else {
      risultato = ''
    }
    return risultato
  }

  usernameMittente(messaggio: Messaggio): string {
    // return messaggio.mittente.persona ? messaggio.mittente.persona.nome + ' ' + messaggio.mittente.persona.cognome : 'non definito';
    const mittente = messaggio.mittente
    if (+this.segnalazione?.segnalante?.utente.id === +mittente.id) {
      return $localize`segnalante`
    }

    let risultato = ''
    if (mittente.odv) {
      // se l'utente corrente non è un odv allora restituisco la stringa gestore
      if (!this.authService.getUser().odv) {
        risultato = $localize`gestore`
      } else {
        risultato = mittente.userId
      }
      // risultato = odvMembershipToString(mittente)

    } else {
      risultato = $localize`segnalante`
    }
    return risultato
  }

  visualizzaAllegato(allegato) {
    this.documentiService
      .downloadDocumento(allegato, TipoDocumento.TIPO_ALLEGATO)
      .then((base64) => {
        allegato.base64 = base64
        this.apriVisualizzatore(allegato)
      })
      .catch((errore) => {
        // console.log(errore);
        this.snackBar.open($localize`Il recupero del file è fallito!`, null, {
          duration: 6000,
        })
      })
  }

  apriVisualizzatore(documento) {
    if (documento.id === 'OMISSIS') {
      this.snackBar.open(
        $localize`Non sei abilitato al download del documento!`,
        null,
        {
          duration: 6000,
        }
      )
      return
    }

    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: documento,
        testo: undefined,
        titolo: $localize`Visualizzazione documento`,
        tipoDocumento: TipoDocumento.TIPO_ALLEGATO,
        bypassAuth: false,
        canDownload: false,
        canPrint: false,
      },
    })
  }

  onSubmit() {
    if (this.formAllegati.controls['files'] != null) {
      this.fileToUpload = (
        this.formAllegati.controls['files'].value as FileInput
      ).files
      this.updateAllegatiMessaggio()
      this.openAllegatiDialog()
    }
  }

  updateAllegatiMessaggio() {
    if (this.fileToUpload == null || this.fileToUpload.length === 0) {
      // console.log('No file selected!');
      return
    }
    this.fileToUpload.forEach(async (file) => {
      const reader = new FileReader()
      reader.onloadend = async (e) => {
        // you can perform an action with readed data here
        // console.log(reader.result);
        if (this.allegati == null) {
          this.allegati = new Array<Documento>()
        }

        const base64 = await processFile(file)

        // let base64 = null;
        // // se il file è una immagine la converto in pdf
        // if (file.type.startsWith("image/")) {
        //   // converte il contenuto del file in base64
        //   base64 = await file.arrayBuffer().then((buffer) => {
        //     let binary = "";
        //     let bytes = new Uint8Array(buffer);
        //     let len = bytes.byteLength;
        //     for (let i = 0; i < len; i++) {
        //       binary += String.fromCharCode(bytes[i]);
        //     }
        //     return window.btoa(binary);
        //   });
        //   base64 = await convertiInPdf(base64, file.type);
        // } else if (
        //   file.type.startsWith("audio/") ||
        //   file.type.startsWith("video/")
        // ) {
        //   // converte il contenuto del file in base64
        //   base64 = await file.arrayBuffer().then((buffer) => {
        //     let binary = "";
        //     let bytes = new Uint8Array(buffer);
        //     let len = bytes.byteLength;
        //     for (let i = 0; i < len; i++) {
        //       binary += String.fromCharCode(bytes[i]);
        //     }
        //     return window.btoa(binary);
        //   });
        // }

        const documento = new Documento(
          reader.result.toString(),
          file.name,
          file.type,
          null
        )
        documento['file'] = file
        if (base64) {
          documento.base64 = base64
        }
        documento.base64 = documento.base64.replace(
          /^data:audio\/mpeg;base64,/,
          ''
        )
        documento.base64 = documento.base64.replace(/^video\/mp4;base64,/, '')
        let duplicato = false
        for (let i = 0; i < this.allegati.length; i++) {
          if (this.allegati[i].nome === documento.nome) {
            duplicato = true
            break
          }
        }
        if (!duplicato) {
          this.allegati.push(documento)
          this.fileCount = this.allegati.length
        }

        this.formAllegati.controls['files'].setValue(null)
        // this.refresh();
      }
      reader.readAsDataURL(file)
    })
  }

  handleFileInput(element) {
    const files = element['files']
    // this.fileToUpload = Array.from(files);
    this.fileToUpload = (
      this.formAllegati.controls['files'].value as FileInput
    ).files
    this.fileToUpload.forEach((file) => {
      // verifica che il file sia pdf, jpeg o mp4
      if (
        !file.type.includes('pdf') &&
        !file.type.startsWith('image/') &&
        !file.type.includes('mp4') &&
        !file.type.includes('audio/mp')
      ) {
        this.fileToUpload.splice(this.fileToUpload.indexOf(file))
        this.snackBar.open(
          // eslint-disable-next-line max-len
          $localize`Il formato del file ${file.name} non è consentito e non può essere allegato. I formati permessi sono pdf, jpeg, png, mp3, ogg o mp4`,
          null,
          {
            duration: 12000,
          }
        )
      } else if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        this.fileToUpload.splice(this.fileToUpload.indexOf(file))
        this.snackBar.open(
          $localize`Il file ${file.name} supera il limite dei ${MAX_FILE_SIZE_MB} MB e non può essere allegato`,
          null,
          {
            duration: 4000,
          }
        )
      }
      if (file.size === 0) {
        this.fileToUpload.splice(this.fileToUpload.indexOf(file))
        this.snackBar.open(
          $localize`Il file ${file.name} è vuoto e verrà rimosso!!!`,
          null,
          {
            duration: 4000,
          }
        )
      }
    })
    this.updateAllegatiMessaggio()
    this.openAllegatiDialog()
  }

  salvaSuInvio(event) {
    if (event.key === 'Enter') {
      // console.log(event);
      this.inviaMessaggio()
    }
  }

  inviaMessaggio() {
    this.messaggio.testo = this.testoMessaggio
    this.messaggio.dataInvio = new Date()
    this.messaggio.chat = this.chat
    this.messaggio.audioCifrato = this.audioCifrato

    if (this.allegati != null) {
      this.messaggio.allegati = this.allegati
      this.allegati = new Array<Documento>()
    }

    this.messaggio.segnalazione = this.segnalazione
    let cloneMessaggio: Messaggio = { ...this.messaggio }
    cloneMessaggio.segnalazione.messaggi = undefined
    cloneMessaggio.segnalazione.documenti = undefined

    cloneMessaggio = decycle(cloneMessaggio)
    cloneMessaggio.dataInvio = new Date()

    const formData = new FormData()
    const message = JSON.stringify(cloneMessaggio)
    formData.append('messaggio', message)

    if (this.audioBlob) {
      formData.append('audio', this.audioBlob, 'audio.mp3')
    }

    // se non c'è ne testo, ne allegati ne audio non invio il messaggio
    if (
      (this.messaggio.testo == null || this.messaggio.testo === '') &&
      (this.messaggio.allegati == null ||
        this.messaggio.allegati.length === 0) &&
      this.audioBlob == null
    ) {
      // scrivi un messaggio di errore
      this.snackBar.open(
        $localize`Non è possibile inviare un messaggio vuoto!`,
        null,
        {
          duration: 4000,
        }
      )

      return
    }

    this.chatService
      .inviaMessaggio(formData)
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.eventService.emitEvent(EVENT_MESSAGGIO_INVIATO, undefined)
          const messaggio = JSON.parse(esito.payload)
          this.messaggiStore.aggiungiMessaggioAChatSelezionata(messaggio)
          this.nuovoMessaggioInviato = true
          this.testoMessaggio = ''
          this.audioBlob = null
          this.fileCount = 0
          this.fileToUpload = null
          // crea un clone del messaggio per inviarlo al socket

          cloneMessaggio.id = messaggio.id
          cloneMessaggio.allegati = messaggio.allegati
          cloneMessaggio.chat = messaggio.chat
          cloneMessaggio.chat.segnalazione = new Segnalazione()
          cloneMessaggio.chat.segnalazione.id = this.segnalazione.id
          cloneMessaggio.chat.moderatore = messaggio.moderatore
          cloneMessaggio.audioHash = messaggio.audioHash
          GlobalVars.socket.emit('chat message', this.chat.id, cloneMessaggio)
          this.snackBar.open($localize`Messaggio Inviato`, null, {
            duration: 2000,
          })
        } else {
          throw new Error(esito.payload)
        }
      })
      .catch((errore: Error) => {
        // console.log(errore);
        this.snackBar.open($localize`Invio Fallito.` + errore.message, null, {
          duration: 4000,
        })
      })
  }

  aggiungiUltimiMessaggi(messaggi: Messaggio[]): any {
    messaggi.forEach((tmpmessaggio) => {
      const tmp = this.messaggi.find(
        (messaggio) =>
          messaggio.testo === tmpmessaggio.testo &&
          messaggio.dataInvio === tmpmessaggio.dataInvio
      )
      if (!tmp) {
        this.messaggi.push(tmpmessaggio)
      } else {
        // console.log('messaggio già inserito skip!!!');
      }
    })
  }

  eliminaAllegato(allegato) {
    for (let i = 0; i < this.allegati.length; i++) {
      if (this.allegati[i].nome === allegato.nome) {
        this.allegati.splice(i)
        break
      }
    }
    this.attachmentRemoved.emit(this.allegati.length)
  }

  canSendMessages() {
    return this.chat !== undefined
  }

  onProcessedBlobChanged(event: { blob: Blob; audioCifrato: boolean }) {
    this.audioBlob = event.blob
    this.audioCifrato = event.audioCifrato
    console.log('Processed blob:', this.audioBlob)
  }

  async playAudio(messaggio: Messaggio): Promise<void> {
    const audioHash = messaggio.audioHash
    this.audioService.getAudio(audioHash).subscribe(
      async (blob) => {
        if (this.isAudioCifrato && messaggio.mittente.automatico && messaggio.mittente.id !== this.authService.getUserPk()) {
          const arrayBuffer = await convertBlobToArrayBuffer(blob)
          blob = await modificaPitchAudio(arrayBuffer, {
            pitch: this.sessionData.configurazione.pitchShifting,
          })
        }
        const audioUrl = URL.createObjectURL(blob)
        const audio = new Audio(audioUrl)
        audio.play()
      },
      (error) => {
        // se l'errore è un 403 non autorizzato, significa che l'audio non è più disponibile
        if (error.status === 403) {
          this.snackBar.open(
            $localize`L'audio non è più disponibile`,
            null,
            {
              duration: 4000,
            }
          )
        }
        console.error('Errore nel recupero del buffer audio', error)
      }
    )
  }

  async onDeleteMessage(messageId: number): Promise<void> {
    const result = await this.dialogService.confirm(
      $localize`Conferma eliminazione`,
      $localize`Sei sicuro di voler eliminare il messaggio?`
    )
    if (result) {
      this.chatService.deleteMessaggio(messageId).subscribe(
        () => {
          // Aggiorna la lista dei messaggi dopo l'eliminazione
          this.inizializzaChat()
        },
        (error) => {
          console.error('Errore durante l\'eliminazione del messaggio:', error)
        }
      )
    }
    return
  }

  openAllegatiDialog() {
    const dialogRef = this.dialog.open(AllegatiDialogComponent, {
      data: { allegati: this.allegati },
    })

    dialogRef.componentInstance.fileSelected.subscribe((files: File[]) => {
      this.fileToUpload = files
      this.updateAllegatiMessaggio()
    })

    dialogRef.componentInstance.onEliminaAllegato.subscribe((allegato) => {
      this.eliminaAllegato(allegato)
      this.fileCount = this.fileCount - 1
    })

    dialogRef.componentInstance.onAggiungiAllegati.subscribe((allegati) => {
      this.handleFileInput(allegati)
      this.fileCount = this.fileCount + allegati.files?.length
    })
  }

  updateBadgeCount(count: number) {
    this.fileCount = count
  }

  handleIconClick() {
    if (this.fileCount === 0) {
      this.attachedfiles.open() // Apre la selezione del file
    } else {
      this.openAllegatiDialog() // Apre la dialog degli allegati
    }
  }

  handleRecorderClick() {
    if (this.consensoAudioChatPrestato) {
    } else {
      this.prestaConsenso()
    }
  }

  get consensoAudioChatPrestato() {
    // verifica se l'utente ha già prestato il consenso audio controllando la segnalazione
    if (this.segnalazione && this.segnalazione.consensoAudioChatPrestato) {
      return true
    } else {
      return false
    }
  }

  prestaConsenso() {
    const dialogRef = this.dialog.open(ChiediConsensoAudioDialogComponent, {
      width: '800px',
      data: {
        testoConsenso:
          this.sessionData.configurazione.testoRichiestaConsensoAudioChat,
      },
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'CANCEL') {
        return
      } else if (result) {
        this.segnalazione.consensoAudioChatPrestato = true
        this.segnalazione.trascrizioneVocaliChat = false
        this.segnalazione.vocaliChatInibiti = false
        this.segnalazione.dataConsensoAudioChat = new Date()
        this.aggiornaConsensoAudio()
      } else {
        this.dialogService
          .confirm(
            $localize`Conferma`,
            // eslint-disable-next-line max-len
            $localize`Sei sicuro di voler revocare il consenso prestato? Se confermi sarà attivata la procedura per inibire l'ascolto dei tuoi messaggi audio da tutte le chat alle quali hai partecipato.`
          )
          .then((res) => {
            if (res) {
              this.segnalazione.trascrizioneVocaliChat = true
              this.segnalazione.consensoAudioChatPrestato = false
              this.segnalazione.vocaliChatInibiti = true
              this.segnalazione.dataConsensoAudioChat = undefined
              this.aggiornaConsensoAudio()
            }
          })
      }
    })
  }

  aggiornaConsensoAudio() {

    this.segnalazioneService.salvaSegnalazione(this.segnalazione).then(
      (esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.segnalazione = JSON.parse(esito.payload)
          this.snackBar.open(
            $localize`Consenso audio salvato con successo`,
            null,
            {
              duration: 2000,
            }
          )
        } else {
          this.snackBar.open(
            $localize`Errore durante il salvataggio del consenso audio`,
            null,
            {
              duration: 2000,
            }
          )
        }
      },
      (error) => {
        this.snackBar.open(
          $localize`Errore durante il salvataggio del consenso audio`,
          null,
          {
            duration: 2000,
          }
        )
      }
    )
  }

  onGestisciConsensoAudio(event) {
    this.prestaConsenso()
  }

  get recorderDisabled() {
    return !this.segnalazione.consensoAudioChatPrestato
  }

  get isAnonima() {
    return this.segnalazione.anonima
  }

  get isAudioCifrato() {
    // verifica tutti i diritti di segnalazione per l'utente corrente
    // e se trova un diritto con audio cifrato restituisce true
    let res = false
    if (this.segnalazione.dirittiSegnalazioneOdv) {
      res = true
      this.segnalazione.dirittiSegnalazioneOdv.forEach((dirsegna) => {
        // controlla che il diritto segnalazione sia applicabile all'utente corrente
        if (isMembroDiGruppo(this.authService.getUser(), dirsegna.odvDestinatario) && !dirsegna.audioCifrato) {
          res = false
          // esci dal foreach
          return
        }
      })
    }
    return res
  }

  get trascrizioneInCorso() {
    return this.segnalazione.trascrizioneVocaliChat
  }

  get vocaliInibiti() {
    return this.segnalazione.vocaliChatInibiti
  }

  get chatHeight() {
    let correzione = 0
    if (window.innerWidth < 600) {
      // Definisci qui l'altezza desiderata per schermi con larghezza inferiore a 600 pixel
      correzione = -120
    } else if (window.innerWidth > 1200) {
      correzione = 0
    } else {
      correzione = -50
    }
    if (!this.pannelloManagerAperto) {
      if (window.innerWidth < 600) {
        correzione += 290
      } else {
        correzione += 170
      }
    }
    if (this.trascrizioneInCorso || this.vocaliInibiti) {
      correzione -= 100
    }
    const margine1 = 435
    const margine2 = 470

    const stile = this.trascrizioneInCorso || (this.vocaliInibiti && !this.trascrizioneInCorso) ?
      `calc(100vh - ${margine1 - correzione}px)` :
      `calc(100vh - ${margine2 - correzione}px)`

    return stile
  }

  iconaAllegato(allegato: Documento) {
    if (allegato.tipo.startsWith('image/')) {
      return 'image'
    } else if (allegato.tipo.startsWith('audio/')) {
      return 'audio_file'
    } else if (allegato.tipo.startsWith('video/')) {
      return 'video_file'
    } else if (allegato.tipo.startsWith('application/pdf')) {
      return 'article'
    } else {
      return 'attach_file'
    }
  }

  get lingua() {
    // ricava il codice della lingua corrente dalla url
    return window.location.href.split('/')[3]
  }

  userId(stato: ChatMessageInfo) {
    if (this.authService.getUser().odv) {
      return (stato.utente?.odv ? stato.utente?.userId : $localize`segnalante`)
    } else {
      return (stato.gruppo.nomeOdv)
    }
  }

  mostra(chi: HTMLElement) {
    if (chi.style.display === 'none') {
      chi.style.display = 'block'
    } else {
      chi.style.display = 'none'
    }
  }

  stampa(stato: ChatMessageInfo) {
    if (this.authService.getUser().odv) {
      return (stato.utente?.odv ? stato.utente?.userId : $localize`segnalante`)
    } else {
      console.log(stato.gruppo.nomeOdv)
      return (stato.gruppo.nomeOdv)
    }
  }

  stati(messaggio: Messaggio) {
    if (this.authService.getUser().odv) {
      // restituisci tutti gli stati meno il mio
      return messaggio.statoInvii.filter((stato) => stato.utente?.id !== this.authService.getUser().id)
    } else {
      // ricava gli stati di invio del messaggio
      const statiInvio = messaggio.statoInvii
      // ricava i gruppi odv coinvolti nella chat
      const gruppi = this.chat.gruppiPartecipanti


      let gruppiFiltrati = []
      gruppiFiltrati = gruppi.filter(
        (gruppo) =>
          !this.isGruppoEscluso(gruppo)
      )



      // crea tanti chat message info quanti sono i gruppi
      const statiInvioGruppi = gruppiFiltrati.map((gruppo) => {
        // cerco uno stato invio di un utente che abbia letto il messaggio ed appartenga al gruppo
        const stato = statiInvio.find((stato) => {
          return stato.utente?.odvMembership.find((odv) => {
            return odv.id === gruppo.id
          }) !== undefined && stato.read
        })

        const cmi = new ChatMessageInfo()
        cmi.gruppo = gruppo
        cmi.messaggio = messaggio
        cmi.delivered = true
        cmi.read = stato !== undefined
        return cmi
      })
      // mi assicuro che ci sia un solo stato invio per ogni gruppo
      const unici = statiInvioGruppi.filter((stato, index, self) => self.findIndex((s) => s.gruppo.id === stato.gruppo.id) === index);
      return unici
    }
  }

  isGruppoEscluso(gruppo: Odv) {
    const cond1 = this.segnalazione.dirittiSegnalazioneOdv.find(
      (diritto) =>
        +diritto.odvDestinatario.id === +gruppo.id
    ) === undefined
    const cond2 = this.segnalazione.dirittiSegnalazioneOdv.find(
      (diritto) =>
        +diritto.odvDestinatario.id === +gruppo.id && diritto.cancellato === true
    ) !== undefined
    return cond1 || cond2
  }
}
