import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { DirittiSegnalazione } from '../modello/diritti-segnalazione'

@Injectable({
  providedIn: 'root'
})
export class OmissisService {
  constructor () { }
  attivaDisattivaOmissis = new Subject<DirittiSegnalazione>()
  disattivaOmissisSeAttivi = new Subject<void>()
}
