<h2 mat-dialog-title class="titolo-dialog">
  Gruppo per moderare Chat
</h2>
<mat-dialog-content>
  <div class="flex-center-center">
    <div class="flex-row flex-100">
      <div class="flex-column flex-100">
        <div class="flex-row flex-center-center flex-100">
          <div class="flex-column flex-50">
            <mat-form-field
              color="accent"
              class="flex-100"
            >
              <mat-select [(ngModel)]="dirittoSelezionato"
                i18n-placeholder placeholder="Partecipa o Modera come..."
              >
                <mat-option
                  *ngFor="
                    let dirittoSegnalazione of diritti"
                  (onSelectionChange)="
                    onGruppoSelezionato(
                      $event,
                      dirittoSegnalazione.odvDestinatario
                    )
                  "
                  [value]="dirittoSegnalazione"
                >
                  {{ dirittoSegnalazione.odvDestinatario.nomeOdv }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div style="margin-bottom: 20px;"></div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button i18n mat-button class="center" (click)="chiudiDialog()">Crea</button>
</mat-dialog-actions>
