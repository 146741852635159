
import { Injectable } from '@angular/core'

import { Segnalazione } from '../modello/segnalazione'
import { User } from '../modello/user'
import { Messaggio } from '../modello/messaggio'
import { Chat } from '../modello/chat'
import * as moment from 'moment'
import { Configurazione } from '../modello/configurazione'
import { Lavorazione } from '../modello/lavorazione'
import { VersioneContenuto } from '../modello/versione-contenuto'
import { ColorCombination } from '../modello/color-combination'

@Injectable()
export class SessionData {
  testoHome: string
  normativa: string

  constructor() { }

  public static initialize(sessionData: any) {
    // console.log('SessionData.initialize', sessionData)
    // inizializza i dati di sessione prendendo i valori dal sessionData passato
    SessionData.utenti = sessionData.utenti
    SessionData.segnalazioneSelezionataElenco = sessionData.segnalazioneSelezionataElenco
    SessionData.navigazione = sessionData.navigazione
    SessionData.lavorazioneSelezionata = sessionData.lavorazioneSelezionata
    SessionData.dataDa = sessionData.dataDa
    SessionData.dataA = sessionData.dataA
    SessionData.statisticheTemaTutte = sessionData.statisticheTemaTutte
    SessionData.statisticheTemaMie = sessionData.statisticheTemaMie
    SessionData.statisticheTemaOdv = sessionData.statisticheTemaOdv
    SessionData.statisticheSorgenteTutte = sessionData.statisticheSorgenteTutte
    SessionData.statisticheSorgenteMie = sessionData.statisticheSorgenteMie
    SessionData.statisticheSorgenteOdv = sessionData.statisticheSorgenteOdv
    SessionData.statisticheInammissibilitaTutte = sessionData.statisticheInammissibilitaTutte
    SessionData.statisticheInammissibilitaMie = sessionData.statisticheInammissibilitaMie
    SessionData.statisticheInammissibilitaOdv = sessionData.statisticheInammissibilitaOdv
    SessionData.statisticheAzioniTutte = sessionData.statisticheAzioniTutte
    SessionData.statisticheAzioniMie = sessionData.statisticheAzioniMie
    SessionData.statisticheAzioniOdv = sessionData.statisticheAzioniOdv
    SessionData.soloTerminiCancellazioneSospesi = sessionData.soloTerminiCancellazioneSospesi


    SessionData.filtroGestore = sessionData.filtroGestore
    SessionData.lavorazioniInCorso = sessionData.lavorazioniInCorso
    SessionData.nessunaLavorazioneInCorso = sessionData.nessunaLavorazioneInCorso
    SessionData.alcuneLavorazioniChiuse = sessionData.alcuneLavorazioniChiuse
    SessionData.bozza = sessionData.bozza
    SessionData.tutteLeLavorazioniChiuse = sessionData.tutteLeLavorazioniChiuse
    SessionData.ritirata = sessionData.ritirata

    console.log('SessionData.initialized', SessionData)

  }

  static filtroGestore = '3'

  static lavorazioniInCorso = true
  static nessunaLavorazioneInCorso = true
  static alcuneLavorazioniChiuse = true
  static bozza = true
  static tutteLeLavorazioniChiuse = true
  static ritirata = true


  //  Dati di stato della maschera statistiche
  static dataDa = moment().subtract(50, 'year').startOf('year')
  static dataA = moment()
  static statisticheTemaTutte = false
  static statisticheTemaMie = true
  static statisticheTemaOdv = false

  static statisticheSorgenteTutte = false
  static statisticheSorgenteMie = true
  static statisticheSorgenteOdv = false

  static statisticheInammissibilitaTutte = false
  static statisticheInammissibilitaMie = true
  static statisticheInammissibilitaOdv = false

  static statisticheAzioniTutte = false
  static statisticheAzioniMie = true
  static statisticheAzioniOdv = false
  static lavorazioneSelezionata: Lavorazione

  static soloTerminiCancellazioneSospesi = false


  segnalazioni: Array<Segnalazione> = []

  configurazione: Configurazione

  private segnalazioneSelezionata: Segnalazione
  messaggiSegnalazioneSelezionata: Array<Messaggio> = []
  static segnalazioneSelezionataElenco: Segnalazione
  chatSelezionata: Chat

  static navigazione: string[] = []
  static utenti: User[]
  public logoCliente: VersioneContenuto
  public logoSecure: VersioneContenuto
  public colorCombo: ColorCombination
  pinCode: string

  cambiaSegnalazioneSelezionata(idSegnalazione: number) {
    console.log('cambiaSegnalazioneSelezionata', idSegnalazione)
    this.segnalazioneSelezionata = this.segnalazioni.find(segnalazione => +segnalazione.id === idSegnalazione)

    this.messaggiSegnalazioneSelezionata = []
  }

  nuovaSegnalazioneSelezionata(segnalazione: Segnalazione) {
    // stampa lo stack con il chiamante

    // console.log('nuovaSegnalazioneSelezionata', segnalazione)
    // se la segnalazione è undefined o null sollevo una eccezione mostrando lo stack trace
    if (!segnalazione) {
      // throw new Error('segnalazione non valorizzata')
      return
    }
    this.segnalazioneSelezionata = segnalazione
    if (segnalazione?.id) {
      sessionStorage.setItem('idSegnalazioneSelezionata', segnalazione.id.toString())
    } else {
      sessionStorage.removeItem('idSegnalazioneSelezionata')
    }

    this.messaggiSegnalazioneSelezionata = []
    if (!this.segnalazioni) {
      return
    }
    if (!segnalazione) {
      return
    }
    const indice = this.segnalazioni.findIndex(tmpsegnalazione => tmpsegnalazione.id === segnalazione.id)
    if (indice !== -1) {
      this.segnalazioni.splice(indice, 1, segnalazione)
    } else {
      this.segnalazioni.push(segnalazione)
    }

  }

  getSegnalazioneSelezionata() {
    return this.segnalazioneSelezionata

  }

  nuovaChatSelezionata(chat: Chat) {
    this.chatSelezionata = chat
    // this.sessionData.getSegnalazioneSelezionata() = chat.segnalazione;
  }

  reset(): any {
    console.log('reset')
    SessionData.utenti = []
    this.segnalazioneSelezionata = undefined
    SessionData.segnalazioneSelezionataElenco = undefined
    this.messaggiSegnalazioneSelezionata = []
    this.segnalazioni = []
    localStorage.clear()
  }

  getLogoCliente(): VersioneContenuto {
    return this.logoCliente
  }



}
