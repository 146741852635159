import { Component, Inject } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AngularEditorConfig } from '@kolkov/angular-editor'



@Component({
  selector: 'app-autorizzazione-dialog',
  templateUrl: 'dialog-autorizzazione-component.html',
})
export class AutorizzazioneSbloccoDialogComponent {

  titolo: string
  azione: string
  motivazione: string
  form: UntypedFormGroup

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Inserisci la motivazione qui...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AutorizzazioneSbloccoDialogComponent>) {
    this.titolo = data['titolo']
    this.azione = data['azione']
    this.motivazione = data['motivazione']
    this.createForm()
  }

  private createForm() {

    this.form = this.fb.group({
      motivazione: this.fb.control({ value: this.motivazione, disabled: false }),
    })
  }

  close() {
    this.dialogRef.close()
  }

  eseguiAzione() {
    this.dialogRef.close({ data: this.form.controls.motivazione })
  }

}
