import { Odv } from './odv'

import { Segnalazione } from './segnalazione'
import { Omissis } from './omissis'
import { User } from './user'
import { Consenso } from './consenso'

export class DirittiSegnalazione {

    id: number


    sceltoDaSegnalante: boolean

    odvCheHaInoltrato: Odv

    consultazioneSegnalazioneSospesa: boolean

    consultazioneSegnalazione: boolean

    comunicazioneConSegnalante: boolean

    aperturaLavorazioni: boolean
    segnalazioneInvisibile: boolean

    puoVedereSegnalante: boolean
    dataRichiestaSbloccoVisibilitaSegnalante: Date
    motivazioneRichiestaSbloccoVisibilitaSegnalante: string
    richiestaSbloccoVisibilitaSegnalanteRespinta: boolean
    richiestaSbloccoVisibilitaSegnalanteAccolta: boolean
    dataRispostaARichiestaSbloccoVisibilitaSegnalante: Date
    motivazioneRispostaARichiestaSbloccoVisibilitaSegnalante: string

    custode: User

    puoVedereSegnalati: boolean

    odvDestinatario: Odv

    segnalazione: Segnalazione

    omissis: Omissis[]
    consensiRichiesti: Consenso[]
    audioCifrato: boolean
    segnalazioneCondivisibile: boolean
    odvDestinatarioInConflittoDiInteressi: boolean
    cancellato: boolean
}
