import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DirittiSegnalazione } from '../modello/diritti-segnalazione'
import { Lavorazione } from '../modello/lavorazione'
import { LogOperativo } from '../modello/log-operativo'
import { Segnalazione } from '../modello/segnalazione'
import { Esito } from '../utility/esito'
import { Operazioni, ServiziRest } from '../utility/utility'

@Injectable({
  providedIn: 'root'
})
export class LogOperativoService {

  constructor(private http: HttpClient) { }

  saveLogOperativo(operazione: Operazioni, segnalazione: Segnalazione,
    lavorazione: Lavorazione, dirittiSegnalazione: DirittiSegnalazione): Observable<Esito> {
    const log = new LogOperativo()
    log.operazione = operazione
    log.segnalazione = segnalazione
    log.lavorazione = lavorazione
    log.dirittiSegnalazione = dirittiSegnalazione
    log.odv = dirittiSegnalazione ? dirittiSegnalazione.odvDestinatario : null
    log.utente = JSON.parse(localStorage.getItem('user') as string)

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })

    const url = `${ServiziRest.urlLogOperativo}`

    return this.http.post<Esito>(url, log, { headers })
  }

  getAllLogOperativo(page: number, limit: number, sortingCriteria): Observable<Esito> {
    const url = `${ServiziRest.urlAllLogOperativo}`
    const params = new HttpParams().set('page', page.toString()).set('limit', limit.toString()).
    set('orderBy', sortingCriteria ? sortingCriteria.orderBy : '').set('direction', sortingCriteria ? sortingCriteria.direction: '')
    return this.http.get<Esito>(url, { params })
  }

  getLogOperativoById(id: number): Observable<Esito> {
    const url = `${ServiziRest.urlLogOperativo}/${id}`
    return this.http.get<Esito>(url)
  }
}
