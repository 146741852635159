export class ColorCombination {

  id: number

  combinationName: string

  default: boolean

  coloreBackground: string

  coloreBackgroundChiaro: string

  coloreBackgroundToolbar: string

  coloreTestoToolbar: string

  coloreRiferimento: string

  coloreTesto: string

  coloreTestoChiaro: string

  coloreSfondoTestataTabelle: string

  coloreTestoToggle: string

  coloreTestoToggleChecked: string

  coloreTestoChatDestra: string

  coloreTestoChatSinistra: string

  coloreTestoTestataTabella: string

  altezzaLogoSecure: number

  altezzaLogoCliente: number

  filtroSvg: string

  coloreSfondoNuvolettaDestra: string
  coloreSfondoNuvolettaSinistra: string
  coloreTestoBottoni: string
  coloreBordoSelettoreLingua: string
}
