<span class="bottone-flottante-alto-sinistra-basso no-print">
  <button mat-mini-fab (click)='backLogin();$event.stopPropagation();' i18n-matTooltip matTooltip="Torna Indietro">
    <mat-icon class="icona-fab" aria-label="Torna indietro">keyboard_arrow_left</mat-icon>
  </button>
</span>
<app-toolbar-landing></app-toolbar-landing>
<mat-toolbar>
  <span class="testo-x-large" style="text-align: center;width: 100%;" i18n>Registrazione</span>
</mat-toolbar>
<div class="alternate-theme">
  <form [formGroup]="registrationForm" style="height:100%" (keydown)="keyDownFunction($event); $event.stopPropagation();"
    *ngIf="!primoAccesso || (primoAccesso && codiceFiscaleVerificato)">
    <mat-card style="height: calc(100vh - 100px);">

      <mat-card-title>

      </mat-card-title>
      <mat-card-content>
        <div class="flex-column" >

          <!-- Campo Nickname -->
          <div class="flex-row" >
            <div style="flex: 0 0 25%"></div>
            <div class="flex-column" >
              <mat-form-field color="accent">
                <input type="text" autocomplete="username" #inputIdUtente matInput class="form-control"
                  formControlName="userId" i18n-placeholder placeholder="Nickname" required>
              </mat-form-field>
              <div
                *ngIf="registrationForm.controls.userId?.invalid && (registrationForm.controls.userId?.dirty || registrationForm.controls.userId?.touched)"
                class="alert alert-danger posizione-errori-utente errori-form">
                <mat-error *ngIf="registrationForm.controls.userId?.errors.required" i18n>
                  Il nickname è obbligatorio.
                </mat-error>
              </div>
            </div>
            <div style="flex: 0 0 25%"></div>
          </div>

          <!-- Campo email -->
          <div class="flex-row" >
            <div style="flex: 0 0 25%"></div>
            <div class="flex-column" >
              <mat-form-field color="accent">
                <input type="text" autocomplete="new-password" matInput class="form-control" formControlName="email"
                  i18n-placeholder placeholder="Email" [required]="emailIsRequired">
              </mat-form-field>
              <div
                *ngIf="registrationForm.controls.email.invalid && (registrationForm.controls.email.dirty || registrationForm.controls.email.touched)"
                class="alert alert-danger posizione-errori-utente errori-form">

                <mat-error *ngIf="registrationForm.controls.email.errors.required" i18n>
                  Questo dato è obbligatorio.
                </mat-error>

                <mat-error *ngIf="registrationForm.controls.email.errors.emailNonValida" i18n>
                  L'email digitata non è valida.
                </mat-error>
                <mat-error *ngIf="registrationForm.controls.email.errors.dominioNonPermesso" i18n>
                  Non è consentito l'utilizzo di email appartenenti ai seguenti domini: {{dominiPermessi}}
                </mat-error>
                <mat-error *ngIf="registrationForm.controls.email.errors.mismatchedEmail" i18n>
                  Gli indirizzi email inseriti non coincidono.
                </mat-error>
              </div>
            </div>
            <div style="flex: 0 0 25%"></div>
          </div>

          <!-- Campo ripeti email -->
          <div class="flex-row" >
            <div style="flex: 0 0 25%"></div>
            <div class="flex-column" >
              <mat-form-field color="accent">
                <input type="text" autocomplete="new-password" matInput class="form-control"
                  formControlName="ripetiEmail" i18n-placeholder placeholder="Digita nuovamente l'indirizzo Email">
              </mat-form-field>
              <div *ngIf="registrationForm.hasError('mismatchedEmail')"
                class="alert alert-danger posizione-errori-utente errori-form">
                <mat-error i18n>
                  Gli indirizzi email inseriti non coincidono.
                </mat-error>
              </div>
            </div>
            <div style="flex: 0 0 25%"></div>
          </div>

          <!--  FOOTER DEL MODULO -->

          <div class="flex-row" >
            <div style="flex: 0 0 25%"></div>
            <div style="flex: 0 0 50%">
              <a (click)="backLogin()" class="pointer" i18n>Torna alla Login</a>
            </div>
            <div style="flex: 0 0 25%"></div>
          </div>

          <div class="flex-column">
            <div class="flex-row" >
              <div style="flex: 0 0 25%"></div>
              <button i18n class="bottone-bordato bottone-login"
                [disabled]="registrationForm.controls.userId.invalid || registrationForm.controls.email.invalid || registrationForm.hasError('mismatchedEmail')"
                mat-button (click)="registrati()" i18n>Registrati
              </button>
              <div style="flex: 0 0 25%"></div>
            </div>
            <div class="flex-row" >
              <div style="flex: 0 0 25%"></div>
              <div class="flex-column">
                <div *ngIf="!emailIsRequired" class="centered" style="margin-top: 50px;">
                  <BR><BR>
                  <H3 i18n>L'indirizzo email non è obbligatorio, se viene fornito ti permetterà di ricevere notifiche sugli
                    aggiornamenti alle tue segnalazioni e generare una nuova Password se dovessi smarrirla</H3>
                </div>
              </div>
              <div style="flex: 0 0 25%"></div>
            </div>
          </div>

          <div  *ngIf="utenteConNuovoPin" class="flex-row" >
            <div style="flex: 0 0 25%"></div>
            <div  class="flex-column">
              <H2 *ngIf="modalitaPinUnico && !utenteConNuovoPin.odv" i18n>Ecco il tuo nuovo PIN per l'accesso al sistema di
                segnalazione</H2>
              <H2 *ngIf="utenteConNuovoPin.odv || !modalitaPinUnico" i18n>Ecco la tua nuova Password per l'accesso al sistema
                di segnalazione</H2>
              <BR>
              <div class="flex-row centered">
                <H1>{{utenteConNuovoPin.pinCode.pincode}}
                  <button mat-icon-button (click)="copiaPin()" i18n-matTooltip matTooltip="Copia negli appunti">
                    <mat-icon class="mat-18" color="accent">file_copy</mat-icon>
                  </button>
                </H1>
              </div>

              <BR>
              <H2 i18n>Conservalo con cura e non rivelarlo a nessuno, ti verrà richiesto ad ogni accesso</H2>
              <H2 i18n>Qualora lo dimenticassi potrai richiederne uno nuovo utilizzando la funzionalità di recupero</H2>
              <div *ngIf="registrazioneConEmail">
                <H2 i18n>Abbiamo inviato un'email all'indirizzo di posta che ci hai fornito, al suo
                  interno troverai
                  un link che ti permetterà l'attivazione dei servizi di notifica e recupero {{ modalitaPinUnico &&
                  !utenteConNuovoPin?.odv ? 'PIN' : 'Password'}}</H2>
              </div>
            </div>
            <div style="flex: 0 0 25%"></div>
          </div>
        </div>

      </mat-card-content>
      <mat-card-footer>


      </mat-card-footer>
    </mat-card>
  </form>
</div>
<div class="footer centro"> {{versione}} </div>
