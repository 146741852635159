import { Observable } from 'rxjs';

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SessionData } from '../sessione/dati-sessione';


export const sessionDataResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<SessionData> | Promise<SessionData> | SessionData => {
  let sessionData: SessionData

  // ora ripristino anche i dati di sessione
  const newSessionDataBase64 = localStorage.getItem('sessionData');
  // console.log('newSessionDataBase64', newSessionDataBase64);
  if (newSessionDataBase64) {
    localStorage.removeItem('sessionData');
    const newSessionData = atob(newSessionDataBase64);
    // console.log('newSessionData', newSessionData);
    if (newSessionData) {
      sessionData = JSON.parse(newSessionData);
    }
  }

  return sessionData;

};
