import { Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Segnalante } from '../../modello/segnalante'
import { AuthService } from '../../servizi/auth.service'
import { ThemeService } from '../../servizi/theme.service'
import { SessionData } from '../../sessione/dati-sessione'
@Component({
  selector: 'app-conferma-invio-segnalazione-dialog',
  templateUrl: 'conferma-invio-segnalazione.html',
})
export class ConfermaInvioSegnalazioneDialogComponent {
  segnalante: Segnalante
  inviaNotifiche = false
  confermaEmail: string

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any, private authService: AuthService, private sessionData: SessionData) {
    this.segnalante = data.segnalante
  }

  get pinPerSegnalazione() {
    return this.sessionData.configurazione.modalitaPinUnicoPerSegnalazione
  }

  get emailUser() {
    return this.authService.getUser().email
  }

  get richiestaAbilitata() {
    return this.sessionData.configurazione.abilitaNotificheVersoEmailInCalceAllaSegnalazione
  }

  get possoUsareEmailPerNotifiche() {
    return this.segnalante.possoUsareEmailPerNotifiche
  }


  onCheckClicked(event) {
    console.log(event)
    this.segnalante.possoUsareEmailPerNotifiche = !this.segnalante.possoUsareEmailPerNotifiche
  }

  get chiediConsenso() {
    return this.authService.getUser().automatico && this.segnalante.email && this.richiestaAbilitata
  }

  get possoProcedere() {
    return (this.possoUsareEmailPerNotifiche && this.confermaEmail === this.segnalante.email) || !this.possoUsareEmailPerNotifiche
  }

}
