<div class="alternate-theme">
  <h1 i18n mat-dialog-title class="titolo-dialog ">Info Utente</h1>
  <div mat-dialog-content>
    <div class="centra-form">
      <table style="width:100%">
        <tr>
          <td colspan="12">
            <mat-form-field style="width:100%">
              <input type="text" matInput i18n-placeholder placeholder="Nickname" [(ngModel)]="utente.userId" disabled>
            </mat-form-field>
          </td>
        </tr>
        <tr *ngIf="utente.odv">
          <td colspan="12">

            {{ ruoliGestore }}

          </td>
        </tr>
        <tr *ngIf="utente.su">
          <td i18n colspan="12" i18n>

            Ricopre il ruolo di Super Utente, può quindi ammministrare gli account

          </td>
        </tr>
        <tr *ngIf="utente.suDatiNonSensibili">
          <td i18n colspan="12" i18n>

            Ricopre il ruolo di Super Utente con limitazione sui dati sensibili, può quindi ammministrare i contenuti ed i parametri di configurazione ma non gli account

          </td>
        </tr>
        <tr *ngIf="custodia">
          <td i8n colspan="12" i18n>

            Ricopre il ruolo di Custode, può quindi concedere o revocare la visibilità sull'identità del segnalante

          </td>
        </tr>
        <tr *ngIf="!utente.su && !utente.odv && !utente.suDatiNonSensibili">
          <td i18n colspan="12">

            Ricopre il ruolo di Utente semplice

          </td>
        </tr>
      </table>
    </div>

  </div>
  <div mat-dialog-actions>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button i18n mat-button mat-dialog-close class="center">Chiudi</button>
  </div>

</div>
