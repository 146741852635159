import { Injectable } from '@angular/core'
import { Odv } from '../modello/odv'
import { ServiziRest } from '../utility/utility'
import { HttpClient } from '@angular/common/http'
import { Esito, ESITO_OK } from '../utility/esito'
import { Observable, Subject, Subscription } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { GestoriSegnalazioniService } from './gestori-segnalazioni.service'

@Injectable({
  providedIn: 'root'
})
export class GestoriSegnalazioniDataService {
  erroriGestoriSegnalazioniDataService = new Subject<string>()
  esitiOperazioniBackend = new Subject<string>()

  subscriptions: Subscription[] = []

  constructor (private http: HttpClient, private gestoriSegnalazioniService: GestoriSegnalazioniService) {
  }

  handleErrorPromise (error: Response | any) {
    console.error(error.message || error)
    return Promise.reject(error.error || error)
  }

  aggiornaOdv (odv: Odv) {
    return this.http.patch<Esito>(ServiziRest.urlGestoriSegnalazione, odv)
      .pipe(
        map(esito => {
          if (esito.esito === ESITO_OK) {
            return JSON.parse(esito.payload) as Odv
          } else {
            throw new Error(esito.payload)
          }
        }),
        tap(odv => {
          this.gestoriSegnalazioniService.updateOdv(odv)
        })
      )
  }

  creaOdv (odv: Odv) {
    return this.http.post<Esito>(ServiziRest.urlGestoriSegnalazione, odv)
      .pipe(
        map(esito => {
          if (esito.esito === ESITO_OK) {
            return JSON.parse(esito.payload) as Odv
          } else {
            throw new Error(esito.payload)
          }
        }),
        tap(odv => {
          this.gestoriSegnalazioniService.addOdv(odv)
        })
      )
  }

  cancellaOdv (odv: Odv) {
    return this.http.delete<Esito>(ServiziRest.urlGestoriSegnalazione + '/' + odv.id)
      .pipe(
        map(esito => {
          if (esito.esito === ESITO_OK) {
            return 'Gruppo Eliminato con successo'
          } else {
            throw new Error(esito.payload)
          }
        }),
        tap(messaggio => {
          this.gestoriSegnalazioniService.deleteOdv(odv)
        })
      )
  }

  recuperaOdvs () {
    return this.http.get<Esito>(ServiziRest.urlGestoriSegnalazione)
      .pipe(
        map(esito => {
          if (esito.esito === ESITO_OK) {
            return JSON.parse(esito.payload) as Odv[]
          } else {
            throw new Error(esito.payload)
          }
        }),
        tap(odvs => {
          this.gestoriSegnalazioniService.setGruppi(odvs)
        })
      )
  }

  recuperaOdv (odv: Odv): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlGestoriSegnalazione + '/' + odv.id).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  recuperaOdvByName (nome: string): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlGetGestoreByNome + '/' + nome).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  async recuperaOdvsByName (testo: string): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlGetGestoriByNome + '/' + testo).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }
}
